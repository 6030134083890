import {SocketApi} from "proto_socket_typescript";
import {proto} from "../proto/messages";
import {common, ws_home} from "../proto/compiled";
import {AppContextStore} from "../stores/AppContextStore";
import {WsConnector} from "./ws_connector";
import {DateUtils} from "../utils/DateUtils";

export class WsProjectsConnector {
    context: AppContextStore;
    api: SocketApi;

    constructor(context: AppContextStore) {
        this.context = context;
        this.api = context.api;

        this.api.getTxMessageHandler(proto.TxLoadAsodProjects.create(), {intercept: true}).subscribe((e) => this.onTxLoadAsodProjects(e));
        this.api.getMessageHandler(new proto.RxApiPregledSessions()).subscribe((e) => this.onRxApiPregledSessions(e));
    }

    private onTxLoadAsodProjects(e: proto.TxLoadAsodProjects) {
        this.api.sendMessage(proto.TxApiPregledGetSessions.create({
            courtCaseNumberFilter: e.proto.opNumber,
            courtFilter: e.proto.court,
            courtroomFilter: e.proto.courtRoom,
            dateFromFilter: DateUtils.secondTimestamp(e.proto.dateFrom),
            dateToFilter: DateUtils.secondTimestamp(e.proto.dateTo),
            ...WsConnector.fromPagination(e.proto.pagination)
        }))
    }

    private onRxApiPregledSessions(m: proto.RxApiPregledSessions) {
        const message = new proto.RxAsodProjects();
        message.proto = ws_home.AsodProjects.create({
            rows: m.proto.sessions.map((e) => ws_home.AsodProjectRow.create({
                id: e.sessionId,
                court: this.context.constants.courtsMap[e.court ?? '']?.name ?? 'N/A',
                courtRoom: this.context.constants.courtroomsMap[e.courtroom ?? '']?.title ?? 'N/A',
                opNumber: e.courtCaseNumber,
                participants: e.participants?.map((p) => ws_home.AsodProjectRowParticipant.create({
                    name: p.displayName,
                    role: this.context.constants.roles[p.roleId ?? '']?.title ?? 'N/A',
                })) ?? [],
                date: {ts: e.sessionStart ? (e.sessionStart as number * 1000) : undefined},
            })),
            pagination: WsConnector.toPagination(m.proto),
        });
        this.api.sendLocalUpdate(message)
    }
}