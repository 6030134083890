import {observer} from "mobx-react-lite";
import React, {useContext} from "react";
import {ManagementStoreContext} from "../../../webservice/stores/ManagementStore";
import {Frame} from "../../elementary/Frame";
import {AsodAppBar} from "../../../fragments/AsodAppBar";
import {Dialog, Typography} from "@mui/material";
import {D} from "../../../app/constants";
import {DataTable} from "../../layouts/DataTable";
import {ProtoTableStoreContext} from "../../../stores/ProtoTableStore";
import {useNavigate} from "react-router-dom";
import {WebserviceRoutes} from "../../../webservice/Routes";
import {ButtonOutline} from "../../elementary/Buttons";
import {BrowserUpdated, Usb} from "@mui/icons-material";
import {WebserviceApps} from "../projects/WebserviceApps";
import {UpgradeDialog} from "../../dialogs/UpgradeDialog";
import {CreatePairingUsb} from "../../../fragments/CreatePairingUsb";

export const ManagementContent = observer(() => {
    const store = useContext(ManagementStoreContext)!;
    const [showUpgradeDialog, setShowUpgradeDialog] = React.useState(false);
    const [showPairingUsb, setShowPairingUsb] = React.useState(false);
    return <ProtoTableStoreContext.Provider value={store.table}>
        <Frame style={{flexGrow: 1}} alignItems={'stretch'} gap={2} padding={2}>
            <AsodAppBar actions={[
                <ButtonOutline onClick={() => setShowPairingUsb(true)}>
                    <Usb/> Ustvari USB za uparjanje
                </ButtonOutline>,
                <ButtonOutline onClick={() => setShowUpgradeDialog(true)}>
                    <BrowserUpdated/> Nadgradnja
                </ButtonOutline>,
                <WebserviceApps/>,
            ]}>
                <Typography variant={'h5'} style={{marginLeft: D.unit(), marginRight: 'auto'}}>Upravljanje
                    sistema</Typography>
            </AsodAppBar>
            <Frame style={{flexGrow: 1}} alignItems={'stretch'} gap={2} padding={1}>
                <DataTable
                    onPageSizeChange={(pageSize) => {
                        store.load();
                    }}
                    onPageChange={(page) => {
                        store.load();
                    }}
                    onRowClick={(r) => {

                    }}
                />
                <Dialog open={showUpgradeDialog} onClose={() => setShowUpgradeDialog(false)}>
                    <UpgradeDialog
                        onCancel={() => {
                            setShowUpgradeDialog(false);
                            store.onUpgrade(false);
                        }}
                        onSave={async () => {
                            setShowUpgradeDialog(false);
                            store.onUpgrade(true);
                        }}
                        nSelected={store.table?.selectedRows?.size ?? 0}
                        state={store.upgrade}
                        inProgress={store.upgradeInProgress}
                    />
                </Dialog>
                {showPairingUsb && <CreatePairingUsb onClose={() => setShowPairingUsb(false)}/>}
            </Frame>
        </Frame>
    </ProtoTableStoreContext.Provider>;
});