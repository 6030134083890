import {createTheme} from "@mui/material";

export const LightTheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#545A7E',
            dark: '#444A6A',
            light: '#656B8B',
        },
        background: {
            default: 'white',
            paper: '#f2f2f8',
        },
        secondary: {
            main: '#EB5757',
            light: '#f77979',
            dark: '#dc4444',
        },
        shades: {
            outline: 'rgba(84,90,126,0.2)',
        },
    },
    typography: {
        button: {
            textTransform: 'none',
            fontWeight: 'bold'
        },
        h6: {
            fontSize: '16px',
            fontWeight: 'bold',
        },
        allVariants: {
            color: '#444A6A',
        }
    },
    shape: {
        borderRadius: 8,
    }
});

export const DarkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#fff',
            dark: '#fff',
            light: '#fff',
        },
        secondary: {
            main: '#EB5757',
            light: '#f77979',
            dark: '#dc4444',
        },
        background: {
            default: '#545A7E',
            paper: '#444A6A',
        },
        text: {
            primary: '#fff',
            secondary: '#A9ACBE',
            disabled: '#b9b9b9',
        },
        shades: {
            outline: 'rgba(255,255,255,0.2)',
        }
    },
    typography: {
        button: {
            textTransform: 'none',
            fontWeight: 'bold'
        },
        h5: {
            fontSize: '18px',
            fontWeight: 'bold',
        },
        allVariants: {
            color: '#fff',
        }
    },
    shape: {
        borderRadius: 8,
    },
});