import styled from "@emotion/styled";
import {Typography} from "@mui/material";

export const BadgeOutline = styled(Typography)`
  margin-left: auto;
  border: 1px solid ${({theme}) => theme.palette.divider};
  border-radius: 4px;
  padding: 2px 4px;
  line-height: 1;
  font-size: 11px;
  font-weight: bold;
`;