import {observer} from "mobx-react-lite";
import useWave from "use-wave";
import Slider from "rc-slider";
import styled from "@emotion/styled";
import React, {useEffect, useState} from "react";
import {D} from "../../app/constants";
import {Space} from "./Space";

interface ChannelLevelProps {
    index: number;
    open: boolean;
    isMuted: boolean;
    isSolo: boolean;
    channelVolume: number;
    setChannelVolume: (v: number) => void;
    onToggleMuted: () => void;
    onToggleSolo: () => void;
    getAudioLevel: () => number; // to optimize using separate observer
}

export const ChannelLevel = observer((props: ChannelLevelProps) => {
    const wave = useWave();

    return <Container open={props.open}>
        <HeaderContainer>
            <CharButton
                onClick={() => props.onToggleMuted()}
                active={props.isMuted}
                style={{borderRight: 'none', borderRadius: '10px 0 0 10px'}} ref={wave}>M</CharButton>
            <ChN>{props.index + 1}</ChN>
            <CharButton
                onClick={() => props.onToggleSolo()}
                active={props.isSolo}
                style={{borderLeft: 'none', borderRadius: '0 10px 10px 0'}} ref={wave}>S</CharButton>
        </HeaderContainer>
        <AudioLevel getAudioLevel={props.getAudioLevel}/>
        <Slider
            style={{margin: '6px 0 8px 0', height: '60px'}}
            tabIndex={-1}
            vertical={true} onChange={(v) => props.setChannelVolume(v as number)}
            max={1} min={0} step={0.01}
            value={props.channelVolume}/>
    </Container>;
});

const AudioLevel = observer(({getAudioLevel}: { getAudioLevel: () => number }) => {
    return <Loudness style={{width: (60 * getAudioLevel()) + 'px'}}/>
});

const Loudness = styled.div`
  height: 4px;
  width: 30px;
  background-color: ${({theme}) => theme.palette.primary.main};
  margin-top: -3px;
  margin-bottom: 3px;
  border-radius: 2px;
  transition: all 100ms linear;
`;

const ChN = styled.div`
  display: flex;
  height: 22px;
  background-color: ${({theme}) => theme.palette.divider};
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 10px;
  padding: 0 4px 0 4px;
`;

const HeaderContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
`;

const Container = styled.div<{ open: boolean }>`
  width: 60px;
  margin: 0 2px 0 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 4px 0 4px;
  background-color: ${({theme, open}) => open ? theme.palette.background.default : 'transparent'};
  border-radius: ${D.radius()};
  border: solid 1px ${({theme, open}) => open ? theme.palette.divider : 'transparent'};
  transition: 300ms all;
`;

const CharButton = styled.div<{ active: boolean }>`
  width: 20px;
  height: 20px;
  cursor: pointer;
  border: solid 1px ${({theme}) => theme.palette.divider};
  background-color: ${({theme, active}) => active ? theme.palette.primary.main : 'transparent'};
  color: ${({theme, active}) => active ? theme.palette.background.default : theme.palette.text.primary};
  font-weight: bold;
  font-size: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
