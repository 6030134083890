import {observer} from "mobx-react-lite";
import {ThemeProvider, Typography} from "@mui/material";
import {DarkTheme, LightTheme} from "../app/theme";
import React, {useContext, useMemo} from "react";
import {AppContextStoreContext} from "../stores/AppContextStore";
import {SocketApi} from "proto_socket_typescript";
import {RecorderContextStore, RecorderContextStoreContext} from "./stores/RecorderContextStore";
import {RecorderRouter} from "./RecorderRouter";
import styled from "@emotion/styled";
import {CssFill} from "../components/base/positioning";
import {CssFlex} from "../components/base/flex";
import {ThemeStyles} from "../components/base/ThemeStyles";
import {SliderStyles} from "../components/base/SliderStyles";

export const RecorderApp = observer(() => {
    const app = useContext(AppContextStoreContext)!;
    const recorder = useMemo(() => new RecorderContextStore(app), [app]);

    return <ThemeProvider theme={DarkTheme}>
        <ThemeStyles>
            <SliderStyles>
                <Typography>
                    <Container>
                        <RecorderContextStoreContext.Provider value={recorder}>
                            <RecorderRouter/>
                        </RecorderContextStoreContext.Provider>
                    </Container>
                </Typography>
            </SliderStyles>
        </ThemeStyles>
    </ThemeProvider>;
});

const Container = styled.div`
  position: fixed;
  ${CssFill};
  ${CssFlex};
  background-color: ${({theme}) => theme.palette.background.paper};

  *::-webkit-scrollbar {
    width: 20px;
  }

  *::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
  }

  *::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    cursor: pointer;
    background-clip: content-box;
    border: 4px solid transparent;
    box-shadow: inset 0 0 5px 0 rgba(255, 255, 255, 1);
  }

  *::-webkit-scrollbar-thumb:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }
`;