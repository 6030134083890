import {observer} from "mobx-react-lite";
import {Schedule} from "@mui/icons-material";
import {ButtonOutline} from "../components/elementary/Buttons";
import React from "react";
import {Dialog, Typography, useTheme} from "@mui/material";
import {DateInput} from "../components/inputs/DateInput";
import {common} from "../proto/compiled";
import {DialogFooter} from "../components/layouts/DialogFooter";
import {D} from "../app/constants";
import {Frame} from "../components/elementary/Frame";
import {Space} from "../components/elementary/Space";

interface PlayAtTimeProps {
    getDefaultValue: () => (number | undefined);
    onChange?: (value: common.IDate) => void;
    minDate?: common.IDate;
    maxDate?: common.IDate;
}

export const PlayAtTime = observer((props: PlayAtTimeProps) => {
    const [value, setValue] = React.useState<common.IDate>(common.Date.create());
    const [isOpen, _setIsOpen] = React.useState(false);
    const theme = useTheme();
    const setIsOpen = (value: boolean) => {
        if (value) {
            setValue(common.Date.create({
                ts: props.getDefaultValue(),
            }));
        }
        _setIsOpen(value);
    }
    if (value) {
        value.granularity = common.DateGranularity.second;
    }

    return <>
        <ButtonOutline style={{marginLeft: 'auto'}} onClick={() => setIsOpen(true)}>
            <Schedule/>Predvajaj ob uri
        </ButtonOutline>
        <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
            <Frame background={theme.palette.background.default} style={{width: D.unit(60)}}
                   gap={2} padding={4}>
                <Typography variant={'h5'}>Predvajaj ob uri</Typography>
                <DateInput
                    minDate={props.minDate}
                    maxDate={props.maxDate}
                    value={value}
                    onChange={(v) => {
                        if (v.ts && props.minDate?.ts && v.ts < props.minDate.ts) v.ts = props.minDate.ts;
                        if (v.ts && props.maxDate?.ts && v.ts > props.maxDate.ts) v.ts = props.maxDate.ts;
                        setValue(v);
                    }}
                    label={'Datum in ura'}/>
                <DialogFooter>
                    <ButtonOutline center onClick={() => setIsOpen(false)}>Prekliči</ButtonOutline>
                    <ButtonOutline center onClick={() => {
                        setIsOpen(false);
                        props.onChange?.(value);
                    }}>Predvajaj</ButtonOutline>
                </DialogFooter>
            </Frame>
        </Dialog>
    </>;
});