import {observer} from "mobx-react-lite";
import styled from "@emotion/styled";
import {useContext, useEffect, useMemo, useState} from "react";
import {AppContextStore, AppContextStoreContext} from "../../stores/AppContextStore";

interface WaveformPositionProps {
    progress: number;
    seeking: boolean;
}

export const WaveformPosition = observer((props: WaveformPositionProps) => {
    const seeking = props.seeking && AppContextStore.mouseDown;
    return <div style={{display: 'flex', height: '100%'}}>
        <div style={{transition: seeking ? undefined : 'all 0.1s linear', flex: props.progress}}/>
        <Indicator/>
        <div style={{transition: seeking ? undefined : 'all 0.1s linear', flex: 1 - props.progress}}/>
    </div>
});

const Indicator = styled.div`
  display: flex;
  width: 0;
  border: solid 2px ${({theme}) => theme.palette.error.main};
  align-items: center;
  justify-content: center;
  overflow: visible;
  user-select: none;
  pointer-events: none;
`;
