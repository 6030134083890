import {Observer, observer} from "mobx-react-lite";
import {DropdownAnything} from "../../../common/DropdownAnything";
import {Dialog, TextField, Typography, useTheme} from "@mui/material";
import React, {useCallback, useMemo} from "react";
import {Frame} from "../../../elementary/Frame";
import useAutoObservable from "../../../../hooks/use_auto_observable";
import {SelectOption} from "../../../elementary/SelectOption";
import {Add} from "@mui/icons-material";
import {Space} from "../../../elementary/Space";
import {EditProjectMemberDialog} from "../../../dialogs/EditProjectMemberDialog";
import {asod_members} from "../../../../proto/compiled";

interface ProjectParticipantsSearchAndAddProps {
    onAdd: (m: asod_members.IAsodMember) => any;
}

export const ProjectParticipantsSearchAndAdd = observer((props: ProjectParticipantsSearchAndAddProps) => {
    const state = useAutoObservable(useMemo(() => ({
        showNewParticipantDialog: false,
        searchValue: '',
        newMember: undefined as asod_members.IAsodMember | undefined,
    }), []));
    const addMember = useCallback((m?: asod_members.IAsodMember) => {
        state.showNewParticipantDialog = true;
        const onlyNumbers = /^\d+$/.test(state.searchValue);
        state.newMember = m ?? asod_members.AsodMember.create({
            name: onlyNumbers ? undefined : state.searchValue,
            taxId: onlyNumbers ? state.searchValue : undefined,
        });
        state.searchValue = '';
        (document.activeElement as any)?.blur();
    }, []);
    const theme = useTheme();
    return <>
        <DropdownAnything triggerBuilder={(ref, isOpen, setIsOpen) => <Observer>
            {() => <TextField
                ref={ref}
                label={'Udeleženci'}
                autoComplete={'off'}
                placeholder={'Poišči in dodaj'}
                value={state.searchValue}
                onChange={(e) => state.searchValue = e.target.value}
                onFocus={() => setIsOpen(true)}
                onBlur={() => setIsOpen(false)}
            />}
        </Observer>} builder={(_, close) => <Observer>
            {() => <Frame style={{border: `solid 1px ${theme.palette.divider}`, marginTop: '2px'}}
                          appearance={'card'} height={50} width={'100%'}>
                {!!state.searchValue?.length && <SelectOption
                    onMouseDown={(e) => {
                        e.preventDefault();
                    }}
                    onClick={() => addMember()}>
                    <Add/><Space/><Typography variant={'button'}>{state.searchValue}</Typography>
                </SelectOption>}
            </Frame>}
        </Observer>}/>
        <Dialog open={state.showNewParticipantDialog} onClose={() => state.showNewParticipantDialog = false}>
            <EditProjectMemberDialog
                add={true}
                member={state.newMember}
                onSave={(m) => {
                    props.onAdd(m);
                    state.showNewParticipantDialog = false;
                }}
                onClose={() => state.showNewParticipantDialog = false}/>
        </Dialog>
    </>
});