import {observer} from "mobx-react-lite";
import {Frame} from "../../../elementary/Frame";
import React, {useContext} from "react";
import {RecorderButton} from "./RecorderButton";
import {RecorderChannels} from "./RecorderChannels";
import {AsodAppBar} from "../../../../fragments/AsodAppBar";
import {RecorderProjectStoreContext} from "../../../../recorder/stores/RecorderProjectStore";
import {RecordingBlocks} from "../common/RecordingBlocks";
import {Dialog, Typography} from "@mui/material";
import {ButtonOutline} from "../../../elementary/Buttons";
import {Transcribe, StopCircleOutlined, Schedule, SettingsOutlined} from "@mui/icons-material";
import {PlayAtTime} from "../../../../fragments/PlayAtTime";
import {ChannelLevels} from "../../../../fragments/ChannelLevels";
import {RecorderProjectBottomBar} from "./RecorderProjectBottomBar";

interface ProjectContentProps {

}

export const RecorderProjectContent = observer((props: ProjectContentProps) => {
    const store = useContext(RecorderProjectStoreContext)!;
    return <Frame style={{flexGrow: 1}} alignItems={'stretch'} gap={2} padding={2}>
        <AsodAppBar actions={[
            <ButtonOutline>
                <SettingsOutlined/>
            </ButtonOutline>
        ]}>
            <RecorderButton
                startedTimestamp={store.recordingState?.changeTimestamp as number | undefined}
                recording={!!store.recordingState?.isRecording}
                onClick={() => store.toggleRecording()}
                loading={store.recordingStateLoading}
            />
            <RecorderChannels/>
        </AsodAppBar>
        <Frame direction={'row'} gap={2}>
            <ChannelLevels levels={store.project.audio.channelLevels}/>
            <PlayAtTime
                minDate={store.project.dateStart}
                maxDate={store.project.dateEnd ?? {ts: Date.now()}}
                getDefaultValue={() => store.project.audio.lastPlayerTime?.getTime()}
                onChange={(value) => {
                    if (store.project.audio.seekDateTime(value)) {
                        store.project.audio.play();
                    }
                }}
            />
            {store.project.audio.isPlaying && <ButtonOutline onClick={() => store.project.audio.stop()}>
                <StopCircleOutlined/>Ustavi predvajanje
            </ButtonOutline>}
        </Frame>
        {!!store.project.data?.audioBlocks?.length && <RecordingBlocks blocks={store.project.data.audioBlocks}/>}
        {!store.project.data?.audioBlocks?.length && <Typography variant={'h3'} style={{
            margin: "auto",
            opacity: 0.2
        }}>
            ni posnetkov - pričnite s snemanjem
        </Typography>}
        <RecorderProjectBottomBar/>
    </Frame>;
});
