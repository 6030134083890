export class DisposeUtils {
    private subscriptions: Unsubscribable[] = [];
    private disposeCallbacks: (() => void)[] = [];

    dispose() {
        for (const cb of this.disposeCallbacks) {
            cb();
        }
        for (const sub of this.subscriptions) {
            sub.unsubscribe();
        }
    }

    add(cb: (() => void) | Unsubscribable) {
        if (typeof cb === 'function') {
            this.disposeCallbacks.push(cb);
        } else {
            this.subscriptions.push(cb);
        }
    }
}

interface Unsubscribable {
    unsubscribe(): void;
}