import styled from "@emotion/styled";
import {Typography} from "@mui/material";
import {D} from "../../app/constants";

export const SelectOption = styled(Typography)<{ selected?: boolean }>`
  display: flex;
  border-bottom: 1px solid ${({theme}) => theme.palette.divider};
  background-color: ${({theme, selected}) => selected ? theme.palette.action.hover : 'transparent'};
  min-height: ${D.interactive()};
  padding: 0 ${D.unit()};
  align-items: center;
  cursor: pointer;
  user-select: none;
  
  :hover {
    background-color: ${({selected, theme}) => selected === undefined && theme.palette.action.hover};
  }
`;