import {observer} from "mobx-react-lite";
import {SidebarLayout} from "../../components/layouts/SidebarLayout";
import React, {useContext, useEffect, useMemo} from "react";
import {Frame} from "../../components/elementary/Frame";
import {ProjectSideBar} from "../../components/pages/project/common/ProjectSideBar";
import {RecorderProjectStore, RecorderProjectStoreContext} from "../stores/RecorderProjectStore";
import {RecorderContextStoreContext} from "../stores/RecorderContextStore";
import {ProjectStoreContext} from "../../stores/ProjectStore";
import {RecorderProjectContent} from "../../components/pages/project/recorder/RecorderProjectContent";

export const IndexPage = observer(() => {
    const context = useContext(RecorderContextStoreContext)!;
    const store = useMemo(() => new RecorderProjectStore(context), [context]);
    useEffect(() => () => store.dispose(), [store]);
    return <RecorderProjectStoreContext.Provider value={store}>
        <ProjectStoreContext.Provider value={store.project}>
            <SidebarLayout>
                <ProjectSideBar/>
                <RecorderProjectContent/>
            </SidebarLayout>
        </ProjectStoreContext.Provider>
    </RecorderProjectStoreContext.Provider>;
});