import styled from "@emotion/styled";
import {CSSProperties} from "react";
import useWave from "use-wave";
import {D} from "../../app/constants";
import {CssFlex, CssFlexCenter} from "../base/flex";
import {Card, Typography, useTheme} from "@mui/material";
import {observer} from "mobx-react-lite";

interface ButtonProps {
    style?: CSSProperties;
    children?: React.ReactNode[] | React.ReactNode;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    disabled?: boolean;
    center?: boolean
}


export const ButtonOutline = (props: ButtonProps) => {
    const theme = useTheme();
    const wave = useWave({color: theme.palette.primary.main});
    return <ButtonOutlineContainer
        style={{flexGrow: props.center ? 1 : undefined}}
        key={props.disabled ? 'disabled' : 'button'}
        ref={props.disabled ? undefined : wave} {...props}>
        <Typography variant={'button'} style={{display: 'contents'}}>
            <ButtonContent style={{
                justifyContent: props.center ? 'center' : undefined,
            }}>
                {props.children}
            </ButtonContent>
        </Typography>
    </ButtonOutlineContainer>;
};

export const ButtonFilled = (props: ButtonProps) => {
    const theme = useTheme();
    const wave = useWave({color: theme.palette.primary.main});
    return <Button
        key={props.disabled ? 'disabled' : 'button'}
        ref={props.disabled ? undefined : wave}
        {...props}
        style={{backgroundColor: theme.palette.primary.main, flexGrow: props.center ? 1 : undefined, ...props.style}}>
        <Typography variant={'button'} style={{display: 'contents', color: theme.palette.background.default}}>
            <ButtonContent style={{
                justifyContent: props.center ? 'center' : undefined,
            }}>
                {props.children}
            </ButtonContent>
        </Typography>
    </Button>;
};

export const AsodFAB = observer((props: ButtonProps) => {
    const wave = useWave();
    const theme = useTheme();
    return <Card elevation={8} style={{
        position: 'absolute',
        bottom: D.unit(2),
        right: D.unit(2),
        backgroundColor: theme.palette.background.default,
    }}>
        <ButtonOutline {...props} style={{
            border: 'none',
            ...props.style
        }}/>
    </Card>
});

const Button = styled.button`
  display: flex;
  border: none;
  flex-shrink: 0;
  padding: 0;
  align-items: center;
  position: relative;
  cursor: ${({disabled}) => disabled ? 'default' : 'pointer'};
  user-select: none;
  border-radius: ${({theme}) => theme.shape.borderRadius}px;
  height: ${D.interactive()};
  background-color: ${({theme}) => theme.palette.background.default};
  overflow: hidden;
`;

export const ButtonContent = styled.span`
  ${CssFlex};
  flex-grow: 1;
  display: inline-flex;
  align-items: center;
  padding: 0 ${D.unit(1)};
  gap: ${D.unit(1)};
`;

const ButtonOutlineContainer = styled(Button)`
  border: 2px solid ${({theme}) => theme.palette.shades.outline};
  background-color: transparent;

  :hover {
    background-color: ${({theme}) => theme.palette.action.hover};
    border-color: ${({theme}) => theme.palette.primary.main};
  }
`;

export const ButtonIconOutline = (props: ButtonProps) => {
    const theme = useTheme();
    const wave = useWave({color: theme.palette.primary.main});
    const children = props.children as React.ReactNode[];
    return <ButtonIconOutlineContainer key={props.disabled ? 'disabled' : 'button'}
                                       ref={props.disabled ? undefined : wave} {...props}>
        <ButtonIconOutlineIconContainer>
            {children[0]}
        </ButtonIconOutlineIconContainer>
        <Typography variant={'button'} style={{display: 'contents'}}>
            <ButtonContent>
                {children.slice(1)}
            </ButtonContent>
        </Typography>
    </ButtonIconOutlineContainer>;
}

const ButtonIconOutlineIconContainer = styled.span`
  ${CssFlexCenter};
  display: inline-flex;
  background-color: ${({theme}) => theme.palette.primary.main};
  color: ${({theme}) => theme.palette.background.default};
  height: 100%;
  aspect-ratio: 1;
`;

const ButtonIconOutlineContainer = styled(Button)`
  border: 2px solid ${({theme}) => theme.palette.primary.main};
  opacity: ${({disabled}) => disabled ? 0.5 : 1};

  :hover {
    background-color: ${({theme, disabled}) => disabled ? undefined : theme.palette.action.hover};
  }
`;