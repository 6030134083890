import React, {useContext, useMemo, useState} from "react";
import {
    Paper,
    TableContainer,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography, Box, useTheme, MenuItem, Menu,
} from "@mui/material";

import ArrowDownward from '@mui/icons-material/ArrowDownward';
import ArrowUpward from '@mui/icons-material/ArrowUpward';

import {observer} from "mobx-react-lite";
import {common, table} from "../../proto/compiled";
import useWave from "use-wave";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import CheckBoxOutlineBlank from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxOutlined from "@mui/icons-material/CheckBoxOutlined";
import Close from "@mui/icons-material/Close";
import styled from "@emotion/styled";
import {Space} from "../elementary/Space";
import {Pagination} from "../elementary/Pagination";
import {D} from "../../app/constants";
import {ProtoTableStoreContext} from "../../stores/ProtoTableStore";
import {AppContextStoreContext} from "../../stores/AppContextStore";

interface Props {
    onRowClick?: (row: TableRowData) => void;
    onSortChange?: (header: TableHeaderCellData) => void;
    onPageChange?: (page: number) => void;
    onPageSizeChange?: (pageSize: number) => void;
    checkboxes?: boolean;
    tableToolbar?: any;
    selectionActions?: (close: () => void) => any;
    actions?: TableAction[];
    extraSpace?: number;
}

interface TableAction {
    header: any;
    width: string;
    child: React.ReactElement | ((r: TableRowData) => React.ReactElement);
    onClick: (r: TableRowData) => void;
}

export const DataTable = observer((props: Props) => {
    const [state, setState] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const wave = useWave();
    const context = useContext(AppContextStoreContext)!;
    const store = useContext(ProtoTableStoreContext)!;
    const header = useMemo(() => store.data.getHeader(), [store.data])
    const rows = useMemo(() => store.data.getRows(), [store.data])

    const closeSelectionActions = () => {
        setAnchorEl(null);
    }

    const onHeaderClick = (headerCell: TableHeaderCellData) => {
        if (headerCell.sortable) {
            if (headerCell.ordering === common.SortOrder.so_desc) {
                headerCell.ordering = common.SortOrder.so_asc;
            } else {
                headerCell.ordering = common.SortOrder.so_desc;
            }
            for (const h of header || []) {
                if (h !== headerCell) {
                    h.ordering = common.SortOrder.so_none;
                }
            }
            if (props.onSortChange) {
                props.onSortChange(headerCell);
            }
            setState(!state);
        }
    };

    const theme = useTheme();

    return (
        <TableContainer component={Paper} elevation={0} style={{
            flexGrow: 1,
            height: 0,
            overflow: 'auto',
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            backgroundColor: theme.palette.background.paper,
            border: `solid 1px ${theme.palette.divider}`,
            userSelect: store.canSelect && (context.shiftPressed || context.ctrlPressed) ? 'none' : undefined,
        }}>
            <Table sx={{minWidth: store.data?.minWidth, tableLayout: 'fixed'}} aria-label="simple table">
                <StyledTableHead style={{zIndex: 99}}>
                    <TableRow>
                        {props.checkboxes && <StyledTableHeaderCell
                            style={{
                                userSelect: 'none',
                                width: store?.isSelected ? '54px' : '32px',
                                position: 'sticky',
                                padding: 0,
                                top: 0,
                                backgroundColor: store?.isSelected ? theme.palette.primary.light : 'white',
                                cursor: store?.isSelected ? 'pointer' : 'default',
                                transition: 'all 0.2s ease-in-out'
                            }}
                            onClick={(e) => {
                                if (store?.isSelected) {
                                    if (props.selectionActions) {
                                        setAnchorEl(e.currentTarget);
                                    } else {
                                        store?.selectedRows.clear();
                                    }
                                }
                            }}
                        >
                            <Box display={"flex"} alignItems={"center"} justifyContent={"center"} height={'40px'}>
                                {store.isSelected && <Typography variant={"caption"} style={{color: 'white'}}>
                                    {store.selectedRows.size}
                                </Typography>}
                                {store?.isSelected && props.selectionActions &&
                                    <ArrowDropDown style={{color: "white"}} fontSize={"small"}/>}
                                {store?.isSelected && !props.selectionActions &&
                                    <Close style={{color: "white"}} fontSize={"small"}/>}
                            </Box>
                        </StyledTableHeaderCell>}
                        {header?.map((h, i) => (
                            // @ts-ignore
                            <StyledTableHeaderCell
                                key={i}
                                style={{
                                    cursor: h.sortable ? "pointer" : "default",
                                    userSelect: 'none',
                                    width: h.maxCharacters ? `${h.maxCharacters}ch` : undefined,
                                    flexGrow: h.flex ? 1 : 0,
                                    position: 'sticky',
                                    top: 0,
                                    color: 'black'
                                }}
                                onClick={() => onHeaderClick(h)}
                            >
                                <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                    {h.name}
                                    {h.sortable &&
                                        (h.ordering === common.SortOrder.so_asc ? <ArrowUpward fontSize={"small"}/> :
                                            h.ordering === common.SortOrder.so_desc ?
                                                <ArrowDownward fontSize={"small"}/> : null)}
                                </Box>
                            </StyledTableHeaderCell>
                        ))}
                        {props.actions?.map((a, i) => <StyledTableHeaderCell
                            key={`action-${i}`}
                            style={{
                                userSelect: 'none',
                                width: a.width,
                                position: 'sticky',
                                top: 0,
                                backgroundColor: 'white',
                            }}
                        >
                            {a.header}
                        </StyledTableHeaderCell>)}
                    </TableRow>
                    {store.data && props.tableToolbar && <TableRow>
                        <th colSpan={header!.length + (props.checkboxes ? 1 : 0)}>{props.tableToolbar}</th>
                    </TableRow>}
                </StyledTableHead>
                <TableBody style={{zIndex: 0}}>
                    {rows?.map((r, i) => (
                        <StyledTableRow
                            key={i}
                            selactive={store.canSelect && (context.shiftPressed || context.ctrlPressed) ? 'true' : undefined}
                            hover
                            selected={store?.selectedRows?.has(r.id!)}
                            style={{
                                backgroundColor: i % 2 ? 'white' : undefined,
                            }}
                            onClick={() => {
                                if (store.canSelect && (context.shiftPressed || context.ctrlPressed)) {
                                    store.onSelect(rows, i);
                                } else if (store?.selectedRows?.size) {
                                    store.selectedRows.clear();
                                } else {
                                    props.onRowClick && props.onRowClick(r);
                                }
                            }}
                        >
                            {props.checkboxes && <StyledTableCell ref={wave} style={{
                                cursor: 'pointer',
                                verticalAlign: 'middle',
                                textAlign: 'center',
                                padding: 0,
                                lineHeight: 0,
                            }} onClick={(e) => {
                                if (context.shiftPressed || context.ctrlPressed) return;
                                e.preventDefault();
                                e.stopPropagation();
                                store.toggle(r.id!);
                            }}>
                                {store.selectedRows.has(r.id!) ? <CheckBoxOutlined fontSize={"small"}/> :
                                    <CheckBoxOutlineBlank fontSize={"small"}/>}
                            </StyledTableCell>}
                            {r.children?.map((c, j) => (
                                <StyledTableCell key={j}>
                                    {c}
                                </StyledTableCell>
                            ))}
                            {props.actions?.map((a, i) => <StyledTableCell sx={{
                                position: 'relative',
                                textAlign: 'center',
                                verticalAlign: 'middle',
                                padding: '0!important',
                                color: theme.palette.primary.main,
                                ':hover': {
                                    color: theme.palette.background.default,
                                    backgroundColor: theme.palette.primary.main,
                                }
                            }} onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                if (store?.selectedRows?.size) {
                                    store.selectedRows.clear();
                                } else {
                                    a.onClick(r);
                                }
                            }} key={`action-cell-${i}`}>
                                <div style={{
                                    display: 'flex',
                                    alignItems: "center",
                                    justifyContent: "center",
                                    position: 'absolute',
                                    top: 0,
                                    bottom: 0,
                                    right: 0,
                                    left: 0
                                }}>
                                    {typeof a.child === 'function' ? a.child(r) : a.child}
                                </div>
                            </StyledTableCell>)}
                        </StyledTableRow>
                    ))}
                    <tr>
                        <td><Space s={props.extraSpace ?? 0}/></td>
                    </tr>
                </TableBody>
            </Table>
            {store.data.pagination &&
                <Pagination
                    pagination={store.data.pagination}
                    style={{alignSelf: 'stretch'}}
                    onPageSizeChange={(size) => {
                        store.data.pagination.pageSize = size;
                        props.onPageSizeChange?.(size);
                    }}
                    onPageChange={(page) => {
                        store.data.pagination.page = page;
                        props.onPageChange?.(page);
                    }} sticky={true}/>}
            {props.selectionActions && <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={closeSelectionActions}
            >
                {props.selectionActions(closeSelectionActions)}
                <MenuItem
                    style={{borderTop: `1px solid ${theme.palette.divider}`, marginBottom: '-6px'}}
                    onClick={() => {
                        store.selectedRows.clear();
                        closeSelectionActions();
                    }}>Počisti izbor</MenuItem>
            </Menu>}
        </TableContainer>
    );
});

const StyledTableHead = styled(TableHead)`
  position: sticky;
  top: 0;
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
`;

const StyledTableRow = styled(TableRow)<{ selactive?: string, selected?: boolean }>`
  cursor: pointer;

  background-color: ${({selected}) => selected ? '#9EC1FC!important' : undefined};

  &:hover {
    background-color: ${({
                           theme,
                           selactive,
                           selected
                         }) => selected ? '#9EC1FC' : selactive ? '#d4e1f9' : theme.palette.action.hover} !important;
  }
`;


const StyledTableCell = styled(TableCell)`
  padding-top: ${D.unit(0.5)};
  padding-bottom: ${D.unit(0.5)};
  overflow: hidden;
  vertical-align: top;
  border-right: 1px solid ${({theme}) => theme.palette.divider};
  z-index: 0;
`;

const StyledTableHeaderCell = styled(StyledTableCell)`
  z-index: 1;
  padding-top: ${D.unit()};
  padding-bottom: ${D.unit()};
  color: ${({theme}) => theme.palette.primary.light} !important;
  font-weight: bold;
  border-bottom: none;
`;

// data
export interface TableHeaderCellData {
    id: string;
    name: any;
    ordering?: common.SortOrder;
    sortable: boolean;
    maxCharacters?: number;
    flex?: number;
}

export interface TableRowData {
    id: string;
    data?: any;
    children: any[];
}

export abstract class DataTableData {
    pagination: common.IPagination;
    minWidth?: string;

    constructor(pagination?: common.IPagination | null) {
        this.pagination = pagination ?? common.Pagination.create({
            page: 1,
            nPages: 1,
            pageSize: 10,
            total: 0,
        });
    }

    abstract getHeader(): TableHeaderCellData[];

    abstract getRows(): TableRowData[];
}