import {asod_audio, asod_members, asod_project} from "../../proto/compiled";

export const DummyAsodMembers = [
    asod_members.AsodMember.create({
        id: "1",
        name: "John Doe",
        role: asod_members.AsodMemberRole.create({name: 'SODNIK'}),
        projectMeta: asod_members.AsodProjectMemberMeta.create({
            accessGranted: false,
        }),
    }),
    asod_members.AsodMember.create({
        id: "1",
        name: "Urška Klakočar",
        role: asod_members.AsodMemberRole.create({name: 'ZAPISNIKAR'}),
        projectMeta: asod_members.AsodProjectMemberMeta.create({
            accessGranted: false,
            audioChannel: 2,
        }),
    }),

];

export const DummyAsodProject = asod_project.AsodProject.create({
    id: 'test-uuid',
    meta: asod_project.AsodProjectMeta.create({
        opNumber: 'I K 123/2021',
        members: DummyAsodMembers,
    }),
    audioBlocks: [
        asod_project.AsodProjectAudioBlock.create({
            timestampStart: new Date(2023, 5, 17, 9, 0, 0).getTime(),
            timestampEnd: new Date(2023, 5, 17, 9, 30, 0).getTime(),
            comments: [
                asod_project.AsodProjectComment.create({
                    id: 'test-uuid-1',
                    timestamp: new Date(2023, 5, 17, 9, 5, 42).getTime(),
                    author: DummyAsodMembers[0],
                    'public': true,
                    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisl eget ultricies aliquam, nunc nunc aliquet nunc, quis aliquam nunc nunc nec nunc.',
                }),
                asod_project.AsodProjectComment.create({
                    id: 'test-uuid-2',
                    timestamp: new Date(2023, 5, 17, 9, 10, 28).getTime(),
                    author: DummyAsodMembers[1],
                    'public': false,
                    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisl eget ultricies aliquam, nunc nunc aliquet nunc, quis aliquam nunc nunc nec nunc.',
                }),
            ],
            audio: asod_audio.AsodAudio.create({
                id: 'test-uuid',
                chunks: [
                    /*

                    asod_audio.AsodAudioChunk.create({
                        id: 'test-uuid-1',
                        timestampStart: new Date(2023, 5, 17, 9, 0, 0).getTime(),
                        timestampEnd: new Date(2023, 5, 17, 9, 10, 0).getTime(),
                        src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3',
                    }),
                    asod_audio.AsodAudioChunk.create({
                        id: 'test-uuid-2',
                        timestampStart: new Date(2023, 5, 17, 9, 10, 0).getTime(),
                        timestampEnd: new Date(2023, 5, 17, 9, 20, 0).getTime(),
                        src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-2.mp3',
                    }),
                    asod_audio.AsodAudioChunk.create({
                        id: 'test-uuid-3',
                        timestampStart: new Date(2023, 5, 17, 9, 20, 0).getTime(),
                        timestampEnd: new Date(2023, 5, 17, 9, 30, 0).getTime(),
                        src: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-3.mp3',
                    }),
                     */
                ],
                waveform: [0, 0.5, 0.2, 0.8, 1, 0]
            }),
        }),
    ]
});