import {useMemo, useState} from "react";
import {observer} from "mobx-react-lite";
import {DropdownAnything} from "../common/DropdownAnything";
import {TextField} from "@mui/material";
import {TextFieldProps} from "@mui/material/TextField/TextField";
import {Frame} from "../elementary/Frame";
import {SelectOption} from "../elementary/SelectOption";


interface SelectInputValue {
    id?: string;
    label?: string;
    child?: any;
}

interface SelectInputProps {
    onBlur?: () => any;
    options: SelectInputValue[];
    value?: SelectInputValue;
    onChange?: (input: SelectInputValue) => any;
    canClear?: boolean;
    textField: TextFieldProps;
}

export const SelectInput = observer((props: SelectInputProps) => {
    const [value, setValue] = useState<string>(props.value?.label ?? '');
    useMemo(() => setValue(props.value?.label ?? ''), [props.value?.label]);
    const [searchString, setSearchString] = useState(value);
    const fixed = true;
    const optionsI = props.options;
    const options = Array.from(optionsI);

    const targetValue = useMemo(() => props.value ?? {
        label: '',
        id: undefined,
    }, [props.value]);

    useMemo(() => options?.sort((a: SelectInputValue, b: SelectInputValue) => {
        const sl = searchString.toLowerCase();
        const incA = a.label!.toLowerCase().includes(sl);
        const incB = b.label!.toLowerCase().includes(sl);
        if (incA && !incB) return -1;
        if (!incA && incB) return 1;
        return a.label!.localeCompare(b.label!);
    }), [options, searchString]);

    return <DropdownAnything
        triggerBuilder={
            (ref, isOpen, setIsOpen) => <TextField
                ref={ref}
                {...props.textField}
                value={value}
                onBlur={(e) => {
                    if (props.canClear && !value.trim()) {
                        targetValue.label = '';
                        targetValue.id = undefined;
                        props.onChange?.(targetValue);
                    }
                    setValue(targetValue.label ?? '');
                    setIsOpen(false);
                    props.onBlur?.();
                }}
                onKeyDown={() => setIsOpen(true)}
                onFocus={() => {
                    setIsOpen(true);
                }}
                onChange={(e) => {
                    setValue(e.target.value);
                    setSearchString(e.target.value)
                    if (fixed) return;
                    targetValue.label! = e.target.value;
                    props.onChange?.(targetValue);
                }}/>

        }
        builder={(marginTop, close) => <Frame appearance={'card'} style={{
            overflowY: 'auto',
            height: '300px',
            marginTop: marginTop + 'px',
        }}>
            {options.map((o: SelectInputValue, i) =>
                <SelectOption
                    key={`${i}-${o.label}`}
                    onMouseDown={(e) => {
                        e.preventDefault();
                    }}
                    onClick={() => {
                        setValue(o.label!);
                        setSearchString(o.label!);
                        targetValue.label = o.label;
                        targetValue.child = o.child;
                        targetValue.id = o.id;
                        props.onChange?.(targetValue);
                        close();
                    }}>{o.child ?? o.label}</SelectOption>)}
        </Frame>}
        compute={(rect: DOMRect) => window.innerHeight - rect.bottom < 300 ? -304 - rect.height : 4}
    />
})