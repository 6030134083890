import {observer} from "mobx-react-lite";
import {Frame} from "../elementary/Frame";
import {D} from "../../app/constants";
import styled from "@emotion/styled";
import {Typography} from "@mui/material";
import {CssFlexCenter} from "../base/flex";
import useWave from "use-wave";
import {CSSProperties} from "react";

interface SoundChannelPickerProps {
    selected?: number;
    onChange: (channel: number) => void;
    nChannels: number;
    style?: CSSProperties;
}

export const SoundChannelPicker = observer((props: SoundChannelPickerProps) => {
    return <Frame row appearance={'outline'} justifyContent={'space-evenly'} alignItems={'center'} padding={1} style={props.style}>
        {Array.from(Array(props.nChannels).keys()).map((i) => <Channel
            key={i}
            selected={i + 1 === props.selected}
            onClick={() => {
                if (i + 1 === props.selected) props.onChange(0);
                else props.onChange(i + 1);
            }}>
            {i + 1}
        </Channel>)}
    </Frame>
});

const Channel = styled(Typography)<{ selected: boolean }>`
  ${CssFlexCenter};
  cursor: pointer;
  user-select: none;
  width: ${D.interactive(0.8)};
  height: ${D.interactive(0.8)};
  box-sizing: border-box;
  font-weight: bold;
  transition: all 0.3s;
  border-radius: 50%;
  border: ${({selected}) => selected ? D.interactive(0.4) : '1px'} solid ${({theme}) => theme.palette.primary.dark};
  color: ${({theme, selected}) => selected ? theme.palette.background.default : theme.palette.primary.dark};
  
  :hover {
    background-color: ${({theme}) => theme.palette.action.focus};
  }
`;