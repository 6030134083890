import {Box, IconButton, Typography} from "@mui/material";
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment/moment";
import 'moment/locale/sl';
import {DateTimePicker} from "@mui/x-date-pickers";
import {useMemo, useState} from "react";
import {common} from "../../proto/compiled";
import {Js2Proto, Proto2Js} from "../../utils/ProtoUtils";
import {DateUtils} from "../../utils/DateUtils";
import {D} from "../../app/constants";
import {observer} from "mobx-react-lite";

interface DateInputProps {
    value: common.IDate;
    granularityEditable?: boolean;
    minDate?: common.IDate;
    maxDate?: common.IDate;
    onChange?: (value: common.IDate) => void;
    onChanged?: (value: common.IDate) => void;
    invalid?: boolean;
    label: string;
}

export const DateInput = observer((props: DateInputProps) => {
    const [date, setDate] = useState(props.value?.ts ? moment(Proto2Js.Date(props.value)) : null);
    const ts = isNaN(date?.valueOf() ?? NaN) ? undefined : date?.valueOf();
    if (!!props.value?.ts && ts !== props.value?.ts) {
        setTimeout(() => {
            setDate(moment(props.value?.ts ? moment(Proto2Js.Date(props.value)) : null));
        }, 0);
    }
    const state = useMemo(() => ({
        minDate: props.minDate ? moment(Proto2Js.Date(props.minDate)) : undefined,
        maxDate: props.maxDate ? moment(Proto2Js.Date(props.maxDate)) : undefined,
    }), []);

    const granularity = props.value.granularity ?? common.DateGranularity.day;

    const handleDateChange = (momentDate: moment.Moment | null) => {
        setDate(momentDate);
        props.value.ts = momentDate?.valueOf();
        props.onChange?.(props.value);
    };

    const handleDateChanged = () => {
        props.onChanged?.(props.value);
    }

    const increaseGranularity = () => {
        props.value.granularity = Math.min(common.DateGranularity.second, granularity + 1);
    }

    const decreaseGranularity = () => {
        props.value.granularity = Math.max(common.DateGranularity.year, granularity - 1);
    }

    const getGranularityText = (granularity: common.DateGranularity) => {
        switch (granularity) {
            case common.DateGranularity.year:
                return 'YY';
            case common.DateGranularity.month:
                return 'MM';
            case common.DateGranularity.day:
                return 'DD';
            case common.DateGranularity.hour:
                return 'HH';
            case common.DateGranularity.minute:
                return 'MM';
            case common.DateGranularity.second:
                return 'SS';
            default:
                return undefined;
        }
    }

    const minDate = props.minDate ? moment(Proto2Js.Date(props.minDate)) : state.minDate;
    const maxDate = props.maxDate ? moment(Proto2Js.Date(props.maxDate)) : state.maxDate;

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <Box display={"flex"} position={"relative"}>
                {granularity > common.DateGranularity.day && <DateTimePicker
                    sx={{
                        flexGrow: 1,
                    }}
                    slotProps={{textField: {size: 'small', onBlur: handleDateChanged, error: !!props.invalid}}}
                    label={props.label}
                    value={date}
                    onChange={handleDateChange}
                    format={DateUtils.getDatePickerFormat(granularity)}
                    minDate={minDate}
                    maxDate={maxDate}
                    onAccept={handleDateChanged}
                    onClose={handleDateChanged}
                />}
                {granularity <= common.DateGranularity.day && <DatePicker
                    sx={{
                        flexGrow: 1,
                    }}
                    slotProps={{textField: {size: 'small', onBlur: handleDateChanged, error: !!props.invalid}}}
                    label={props.label}
                    value={date}
                    onChange={handleDateChange}
                    format={DateUtils.getDatePickerFormat(granularity)}
                    minDate={minDate}
                    maxDate={maxDate}
                    onAccept={handleDateChanged}
                    onClose={handleDateChanged}
                />}
                {props.granularityEditable &&
                    <Box display={"flex"} position={"absolute"} right={D.unit(5)}
                         bottom={0} top={0} alignItems={"center"}>
                        {granularity > common.DateGranularity.year &&
                            <IconButton tabIndex={-1} onClick={decreaseGranularity} sx={{aspectRatio: 1}}
                                        size={"small"}><Typography
                                fontSize={"small"}
                                fontWeight={"bold"}
                                color={"red"}>
                                - {getGranularityText(granularity)}
                            </Typography></IconButton>}
                        {granularity < common.DateGranularity.second &&
                            <IconButton tabIndex={-1} onClick={increaseGranularity} sx={{aspectRatio: 1}}
                                        size={"small"}><Typography
                                fontSize={"small"}
                                fontWeight={"bold"}
                                color={"green"}>
                                {getGranularityText((granularity) + 1)} +
                            </Typography></IconButton>}
                    </Box>
                }            </Box>
        </LocalizationProvider>
    );
});
