import {observer} from "mobx-react-lite";
import React, {useContext} from "react";
import {D} from "../../../app/constants";
import {Frame} from "../../elementary/Frame";
import {Space} from "../../elementary/Space";
import {Logo} from "../../elementary/Logo";
import {Divider, TextField} from "@mui/material";
import useAutoObservable from "../../../hooks/use_auto_observable";
import {ManagementStoreContext} from "../../../webservice/stores/ManagementStore";

export const ManagementSideBar = observer(() => {
    const store = useContext(ManagementStoreContext)!;
    const data = useAutoObservable(store.loadData);
    return <Frame style={{flexGrow: 1}} alignItems={'stretch'} gap={2}>
        <Space/>
        <Logo style={{margin: `${D.unit(2)} auto`}}/>
        <Frame gap={2} style={{flexGrow: 1, flexBasis: 0, overflow: 'auto'}} padding={2}>
            <TextField
                label={'Sodišče'}
                value={data.court}
                onChange={(e) => data.court = e.target.value}
                onKeyDown={(e) => e.key === 'Enter' && store.load()}
                onBlur={() => store.load()}
            />
            <TextField
                label={'Sodna dvorana'}
                value={data.courtRoom}
                onChange={(e) => data.courtRoom = e.target.value}
                onKeyDown={(e) => e.key === 'Enter' && store.load()}
                onBlur={() => store.load()}
            />
        </Frame>
    </Frame>;
});