import {observer} from "mobx-react-lite";
import {Frame} from "../components/elementary/Frame";
import {IconButton, Typography} from "@mui/material";
import {PlayArrow, Pause} from "@mui/icons-material";
import styled from "@emotion/styled";
import {D} from "../app/constants";
import {Waveform} from "../components/elementary/Waveform";
import {WaveformPosition} from "../components/elementary/WaveformPosition";
import {useMemo, useState} from "react";

interface AsodAudioPlayerProps {
    isPlaying: boolean;
    progress?: number;
    seek?: (progress: number) => void;
    seekSeconds?: (seconds: number) => void;
    waveform?: number[];
    togglePlaying: () => void;
}

export const AsodAudioPlayer = observer((props: AsodAudioPlayerProps) => {
    const [seeking, setSeeking] = useState({
        cancel: undefined as NodeJS.Timeout | undefined,
        seeking: false,
    });

    return <Frame direction={'row'} grow gap={0.5} paddingX={0.5}>
        <IB onClick={() => props.togglePlaying()}>
            {props.isPlaying ? <Pause/> : <PlayArrow/>}
        </IB>
        <Separator/>
        {props.seekSeconds && <>
            <IB onClick={() => props.seekSeconds!(-300)}>
                <Typography variant={'button'}>-5m</Typography>
            </IB>
            <IB onClick={() => props.seekSeconds!(-10)}>
                <Typography variant={'button'}>-10s</Typography>
            </IB>
        </>}
        <Waveform
            waveform={props.waveform}
            gap={3}
            tickPadding={12}
            thickness={3}
            onSeek={(p) => {
                clearTimeout(seeking.cancel);
                const update = !seeking.seeking;
                seeking.seeking = true;
                seeking.cancel = setTimeout(() => setSeeking({seeking: false, cancel: undefined}), 100);
                if (update) setSeeking(seeking);
                props.seek?.(p);
            }}>
            {props.progress !== undefined && <WaveformPosition seeking={seeking.seeking} progress={props.progress}/>}
        </Waveform>
        {props.seekSeconds && <>
            <IB onClick={() => props.seekSeconds!(300)}>
                <Typography variant={'button'}>+5m</Typography>
            </IB>
            <IB onClick={() => props.seekSeconds!(10)}>
                <Typography variant={'button'}>+10s</Typography>
            </IB>
        </>}
    </Frame>
});

const IB = styled(IconButton)`
  aspect-ratio: 1;
  margin: ${D.unit(0.5)} 0;
`;

const Separator = styled.div`
  width: 2px;
  background-color: rgba(255, 255, 255, 0.12);
`;