import {useCallback, useEffect, useRef, useState} from 'react';
import {useMutationObservable} from "./use_mutation_observable";

interface ContainerSize {
    width: number;
    height: number;
}

type UseMeasureArgs = () => {
    ref: React.RefObject<any>;
    size: ContainerSize | undefined;
    windowSize: ContainerSize;
};

const initSize: ContainerSize = {width: 0, height: 0};

export const useMeasure: UseMeasureArgs = () => {
    const ref = useRef<any>(null);
    const [size, setSize] = useState<ContainerSize | undefined>(undefined);
    const [windowSize, setWindowSize] = useState<ContainerSize>(initSize);

    const update = useCallback(() => {
        if (ref.current) {
            setSize(ref.current.getBoundingClientRect());
        }
        if (typeof window !== 'undefined') {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
    }, []);

    useEffect(() => update(), [update]);
    useMutationObservable(ref, update);
    useEffect(() => {
        window.addEventListener('resize', update);
        return () => window.removeEventListener('resize', update);
    }, [update]);

    return {ref, size, windowSize};
};