import {AppContextStore} from "../AppContextStore";
import {ProjectStore} from "../ProjectStore";
import {autorun, isObservable, makeAutoObservable} from "mobx";
import {asod_audio, asod_project, common} from "../../proto/compiled";
import {DisposeUtils} from "../../utils/DisposeUtils";
import {AudioPlayerStore} from "../AudioPlayerStore";
import {MobxUtils} from "../../utils/MobxUtils";
import {proto} from "../../proto/messages";
import {toast} from "react-toastify";
import { ChannelLevelData } from "../../fragments/ChannelLevels";

export class AudioStore {
    private context: AppContextStore;
    private blockAudioPlayerStores: Map<string, AudioPlayerStore> = new Map<string, AudioPlayerStore>();
    private disposeUtils = new DisposeUtils();
    private project: ProjectStore;
    private recordingPlayerStore?: AudioPlayerStore;
    private recordingChunkId?: string;
    private lastPlayingBlock?: asod_project.IAsodProjectAudioBlock;
    lastPlayerTime?: Date;

    constructor(context: AppContextStore, project: ProjectStore) {
        this.context = context;
        this.project = project;
        makeAutoObservable(this);
        let prevData = this.project.data;
        this.disposeUtils.add(autorun(() => {
            MobxUtils.makeObservable(this.project.data);
            const blocks = this.project.data?.audioBlocks ?? [];
            this.lastPlayingBlock ??= blocks[0];
            if (!this.project.data) return;
            if (prevData !== this.project.data) {
                this.blockAudioPlayerStores.forEach((store) => store.dispose());
                this.blockAudioPlayerStores.clear();
            }
            for (const block of blocks) {
                this.initBlock(block);
            }
            prevData = this.project.data;
        }));

        this.disposeUtils.add(this.context.api.getMessageHandler(new proto.RxRecorderRecordingState()).subscribe((e) => this.onRecordingState(e)));
    }

    private initBlock(block: asod_project.IAsodProjectAudioBlock) {
        if (this.blockAudioPlayerStores.has(block.id!)) return;
        MobxUtils.makeObservable(block.audio);
        const store = new AudioPlayerStore(block.audio!, {
            onPlayerTime: (time) => {
                this.lastPlayerTime = time;
                this.lastPlayingBlock = block;
            }
        });
        store.setRecordingChunk(this.recordingChunkId);
        this.blockAudioPlayerStores.set(block.id!, store);
    }

    dispose() {
        this.blockAudioPlayerStores.forEach((store) => store.dispose());
        this.disposeUtils.dispose();
    }


    getBlockProgress(block?: asod_project.IAsodProjectAudioBlock): number | undefined {
        return this.blockAudioPlayerStores.get(block?.id ?? '')?.progress;
    }

    isBlockPlaying(block: asod_project.IAsodProjectAudioBlock): boolean {
        return this.blockAudioPlayerStores.get(block.id!)?.isPlaying ?? false;
    }

    advanceBlockProgressSeconds(block: asod_project.IAsodProjectAudioBlock, seconds: number) {
        const player = this.blockAudioPlayerStores.get(block.id!);
        if (!player) return;
        player.seekSeconds(player.currentTime + seconds);
    }

    setBlockProgressFraction(block: asod_project.IAsodProjectAudioBlock, progress: number) {
        const player = this.blockAudioPlayerStores.get(block.id!);
        if (!player) return;
        player.seek(progress);
    }

    toggleBlockPlaying(block: asod_project.IAsodProjectAudioBlock) {
        const isBlockPlaying = this.isBlockPlaying(block);
        for (const [id, store] of Array.from(this.blockAudioPlayerStores.entries())) {
            if (id === block.id) {
                store.setPlaying(!isBlockPlaying);
            } else {
                store.setPlaying(false);
            }
        }
    }

    stop() {
        for (const store of Array.from(this.blockAudioPlayerStores.values())) {
            store.setPlaying(false);
        }
    }

    get isPlaying() {
        return Array.from(this.blockAudioPlayerStores.values()).some((store) => store.isPlaying);
    }

    private onRecordingState(e: proto.RxRecorderRecordingState) {
        const recordingStore = this.blockAudioPlayerStores.get(e.proto.recordingBlockId ?? '');
        if (this.recordingChunkId === e.proto.recordingChunkId) return;
        this.recordingChunkId = e.proto.recordingChunkId ?? '';
        this.recordingPlayerStore?.setRecordingChunk(this.recordingChunkId);
        if (this.recordingPlayerStore === recordingStore) return;
        this.recordingPlayerStore?.setRecordingChunk(undefined);
        if (recordingStore) {
            recordingStore.setRecordingChunk(e.proto.recordingChunkId ?? '');
        }
        this.recordingPlayerStore = recordingStore;
    }

    seekDateTime(value: common.IDate) {
        const block = this.project.getBlockByDate(value);
        if (!block) {
            toast.error('Za izbrani čas ni posnetka.');
            return false;
        }
        this.blockAudioPlayerStores.get(block.id!)?.seekDate(value);
        return true;
    }

    play() {
        const block = this.lastPlayingBlock;
        if (!block) return;
        this.blockAudioPlayerStores.get(block.id!)?.setPlaying(true);
    }

    get nChannels(): number | undefined {
        if (!this.project.data?.audioBlocks?.length) return undefined;
        return this.project.data?.audioBlocks[0]?.audio?.nChannels ?? undefined;
    }

    get channelLevels(): ChannelLevelData[] {
        const audioStore: AudioPlayerStore = this.blockAudioPlayerStores.values().next().value;
        if (!audioStore) return [];
        return new Array(this.nChannels).fill(0).map((_, i) => ({
            index: i,
            isMuted: audioStore?.channelMuted(i) ?? false,
            isSolo: audioStore?.soloChannel(i) ?? false,
            channelVolume: audioStore?.channelVolume(i) ?? 1,
            setChannelVolume: (volume: number) => audioStore?.setChannelVolume(i, volume),
            onToggleMuted: () => audioStore?.toggleMuted(i),
            onToggleSolo: () => audioStore?.toggleSolo(i),
            getAudioLevel: () => Array.from(this.blockAudioPlayerStores.values()).find((s) => s.isPlaying)?.channelLoudness(i) ?? 0,
        }));
    }

    refreshAudioChunk(blockId: string, chunk: asod_audio.IAsodAudioChunk) {
        const audio = this.blockAudioPlayerStores.get(blockId);
        if (!audio) return;
        audio.refreshChunk(chunk.id!, chunk.src!);
    }
}