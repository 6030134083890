import {SocketApi} from "proto_socket_typescript";
import {AuthStore} from "./AuthStore";
import React from "react";
import {autorun, makeAutoObservable} from "mobx";
import {proto} from "../proto/messages";
import {api_konstante, api_pairing} from "../proto/compiled";
import {WsConnector} from "../api/ws_connector";
import {toast} from "react-toastify";

export const AppContextStoreContext = React.createContext<AppContextStore | undefined>(undefined);

export enum AsodVariant {
    recorder = 'recorder',
    asodint = 'asodint',
    asodout = 'asodout',
}

export class AppContextStore {
    static variant = process.env.REACT_APP_VARIANT as AsodVariant;
    api: SocketApi;
    auth: AuthStore;
    ctrlPressed = false;
    shiftPressed = false;
    static mouseDown = false;
    constants: Constants;
    isConnected = false;
    private connector?: WsConnector;
    features: Features;

    constructor(api: SocketApi) {
        this.api = api;
        this.features = new Features();
        if (!this.isRecorder) {
            this.connector = new WsConnector(this);
        }
        this.api.connection.connectedChanges.subscribe((e) => {
            if (this.isRecorder) {
                this.isConnected &&= e;
            } else {
                this.isConnected = e
            }
        });
        if (!this.isRecorder) {
            this.isConnected = this.api.connection.connected;
        }
        this.auth = new AuthStore(this);
        this.setGlobalEventListeners();
        this.constants = new Constants(this);
        makeAutoObservable(this);
        this.setupReactions();
    }

    get isRecorder() {
        return AppContextStore.variant === AsodVariant.recorder;
    }

    private setGlobalEventListeners() {
        window.addEventListener('focus', () => {
            this.shiftPressed = false;
            this.ctrlPressed = false;
        });
        window.addEventListener('keydown', (e) => {
            this.shiftPressed = e.shiftKey;
            this.ctrlPressed = e.ctrlKey || e.metaKey;
        });
        window.addEventListener('keyup', (e) => {
            this.shiftPressed = e.shiftKey;
            this.ctrlPressed = e.ctrlKey || e.metaKey;
        });
        window.addEventListener('mousedown', () => {
            AppContextStore.mouseDown = true;
        });
        window.addEventListener('mouseup', () => {
            AppContextStore.mouseDown = false;
        });
        this.api.getMessageHandler(new proto.RxIlolError()).subscribe((m) => toast.error(m.proto.reason));
    }

    private setupReactions() {
        if (!this.isRecorder) {
            // recorder has special condition for this call (when connection is established using pairing certificate)
            autorun(() => {
                if (this.isConnected && this.auth.loggedIn && !this.auth.hasUser) {
                    this.api.sendMessage(proto.TxApiGeneralWhoAmI.create());
                }
            });
            autorun(() => {
                if (this.auth.loggedInAndHasUser) {
                    this.api.sendMessage(proto.TxApiKonstanteGetRoles.create());
                }
            });
        }
        autorun(() => {
            if (this.isConnected) {
                this.api.sendMessage(proto.TxApiPairingGetCourts.create());
                this.api.sendMessage(proto.TxApiPairingGetCourtrooms.create());
            }
        });

    }
}

class Constants {
    private app: AppContextStore;
    courts: api_pairing.IApiPairingCourt[] = [];
    courtsMap: { [key: string]: api_pairing.IApiPairingCourt } = {};
    courtrooms: api_pairing.IApiPairingCourtroom[] = [];
    courtroomsMap: { [key: string]: api_pairing.IApiPairingCourtroom } = {};
    roles: { [key: string]: api_konstante.IApiKonstanteRole } = {};

    constructor(app: AppContextStore) {
        this.app = app;
        this.app.api.getMessageHandler(new proto.RxApiPairingCourts()).subscribe((e) => {
            this.courts = e.proto.courts;
            this.courtsMap = {};
            for (const court of e.proto.courts) {
                this.courtsMap[court.id ?? ''] = court;
            }
        });
        this.app.api.getMessageHandler(new proto.RxApiPairingCourtrooms()).subscribe((e) => {
            this.courtrooms = e.proto.courtrooms;
            this.courtroomsMap = {};
            for (const courtroom of e.proto.courtrooms) {
                this.courtroomsMap[courtroom.id ?? ''] = courtroom;
            }
        });
        this.app.api.getMessageHandler(new proto.RxApiKonstanteRoles()).subscribe((e) => {
            this.roles = {};
            for (const role of e.proto.courtRoles) {
                this.roles[role.id ?? ''] = role;
            }
        });
        makeAutoObservable(this);
    }
}

class Features {
    apps: boolean;
    uploadSession: boolean;
    editCourtNumber: boolean;
    editSession: boolean;
    showDeeplinks: boolean;

    constructor() {
        this.uploadSession = AppContextStore.variant === AsodVariant.asodint;
        this.editCourtNumber = AppContextStore.variant === AsodVariant.recorder;
        this.editSession = [AsodVariant.asodint, AsodVariant.recorder].includes(AppContextStore.variant);
        this.showDeeplinks = AppContextStore.variant === AsodVariant.asodint;
        this.apps = AppContextStore.variant === AsodVariant.asodint;

        makeAutoObservable(this);
    }
}