import {AppContextStore} from "./AppContextStore";
import {createContext} from "react";
import {asod_project} from "../proto/compiled";
import {makeAutoObservable} from "mobx";
import {uploader} from "proto_socket_typescript/lib/proto/compiled";

export const UploadProjectStoreContext = createContext<UploadProjectStore | undefined>(undefined);

export class UploadProjectStore {
    private context: AppContextStore;
    meta = asod_project.AsodProjectMeta.create();
    file?: uploader.IUFile;

    constructor(context: AppContextStore) {
        this.context = context;
        makeAutoObservable(this);
        makeAutoObservable(this.meta);
    }

    dispose() {

    }
}