import {observer} from "mobx-react-lite";
import React, {useContext} from "react";
import {D} from "../../../app/constants";
import {Frame} from "../../elementary/Frame";
import {Space} from "../../elementary/Space";
import {Logo} from "../../elementary/Logo";
import {Divider, TextField} from "@mui/material";
import useAutoObservable from "../../../hooks/use_auto_observable";
import {LogsStoreContext} from "../../../webservice/stores/LogsStore";
import {DateInput} from "../../inputs/DateInput";
import {common} from "../../../proto/compiled";
import {CheckInput} from "../../inputs/CheckInput";

export const LogsSideBar = observer(() => {
    const store = useContext(LogsStoreContext)!;
    const data = useAutoObservable(store.loadData);
    useAutoObservable(data.dateFrom!, data.dateTo);
    return <Frame style={{flexGrow: 1}} alignItems={'stretch'} gap={2}>
        <Space/>
        <Logo style={{margin: `${D.unit(2)} auto`}}/>
        <Frame gap={2} style={{flexGrow: 1, flexBasis: 0, overflow: 'auto'}} padding={2}>
            <TextField
                label={'Sodišče'}
                value={data.court}
                onChange={(e) => data.court = e.target.value}
                onKeyDown={(e) => e.key === 'Enter' && store.load()}
                onBlur={() => store.load()}
            />
            <TextField
                label={'Dvorana'}
                value={data.courtroom}
                onChange={(e) => data.courtroom = e.target.value}
                onKeyDown={(e) => e.key === 'Enter' && store.load()}
                onBlur={() => store.load()}
            />
            <TextField
                label={'Akcija'}
                value={data.action}
                onChange={(e) => data.action = e.target.value}
                onKeyDown={(e) => e.key === 'Enter' && store.load()}
                onBlur={() => store.load()}
            />
            <DateInput value={data.dateFrom!} label={'Datum - od'} onChanged={() => {
                if (!data.dateFrom!.ts) {
                    data.dateFrom!.ts = Date.now() - 1000 * 60 * 60 * 24;
                }
                store.load();
            }}/>
            <DateInput value={data.dateTo!} label={'Datum - do'} onChanged={() => {
                if (!data.dateTo!.ts) {
                    data.dateTo!.ts = Date.now();
                }
                store.load();
            }}/>
        </Frame>
    </Frame>;
});