import {SocketApi} from "proto_socket_typescript";
import React from "react";
import {AppContextStore} from "../../stores/AppContextStore";
import {recorder_state} from "../../proto/compiled";
import {proto} from "../../proto/messages";
import {autorun, makeAutoObservable} from "mobx";

export const RecorderContextStoreContext = React.createContext<RecorderContextStore | undefined>(undefined);

export class RecorderContextStore {
    app: AppContextStore;
    api: SocketApi;
    private state?: recorder_state.IRecorderState;
    webserviceState = recorder_state.RecorderWebserviceState.create({
        isConnected: false,
    });
    uploadState?: recorder_state.RecorderProjectUploadState;

    constructor(app: AppContextStore) {
        this.app = app;
        this.api = app.api;
        this.api.getMessageHandler(new proto.RxRecorderState()).subscribe((e) => this.state = e.proto);
        this.api.getMessageHandler(new proto.RxRecorderWebserviceState()).subscribe((e) => this.webserviceState = e.proto);
        this.api.getMessageHandler(new proto.RxRecorderProjectUploadState()).subscribe((e) => this.uploadState = e.proto);
        this.api.getMessageHandler(new proto.RxOnRecorderBoot()).subscribe((e) => window.location.reload());
        makeAutoObservable(this);
        this.setupReactions();
    }

    get hasState() {
        return !!this.state;
    }

    get isPaired() {
        return this.state?.pairing?.isPaired ?? false;
    }

    get hasPairingCertificate() {
        return this.state?.pairing?.hasPairingCertificate ?? false;
    }

    get isCSRGenerated() {
        return this.state?.pairing?.csrGenerated ?? false;
    }


    private setupReactions() {
        autorun(() => {
            if (this.webserviceState.isConnected && this.app.auth.loggedIn && !this.app.auth.hasUser) {
                this.api.sendMessage(proto.TxApiGeneralWhoAmI.create());
            }
        });
        autorun(() => {
            if (this.webserviceState.isConnected && this.isPaired && this.app.auth.loggedInAndHasUser) {
                this.api.sendMessage(proto.TxApiKonstanteGetRoles.create());
            }
        });
        autorun(() => {
            this.app.isConnected = this.webserviceState.isConnected;
        });
    }
}