import React, {useContext} from "react";
import {Frame} from "../../../elementary/Frame";
import {asod_recorder_audio} from "../../../../proto/compiled";
import styled from "@emotion/styled";
import {D} from "../../../../app/constants";
import {CssFlexCenter} from "../../../base/flex";
import {ProjectDimens} from "../common/ProjectDimens";
import {Typography} from "@mui/material";
import {MicOutlined, WarningOutlined} from "@mui/icons-material";
import {RecorderProjectStoreContext} from "../../../../recorder/stores/RecorderProjectStore";
import {observer} from "mobx-react-lite";

export const RecorderChannels = observer(() => {
    const store = useContext(RecorderProjectStoreContext)!;
    return <Frame alignItems={'stretch'} justifyContent={'space-evenly'} grow height={ProjectDimens.headerHeight}
                  appearance={'shade'} row paddingX={2} paddingY={1}>
        {store.channels.map((c, i) => <RecorderChannel key={i} channel={c}/>)}
    </Frame>
});

export const RecorderChannel = (props: { channel: asod_recorder_audio.IAsodRecorderAudioChannel }) => {
    return <Frame justifyContent={'space-between'} alignItems={'center'}>
        <RecorderChannelContainer>
            <RecorderChannelLevel
                style={{
                    width: `${100 * (0.5 + (props.channel.level ?? 0) / 2)}%`,
                    height: `${100 * (0.5 + (props.channel.level ?? 0) / 2)}%`,
                }}/>
            <RecorderChannelLabel>{props.channel.number}</RecorderChannelLabel>
        </RecorderChannelContainer>
        {props.channel.status === asod_recorder_audio.AsodRecorderAudioChannelStatus.aracs_active &&
            <MicOutlined fontSize={'small'} color={'success'}/>}
        {props.channel.status === asod_recorder_audio.AsodRecorderAudioChannelStatus.aracs_low_volume &&
            <MicOutlined fontSize={'small'} color={'warning'}/>}
        {props.channel.status === asod_recorder_audio.AsodRecorderAudioChannelStatus.aracs_muted &&
            <WarningOutlined fontSize={'small'} color={'error'}/>}
    </Frame>
}

const RecorderChannelContainer = styled.div`
  position: relative;
  ${CssFlexCenter};
  width: ${D.unit(ProjectDimens.headerHeight / 2)};
  height: ${D.unit(ProjectDimens.headerHeight / 2)};
  border-radius: 50%;
  border: 1px solid ${({theme}) => theme.palette.shades.outline};
`;

const RecorderChannelLevel = styled.div`
  position: absolute;
  background-color: white;
  border-radius: 50%;
  z-index: 1;
  transition: all 0.2s ease-in-out;
`;

const RecorderChannelLabel = styled(Typography)`
  ${CssFlexCenter};
  font-weight: bold;
  font-size: 12px;
  background-color: ${({theme}) => theme.palette.background.default};
  width: 50%;
  height: 50%;
  border-radius: 50%;
  z-index: 2;
`;