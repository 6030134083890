import {autorun, makeAutoObservable, ObservableSet} from "mobx";
import {DisposeUtils} from "../utils/DisposeUtils";
import {asod_audio, common} from "../proto/compiled";
import {MobxUtils} from "../utils/MobxUtils";

interface AudioPlayerStoreListeners {
    onEnded?: () => void;
    onPlayerTime?: (date: Date) => void;
}

interface AudioPlayerListeners {
    onEnded?: () => void;
}

export class AudioPlayerStore {
    private disposeUtils = new DisposeUtils();
    private audio: asod_audio.IAsodAudio;
    private listeners?: AudioPlayerStoreListeners;
    private players: AudioPlayer[] = [];
    private playersMap = new Map<string | undefined, AudioPlayer>();

    private isRecording = false;
    private recordingChunkId?: string;

    private lastPlayingChunkId?: string;

    constructor(audio: asod_audio.IAsodAudio, listeners?: AudioPlayerStoreListeners) {
        (window as any)['audios'] = this;
        this.audio = audio;
        this.listeners = listeners;

        this.disposeUtils.add(autorun(() => {
            const chunks = this.audio.chunks;
            if (!chunks?.length) return;
            if (this.players.length < (this.audio.chunks?.length ?? 0)) {
                for (const chunk of this.audio.chunks ?? []) {
                    this.getPlayer(chunk);
                }
            }
        }));
        if (listeners?.onPlayerTime) {
            let initialized = false;
            this.disposeUtils.add(autorun(() => {
                const ct = this.currentTimeAbsolute;
                if (!ct || !initialized) {
                    initialized = true;
                    return;
                }
                listeners.onPlayerTime!(ct);
            }));
        }
        makeAutoObservable(this);
    }

    dispose() {
        this.disposeUtils.dispose();
        for (const player of this.players) {
            player.dispose();
        }
        this.players = [];
    }

    private getPlayer(chunk: asod_audio.IAsodAudioChunk) {
        if (!this.playersMap.has(chunk.id!)) {
            const player = new AudioPlayer({
                id: chunk.id!,
                src: chunk.src!?.replace('asod.t3st.si:10001', 'asodint.t3st.si'),
                nChannels: this.audio.nChannels!,
                durationS: (((chunk.timestampEnd as number | undefined) || Date.now()) - (chunk.timestampStart as number)) / 1000,
            }, {
                onEnded: () => this.onAudioChunkEnded(chunk.id!),
            }, this.players[0]);
            this.playersMap.set(chunk.id!, player);
            this.players.push(player);
        }
        return this.playersMap.get(chunk.id!)!;
    }

    private onAudioChunkEnded(id: string) {
        if (this.players[this.players.length - 1].id === id) {
            this.listeners?.onEnded?.();
            return;
        } else {
            const index = this.players.findIndex((p) => p.id === id);
            if (index < 0) return;
            const nextPlayer = this.players[index + 1];
            if (nextPlayer.id === this.recordingChunkId) {
                this.refreshRecordingChunk();
            }
            nextPlayer.seek(0);
            nextPlayer.setPlaying(true);
            this.lastPlayingChunkId = nextPlayer.id;
        }
    }

    get isPlaying() {
        return this.players.some((p) => p.isPlaying);
    }

    get durationS() {
        if (!this.audio.chunks?.length) {
            console.log('ERROR: durationS() - chunks are empty');
            return undefined;
        }
        const startTs = this.audio.chunks[0].timestampStart as number | undefined;
        const endTs = this.audio.chunks[this.audio.chunks.length - 1].timestampEnd as number | undefined;
        if (!startTs || !endTs) {
            console.log(`ERROR: durationS() - st: ${startTs}, et: ${endTs}`);
            return undefined;
        }
        return (endTs - startTs) / 1000;
    }

    get progress() {
        const duration = this.durationS;
        if (!duration) return undefined;
        return this.currentTime / duration;
    }

    seek(progress: number) {
        const duration = this.durationS;
        if (!duration) return;
        this.seekSeconds(progress * duration);
    }

    seekDate(date: common.IDate) {
        const ts = date.ts as number | undefined;
        if (!ts) return;
        const ms = ts - (this.audio.chunks?.[0].timestampStart as number);
        this.seekSeconds(ms / 1000);
    }

    seekSeconds(seconds: number) {
        if (!this.audio.chunks?.length) return;
        const duration = this.durationS;
        if (!duration) return;
        if (seconds >= duration) {
            this.players[this.players.length - 1].seek(this.players[this.players.length - 1].audioFile.durationS);
        } else {
            const _seek = (player: AudioPlayer, progressS: number) => {
                player.seek(progressS);
                if (this.lastPlayingChunkId !== player.id) {
                    const lastPlayer = this.playersMap.get(this.lastPlayingChunkId);
                    const wasPlaying = lastPlayer?.isPlaying ?? false;
                    lastPlayer?.setPlaying(false);
                    const nextPlayer = this.playersMap.get(player.id);
                    if (wasPlaying && nextPlayer?.id === this.recordingChunkId) {
                        this.refreshRecordingChunk();
                    }
                    nextPlayer?.setPlaying(wasPlaying);
                }
                this.lastPlayingChunkId = player.id;
            }
            let offsetS = 0;
            for (const player of this.players) {
                if (seconds < offsetS + player.audioFile.durationS) {
                    _seek(player, seconds - offsetS);
                    return;
                }
                offsetS += player.audioFile.durationS;
            }
            const lastPlayer = this.players[this.players.length - 1];
            _seek(lastPlayer, seconds - (offsetS - lastPlayer.audioFile.durationS));
        }
    }

    get currentTime() {
        let offsetS = 0;
        for (const player of this.players) {
            if (player.id === this.lastPlayingChunkId) {
                return offsetS + player.currentTime;
            }
            offsetS += player.audioFile.durationS;
        }
        return 0;
    }

    get currentTimeAbsolute() {
        const ts = this.audio.chunks?.[0]?.timestampStart as number | undefined;
        if (!ts) return undefined;
        return new Date(ts + this.currentTime * 1000);
    }

    setPlaying(play: boolean) {
        if (!this.players.length) return;
        if (!play) {
            this.players.forEach((p) => p.setPlaying(false));
        } else {
            const chunk = this.playersMap.get(this.lastPlayingChunkId) ?? this.players[0];
            if (this.recordingChunkId === chunk.id) {
                this.refreshRecordingChunk();
            }
            chunk.setPlaying(true);
            this.lastPlayingChunkId = chunk.id;
        }
    }

    setRecordingChunk(recordingChunkId?: string) {
        if (this.recordingChunkId === recordingChunkId) return;
        if (this.recordingChunkId) {
            this.refreshRecordingChunk();
        }
        this.recordingChunkId = recordingChunkId;
        this.refreshRecordingChunk();
    }

    refreshChunk(chunkId: string, src?: string) {
        const chunk = this.audio.chunks?.find((c) => c.id === chunkId);
        if (!chunk) return;
        this.getPlayer(chunk).refresh(
            (((chunk.timestampEnd as number | undefined) || Date.now()) - (chunk.timestampStart as number)) / 1000,
            src
        );
    }

    refreshRecordingChunk() {
        if (!this.recordingChunkId) return;
        this.refreshChunk(this.recordingChunkId);
    }

    channelMuted(i: number) {
        return this.players[0]?.channelMuted(i);
    }

    soloChannel(i: number) {
        return this.players[0]?.channelSolo(i);
    }

    channelVolume(i: number) {
        return this.players[0]?.channelVolume(i);
    }

    setChannelVolume(i: number, volume: number) {
        for (const player of this.players) {
            player.setChannelVolume(i, volume);
        }
    }

    toggleMuted(i: number) {
        for (const player of this.players) {
            player.toggleMuted(i);
        }
    }

    toggleSolo(i: number) {
        for (const player of this.players) {
            player.toggleSolo(i);
        }
    }

    channelLoudness(i: number) {
        const player = this.players.find((p) => p.isPlaying);
        return player?.channelLoudness(i);
    }

    playbackRate() {
        return this.players[0]?.playbackRate;
    }
}


class AudioPlayer {
    id?: string;
    audioContext: AudioContext;
    private mediaElement?: HTMLAudioElement | HTMLVideoElement;
    track?: MediaElementAudioSourceNode;
    analyzers: AnalyserNode[] = [];
    analyzersVolData: Float32Array[] = [];
    isPlaying = false;
    currentTime = 0;
    gains: GainNode[] = [];

    // volume props
    volumes: number[] = [];
    mutedChannels = new ObservableSet<number>();
    soloChannel?: number;

    playbackRate = 1;
    audioFile: AudioFile;
    private nChannels?: number;
    private listeners?: AudioPlayerListeners;
    private fastUpdateInterval: NodeJS.Timer;
    private restartPlayback = false;
    private loudness: number[] = [];
    private playFuture: Promise<void> | undefined;

    constructor(audioFile: AudioFile, listeners?: AudioPlayerListeners, defaults?: AudioPlayer) {
        this.audioContext = new AudioContext();
        this.listeners = listeners;
        makeAutoObservable(this);

        if (defaults) {
            this.volumes = defaults.volumes;
            this.mutedChannels = defaults.mutedChannels;
            this.soloChannel = defaults.soloChannel;
        }

        // set audio file
        this.id = audioFile?.id;
        this.audioFile = audioFile;
        const me = this.createMediaElement();
        me.src = this.audioFile.src;
        if (!this.track || this.audioFile.nChannels !== this.nChannels) {
            this.createTrack();
        }

        this.fastUpdateInterval = setInterval(() => {
            if (!this.isPlaying) return;
            const ct = this.mediaElement?.currentTime ?? 0;
            if (!this.mediaElement?.paused && ct !== this.currentTime) {
                this.currentTime = ct;
            }
            this.loudness = this.analyzers.map((_, i) => this.calculateChannelLoudness(i));
        }, 100);
    }

    refresh(durationS: number, src?: string) {
        if (!this.mediaElement) {
            throw new Error("No media element");
        }
        this.audioFile.src = src ?? this.audioFile.src;
        this.audioFile.durationS = durationS;
        this.mediaElement.src = (src ?? this.audioFile.src) + '?d=' + durationS;
        this.mediaElement.currentTime = this.currentTime;
        this.restartPlayback = true;
    }

    createMediaElement() {
        this.disposeMediaElement();
        this.mediaElement = document.createElement('audio');
        this.mediaElement.playbackRate = this.playbackRate;
        this.mediaElement.crossOrigin = "anonymous";

        this.mediaElement.oncanplay = () => {
            if (this.isPlaying && this.restartPlayback) {
                this._play();
                this.restartPlayback = false;
            }
        }
        this.mediaElement.onended = () => {
            this.listeners?.onEnded?.();
        }
        this.mediaElement.ondurationchange = () => {
            if (this.mediaElement?.paused) return;
            this.currentTime = this.mediaElement?.currentTime ?? 0;
        };

        this.mediaElement.onseeking = () => {
            if (this.mediaElement?.paused) return;
            // this.currentTime = this.mediaElement?.currentTime ?? 0;
        };

        this.mediaElement.onpause = () => {
            this.isPlaying = false;
            if (this.mediaElement?.paused) return;
            this.currentTime = this.mediaElement?.currentTime ?? 0;
        };

        return this.mediaElement!;
    }

    disposeMediaElement() {
        const me = this.mediaElement;
        if (!me) return;
        me.ondurationchange = null;
        me.onseeking = null;
        me.onpause = null;
        me.src = "";
    }

    channelMuted(index: number): boolean {
        return (
            (this.soloChannel !== undefined && this.soloChannel !== index) ||
            (this.soloChannel === undefined && this.mutedChannels.has(index))
        );
    }

    channelSolo(index: number): boolean {
        return this.soloChannel === index;
    }

    dispose() {
        this.disposeMediaElement();
        try {
            this.audioContext.close();
        } catch (e) {
            console.error(e);
        }
    }

    setPlaying(play: boolean) {
        if (!this.audioFile) {
            throw new Error("No audio file");
        }
        if (play === this.isPlaying || !this.mediaElement) return;
        if (this.audioContext.state === "suspended") {
            this.audioContext.resume();
        }
        if (!this.track) {
            this.createTrack();
        }
        if (play) {
            this._play();
            this.isPlaying = true;
        } else {
            this.currentTime = this.mediaElement.currentTime;
            this._pause();
            this.isPlaying = false;
        }
    }

    setRate(rate: number) {
        const me = this.mediaElement;
        if (!me) return;
        me.playbackRate = rate;
        this.playbackRate = rate;
    }

    seek(seconds: number) {
        const me = this.mediaElement;
        if (!me) return;
        this.currentTime = seconds;
        me.currentTime = seconds;
    }

    private createTrack() {
        if (!this.audioFile) {
            throw new Error("No audio file");
        }
        const me = this.mediaElement;
        if (!me) {
            throw new Error("No media element");
        }
        if (this.track) {
            this.disposeTrack();
        }

        this.nChannels = this.audioFile.nChannels;
        this.track = this.audioContext.createMediaElementSource(me);
        const splitter = this.audioContext.createChannelSplitter(this.nChannels);
        this.track.connect(splitter);
        const initVolumes = !this.volumes.length;
        this.loudness = [];
        for (let i = 0; i < this.nChannels; i++) {
            const gain = this.audioContext.createGain();
            const analyser = this.audioContext.createAnalyser();
            splitter.connect(analyser, i, 0);
            analyser.connect(gain);
            gain.connect(this.audioContext.destination);
            this.gains.push(gain);
            if (initVolumes) {
                this.volumes.push(1);
            } else {
                gain.gain.value = this.volumes[i];
            }
            this.analyzers.push(analyser);
            this.loudness.push(0);
            this.analyzersVolData.push(new Float32Array(analyser.fftSize));
        }
    }

    private updateMutedSoloGains() {
        for (let i = 0; i < this.gains.length; i++) {
            if (this.channelMuted(i)) {
                this.gains[i].gain.value = 0;
            } else {
                this.gains[i].gain.value =
                    this.soloChannel === undefined ? this.volumes[i] : 1;
            }
        }
    }

    toggleSolo(index: number) {
        if (this.soloChannel === index) {
            this.soloChannel = undefined;
        } else {
            this.soloChannel = index;
        }
        this.updateMutedSoloGains();
    }

    toggleMuted(index: number) {
        if (this.mutedChannels.has(index)) {
            this.mutedChannels.delete(index);
        } else {
            this.mutedChannels.add(index);
        }
        this.updateMutedSoloGains();
    }

    maxLoudness = 0;


    channelLoudness(index: number) {
        return this.loudness[index] ?? 0;
    }

    private calculateChannelLoudness(index: number) {
        this.analyzers[index].getFloatTimeDomainData(this.analyzersVolData[index]);
        let sumSquares = 0.0;
        this.analyzersVolData[index].forEach((a) => (sumSquares += a * a));
        const loudness = Math.sqrt(
            sumSquares / this.analyzersVolData[index].length
        );
        if (this.maxLoudness < loudness) this.maxLoudness = loudness;
        if (loudness === 0) return 0;
        return loudness / this.maxLoudness;
    }

    setChannelVolume(index: number, v: number) {
        if (this.soloChannel !== undefined) return;
        this.volumes[index] = v;
        this.gains[index].gain.value = v;
    }

    channelVolume(index: number) {
        if (this.soloChannel !== undefined)
            return this.soloChannel === index ? 1 : 0;
        return this.volumes[index];
    }

    private disposeTrack() {
        this.track?.disconnect();
        this.track = undefined;

        this.gains.map((g) => g.disconnect());
        this.gains = [];

        this.analyzers.map((a) => a.disconnect());
        this.analyzers = [];

        this.analyzersVolData = [];

        clearInterval(this.fastUpdateInterval);
    }

    private async _pause() {
        await this.playFuture;
        this.mediaElement?.pause();
    }

    private async _play() {
        await this.playFuture;
        this.playFuture = this.mediaElement?.play();
    }
}

interface AudioFile {
    id: string;
    src: string;
    nChannels: number;
    durationS: number;
}