import {observer} from "mobx-react-lite";
import React, {useContext, useMemo, useState} from "react";
import {ProjectStoreContext} from "../../../../stores/ProjectStore";
import {Frame} from "../../../elementary/Frame";
import {Dialog, IconButton, TextField, Typography, useTheme} from "@mui/material";
import {asod_members} from "../../../../proto/compiled";
import {D} from "../../../../app/constants";
import {ArrowForwardIos, MicOutlined, Public, PublicOff} from "@mui/icons-material";
import {MobxUtils} from "../../../../utils/MobxUtils";
import {LoadingOverlay} from "../../../elementary/LoadingOverlay";
import styled from "@emotion/styled";
import {CssFlexCenter} from "../../../base/flex";
import useWave from "use-wave";
import {EditProjectMemberDialog} from "../../../dialogs/EditProjectMemberDialog";
import {DropdownAnything} from "../../../common/DropdownAnything";
import {DialogContainer} from "../../../elementary/Containers";
import {SoundChannelPicker} from "../../../inputs/SoundChannelPicker";
import {AppContextStoreContext} from "../../../../stores/AppContextStore";
import {ProjectParticipantsSearchAndAdd} from "./ProjectParticipantsSearchAndAdd";
import {WebserviceContextStoreContext} from "../../../../webservice/stores/WebserviceContextStore";

interface ProjectMembersProps {
    members: asod_members.IAsodMember[];
    onChange?: (m: asod_members.IAsodMember) => any;
    onAdd?: (m: asod_members.IAsodMember) => any;
}

export const ProjectMembers = observer((props: ProjectMembersProps) => {
    const theme = useTheme();
    const [editingMember, setEditingMember] = useState<number | undefined>(undefined);
    const features = useContext(AppContextStoreContext)!.features;

    return <Frame radius={1} background={theme.palette.background.default} padding={1} margin={-1} marginTop={-0.5}
                  style={{
                      border: theme.palette.mode === 'dark' ? undefined : `solid 1px ${theme.palette.divider}`
                  }}
    >
        {features.editSession && <ProjectParticipantsSearchAndAdd onAdd={(m) => props.onAdd?.(m)}/>}
        {!features.editSession && <Typography style={{marginLeft: D.unit()}} variant={'h6'}>Udeleženci</Typography>}
        <Frame alignItems={'center'} marginTop={1}>
            <ProjectMembersList
                onChange={(m) => props.onChange?.(m)}
                onClick={(m, i) => {
                    setEditingMember(i);
                }}
                members={props.members}/>
        </Frame>
        {features.editSession && <Dialog open={editingMember !== undefined} onClose={() => setEditingMember(undefined)}>
            <EditProjectMemberDialog
                member={props.members[editingMember ?? -1]}
                onSave={(m) => {
                    props.members[editingMember!] = m;
                    props.onChange?.(m);
                    setEditingMember(undefined);
                }}
                onClose={() => {
                    setEditingMember(undefined);
                }}/>
        </Dialog>}
    </Frame>;
});

export const ProjectMembersList = observer((props: {
    members: asod_members.IAsodMember[],
    onClick: (m: asod_members.IAsodMember, i: number) => any,
    onChange: (m: asod_members.IAsodMember) => Promise<boolean>
}) => {
    const context = useContext(AppContextStoreContext)!;
    if (props.members.length === 0 && !context.isRecorder) return <>Ni udeležencev</>;

    return <Frame alignItems={'stretch'} fill gap={0.5}>
        {context.isRecorder && <Member
            self={true}
            member={{}}
        />}
        {props.members.map((member, i) => <Member
            key={i}
            onClick={() => props.onClick(member, i)}
            onChange={() => {
                return props.onChange(member);
            }}
            member={member}/>)}
    </Frame>
});

const Member = observer((props: {
    member: asod_members.IAsodMember,
    self?: boolean,
    onChange?: () => Promise<boolean>,
    onClick?: () => any
}) => {
    useMemo(() => {
        props.member.projectMeta ??= asod_members.AsodProjectMemberMeta.create({});
        MobxUtils.makeObservable(props.member.projectMeta);
    }, [props.member]);
    const [loading, setLoading] = useState(false);
    const context = useContext(AppContextStoreContext)!;
    const features = useContext(AppContextStoreContext)!.features;

    const theme = useTheme();
    return <Frame
        radius={1}
        clip
        style={{
            padding: `${D.unit(0.5)} ${D.unit(1)}`,
            border: theme.palette.mode === 'dark' ? undefined : `solid 1px ${theme.palette.divider}`,
        }}
        direction={'row'}
        alignItems={'center'}
        background={theme.palette.background.paper}>
        <Typography variant={"button"} style={{flexGrow: 1, flexBasis: D.unit(22), marginRight: D.unit()}}>
            {props.self ? (context.auth.userName ?? 'Trenutni uporabnik') : props.member.name}
        </Typography>
        <Typography variant={'body2'} style={{
            marginRight: props.self ? D.unit(0.5) : undefined,
            textAlign: 'right',
            fontSize: '10px',
        }}>
            {(props.self ? 'ZAPISNIKAR' : props.member.role?.name)?.toUpperCase()}
        </Typography>
        {!props.self && <>
            <MemberChannelButton nChannels={8} channel={props.member.projectMeta?.audioChannel}
                                 onChange={features.editSession ? async (c) => {
                                     const prevChannel = props.member.projectMeta!.audioChannel;
                                     props.member.projectMeta!.audioChannel = c;
                                     setLoading(true);
                                     if (!(await props.onChange?.())) props.member.projectMeta!.audioChannel = prevChannel;
                                     setLoading(false);
                                 } : undefined}/>
            {features.editSession && <IconButton
                onPointerDownCapture={(e) => e.stopPropagation()}
                onClick={async (e) => {
                    e.stopPropagation();
                    props.member.projectMeta!.accessGranted = !props.member.projectMeta?.accessGranted;
                    setLoading(true);
                    if (!(await props.onChange?.())) props.member.projectMeta!.accessGranted = !props.member.projectMeta?.accessGranted;
                    setLoading(false);
                }}
                style={{margin: `${D.unit(-0.5)} 0`, marginLeft: D.unit(-1)}}>
                {props.member.projectMeta?.accessGranted ?
                    <Public fontSize={"small"}/> :
                    <PublicOff fontSize={"small"}/>}
            </IconButton>}
            {features.editSession && <IconButton onClick={() => props.onClick?.()}
                        style={{margin: `${D.unit(-0.5)} ${D.unit(-1)}`, marginLeft: D.unit(-1.5)}}>
                <ArrowForwardIos fontSize={"small"}/>
            </IconButton>}
            <LoadingOverlay onPointerDownCapture={(e) => loading ? e.stopPropagation() : undefined} visible={loading}/>
        </>}
    </Frame>;
});

const MemberChannelButton = (props: { channel?: number | null, nChannels: number, onChange?: (c?: number) => any }) => {
    if (!props.onChange) {
        return <IconButton
            style={{margin: `${D.unit(-0.5)} 0`, pointerEvents: 'none'}}>
            <MemberChannelButtonContainer selected={true}>
                {!!props.channel && props.channel}
            </MemberChannelButtonContainer>
        </IconButton>;
    }

    return <DropdownAnything
        barrierDismissible={true}
        triggerBuilder={(ref, isOpen, setIsOpen) => <IconButton
            ref={ref}
            onPointerDownCapture={(e) => e.stopPropagation()}
            onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setIsOpen(!isOpen);
            }}
            style={{margin: `${D.unit(-0.5)} 0`}}>
            <MemberChannelButtonContainer selected={!!props.channel}>
                {!!props.channel ? props.channel :
                    <MicOutlined style={{color: 'white', fontSize: '14px'}}/>}
            </MemberChannelButtonContainer>
        </IconButton>} builder={(_, close) => <DialogContainer style={{width: '400px'}}>
        <SoundChannelPicker style={{flexGrow: '1'}} selected={props.channel ?? undefined}
                            onChange={(ch) => {
                                props.onChange!(ch);
                                close();
                            }}
                            nChannels={props.nChannels}/>
    </DialogContainer>}/>
}

const MemberChannelButtonContainer = styled.div<{ selected: boolean }>`
  ${CssFlexCenter};
  aspect-ratio: 1;
  width: 16px;
  height: 16px;
  background-color: ${({theme, selected}) => selected ? undefined : theme.palette.secondary.main};
  border: solid 2px ${({theme, selected}) => selected ? theme.palette.primary.main : 'transparent'};
  border-radius: 100%;
  font-size: 14px;
  font-weight: bold;
`;
