import {observer} from "mobx-react-lite";
import React, {useContext, useMemo} from "react";
import {Frame} from "../elementary/Frame";
import {TextField, Typography, useTheme} from "@mui/material";
import {D} from "../../app/constants";
import {AppContextStoreContext} from "../../stores/AppContextStore";
import {ButtonFilled, ButtonOutline} from "../elementary/Buttons";
import {DialogFooter} from "../layouts/DialogFooter";
import {asod_members} from "../../proto/compiled";
import useAutoObservable from "../../hooks/use_auto_observable";
import {Public, PublicOff} from "@mui/icons-material";
import {SoundChannelPicker} from "../inputs/SoundChannelPicker";
import {SelectInput} from "../inputs/SelectInput";
import {Validators} from "../../validation/validator";
import {TaxIdValidator} from "../../validation/validators/TaxIdValidator";
import {RoleValidator} from "../../validation/validators/RoleValidator";
import {toast} from "react-toastify";
import {NotEmptyValidator} from "../../validation/validators/NotEmptyValidator";

interface EditProjectMemberDialogProps {
    add?: boolean;
    onClose: () => any;
    onSave: (m: asod_members.IAsodMember) => any;
    member?: asod_members.IAsodMember;
}

export const EditProjectMemberDialog = observer((props: EditProjectMemberDialogProps) => {
    useMemo(() => {
        if (!props.member) return;
        props.member.role ??= asod_members.AsodMemberRole.create();
        props.member.projectMeta ??= asod_members.AsodProjectMemberMeta.create();
    }, [props.member]);
    const member: asod_members.IAsodMember = useMemo(() => asod_members.AsodMember.fromObject(asod_members.AsodMember.create(props.member).toJSON()), [props.member]);
    useAutoObservable(member, member?.role, member?.projectMeta);
    const theme = useTheme();
    const context = useContext(AppContextStoreContext)!;

    const validators = useMemo(() => new Validators(), []);
    const taxIdValidator = useMemo(() => validators.add(new TaxIdValidator(member?.taxId ?? undefined, true)), [validators]);
    const nameValidator = useMemo(() => validators.add(new NotEmptyValidator(member?.name ?? undefined)), [validators]);
    const roleValidator = useMemo(() => validators.add(new RoleValidator(member?.role ?? undefined)), [validators]);

    if (!member) return null;
    return <Frame background={theme.palette.background.default} style={{width: D.unit(60), minHeight: '80vh'}}
                  gap={2} padding={4}>
        <Typography variant={'h5'}>{props.add ? 'Dodaj' : 'Uredi'} udeleženca</Typography>
        <TextField
            label={'Ime'}
            value={member.name}
            onChange={(e) => member.name = nameValidator.onChanged(e.target.value)}
            error={!!nameValidator.error}
            helperText={nameValidator.error}
        />
        <TextField
            label={'Davčna številka'}
            disabled={!props.add}
            value={member.taxId}
            onChange={(e) => member.taxId = taxIdValidator.onChanged(e.target.value)}
            error={!!taxIdValidator.error}
            helperText={taxIdValidator.error}
        />
        <SelectInput
            onChange={(r) => {
                member.role ??= {};
                member.role.id = r.id;
                member.role.name = r.label;
                roleValidator.onChanged(r);
            }}
            value={{
                id: member.role?.id ?? undefined,
                label: member.role?.name ?? undefined,
            }}
            options={Object.values(context.constants.roles).map((r) => ({
                id: r.id ?? undefined,
                label: r.title ?? undefined,
            }))}
            textField={{
                label: 'Vloga',
                value: member.role?.name,
                error: !!roleValidator.error,
                helperText: roleValidator.error,
                onChange: (e) => member.role!.name = e.target.value,
            }}/>
        <ButtonOutline onClick={() => {
            member.projectMeta!.accessGranted = !member.projectMeta?.accessGranted;
        }}>
            {member.projectMeta?.accessGranted && <><Public/> Dostop preko javnega servisa je dovoljen</>}
            {!member.projectMeta?.accessGranted && <><PublicOff/> Dostop preko javnega servisa ni dovoljen</>}
        </ButtonOutline>
        <Typography style={{marginLeft: D.unit(2), marginTop: D.unit(-1), marginBottom: D.unit(-2)}} variant={'button'}>Zvočni
            kanal</Typography>
        <SoundChannelPicker nChannels={8} onChange={(ch) => member!.projectMeta!.audioChannel = ch}
                            selected={member.projectMeta?.audioChannel ?? 0}/>
        <DialogFooter bottom>
            <ButtonOutline center onClick={() => {
                props.onClose();
            }}>
                PREKLIČI
            </ButtonOutline>
            <ButtonFilled center onClick={() => {
                if (!validators.isValid()) {
                    toast.error('Polja niso izpolnjena pravilno.');
                    return;
                }
                props.onSave(member);
            }}>
                SHRANI
            </ButtonFilled>
        </DialogFooter>
    </Frame>
});