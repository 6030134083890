import styled from "@emotion/styled";
import {Typography} from "@mui/material";
import InsertDriveFile from "@mui/icons-material/InsertDriveFile";
import {CSSProperties, useEffect, useState} from "react";
import {uploader} from "proto_socket_typescript/lib/proto/compiled";
import {D} from "../../app/constants";

export const ProtoUFile = (props: { file?: uploader.IUFile }) => {
    return <Container>
        <UFileThumbnail src={props.file?.preview ?? ''}/>v
        <Typography fontWeight={"bold"}>
            {props.file?.name}
        </Typography>
    </Container>;
}

export const UFileThumbnail = (props: { src?: string, style?: CSSProperties }) => {
    const hasPreview = !!props.src?.length;
    const [thumbError, setThumbError] = useState(!hasPreview);
    useEffect(() => setThumbError(!hasPreview), [hasPreview]);
    if (!thumbError) {
        return <Img style={props.style}
                    src={props.src}
                    onError={() => setThumbError(true)}/>;
    }
    return <Placeholder style={props.style}>
        <InsertDriveFile fontSize={"large"} style={{marginRight: D.unit()}}/>
    </Placeholder>;
}

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Img = styled.img`
  height: calc(100% - ${D.unit()});
  aspect-ratio: 1;
  object-fit: contain;
  background-color: white;
  margin-left: ${D.unit(0.5)};
  margin-right: ${D.unit(2)};
  border-radius: ${D.radius()};
`;

const Placeholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - ${D.unit()});
  aspect-ratio: 1;
  object-fit: contain;
  background-color: white;
  margin-left: ${D.unit(0.5)};
  margin-right: ${D.unit(2)};
  border-radius: ${D.radius()};
`;