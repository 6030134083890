import {observer} from "mobx-react-lite";
import React, {useContext, useEffect, useMemo} from "react";
import {Frame} from "../elementary/Frame";
import {CircularProgress, TextField, Typography, useTheme} from "@mui/material";
import {D} from "../../app/constants";
import {AppContextStoreContext} from "../../stores/AppContextStore";
import {ButtonFilled, ButtonOutline} from "../elementary/Buttons";
import {DialogFooter} from "../layouts/DialogFooter";
import {LoadingOverlay} from "../elementary/LoadingOverlay";
import {DoneAll} from "@mui/icons-material";

interface SignatureVerificationDialogProps {
    onClose: () => any;
}

export const SignatureVerificationDialog = observer((props: SignatureVerificationDialogProps) => {
    const theme = useTheme();
    const context = useContext(AppContextStoreContext)!;
    const [loading, setLoading] = React.useState(true);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);


    return <Frame background={theme.palette.background.default} style={{width: D.unit(60), minHeight: D.unit(50)}}
                  gap={2} padding={4}>
        <Typography variant={'h5'}>Preverjanje podpisov</Typography>
        <Frame grow center>
            <Frame row gap={2} alignItems={'center'}>
                {!loading && <><DoneAll fontSize={'large'}/> <Typography variant={'h6'}>Vsi podpisi so
                    veljavni.</Typography></>}
                {loading && <CircularProgress/>}
            </Frame>
        </Frame>
        <DialogFooter bottom>
            <ButtonFilled center onClick={() => {
                props.onClose();
            }}>
                ZAPRI
            </ButtonFilled>
        </DialogFooter>
    </Frame>
});