import {observer} from "mobx-react-lite";
import {Dialog} from "@mui/material";
import {SignatureVerificationDialog} from "../components/dialogs/SignatureVerificationDialog";

interface SignatureVerificationProps {
    onClose: () => any;
    isOpen: boolean;
}

export const SignatureVerification = observer((props: SignatureVerificationProps) => {
    return <Dialog open={props.isOpen} onClose={() => props.onClose()}>
        <SignatureVerificationDialog onClose={() => props.onClose()}/>
    </Dialog>
});