import {observer} from "mobx-react-lite";
import {SidebarLayout} from "../../components/layouts/SidebarLayout";
import React, {useContext, useEffect, useMemo} from "react";
import {AppContextStoreContext} from "../../stores/AppContextStore";
import {ManagementStore, ManagementStoreContext} from "../stores/ManagementStore";
import {ManagementContent} from "../../components/pages/management/ManagementContent";
import {ManagementSideBar} from "../../components/pages/management/ManagementSideBar";

let store: ManagementStore;

export const ManagementPage = observer(() => {
    const context = useContext(AppContextStoreContext)!;
    store = useMemo(() => store ?? new ManagementStore(context), [context]);
    return <ManagementStoreContext.Provider value={store}>
        <SidebarLayout>
            <ManagementSideBar/>
            <ManagementContent/>
        </SidebarLayout>
    </ManagementStoreContext.Provider>;
});