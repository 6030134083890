import styled from "@emotion/styled";
import React, {CSSProperties} from "react";
import {Card} from "@mui/material";
import {CardProps} from "@mui/material/Card/Card";

interface DialogContainerProps extends CardProps {
    style?: CSSProperties;
}

export const DialogContainer = (props: DialogContainerProps) => <Card elevation={8}
                                                                      {...props}
                                                                      style={{
                                                                          zIndex: 1000,
                                                                          overflowY: 'auto',
                                                                          display: 'flex',
                                                                          ...props.style,
                                                                      }}>
    {props.children}
</Card>;