import React from "react";
import {AppContextStore} from "../../stores/AppContextStore";
import {proto} from "../../proto/messages";
import {common, ws_users} from "../../proto/compiled";
import {ErrorUtils} from "../../utils/ErrorUtils";
import {makeAutoObservable} from "mobx";
import {ProtoTableStore} from "../../stores/ProtoTableStore";
import {Globals} from "../../index";
import {DummyAsodUsers} from "../../testing/data/DummyAsodUsers";
import {UsersTable} from "../../components/tables/UsersTable";
import {DataTableData} from "../../components/layouts/DataTable";
import {DisposeUtils} from "../../utils/DisposeUtils";

export const UsersStoreContext = React.createContext<UsersStore | undefined>(undefined);

export class UsersStore {
    app: AppContextStore;
    private disposeUtils = new DisposeUtils();
    loadData = ws_users.LoadAsodUsers.create({
        pagination: common.Pagination.create({
            page: 1,
            pageSize: 10,
        }),
    });
    users?: ws_users.AsodUsers;
    private loading = 0;
    table: ProtoTableStore;

    constructor(app: AppContextStore) {
        this.app = app;
        this.table = new ProtoTableStore(this.app, new UsersTable(), true);
        this.disposeUtils.add(
            this.app.api.getMessageHandler(new proto.RxAsodUsers()).subscribe((e) => this.onAsodUsers(e))
        )
        this.load();
        makeAutoObservable(this);

        if (Globals.useDummyData) {
            const users = new proto.RxAsodUsers();
            users.proto = DummyAsodUsers;
            this.onAsodUsers(users);
        }
    }

    dispose() {
        this.disposeUtils.dispose();
        this.table.dispose();
    }

    private onAsodUsers(e: proto.RxAsodUsers) {
        this.users = e.proto;
        this.table.dispose();
        this.table = new ProtoTableStore(this.app, new UsersTable(this.users), true);
    }

    get isLoading() {
        return this.loading > 0;
    }

    async load() {
        this.loading++;
        const response = await this.app.api.sendMessage(
            proto.TxLoadAsodUsers.create(this.loadData),
            {ack: true}
        );
        ErrorUtils.response(response);
        this.loading--;
    }
}
