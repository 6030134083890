import {observer} from "mobx-react-lite";
import {AppContextStoreContext} from "../../stores/AppContextStore";
import React, {useContext} from "react";
import styled from "@emotion/styled";
import {Frame} from "../elementary/Frame";
import {ButtonFilled, ButtonOutline} from "../elementary/Buttons";
import {DialogFooter} from "../layouts/DialogFooter";
import {Dialog} from "@mui/material";
import {D} from "../../app/constants";

export const SsoDialog = observer(() => {
    const context = useContext(AppContextStoreContext)!;
    return <Dialog maxWidth={false} open={!!context.auth.ssoUrl} onClose={() => context.auth.ssoUrl = undefined}>
        <Frame padding={4} gap={4} width={'calc(100vw - 80px)'} height={'calc(100vh - 80px)'}>
            {!!context.auth.ssoUrl && <Iframe
                src={context.auth.ssoUrl}
            />}
            <DialogFooter>
                <ButtonOutline onClick={() => context.auth.ssoUrl = undefined}>
                    ZAPRI
                </ButtonOutline>
            </DialogFooter>
        </Frame>
    </Dialog>;
});

const Iframe = styled.iframe`
  background-color: transparent;
  margin: ${D.unit(-4)};
  flex-grow: 1;
  border: 0;
  outline: none;
`;