import {Observer, observer} from "mobx-react-lite";
import {Frame} from "../components/elementary/Frame";
import {ArrowDropDown, ExpandMore, Logout, PersonOutlined, SettingsOutlined} from "@mui/icons-material";
import {ButtonOutline} from "../components/elementary/Buttons";
import React, {useContext} from "react";
import {AppContextStoreContext} from "../stores/AppContextStore";
import {Card, Typography, useTheme} from "@mui/material";
import {DropdownAnything} from "../components/common/DropdownAnything";
import {SelectOption} from "../components/elementary/SelectOption";
import {D} from "../app/constants";
import useWave from "use-wave";
import {useNavigate} from "react-router-dom";

interface AsodAppBarProps {
    children?: any;
    actions?: any;
}

export const AsodAppBar = observer((props: AsodAppBarProps) => {
    const context = useContext(AppContextStoreContext);
    const wave = useWave();
    const store = useContext(AppContextStoreContext)!;
    const navigate = useNavigate();
    return <Frame row alignItems={'center'} gap={2}>
        {props.children ? props.children : <div style={{flexGrow: 1}}/>}
        {props.actions}
        {context?.auth.loggedIn && <DropdownAnything
            barrierDismissible={true}
            triggerBuilder={(ref, isOpen, setIsOpen) => <Observer>
                {() => <div ref={ref}>
                    <ButtonOutline onClick={() => setIsOpen(!isOpen)}>
                        <PersonOutlined/>
                        <Frame noPointer>
                            <Typography fontWeight={'bold'} lineHeight={1}>{context?.auth.userName}</Typography>
                            <Typography variant={'body2'} lineHeight={1}>{context?.auth.role}</Typography>
                        </Frame>
                        <ExpandMore/>
                    </ButtonOutline>
                </div>}
            </Observer>}
            builder={(marginTop, close) => <Card elevation={8} style={{
                zIndex: 1000,
                marginTop: marginTop + 'px',
                overflowY: 'auto',
                display: 'flex',
                flexDirection: marginTop < 0 ? 'column-reverse' : 'column',
            }}>
                <SelectOption onClick={() => {
                    store.auth.logout();
                }} ref={wave}>
                    <Logout style={{marginRight: D.unit(0.5), marginLeft: D.unit(0.5)}}/>
                    <Typography variant={'button'}>Odjava</Typography>
                </SelectOption>
            </Card>}
            compute={(rect: DOMRect) => {
                if (window.innerHeight - rect.bottom < 300) {
                    return -304 - rect.height;
                } else {
                    return 0;
                }
            }}/>}
    </Frame>
});