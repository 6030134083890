import {observer} from "mobx-react-lite";
import React, {useContext} from "react";
import {D} from "../../../app/constants";
import {Event} from "@mui/icons-material";
import {Frame} from "../../elementary/Frame";
import {Space} from "../../elementary/Space";
import {Logo} from "../../elementary/Logo";
import {ButtonOutline} from "../../elementary/Buttons";
import {Divider, TextField} from "@mui/material";
import {ProjectsStore, ProjectsStoreContext} from "../../../webservice/stores/ProjectsStore";
import useAutoObservable from "../../../hooks/use_auto_observable";
import {DateInput} from "../../inputs/DateInput";

interface ProjectsSideBarProps {

}

export const ProjectsSideBar = observer((props: ProjectsSideBarProps) => {
    const store = useContext(ProjectsStoreContext)!;
    const data = useAutoObservable(store.loadData);
    return <Frame style={{flexGrow: 1}} alignItems={'stretch'} gap={2}>
        <Space/>
        <Logo style={{margin: `${D.unit(2)} auto`}}/>
        <Frame gap={2} style={{flexGrow: 1, flexBasis: 0, overflow: 'auto'}} padding={2}>
            <TextField
                label={'Sodišče'}
                value={data.court}
                onChange={(e) => data.court = e.target.value}
                onKeyDown={(e) => e.key === 'Enter' && store.load()}
                onBlur={() => store.load()}
            />
            <TextField
                label={'Sodna dvorana'}
                value={data.courtRoom}
                onChange={(e) => data.courtRoom = e.target.value}
                onKeyDown={(e) => e.key === 'Enter' && store.load()}
                onBlur={() => store.load()}
            />
            <TextField
                label={'Opravilna številka'}
                value={data.opNumber}
                onChange={(e) => data.opNumber = e.target.value}
                onKeyDown={(e) => e.key === 'Enter' && store.load()}
                onBlur={() => store.load()}
            />
            <DateInput
                label={'Datum - od'}
                value={data.dateFrom!}
                onChanged={() => store.load()}
            />
            <DateInput
                label={'Datum - do'}
                value={data.dateTo!}
                onChanged={() => store.load()}
            />
        </Frame>
    </Frame>;
});