import {observer} from "mobx-react-lite";
import React, {useContext, useMemo, useState} from "react";
import {Frame} from "../../../elementary/Frame";
import {Dialog, IconButton, TextField, Typography, useTheme} from "@mui/material";
import {api_deeplinks, asod_members} from "../../../../proto/compiled";
import {D} from "../../../../app/constants";
import {v4 as uuidv4} from 'uuid';
import {
    Add,
    ArrowForwardIos,
    CopyAll,
    CopyAllOutlined,
    DeleteOutlined,
    MicOutlined,
    Public,
    PublicOff
} from "@mui/icons-material";
import {MobxUtils} from "../../../../utils/MobxUtils";
import {LoadingOverlay} from "../../../elementary/LoadingOverlay";
import styled from "@emotion/styled";
import {CssFlexCenter} from "../../../base/flex";
import {DropdownAnything} from "../../../common/DropdownAnything";
import {DialogContainer} from "../../../elementary/Containers";
import {SoundChannelPicker} from "../../../inputs/SoundChannelPicker";
import {AppContextStoreContext} from "../../../../stores/AppContextStore";
import useAutoObservable from "../../../../hooks/use_auto_observable";
import {DateUtils} from "../../../../utils/DateUtils";
import {toast} from "react-toastify";
import {proto} from "../../../../proto/messages";
import {ErrorUtils} from "../../../../utils/ErrorUtils";
import {ProjectStoreContext} from "../../../../stores/ProjectStore";
import useWave from "use-wave";

interface DeepLinksProps {
    deepLinks: api_deeplinks.IApiDeeplinksSessionDeeplink[];
    onCreated: (dl: api_deeplinks.IApiDeeplinksSessionDeeplink) => any;
}

export const DeepLinks = observer((props: DeepLinksProps) => {
    const theme = useTheme();
    const context = useContext(AppContextStoreContext)!;
    const state = useAutoObservable(useMemo(() => ({
        loading: false,
    }), []));

    return <Frame radius={1} background={theme.palette.background.default} padding={1} margin={-1} marginTop={-0.5}
                  style={{
                      border: theme.palette.mode === 'dark' ? undefined : `solid 1px ${theme.palette.divider}`
                  }}
    >
        <Typography variant={'h6'}>Globoke povezave</Typography>
        <DeepLinkAdd onAdd={async (m) => {
            state.loading = true;
            const response = await context.api.sendMessage(
                proto.TxApiDeeplinksAddDeeplink.create(m),
                {ack: true}
            );
            if (ErrorUtils.response(response)) {
                props.onCreated(m);
            }
            state.loading = false;
        }}/>
        <Frame alignItems={'center'} marginTop={1}>
            <DeepLinksList
                deepLinks={props.deepLinks}/>
        </Frame>
        <LoadingOverlay visible={state.loading}/>
    </Frame>;
});

export const DeepLinkAdd = observer((props: { onAdd: (dl: api_deeplinks.IApiDeeplinksSessionDeeplink) => any }) => {
    const [newDeeplink, setNewDeeplink] = useState<api_deeplinks.ApiDeeplinksSessionDeeplink>(api_deeplinks.ApiDeeplinksSessionDeeplink.create({}));
    const deeplink = useAutoObservable(newDeeplink);
    const theme = useTheme();
    const wave = useWave();
    const project = useContext(ProjectStoreContext)!;
    const lpt = project.audio.lastPlayerTime?.getTime();
    return <Frame row clip>
        <TimestampContainer style={lpt ? undefined : {
            right: 0,
            backgroundColor: theme.palette.background.paper,
            border: 'none'
        }}>
            <Typography variant={"button"} style={{lineHeight: 1, textAlign: 'center'}}>
                {lpt ? DateUtils.toTimeString({ts: lpt}) : 'izberite čas posnetka'}
            </Typography>
        </TimestampContainer>
        <TextField
            style={{
                flexGrow: 1,
            }}
            placeholder={lpt ? 'Ime povezave' : undefined}
            disabled={!lpt}
            value={deeplink.name}
            onChange={(e) => deeplink.name = e.target.value}
            inputProps={{
                style: {
                    marginLeft: D.unit(11),
                    marginRight: D.interactive(),
                }
            }}
        />
        <AddButtonContainer
            ref={wave}
            onClick={lpt ? () => {
                newDeeplink.sessionId = project.projectId!;
                newDeeplink.segmentStart = lpt;
                newDeeplink.createdBy = project.context.auth.userName ?? '';
                newDeeplink.createdAt = Date.now();
                newDeeplink.deeplinkId = uuidv4();
                newDeeplink.url = 'https://google.com';
                props.onAdd(newDeeplink);
                setNewDeeplink(api_deeplinks.ApiDeeplinksSessionDeeplink.create({}));
            } : undefined}
            style={{
                marginRight: lpt ? 0 : D.interactive(-1)
            }}>
            <Add/>
        </AddButtonContainer>
    </Frame>;
});

export const DeepLinksList = observer((props: {
    deepLinks: api_deeplinks.IApiDeeplinksSessionDeeplink[],
}) => {
    const state = useAutoObservable(useMemo(() => ({
        loading: false,
    }), []));
    const context = useContext(AppContextStoreContext)!;
    if (props.deepLinks.length === 0) return <>Ni globokih povezav</>;

    return <Frame alignItems={'stretch'} fill gap={0.5}>
        {props.deepLinks.map((deepLink, i) => <DeepLink
            key={i}
            deepLink={deepLink}
            onDelete={async () => {
                state.loading = true;
                ErrorUtils.response(await context.api.sendMessage(proto.TxApiDeeplinksRemoveDeeplink.create({
                    deeplinkId: deepLink.deeplinkId,
                }), {ack: true}));
                state.loading = false;
            }}
        />)}
        <LoadingOverlay visible={state.loading}/>
    </Frame>
});

const DeepLink = observer((props: {
    deepLink: api_deeplinks.IApiDeeplinksSessionDeeplink
    onDelete?: () => any,
}) => {
    const theme = useTheme();
    return <Frame
        radius={1}
        clip
        style={{
            padding: `${D.unit(0.5)} ${D.unit(0)}`,
            border: theme.palette.mode === 'dark' ? undefined : `solid 1px ${theme.palette.divider}`,
        }}
        background={theme.palette.background.paper}>
        <Frame row alignItems={'center'}>
            <Typography
                variant={"button"}
                fontWeight={"normal"}
                style={{
                    width: D.unit(10),
                    marginRight: D.unit(1),
                    textAlign: 'center',
                    borderRight: `solid 1px ${theme.palette.divider}`,
                }}>
                {DateUtils.toTimeString({ts: props.deepLink.segmentStart})}
            </Typography>
            <Typography variant={"button"} style={{flexGrow: 1, marginRight: D.unit()}}>
                {props.deepLink.name}
            </Typography>
            <IconButton
                color={'primary'}
                onPointerDownCapture={(e) => e.stopPropagation()}
                onClick={() => {
                    const url = props.deepLink.url;
                    if (url) {
                        navigator.clipboard.writeText(url);
                    }
                    toast.success('Povezava je bila skopirana v odložišče');
                }}
                style={{margin: `${D.unit(-0.5)} 0`}}>
                <CopyAllOutlined/>
            </IconButton>
            <IconButton
                color={'primary'}
                onClick={() => props.onDelete?.()}
                style={{margin: `${D.unit(-0.5)} ${D.unit(0)}`, marginLeft: D.unit(-1.5)}}>
                <DeleteOutlined/>
            </IconButton>
        </Frame>
        {!!props.deepLink.createdAt && !!props.deepLink.createdBy?.length && <Typography
            fontWeight={"bold"}
            color={theme.palette.text.secondary}
            variant={"caption"} style={{margin: `-2px ${D.unit()}`}} lineHeight={1}>
            dodano {DateUtils.toDateString({ts: props.deepLink.createdAt})}, {props.deepLink.createdBy}
        </Typography>}
    </Frame>;
});

const TimestampContainer = styled.div`
  ${CssFlexCenter};
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: calc(100% - ${D.unit(11)});
  border-right: solid 1px ${({theme}) => theme.palette.divider};
  transition: all 0.3s;
`;

const AddButtonContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  ${CssFlexCenter};
  aspect-ratio: 1;
  height: 100%;
  color: ${({theme}) => theme.palette.background.paper};
  background-color: ${({theme}) => theme.palette.primary.main};
  border-radius: 0 ${D.radius()} ${D.radius()} 0;
  z-index: 2;
  transition: all 0.3s;
  cursor: pointer;
`;