import {observer} from "mobx-react-lite";
import {useMeasure} from "../../hooks/use_measure";
import {CSSProperties, useMemo} from "react";
import styled from "@emotion/styled";
import {MathUtils} from "../../utils/MathUtils";

interface WaveformProps {
    waveform?: number[]
    gap: number;
    thickness: number;
    onSeek?: (progress: number) => void;
    children?: any;
    style?: CSSProperties;
    tickPadding?: number
}

export const Waveform = observer((props: WaveformProps) => {
    const {ref, size} = useMeasure();

    const nTicks = useMemo(() => {
        return Math.floor((size?.width ?? 0) / (props.gap + props.thickness)) + 1;
    }, [size, props.gap, props.thickness]);

    const waveform = useMemo(() => {
        const waveform = props.waveform?.slice();
        if (!waveform) return Array(nTicks).fill(0);
        const result = [];
        // fill based on props.waveform (linearly interpolate)
        for (let i = 1; i < nTicks; i++) {
            const i0 = Math.floor(waveform.length * (i - 1) / nTicks);
            const i1 = Math.floor(waveform.length * i / nTicks);
            let tick;
            if (i0 === i1) {
                tick = waveform[i0];
            } else {
                tick = MathUtils.mean(waveform.slice(i0, i1));
            }
            result.push(tick);
        }
        // normalize result
        const max = Math.max(...result);
        if (max > 0) {
            for (let i = 0; i < result.length; i++) {
                result[i] /= max;
            }
        }
        return result;
    }, [nTicks, props.waveform]);

    const ticks = useMemo(() => waveform.map((w, i) => <WaveformTick
        key={'tick-' + i}
        width={props.thickness}
        gap={props.gap}
        style={{
            height: `${Math.max(4, w * (size?.height ?? 0) - (props.tickPadding ?? 0))}px`,
        }}/>), [props.thickness, props.gap, size?.height, waveform, props.tickPadding]);

    return <Container
        ref={ref}
        style={{cursor: props.onSeek ? 'pointer' : 'default', ...props.style}}
        onPointerDown={e => {
            if (!props.onSeek) return;
            const progress = (e.pageX - ref.current!.getBoundingClientRect().x) / (size?.width ?? 1);
            props.onSeek(progress < 0 ? 0 : progress > 1 ? 1 : progress);
        }}
        onMouseMove={e => {
            if (e.buttons !== 1) return;
            if (!props.onSeek) return;
            const progress = (e.pageX - ref.current!.getBoundingClientRect().x) / (size?.width ?? 1);
            props.onSeek(progress < 0 ? 0 : progress > 1 ? 1 : progress);
        }}
        onTouchMove={e => {
            if (e.touches.length !== 1) return;
            if (!props.onSeek) return;
            const progress = (e.touches[0].pageX - ref.current!.getBoundingClientRect().x) / (size?.width ?? 1);
            props.onSeek(progress < 0 ? 0 : progress > 1 ? 1 : progress);
        }}>
        {ticks}
        {!!props.children && <div style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0}}>
            {props.children}
        </div>}
    </Container>
});

const Container = styled.div`
  position: relative;
  align-items: center;
  display: flex;
  width: 0;
  flex-grow: 1;
  overflow: hidden;
`;

const WaveformTick = styled.div<{ width: number, gap: number }>`
  width: ${({width}) => width}px;
  margin: 0 ${({gap}) => gap / 2}px;
  border-radius: ${({width}) => width / 2}px;
  background-color: ${({theme}) => theme.palette.primary.main};
  flex-shrink: 0;
`;