import React, {useEffect} from "react";
import {Frame} from "../../../elementary/Frame";
import styled from "@emotion/styled";
import {D} from "../../../../app/constants";
import useWave from "use-wave";
import {duration, Typography} from "@mui/material";
import {ProjectDimens} from "../common/ProjectDimens";
import {Formatters} from "../../../../utils/Formatters";
import {LoadingOverlay} from "../../../elementary/LoadingOverlay";

interface RecorderButtonProps {
    recording: boolean;
    startedTimestamp?: number;
    onClick?: () => Promise<any>;
    loading?: boolean;
}

const getDurationS = (props: RecorderButtonProps) => {
    if (!props.recording || !props.startedTimestamp) return 0;
    return (Date.now() - props.startedTimestamp) / 1000;
}

export const RecorderButton = (props: RecorderButtonProps) => {
    const wave = useWave();
    const [loading, setLoading] = React.useState(false);
    const [r, setR] = React.useState(false);
    const [durationS, setDurationS] = React.useState(getDurationS(props));

    useEffect(() => {
        const interval = setInterval(() => setR(!r), 1000);
        return () => {
            clearInterval(interval);
        };
    }, [r]);

    useEffect(() => {
        setDurationS(getDurationS(props));
    }, [props, r, props.startedTimestamp]);

    return <Frame clip height={ProjectDimens.headerHeight} clickable={!loading && !props.loading} ref={wave}
                  appearance={'shade'} center row gap={1}
                  paddingX={2} onClick={async (e) => {
        setLoading(true);
        try {
            await props.onClick?.();
        } catch (e) {
            console.error(e);
        }
        setLoading(false);
    }}>
        <RecorderButtonContainer recording={props.recording}>
            <RecorderButtonIcon/>
        </RecorderButtonContainer>
        <Frame noPointer center width={20}>
            <Typography lineHeight={1} variant={'h4'}
                        fontWeight={"bold"}>{Formatters.toDuration(durationS)}</Typography>
            <Typography lineHeight={1}
                        fontWeight={"bold"}>{props.recording ? 'USTAVI SNEMANJE' : 'ZAČNI SNEMANJE'}</Typography>
        </Frame>
        <LoadingOverlay onPointerDownCapture={(e) => (loading || !!props.loading) && e.stopPropagation()}
                        visible={loading || !!props.loading}/>
    </Frame>
};

const RecorderButtonContainer = styled.div<{ recording: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({recording}) => recording ? D.radius() : '50%'};
  width: ${D.interactive(1.2)};
  height: ${D.interactive(1.2)};
  margin: ${D.unit()} 0;
  background-color: ${({theme, recording}) => recording ? 'white' : theme.palette.error.main};
  transition: all 0.3s ease-in-out;

  * {
    border-radius: ${({recording}) => recording ? '2px' : '50%'};
    background-color: ${({theme, recording}) => recording ? theme.palette.error.main : 'white'};
  }
`;

const RecorderButtonIcon = styled.div`
  border-radius: 50%;
  background-color: white;
  transition: all 0.3s ease-in-out;
  width: ${D.unit(2)};
  height: ${D.unit(2)};
`;