import {common, ws_management} from "../../proto/compiled";
import {Typography, useTheme} from "@mui/material";
import {DataTableData, TableRowData} from "../layouts/DataTable";
import {BrowserUpdatedOutlined, DomainVerificationOutlined} from "@mui/icons-material";
import styled from "@emotion/styled";
import {Formatters} from "../../utils/Formatters";

export class ManagementTable extends DataTableData {
    private management?: ws_management.IAsodManagement;

    constructor(management?: ws_management.IAsodManagement) {
        super(management?.pagination);
        this.minWidth = '900px';
        this.management = management;
    }

    getHeader() {
        const sortBy = this.management?.sortBy;
        const sortOrder = this.management?.sortOrder ?? common.SortOrder.so_none;
        return [
            {
                id: 'court',
                name: 'SODIŠČE',
                ordering: sortBy === ws_management.AsodManagementOrderingTerm.amot_court ? sortOrder : common.SortOrder.so_none,
                sortable: true,
                maxCharacters: 25,
            },
            {
                id: 'courtroom',
                name: 'DVORANA',
                ordering: sortBy === ws_management.AsodManagementOrderingTerm.amot_courtroom ? sortOrder : common.SortOrder.so_none,
                sortable: true,
                maxCharacters: 15,
            },
            {
                id: 'version',
                name: 'VERZIJA',
                ordering: sortBy === ws_management.AsodManagementOrderingTerm.amot_version ? sortOrder : common.SortOrder.so_none,
                sortable: true,
                maxCharacters: 15
            },
            {
                id: 'status',
                name: 'STANJE',
                ordering: sortBy === ws_management.AsodManagementOrderingTerm.amot_status ? sortOrder : common.SortOrder.so_none,
                sortable: true,
                maxCharacters: 24,
            },
            {
                id: 'storage',
                name: 'PROSTOR NA DISKU',
                ordering: sortBy === ws_management.AsodManagementOrderingTerm.amot_storage ? sortOrder : common.SortOrder.so_none,
                sortable: true,
                flex: 1,
            },
        ];
    }

    getRows(): TableRowData[] {
        return this.management?.rows!.map((p, i) => ({
            id: p.id!,
            data: p,
            children: [
                <Typography key={'court'} variant={'body2'}>{p.court}</Typography>,
                <Typography key={'courtRoom'} variant={'body2'}>{p.courtRoom}</Typography>,
                <VersionCell key={'versionStatus'} v={p.version!} s={p.versionStatus!}/>,
                <StatusCell key={'statusTime'} s={p.status!} st={p.statusTime!}/>,
                <StorageCell key={'storage'} used={p.storageUsedGb!} total={p.storageTotalGb!}/>,
            ],
        })) ?? [];
    }
}

const VersionCell = (p: { v: string, s: ws_management.AsodManagementVersionStatus }) => {
    const theme = useTheme();
    let icon;
    let color = undefined as undefined | string;
    switch (p.s) {
        case ws_management.AsodManagementVersionStatus.amvs_latest:
            icon = <DomainVerificationOutlined fontSize={'small'}/>;
            break;
        case ws_management.AsodManagementVersionStatus.amvs_update_scheduled:
            icon = <BrowserUpdatedOutlined fontSize={'small'} color={'warning'}/>;
            color = theme.palette.warning.main;
            break;
        case ws_management.AsodManagementVersionStatus.amvs_outdated:
        default:
            icon = <BrowserUpdatedOutlined fontSize={'small'} color={'error'}/>;
            color = theme.palette.error.main;
            break;
    }
    return <Typography variant={'body2'}
                       style={{display: 'flex', alignItems: 'center', color: color, fontWeight: 'bold'}}>
        {icon} {p.v}
    </Typography>;
};

const StatusCell = (p: { s: ws_management.AsodManagementStatus, st: common.IDate }) => {
    const theme = useTheme();
    let color;
    let state;
    switch (p.s) {
        case ws_management.AsodManagementStatus.ams_online:
            color = theme.palette.success.main;
            state = 'vključeno';
            break;
        case ws_management.AsodManagementStatus.ams_offline:
            color = theme.palette.error.main;
            state = 'izključeno';
            break;
        case ws_management.AsodManagementStatus.ams_unknown:
        default:
            color = theme.palette.text.disabled;
            state = 'neznano';
            break;
    }
    return <Typography variant={'body2'} style={{display: 'flex', alignItems: 'center'}}>
        <StatusBadge style={{color: color}}/> {state} {p.s === ws_management.AsodManagementStatus.ams_offline && <>({Formatters.toVerboseDuration(
        (new Date().getTime() - (p.st.ts as number)) / 1000
    )})</>}
    </Typography>
};

const StorageCell = (p: { used: number, total: number }) => {
    if (p.total === 0) return null;
    return <Typography variant={'body2'}>
        <b>{Math.round(100 * p.used / p.total)}%</b> ({Math.round(p.used)}GB / {Math.round(p.total)}GB)
    </Typography>
};

const StatusBadge = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
`;