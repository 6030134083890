import {observer} from "mobx-react-lite";
import {Frame} from "../elementary/Frame";
import {FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography, useTheme} from "@mui/material";
import React from "react";
import {ws_management} from "../../proto/compiled";
import useAutoObservable from "../../hooks/use_auto_observable";
import {D} from "../../app/constants";
import {DialogFooter} from "../layouts/DialogFooter";
import {ButtonFilled, ButtonOutline} from "../elementary/Buttons";
import {DateInput} from "../inputs/DateInput";
import {LoadingOverlay} from "../elementary/LoadingOverlay";
import {DateUtils} from "../../utils/DateUtils";

interface UpgradeDialogProps {
    state?: ws_management.IAsodManagementUpgrade;
    inProgress?: ws_management.IAsodManagementUpgradeInProgress;
    nSelected: number;
    onCancel: () => any;
    onSave: () => Promise<any>;
}

export const UpgradeDialog = observer((props: UpgradeDialogProps) => {
    const state = useAutoObservable(props.state);
    const theme = useTheme();
    const [loading, setLoading] = React.useState(false);
    return <Frame padding={4} gap={2} style={{width: D.unit(60), minHeight: '80vh'}}>
        <Typography variant={'h5'}>Nadgradnja</Typography>
        {!!props.inProgress && <>
            <Typography variant={'body1'}>
                Na nadgradnjo čaka {props.inProgress.nPending} naprav, ki se bodo posodobile v časovnem oknu
                od {DateUtils.toDateTimeString(props.inProgress.upgradeTimeFrom)} do {DateUtils.toDateTimeString(props.inProgress.upgradeTimeTo)}.
            </Typography>
        </>}
        {!!state && <><Frame appearance={'outline'} gap={2} padding={2}>
            <FormControl>
                <RadioGroup
                    value={state.upgradeAll ? 'all' : 'selected'}
                    onChange={(e) => state.upgradeAll = e.target.value === 'all'}
                >
                    <FormControlLabel value="all" control={<Radio/>} label="Vse naprave"/>
                    <FormControlLabel value="selected" control={<Radio/>}
                                      label={`Trenutna izbira (${props.nSelected})`}/>
                </RadioGroup>
            </FormControl>
        </Frame>
            <DateInput
                label={'Časovno okno - OD'}
                value={state.upgradeTimeFrom!}/>
            <DateInput
                label={'Časovno okno - DO'}
                value={state.upgradeTimeTo!}/></>}
        <DialogFooter bottom>
            <ButtonOutline center onClick={() => props.onCancel()}>
                ZAPRI
            </ButtonOutline>
            <ButtonFilled style={{backgroundColor: theme.palette.error.main}} center onClick={async () => {
                setLoading(true);
                await props.onSave();
                setLoading(false);
            }}>
                {!!props.inProgress ? 'PREKINI' : 'ZAČNI'}
            </ButtonFilled>
        </DialogFooter>
        <LoadingOverlay visible={loading}/>
    </Frame>
});