import {
    getCrypto,
    getAlgorithmParameters,
    CertificationRequest,
    AttributeTypeAndValue,
    ICryptoEngine,
    CryptoEngineAlgorithmParams
} from 'pkijs/build'
import {AppContextStore} from "./AppContextStore";
import {CryptoUtils} from "../utils/CryptoUtils";
import {arrayBufferToString, toBase64} from "pvutils";
import * as asn1js from 'asn1js';
import {makeAutoObservable} from "mobx";
import {proto} from "../proto/messages";
import {DisposeUtils} from "../utils/DisposeUtils";
import {toast} from "react-toastify";

export class PairingUsbStore {
    context: AppContextStore;
    private disposeUtils = new DisposeUtils();
    private crypto: ICryptoEngine;
    private algorithm: CryptoEngineAlgorithmParams;
    private certificate?: string | undefined;
    private privateKey?: string;

    constructor(context: AppContextStore) {
        this.context = context;
        this.crypto = getCrypto()!;
        this.algorithm = getAlgorithmParameters('ECDSA', 'generateKey');
        if ('hash' in this.algorithm.algorithm) {
            (this.algorithm.algorithm as any).hash.name = 'SHA-256';
        }

        if (typeof this.crypto === 'undefined') {
            throw Error('No WebCrypto extension found');
        }
        this.init();
        makeAutoObservable(this);

        this.disposeUtils.add(this.context.api.getMessageHandler(new proto.RxApiPairingCsrResponse()).subscribe((m) => this.onCsrResponse(m)));
    }

    async createCSR(keyPair: CryptoKeyPair, hashAlgo: string) {
        const pkcs10 = new CertificationRequest()
        pkcs10.version = 0

        // list of OID reference: http://oidref.com/2.5.4
        pkcs10.subject.typesAndValues.push(new AttributeTypeAndValue({
            type: '2.5.4.10', //organizationName
            value: new asn1js.Utf8String({value: 'iLOL d.o.o.'})
        }))

        // add attributes to make CSR valid
        // Attributes must be "a0:00" if empty
        pkcs10.attributes = []

        await pkcs10.subjectPublicKeyInfo.importKey(keyPair.publicKey)

        //signing final PKCS#10 request
        await pkcs10.sign(keyPair.privateKey, hashAlgo)

        return pkcs10.toSchema().toBER(false)
    }

    async createPKCS10() {
        const keyPair = await this.crypto.generateKey(this.algorithm.algorithm as Algorithm, true, this.algorithm.usages) as CryptoKeyPair;

        return {
            csr: `-----BEGIN CERTIFICATE REQUEST-----\n${
                CryptoUtils.formatPEM(
                    toBase64(
                        arrayBufferToString(
                            await this.createCSR(keyPair, 'SHA-256')
                        )
                    )
                )}\n-----END CERTIFICATE REQUEST-----`,
            privateKey: `-----BEGIN PRIVATE KEY-----\n${
                CryptoUtils.formatPEM(
                    toBase64(
                        arrayBufferToString(await this.crypto.exportKey('pkcs8', keyPair.privateKey))
                    )
                )
            }\n-----END PRIVATE KEY-----`
        }
    }

    get hasCertificate() {
        return !!this.certificate;
    }

    download() {
        if (!this.certificate || !this.privateKey) {
            throw Error('No certificate or private key found');
        }
        const pem = `${this.certificate}${this.privateKey}`;
        const blob = new Blob([pem], {type: 'application/x-pem-file'});
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'pairing.pem';
        a.click();
        URL.revokeObjectURL(url);
    }

    dispose() {
        this.disposeUtils.dispose();
    }


    private async init() {
        const result = await this.createPKCS10();
        this.privateKey = result.privateKey;
        await this.context.api.sendMessage(proto.TxApiPairingCertificateSigningRequest.create({
            csr: result.csr,
            type: 'PAIRING'
        }));
    }

    private onCsrResponse(m: proto.RxApiPairingCsrResponse) {
        if (!m.proto.certificate.length) {
            toast.error(`Failed to generate certificate (${m.proto.status})`);
            return;
        }
        this.certificate = m.proto.certificate;
    }
}