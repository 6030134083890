import {observer} from "mobx-react-lite";
import React, {useContext, useEffect, useMemo} from "react";
import {UploadProjectStore, UploadProjectStoreContext} from "../../stores/UploadProjectStore";
import {Frame} from "../elementary/Frame";
import {TextField, Typography, useTheme} from "@mui/material";
import {D} from "../../app/constants";
import {ProjectMembers} from "../pages/project/common/ProjectMembers";
import {AppContextStore, AppContextStoreContext} from "../../stores/AppContextStore";
import {ButtonFilled, ButtonOutline} from "../elementary/Buttons";
import {Event} from "@mui/icons-material";
import {DialogFooter} from "../layouts/DialogFooter";
import {UploadArea} from "../../fragments/UploadArea";

export const UploadProjectDialog = observer(({onCancel, onSave}: { onCancel: () => any, onSave: () => any }) => {
    const context = useContext(AppContextStoreContext)!;
    const store = useMemo(() => new UploadProjectStore(context), [context]);
    useEffect(() => () => store.dispose(), [store]);
    const theme = useTheme();
    return <Frame background={theme.palette.background.default} style={{width: D.unit(60), minHeight: '80vh'}}
                  gap={2} padding={4}>
        <Typography variant={'h5'}>Naloži obravnavo</Typography>
        <UploadArea file={store.file} onUploaded={(f) => store.file = f}/>
        <TextField
            label={'Opravilna številka'}
            value={store.meta.opNumber}
            onChange={(e) => store.meta.opNumber = e.target.value}
        />
        <ProjectMembers members={store.meta.members}/>
        <ButtonOutline style={{marginTop: 'auto'}}>
            <Event/> Izberi dogodek in predizpolni
        </ButtonOutline>
        <DialogFooter>
            <ButtonOutline center onClick={() => onCancel()}>
                PREKLIČI
            </ButtonOutline>
            <ButtonFilled center onClick={() => onSave()}>
                SHRANI
            </ButtonFilled>
        </DialogFooter>
    </Frame>
});