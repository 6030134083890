import React from "react";
import {AppContextStore} from "../../stores/AppContextStore";
import {proto} from "../../proto/messages";
import {common, ws_management} from "../../proto/compiled";
import {ErrorUtils} from "../../utils/ErrorUtils";
import {makeAutoObservable} from "mobx";
import {ProtoTableStore} from "../../stores/ProtoTableStore";
import {Globals} from "../../index";
import {DummyAsodManagement} from "../../testing/data/DummyAsodManagement";
import {ManagementTable} from "../../components/tables/ManagementTable";
import {DisposeUtils} from "../../utils/DisposeUtils";
import DateGranularity = common.DateGranularity;

export const ManagementStoreContext = React.createContext<ManagementStore | undefined>(undefined);

export class ManagementStore {
    app: AppContextStore;
    private disposeUtils = new DisposeUtils();
    loadData = ws_management.LoadAsodManagement.create({
        pagination: common.Pagination.create({
            page: 1,
            pageSize: 10,
        }),
    });
    management?: ws_management.AsodManagement;
    private loading = 0;
    table: ProtoTableStore;

    private _upgrade?: ws_management.AsodManagementUpgrade;
    upgrade?: ws_management.AsodManagementUpgrade;
    upgradeInProgress?: ws_management.IAsodManagementUpgradeInProgress;

    constructor(app: AppContextStore) {
        this.app = app;
        this.table = new ProtoTableStore(this.app, new ManagementTable(), true);
        this.disposeUtils.add(
            this.app.api.getMessageHandler(new proto.RxAsodManagement()).subscribe((e) => this.onAsodManagement(e))
        )
        this.initUpgradeState();
        this.load();
        makeAutoObservable(this);

        if (Globals.useDummyData) {
            const management = new proto.RxAsodManagement();
            management.proto = DummyAsodManagement;
            this.onAsodManagement(management);
        }
    }

    dispose() {
        this.disposeUtils.dispose();
        this.table.dispose();
    }

    private onAsodManagement(e: proto.RxAsodManagement) {
        this.management = e.proto;
        this.table.dispose();
        this.table = new ProtoTableStore(this.app, new ManagementTable(this.management), true);
    }

    get isLoading() {
        return this.loading > 0;
    }

    async load() {
        this.loading++;
        this.loadData.pagination = this.table?.data.pagination ?? this.loadData.pagination;
        const response = await this.app.api.sendMessage(
            proto.TxLoadAsodManagement.create(this.loadData),
            {ack: true}
        );
        ErrorUtils.response(response);
        this.loading--;
    }

    onUpgrade(doAction: boolean) {
        if (doAction) {
            if (this.upgradeInProgress) {
                this.upgradeInProgress = undefined;
                this.initUpgradeState();
            } else {
                this.upgradeInProgress = ws_management.AsodManagementUpgradeInProgress.create({
                    nPending: 11,
                    upgradeTimeFrom: this.upgrade?.upgradeTimeFrom,
                    upgradeTimeTo: this.upgrade?.upgradeTimeTo,
                });
                this._upgrade = undefined;
                this.upgrade = undefined;
            }
        } else if (this._upgrade) {
            this.upgrade = ws_management.AsodManagementUpgrade.fromObject(this._upgrade.toJSON());
        }
    }

    private initUpgradeState() {
        this._upgrade = ws_management.AsodManagementUpgrade.create({
            upgradeAll: true,
            upgradeTimeFrom: common.Date.create({granularity: DateGranularity.minute}),
            upgradeTimeTo: common.Date.create({granularity: DateGranularity.minute}),
        })
        this.upgrade = ws_management.AsodManagementUpgrade.fromObject(this._upgrade!.toJSON());
    }
}
