import {observer} from "mobx-react-lite";
import {Frame} from "../../components/elementary/Frame";
import {Typography, useTheme} from "@mui/material";
import {ButtonIconOutline, ButtonOutline} from "../../components/elementary/Buttons";
import {ArrowForwardIos, PersonOutline} from "@mui/icons-material";
import SiPass from '../../assets/sipass.png';
import Keycloak from '../../assets/keycloak.png';
import {D} from "../../app/constants";
import React, {useContext, useEffect, useState} from "react";
import {Space} from "../../components/elementary/Space";
import {Logo} from "../../components/elementary/Logo";
import {AppContextStore, AppContextStoreContext, AsodVariant} from "../../stores/AppContextStore";
import {SsoDialog} from "../../components/dialogs/SsoDialog";
import {RecorderRoutes} from "../../recorder/Routes";
import {useNavigate} from "react-router-dom";
import LogoEkp from '../../assets/logo/ekp.png';
import LogoUp from '../../assets/logo/up.png';

export const LoginPage = observer(() => {
    const theme = useTheme();
    const context = useContext(AppContextStoreContext)!;
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    useEffect(() => {
        if (context.auth.loggedInAndHasUser) {
            navigate(RecorderRoutes.index);
        }
    }, [context.auth.loggedInAndHasUser, navigate]);

    return <Frame
        style={{backgroundColor: theme.palette.primary.main}}
        direction={'row'}
        alignItems={'stretch'} fill center>
        <Frame appearance={'card'}
               style={{maxWidth: D.unit(80)}}
               width={0}
               grow
               radius={0}
               shadow={10}
               justifyContent={'center'}
               padding={8}
               gap={2}>
            <Logo style={{alignSelf: 'center', marginBottom: 'auto', marginTop: 'auto'}}/>

            <ButtonOutline
                onClick={AppContextStore.variant === AsodVariant.asodout ? () => context.auth.login() : undefined}
                style={{borderColor: theme.palette.primary.main, height: "auto", padding: D.unit()}}>
                <img src={SiPass} style={{height: D.interactive()}} alt={'sipass'}/>
                <ArrowForwardIos style={{marginLeft: 'auto'}}/>
            </ButtonOutline>

            {AppContextStore.variant !== AsodVariant.asodout && <ButtonOutline
                onClick={() => context.auth.login()}
                style={{borderColor: theme.palette.primary.main, height: "auto", padding: D.unit()}}>
                <img src={Keycloak} style={{height: D.interactive()}} alt={'keycloak'}/>
                <ArrowForwardIos style={{marginLeft: 'auto'}}/>
            </ButtonOutline>}

            {AppContextStore.variant !== AsodVariant.asodout && <ButtonIconOutline
                onClick={() => context.auth.login()}
                style={{
                    height: D.interactive(1.5),
                    paddingRight: D.unit()
                }}>
                <PersonOutline fontSize={'large'}/>
                <Space s={0.5}/>
                LOKALNA PRIJAVA
                <ArrowForwardIos style={{marginLeft: 'auto'}}/>
            </ButtonIconOutline>}
            <Typography style={{marginTop: 'auto', textAlign: 'center'}} variant={'h5'}>
                Centralni repozitorij posnetkov<br/>sodnih obravnav.
            </Typography>
            <Space/>
            <Frame row justifyContent={'space-evenly'}>
                <img src={LogoEkp} style={{height: D.unit(8)}} alt={'ekp'}/>
                <img src={LogoUp} style={{height: D.unit(8)}} alt={'up'}/>
            </Frame>
        </Frame>
        <SsoDialog/>
    </Frame>;
});