import {createContext} from "react";
import {makeAutoObservable} from "mobx";
import {recorder_state} from "../../proto/compiled";
import {RecorderContextStore} from "./RecorderContextStore";
import {proto} from "../../proto/messages";
import {ErrorUtils} from "../../utils/ErrorUtils";

export const RecorderPairingStoreContext = createContext<RecorderPairingStore | undefined>(undefined);

export class RecorderPairingStore {
    private context: RecorderContextStore;
    metadata = recorder_state.RecorderMetadata.create();
    loading = false;

    constructor(context: RecorderContextStore) {
        this.context = context;
        makeAutoObservable(this.metadata);
        makeAutoObservable(this);
    }

    dispose() {

    }

    async pair() {
        this.loading = true;
        const response = await this.context.api.sendMessage(proto.TxRecorderPair.create({
            metadata: this.metadata,
            saveToUsb: false,
        }), {ack: true});
        ErrorUtils.response(response);
        this.loading = false;
    }

    async saveCsrToUsb() {
        this.loading = true;
        const response = await this.context.api.sendMessage(proto.TxRecorderPair.create({
            metadata: this.metadata,
            saveToUsb: true,
        }), {ack: true});
        ErrorUtils.response(response);
        this.loading = false;
    }

    get metadataValid() {
        return !!this.metadata.court.length && !!this.metadata.courtroom.length;
    }

    async cancelCSR() {
        this.loading = true;
        const response = await this.context.api.sendMessage(proto.TxRecorderDeleteCsr.create(), {ack: true});
        ErrorUtils.response(response);
        this.loading = false;
    }
}