import {common, ws_management} from "../../proto/compiled";

export const DummyAsodManagement = ws_management.AsodManagement.create({
    rows: [
        ws_management.AsodManagementRow.create({
            id: 't1',
            court: 'Vrhovno sodišče',
            department: 'kazenski',
            courtRoom: 'KX-130',
            name: 'C15',
            version: '0.9.2',
            versionStatus: ws_management.AsodManagementVersionStatus.amvs_outdated,
            status: ws_management.AsodManagementStatus.ams_online,
            statusTime: common.Date.create({ts: new Date().getTime() - 1251000}),
            storageTotalGb: 205.1,
            storageUsedGb: 92.5,
        }),
        ws_management.AsodManagementRow.create({
            id: 't2',
            court: 'Vrhovno sodišče',
            department: 'kazenski',
            courtRoom: 'KX-130',
            name: 'C15',
            version: '0.9.2',
            versionStatus: ws_management.AsodManagementVersionStatus.amvs_update_scheduled,
            status: ws_management.AsodManagementStatus.ams_offline,
            statusTime: common.Date.create({ts: new Date().getTime() - 3600000*11.5}),
            storageTotalGb: 95.1,
            storageUsedGb: 82.2,
        }),
        ...new Array(10).fill(0).map((_, i) => ws_management.AsodManagementRow.create({
            id: i.toString(),
            court: 'Vrhovno sodišče',
            department: 'kazenski',
            courtRoom: 'KX-129',
            name: 'C13',
            version: '1.0.0',
            versionStatus: ws_management.AsodManagementVersionStatus.amvs_latest,
            status: ws_management.AsodManagementStatus.ams_online,
            statusTime: common.Date.create({ts: new Date().getTime() - 3600000}),
            storageTotalGb: 250.1,
            storageUsedGb: 124.5,
        })),
    ],
    pagination: common.Pagination.create({
        total: 150,
        page: 1,
        nPages: 19,
        pageSize: 10,
    }),
    sortBy: ws_management.AsodManagementOrderingTerm.amot_court,
    sortOrder: common.SortOrder.so_desc,
});