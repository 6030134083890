import {common} from "../proto/compiled";

export class Proto2Js {
    static Date = (date?: common.IDate): Date | undefined => {
        if (!date) return undefined;
        return new Date(date.ts as number);
    }
}

export class Js2Proto {
    static Date = (date?: Date): common.IDate | undefined => {
        if (!date) return undefined;
        return common.Date.create({
            ts: date.getTime(),
            tz: new window.Intl.DateTimeFormat().resolvedOptions().timeZone,
            granularity: common.DateGranularity.no_granularity,
        });
    }
}