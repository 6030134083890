import {observer} from "mobx-react-lite";
import React, {useState} from "react";
import useWave from "use-wave";
import {ChannelLevel} from "../components/elementary/ChannelLevel";
import {IconButton, useTheme} from "@mui/material";
import {ExpandMore} from "@mui/icons-material";
import styled from "@emotion/styled";
import {Collapsible} from "../components/elementary/collapsible";
import {D} from "../app/constants";
import {CssFlexCenter} from "../components/base/flex";

export interface ChannelLevelData {
    index: number;
    isMuted: boolean;
    isSolo: boolean;
    channelVolume: number;
    setChannelVolume: (v: number) => void;
    onToggleMuted: () => void;
    onToggleSolo: () => void;
    getAudioLevel: () => number; // to optimize using separate observer
}

interface ChannelLevelsProps {
    levels: ChannelLevelData[];
    grow?: boolean;
}

export const ChannelLevels = observer((props: ChannelLevelsProps) => {
    const [expanded, setExpanded] = useState(false);
    const wave = useWave();
    const theme = useTheme();
    if (props.levels.length === 0) return null;
    return <Container style={{
        minHeight: D.interactive(),
        boxSizing: 'border-box',
        width: props.grow ? '100%' : undefined,
        backgroundColor: expanded ? theme.palette.background.paper : 'transparent',
        padding: expanded ? `${D.unit(1)} 0` : 0,
        margin: expanded ? `${D.unit(-1)} 0` : 0,
    }}>
        <HeaderContainer>
            <IconButton onClick={() => setExpanded(!expanded)} ref={wave} style={{
                fontSize: '20px',
                pointerEvents: 'all',
                width: D.interactive(0.8),
                height: D.interactive(0.8),
                position: 'absolute',
                transition: 'all 300ms',
                transform: `rotate(${expanded ? '180' : '0'}deg)`,
                right: '2px',
                marginLeft: 'auto',
            }}><ExpandMore/></IconButton>
        </HeaderContainer>
        <Collapsible expanded={expanded} style={{marginTop: '-35px', paddingTop: '35px'}}>
            <ContentContainer>
                <div style={{marginRight: 'auto'}}/>
                {props.levels.map((level, i) => <ChannelLevel
                    key={'channel-level-' + i}
                    {...level}
                    open={expanded}
                />)}
                <div style={{marginLeft: 'auto'}}/>
            </ContentContainer>
        </Collapsible>
    </Container>;
});

const HeaderContainer = styled.div`
  ${CssFlexCenter};
  pointer-events: none;
  position: relative;
  display: flex;
  justify-content: center;
  height: 35px;
  width: 100%;
  margin-top: 3px;
`;

const ContentContainer = styled.div`
  height: 110px;
  margin-top: -35px;
  padding: 0 35px 0 35px;
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  transition: 300ms all;
  border-radius: ${D.radius()};
  border: solid 1px ${({theme}) => theme.palette.divider};
`;