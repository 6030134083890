import {observer} from "mobx-react-lite";
import {Frame} from "../../../elementary/Frame";
import {TextField, Typography, useTheme} from "@mui/material";
import {DateUtils} from "../../../../utils/DateUtils";
import {useContext, useEffect, useMemo, useRef, useState} from "react";
import {isObservable, makeAutoObservable} from "mobx";
import {AsodAudioPlayer} from "../../../../fragments/AsodAudioPlayer";
import {ButtonOutline} from "../../../elementary/Buttons";
import {Add} from "@mui/icons-material";
import {D} from "../../../../app/constants";
import {asod_project} from "../../../../proto/compiled";
import {LoadingOverlay} from "../../../elementary/LoadingOverlay";
import {MobxUtils} from "../../../../utils/MobxUtils";
import {ProjectStoreContext} from "../../../../stores/ProjectStore";
import useAutoObservable from "../../../../hooks/use_auto_observable";

interface RecordingBlocksProps {
    blocks: asod_project.IAsodProjectAudioBlock[];
}

export const RecordingBlocks = observer((props: RecordingBlocksProps) => {
    return <Frame grow gap={2} style={{overflowY: 'auto', paddingBottom: D.unit(30)}} padding={1}>
        {props.blocks.map((b, i) => <RecordingBlock key={'block-' + i} block={b}/>)}
    </Frame>
});

export const RecordingBlock = observer((props: {
    block: asod_project.IAsodProjectAudioBlock;
}) => {
    const theme = useTheme();
    const store = useContext(ProjectStoreContext)!;
    const [loading, setLoading] = useState(false);
    useAutoObservable(props.block, props.block.audio);

    const progress = store.audio.getBlockProgress(props.block);

    return <Frame background={theme.palette.background.paper} appearance={'card'} padding={2} gap={1}>
        {!!props.block.timestampStart && <Typography variant={'h5'}>
            Snemanje se je pričelo {DateUtils.toDateTimeString(props.block.timestampStart)}
        </Typography>}

        {props.block.comments
            ?.map((c, i) =>
                <RecordingBlockComment key={'comment-' + i} blockId={props.block.id!} comment={c}/>)}
        {store.context.isRecorder && store && !props.block.timestampEnd && <ButtonOutline onClick={async () => {
            setLoading(true);
            await store.annotations.addComment(props.block);
            setLoading(false);
        }}
                                                                                          style={{
                                                                                              marginRight: 'auto',
                                                                                              paddingRight: D.unit()
                                                                                          }}>
            <Add/>Dodaj komentar
        </ButtonOutline>}
        {!!props.block.timestampEnd && <Typography variant={'h5'}>
            Snemanje se je zaključilo {DateUtils.toDateTimeString(props.block.timestampEnd)}
        </Typography>}
        <Frame
            margin={-2}
            marginTop={0}
            direction={'row'}
            height={6}
            style={{
                boxShadow: 'rgba(0, 0, 0, 0.40) 0 0 5px 0',
            }}>
            <AsodAudioPlayer
                isPlaying={store.audio.isBlockPlaying(props.block)}
                progress={progress}
                waveform={props.block.audio?.waveform ?? undefined}
                togglePlaying={() => store.audio.toggleBlockPlaying(props.block)}
                seek={(p) => store.audio.setBlockProgressFraction(props.block, p)}
                seekSeconds={(s) => store.audio.advanceBlockProgressSeconds(props.block, s)}
            />
        </Frame>
        <LoadingOverlay visible={loading}/>
    </Frame>
});

const RecordingBlockComment = observer((props: { blockId: string, comment: asod_project.IAsodProjectComment }) => {
    const ref = useRef<HTMLInputElement>(null);
    const state = useMemo(() => {
        if (!isObservable(props.comment)) makeAutoObservable(props.comment);
        return {
            changed: false,
        };
    }, [props.comment]);
    const theme = useTheme();
    const store = useContext(ProjectStoreContext)!;
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (store.annotations.focusedComment === props.comment.id) {
            ref.current?.scrollIntoView({behavior: 'smooth', block: 'center'});
            ref.current?.focus();
        }
    }, [ref, props.comment, store.annotations.focusedComment]);

    return <Frame padding={2} background={theme.palette.background.default} appearance={'card'} style={{
        boxShadow: theme.shadows[1],
    }}>
        <Typography variant={'h5'}>
            {DateUtils.toTimeString(props.comment.timestamp)}
            &nbsp;-&nbsp;
            {props.comment.author?.name}
        </Typography>
        {/* causing "ResizeObserver loop completed with undelivered notifications" */}
        {!store.context.isRecorder && <Typography variant={'body1'}>
            {props.comment.body}
        </Typography>}
        {store.context.isRecorder && <TextField
            inputRef={ref}
            placeholder="Vnesite komentar..."
            variant={'standard'}
            multiline
            onChange={(e) => {
                state.changed = true;
                props.comment.body = e.target.value;
            }}
            onBlur={async () => {
                if (!state.changed) return;
                state.changed = false;
                setLoading(true);
                await store.annotations.saveComment(props.blockId, props.comment);
                setLoading(false);
            }}
            InputProps={{disableUnderline: true}}
            value={props.comment.body}
        />}
        <LoadingOverlay visible={loading}/>
    </Frame>
});