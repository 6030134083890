import {observer} from "mobx-react-lite";
import {SidebarLayout} from "../../components/layouts/SidebarLayout";
import React, {useContext, useEffect, useMemo} from "react";
import {AppContextStoreContext} from "../../stores/AppContextStore";
import {ProjectsSideBar} from "../../components/pages/projects/ProjectsSideBar";
import {ProjectsStore, ProjectsStoreContext} from "../stores/ProjectsStore";
import {ProjectsContent} from "../../components/pages/projects/ProjectsContent";

let store: ProjectsStore;

export const IndexPage = observer(() => {
    const context = useContext(AppContextStoreContext)!;
    store = useMemo(() => store ?? new ProjectsStore(context), [context]);
    return <ProjectsStoreContext.Provider value={store}>
        <SidebarLayout>
            <ProjectsSideBar/>
            <ProjectsContent/>
        </SidebarLayout>
    </ProjectsStoreContext.Provider>;
});