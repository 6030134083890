import {createContext} from "react";
import {autorun, isObservable, makeAutoObservable} from "mobx";
import {AppContextStore} from "./AppContextStore";
import {proto} from "../proto/messages";
import {asod_members, asod_project, common} from "../proto/compiled";
import {ErrorUtils} from "../utils/ErrorUtils";
import {Globals} from "../index";
import {DummyAsodProject} from "../testing/data/DummyAsodProject";
import {AnnotationsStore} from "./project/AnnotationsStore";
import {AudioStore} from "./project/AudioStore";
import {DisposeUtils} from "../utils/DisposeUtils";
import {MobxUtils} from "../utils/MobxUtils";

export const ProjectStoreContext = createContext<ProjectStore | undefined>(undefined);

export class ProjectStore {
    private disposeUtils = new DisposeUtils();
    context: AppContextStore;
    projectId?: string;
    data?: asod_project.IAsodProject = Globals.useDummyData ? DummyAsodProject : undefined;
    annotations: AnnotationsStore;
    audio: AudioStore;
    meta: ProjectMetadata;


    constructor(context: AppContextStore, projectId?: string) {
        this.context = context;
        this.projectId = projectId;
        this.annotations = new AnnotationsStore(context, this);
        this.audio = new AudioStore(context, this);
        this.meta = new ProjectMetadata(context, this);
        makeAutoObservable(this);
        this.disposeUtils.add(
            this.context.api.getMessageHandler(new proto.RxAsodProject()).subscribe((m) => this.onAsodProject(m))
        );
        this.load();
    }

    async load() {
        ErrorUtils.response(
            await this.context.api.sendMessage(
                proto.TxLoadAsodProject.create({id: this.projectId}), {ack: true}
            )
        );
    }

    dispose() {
        this.audio.dispose();
        this.annotations.dispose();
        this.meta.dispose();
        this.disposeUtils.dispose();
    }

    private onAsodProject(m: proto.RxAsodProject) {
        if (m.proto.id !== this.projectId && !!this.projectId) return;
        this.projectId = m.proto.id;
        this.data = m.proto;
        MobxUtils.makeObservable(this.data.meta);
        if (Globals.useDummyData) {
            this.data.meta = DummyAsodProject.meta;
        }
    }

    audioBlock(id: string): asod_project.IAsodProjectAudioBlock | undefined {
        return this.data?.audioBlocks?.find((b) => b.id === id);
    }

    get dateStart() {
        if (!this.data?.audioBlocks?.length) return undefined;
        const ts = this.data.audioBlocks[0].timestampStart;
        if (!ts) return undefined;
        return common.Date.create({ts: ts});
    }

    get dateEnd() {
        if (!this.data?.audioBlocks?.length) return undefined;
        const ts = this.data.audioBlocks[this.data.audioBlocks.length - 1].timestampEnd;
        if (!ts) return undefined;
        return common.Date.create({ts: ts});
    }

    getBlockByDate(value: common.IDate) {
        if (!value.ts) return undefined;
        // if it's recording, last block is up until now
        const lastBlock = this.data?.audioBlocks?.[this.data.audioBlocks.length - 1];
        for (const block of this.data?.audioBlocks ?? []) {
            if (!block.timestampStart || (!block.timestampEnd && block.id !== lastBlock?.id)) continue;
            if (block.timestampStart <= value.ts && (block.timestampEnd ?? Date.now()) >= value.ts) return block;
        }
        return undefined;
    }
}

class ProjectMetadata {
    private base: ProjectStore;
    private context: AppContextStore;
    private disposeUtils = new DisposeUtils();
    private _loading = 0;

    constructor(context: AppContextStore, base: ProjectStore) {
        this.context = context;
        this.base = base;

        this.disposeUtils.add(autorun(() => {
            if (!this.base.data) return;
            if (!this.base.data.meta) {
                this.base.data.meta = asod_project.AsodProjectMeta.create();
            }
            if (!isObservable(this.base.data)) {
                makeAutoObservable(this.base.data);
            }
            if (!isObservable(this.base.data.meta)) {
                makeAutoObservable(this.base.data.meta);
                this.base.data.meta.members?.map((m) => makeAutoObservable(m));
            }
        }));
        this.disposeUtils.add(this.base.context.api.getMessageHandler(new proto.RxAsodProjectMeta()).subscribe((m) => this.onProjectMeta(m)));
        makeAutoObservable(this);
    }

    get data() {
        return this.base.data?.meta;
    }

    get members(): asod_members.IAsodMember[] {
        return this.data?.members || [];
    }

    async saveMember(m: asod_members.IAsodMember) {
        this._loading++;
        const res = await this.context.api.sendMessage(proto.TxSaveAsodMember.create({
            sessionId: this.base.projectId,
            member: m,
        }), {ack: true});
        this._loading--;
        return ErrorUtils.response(res);
    }

    get opNumber(): string {
        return this.data?.opNumber || "";
    }

    set opNumber(opNumber: string) {
        this.data!.opNumber = opNumber;
    }

    get loading(): boolean {
        return this._loading > 0;
    }

    async saveOpNumber() {
        this._loading++;
        const response = await this.context.api.sendMessage(
            proto.TxApiObravnaveChangeSessionCourtCaseNumber.create({
                sessionId: this.base.projectId,
                courtCaseNumber: this.data?.opNumber ?? ""
            }), {ack: true}
        );
        ErrorUtils.response(response);
        this._loading--;
    }

    dispose() {
        this.disposeUtils.dispose();
    }

    private onProjectMeta(m: proto.RxAsodProjectMeta) {
        if (!this.base.data) return;
        this.base.data.meta = m.proto;
        makeAutoObservable(this.base.data.meta);
    }
}