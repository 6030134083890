import {SocketApi} from "proto_socket_typescript";
import {proto} from "../proto/messages";
import {common, ws_management} from "../proto/compiled";
import {AppContextStore} from "../stores/AppContextStore";
import {WsConnector} from "./ws_connector";

export class WsManagementConnector {
    context: AppContextStore;
    api: SocketApi;

    constructor(context: AppContextStore) {
        this.context = context;
        this.api = context.api;

        this.api.getTxMessageHandler(proto.TxLoadAsodManagement.create(), {intercept: true}).subscribe((e) => this.onTxLoadAsodManagement(e));
        this.api.getMessageHandler(new proto.RxApiSnemalnikiRecorders()).subscribe((e) => this.onRxApiSnemalnikiRecorders(e));
    }

    private onTxLoadAsodManagement(e: proto.TxLoadAsodManagement) {
        this.api.sendMessage(proto.TxApiSnemalnikiGetRecorders.create({
            name: e.proto.quickSearch,
            court: e.proto.court,
            courtroom: e.proto.courtRoom,
            ...WsConnector.fromPagination(e.proto.pagination)
        }))
    }

    private onRxApiSnemalnikiRecorders(m: proto.RxApiSnemalnikiRecorders) {
        const message = new proto.RxAsodManagement();
        message.proto = ws_management.AsodManagement.create({
            rows: m.proto.recorders.map((e) => ws_management.AsodManagementRow.create({
                id: e.recorderId,
                court: e.courtName,
                department: e.courtSection,
                courtRoom: e.courtroom ?? 'N/A',
                name: e.recorderName,
                version: e.softwareVersion,
                versionStatus: ws_management.AsodManagementVersionStatus.amvs_latest,
                status: {
                    'idle': ws_management.AsodManagementStatus.ams_online,
                    'recording': ws_management.AsodManagementStatus.ams_online,
                    'online': ws_management.AsodManagementStatus.ams_online,
                    'offline': ws_management.AsodManagementStatus.ams_offline,
                }[e.status ?? ''] ?? ws_management.AsodManagementStatus.ams_unknown,
                statusTime: {ts: (e.statusTime ?? 0) as number * 1000},
                ...(!!e.diskCapacity && !!e.diskAmountFilled && {
                    storageTotalGb: e.diskCapacity as number / 1e9,
                    storageUsedGb: e.diskAmountFilled as number / 1e9,
                })
            })),
            pagination: WsConnector.toPagination(m.proto),
        });
        this.api.sendLocalUpdate(message)
    }
}