import React, {CSSProperties, useEffect, useState} from "react";
import {
    Box,
    Button,
    IconButton,
    TextField,
    Typography, useTheme,
} from "@mui/material";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft"
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight"
import LastPageIcon from "@mui/icons-material/LastPage"
import {common} from "../../proto/compiled";
import styled from "@emotion/styled";
import {D} from "../../app/constants";
import {useMeasure} from "../../hooks/use_measure";

interface PaginationProps {
    pagination: common.IPagination;
    onPageChange: (page: number) => void;
    onPageSizeChange: (pageSize: number) => void;
    sticky: boolean;
    flat?: boolean;
    style?: CSSProperties;
}

const PaginationToolbar = styled.div`
  display: flex;
  align-items: center;
  padding: ${D.unit(0.5)} ${D.unit(2)};
  background-color: ${({theme}) => theme.palette.background.default};
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.15);
  align-self: flex-start;
`;

const PageNumbers: React.FC<{
    total: number,
    current: number,
    onPageChange: (page: number) => void,
}> = ({total, current, onPageChange}) => {
    const {ref, size} = useMeasure();
    const buttonWidth = 50; // Set the button width including margins and padding
    const buttonHeight = 30; // Set the button width including margins and padding
    const numButtons = Math.floor((size?.width ?? 0) / (buttonWidth + 4));
    const start = Math.max(0, Math.min(total - numButtons, current - Math.floor(numButtons / 2)));
    return (
        <Box display={"flex"} flexGrow={1} justifyContent={"center"} width={0} ref={ref}>
            {Array.from({length: numButtons}, (_, i) => (
                (start + i) < total ? <Button
                    key={start + i}
                    variant={start + i + 1 === current ? "contained" : undefined}
                    sx={{
                        width: `${buttonWidth}px`,
                        height: `${buttonHeight}px`,
                        padding: '0',
                        marginRight: '4px',
                        minWidth: '0'
                    }}
                    onClick={() => onPageChange(start + i + 1)}
                >
                    {start + i + 1}
                </Button> : null
            ))}
        </Box>
    )
}

export const Pagination: React.FC<PaginationProps> = ({
                                                          pagination,
                                                          onPageChange,
                                                          onPageSizeChange,
                                                          sticky = false,
                                                          flat = false,
                                                          style,
                                                      }) => {
    const theme = useTheme();
    const [pageInput, setPageInput] = useState<string>(pagination.page!.toString());
    const [pageSize, setPageSize] = useState<string>(pagination.pageSize!.toString());
    useEffect(() => {
        setPageInput(pagination.page!.toString());
    }, [pagination.page]);
    useEffect(() => {
        setPageSize(pagination.pageSize!.toString());
    }, [pagination.pageSize]);


    const handleFirstPageButtonClick = () => onPageChange(1);
    const handleBackButtonClick = () => onPageChange(pagination.page! - 1);
    const handleNextButtonClick = () => onPageChange(pagination.page! + 1);
    const handleLastPageButtonClick = () => onPageChange(pagination.nPages!);

    const handlePageInputChange: React.ChangeEventHandler<HTMLInputElement> = (
        event
    ) => {
        setPageInput(event.target.value);
    };

    const handlePageInputBlur: any = (
        event: any
    ) => {
        const newPage = parseInt(event.target.value, 10);
        if (!Number.isNaN(newPage) && newPage !== pagination.page) {
            onPageChange(Math.max(1, Math.min(newPage, pagination.nPages!)));
        }
        setTimeout(() => {
            setPageInput(pagination.page!.toString());
        }, 300);
    };

    const handlePageSizeInputChange: React.ChangeEventHandler<HTMLInputElement> = (
        event
    ) => {
        setPageSize(event.target.value);
    };

    const handlePageSizeInputBlur: any = (
        event: any
    ) => {
        const newPageSize = parseInt(event.target.value, 10);
        if (!Number.isNaN(newPageSize) && newPageSize !== pagination.pageSize) {
            onPageSizeChange(Math.max(8, Math.min(500, newPageSize)));
        }
        setTimeout(() => {
            setPageSize(pagination.pageSize!.toString());
        }, 300);
    };

    return (
        <PaginationToolbar style={{
            position: sticky ? 'sticky' : undefined,
            bottom: sticky ? 0 : undefined,
            boxShadow: flat ? 'none' : undefined,
            borderTop: flat ? `1px solid ${theme.palette.divider}` : undefined,
            padding: flat ? `${D.unit(1)} ${D.unit(2)}` : undefined,
            ...style
        }}>
            <TextField
                type="number"
                size={"small"}
                sx={{width: D.interactive(2), margin: '0px 4px', padding: '0px 4px'}}
                inputProps={{style: {margin: 0, padding: 0, textAlign: 'center'}}}
                value={pageSize}
                label={'na stran'}
                onKeyDown={(e: any) => {
                    if (e.key === 'Enter') handlePageSizeInputBlur(e);
                }}
                onChange={handlePageSizeInputChange}
                onBlur={handlePageSizeInputBlur}
            />
            <IconButton onClick={handleFirstPageButtonClick} disabled={pagination.page === 1}>
                <FirstPageIcon/>
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={pagination.page === 1}>
                <KeyboardArrowLeft/>
            </IconButton>
            <PageNumbers
                total={pagination.nPages!}
                current={pagination.page!}
                onPageChange={(p) => {
                    if (p !== pagination.page!) onPageChange(p);
                }}
            />
            <TextField
                type="number"
                size={"small"}
                sx={{width: D.interactive(2), margin: '0px 4px', padding: '0px 4px'}}
                inputProps={{style: {margin: 0, padding: 0, textAlign: 'center'}}}
                value={pageInput}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') handlePageInputBlur(e);
                }}
                onChange={handlePageInputChange}
                onBlur={handlePageInputBlur}
            />
            <Typography>od {pagination.nPages}, {pagination.total} vnosov</Typography>
            <IconButton onClick={handleNextButtonClick} disabled={pagination.page === pagination.nPages}>
                <KeyboardArrowRight/>
            </IconButton>
            <IconButton onClick={handleLastPageButtonClick} disabled={pagination.page === pagination.nPages}>
                <LastPageIcon/>
            </IconButton>
        </PaginationToolbar>
    );
};