import {observer} from "mobx-react-lite";
import {Frame} from "../../../elementary/Frame";
import React, {useContext, useEffect, useState} from "react";
import {Logo} from "../../../elementary/Logo";
import {ButtonIconOutline, ButtonOutline} from "../../../elementary/Buttons";
import {D} from "../../../../app/constants";
import {ArrowForwardIos, CheckCircle, CheckCircleOutline, Event} from "@mui/icons-material";
import {Divider, TextField} from "@mui/material";
import {ProjectMembers} from "./ProjectMembers";
import {Space} from "../../../elementary/Space";
import {ProjectStoreContext} from "../../../../stores/ProjectStore";
import {LoadingOverlay} from "../../../elementary/LoadingOverlay";
import {Globals} from "../../../../index";
import {RecorderProjectStoreContext} from "../../../../recorder/stores/RecorderProjectStore";
import {asod_members} from "../../../../proto/compiled";
import {WebserviceProjectStoreContext} from "../../../../webservice/stores/WebserviceProjectStore";
import {DeepLinks} from "../webservice/DeepLinks";
import {WebserviceContextStoreContext} from "../../../../webservice/stores/WebserviceContextStore";
import {useNavigate} from "react-router-dom";
import {WebserviceRoutes} from "../../../../webservice/Routes";
import {AppContextStoreContext} from "../../../../stores/AppContextStore";
import {autorun} from "mobx";

interface ProjectSideBarProps {

}

export const ProjectSideBar = observer((props: ProjectSideBarProps) => {
    const recorder = useContext(RecorderProjectStoreContext);
    const webservice = useContext(WebserviceProjectStoreContext);
    const features = useContext(AppContextStoreContext)!.features;
    const store = useContext(ProjectStoreContext)!;
    const navigate = useNavigate();
    const [opNumber, setOpNumber] = useState(store.meta.opNumber);
    useEffect(() => {
        return autorun((d) => {
            if (store.meta.opNumber?.length) {
                setOpNumber(store.meta.opNumber);
            }
        });
    }, []);
    return <Frame style={{flexGrow: 1}} alignItems={'stretch'} gap={2}>
        <Space/>
        <Logo onClick={webservice ? () => navigate(WebserviceRoutes.index) : undefined}
              style={{margin: `${D.unit(2)} auto`}}/>
        <Frame gap={2} style={{flexGrow: 1, flexBasis: 0, overflow: 'auto'}} padding={2}>
            {recorder && <ButtonOutline>
                <Event/> Izberi dogodek in predizpolni
            </ButtonOutline>}
            {recorder && <Divider/>}
            <TextField
                label={'Opravilna številka'}
                className={!features.editCourtNumber ? 'text-readonly' : undefined}
                autoComplete={'off'}
                value={opNumber}
                onChange={features.editCourtNumber ? (e) => setOpNumber(e.target.value) : undefined}
                onBlur={features.editCourtNumber ? () => {
                    if (opNumber !== store.meta.opNumber) {
                        store.meta.opNumber = opNumber;
                        store.meta.saveOpNumber();
                    }
                } : undefined}
            />
            <ProjectMembers
                members={store.meta.members}
                onChange={features.editSession ? (m) => store.meta.saveMember(m) : undefined}
                onAdd={features.editSession ? (m) => {
                    if (!recorder) {
                        store.meta.members.push(m);
                    }
                    store.meta.saveMember(m);
                } : undefined}
            />
            {features.showDeeplinks && webservice && <>
                <DeepLinks deepLinks={webservice.deepLinks} onCreated={(dl) => webservice?.deepLinks.push(dl)}/>
            </>}
            {recorder && <Divider/>}
            {recorder && <ButtonIconOutline style={{marginTop: 'auto'}} onClick={() => {
                recorder.completeProject();
            }}>
                <CheckCircleOutline/>
                Zaključi obravnavo
                <ArrowForwardIos style={{marginLeft: 'auto'}}/>
            </ButtonIconOutline>}
        </Frame>
        <LoadingOverlay visible={store.meta.loading} delay={300}/>
    </Frame>;
});