import {makeAutoObservable} from "mobx";
import {AppContextStore} from "../AppContextStore";
import {asod_project} from "../../proto/compiled";
import {proto} from "../../proto/messages";
import {ErrorUtils} from "../../utils/ErrorUtils";
import {ProjectStore} from "../ProjectStore";

export class AnnotationsStore {
    private project: ProjectStore;
    private context: AppContextStore;
    focusedComment?: string;

    constructor(context: AppContextStore, project: ProjectStore) {
        this.context = context;
        this.project = project;
        makeAutoObservable(this);
    }

    async addComment(block: asod_project.IAsodProjectAudioBlock) {
        try {
            const response = await this.context.api.fetch(proto.TxAsodProjectAddComment.create({
                projectId: this.project.projectId,
                timestamp: new Date().getTime(),
            }), new proto.RxAsodProjectComment());
            block.comments ??= [];
            block.comments.push(response.proto);
            block.comments.sort((a, b) => (a.timestamp as number) - (b.timestamp as number));
            this.focusedComment = response.proto.id;
        } catch (e) {
            ErrorUtils.fromFetch(e);
        }
    }

    dispose() {

    }

    async saveComment(blockId: string, comment: asod_project.IAsodProjectComment) {
        const response = await this.context.api.sendMessage(proto.TxAsodProjectSaveComment.create({
            blockId: blockId,
            comment: comment,
        }), {ack: true});
        ErrorUtils.response(response);
    }
}