import {observer} from "mobx-react-lite";
import {CommonProps} from "../components";
import React from "react";
import {Frame} from "../elementary/Frame";
import {useTheme} from "@mui/material";
import {D} from "../../app/constants";

interface SidebarLayoutProps extends CommonProps {
    width?: any;
}

export const SidebarLayout = observer((props: SidebarLayoutProps) => {
    const theme = useTheme();
    const isDark = theme.palette.mode === 'dark';
    if (props.children?.length !== 2) throw new Error('SidebarLayout must have exactly two children.');
    return <Frame direction={'row'} fill style={props.style}>
        <Frame style={{width: props.width ?? '350px'}}>
            {props.children[0]}
        </Frame>
        <Frame fill appearance={'card'} margin={0} padding={0} shadow={10}>
            {props.children[1]}
        </Frame>
    </Frame>
});