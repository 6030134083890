import {observer} from "mobx-react-lite";
import {Frame} from "../../components/elementary/Frame";
import {Logo} from "../../components/elementary/Logo";
import {
    ArrowForwardIos, Cancel, Check,
    Download,
    DownloadForOffline, DownloadForOfflineOutlined, Info,
    InfoOutlined,
    Language,
    PersonOutline
} from "@mui/icons-material";
import {TextField, Typography} from "@mui/material";
import React, {useContext, useEffect, useMemo} from "react";
import {RecorderContextStoreContext} from "../stores/RecorderContextStore";
import {RecorderPairingStore, RecorderPairingStoreContext} from "../stores/RecorderPairingStore";
import {Space} from "../../components/elementary/Space";
import {ButtonIconOutline} from "../../components/elementary/Buttons";
import {LoadingOverlay} from "../../components/elementary/LoadingOverlay";
import {useNavigate} from "react-router-dom";
import {RecorderRoutes} from "../Routes";
import {SsoDialog} from "../../components/dialogs/SsoDialog";
import {SelectInput} from "../../components/inputs/SelectInput";
import {AsodAppBar} from "../../fragments/AsodAppBar";

export const PairingPage = observer(() => {
    const context = useContext(RecorderContextStoreContext)!;
    const store = useMemo(() => new RecorderPairingStore(context), [context]);
    const navigate = useNavigate();
    useEffect(() => {
        if (context.hasState && context.isPaired) {
            navigate(RecorderRoutes.login);
        }
    }, [context.hasState, context.isPaired, navigate]);
    useEffect(() => () => store.dispose(), [store]);

    return <Frame alignItems={'stretch'} fill padding={2}>
        <AsodAppBar/>
        <Frame fill center>
            <Logo/>
            <Space s={7}/>
            {context.isCSRGenerated && <WaitingForCertificate store={store}/>}
            {!context.isCSRGenerated && <>
                {!context.hasPairingCertificate && <WaitingForPairingUsb/>}
                {context.hasPairingCertificate && <RecorderPairingStoreContext.Provider value={store}>
                    <GenerateCsr/>
                </RecorderPairingStoreContext.Provider>}
                <LoadingOverlay visible={store.loading || !!context.app.auth.ssoUrl}/>
            </>}
        </Frame>
    </Frame>;
});

const WaitingForCertificate = observer(({store}: { store: RecorderPairingStore }) => {
    return <>
        <Frame appearance={'filled-outline'} width={60} padding={2} row gap={2} center
               style={{maxWidth: '700px'}}>
            <Check fontSize={'large'}/>
            <Typography variant={'h5'}>
                Zahtevek za uparitev je bil poslan in čaka na odobritev.
            </Typography>
        </Frame>
        <Space s={2}/>
        <Frame appearance={'filled-outline'} width={60} padding={2} row gap={2} center
               style={{maxWidth: '700px'}}>
            <Info fontSize={'large'}/>
            <Typography variant={'h5'}>
                Za uparitev brez povezave, vstavite USB ključ z odobrenim zahtevkom.
            </Typography>
        </Frame>
        <Space s={5}/>
        <ButtonIconOutline onClick={() => store.cancelCSR()}>
            <Cancel/>
            Prekliči zahtevek za uparjanje
            <ArrowForwardIos style={{marginLeft: 'auto'}}/>
        </ButtonIconOutline>
    </>;
});
const WaitingForPairingUsb = () => (<Frame appearance={'filled-outline'} padding={2} row gap={2} center
                                           style={{maxWidth: '700px'}}>
    <InfoOutlined fontSize={'large'}/>
    <Typography variant={'h5'}>
        Naprava še ni uparjena s centralnim sistemom. Za nadaljevanje vstavite USB ključ z ustreznimi
        pooblastili.
    </Typography>
</Frame>);


const GenerateCsr = observer(() => {
    const store = useContext(RecorderPairingStoreContext)!;
    const context = useContext(RecorderContextStoreContext)!;
    return <Frame width={50} centerText gap={2}>
        <SelectInput
            options={context.app.constants.courts.map(c => ({
                id: c.id!,
                label: c.name!,
            }))}
            onChange={(v) => store.metadata.court = v.id ?? ''}
            textField={{
                name: 'court',
                label: 'Sodišče',
                value: store.metadata.court,
                inputProps: {
                    autocomplete: "off",
                }
            }}
        />
        <TextField
            name={'courtroom'}
            label={'Sodna dvorana'}
            inputProps={{
                autocomplete: "off",
            }}
            value={store.metadata.courtroom}
            onChange={(e) => store.metadata.courtroom = e.target.value}
        />
        <Space/>
        {context.webserviceState.isConnected &&
            <ButtonIconOutline disabled={!store.metadataValid} onClick={() => store.pair()}>
                <Language/>
                Pošlji na strežnik
                <ArrowForwardIos style={{marginLeft: 'auto'}}/>
            </ButtonIconOutline>}
        {!context.webserviceState.isConnected &&
            <ButtonIconOutline disabled={!store.metadataValid} onClick={() => store.saveCsrToUsb()}>
                <DownloadForOfflineOutlined/>
                Shrani na USB
                <ArrowForwardIos style={{marginLeft: 'auto'}}/>
            </ButtonIconOutline>}
        {context.webserviceState.isConnected && !context.app.auth.loggedIn && <>
            <ButtonIconOutline onClick={() => context.app.auth.login()}>
                <PersonOutline/>
                Prijava za direktno uparjanje
                <ArrowForwardIos style={{marginLeft: 'auto'}}/>
            </ButtonIconOutline>
        </>}
        <SsoDialog/>
    </Frame>;
});