import {observer} from "mobx-react-lite";
import {SidebarLayout} from "../../components/layouts/SidebarLayout";
import React, {useContext, useEffect, useMemo} from "react";
import {AppContextStoreContext} from "../../stores/AppContextStore";
import {UsersSideBar} from "../../components/pages/users/UsersSideBar";
import {UsersStore, UsersStoreContext} from "../stores/UsersStore";
import {UsersContent} from "../../components/pages/users/UsersContent";

export const UsersPage = observer(() => {
    const context = useContext(AppContextStoreContext)!;
    const store = useMemo(() => new UsersStore(context), [context]);
    useEffect(() => () => store.dispose(), [store]);
    return <UsersStoreContext.Provider value={store}>
        <SidebarLayout>
            <UsersSideBar/>
            <UsersContent/>
        </SidebarLayout>
    </UsersStoreContext.Provider>;
});