import {observer} from "mobx-react-lite";
import {SidebarLayout} from "../../components/layouts/SidebarLayout";
import React, {useContext, useEffect, useMemo} from "react";
import {ProjectStore, ProjectStoreContext} from "../../stores/ProjectStore";
import {useParams} from "react-router-dom";
import {AppContextStoreContext} from "../../stores/AppContextStore";
import {WebserviceProjectContent} from "../../components/pages/project/webservice/WebserviceProjectContent";
import {WebserviceProjectStore, WebserviceProjectStoreContext} from "../stores/WebserviceProjectStore";
import {ProjectSideBar} from "../../components/pages/project/common/ProjectSideBar";

export const ProjectPage = observer(() => {
    const {projectId} = useParams();
    const context = useContext(AppContextStoreContext)!;
    const store = useMemo(() => new WebserviceProjectStore(context, projectId!), [context, projectId]);
    useEffect(() => () => store.dispose(), [store]);
    return <WebserviceProjectStoreContext.Provider value={store}>
        <ProjectStoreContext.Provider value={store.project}>
            <SidebarLayout>
                <ProjectSideBar/>
                <WebserviceProjectContent/>
            </SidebarLayout>
        </ProjectStoreContext.Provider>
    </WebserviceProjectStoreContext.Provider>;
});