import {Route, Routes} from "react-router-dom";
import {ProtectedRoute, WebserviceRoutes} from "./Routes";
import {IndexPage} from "./pages";
import {LoginPage} from "./pages/login";
import {ProjectPage} from "./pages/project";
import {UsersPage} from "./pages/users";
import {LogsPage} from "./pages/logs";
import {ManagementPage} from "./pages/management";

export const WebserviceRouter = () => {
    return <Routes>
        <Route path={WebserviceRoutes.login} element={<LoginPage/>}/>
        <Route path={WebserviceRoutes.index} element={<ProtectedRoute><IndexPage/></ProtectedRoute>}/>
        <Route path={WebserviceRoutes.projectRoute} element={<ProtectedRoute><ProjectPage/></ProtectedRoute>}/>
        <Route path={WebserviceRoutes.users} element={<ProtectedRoute><UsersPage/></ProtectedRoute>}/>
        <Route path={WebserviceRoutes.management} element={<ProtectedRoute><ManagementPage/></ProtectedRoute>}/>
        <Route path={WebserviceRoutes.logs} element={<ProtectedRoute><LogsPage/></ProtectedRoute>}/>
    </Routes>;
};