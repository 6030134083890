import {common, ws_logs} from "../../proto/compiled";
import {Typography, useTheme} from "@mui/material";
import {DateUtils} from "../../utils/DateUtils";
import {DataTableData, TableRowData} from "../layouts/DataTable";

export class LogsTable extends DataTableData {
    private logs?: ws_logs.IAsodLogs;

    constructor(logs?: ws_logs.IAsodLogs) {
        super(logs?.pagination);
        this.minWidth = '900px';
        this.logs = logs;
    }

    getHeader() {
        const sortBy = this.logs?.sortBy;
        const sortOrder = this.logs?.sortOrder ?? common.SortOrder.so_none;
        return [
            {
                id: 'date',
                name: 'DATUM',
                ordering: sortBy === ws_logs.AsodLogsOrderingTerm.alot_date ? sortOrder : common.SortOrder.so_none,
                sortable: true,
                maxCharacters: 24,
            },
            {
                id: 'court',
                name: 'MESTO DOGODKA',
                ordering: sortBy === ws_logs.AsodLogsOrderingTerm.alot_court ? sortOrder : common.SortOrder.so_none,
                sortable: true,
                maxCharacters: 30,
            },
            {
                id: 'action',
                name: 'AKCIJA',
                sortable: false,
                maxCharacters: 15,
            },
            {
                id: 'message',
                name: 'SPOROČILO',
                sortable: false,
                flex: 1,
            },
        ];
    }

    getRows(): TableRowData[] {
        return this.logs?.rows!.map((p, i) => ({
            id: p.id!,
            data: p,
            children: [
                <Typography key={'timestamp'} variant={'body2'}>{DateUtils.toDateTimeString({ts: p.timestamp! * 1000})}</Typography>,
                <Typography key={'name'} variant={'body2'}>{[p.courtName, p.courtRoomName, p.deviceType].filter((i) => !!i).join(' > ')}</Typography>,
                <Typography key={'action'} variant={'body2'}>{p.action} {!!p.userDisplayName?.length && `(${p.userDisplayName})`}</Typography>,
                <Typography key={'rawEvent'} variant={'body2'}>{p.rawEvent}</Typography>,
            ],
        })) ?? [];
    }
}

const LogMessage = ({message}: { message: ws_logs.IAsodLogMessage }) => {
    const theme = useTheme();
    return <Typography variant={'body2'} style={{
        display: 'flex',
        alignItems: 'center',
        color: message.isError ? theme.palette.error.main : undefined,
    }}>
        <b>{message.label}</b>: {message.message}
    </Typography>
};