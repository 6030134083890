import {common} from "../proto/compiled";

export class DateUtils {
    static toDateString(obj?: common.IDate | number | Long | null) {
        if (!obj) {
            return "";
        }
        const date = new Date(((obj as any)['ts'] ?? obj) as number);
        return `${date.getDate()}. ${date.getMonth() + 1}. ${date.getFullYear()}`
    }

    static toMonthDateString(obj?: common.IDate | number | Long | null) {
        const months = [
            'januar',
            'februar',
            'marec',
            'april',
            'maj',
            'junij',
            'julij',
            'avgust',
            'september',
            'oktober',
            'november',
            'december',
        ]
        if (!obj) {
            return "";
        }
        const date = new Date(((obj as any)['ts'] ?? obj) as number);
        return `${date.getDate()}. ${months[date.getMonth()]}. ${date.getFullYear()}`
    }

    static toTimeString(obj?: common.IDate | number | Long | null, includeSeconds = true) {
        if (!obj) {
            return "";
        }
        const date = new Date(((obj as any)['ts'] ?? obj) as number);
        return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}${includeSeconds ? `:${date.getSeconds().toString().padStart(2, '0')}` : ''}`
    }

    static toDateTimeString(obj?: common.IDate | number | Long | null, includeSeconds = true) {
        return `${DateUtils.toDateString(obj)} ${DateUtils.toTimeString(obj, includeSeconds)}`
    }

    static getDatePickerFormat(granularity: common.DateGranularity): string {
        switch (granularity) {
            case common.DateGranularity.year:
                return 'YYYY';
            case common.DateGranularity.month:
                return 'MM. YYYY';
            case common.DateGranularity.day:
                return 'DD. MM. YYYY';
            case common.DateGranularity.hour:
                return 'DD. MM. YYYY ob HH';
            case common.DateGranularity.minute:
                return 'DD. MM. YYYY ob HH:mm';
            case common.DateGranularity.second:
                return 'DD. MM. YYYY ob HH:mm:ss';
            default:
                return 'DD. MM. YYYY';
        }
    }

    static secondTimestamp(date?: common.IDate | null) {
        if (!date?.ts) {
            return undefined;
        }
        return Math.round((date.ts as number) / 1000);
    }
}