import {observer} from "mobx-react-lite";
import {useContext} from "react";
import {ProjectStoreContext} from "../stores/ProjectStore";
import {Typography, useTheme} from "@mui/material";
import {DateUtils} from "../utils/DateUtils";
import {Frame} from "../components/elementary/Frame";
import {D} from "../app/constants";
import {Subscriptions} from "@mui/icons-material";

export const LastPlayerTime = observer(() => {
    const store = useContext(ProjectStoreContext);
    const lpt = store?.audio.lastPlayerTime;
    const theme = useTheme();
    if (!store || !lpt) return null;
    return <Frame row height={D.interactive()} appearance={'outline'} center paddingX={2}>
        <Subscriptions style={{
            color: theme.palette.primary.main,
            paddingRight: D.unit(),
            marginLeft: D.unit(-0.5),
            borderRight: `1px solid ${theme.palette.divider}`,
        }}/>
        <Typography variant={'h6'} style={{marginLeft: D.unit()}} fontWeight={'bold'}>
            {DateUtils.toDateTimeString({ts: lpt.getTime()})}
        </Typography>
    </Frame>;
});