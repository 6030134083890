import styled from "@emotion/styled";
import {D} from "../../app/constants";
import {useTheme} from "@mui/material";

export const DialogFooter = ({children, bottom, border}: { children: any, bottom?: boolean, border?: boolean }) => {
    const theme = useTheme();
    return <Container style={{
        marginTop: bottom ? 'auto' : undefined,
        backgroundColor: border ? 'transparent' : undefined,
        borderTop: border ? `1px solid ${theme.palette.divider}` : undefined,
        boxShadow: border ? 'none' : undefined
    }}>
        {children}
    </Container>
};

const Container = styled.div`
  padding: 0 ${D.unit(4)};
  margin: 0 ${D.unit(-4)};
  margin-bottom: ${D.unit(-4)};
  height: ${D.interactive(2)};
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.15);
  background-color: ${({theme}) => theme.palette.background.paper};
  gap: ${D.unit(2)};
`;