import {css} from "@emotion/react";

export const CssFlex = css`
  display: flex;
  flex-shrink: 0;
`;

export const CssFlexCenter = css`
  ${CssFlex};
  align-items: center;
  justify-content: center;
`;