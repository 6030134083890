import {observer} from "mobx-react-lite";
import styled from "@emotion/styled";
import {D} from "../../../../app/constants";
import {useContext, useEffect, useState} from "react";
import {AppContextStore, AppContextStoreContext} from "../../../../stores/AppContextStore";
import {Frame} from "../../../elementary/Frame";
import {Typography, useTheme} from "@mui/material";
import {LanguageOutlined} from "@mui/icons-material";
import {DateUtils} from "../../../../utils/DateUtils";
import {RecorderProjectStoreContext} from "../../../../recorder/stores/RecorderProjectStore";
import {RecorderContextStoreContext} from "../../../../recorder/stores/RecorderContextStore";

export const RecorderProjectBottomBar = observer(() => {
    return <Container>
        <ConnectionIndicator/>
        <UploadProgressIndicator/>
        <DateAndTime/>
    </Container>
});

const ConnectionIndicator = observer(() => {
    const context = useContext(AppContextStoreContext)!;
    const theme = useTheme();
    const color = context.isConnected ? theme.palette.success.main : theme.palette.error.main;
    return <Frame row gap={1} center style={{color: color}}>
        <LanguageOutlined fontSize={"large"}/>
        <Typography variant={"h5"} style={{width: D.unit(20), color: color, transition: 'all 0.3s'}}>
            {context.isConnected ? "Povezava je vzpostavljena" : "Ni povezave s strežnikom"}
        </Typography>
    </Frame>
});

const UploadProgressIndicator = observer(() => {
    const context = useContext(RecorderContextStoreContext)!;
    const project = useContext(RecorderProjectStoreContext);
    const hasErrors = !!project?.uploadErrors?.errors?.length;
    return <Typography variant={"h5"} style={{
        position: 'absolute',
        left: 0,
        right: 0,
        textAlign: 'center',
    }}>
        {!context.uploadState && 'SINHRONIZACIJA NI NA VOLJO'}
        {!!context.uploadState && (!context.uploadState.remainingActions ? hasErrors ? `SINHRONIZACIJA NI USPELA` : 'STANJE JE USKLAJENO S STREŽNIKOM' : `SINHRONIZACIJA V TEKU (ŠE ${context.uploadState?.remainingActions} DOGODKOV)`)}
    </Typography>
});

const DateAndTime = () => {
    const [date, setDate] = useState(new Date());
    useEffect(() => {
        const interval = setInterval(() => {
            setDate(new Date());
        }, 1000);
        return () => {
            clearInterval(interval);
        }
    }, []);
    return <Frame alignItems={'flex-end'}>
        <Typography variant={"h4"}>{DateUtils.toTimeString({ts: date.getTime()})}</Typography>
        <Typography variant={"h5"}>{DateUtils.toMonthDateString({ts: date.getTime()})}</Typography>
    </Frame>
}

const Container = styled.div`
  margin: ${D.unit(-2)};
  margin-top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${D.unit(2)};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  height: ${D.interactive(2)};
`;