import {SocketApi} from "proto_socket_typescript";
import {proto} from "../proto/messages";
import {common, ws_logs} from "../proto/compiled";
import {AppContextStore} from "../stores/AppContextStore";
import {WsConnector} from "./ws_connector";
import {DateUtils} from "../utils/DateUtils";

export class WsLogsConnector {
    context: AppContextStore;
    api: SocketApi;

    constructor(context: AppContextStore) {
        this.context = context;
        this.api = context.api;

        this.api.getTxMessageHandler(proto.TxLoadAsodLogs.create(), {intercept: true}).subscribe((e) => this.onTxLoadAsodLogs(e));
        this.api.getMessageHandler(new proto.RxApiLogsAuditLogs()).subscribe((e) => this.onRxApiLogsAuditLogs(e));
    }

    private onTxLoadAsodLogs(e: proto.TxLoadAsodLogs) {
        this.api.sendMessage(proto.TxApiLogsGetAuditLogs.create({
            courtFilter: e.proto.court,
            courtRoomFilter: e.proto.courtroom,
            actionFilter: e.proto.action,
            actionKindFilter: e.proto.actionKind,
            epochFrom: DateUtils.secondTimestamp(e.proto.dateFrom),
            epochTo: DateUtils.secondTimestamp(e.proto.dateTo),
            ...WsConnector.fromPagination(e.proto.pagination)
        }))
    }

    private onRxApiLogsAuditLogs(m: proto.RxApiLogsAuditLogs) {
        const message = new proto.RxAsodLogs();
        message.proto = ws_logs.AsodLogs.create({
            rows: m.proto.auditLogs,
            pagination: WsConnector.toPagination(m.proto),
        });
        this.api.sendLocalUpdate(message)
    }
}