import {observer} from "mobx-react-lite";
import React, {useContext, useEffect} from "react";
import {AppContextStoreContext} from "../stores/AppContextStore";
import {useNavigate} from "react-router-dom";
import {LoadingOverlay} from "../components/elementary/LoadingOverlay";

export abstract class RecorderRoutes {
    static pairing = '/pairing';
    static login = '/login';
    static index = '/';

    static get waitForAuth() {
        return `${window.location.origin}`
    }
}

export const ProtectedRoute = observer((props: { children: any }) => {
    const navigate = useNavigate();
    const context = useContext(AppContextStoreContext)
    useEffect(() => {
        setTimeout(() => {
            if (!context?.auth.loggedInAndHasUser) {
                navigate(RecorderRoutes.login);
            }
        }, 500);
    }, [navigate, context?.auth.loggedInAndHasUser]);
    if (!context?.auth.loggedInAndHasUser) {
        return <LoadingOverlay visible/>;
    }
    return props.children;
});