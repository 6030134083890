import {createContext} from "react";
import {ProjectStore} from "../../stores/ProjectStore";
import {makeAutoObservable} from "mobx";
import {AppContextStore} from "../../stores/AppContextStore";
import {DisposeUtils} from "../../utils/DisposeUtils";
import {api_deeplinks} from "../../proto/compiled";
import {proto} from "../../proto/messages";

export const WebserviceProjectStoreContext = createContext<WebserviceProjectStore | undefined>(undefined);

export class WebserviceProjectStore {
    private disposeUtils = new DisposeUtils();
    context: AppContextStore;
    project: ProjectStore;
    deepLinks: api_deeplinks.IApiDeeplinksSessionDeeplink[] = [];

    constructor(context: AppContextStore, projectId: string) {
        this.context = context;
        this.project = new ProjectStore(context, projectId);
        makeAutoObservable(this);
        this.disposeUtils.add(
            this.context.api.getMessageHandler(new proto.RxApiDeeplinksSessionDeeplinks()).subscribe((m) => this.onSessionDeeplink(m))
        );
    }

    async load() {
        await this.project.load();
    }

    dispose() {
        this.project.dispose();
        this.disposeUtils.dispose();
    }

    private onSessionDeeplink(m: proto.RxApiDeeplinksSessionDeeplinks) {
        this.deepLinks = m.proto.deeplinks;
    }
}

