import {DropdownAnything} from "../../common/DropdownAnything";
import {observer} from "mobx-react-lite";
import {
    BugReportOutlined,
    CloseOutlined,
    ConstructionOutlined,
    EnhancedEncryptionOutlined,
    GraphicEq,
    GridViewOutlined,
} from "@mui/icons-material";
import React, {useContext} from "react";
import {ButtonOutline} from "../../elementary/Buttons";
import {Card, IconButton, Typography, useTheme} from "@mui/material";
import {D} from "../../../app/constants";
import {Frame} from "../../elementary/Frame";
import styled from "@emotion/styled";
import {CssFlexCenter} from "../../base/flex";
import useWave from "use-wave";
import {useNavigate} from "react-router-dom";
import {WebserviceRoutes} from "../../../webservice/Routes";
import {AppContextStore, AppContextStoreContext, AsodVariant} from "../../../stores/AppContextStore";
import {SignatureVerification} from "../../../fragments/SignatureVerification";
import {WebserviceContextStoreContext} from "../../../webservice/stores/WebserviceContextStore";

export const WebserviceApps = observer(() => {
    const theme = useTheme();
    const navigate = useNavigate();
    const features = useContext(AppContextStoreContext)!.features;
    const [preverjanjePodpisov, setPreverjanjePodpisov] = React.useState(false);
    if (!features.apps) return null;
    return <>
        <DropdownAnything
            barrierDismissible={true}
            triggerBuilder={(ref, isOpen, setIsOpen) => <div ref={ref}>
                <ButtonOutline onClick={() => setIsOpen(!isOpen)}>
                    <GridViewOutlined/>
                </ButtonOutline>
            </div>}
            builder={(_, close) => <Card elevation={8} style={{
                width: D.interactive(10),
                margin: `${D.interactive(-1)} 0 0 ${D.interactive(-8.95)}`,
            }}>
                <Frame row center background={theme.palette.background.paper} style={{
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    height: D.interactive(),
                }}>
                    <Typography variant={'button'} style={{marginLeft: D.unit(2)}}>
                        Aplikacije
                    </Typography>
                    <IconButton style={{marginLeft: 'auto', aspectRatio: 1}} onClick={() => close()}>
                        <Typography variant={'button'} style={{display: 'contents'}}>
                            <CloseOutlined/>
                        </Typography>
                    </IconButton>
                </Frame>
                <Frame background={theme.palette.background.default}>
                    <AppItem
                        onClick={() => {
                            navigate(WebserviceRoutes.index);
                            close();
                        }}
                        icon={<GraphicEq fontSize={'large'}/>}
                        title={'Obravnave'}
                        description={'Pregled in iskanje po vseh obravnavah.'}
                    />
                    <AppItem
                        onClick={() => {
                            navigate(WebserviceRoutes.management);
                            close();
                        }}
                        icon={<ConstructionOutlined fontSize={'large'}/>}
                        title={'Upravljanje sistema'}
                        description={'Pregled stanja sistema, nameščanje posodobitev, uparjanje snemalnih naprav.'}
                    />
                    <AppItem
                        onClick={() => {
                            setPreverjanjePodpisov(true);
                            close();
                        }}
                        icon={<EnhancedEncryptionOutlined fontSize={'large'}/>}
                        title={'Preverjanje podpisov posnetkov'}
                        description={'Preveri digitalne podpise vseh zvočnih posnetkov in obravnav.'}
                    />
                    <AppItem
                        onClick={() => {
                            navigate(WebserviceRoutes.logs);
                            close();
                        }}
                        icon={<BugReportOutlined fontSize={'large'}/>}
                        title={'Revizijske sledi'}
                        description={'Pregled izvedenih akcij, prijav in sistemskih dogodkov.'}
                    />
                </Frame>
            </Card>}
        />
        <SignatureVerification onClose={() => setPreverjanjePodpisov(false)} isOpen={preverjanjePodpisov}/>
    </>
});

const AppItem = (props: { onClick: () => any, icon: any, title: string, description: string }) => {
    const wave = useWave();
    return <Hoverable
        onClick={() => props.onClick()}
        clickable={true}
        ref={wave}
        row
        alignItems={'center'}
        gap={2}
        padding={1}
        style={{
            borderBottom: `1px solid ${useTheme().palette.divider}`,
        }}>
        <OutlinedIcon>
            {props.icon}
        </OutlinedIcon>
        <Frame fill justifyContent={'center'} style={{pointerEvents: 'none'}}>
            <Typography variant={'button'} lineHeight={1.1}>
                {props.title}
            </Typography>
            <Typography variant={'caption'} lineHeight={1.1}>
                {props.description}
            </Typography>
        </Frame>
    </Hoverable>;
};

const Hoverable = styled(Frame)`
  &:hover {
    background-color: ${({theme}) => theme.palette.action.hover};
  }
`;

const OutlinedIcon = styled.div`
  ${CssFlexCenter};
  width: ${D.interactive(1.3)};
  height: ${D.interactive(1.3)};
  border: solid 1px ${({theme}) => theme.palette.divider};
  border-radius: ${D.radius()};
  color: ${({theme}) => theme.palette.primary.main};
`;