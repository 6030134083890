import {createContext} from "react";
import {autorun, makeAutoObservable, ObservableSet} from "mobx";
import {AppContextStore} from "./AppContextStore";
import {DataTableData, TableRowData} from "../components/layouts/DataTable";
import {DisposeUtils} from "../utils/DisposeUtils";

export class ProtoTableStore {
    private app: AppContextStore;
    private disposeUtils = new DisposeUtils();
    data: DataTableData;
    selectedRows = new ObservableSet<string>();
    startI = -1;
    adding = false;
    canSelect: boolean;

    constructor(app: AppContextStore, data: DataTableData, canSelect = false) {
        this.app = app;
        this.data = data;
        this.canSelect = canSelect;
        makeAutoObservable(this);
        this.disposeUtils.add(autorun(() => {
            if (!this.app.ctrlPressed && !this.app.shiftPressed) {
                this.startI = -1;
            }
        }));
    }

    onSelect(rows: TableRowData[], i: number) {
        const rid = rows[i].id;
        if (this.app.shiftPressed) {
            if (this.startI < 0) {
                this.startI = i;
                this.toggle(rid);
            } else {
                for (let j = Math.min(this.startI, i); j <= Math.max(this.startI, i); j++) {
                    if (this.adding) {
                        this.selectedRows.add(rows[j].id!);
                    } else {
                        this.selectedRows.delete(rows[j].id!);
                    }
                }
                this.startI = -1;
            }
        } else {
            this.startI = -1;
            this.toggle(rid);
        }
    }

    toggle(id: string) {
        if (this.selectedRows.has(id)) {
            this.selectedRows.delete(id);
            this.adding = false;
        } else {
            this.selectedRows.add(id);
            this.adding = true;
        }
    }

    get isSelected() {
        return !!this.selectedRows.size;
    }

    dispose() {
        this.disposeUtils.dispose();
    }
}

export const ProtoTableStoreContext = createContext<ProtoTableStore | undefined>(undefined);