import {makeAutoObservable} from "mobx";
import * as tus from 'tus-js-client'
import {proto} from "../proto/messages";
import {toast} from "react-toastify";
import {uploader} from "../proto/compiled";
import {createContext} from "react";
import {ErrorUtils} from "../utils/ErrorUtils";
import {AppContextStore} from "./AppContextStore";

export class UploadStore {
    private disposed = false;
    tusUpload?: tus.Upload = undefined;
    uploadTask?: uploader.IUploadTask = undefined;
    file?: File = undefined;
    uploadedFile?: uploader.IUFile = undefined;

    started = false;
    progress?: number = undefined;
    done = false;
    error?: string = undefined;
    private readonly extensions?: Set<string>;
    private context: AppContextStore;

    constructor(context: AppContextStore, extensions?: Set<string>) {
        this.context = context;
        this.extensions = extensions;
        makeAutoObservable(this);
    }

    dispose() {
        this.disposed = true;
        if (this.started && !this.done) {
            this.tusUpload?.abort(true);
        }
    }

    async uploadFile(acceptedFiles: File[]) {
        try {
            return await this._uploadFile(acceptedFiles);
        } catch (e) {
            ErrorUtils.fromFetch(e);
            await this.cancel();
        }
    }

    private async _uploadFile(acceptedFiles: File[]) {
        if (!acceptedFiles.length) return;
        if (acceptedFiles.length > 1) {
            toast.error('Izberite eno datoteko!');
            return;
        }
        const file = acceptedFiles[0];
        const fnParts = file.name.toLowerCase().split('.');
        if (this.extensions && !this.extensions.has(fnParts[fnParts.length - 1])) {
            toast.error('Nepodprt format!');
            return;
        }
        const fingerprint = Math.round(Math.random() * 10000000000).toString();
        const task = uploader.UploadTask.create({
            status: uploader.UploadStatus.scheduled,
            created: Date.now(),
            name: file.name,
            path: file.webkitRelativePath,
            fingerprint: fingerprint,
            mime: file.type,
        });
        this.uploadTask = task;
        this.file = file;
        return await this.onUploadSlot(await this.context.api.fetch(proto.TxUploadUFile.create({task: task}), new proto.RxUploadSlot()));
    }

    private onUploadSlot(m: proto.RxUploadSlot) {
        if (!this.file || !this.uploadTask || this.uploadTask.fingerprint !== m.proto.file?.uploadTask) return;
        this.uploadedFile = m.proto.file ?? undefined;
        this.started = true;
        const cs = this.file.size / 20;
        const min = 200 * 1000;
        const max = 100 * 1000 * 1000;
        const endpoint = `${window.location.protocol}//${window.location.host}/tus/`.replaceAll(':3000', ':9000');
        return new Promise((resolve, reject) => {
            this.tusUpload = new tus.Upload(this.file!, {
                endpoint: endpoint,
                retryDelays: [0, 3000, 5000, 10000, 20000],
                metadata: m.proto.metadata,
                chunkSize: cs < min ? min : cs > max ? max : cs,
                onError: (error) => {
                    this.error = error.toString();
                    this.done = true;
                    reject(error);
                },
                onProgress: (bytesUploaded, bytesTotal) => {
                    this.progress = bytesUploaded / bytesTotal;
                },
                onSuccess: () => {
                    this.done = true;
                    resolve(this.uploadedFile);
                }
            });
            this.tusUpload.start();
        });
    }

    async cancel() {
        if (this.started && !this.done) {
            await this.tusUpload?.abort(true);
        }
        this.tusUpload = undefined;
        this.uploadTask = undefined;
        this.file = undefined;
        this.progress = undefined;
        this.error = undefined;
        this.started = false;
        this.done = false;
        this.uploadedFile = undefined;
    }
}

export const UploadStoreContext = createContext<UploadStore | undefined>(undefined);