import {observer} from "mobx-react-lite";
import {Frame} from "../../components/elementary/Frame";
import {ButtonIconOutline} from "../../components/elementary/Buttons";
import {ArrowForwardIos, PersonOutline, PublicOff} from "@mui/icons-material";
import {D} from "../../app/constants";
import React, {useContext, useEffect} from "react";
import {Space} from "../../components/elementary/Space";
import {Logo} from "../../components/elementary/Logo";
import {useNavigate} from "react-router-dom";
import {RecorderRoutes} from "../Routes";
import {RecorderContextStoreContext} from "../stores/RecorderContextStore";
import {LoadingOverlay} from "../../components/elementary/LoadingOverlay";
import {SsoDialog} from "../../components/dialogs/SsoDialog";

export const LoginPage = observer(() => {
    const context = useContext(RecorderContextStoreContext)!;
    const navigate = useNavigate();
    useEffect(() => {
        if (context.hasState && !context.isPaired) {
            navigate(RecorderRoutes.pairing);
        }
    }, [context.hasState, context.isPaired, navigate]);
    useEffect(() => {
        if (context.app.auth.loggedInAndHasUser) {
            navigate(RecorderRoutes.index);
        }
    }, [context.app.auth.loggedInAndHasUser, navigate]);

    const [loading, setLoading] = React.useState(false);

    return <>
        <Frame center
               padding={8}
               grow
               gap={2}>
            <Logo style={{alignSelf: 'center', marginBottom: 'auto', marginTop: 'auto'}}/>
            <Space s={8}/>
            <ButtonIconOutline
                style={{width: D.unit(50), height: D.interactive(1.5), paddingRight: D.unit()}}
                onClick={async () => {
                    setLoading(true);
                    await context.app.auth.login();
                    setLoading(false);
                }}
            >
                <PersonOutline fontSize={'large'}/>
                <Space s={0.5}/>
                PRIJAVA
                <ArrowForwardIos style={{marginLeft: 'auto'}}/>
            </ButtonIconOutline>
            <ButtonIconOutline onClick={() => {
                navigate(RecorderRoutes.index);
            }} style={{width: D.unit(50), height: D.interactive(1.5), paddingRight: D.unit()}}>
                <PublicOff fontSize={'large'}/>
                <Space s={0.5}/>
                NAČIN BREZ POVEZAVE
                <ArrowForwardIos style={{marginLeft: 'auto'}}/>
            </ButtonIconOutline>
        </Frame>
        <LoadingOverlay visible={!context.hasState || loading}/>
        <SsoDialog/>
    </>;
});