import {Route, Routes} from "react-router-dom";
import {ProtectedRoute, RecorderRoutes} from "./Routes";
import {IndexPage} from "./pages";
import {LoginPage} from "./pages/login";
import {PairingPage} from "./pages/pairing";

export const RecorderRouter = () => {
    return <Routes>
        <Route path={RecorderRoutes.pairing} element={<PairingPage/>}/>
        <Route path={RecorderRoutes.login} element={<LoginPage/>}/>
        <Route path={RecorderRoutes.index} element={<ProtectedRoute><IndexPage/></ProtectedRoute>}/>
    </Routes>;
};