import React from "react";
import {AppContextStore} from "../../stores/AppContextStore";
import {proto} from "../../proto/messages";
import {common, ws_home} from "../../proto/compiled";
import {ErrorUtils} from "../../utils/ErrorUtils";
import {makeAutoObservable} from "mobx";
import {ProtoTableStore} from "../../stores/ProtoTableStore";
import {Globals} from "../../index";
import {DummyAsodProjects} from "../../testing/data/DummyAsodProjects";
import {ProjectsTable} from "../../components/tables/ProjectsTable";
import {DataTableData} from "../../components/layouts/DataTable";
import {DisposeUtils} from "../../utils/DisposeUtils";

export const ProjectsStoreContext = React.createContext<ProjectsStore | undefined>(undefined);

export class ProjectsStore {
    app: AppContextStore;
    private disposeUtils = new DisposeUtils();
    loadData = ws_home.LoadAsodProjects.create({
        dateFrom: common.DateFilter.create(),
        dateTo: common.DateFilter.create(),
        pagination: common.Pagination.create({
            page: 1,
            pageSize: 10,
        }),
    });
    projects?: ws_home.AsodProjects;
    private loading = 0;
    table: ProtoTableStore;

    constructor(app: AppContextStore) {
        this.app = app;
        this.table = new ProtoTableStore(this.app, new ProjectsTable(), true);
        this.disposeUtils.add(
            this.app.api.getMessageHandler(new proto.RxAsodProjects()).subscribe((e) => this.onAsodProjects(e))
        )
        this.load();
        makeAutoObservable(this);

        if (Globals.useDummyData) {
            const projects = new proto.RxAsodProjects();
            projects.proto = DummyAsodProjects;
            this.onAsodProjects(projects);
        }
    }

    dispose() {
        this.disposeUtils.dispose();
        this.table.dispose();
    }

    private onAsodProjects(e: proto.RxAsodProjects) {
        this.loading = 0;
        this.projects = e.proto;
        this.table.dispose();
        this.table = new ProtoTableStore(this.app, new ProjectsTable(this.projects), true);
    }

    get isLoading() {
        return this.loading > 0;
    }

    load() {
        this.loadData.pagination = this.table?.data.pagination ?? this.loadData.pagination;
        this.app.api.sendMessage(proto.TxLoadAsodProjects.create(this.loadData));
    }
}
