import {useCallback, useContext, useEffect, useMemo} from "react";
import styled from "@emotion/styled";
import {Box, IconButton, LinearProgress, Typography, useTheme} from "@mui/material";
import Delete from "@mui/icons-material/Delete";
import InsertDriveFile from "@mui/icons-material/InsertDriveFile";
import {useDropzone} from "react-dropzone";
import React from "react";
import {uploader} from "../proto/compiled";
import {observer} from "mobx-react-lite";
import {UploadStore} from "../stores/UploadStore";
import {AppContextStoreContext} from "../stores/AppContextStore";
import {D} from "../app/constants";
import {ProtoUFile} from "../components/elementary/ProtoUFile";

interface UploadAreaProps {
    file?: uploader.IUFile;
    onUploaded: (input: uploader.IUFile) => void;
}

export const UploadArea = observer((props: UploadAreaProps) => {
    const context = useContext(AppContextStoreContext)!;
    const store = useMemo(() => new UploadStore(context), [context]);
    const theme = useTheme();

    useEffect(() => {
        return () => store.dispose();
    }, [store]);

    const onDrop = useCallback((acceptedFiles: File[]) => {
        store.uploadFile(acceptedFiles).then((f) => {
            if (f) {
                props.onUploaded(f);
            }
        });
    }, [props, store]);

    const hasFile = !!props.file?.src;

    const dropzone = useDropzone({onDrop: onDrop});

    return (
        <Container onClick={() => {
            const src = props.file?.src;
            if (src) {
                window.open(src, '_blank');
            }
        }} {...(hasFile ? undefined : dropzone.getRootProps())} style={{
            backgroundColor: dropzone.isDragActive ? theme.palette.action.hover : undefined,
            borderColor: dropzone.isDragActive ? theme.palette.primary.main : undefined,
            display: 'flex', alignItems: "center", justifyContent: "center",
            position: "relative",
            flexDirection: "column"
        }}>
            {!hasFile && <input {...dropzone.getInputProps()} />}
            {(store.file || hasFile) ? (
                <React.Fragment>
                    {(store.done || hasFile) ? (
                        <Box display={"flex"} alignSelf={"stretch"} height={'100%'} marginLeft={D.unit(-2)}>
                            <ProtoUFile file={props.file}/>
                            <IconButton style={{marginLeft: "auto", alignSelf: 'center'}} onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                props.file = undefined;
                                store.cancel();
                            }}>
                                <Delete/>
                            </IconButton>
                        </Box>
                    ) : (
                        <>
                            <Typography variant="body1">
                                {store.file?.name ?? '/'}
                            </Typography>
                            <LinearProgress
                                key={store.progress !== undefined ? "determinate-progress" : "indeterminate-progress"}
                                variant={store.progress !== undefined ? "determinate" : "indeterminate"}
                                value={store.progress !== undefined ? store.progress * 100 : undefined}
                                color={'secondary'}/>
                        </>
                    )}
                </React.Fragment>
            ) : (
                <>
                    <InsertDriveFile style={{marginBottom: D.unit()}}/>
                    <Typography>
                        {props.file ? props.file.name : 'Izberi datoteko.'}
                    </Typography>
                </>
            )}
        </Container>
    );
});

const Container = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  border-radius: ${D.radius()};
  height: ${D.input(3)};
  user-select: none;
  cursor: pointer;
  color: ${({theme}) => theme.palette.primary.main};
  background-color: ${({theme}) => theme.palette.background.default};
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
  padding: 0 ${D.unit(2)};
  border: 1px solid transparent;
  transition: all 0.2s;
`;

const LinearProgressContainer = styled.div`
  overflow: hidden;
  width: 300px;
  max-width: 80%;
  transition: all 300ms;
  margin-top: ${D.unit()};
`;