import {observer} from "mobx-react-lite";
import React, {useContext} from "react";
import {UsersStoreContext} from "../../../webservice/stores/UsersStore";
import {Frame} from "../../elementary/Frame";
import {AsodAppBar} from "../../../fragments/AsodAppBar";
import {Typography} from "@mui/material";
import {D} from "../../../app/constants";
import {DataTable} from "../../layouts/DataTable";
import {ProtoTableStoreContext} from "../../../stores/ProtoTableStore";
import {useNavigate} from "react-router-dom";
import {WebserviceRoutes} from "../../../webservice/Routes";
import {WebserviceApps} from "../projects/WebserviceApps";

export const UsersContent = observer(() => {
    const store = useContext(UsersStoreContext)!;
    const navigate = useNavigate();
    return <ProtoTableStoreContext.Provider value={store.table}>
        <Frame style={{flexGrow: 1}} alignItems={'stretch'} gap={2} padding={2}>
            <AsodAppBar actions={[<WebserviceApps/>]}>
                <Typography variant={'h5'} style={{marginLeft: D.unit(), marginRight: 'auto'}}>Uporabniki</Typography>
            </AsodAppBar>
            <Frame style={{flexGrow: 1}} alignItems={'stretch'} gap={2} padding={1}>
                <DataTable onRowClick={(r) => {
                }}/>
            </Frame>
        </Frame>
    </ProtoTableStoreContext.Provider>;
});
