export abstract class MathUtils {
    static median(numbers: number[]) {
        const sorted = Array.from(numbers).sort((a, b) => a - b);
        const middle = Math.floor(sorted.length / 2);

        if (sorted.length % 2 === 0) {
            return (sorted[middle - 1] + sorted[middle]) / 2;
        }

        return sorted[middle];
    }

    static mean(numbers: number[]) {
        return numbers.reduce((a, b) => a + b) / numbers.length;
    }
}