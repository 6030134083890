import React from "react";
import {AppContextStore, AsodVariant} from "../../stores/AppContextStore";
import {makeAutoObservable} from "mobx";

export const WebserviceContextStoreContext = React.createContext<WebserviceContextStore | undefined>(undefined);

export class WebserviceContextStore {
    app: AppContextStore;

    constructor(app: AppContextStore) {
        this.app = app;
    }
}