import styled from "@emotion/styled";

export const LoadingOverlay = styled.div<{ visible: boolean, delay?: number }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: ${({visible}) => visible ? 'all' : 'none'};
  opacity: ${({visible}) => visible ? '1' : '0'};
  transition: opacity 100ms ease;
  transition-delay: ${({visible, delay}) => (visible && delay) ? (delay + 'ms') : '100ms'};
  display: flex;
  align-items: center;
  justify-content: center;

  background: linear-gradient(270deg, rgba(170, 170, 170, 0.3), rgba(255, 255, 255, 0.6), rgba(170, 170, 170, 0.3));
  background-size: 200% 200%;

  animation: LoadingBg 2s linear infinite;

  @keyframes LoadingBg {
    0% {
      background-position: 200% 200%;
    }
    100% {
      background-position: 0 0;
    }
  }
`;