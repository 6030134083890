import {common, ws_users} from "../../proto/compiled";

export const DummyAsodUsers = ws_users.AsodUsers.create({
    rows: new Array(20).fill(0).map((_, i) => ws_users.AsodUsersRow.create({
        id: i.toString(),
        court: 'Vrhovno sodišče',
        nameSurname: 'Urška Klakočar',
        department: 'kazenski',
        role: 'ZAPISNIKAR',
        lastLogin: common.Date.create({ts: new Date().getTime()}),
    })),
    pagination: common.Pagination.create({
        total: 150,
        page: 1,
        nPages: 19,
        pageSize: 20,
    }),
    sortBy: ws_users.AsodUsersOrderingTerm.auot_name_surname,
    sortOrder: common.SortOrder.so_desc,
});