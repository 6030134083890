import {observer} from "mobx-react-lite";
import {ThemeProvider, Typography} from "@mui/material";
import {DarkTheme, LightTheme} from "../app/theme";
import React, {useContext, useMemo} from "react";
import {AppContextStoreContext} from "../stores/AppContextStore";
import {WebserviceRouter} from "./WebserviceRouter";
import {WebserviceContextStore, WebserviceContextStoreContext} from "./stores/WebserviceContextStore";
import {ThemeStyles} from "../components/base/ThemeStyles";
import styled from "@emotion/styled";
import {CssFill} from "../components/base/positioning";
import {CssFlex} from "../components/base/flex";
import {RecorderContextStoreContext} from "../recorder/stores/RecorderContextStore";
import {RecorderRouter} from "../recorder/RecorderRouter";
import {SliderStyles} from "../components/base/SliderStyles";

export const WebserviceApp = observer(() => {
    const app = useContext(AppContextStoreContext)!;
    const webservice = useMemo(() => new WebserviceContextStore(app), [app]);

    return <ThemeProvider theme={LightTheme}>
        <ThemeStyles>
            <SliderStyles>
                <Typography>
                    <Container>
                        <WebserviceContextStoreContext.Provider value={webservice}>
                            <WebserviceRouter/>
                        </WebserviceContextStoreContext.Provider>
                    </Container>
                </Typography>
            </SliderStyles>
        </ThemeStyles>
    </ThemeProvider>;
});


const Container = styled.div`
  position: fixed;
  ${CssFill};
  ${CssFlex};
  background-color: ${({theme}) => theme.palette.background.paper};

  *::-webkit-scrollbar {
    width: 20px;
  }

  *::-webkit-scrollbar-track {
    background-color: rgba(68, 74, 106, 0.1);
    border-radius: 10px;
  }

  *::-webkit-scrollbar-thumb {
    background-color: rgba(68, 74, 106, 0.2);
    border-radius: 10px;
    cursor: pointer;
    background-clip: content-box;
    border: 4px solid transparent;
    box-shadow: inset 0 0 5px 0 rgb(68, 74, 106);
  }

  *::-webkit-scrollbar-thumb:hover {
    background-color: rgba(68, 74, 106, 0.5);
  }
`;