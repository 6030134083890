import React from "react";
import {AppContextStore} from "../../stores/AppContextStore";
import {proto} from "../../proto/messages";
import {common, ws_logs} from "../../proto/compiled";
import {ErrorUtils} from "../../utils/ErrorUtils";
import {makeAutoObservable} from "mobx";
import {ProtoTableStore} from "../../stores/ProtoTableStore";
import {Globals} from "../../index";
import {LogsTable} from "../../components/tables/LogsTable";
import {DisposeUtils} from "../../utils/DisposeUtils";

export const LogsStoreContext = React.createContext<LogsStore | undefined>(undefined);

export class LogsStore {
    app: AppContextStore;
    private disposeUtils = new DisposeUtils();
    loadData = ws_logs.LoadAsodLogs.create({
        pagination: common.Pagination.create({
            page: 1,
            pageSize: 10,
        }),
        dateFrom: common.DateFilter.create({
            ts: Date.now() - 1000 * 60 * 60 * 24,
            granularity: common.DateGranularity.second,
        }),
        dateTo: common.DateFilter.create({
            ts: Date.now(),
            granularity: common.DateGranularity.second,
        }),
    });
    logs?: ws_logs.AsodLogs;
    private loading = 0;
    table: ProtoTableStore;

    constructor(app: AppContextStore) {
        this.app = app;
        this.table = new ProtoTableStore(this.app, new LogsTable(), true);
        this.disposeUtils.add(
            this.app.api.getMessageHandler(new proto.RxAsodLogs()).subscribe((e) => this.onAsodLogs(e))
        )
        this.load();
        makeAutoObservable(this);
    }

    dispose() {
        this.disposeUtils.dispose();
        this.table.dispose();
    }

    private onAsodLogs(e: proto.RxAsodLogs) {
        this.logs = e.proto;
        this.table.dispose();
        this.table = new ProtoTableStore(this.app, new LogsTable(this.logs), true);
    }

    get isLoading() {
        return this.loading > 0;
    }

    async load() {
        this.loading++;
        this.loadData.pagination = this.table?.data.pagination ?? this.loadData.pagination;
        const response = await this.app.api.sendMessage(
            proto.TxLoadAsodLogs.create(this.loadData),
            {ack: true}
        );
        ErrorUtils.response(response);
        this.loading--;
    }
}
