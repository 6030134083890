import {asod_auth, asod_members, asod_project, common, ws_home} from "../../proto/compiled";

export const DummyAsodProjects = ws_home.AsodProjects.create({
    rows: new Array(8).fill(0).map((_, i) => ws_home.AsodProjectRow.create({
        id: i.toString(),
        court: 'Vrhovno sodišče',
        courtRoom: 'X - VII',
        opNumber: `KZ-${i.toString().padStart(6, '0')}`,
        participants: [
            ws_home.AsodProjectRowParticipant.create({name: 'Ciril Keršmanc', role: 'SODNIK'}),
            ws_home.AsodProjectRowParticipant.create({name: 'Urška Klakočar', role: 'ZAPISNIKAR'}),
            ws_home.AsodProjectRowParticipant.create({name: 'Janez Novak'}),
            ws_home.AsodProjectRowParticipant.create({name: 'Jožica Novak'}),
        ],
        date: common.Date.create({ts: new Date().getTime()}),
    })),
    pagination: common.Pagination.create({
        total: 150,
        page: 1,
        nPages: 19,
        pageSize: 8,
    }),
    sortBy: ws_home.AsodProjectsOrderingTerm.apot_date,
    sortOrder: common.SortOrder.so_desc,
});