import {common, ws_users} from "../../proto/compiled";
import {List, Typography} from "@mui/material";
import {DateUtils} from "../../utils/DateUtils";
import {DataTableData, TableRowData} from "../layouts/DataTable";
import {BadgeOutline} from "../elementary/Badges";

export class UsersTable extends DataTableData {
    private users?: ws_users.IAsodUsers;

    constructor(users?: ws_users.IAsodUsers) {
        super(users?.pagination);
        this.minWidth = '900px';
        this.users = users;
    }

    getHeader() {
        const sortBy = this.users?.sortBy;
        const sortOrder = this.users?.sortOrder ?? common.SortOrder.so_none;
        return [
            {
                id: 'court',
                name: 'SODIŠČE',
                ordering: sortBy === ws_users.AsodUsersOrderingTerm.auot_court ? sortOrder : common.SortOrder.so_none,
                sortable: true,
                maxCharacters: 25,
            },
            {
                id: 'name-surname',
                name: 'IME IN PRIIMEK',
                ordering: sortBy === ws_users.AsodUsersOrderingTerm.auot_name_surname ? sortOrder : common.SortOrder.so_none,
                sortable: true,
                flex: 1,
            },
            {
                id: 'department',
                name: 'ODDELEK',
                ordering: sortBy === ws_users.AsodUsersOrderingTerm.auot_department ? sortOrder : common.SortOrder.so_none,
                sortable: true,
                maxCharacters: 15,
            },
            {
                id: 'role',
                name: 'VLOGA',
                ordering: sortBy === ws_users.AsodUsersOrderingTerm.auot_role ? sortOrder : common.SortOrder.so_none,
                sortable: true,
                maxCharacters: 20,
            },
            {
                id: 'last-login',
                name: 'ZADNJA PRIJAVA',
                ordering: sortBy === ws_users.AsodUsersOrderingTerm.auot_last_login ? sortOrder : common.SortOrder.so_none,
                sortable: true,
                maxCharacters: 24,
            },
        ];
    }

    getRows(): TableRowData[] {
        return this.users?.rows!.map((p, i) => ({
            id: p.id!,
            data: p,
            children: [
                <Typography key={'court'} variant={'body2'}>{p.court}</Typography>,
                <Typography key={'nameSurname'} variant={'body2'}>{p.nameSurname}</Typography>,
                <Typography key={'department'} variant={'body2'}>{p.department}</Typography>,
                <Typography key={'role'} variant={'body2'}>{p.role}</Typography>,
                <Typography key={'lastLogin'} variant={'body2'}>{DateUtils.toDateTimeString(p.lastLogin)}</Typography>,
            ],
        })) ?? [];
    }
}