import {SocketApi} from "proto_socket_typescript";
import {proto} from "../proto/messages";
import {asod_project, api_pregled, asod_members, asod_audio, api_deeplinks} from "../proto/compiled";
import {AppContextStore} from "../stores/AppContextStore";
import {v4 as uuidv4} from 'uuid';
import {ErrorUtils} from "../utils/ErrorUtils";

export class WsProjectConnector {
    context: AppContextStore;
    api: SocketApi;

    constructor(context: AppContextStore) {
        this.context = context;
        this.api = context.api;

        this.api.getTxMessageHandler(proto.TxLoadAsodProject.create(), {intercept: true}).subscribe((e) => this.onTxLoadAsodProject(e));
        this.api.getTxMessageHandler(proto.TxSaveAsodMember.create(), {intercept: true}).subscribe((e) => this.onSaveAsodMember(e));
        this.api.getMessageHandler(new proto.RxApiPregledPackagedSession()).subscribe((e) => this.onRxApiPregledPackagedSession(e));
    }

    private onTxLoadAsodProject(e: proto.TxLoadAsodProject) {
        this.api.sendMessage(proto.TxApiPregledGetPackagedSession.create({
            sessionId: e.proto.id,
        }))
    }

    private onRxApiPregledPackagedSession(m: proto.RxApiPregledPackagedSession) {
        const message = new proto.RxAsodProject();
        message.proto = asod_project.AsodProject.create({
            id: m.proto.sessionId,
            meta: asod_project.AsodProjectMeta.create({
                opNumber: m.proto.courtCaseNumber,
                members: m.proto.participants.map((p) => asod_members.AsodMember.create({
                    id: p.participationId,
                    name: p.displayName,
                    taxId: p.participantTaxId,
                    role: asod_members.AsodMemberRole.create({
                        id: p.roleId,
                        name: this.context.constants.roles[p.roleId ?? '']?.title ?? 'N/A',
                    }),
                    projectMeta: asod_members.AsodProjectMemberMeta.create({
                        audioChannel: p.channelNumber,
                        accessGranted: p.publicAccess,
                    }),
                })),
            }),
            audioBlocks: WsProjectConnector.getAudioBlock(m.proto.segments, m.proto.annotations),
        });
        this.api.sendLocalUpdate(message)

        const deepLinksMessage = new proto.RxApiDeeplinksSessionDeeplinks();
        deepLinksMessage.proto = api_deeplinks.ApiDeeplinksSessionDeeplinks.create({
                deeplinks: m.proto.deeplinks.map((d) => api_deeplinks.ApiDeeplinksSessionDeeplink.create({
                    sessionId: m.proto.sessionId,
                    deeplinkId: d.deeplinkId,
                    name: d.name,
                    url: d.url,
                    createdBy: d.createdByUsername,
                    publiclyAccessible: d.publiclyAccessible,
                    segmentStart: d.segmentStart,
                    segmentEnd: d.segmentEnd,
                    createdAt: d.createdAt,
                })),
            }
        );
        this.api.sendLocalUpdate(deepLinksMessage);
    }

    private static getAudioBlock(segments: api_pregled.IAsodPregledSegment[], annotations: api_pregled.IApiPregledAnnotation[]) {
        if (!segments.length) return [];
        const nChannels = segments[0].channelCount ?? 1;
        const blocks: asod_project.IAsodProjectAudioBlock[] = [];

        const getBlock = (start: any) => {
            return asod_project.AsodProjectAudioBlock.create({
                id: uuidv4(),
                timestampStart: start,
                comments: [],
                audio: asod_audio.AsodAudio.create({
                    id: uuidv4(),
                    chunks: [],
                    waveform: [],
                    nChannels: nChannels
                }),
            });
        };

        let block = getBlock(segments[0].startTime);
        let prevEnd = segments[0].startTime!;

        const finishBlock = (nextStartTime: any) => {
            block.timestampEnd = prevEnd;
            block.comments = annotations.filter((a) => (a.createdAt! as number * 1000) >= block.timestampStart && (a.createdAt! as number * 1000) <= block.timestampEnd).map((a) => asod_project.AsodProjectComment.create({
                id: a.annotationId,
                timestamp: a.createdAt,
                author: asod_members.AsodMember.create({
                    id: a.createdBy,
                    name: a.createdByUsername,
                }),
                public: a.publiclyAccessible,
                body: a.content,
            }));
            blocks.push(block);
            block = getBlock(nextStartTime);
        };

        for (const segment of segments) {
            if (segment.startTime !== prevEnd) {
                finishBlock(segment.startTime);
            }
            block.audio!.waveform?.push(...segment.waveform ?? []);
            block.audio!.chunks?.push(asod_audio.AsodAudioChunk.create({
                id: segment.segmentId,
                timestampStart: segment.startTime,
                timestampEnd: segment.endTime,
                src: segment.url,
                status: asod_audio.AsodAudioChunkStatus.aacs_uploaded,
            }));
            prevEnd = segment.endTime!;
        }
        finishBlock(prevEnd)

        return blocks;
    }

    private async onSaveAsodMember(e: proto.TxSaveAsodMember) {
        if (e.proto.member?.id?.length) {
            if (!ErrorUtils.response(await this.api.sendMessage(proto.TxApiUdelezenciUpdateParticipant.create({
                participationId: e.proto.member?.id,
                participantName: e.proto.member?.name,
                roleId: e.proto.member?.role?.id,
                grantPublicAccess: e.proto.member?.projectMeta?.accessGranted,
                channelNumber: e.proto.member?.projectMeta?.audioChannel,
            }), {ack: true}))) {
                this.reloadSession(e.proto.sessionId);
            }
            if (e.proto.member?.taxId?.length) {
                // todo - detect if taxId can be set
                // if (!ErrorUtils.response(await this.api.sendMessage(proto.TxApiUdelezenciSetParticipantTaxId.create({
                //     participationId: e.proto.member.id,
                //     taxId: e.proto.member.taxId,
                // }), {ack: true}))) {
                //     this.reloadSession(e.proto.sessionId);
                // }
            }
        } else {
            if (!ErrorUtils.response(await this.api.sendMessage(proto.TxApiUdelezenciAddParticipant.create({
                participationId: uuidv4(),
                sessionId: e.proto.sessionId,
                participantName: e.proto.member?.name,
                participantTaxId: e.proto.member?.taxId,
                roleId: e.proto.member?.role?.id,
                grantPublicAccess: e.proto.member?.projectMeta?.accessGranted,
                channelNumber: e.proto.member?.projectMeta?.audioChannel,
            }), {ack: true}))) {
                this.reloadSession(e.proto.sessionId);
            }
        }
    }

    private reloadSession(sessionId: string) {
        this.api.sendMessage(proto.TxApiPregledGetPackagedSession.create({
            sessionId: sessionId,
        }))
    }
}