import {observer} from "mobx-react-lite";
import {SidebarLayout} from "../../components/layouts/SidebarLayout";
import React, {useContext, useMemo} from "react";
import {AppContextStoreContext} from "../../stores/AppContextStore";
import {LogsSideBar} from "../../components/pages/logs/LogsSideBar";
import {LogsStore, LogsStoreContext} from "../stores/LogsStore";
import {LogsContent} from "../../components/pages/logs/LogsContent";

let store: LogsStore;
export const LogsPage = observer(() => {
    const context = useContext(AppContextStoreContext)!;
    store = useMemo(() => store ?? new LogsStore(context), [context]);
    return <LogsStoreContext.Provider value={store}>
        <SidebarLayout>
            <LogsSideBar/>
            <LogsContent/>
        </SidebarLayout>
    </LogsStoreContext.Provider>;
});