import {SocketApiAckStatus, SocketApiTxStatus} from "proto_socket_typescript/lib/socket_api";
import {toast} from "react-toastify";
import {AppContextStore, AsodVariant} from "../stores/AppContextStore";

export abstract class ErrorUtils {
    static fromFetch(e: any) {
        if (e instanceof SocketApiTxStatus && e.status === SocketApiAckStatus.success) {
            return true;
        }
        toast.error(ErrorUtils.toString(e));
        return false
    }

    static response(response: SocketApiTxStatus): boolean {
        if (response.status !== SocketApiAckStatus.success) {
            if (AppContextStore.variant === AsodVariant.recorder) {
                toast.error(response.errorMessage || 'Neznana napaka');
            }
            return false;
        }
        return true;
    }

    static toString(e: any) {
        if (e instanceof SocketApiTxStatus) {
            return e.errorMessage;
        } else if (e.message) {
            return e.message;
        } else {
            return e.toString();
        }
    }
}