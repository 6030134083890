import {AppContextStore} from "../stores/AppContextStore";
import {WsProjectsConnector} from "./ws_projects_connector";
import {common} from "../proto/compiled";
import {WsManagementConnector} from "./ws_management_connector";
import {WsProjectConnector} from "./ws_project_connector";
import {WsLogsConnector} from "./ws_logs_connector";

export class WsConnector {
    context: AppContextStore;
    private projects: WsProjectsConnector;
    private project: WsProjectConnector;
    private management: WsManagementConnector;
    private logs: WsLogsConnector;

    constructor(context: AppContextStore) {
        this.context = context;
        this.projects = new WsProjectsConnector(context);
        this.project = new WsProjectConnector(context);
        this.management = new WsManagementConnector(context);
        this.logs = new WsLogsConnector(context);
    }

    static fromPagination(data?: common.IPagination | null) {
        let ps = data?.pageSize ?? 10;
        let offset = ((data?.page ?? 1) - 1) * ps;
        return {
            offset: offset,
            pageSize: ps,
        }
    }

    static toPagination(data: { offset: number, pageSize: number, lastItemIndex: number }) {
        let page = Math.floor(data.offset / data.pageSize) + 1;
        return common.Pagination.create({
            page: page,
            pageSize: data.pageSize,
            nPages: Math.ceil(data.lastItemIndex / data.pageSize),
            total: data.lastItemIndex + 1,
        });
    }
}