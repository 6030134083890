import {common, ws_home} from "../../proto/compiled";
import {List, Typography} from "@mui/material";
import {DateUtils} from "../../utils/DateUtils";
import {DataTableData, TableRowData} from "../layouts/DataTable";
import {BadgeOutline} from "../elementary/Badges";

export class ProjectsTable extends DataTableData {
    private projects?: ws_home.IAsodProjects;

    constructor(projects?: ws_home.IAsodProjects) {
        super(projects?.pagination);
        this.minWidth = '900px';
        this.projects = projects;
    }

    getHeader() {
        const sortBy = this.projects?.sortBy;
        const sortOrder = this.projects?.sortOrder ?? common.SortOrder.so_none;
        return [
            {
                id: 'court',
                name: 'SODIŠČE',
                ordering: sortBy === ws_home.AsodProjectsOrderingTerm.apot_court ? sortOrder : common.SortOrder.so_none,
                sortable: true,
                maxCharacters: 25,
            },
            {
                id: 'courtroom',
                name: 'DVORANA',
                ordering: sortBy === ws_home.AsodProjectsOrderingTerm.apot_court_room ? sortOrder : common.SortOrder.so_none,
                sortable: true,
                maxCharacters: 15,
            },
            {
                id: 'op-number',
                name: 'OPRAVILNA ŠTEVILKA',
                ordering: sortBy === ws_home.AsodProjectsOrderingTerm.apot_op_number ? sortOrder : common.SortOrder.so_none,
                sortable: true,
                maxCharacters: 24,
            },
            {
                id: 'participants',
                name: 'UDELEŽENCI',
                ordering: common.SortOrder.so_none,
                sortable: false,
                flex: 1,
            },
            {
                id: 'date',
                name: 'DATUM IN URA',
                ordering: sortBy === ws_home.AsodProjectsOrderingTerm.apot_date ? sortOrder : common.SortOrder.so_none,
                sortable: true,
                maxCharacters: 24,
            },
        ];
    }

    getRows(): TableRowData[] {
        return this.projects?.rows?.map((p, i) => ({
            id: p.id!,
            data: p,
            children: [
                <Typography key={'court'} variant={'body2'}>{p.court}</Typography>,
                <Typography key={'courtRoom'} variant={'body2'}>{p.courtRoom}</Typography>,
                <Typography key={'opNumber'} variant={'body2'}>{p.opNumber}</Typography>,
                <List key={'participants'} dense>
                    {p.participants?.map((p, i) => <Typography key={i} variant={'body2'} style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                        • {p.name}
                        {!!p.role?.length && <BadgeOutline>{p.role}</BadgeOutline>}
                    </Typography>)}
                </List>,
                <Typography key={'date'} variant={'body2'}>{DateUtils.toDateTimeString(p.date)}</Typography>,
            ],
        })) ?? [];
    }
}