import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {ToastContainer} from "react-toastify";
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import 'rc-slider/assets/index.css';
import { configure } from "mobx"

configure({
    enforceActions: "never",
})

if (!new class {
    x: any
}().hasOwnProperty('x')) throw new Error('Transpiler is not configured correctly');

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

export const Globals = {
    useDummyData: false,
};

root.render(
    <BrowserRouter>
        <App/>
        <ToastContainer position={'bottom-right'}/>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
