import {observer} from "mobx-react-lite";
import React, {useContext, useState} from "react";
import {ProjectsStoreContext} from "../../../webservice/stores/ProjectsStore";
import {Frame} from "../../elementary/Frame";
import {AsodAppBar} from "../../../fragments/AsodAppBar";
import {Upload} from "@mui/icons-material";
import {ButtonOutline} from "../../elementary/Buttons";
import {Dialog, Typography, useTheme} from "@mui/material";
import {D} from "../../../app/constants";
import {WebserviceApps} from "./WebserviceApps";
import {DataTable} from "../../layouts/DataTable";
import {ProtoTableStoreContext} from "../../../stores/ProtoTableStore";
import {useNavigate} from "react-router-dom";
import {WebserviceRoutes} from "../../../webservice/Routes";
import {UploadProjectDialog} from "../../dialogs/UploadProjectDialog";
import {WebserviceContextStore, WebserviceContextStoreContext} from "../../../webservice/stores/WebserviceContextStore";
import {AppContextStoreContext} from "../../../stores/AppContextStore";

interface ProjectContentProps {

}

export const ProjectsContent = observer((props: ProjectContentProps) => {
    const store = useContext(ProjectsStoreContext)!;
    const features = useContext(AppContextStoreContext)!.features;
    const navigate = useNavigate();
    const theme = useTheme();
    const [showUploadProjectDialog, setShowUploadProjectDialog] = useState(false);
    return <ProtoTableStoreContext.Provider value={store.table}>
        <Frame style={{flexGrow: 1}} alignItems={'stretch'} gap={2} padding={2}>
            <AsodAppBar actions={[
                features.uploadSession && <ButtonOutline onClick={() => setShowUploadProjectDialog(true)}>
                    <Upload/>Naloži obravnavo
                </ButtonOutline>,
                <WebserviceApps/>,
            ]}>
                <Typography variant={'h5'} style={{marginLeft: D.unit(), marginRight: 'auto'}}>Obravnave</Typography>
            </AsodAppBar>
            <Frame style={{flexGrow: 1}} alignItems={'stretch'} gap={2} padding={1}>
                <DataTable
                    onPageSizeChange={(pageSize) => {
                        store.load();
                    }}
                    onPageChange={(page) => {
                        store.load();
                    }}
                    onRowClick={(r) => {
                        navigate(WebserviceRoutes.project(r.id));
                    }}/>
            </Frame>
            {features.uploadSession && <Dialog open={showUploadProjectDialog} onClose={() => setShowUploadProjectDialog(false)}>
                <UploadProjectDialog
                    onCancel={() => setShowUploadProjectDialog(false)}
                    onSave={() => {
                        setShowUploadProjectDialog(false);
                    }}/>
            </Dialog>}
        </Frame>
    </ProtoTableStoreContext.Provider>;
});
