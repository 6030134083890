import {observer} from "mobx-react-lite";
import {Frame} from "../../../elementary/Frame";
import React, {useContext} from "react";
import {AsodAppBar} from "../../../../fragments/AsodAppBar";
import {RecordingBlocks} from "../common/RecordingBlocks";
import {Typography} from "@mui/material";
import {WebserviceProjectStoreContext} from "../../../../webservice/stores/WebserviceProjectStore";
import {D} from "../../../../app/constants";
import {WebserviceApps} from "../../projects/WebserviceApps";
import {ChannelLevels} from "../../../../fragments/ChannelLevels";
import {PlayAtTime} from "../../../../fragments/PlayAtTime";
import {ButtonOutline} from "../../../elementary/Buttons";
import {StopCircleOutlined} from "@mui/icons-material";
import {LastPlayerTime} from "../../../../fragments/LastPlayerTime";

interface ProjectContentProps {

}

export const WebserviceProjectContent = observer((props: ProjectContentProps) => {
    const store = useContext(WebserviceProjectStoreContext)!;
    return <Frame style={{flexGrow: 1}} alignItems={'stretch'} gap={2} padding={2}>
        <AsodAppBar actions={[<WebserviceApps/>]}>
            <Typography variant={'h5'}
                        style={{marginLeft: D.unit(), marginRight: 'auto'}}>
                {store.project.data?.meta?.opNumber ?? 'Projekt'}
            </Typography>
        </AsodAppBar>
        <Frame direction={'row'} gap={2} padding={1}>
            <ChannelLevels levels={store.project.audio.channelLevels}/>
            <div style={{
                pointerEvents: 'none',
                display: 'flex',
                position: 'absolute',
                left: 0,
                right: 0,
                justifyContent: 'center',
            }}>
                <LastPlayerTime/>
            </div>
            <PlayAtTime
                minDate={store.project.dateStart}
                maxDate={store.project.dateEnd}
                getDefaultValue={() => store.project.audio.lastPlayerTime?.getTime()}
                onChange={(value) => {
                    store.project.audio.seekDateTime(value);
                    store.project.audio.play();
                }}
            />
            {store.project.audio.isPlaying && <ButtonOutline onClick={() => store.project.audio.stop()}>
                <StopCircleOutlined/>Ustavi predvajanje
            </ButtonOutline>}
        </Frame>
        {store.project.data?.audioBlocks && <RecordingBlocks blocks={store.project.data.audioBlocks}/>}
        {!store.project.data?.audioBlocks && <Typography variant={'h3'} style={{
            margin: "auto",
            opacity: 0.2
        }}>
            ni posnetkov
        </Typography>}
    </Frame>;
});
