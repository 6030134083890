import styled from "@emotion/styled";
import {D} from "../../app/constants";
import app from "../../App";
import {type} from "node:os";

interface FrameProps {
    direction?: 'row' | 'column';
    row?: boolean;
    center?: boolean;
    alignItems?: 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'baseline';
    justifyContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly';
    centerText?: boolean;
    fill?: boolean;

    appearance?: 'none' | 'outline' | 'filled-outline' | 'card' | 'shade';
    color?: string;
    background?: string;
    noTransition?: boolean;
    shadow?: number;

    flex?: number;
    radius?: number;
    padding?: number;
    paddingX?: number;
    paddingY?: number;
    margin?: number | string;
    marginTop?: number;
    marginBottom?: number;
    marginLeft?: number;
    marginRight?: number;
    gap?: number;
    height?: number | string;
    width?: number | string;
    grow?: boolean;
    clip?: boolean;

    clickable?: boolean;
    noPointer?: boolean;
}


const S = function <T extends keyof any>(val: T | undefined, map: Record<T, any>) {
    if (val === undefined) {
        return Object.values(map)[0];
    }
    return map[val as T] as any;
};

export const Frame = styled.div<FrameProps>`
  position: relative;
  display: flex;
  flex-shrink: 0;
  flex-grow: ${({grow}) => grow ? 1 : 0};
  flex-basis: ${({flex, fill, grow}) => (flex || fill || grow) ? 0 : 'auto'};
  flex: ${({flex, fill}) => fill ? 1 : flex};
  align-self: ${({center, fill}) => fill ? 'stretch' : center ? 'center' : undefined};
  flex-direction: ${({row, direction}) => row ? 'row' : S(direction, {
    'column': 'column',
    'row': 'row',
  })};
  background-color: ${({theme, appearance, color, background}) => background ?? S(appearance, {
    'none': undefined,
    'outline': undefined,
    'shade': theme.palette.background.paper,
    'filled-outline': color ?? theme.palette.action.hover,
    'card': theme.palette.background.default,
  })};
  border-radius: ${({appearance, radius}) => D.radius(radius ?? ((!appearance || appearance === 'none') ? 0 : 1))};
  height: ${({height}) => typeof height === 'number' ? D.unit(height) : height};
  width: ${({width}) => typeof width === 'number' ? D.unit(width) : width};
  box-shadow: ${({theme, appearance, shadow}) => shadow ? theme.shadows[shadow] : S(appearance, {
    'none': undefined,
    'outline': undefined,
    'shade': undefined,
    'filled-outline': undefined,
    'card': theme.shadows[4],
  })};
  border: ${({theme, appearance, color}) => S(appearance, {
    'none': undefined,
    'outline': `solid 1px ${color ?? theme.palette.divider}`,
    'shade': undefined,
    'filled-outline': `solid 1px ${color ?? theme.palette.divider}`,
    'card': undefined,
  })};
  box-sizing: border-box;
  padding: ${({
                paddingX,
                paddingY,
                padding
              }) => (paddingX || paddingY) ? `${D.unit(paddingY ?? 0)} ${D.unit(paddingX ?? 0)}` : D.unit(padding ?? 0)};
  margin: ${({margin}) => typeof margin === 'string' ? margin : D.unit(margin ?? 0)};
  margin-top: ${({margin, marginTop}) => marginTop !== undefined && D.unit(marginTop)};
  margin-bottom: ${({margin, marginBottom}) => marginBottom !== undefined && D.unit(marginBottom)};
  margin-left: ${({margin, marginLeft}) => marginLeft !== undefined && D.unit(marginLeft)};
  margin-right: ${({margin, marginRight}) => marginRight !== undefined && D.unit(marginRight)};
  gap: ${({gap}) => gap !== undefined && D.unit(gap)};


  color: ${({theme}) => theme.palette.text.primary};
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  letter-spacing: 0.00938em;
  text-align: ${({centerText}) => centerText ? 'center' : 'left'};
  transition: ${({noTransition}) => noTransition ? 'none' : 'all 0.3s'};

  overflow: ${({clip, appearance}) => (clip || appearance === 'card') ? 'hidden' : undefined};

  user-select: ${({clickable}) => clickable ? 'none' : 'auto'};
  cursor: ${({clickable}) => clickable ? 'pointer' : 'auto'};
  pointer-events: ${({noPointer}) => noPointer ? 'none' : 'auto'};

  align-items: ${({alignItems, center}) => alignItems ?? (center ? 'center' : undefined)};
  justify-content: ${({justifyContent, center}) => justifyContent ?? (center ? 'center' : undefined)};
`;