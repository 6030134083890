import makeAutoObservable from "mobx-store-inheritance";

export class Validators {
    private validators: Validator<any>[];

    constructor(...validators: Validator<any>[]) {
        this.validators = validators;
    }

    add<T>(validator: Validator<T>): Validator<T> {
        this.validators.push(validator);
        return validator;
    }

    showErrors(showErrors: boolean) {
        for (const validator of this.validators) {
            validator.showErrors = showErrors;
        }
    }

    isValid(): boolean {
        this.showErrors(true);
        for (const validator of this.validators) {
            if (validator.error !== undefined) {
                return false;
            }
        }
        return true;
    }
}

export abstract class Validator<T> {
    _error: string | undefined;
    showErrors = false;

    constructor(value: T) {
        this.onChanged(value);
        makeAutoObservable(this);
    }

    abstract onChanged(value: T): T;

    get error(): string | undefined {
        if (!this.showErrors) {
            return undefined;
        }
        return this._error;
    }
}