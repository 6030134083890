import {Validator} from "../validator";
import {asod_members} from "../../proto/compiled";

export class RoleValidator extends Validator<asod_members.IAsodMemberRole | undefined> {
    onChanged(value?: asod_members.IAsodMemberRole): asod_members.IAsodMemberRole | undefined {
        this._error = undefined;
        if (!value || !value?.id?.length) {
            this._error = "Izberite vlogo";
        }
        return value;
    }
}
