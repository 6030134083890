import {useMemo} from 'react';
import {isObservable, makeAutoObservable} from "mobx";

const useAutoObservable = <T extends Object | undefined>(item: T, ...objs: any[]): T => {
    useMemo(() => {
        if (!!item && !isObservable(item)) {
            makeAutoObservable(item);
        }
        for (const obj of objs) {
            if (!!obj && !isObservable(obj)) {
                makeAutoObservable(obj);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item, ...objs]);
    return item;
};

export default useAutoObservable;
