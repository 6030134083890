import {Validator} from "../validator";

export class TaxIdValidator extends Validator<string | undefined> {
    private canBeEmpty: boolean;

    constructor(value: string | undefined, canBeEmpty?: boolean) {
        super(value);
        this.canBeEmpty = !!canBeEmpty;
        this.onChanged(value);
    }

    onChanged(value?: string): string | undefined {
        this._error = undefined;
        if (this.canBeEmpty && !value?.length) {
            return value;
        }

        if (value?.length !== 8) {
            this._error = "Vnesite 8 znakov";
        } else if (!/^\d+$/.test(value)) {
            this._error = "Vnesite samo številke";
        } else {
            const sum = value.substring(0, 7).split("").map((c, i) => parseInt(c) * (8 - i)).reduce((a, b) => a + b, 0);
            let x = 11 - sum % 11;
            if (x > 9) {
                x = 0;
            }
            if (x !== parseInt(value[7])) {
                this._error = "Neveljavna davčna številka";
            }
        }

        return value;
    }
}
