import {observer} from "mobx-react-lite";
import React, {useContext, useEffect, useMemo, useState} from "react";
import {PairingUsbStore} from "../stores/PairingUsbStore";
import {AppContextStoreContext} from "../stores/AppContextStore";
import {Dialog, Typography, useTheme} from "@mui/material";
import {Frame} from "../components/elementary/Frame";
import {D} from "../app/constants";
import {ButtonFilled, ButtonOutline} from "../components/elementary/Buttons";
import {DialogFooter} from "../components/layouts/DialogFooter";
import {LoadingOverlay} from "../components/elementary/LoadingOverlay";

export const CreatePairingUsb = observer(({onClose}: { onClose: () => any }) => {
    const context = useContext(AppContextStoreContext)!;
    const store = useMemo(() => new PairingUsbStore(context), [context]);
    const theme = useTheme();
    const [visible, setDialogVisible] = useState(false);
    useEffect(() => {
        setDialogVisible(true);
    }, []);

    const close = () => {
        setDialogVisible(false);
        setTimeout(() => {
            onClose();
        }, 300);
    };

    return <Dialog open={visible} onClose={() => close()}>
        <Frame padding={4} gap={2} style={{width: D.unit(60)}}>
            <Typography variant={'h5'}>Ustvari USB za uparjanje</Typography>
            <DialogFooter bottom>
                <ButtonOutline center onClick={() => close()}>
                    ZAPRI
                </ButtonOutline>
                <ButtonFilled style={{
                    backgroundColor: store.hasCertificate ? theme.palette.success.main : theme.palette.action.disabled
                }} disabled={!store.hasCertificate} center onClick={async () => {
                    store.download();
                    close();
                }}>
                    PRENESI
                    <LoadingOverlay visible={!store.hasCertificate}/>
                </ButtonFilled>
            </DialogFooter>
        </Frame>
    </Dialog>
});