import {createContext} from "react";
import {AppContextStore} from "../../stores/AppContextStore";
import {ProjectStore} from "../../stores/ProjectStore";
import {makeAutoObservable} from "mobx";
import {ErrorUtils} from "../../utils/ErrorUtils";
import {proto} from "../../proto/messages";
import {asod_project, asod_recorder_audio, asod_recorder_project, recorder_state} from "../../proto/compiled";
import {RecorderContextStore} from "./RecorderContextStore";
import {AnnotationsStore} from "../../stores/project/AnnotationsStore";
import {DisposeUtils} from "../../utils/DisposeUtils";
import {MobxUtils} from "../../utils/MobxUtils";

export const RecorderProjectStoreContext = createContext<RecorderProjectStore | undefined>(undefined);

export class RecorderProjectStore {
    private disposeUtils = new DisposeUtils();
    context: RecorderContextStore;
    project: ProjectStore;
    channels: asod_recorder_audio.IAsodRecorderAudioChannel[] = [];
    recordingState?: recorder_state.IRecorderRecordingState;
    recordingStateLoading = true;
    uploadErrors?: recorder_state.IRecorderProjectUploadErrors;

    constructor(context: RecorderContextStore) {
        this.context = context;
        this.project = new ProjectStore(context.app);
        makeAutoObservable(this);

        this.disposeUtils.add(
            this.context.api.getMessageHandler(new proto.RxAsodRecorderAudioChannels()).subscribe((e) => this.onChannelLevels(e))
        );
        this.disposeUtils.add(
            this.context.api.getMessageHandler(new proto.RxRecorderRecordingState()).subscribe((e) => this.onRecordingState(e))
        );
        this.disposeUtils.add(
            this.context.api.getMessageHandler(new proto.RxAsodRecorderProjectAudioBlock()).subscribe((m) => this.onAudioBlock(m))
        );
        this.disposeUtils.add(
            this.context.api.getMessageHandler(new proto.RxAsodRecorderProjectAudio()).subscribe((m) => this.onAudio(m))
        );
        this.disposeUtils.add(
            this.context.api.getMessageHandler(new proto.RxAsodRecorderProjectAudioChunk()).subscribe((m) => this.onAudioChunk(m))
        );
        this.disposeUtils.add(
            this.context.api.getMessageHandler(new proto.RxRecorderProjectUploadErrors()).subscribe((m) => this.uploadErrors = m.proto)
        );
    }

    async load() {
    }

    dispose() {
        this.project.dispose();
        this.disposeUtils.dispose();
    }

    private onChannelLevels(m: proto.RxAsodRecorderAudioChannels) {
        this.channels = m.proto.channels;
    }

    async toggleRecording() {
        this.recordingStateLoading = true;
        const response = await this.context.api.sendMessage(proto.TxAsodRecorderAudioToggleRecording.create(), {ack: true});
        if (!ErrorUtils.response(response)) {
            this.recordingStateLoading = false;
        }
    }


    private onRecordingState(e: proto.RxRecorderRecordingState) {
        this.recordingState = e.proto;
        this.recordingStateLoading = false;
    }

    private onAudioBlock(m: proto.RxAsodRecorderProjectAudioBlock) {
        if (this.project.projectId !== m.proto.projectId) return;
        const index = this.project.data?.audioBlocks?.findIndex((b) => b.id === m.proto.block?.id);
        if (index !== undefined && index >= 0) {
            const block = this.project.data!.audioBlocks![index];
            block.timestampStart = m.proto.block?.timestampStart;
            block.timestampEnd = m.proto.block?.timestampEnd;
            block.audio!.chunks = m.proto.block?.audio?.chunks;
        } else {
            this.project.data?.audioBlocks?.push(m.proto.block!);
        }
    }

    private onAudio(m: proto.RxAsodRecorderProjectAudio) {
        const block = this.project.audioBlock(m.proto.audioBlockId!);
        if (block !== undefined) {
            block.audio!.chunks = m.proto.audio?.chunks;
            block.audio!.waveform = m.proto.audio?.waveform;
        }
    }

    private onAudioChunk(m: proto.RxAsodRecorderProjectAudioChunk) {
        const block = this.project.audioBlock(m.proto.audioBlockId!);
        if (block !== undefined) {
            const index = block.audio?.chunks?.findIndex((c) => c.id === m.proto.audioChunk!.id);
            if (index !== undefined && index >= 0) {
                const chunk = block.audio!.chunks![index];
                chunk.timestampStart = m.proto.audioChunk?.timestampStart;
                chunk.timestampEnd = m.proto.audioChunk?.timestampEnd;
                chunk.status = m.proto.audioChunk?.status;
                chunk.src = m.proto.audioChunk?.src;
                this.project.audio.refreshAudioChunk(m.proto.audioBlockId, chunk)
            }
        }
    }

    async completeProject() {
        const response = await this.context.api.sendMessage(proto.TxAsodRecorderProjectComplete.create(), {ack: true});
        ErrorUtils.response(response);
        this.project.projectId = undefined;
        await this.project.load();
    }
}

