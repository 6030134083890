/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

export const api_integriteta = $root.api_integriteta = (() => {

    /**
     * Namespace api_integriteta.
     * @exports api_integriteta
     * @namespace
     */
    const api_integriteta = {};

    api_integriteta.ApiIntegritetaVerifySignatures = (function() {

        /**
         * Properties of an ApiIntegritetaVerifySignatures.
         * @memberof api_integriteta
         * @interface IApiIntegritetaVerifySignatures
         * @property {string|null} [verificationId] ApiIntegritetaVerifySignatures verificationId
         * @property {number|Long|null} [fromTimestamp] ApiIntegritetaVerifySignatures fromTimestamp
         * @property {number|Long|null} [toTimestamp] ApiIntegritetaVerifySignatures toTimestamp
         */

        /**
         * Constructs a new ApiIntegritetaVerifySignatures.
         * @memberof api_integriteta
         * @classdesc Represents an ApiIntegritetaVerifySignatures.
         * @implements IApiIntegritetaVerifySignatures
         * @constructor
         * @param {api_integriteta.IApiIntegritetaVerifySignatures=} [properties] Properties to set
         */
        function ApiIntegritetaVerifySignatures(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiIntegritetaVerifySignatures verificationId.
         * @member {string} verificationId
         * @memberof api_integriteta.ApiIntegritetaVerifySignatures
         * @instance
         */
        ApiIntegritetaVerifySignatures.prototype.verificationId = "";

        /**
         * ApiIntegritetaVerifySignatures fromTimestamp.
         * @member {number|Long} fromTimestamp
         * @memberof api_integriteta.ApiIntegritetaVerifySignatures
         * @instance
         */
        ApiIntegritetaVerifySignatures.prototype.fromTimestamp = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ApiIntegritetaVerifySignatures toTimestamp.
         * @member {number|Long} toTimestamp
         * @memberof api_integriteta.ApiIntegritetaVerifySignatures
         * @instance
         */
        ApiIntegritetaVerifySignatures.prototype.toTimestamp = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new ApiIntegritetaVerifySignatures instance using the specified properties.
         * @function create
         * @memberof api_integriteta.ApiIntegritetaVerifySignatures
         * @static
         * @param {api_integriteta.IApiIntegritetaVerifySignatures=} [properties] Properties to set
         * @returns {api_integriteta.ApiIntegritetaVerifySignatures} ApiIntegritetaVerifySignatures instance
         */
        ApiIntegritetaVerifySignatures.create = function create(properties) {
            return new ApiIntegritetaVerifySignatures(properties);
        };

        /**
         * Encodes the specified ApiIntegritetaVerifySignatures message. Does not implicitly {@link api_integriteta.ApiIntegritetaVerifySignatures.verify|verify} messages.
         * @function encode
         * @memberof api_integriteta.ApiIntegritetaVerifySignatures
         * @static
         * @param {api_integriteta.IApiIntegritetaVerifySignatures} message ApiIntegritetaVerifySignatures message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiIntegritetaVerifySignatures.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.verificationId != null && Object.hasOwnProperty.call(message, "verificationId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.verificationId);
            if (message.fromTimestamp != null && Object.hasOwnProperty.call(message, "fromTimestamp"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.fromTimestamp);
            if (message.toTimestamp != null && Object.hasOwnProperty.call(message, "toTimestamp"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.toTimestamp);
            return writer;
        };

        /**
         * Encodes the specified ApiIntegritetaVerifySignatures message, length delimited. Does not implicitly {@link api_integriteta.ApiIntegritetaVerifySignatures.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_integriteta.ApiIntegritetaVerifySignatures
         * @static
         * @param {api_integriteta.IApiIntegritetaVerifySignatures} message ApiIntegritetaVerifySignatures message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiIntegritetaVerifySignatures.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiIntegritetaVerifySignatures message from the specified reader or buffer.
         * @function decode
         * @memberof api_integriteta.ApiIntegritetaVerifySignatures
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_integriteta.ApiIntegritetaVerifySignatures} ApiIntegritetaVerifySignatures
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiIntegritetaVerifySignatures.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_integriteta.ApiIntegritetaVerifySignatures();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.verificationId = reader.string();
                        break;
                    }
                case 2: {
                        message.fromTimestamp = reader.uint64();
                        break;
                    }
                case 3: {
                        message.toTimestamp = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiIntegritetaVerifySignatures message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_integriteta.ApiIntegritetaVerifySignatures
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_integriteta.ApiIntegritetaVerifySignatures} ApiIntegritetaVerifySignatures
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiIntegritetaVerifySignatures.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiIntegritetaVerifySignatures message.
         * @function verify
         * @memberof api_integriteta.ApiIntegritetaVerifySignatures
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiIntegritetaVerifySignatures.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.verificationId != null && message.hasOwnProperty("verificationId"))
                if (!$util.isString(message.verificationId))
                    return "verificationId: string expected";
            if (message.fromTimestamp != null && message.hasOwnProperty("fromTimestamp"))
                if (!$util.isInteger(message.fromTimestamp) && !(message.fromTimestamp && $util.isInteger(message.fromTimestamp.low) && $util.isInteger(message.fromTimestamp.high)))
                    return "fromTimestamp: integer|Long expected";
            if (message.toTimestamp != null && message.hasOwnProperty("toTimestamp"))
                if (!$util.isInteger(message.toTimestamp) && !(message.toTimestamp && $util.isInteger(message.toTimestamp.low) && $util.isInteger(message.toTimestamp.high)))
                    return "toTimestamp: integer|Long expected";
            return null;
        };

        /**
         * Creates an ApiIntegritetaVerifySignatures message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_integriteta.ApiIntegritetaVerifySignatures
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_integriteta.ApiIntegritetaVerifySignatures} ApiIntegritetaVerifySignatures
         */
        ApiIntegritetaVerifySignatures.fromObject = function fromObject(object) {
            if (object instanceof $root.api_integriteta.ApiIntegritetaVerifySignatures)
                return object;
            let message = new $root.api_integriteta.ApiIntegritetaVerifySignatures();
            if (object.verificationId != null)
                message.verificationId = String(object.verificationId);
            if (object.fromTimestamp != null)
                if ($util.Long)
                    (message.fromTimestamp = $util.Long.fromValue(object.fromTimestamp)).unsigned = true;
                else if (typeof object.fromTimestamp === "string")
                    message.fromTimestamp = parseInt(object.fromTimestamp, 10);
                else if (typeof object.fromTimestamp === "number")
                    message.fromTimestamp = object.fromTimestamp;
                else if (typeof object.fromTimestamp === "object")
                    message.fromTimestamp = new $util.LongBits(object.fromTimestamp.low >>> 0, object.fromTimestamp.high >>> 0).toNumber(true);
            if (object.toTimestamp != null)
                if ($util.Long)
                    (message.toTimestamp = $util.Long.fromValue(object.toTimestamp)).unsigned = true;
                else if (typeof object.toTimestamp === "string")
                    message.toTimestamp = parseInt(object.toTimestamp, 10);
                else if (typeof object.toTimestamp === "number")
                    message.toTimestamp = object.toTimestamp;
                else if (typeof object.toTimestamp === "object")
                    message.toTimestamp = new $util.LongBits(object.toTimestamp.low >>> 0, object.toTimestamp.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from an ApiIntegritetaVerifySignatures message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_integriteta.ApiIntegritetaVerifySignatures
         * @static
         * @param {api_integriteta.ApiIntegritetaVerifySignatures} message ApiIntegritetaVerifySignatures
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiIntegritetaVerifySignatures.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.verificationId = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.fromTimestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.fromTimestamp = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.toTimestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.toTimestamp = options.longs === String ? "0" : 0;
            }
            if (message.verificationId != null && message.hasOwnProperty("verificationId"))
                object.verificationId = message.verificationId;
            if (message.fromTimestamp != null && message.hasOwnProperty("fromTimestamp"))
                if (typeof message.fromTimestamp === "number")
                    object.fromTimestamp = options.longs === String ? String(message.fromTimestamp) : message.fromTimestamp;
                else
                    object.fromTimestamp = options.longs === String ? $util.Long.prototype.toString.call(message.fromTimestamp) : options.longs === Number ? new $util.LongBits(message.fromTimestamp.low >>> 0, message.fromTimestamp.high >>> 0).toNumber(true) : message.fromTimestamp;
            if (message.toTimestamp != null && message.hasOwnProperty("toTimestamp"))
                if (typeof message.toTimestamp === "number")
                    object.toTimestamp = options.longs === String ? String(message.toTimestamp) : message.toTimestamp;
                else
                    object.toTimestamp = options.longs === String ? $util.Long.prototype.toString.call(message.toTimestamp) : options.longs === Number ? new $util.LongBits(message.toTimestamp.low >>> 0, message.toTimestamp.high >>> 0).toNumber(true) : message.toTimestamp;
            return object;
        };

        /**
         * Converts this ApiIntegritetaVerifySignatures to JSON.
         * @function toJSON
         * @memberof api_integriteta.ApiIntegritetaVerifySignatures
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiIntegritetaVerifySignatures.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiIntegritetaVerifySignatures
         * @function getTypeUrl
         * @memberof api_integriteta.ApiIntegritetaVerifySignatures
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiIntegritetaVerifySignatures.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_integriteta.ApiIntegritetaVerifySignatures";
        };

        return ApiIntegritetaVerifySignatures;
    })();

    api_integriteta.ApiIntegritetaVerifyAllSignatures = (function() {

        /**
         * Properties of an ApiIntegritetaVerifyAllSignatures.
         * @memberof api_integriteta
         * @interface IApiIntegritetaVerifyAllSignatures
         * @property {string|null} [verificationId] ApiIntegritetaVerifyAllSignatures verificationId
         */

        /**
         * Constructs a new ApiIntegritetaVerifyAllSignatures.
         * @memberof api_integriteta
         * @classdesc Represents an ApiIntegritetaVerifyAllSignatures.
         * @implements IApiIntegritetaVerifyAllSignatures
         * @constructor
         * @param {api_integriteta.IApiIntegritetaVerifyAllSignatures=} [properties] Properties to set
         */
        function ApiIntegritetaVerifyAllSignatures(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiIntegritetaVerifyAllSignatures verificationId.
         * @member {string} verificationId
         * @memberof api_integriteta.ApiIntegritetaVerifyAllSignatures
         * @instance
         */
        ApiIntegritetaVerifyAllSignatures.prototype.verificationId = "";

        /**
         * Creates a new ApiIntegritetaVerifyAllSignatures instance using the specified properties.
         * @function create
         * @memberof api_integriteta.ApiIntegritetaVerifyAllSignatures
         * @static
         * @param {api_integriteta.IApiIntegritetaVerifyAllSignatures=} [properties] Properties to set
         * @returns {api_integriteta.ApiIntegritetaVerifyAllSignatures} ApiIntegritetaVerifyAllSignatures instance
         */
        ApiIntegritetaVerifyAllSignatures.create = function create(properties) {
            return new ApiIntegritetaVerifyAllSignatures(properties);
        };

        /**
         * Encodes the specified ApiIntegritetaVerifyAllSignatures message. Does not implicitly {@link api_integriteta.ApiIntegritetaVerifyAllSignatures.verify|verify} messages.
         * @function encode
         * @memberof api_integriteta.ApiIntegritetaVerifyAllSignatures
         * @static
         * @param {api_integriteta.IApiIntegritetaVerifyAllSignatures} message ApiIntegritetaVerifyAllSignatures message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiIntegritetaVerifyAllSignatures.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.verificationId != null && Object.hasOwnProperty.call(message, "verificationId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.verificationId);
            return writer;
        };

        /**
         * Encodes the specified ApiIntegritetaVerifyAllSignatures message, length delimited. Does not implicitly {@link api_integriteta.ApiIntegritetaVerifyAllSignatures.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_integriteta.ApiIntegritetaVerifyAllSignatures
         * @static
         * @param {api_integriteta.IApiIntegritetaVerifyAllSignatures} message ApiIntegritetaVerifyAllSignatures message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiIntegritetaVerifyAllSignatures.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiIntegritetaVerifyAllSignatures message from the specified reader or buffer.
         * @function decode
         * @memberof api_integriteta.ApiIntegritetaVerifyAllSignatures
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_integriteta.ApiIntegritetaVerifyAllSignatures} ApiIntegritetaVerifyAllSignatures
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiIntegritetaVerifyAllSignatures.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_integriteta.ApiIntegritetaVerifyAllSignatures();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.verificationId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiIntegritetaVerifyAllSignatures message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_integriteta.ApiIntegritetaVerifyAllSignatures
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_integriteta.ApiIntegritetaVerifyAllSignatures} ApiIntegritetaVerifyAllSignatures
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiIntegritetaVerifyAllSignatures.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiIntegritetaVerifyAllSignatures message.
         * @function verify
         * @memberof api_integriteta.ApiIntegritetaVerifyAllSignatures
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiIntegritetaVerifyAllSignatures.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.verificationId != null && message.hasOwnProperty("verificationId"))
                if (!$util.isString(message.verificationId))
                    return "verificationId: string expected";
            return null;
        };

        /**
         * Creates an ApiIntegritetaVerifyAllSignatures message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_integriteta.ApiIntegritetaVerifyAllSignatures
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_integriteta.ApiIntegritetaVerifyAllSignatures} ApiIntegritetaVerifyAllSignatures
         */
        ApiIntegritetaVerifyAllSignatures.fromObject = function fromObject(object) {
            if (object instanceof $root.api_integriteta.ApiIntegritetaVerifyAllSignatures)
                return object;
            let message = new $root.api_integriteta.ApiIntegritetaVerifyAllSignatures();
            if (object.verificationId != null)
                message.verificationId = String(object.verificationId);
            return message;
        };

        /**
         * Creates a plain object from an ApiIntegritetaVerifyAllSignatures message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_integriteta.ApiIntegritetaVerifyAllSignatures
         * @static
         * @param {api_integriteta.ApiIntegritetaVerifyAllSignatures} message ApiIntegritetaVerifyAllSignatures
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiIntegritetaVerifyAllSignatures.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.verificationId = "";
            if (message.verificationId != null && message.hasOwnProperty("verificationId"))
                object.verificationId = message.verificationId;
            return object;
        };

        /**
         * Converts this ApiIntegritetaVerifyAllSignatures to JSON.
         * @function toJSON
         * @memberof api_integriteta.ApiIntegritetaVerifyAllSignatures
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiIntegritetaVerifyAllSignatures.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiIntegritetaVerifyAllSignatures
         * @function getTypeUrl
         * @memberof api_integriteta.ApiIntegritetaVerifyAllSignatures
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiIntegritetaVerifyAllSignatures.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_integriteta.ApiIntegritetaVerifyAllSignatures";
        };

        return ApiIntegritetaVerifyAllSignatures;
    })();

    api_integriteta.ApiIntegritetaGetVerificationHistory = (function() {

        /**
         * Properties of an ApiIntegritetaGetVerificationHistory.
         * @memberof api_integriteta
         * @interface IApiIntegritetaGetVerificationHistory
         */

        /**
         * Constructs a new ApiIntegritetaGetVerificationHistory.
         * @memberof api_integriteta
         * @classdesc Represents an ApiIntegritetaGetVerificationHistory.
         * @implements IApiIntegritetaGetVerificationHistory
         * @constructor
         * @param {api_integriteta.IApiIntegritetaGetVerificationHistory=} [properties] Properties to set
         */
        function ApiIntegritetaGetVerificationHistory(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new ApiIntegritetaGetVerificationHistory instance using the specified properties.
         * @function create
         * @memberof api_integriteta.ApiIntegritetaGetVerificationHistory
         * @static
         * @param {api_integriteta.IApiIntegritetaGetVerificationHistory=} [properties] Properties to set
         * @returns {api_integriteta.ApiIntegritetaGetVerificationHistory} ApiIntegritetaGetVerificationHistory instance
         */
        ApiIntegritetaGetVerificationHistory.create = function create(properties) {
            return new ApiIntegritetaGetVerificationHistory(properties);
        };

        /**
         * Encodes the specified ApiIntegritetaGetVerificationHistory message. Does not implicitly {@link api_integriteta.ApiIntegritetaGetVerificationHistory.verify|verify} messages.
         * @function encode
         * @memberof api_integriteta.ApiIntegritetaGetVerificationHistory
         * @static
         * @param {api_integriteta.IApiIntegritetaGetVerificationHistory} message ApiIntegritetaGetVerificationHistory message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiIntegritetaGetVerificationHistory.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified ApiIntegritetaGetVerificationHistory message, length delimited. Does not implicitly {@link api_integriteta.ApiIntegritetaGetVerificationHistory.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_integriteta.ApiIntegritetaGetVerificationHistory
         * @static
         * @param {api_integriteta.IApiIntegritetaGetVerificationHistory} message ApiIntegritetaGetVerificationHistory message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiIntegritetaGetVerificationHistory.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiIntegritetaGetVerificationHistory message from the specified reader or buffer.
         * @function decode
         * @memberof api_integriteta.ApiIntegritetaGetVerificationHistory
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_integriteta.ApiIntegritetaGetVerificationHistory} ApiIntegritetaGetVerificationHistory
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiIntegritetaGetVerificationHistory.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_integriteta.ApiIntegritetaGetVerificationHistory();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiIntegritetaGetVerificationHistory message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_integriteta.ApiIntegritetaGetVerificationHistory
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_integriteta.ApiIntegritetaGetVerificationHistory} ApiIntegritetaGetVerificationHistory
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiIntegritetaGetVerificationHistory.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiIntegritetaGetVerificationHistory message.
         * @function verify
         * @memberof api_integriteta.ApiIntegritetaGetVerificationHistory
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiIntegritetaGetVerificationHistory.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an ApiIntegritetaGetVerificationHistory message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_integriteta.ApiIntegritetaGetVerificationHistory
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_integriteta.ApiIntegritetaGetVerificationHistory} ApiIntegritetaGetVerificationHistory
         */
        ApiIntegritetaGetVerificationHistory.fromObject = function fromObject(object) {
            if (object instanceof $root.api_integriteta.ApiIntegritetaGetVerificationHistory)
                return object;
            return new $root.api_integriteta.ApiIntegritetaGetVerificationHistory();
        };

        /**
         * Creates a plain object from an ApiIntegritetaGetVerificationHistory message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_integriteta.ApiIntegritetaGetVerificationHistory
         * @static
         * @param {api_integriteta.ApiIntegritetaGetVerificationHistory} message ApiIntegritetaGetVerificationHistory
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiIntegritetaGetVerificationHistory.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this ApiIntegritetaGetVerificationHistory to JSON.
         * @function toJSON
         * @memberof api_integriteta.ApiIntegritetaGetVerificationHistory
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiIntegritetaGetVerificationHistory.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiIntegritetaGetVerificationHistory
         * @function getTypeUrl
         * @memberof api_integriteta.ApiIntegritetaGetVerificationHistory
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiIntegritetaGetVerificationHistory.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_integriteta.ApiIntegritetaGetVerificationHistory";
        };

        return ApiIntegritetaGetVerificationHistory;
    })();

    api_integriteta.ApiIntegritetaVerificationHistory = (function() {

        /**
         * Properties of an ApiIntegritetaVerificationHistory.
         * @memberof api_integriteta
         * @interface IApiIntegritetaVerificationHistory
         * @property {string|null} [sourceEventId] ApiIntegritetaVerificationHistory sourceEventId
         * @property {Array.<api_integriteta.IApiIntegritetaVerificationHistoryEntry>|null} [history] ApiIntegritetaVerificationHistory history
         * @property {number|null} [offset] ApiIntegritetaVerificationHistory offset
         * @property {number|null} [pageSize] ApiIntegritetaVerificationHistory pageSize
         * @property {number|null} [lastItemIndex] ApiIntegritetaVerificationHistory lastItemIndex
         */

        /**
         * Constructs a new ApiIntegritetaVerificationHistory.
         * @memberof api_integriteta
         * @classdesc Represents an ApiIntegritetaVerificationHistory.
         * @implements IApiIntegritetaVerificationHistory
         * @constructor
         * @param {api_integriteta.IApiIntegritetaVerificationHistory=} [properties] Properties to set
         */
        function ApiIntegritetaVerificationHistory(properties) {
            this.history = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiIntegritetaVerificationHistory sourceEventId.
         * @member {string} sourceEventId
         * @memberof api_integriteta.ApiIntegritetaVerificationHistory
         * @instance
         */
        ApiIntegritetaVerificationHistory.prototype.sourceEventId = "";

        /**
         * ApiIntegritetaVerificationHistory history.
         * @member {Array.<api_integriteta.IApiIntegritetaVerificationHistoryEntry>} history
         * @memberof api_integriteta.ApiIntegritetaVerificationHistory
         * @instance
         */
        ApiIntegritetaVerificationHistory.prototype.history = $util.emptyArray;

        /**
         * ApiIntegritetaVerificationHistory offset.
         * @member {number} offset
         * @memberof api_integriteta.ApiIntegritetaVerificationHistory
         * @instance
         */
        ApiIntegritetaVerificationHistory.prototype.offset = 0;

        /**
         * ApiIntegritetaVerificationHistory pageSize.
         * @member {number} pageSize
         * @memberof api_integriteta.ApiIntegritetaVerificationHistory
         * @instance
         */
        ApiIntegritetaVerificationHistory.prototype.pageSize = 0;

        /**
         * ApiIntegritetaVerificationHistory lastItemIndex.
         * @member {number} lastItemIndex
         * @memberof api_integriteta.ApiIntegritetaVerificationHistory
         * @instance
         */
        ApiIntegritetaVerificationHistory.prototype.lastItemIndex = 0;

        /**
         * Creates a new ApiIntegritetaVerificationHistory instance using the specified properties.
         * @function create
         * @memberof api_integriteta.ApiIntegritetaVerificationHistory
         * @static
         * @param {api_integriteta.IApiIntegritetaVerificationHistory=} [properties] Properties to set
         * @returns {api_integriteta.ApiIntegritetaVerificationHistory} ApiIntegritetaVerificationHistory instance
         */
        ApiIntegritetaVerificationHistory.create = function create(properties) {
            return new ApiIntegritetaVerificationHistory(properties);
        };

        /**
         * Encodes the specified ApiIntegritetaVerificationHistory message. Does not implicitly {@link api_integriteta.ApiIntegritetaVerificationHistory.verify|verify} messages.
         * @function encode
         * @memberof api_integriteta.ApiIntegritetaVerificationHistory
         * @static
         * @param {api_integriteta.IApiIntegritetaVerificationHistory} message ApiIntegritetaVerificationHistory message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiIntegritetaVerificationHistory.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sourceEventId != null && Object.hasOwnProperty.call(message, "sourceEventId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.sourceEventId);
            if (message.history != null && message.history.length)
                for (let i = 0; i < message.history.length; ++i)
                    $root.api_integriteta.ApiIntegritetaVerificationHistoryEntry.encode(message.history[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.offset != null && Object.hasOwnProperty.call(message, "offset"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.offset);
            if (message.pageSize != null && Object.hasOwnProperty.call(message, "pageSize"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.pageSize);
            if (message.lastItemIndex != null && Object.hasOwnProperty.call(message, "lastItemIndex"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.lastItemIndex);
            return writer;
        };

        /**
         * Encodes the specified ApiIntegritetaVerificationHistory message, length delimited. Does not implicitly {@link api_integriteta.ApiIntegritetaVerificationHistory.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_integriteta.ApiIntegritetaVerificationHistory
         * @static
         * @param {api_integriteta.IApiIntegritetaVerificationHistory} message ApiIntegritetaVerificationHistory message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiIntegritetaVerificationHistory.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiIntegritetaVerificationHistory message from the specified reader or buffer.
         * @function decode
         * @memberof api_integriteta.ApiIntegritetaVerificationHistory
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_integriteta.ApiIntegritetaVerificationHistory} ApiIntegritetaVerificationHistory
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiIntegritetaVerificationHistory.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_integriteta.ApiIntegritetaVerificationHistory();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sourceEventId = reader.string();
                        break;
                    }
                case 2: {
                        if (!(message.history && message.history.length))
                            message.history = [];
                        message.history.push($root.api_integriteta.ApiIntegritetaVerificationHistoryEntry.decode(reader, reader.uint32()));
                        break;
                    }
                case 3: {
                        message.offset = reader.int32();
                        break;
                    }
                case 4: {
                        message.pageSize = reader.int32();
                        break;
                    }
                case 5: {
                        message.lastItemIndex = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiIntegritetaVerificationHistory message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_integriteta.ApiIntegritetaVerificationHistory
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_integriteta.ApiIntegritetaVerificationHistory} ApiIntegritetaVerificationHistory
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiIntegritetaVerificationHistory.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiIntegritetaVerificationHistory message.
         * @function verify
         * @memberof api_integriteta.ApiIntegritetaVerificationHistory
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiIntegritetaVerificationHistory.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.sourceEventId != null && message.hasOwnProperty("sourceEventId"))
                if (!$util.isString(message.sourceEventId))
                    return "sourceEventId: string expected";
            if (message.history != null && message.hasOwnProperty("history")) {
                if (!Array.isArray(message.history))
                    return "history: array expected";
                for (let i = 0; i < message.history.length; ++i) {
                    let error = $root.api_integriteta.ApiIntegritetaVerificationHistoryEntry.verify(message.history[i]);
                    if (error)
                        return "history." + error;
                }
            }
            if (message.offset != null && message.hasOwnProperty("offset"))
                if (!$util.isInteger(message.offset))
                    return "offset: integer expected";
            if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                if (!$util.isInteger(message.pageSize))
                    return "pageSize: integer expected";
            if (message.lastItemIndex != null && message.hasOwnProperty("lastItemIndex"))
                if (!$util.isInteger(message.lastItemIndex))
                    return "lastItemIndex: integer expected";
            return null;
        };

        /**
         * Creates an ApiIntegritetaVerificationHistory message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_integriteta.ApiIntegritetaVerificationHistory
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_integriteta.ApiIntegritetaVerificationHistory} ApiIntegritetaVerificationHistory
         */
        ApiIntegritetaVerificationHistory.fromObject = function fromObject(object) {
            if (object instanceof $root.api_integriteta.ApiIntegritetaVerificationHistory)
                return object;
            let message = new $root.api_integriteta.ApiIntegritetaVerificationHistory();
            if (object.sourceEventId != null)
                message.sourceEventId = String(object.sourceEventId);
            if (object.history) {
                if (!Array.isArray(object.history))
                    throw TypeError(".api_integriteta.ApiIntegritetaVerificationHistory.history: array expected");
                message.history = [];
                for (let i = 0; i < object.history.length; ++i) {
                    if (typeof object.history[i] !== "object")
                        throw TypeError(".api_integriteta.ApiIntegritetaVerificationHistory.history: object expected");
                    message.history[i] = $root.api_integriteta.ApiIntegritetaVerificationHistoryEntry.fromObject(object.history[i]);
                }
            }
            if (object.offset != null)
                message.offset = object.offset | 0;
            if (object.pageSize != null)
                message.pageSize = object.pageSize | 0;
            if (object.lastItemIndex != null)
                message.lastItemIndex = object.lastItemIndex | 0;
            return message;
        };

        /**
         * Creates a plain object from an ApiIntegritetaVerificationHistory message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_integriteta.ApiIntegritetaVerificationHistory
         * @static
         * @param {api_integriteta.ApiIntegritetaVerificationHistory} message ApiIntegritetaVerificationHistory
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiIntegritetaVerificationHistory.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.history = [];
            if (options.defaults) {
                object.sourceEventId = "";
                object.offset = 0;
                object.pageSize = 0;
                object.lastItemIndex = 0;
            }
            if (message.sourceEventId != null && message.hasOwnProperty("sourceEventId"))
                object.sourceEventId = message.sourceEventId;
            if (message.history && message.history.length) {
                object.history = [];
                for (let j = 0; j < message.history.length; ++j)
                    object.history[j] = $root.api_integriteta.ApiIntegritetaVerificationHistoryEntry.toObject(message.history[j], options);
            }
            if (message.offset != null && message.hasOwnProperty("offset"))
                object.offset = message.offset;
            if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                object.pageSize = message.pageSize;
            if (message.lastItemIndex != null && message.hasOwnProperty("lastItemIndex"))
                object.lastItemIndex = message.lastItemIndex;
            return object;
        };

        /**
         * Converts this ApiIntegritetaVerificationHistory to JSON.
         * @function toJSON
         * @memberof api_integriteta.ApiIntegritetaVerificationHistory
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiIntegritetaVerificationHistory.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiIntegritetaVerificationHistory
         * @function getTypeUrl
         * @memberof api_integriteta.ApiIntegritetaVerificationHistory
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiIntegritetaVerificationHistory.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_integriteta.ApiIntegritetaVerificationHistory";
        };

        return ApiIntegritetaVerificationHistory;
    })();

    api_integriteta.ApiIntegritetaVerificationHistoryEntry = (function() {

        /**
         * Properties of an ApiIntegritetaVerificationHistoryEntry.
         * @memberof api_integriteta
         * @interface IApiIntegritetaVerificationHistoryEntry
         * @property {string|null} [verificationId] ApiIntegritetaVerificationHistoryEntry verificationId
         * @property {number|null} [validCount] ApiIntegritetaVerificationHistoryEntry validCount
         * @property {number|null} [invalidCount] ApiIntegritetaVerificationHistoryEntry invalidCount
         * @property {string|null} [triggeredByUser] ApiIntegritetaVerificationHistoryEntry triggeredByUser
         * @property {number|Long|null} [triggeredAt] ApiIntegritetaVerificationHistoryEntry triggeredAt
         */

        /**
         * Constructs a new ApiIntegritetaVerificationHistoryEntry.
         * @memberof api_integriteta
         * @classdesc Represents an ApiIntegritetaVerificationHistoryEntry.
         * @implements IApiIntegritetaVerificationHistoryEntry
         * @constructor
         * @param {api_integriteta.IApiIntegritetaVerificationHistoryEntry=} [properties] Properties to set
         */
        function ApiIntegritetaVerificationHistoryEntry(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiIntegritetaVerificationHistoryEntry verificationId.
         * @member {string} verificationId
         * @memberof api_integriteta.ApiIntegritetaVerificationHistoryEntry
         * @instance
         */
        ApiIntegritetaVerificationHistoryEntry.prototype.verificationId = "";

        /**
         * ApiIntegritetaVerificationHistoryEntry validCount.
         * @member {number} validCount
         * @memberof api_integriteta.ApiIntegritetaVerificationHistoryEntry
         * @instance
         */
        ApiIntegritetaVerificationHistoryEntry.prototype.validCount = 0;

        /**
         * ApiIntegritetaVerificationHistoryEntry invalidCount.
         * @member {number} invalidCount
         * @memberof api_integriteta.ApiIntegritetaVerificationHistoryEntry
         * @instance
         */
        ApiIntegritetaVerificationHistoryEntry.prototype.invalidCount = 0;

        /**
         * ApiIntegritetaVerificationHistoryEntry triggeredByUser.
         * @member {string} triggeredByUser
         * @memberof api_integriteta.ApiIntegritetaVerificationHistoryEntry
         * @instance
         */
        ApiIntegritetaVerificationHistoryEntry.prototype.triggeredByUser = "";

        /**
         * ApiIntegritetaVerificationHistoryEntry triggeredAt.
         * @member {number|Long} triggeredAt
         * @memberof api_integriteta.ApiIntegritetaVerificationHistoryEntry
         * @instance
         */
        ApiIntegritetaVerificationHistoryEntry.prototype.triggeredAt = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new ApiIntegritetaVerificationHistoryEntry instance using the specified properties.
         * @function create
         * @memberof api_integriteta.ApiIntegritetaVerificationHistoryEntry
         * @static
         * @param {api_integriteta.IApiIntegritetaVerificationHistoryEntry=} [properties] Properties to set
         * @returns {api_integriteta.ApiIntegritetaVerificationHistoryEntry} ApiIntegritetaVerificationHistoryEntry instance
         */
        ApiIntegritetaVerificationHistoryEntry.create = function create(properties) {
            return new ApiIntegritetaVerificationHistoryEntry(properties);
        };

        /**
         * Encodes the specified ApiIntegritetaVerificationHistoryEntry message. Does not implicitly {@link api_integriteta.ApiIntegritetaVerificationHistoryEntry.verify|verify} messages.
         * @function encode
         * @memberof api_integriteta.ApiIntegritetaVerificationHistoryEntry
         * @static
         * @param {api_integriteta.IApiIntegritetaVerificationHistoryEntry} message ApiIntegritetaVerificationHistoryEntry message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiIntegritetaVerificationHistoryEntry.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.verificationId != null && Object.hasOwnProperty.call(message, "verificationId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.verificationId);
            if (message.validCount != null && Object.hasOwnProperty.call(message, "validCount"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.validCount);
            if (message.invalidCount != null && Object.hasOwnProperty.call(message, "invalidCount"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.invalidCount);
            if (message.triggeredByUser != null && Object.hasOwnProperty.call(message, "triggeredByUser"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.triggeredByUser);
            if (message.triggeredAt != null && Object.hasOwnProperty.call(message, "triggeredAt"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint64(message.triggeredAt);
            return writer;
        };

        /**
         * Encodes the specified ApiIntegritetaVerificationHistoryEntry message, length delimited. Does not implicitly {@link api_integriteta.ApiIntegritetaVerificationHistoryEntry.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_integriteta.ApiIntegritetaVerificationHistoryEntry
         * @static
         * @param {api_integriteta.IApiIntegritetaVerificationHistoryEntry} message ApiIntegritetaVerificationHistoryEntry message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiIntegritetaVerificationHistoryEntry.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiIntegritetaVerificationHistoryEntry message from the specified reader or buffer.
         * @function decode
         * @memberof api_integriteta.ApiIntegritetaVerificationHistoryEntry
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_integriteta.ApiIntegritetaVerificationHistoryEntry} ApiIntegritetaVerificationHistoryEntry
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiIntegritetaVerificationHistoryEntry.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_integriteta.ApiIntegritetaVerificationHistoryEntry();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.verificationId = reader.string();
                        break;
                    }
                case 2: {
                        message.validCount = reader.int32();
                        break;
                    }
                case 3: {
                        message.invalidCount = reader.int32();
                        break;
                    }
                case 4: {
                        message.triggeredByUser = reader.string();
                        break;
                    }
                case 5: {
                        message.triggeredAt = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiIntegritetaVerificationHistoryEntry message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_integriteta.ApiIntegritetaVerificationHistoryEntry
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_integriteta.ApiIntegritetaVerificationHistoryEntry} ApiIntegritetaVerificationHistoryEntry
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiIntegritetaVerificationHistoryEntry.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiIntegritetaVerificationHistoryEntry message.
         * @function verify
         * @memberof api_integriteta.ApiIntegritetaVerificationHistoryEntry
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiIntegritetaVerificationHistoryEntry.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.verificationId != null && message.hasOwnProperty("verificationId"))
                if (!$util.isString(message.verificationId))
                    return "verificationId: string expected";
            if (message.validCount != null && message.hasOwnProperty("validCount"))
                if (!$util.isInteger(message.validCount))
                    return "validCount: integer expected";
            if (message.invalidCount != null && message.hasOwnProperty("invalidCount"))
                if (!$util.isInteger(message.invalidCount))
                    return "invalidCount: integer expected";
            if (message.triggeredByUser != null && message.hasOwnProperty("triggeredByUser"))
                if (!$util.isString(message.triggeredByUser))
                    return "triggeredByUser: string expected";
            if (message.triggeredAt != null && message.hasOwnProperty("triggeredAt"))
                if (!$util.isInteger(message.triggeredAt) && !(message.triggeredAt && $util.isInteger(message.triggeredAt.low) && $util.isInteger(message.triggeredAt.high)))
                    return "triggeredAt: integer|Long expected";
            return null;
        };

        /**
         * Creates an ApiIntegritetaVerificationHistoryEntry message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_integriteta.ApiIntegritetaVerificationHistoryEntry
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_integriteta.ApiIntegritetaVerificationHistoryEntry} ApiIntegritetaVerificationHistoryEntry
         */
        ApiIntegritetaVerificationHistoryEntry.fromObject = function fromObject(object) {
            if (object instanceof $root.api_integriteta.ApiIntegritetaVerificationHistoryEntry)
                return object;
            let message = new $root.api_integriteta.ApiIntegritetaVerificationHistoryEntry();
            if (object.verificationId != null)
                message.verificationId = String(object.verificationId);
            if (object.validCount != null)
                message.validCount = object.validCount | 0;
            if (object.invalidCount != null)
                message.invalidCount = object.invalidCount | 0;
            if (object.triggeredByUser != null)
                message.triggeredByUser = String(object.triggeredByUser);
            if (object.triggeredAt != null)
                if ($util.Long)
                    (message.triggeredAt = $util.Long.fromValue(object.triggeredAt)).unsigned = true;
                else if (typeof object.triggeredAt === "string")
                    message.triggeredAt = parseInt(object.triggeredAt, 10);
                else if (typeof object.triggeredAt === "number")
                    message.triggeredAt = object.triggeredAt;
                else if (typeof object.triggeredAt === "object")
                    message.triggeredAt = new $util.LongBits(object.triggeredAt.low >>> 0, object.triggeredAt.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from an ApiIntegritetaVerificationHistoryEntry message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_integriteta.ApiIntegritetaVerificationHistoryEntry
         * @static
         * @param {api_integriteta.ApiIntegritetaVerificationHistoryEntry} message ApiIntegritetaVerificationHistoryEntry
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiIntegritetaVerificationHistoryEntry.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.verificationId = "";
                object.validCount = 0;
                object.invalidCount = 0;
                object.triggeredByUser = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.triggeredAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.triggeredAt = options.longs === String ? "0" : 0;
            }
            if (message.verificationId != null && message.hasOwnProperty("verificationId"))
                object.verificationId = message.verificationId;
            if (message.validCount != null && message.hasOwnProperty("validCount"))
                object.validCount = message.validCount;
            if (message.invalidCount != null && message.hasOwnProperty("invalidCount"))
                object.invalidCount = message.invalidCount;
            if (message.triggeredByUser != null && message.hasOwnProperty("triggeredByUser"))
                object.triggeredByUser = message.triggeredByUser;
            if (message.triggeredAt != null && message.hasOwnProperty("triggeredAt"))
                if (typeof message.triggeredAt === "number")
                    object.triggeredAt = options.longs === String ? String(message.triggeredAt) : message.triggeredAt;
                else
                    object.triggeredAt = options.longs === String ? $util.Long.prototype.toString.call(message.triggeredAt) : options.longs === Number ? new $util.LongBits(message.triggeredAt.low >>> 0, message.triggeredAt.high >>> 0).toNumber(true) : message.triggeredAt;
            return object;
        };

        /**
         * Converts this ApiIntegritetaVerificationHistoryEntry to JSON.
         * @function toJSON
         * @memberof api_integriteta.ApiIntegritetaVerificationHistoryEntry
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiIntegritetaVerificationHistoryEntry.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiIntegritetaVerificationHistoryEntry
         * @function getTypeUrl
         * @memberof api_integriteta.ApiIntegritetaVerificationHistoryEntry
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiIntegritetaVerificationHistoryEntry.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_integriteta.ApiIntegritetaVerificationHistoryEntry";
        };

        return ApiIntegritetaVerificationHistoryEntry;
    })();

    api_integriteta.ApiIntegritetaCancelVerification = (function() {

        /**
         * Properties of an ApiIntegritetaCancelVerification.
         * @memberof api_integriteta
         * @interface IApiIntegritetaCancelVerification
         * @property {string|null} [verificationId] ApiIntegritetaCancelVerification verificationId
         */

        /**
         * Constructs a new ApiIntegritetaCancelVerification.
         * @memberof api_integriteta
         * @classdesc Represents an ApiIntegritetaCancelVerification.
         * @implements IApiIntegritetaCancelVerification
         * @constructor
         * @param {api_integriteta.IApiIntegritetaCancelVerification=} [properties] Properties to set
         */
        function ApiIntegritetaCancelVerification(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiIntegritetaCancelVerification verificationId.
         * @member {string} verificationId
         * @memberof api_integriteta.ApiIntegritetaCancelVerification
         * @instance
         */
        ApiIntegritetaCancelVerification.prototype.verificationId = "";

        /**
         * Creates a new ApiIntegritetaCancelVerification instance using the specified properties.
         * @function create
         * @memberof api_integriteta.ApiIntegritetaCancelVerification
         * @static
         * @param {api_integriteta.IApiIntegritetaCancelVerification=} [properties] Properties to set
         * @returns {api_integriteta.ApiIntegritetaCancelVerification} ApiIntegritetaCancelVerification instance
         */
        ApiIntegritetaCancelVerification.create = function create(properties) {
            return new ApiIntegritetaCancelVerification(properties);
        };

        /**
         * Encodes the specified ApiIntegritetaCancelVerification message. Does not implicitly {@link api_integriteta.ApiIntegritetaCancelVerification.verify|verify} messages.
         * @function encode
         * @memberof api_integriteta.ApiIntegritetaCancelVerification
         * @static
         * @param {api_integriteta.IApiIntegritetaCancelVerification} message ApiIntegritetaCancelVerification message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiIntegritetaCancelVerification.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.verificationId != null && Object.hasOwnProperty.call(message, "verificationId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.verificationId);
            return writer;
        };

        /**
         * Encodes the specified ApiIntegritetaCancelVerification message, length delimited. Does not implicitly {@link api_integriteta.ApiIntegritetaCancelVerification.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_integriteta.ApiIntegritetaCancelVerification
         * @static
         * @param {api_integriteta.IApiIntegritetaCancelVerification} message ApiIntegritetaCancelVerification message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiIntegritetaCancelVerification.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiIntegritetaCancelVerification message from the specified reader or buffer.
         * @function decode
         * @memberof api_integriteta.ApiIntegritetaCancelVerification
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_integriteta.ApiIntegritetaCancelVerification} ApiIntegritetaCancelVerification
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiIntegritetaCancelVerification.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_integriteta.ApiIntegritetaCancelVerification();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.verificationId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiIntegritetaCancelVerification message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_integriteta.ApiIntegritetaCancelVerification
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_integriteta.ApiIntegritetaCancelVerification} ApiIntegritetaCancelVerification
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiIntegritetaCancelVerification.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiIntegritetaCancelVerification message.
         * @function verify
         * @memberof api_integriteta.ApiIntegritetaCancelVerification
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiIntegritetaCancelVerification.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.verificationId != null && message.hasOwnProperty("verificationId"))
                if (!$util.isString(message.verificationId))
                    return "verificationId: string expected";
            return null;
        };

        /**
         * Creates an ApiIntegritetaCancelVerification message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_integriteta.ApiIntegritetaCancelVerification
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_integriteta.ApiIntegritetaCancelVerification} ApiIntegritetaCancelVerification
         */
        ApiIntegritetaCancelVerification.fromObject = function fromObject(object) {
            if (object instanceof $root.api_integriteta.ApiIntegritetaCancelVerification)
                return object;
            let message = new $root.api_integriteta.ApiIntegritetaCancelVerification();
            if (object.verificationId != null)
                message.verificationId = String(object.verificationId);
            return message;
        };

        /**
         * Creates a plain object from an ApiIntegritetaCancelVerification message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_integriteta.ApiIntegritetaCancelVerification
         * @static
         * @param {api_integriteta.ApiIntegritetaCancelVerification} message ApiIntegritetaCancelVerification
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiIntegritetaCancelVerification.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.verificationId = "";
            if (message.verificationId != null && message.hasOwnProperty("verificationId"))
                object.verificationId = message.verificationId;
            return object;
        };

        /**
         * Converts this ApiIntegritetaCancelVerification to JSON.
         * @function toJSON
         * @memberof api_integriteta.ApiIntegritetaCancelVerification
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiIntegritetaCancelVerification.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiIntegritetaCancelVerification
         * @function getTypeUrl
         * @memberof api_integriteta.ApiIntegritetaCancelVerification
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiIntegritetaCancelVerification.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_integriteta.ApiIntegritetaCancelVerification";
        };

        return ApiIntegritetaCancelVerification;
    })();

    return api_integriteta;
})();

export const recorder_pairing = $root.recorder_pairing = (() => {

    /**
     * Namespace recorder_pairing.
     * @exports recorder_pairing
     * @namespace
     */
    const recorder_pairing = {};

    recorder_pairing.RecorderOnPairingCertPasswordRequest = (function() {

        /**
         * Properties of a RecorderOnPairingCertPasswordRequest.
         * @memberof recorder_pairing
         * @interface IRecorderOnPairingCertPasswordRequest
         */

        /**
         * Constructs a new RecorderOnPairingCertPasswordRequest.
         * @memberof recorder_pairing
         * @classdesc Represents a RecorderOnPairingCertPasswordRequest.
         * @implements IRecorderOnPairingCertPasswordRequest
         * @constructor
         * @param {recorder_pairing.IRecorderOnPairingCertPasswordRequest=} [properties] Properties to set
         */
        function RecorderOnPairingCertPasswordRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new RecorderOnPairingCertPasswordRequest instance using the specified properties.
         * @function create
         * @memberof recorder_pairing.RecorderOnPairingCertPasswordRequest
         * @static
         * @param {recorder_pairing.IRecorderOnPairingCertPasswordRequest=} [properties] Properties to set
         * @returns {recorder_pairing.RecorderOnPairingCertPasswordRequest} RecorderOnPairingCertPasswordRequest instance
         */
        RecorderOnPairingCertPasswordRequest.create = function create(properties) {
            return new RecorderOnPairingCertPasswordRequest(properties);
        };

        /**
         * Encodes the specified RecorderOnPairingCertPasswordRequest message. Does not implicitly {@link recorder_pairing.RecorderOnPairingCertPasswordRequest.verify|verify} messages.
         * @function encode
         * @memberof recorder_pairing.RecorderOnPairingCertPasswordRequest
         * @static
         * @param {recorder_pairing.IRecorderOnPairingCertPasswordRequest} message RecorderOnPairingCertPasswordRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RecorderOnPairingCertPasswordRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified RecorderOnPairingCertPasswordRequest message, length delimited. Does not implicitly {@link recorder_pairing.RecorderOnPairingCertPasswordRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof recorder_pairing.RecorderOnPairingCertPasswordRequest
         * @static
         * @param {recorder_pairing.IRecorderOnPairingCertPasswordRequest} message RecorderOnPairingCertPasswordRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RecorderOnPairingCertPasswordRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RecorderOnPairingCertPasswordRequest message from the specified reader or buffer.
         * @function decode
         * @memberof recorder_pairing.RecorderOnPairingCertPasswordRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {recorder_pairing.RecorderOnPairingCertPasswordRequest} RecorderOnPairingCertPasswordRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RecorderOnPairingCertPasswordRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.recorder_pairing.RecorderOnPairingCertPasswordRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RecorderOnPairingCertPasswordRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof recorder_pairing.RecorderOnPairingCertPasswordRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {recorder_pairing.RecorderOnPairingCertPasswordRequest} RecorderOnPairingCertPasswordRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RecorderOnPairingCertPasswordRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RecorderOnPairingCertPasswordRequest message.
         * @function verify
         * @memberof recorder_pairing.RecorderOnPairingCertPasswordRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RecorderOnPairingCertPasswordRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a RecorderOnPairingCertPasswordRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof recorder_pairing.RecorderOnPairingCertPasswordRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {recorder_pairing.RecorderOnPairingCertPasswordRequest} RecorderOnPairingCertPasswordRequest
         */
        RecorderOnPairingCertPasswordRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.recorder_pairing.RecorderOnPairingCertPasswordRequest)
                return object;
            return new $root.recorder_pairing.RecorderOnPairingCertPasswordRequest();
        };

        /**
         * Creates a plain object from a RecorderOnPairingCertPasswordRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof recorder_pairing.RecorderOnPairingCertPasswordRequest
         * @static
         * @param {recorder_pairing.RecorderOnPairingCertPasswordRequest} message RecorderOnPairingCertPasswordRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RecorderOnPairingCertPasswordRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this RecorderOnPairingCertPasswordRequest to JSON.
         * @function toJSON
         * @memberof recorder_pairing.RecorderOnPairingCertPasswordRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RecorderOnPairingCertPasswordRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RecorderOnPairingCertPasswordRequest
         * @function getTypeUrl
         * @memberof recorder_pairing.RecorderOnPairingCertPasswordRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RecorderOnPairingCertPasswordRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/recorder_pairing.RecorderOnPairingCertPasswordRequest";
        };

        return RecorderOnPairingCertPasswordRequest;
    })();

    recorder_pairing.RecorderPairingCertPasswordRequest = (function() {

        /**
         * Properties of a RecorderPairingCertPasswordRequest.
         * @memberof recorder_pairing
         * @interface IRecorderPairingCertPasswordRequest
         * @property {string|null} [password] RecorderPairingCertPasswordRequest password
         */

        /**
         * Constructs a new RecorderPairingCertPasswordRequest.
         * @memberof recorder_pairing
         * @classdesc Represents a RecorderPairingCertPasswordRequest.
         * @implements IRecorderPairingCertPasswordRequest
         * @constructor
         * @param {recorder_pairing.IRecorderPairingCertPasswordRequest=} [properties] Properties to set
         */
        function RecorderPairingCertPasswordRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RecorderPairingCertPasswordRequest password.
         * @member {string} password
         * @memberof recorder_pairing.RecorderPairingCertPasswordRequest
         * @instance
         */
        RecorderPairingCertPasswordRequest.prototype.password = "";

        /**
         * Creates a new RecorderPairingCertPasswordRequest instance using the specified properties.
         * @function create
         * @memberof recorder_pairing.RecorderPairingCertPasswordRequest
         * @static
         * @param {recorder_pairing.IRecorderPairingCertPasswordRequest=} [properties] Properties to set
         * @returns {recorder_pairing.RecorderPairingCertPasswordRequest} RecorderPairingCertPasswordRequest instance
         */
        RecorderPairingCertPasswordRequest.create = function create(properties) {
            return new RecorderPairingCertPasswordRequest(properties);
        };

        /**
         * Encodes the specified RecorderPairingCertPasswordRequest message. Does not implicitly {@link recorder_pairing.RecorderPairingCertPasswordRequest.verify|verify} messages.
         * @function encode
         * @memberof recorder_pairing.RecorderPairingCertPasswordRequest
         * @static
         * @param {recorder_pairing.IRecorderPairingCertPasswordRequest} message RecorderPairingCertPasswordRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RecorderPairingCertPasswordRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.password != null && Object.hasOwnProperty.call(message, "password"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.password);
            return writer;
        };

        /**
         * Encodes the specified RecorderPairingCertPasswordRequest message, length delimited. Does not implicitly {@link recorder_pairing.RecorderPairingCertPasswordRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof recorder_pairing.RecorderPairingCertPasswordRequest
         * @static
         * @param {recorder_pairing.IRecorderPairingCertPasswordRequest} message RecorderPairingCertPasswordRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RecorderPairingCertPasswordRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RecorderPairingCertPasswordRequest message from the specified reader or buffer.
         * @function decode
         * @memberof recorder_pairing.RecorderPairingCertPasswordRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {recorder_pairing.RecorderPairingCertPasswordRequest} RecorderPairingCertPasswordRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RecorderPairingCertPasswordRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.recorder_pairing.RecorderPairingCertPasswordRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.password = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RecorderPairingCertPasswordRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof recorder_pairing.RecorderPairingCertPasswordRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {recorder_pairing.RecorderPairingCertPasswordRequest} RecorderPairingCertPasswordRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RecorderPairingCertPasswordRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RecorderPairingCertPasswordRequest message.
         * @function verify
         * @memberof recorder_pairing.RecorderPairingCertPasswordRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RecorderPairingCertPasswordRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.password != null && message.hasOwnProperty("password"))
                if (!$util.isString(message.password))
                    return "password: string expected";
            return null;
        };

        /**
         * Creates a RecorderPairingCertPasswordRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof recorder_pairing.RecorderPairingCertPasswordRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {recorder_pairing.RecorderPairingCertPasswordRequest} RecorderPairingCertPasswordRequest
         */
        RecorderPairingCertPasswordRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.recorder_pairing.RecorderPairingCertPasswordRequest)
                return object;
            let message = new $root.recorder_pairing.RecorderPairingCertPasswordRequest();
            if (object.password != null)
                message.password = String(object.password);
            return message;
        };

        /**
         * Creates a plain object from a RecorderPairingCertPasswordRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof recorder_pairing.RecorderPairingCertPasswordRequest
         * @static
         * @param {recorder_pairing.RecorderPairingCertPasswordRequest} message RecorderPairingCertPasswordRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RecorderPairingCertPasswordRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.password = "";
            if (message.password != null && message.hasOwnProperty("password"))
                object.password = message.password;
            return object;
        };

        /**
         * Converts this RecorderPairingCertPasswordRequest to JSON.
         * @function toJSON
         * @memberof recorder_pairing.RecorderPairingCertPasswordRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RecorderPairingCertPasswordRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RecorderPairingCertPasswordRequest
         * @function getTypeUrl
         * @memberof recorder_pairing.RecorderPairingCertPasswordRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RecorderPairingCertPasswordRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/recorder_pairing.RecorderPairingCertPasswordRequest";
        };

        return RecorderPairingCertPasswordRequest;
    })();

    recorder_pairing.RecorderDeleteCsr = (function() {

        /**
         * Properties of a RecorderDeleteCsr.
         * @memberof recorder_pairing
         * @interface IRecorderDeleteCsr
         */

        /**
         * Constructs a new RecorderDeleteCsr.
         * @memberof recorder_pairing
         * @classdesc Represents a RecorderDeleteCsr.
         * @implements IRecorderDeleteCsr
         * @constructor
         * @param {recorder_pairing.IRecorderDeleteCsr=} [properties] Properties to set
         */
        function RecorderDeleteCsr(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new RecorderDeleteCsr instance using the specified properties.
         * @function create
         * @memberof recorder_pairing.RecorderDeleteCsr
         * @static
         * @param {recorder_pairing.IRecorderDeleteCsr=} [properties] Properties to set
         * @returns {recorder_pairing.RecorderDeleteCsr} RecorderDeleteCsr instance
         */
        RecorderDeleteCsr.create = function create(properties) {
            return new RecorderDeleteCsr(properties);
        };

        /**
         * Encodes the specified RecorderDeleteCsr message. Does not implicitly {@link recorder_pairing.RecorderDeleteCsr.verify|verify} messages.
         * @function encode
         * @memberof recorder_pairing.RecorderDeleteCsr
         * @static
         * @param {recorder_pairing.IRecorderDeleteCsr} message RecorderDeleteCsr message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RecorderDeleteCsr.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified RecorderDeleteCsr message, length delimited. Does not implicitly {@link recorder_pairing.RecorderDeleteCsr.verify|verify} messages.
         * @function encodeDelimited
         * @memberof recorder_pairing.RecorderDeleteCsr
         * @static
         * @param {recorder_pairing.IRecorderDeleteCsr} message RecorderDeleteCsr message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RecorderDeleteCsr.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RecorderDeleteCsr message from the specified reader or buffer.
         * @function decode
         * @memberof recorder_pairing.RecorderDeleteCsr
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {recorder_pairing.RecorderDeleteCsr} RecorderDeleteCsr
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RecorderDeleteCsr.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.recorder_pairing.RecorderDeleteCsr();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RecorderDeleteCsr message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof recorder_pairing.RecorderDeleteCsr
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {recorder_pairing.RecorderDeleteCsr} RecorderDeleteCsr
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RecorderDeleteCsr.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RecorderDeleteCsr message.
         * @function verify
         * @memberof recorder_pairing.RecorderDeleteCsr
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RecorderDeleteCsr.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a RecorderDeleteCsr message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof recorder_pairing.RecorderDeleteCsr
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {recorder_pairing.RecorderDeleteCsr} RecorderDeleteCsr
         */
        RecorderDeleteCsr.fromObject = function fromObject(object) {
            if (object instanceof $root.recorder_pairing.RecorderDeleteCsr)
                return object;
            return new $root.recorder_pairing.RecorderDeleteCsr();
        };

        /**
         * Creates a plain object from a RecorderDeleteCsr message. Also converts values to other types if specified.
         * @function toObject
         * @memberof recorder_pairing.RecorderDeleteCsr
         * @static
         * @param {recorder_pairing.RecorderDeleteCsr} message RecorderDeleteCsr
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RecorderDeleteCsr.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this RecorderDeleteCsr to JSON.
         * @function toJSON
         * @memberof recorder_pairing.RecorderDeleteCsr
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RecorderDeleteCsr.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RecorderDeleteCsr
         * @function getTypeUrl
         * @memberof recorder_pairing.RecorderDeleteCsr
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RecorderDeleteCsr.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/recorder_pairing.RecorderDeleteCsr";
        };

        return RecorderDeleteCsr;
    })();

    recorder_pairing.RecorderPair = (function() {

        /**
         * Properties of a RecorderPair.
         * @memberof recorder_pairing
         * @interface IRecorderPair
         * @property {recorder_state.IRecorderMetadata|null} [metadata] RecorderPair metadata
         * @property {boolean|null} [saveToUsb] RecorderPair saveToUsb
         */

        /**
         * Constructs a new RecorderPair.
         * @memberof recorder_pairing
         * @classdesc Represents a RecorderPair.
         * @implements IRecorderPair
         * @constructor
         * @param {recorder_pairing.IRecorderPair=} [properties] Properties to set
         */
        function RecorderPair(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RecorderPair metadata.
         * @member {recorder_state.IRecorderMetadata|null|undefined} metadata
         * @memberof recorder_pairing.RecorderPair
         * @instance
         */
        RecorderPair.prototype.metadata = null;

        /**
         * RecorderPair saveToUsb.
         * @member {boolean} saveToUsb
         * @memberof recorder_pairing.RecorderPair
         * @instance
         */
        RecorderPair.prototype.saveToUsb = false;

        /**
         * Creates a new RecorderPair instance using the specified properties.
         * @function create
         * @memberof recorder_pairing.RecorderPair
         * @static
         * @param {recorder_pairing.IRecorderPair=} [properties] Properties to set
         * @returns {recorder_pairing.RecorderPair} RecorderPair instance
         */
        RecorderPair.create = function create(properties) {
            return new RecorderPair(properties);
        };

        /**
         * Encodes the specified RecorderPair message. Does not implicitly {@link recorder_pairing.RecorderPair.verify|verify} messages.
         * @function encode
         * @memberof recorder_pairing.RecorderPair
         * @static
         * @param {recorder_pairing.IRecorderPair} message RecorderPair message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RecorderPair.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                $root.recorder_state.RecorderMetadata.encode(message.metadata, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.saveToUsb != null && Object.hasOwnProperty.call(message, "saveToUsb"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.saveToUsb);
            return writer;
        };

        /**
         * Encodes the specified RecorderPair message, length delimited. Does not implicitly {@link recorder_pairing.RecorderPair.verify|verify} messages.
         * @function encodeDelimited
         * @memberof recorder_pairing.RecorderPair
         * @static
         * @param {recorder_pairing.IRecorderPair} message RecorderPair message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RecorderPair.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RecorderPair message from the specified reader or buffer.
         * @function decode
         * @memberof recorder_pairing.RecorderPair
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {recorder_pairing.RecorderPair} RecorderPair
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RecorderPair.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.recorder_pairing.RecorderPair();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.metadata = $root.recorder_state.RecorderMetadata.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.saveToUsb = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RecorderPair message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof recorder_pairing.RecorderPair
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {recorder_pairing.RecorderPair} RecorderPair
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RecorderPair.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RecorderPair message.
         * @function verify
         * @memberof recorder_pairing.RecorderPair
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RecorderPair.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.metadata != null && message.hasOwnProperty("metadata")) {
                let error = $root.recorder_state.RecorderMetadata.verify(message.metadata);
                if (error)
                    return "metadata." + error;
            }
            if (message.saveToUsb != null && message.hasOwnProperty("saveToUsb"))
                if (typeof message.saveToUsb !== "boolean")
                    return "saveToUsb: boolean expected";
            return null;
        };

        /**
         * Creates a RecorderPair message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof recorder_pairing.RecorderPair
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {recorder_pairing.RecorderPair} RecorderPair
         */
        RecorderPair.fromObject = function fromObject(object) {
            if (object instanceof $root.recorder_pairing.RecorderPair)
                return object;
            let message = new $root.recorder_pairing.RecorderPair();
            if (object.metadata != null) {
                if (typeof object.metadata !== "object")
                    throw TypeError(".recorder_pairing.RecorderPair.metadata: object expected");
                message.metadata = $root.recorder_state.RecorderMetadata.fromObject(object.metadata);
            }
            if (object.saveToUsb != null)
                message.saveToUsb = Boolean(object.saveToUsb);
            return message;
        };

        /**
         * Creates a plain object from a RecorderPair message. Also converts values to other types if specified.
         * @function toObject
         * @memberof recorder_pairing.RecorderPair
         * @static
         * @param {recorder_pairing.RecorderPair} message RecorderPair
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RecorderPair.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.metadata = null;
                object.saveToUsb = false;
            }
            if (message.metadata != null && message.hasOwnProperty("metadata"))
                object.metadata = $root.recorder_state.RecorderMetadata.toObject(message.metadata, options);
            if (message.saveToUsb != null && message.hasOwnProperty("saveToUsb"))
                object.saveToUsb = message.saveToUsb;
            return object;
        };

        /**
         * Converts this RecorderPair to JSON.
         * @function toJSON
         * @memberof recorder_pairing.RecorderPair
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RecorderPair.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RecorderPair
         * @function getTypeUrl
         * @memberof recorder_pairing.RecorderPair
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RecorderPair.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/recorder_pairing.RecorderPair";
        };

        return RecorderPair;
    })();

    return recorder_pairing;
})();

export const recorder_state = $root.recorder_state = (() => {

    /**
     * Namespace recorder_state.
     * @exports recorder_state
     * @namespace
     */
    const recorder_state = {};

    recorder_state.RecorderState = (function() {

        /**
         * Properties of a RecorderState.
         * @memberof recorder_state
         * @interface IRecorderState
         * @property {recorder_state.IRecorderPairingState|null} [pairing] RecorderState pairing
         * @property {recorder_state.IRecorderMetadata|null} [metadata] RecorderState metadata
         */

        /**
         * Constructs a new RecorderState.
         * @memberof recorder_state
         * @classdesc Represents a RecorderState.
         * @implements IRecorderState
         * @constructor
         * @param {recorder_state.IRecorderState=} [properties] Properties to set
         */
        function RecorderState(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RecorderState pairing.
         * @member {recorder_state.IRecorderPairingState|null|undefined} pairing
         * @memberof recorder_state.RecorderState
         * @instance
         */
        RecorderState.prototype.pairing = null;

        /**
         * RecorderState metadata.
         * @member {recorder_state.IRecorderMetadata|null|undefined} metadata
         * @memberof recorder_state.RecorderState
         * @instance
         */
        RecorderState.prototype.metadata = null;

        /**
         * Creates a new RecorderState instance using the specified properties.
         * @function create
         * @memberof recorder_state.RecorderState
         * @static
         * @param {recorder_state.IRecorderState=} [properties] Properties to set
         * @returns {recorder_state.RecorderState} RecorderState instance
         */
        RecorderState.create = function create(properties) {
            return new RecorderState(properties);
        };

        /**
         * Encodes the specified RecorderState message. Does not implicitly {@link recorder_state.RecorderState.verify|verify} messages.
         * @function encode
         * @memberof recorder_state.RecorderState
         * @static
         * @param {recorder_state.IRecorderState} message RecorderState message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RecorderState.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.pairing != null && Object.hasOwnProperty.call(message, "pairing"))
                $root.recorder_state.RecorderPairingState.encode(message.pairing, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                $root.recorder_state.RecorderMetadata.encode(message.metadata, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified RecorderState message, length delimited. Does not implicitly {@link recorder_state.RecorderState.verify|verify} messages.
         * @function encodeDelimited
         * @memberof recorder_state.RecorderState
         * @static
         * @param {recorder_state.IRecorderState} message RecorderState message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RecorderState.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RecorderState message from the specified reader or buffer.
         * @function decode
         * @memberof recorder_state.RecorderState
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {recorder_state.RecorderState} RecorderState
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RecorderState.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.recorder_state.RecorderState();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.pairing = $root.recorder_state.RecorderPairingState.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.metadata = $root.recorder_state.RecorderMetadata.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RecorderState message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof recorder_state.RecorderState
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {recorder_state.RecorderState} RecorderState
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RecorderState.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RecorderState message.
         * @function verify
         * @memberof recorder_state.RecorderState
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RecorderState.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.pairing != null && message.hasOwnProperty("pairing")) {
                let error = $root.recorder_state.RecorderPairingState.verify(message.pairing);
                if (error)
                    return "pairing." + error;
            }
            if (message.metadata != null && message.hasOwnProperty("metadata")) {
                let error = $root.recorder_state.RecorderMetadata.verify(message.metadata);
                if (error)
                    return "metadata." + error;
            }
            return null;
        };

        /**
         * Creates a RecorderState message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof recorder_state.RecorderState
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {recorder_state.RecorderState} RecorderState
         */
        RecorderState.fromObject = function fromObject(object) {
            if (object instanceof $root.recorder_state.RecorderState)
                return object;
            let message = new $root.recorder_state.RecorderState();
            if (object.pairing != null) {
                if (typeof object.pairing !== "object")
                    throw TypeError(".recorder_state.RecorderState.pairing: object expected");
                message.pairing = $root.recorder_state.RecorderPairingState.fromObject(object.pairing);
            }
            if (object.metadata != null) {
                if (typeof object.metadata !== "object")
                    throw TypeError(".recorder_state.RecorderState.metadata: object expected");
                message.metadata = $root.recorder_state.RecorderMetadata.fromObject(object.metadata);
            }
            return message;
        };

        /**
         * Creates a plain object from a RecorderState message. Also converts values to other types if specified.
         * @function toObject
         * @memberof recorder_state.RecorderState
         * @static
         * @param {recorder_state.RecorderState} message RecorderState
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RecorderState.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.pairing = null;
                object.metadata = null;
            }
            if (message.pairing != null && message.hasOwnProperty("pairing"))
                object.pairing = $root.recorder_state.RecorderPairingState.toObject(message.pairing, options);
            if (message.metadata != null && message.hasOwnProperty("metadata"))
                object.metadata = $root.recorder_state.RecorderMetadata.toObject(message.metadata, options);
            return object;
        };

        /**
         * Converts this RecorderState to JSON.
         * @function toJSON
         * @memberof recorder_state.RecorderState
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RecorderState.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RecorderState
         * @function getTypeUrl
         * @memberof recorder_state.RecorderState
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RecorderState.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/recorder_state.RecorderState";
        };

        return RecorderState;
    })();

    recorder_state.RecorderWebserviceState = (function() {

        /**
         * Properties of a RecorderWebserviceState.
         * @memberof recorder_state
         * @interface IRecorderWebserviceState
         * @property {boolean|null} [isConnected] RecorderWebserviceState isConnected
         */

        /**
         * Constructs a new RecorderWebserviceState.
         * @memberof recorder_state
         * @classdesc Represents a RecorderWebserviceState.
         * @implements IRecorderWebserviceState
         * @constructor
         * @param {recorder_state.IRecorderWebserviceState=} [properties] Properties to set
         */
        function RecorderWebserviceState(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RecorderWebserviceState isConnected.
         * @member {boolean} isConnected
         * @memberof recorder_state.RecorderWebserviceState
         * @instance
         */
        RecorderWebserviceState.prototype.isConnected = false;

        /**
         * Creates a new RecorderWebserviceState instance using the specified properties.
         * @function create
         * @memberof recorder_state.RecorderWebserviceState
         * @static
         * @param {recorder_state.IRecorderWebserviceState=} [properties] Properties to set
         * @returns {recorder_state.RecorderWebserviceState} RecorderWebserviceState instance
         */
        RecorderWebserviceState.create = function create(properties) {
            return new RecorderWebserviceState(properties);
        };

        /**
         * Encodes the specified RecorderWebserviceState message. Does not implicitly {@link recorder_state.RecorderWebserviceState.verify|verify} messages.
         * @function encode
         * @memberof recorder_state.RecorderWebserviceState
         * @static
         * @param {recorder_state.IRecorderWebserviceState} message RecorderWebserviceState message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RecorderWebserviceState.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.isConnected != null && Object.hasOwnProperty.call(message, "isConnected"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.isConnected);
            return writer;
        };

        /**
         * Encodes the specified RecorderWebserviceState message, length delimited. Does not implicitly {@link recorder_state.RecorderWebserviceState.verify|verify} messages.
         * @function encodeDelimited
         * @memberof recorder_state.RecorderWebserviceState
         * @static
         * @param {recorder_state.IRecorderWebserviceState} message RecorderWebserviceState message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RecorderWebserviceState.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RecorderWebserviceState message from the specified reader or buffer.
         * @function decode
         * @memberof recorder_state.RecorderWebserviceState
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {recorder_state.RecorderWebserviceState} RecorderWebserviceState
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RecorderWebserviceState.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.recorder_state.RecorderWebserviceState();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.isConnected = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RecorderWebserviceState message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof recorder_state.RecorderWebserviceState
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {recorder_state.RecorderWebserviceState} RecorderWebserviceState
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RecorderWebserviceState.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RecorderWebserviceState message.
         * @function verify
         * @memberof recorder_state.RecorderWebserviceState
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RecorderWebserviceState.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.isConnected != null && message.hasOwnProperty("isConnected"))
                if (typeof message.isConnected !== "boolean")
                    return "isConnected: boolean expected";
            return null;
        };

        /**
         * Creates a RecorderWebserviceState message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof recorder_state.RecorderWebserviceState
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {recorder_state.RecorderWebserviceState} RecorderWebserviceState
         */
        RecorderWebserviceState.fromObject = function fromObject(object) {
            if (object instanceof $root.recorder_state.RecorderWebserviceState)
                return object;
            let message = new $root.recorder_state.RecorderWebserviceState();
            if (object.isConnected != null)
                message.isConnected = Boolean(object.isConnected);
            return message;
        };

        /**
         * Creates a plain object from a RecorderWebserviceState message. Also converts values to other types if specified.
         * @function toObject
         * @memberof recorder_state.RecorderWebserviceState
         * @static
         * @param {recorder_state.RecorderWebserviceState} message RecorderWebserviceState
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RecorderWebserviceState.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.isConnected = false;
            if (message.isConnected != null && message.hasOwnProperty("isConnected"))
                object.isConnected = message.isConnected;
            return object;
        };

        /**
         * Converts this RecorderWebserviceState to JSON.
         * @function toJSON
         * @memberof recorder_state.RecorderWebserviceState
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RecorderWebserviceState.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RecorderWebserviceState
         * @function getTypeUrl
         * @memberof recorder_state.RecorderWebserviceState
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RecorderWebserviceState.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/recorder_state.RecorderWebserviceState";
        };

        return RecorderWebserviceState;
    })();

    recorder_state.RecorderPairingState = (function() {

        /**
         * Properties of a RecorderPairingState.
         * @memberof recorder_state
         * @interface IRecorderPairingState
         * @property {boolean|null} [isPaired] RecorderPairingState isPaired
         * @property {boolean|null} [hasPairingCertificate] RecorderPairingState hasPairingCertificate
         * @property {boolean|null} [csrGenerated] RecorderPairingState csrGenerated
         */

        /**
         * Constructs a new RecorderPairingState.
         * @memberof recorder_state
         * @classdesc Represents a RecorderPairingState.
         * @implements IRecorderPairingState
         * @constructor
         * @param {recorder_state.IRecorderPairingState=} [properties] Properties to set
         */
        function RecorderPairingState(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RecorderPairingState isPaired.
         * @member {boolean} isPaired
         * @memberof recorder_state.RecorderPairingState
         * @instance
         */
        RecorderPairingState.prototype.isPaired = false;

        /**
         * RecorderPairingState hasPairingCertificate.
         * @member {boolean} hasPairingCertificate
         * @memberof recorder_state.RecorderPairingState
         * @instance
         */
        RecorderPairingState.prototype.hasPairingCertificate = false;

        /**
         * RecorderPairingState csrGenerated.
         * @member {boolean} csrGenerated
         * @memberof recorder_state.RecorderPairingState
         * @instance
         */
        RecorderPairingState.prototype.csrGenerated = false;

        /**
         * Creates a new RecorderPairingState instance using the specified properties.
         * @function create
         * @memberof recorder_state.RecorderPairingState
         * @static
         * @param {recorder_state.IRecorderPairingState=} [properties] Properties to set
         * @returns {recorder_state.RecorderPairingState} RecorderPairingState instance
         */
        RecorderPairingState.create = function create(properties) {
            return new RecorderPairingState(properties);
        };

        /**
         * Encodes the specified RecorderPairingState message. Does not implicitly {@link recorder_state.RecorderPairingState.verify|verify} messages.
         * @function encode
         * @memberof recorder_state.RecorderPairingState
         * @static
         * @param {recorder_state.IRecorderPairingState} message RecorderPairingState message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RecorderPairingState.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.isPaired != null && Object.hasOwnProperty.call(message, "isPaired"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.isPaired);
            if (message.csrGenerated != null && Object.hasOwnProperty.call(message, "csrGenerated"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.csrGenerated);
            if (message.hasPairingCertificate != null && Object.hasOwnProperty.call(message, "hasPairingCertificate"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.hasPairingCertificate);
            return writer;
        };

        /**
         * Encodes the specified RecorderPairingState message, length delimited. Does not implicitly {@link recorder_state.RecorderPairingState.verify|verify} messages.
         * @function encodeDelimited
         * @memberof recorder_state.RecorderPairingState
         * @static
         * @param {recorder_state.IRecorderPairingState} message RecorderPairingState message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RecorderPairingState.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RecorderPairingState message from the specified reader or buffer.
         * @function decode
         * @memberof recorder_state.RecorderPairingState
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {recorder_state.RecorderPairingState} RecorderPairingState
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RecorderPairingState.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.recorder_state.RecorderPairingState();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.isPaired = reader.bool();
                        break;
                    }
                case 3: {
                        message.hasPairingCertificate = reader.bool();
                        break;
                    }
                case 2: {
                        message.csrGenerated = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RecorderPairingState message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof recorder_state.RecorderPairingState
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {recorder_state.RecorderPairingState} RecorderPairingState
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RecorderPairingState.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RecorderPairingState message.
         * @function verify
         * @memberof recorder_state.RecorderPairingState
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RecorderPairingState.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.isPaired != null && message.hasOwnProperty("isPaired"))
                if (typeof message.isPaired !== "boolean")
                    return "isPaired: boolean expected";
            if (message.hasPairingCertificate != null && message.hasOwnProperty("hasPairingCertificate"))
                if (typeof message.hasPairingCertificate !== "boolean")
                    return "hasPairingCertificate: boolean expected";
            if (message.csrGenerated != null && message.hasOwnProperty("csrGenerated"))
                if (typeof message.csrGenerated !== "boolean")
                    return "csrGenerated: boolean expected";
            return null;
        };

        /**
         * Creates a RecorderPairingState message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof recorder_state.RecorderPairingState
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {recorder_state.RecorderPairingState} RecorderPairingState
         */
        RecorderPairingState.fromObject = function fromObject(object) {
            if (object instanceof $root.recorder_state.RecorderPairingState)
                return object;
            let message = new $root.recorder_state.RecorderPairingState();
            if (object.isPaired != null)
                message.isPaired = Boolean(object.isPaired);
            if (object.hasPairingCertificate != null)
                message.hasPairingCertificate = Boolean(object.hasPairingCertificate);
            if (object.csrGenerated != null)
                message.csrGenerated = Boolean(object.csrGenerated);
            return message;
        };

        /**
         * Creates a plain object from a RecorderPairingState message. Also converts values to other types if specified.
         * @function toObject
         * @memberof recorder_state.RecorderPairingState
         * @static
         * @param {recorder_state.RecorderPairingState} message RecorderPairingState
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RecorderPairingState.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.isPaired = false;
                object.csrGenerated = false;
                object.hasPairingCertificate = false;
            }
            if (message.isPaired != null && message.hasOwnProperty("isPaired"))
                object.isPaired = message.isPaired;
            if (message.csrGenerated != null && message.hasOwnProperty("csrGenerated"))
                object.csrGenerated = message.csrGenerated;
            if (message.hasPairingCertificate != null && message.hasOwnProperty("hasPairingCertificate"))
                object.hasPairingCertificate = message.hasPairingCertificate;
            return object;
        };

        /**
         * Converts this RecorderPairingState to JSON.
         * @function toJSON
         * @memberof recorder_state.RecorderPairingState
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RecorderPairingState.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RecorderPairingState
         * @function getTypeUrl
         * @memberof recorder_state.RecorderPairingState
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RecorderPairingState.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/recorder_state.RecorderPairingState";
        };

        return RecorderPairingState;
    })();

    recorder_state.RecorderMetadata = (function() {

        /**
         * Properties of a RecorderMetadata.
         * @memberof recorder_state
         * @interface IRecorderMetadata
         * @property {string|null} [court] RecorderMetadata court
         * @property {string|null} [courtroom] RecorderMetadata courtroom
         */

        /**
         * Constructs a new RecorderMetadata.
         * @memberof recorder_state
         * @classdesc Represents a RecorderMetadata.
         * @implements IRecorderMetadata
         * @constructor
         * @param {recorder_state.IRecorderMetadata=} [properties] Properties to set
         */
        function RecorderMetadata(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RecorderMetadata court.
         * @member {string} court
         * @memberof recorder_state.RecorderMetadata
         * @instance
         */
        RecorderMetadata.prototype.court = "";

        /**
         * RecorderMetadata courtroom.
         * @member {string} courtroom
         * @memberof recorder_state.RecorderMetadata
         * @instance
         */
        RecorderMetadata.prototype.courtroom = "";

        /**
         * Creates a new RecorderMetadata instance using the specified properties.
         * @function create
         * @memberof recorder_state.RecorderMetadata
         * @static
         * @param {recorder_state.IRecorderMetadata=} [properties] Properties to set
         * @returns {recorder_state.RecorderMetadata} RecorderMetadata instance
         */
        RecorderMetadata.create = function create(properties) {
            return new RecorderMetadata(properties);
        };

        /**
         * Encodes the specified RecorderMetadata message. Does not implicitly {@link recorder_state.RecorderMetadata.verify|verify} messages.
         * @function encode
         * @memberof recorder_state.RecorderMetadata
         * @static
         * @param {recorder_state.IRecorderMetadata} message RecorderMetadata message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RecorderMetadata.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.court != null && Object.hasOwnProperty.call(message, "court"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.court);
            if (message.courtroom != null && Object.hasOwnProperty.call(message, "courtroom"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.courtroom);
            return writer;
        };

        /**
         * Encodes the specified RecorderMetadata message, length delimited. Does not implicitly {@link recorder_state.RecorderMetadata.verify|verify} messages.
         * @function encodeDelimited
         * @memberof recorder_state.RecorderMetadata
         * @static
         * @param {recorder_state.IRecorderMetadata} message RecorderMetadata message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RecorderMetadata.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RecorderMetadata message from the specified reader or buffer.
         * @function decode
         * @memberof recorder_state.RecorderMetadata
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {recorder_state.RecorderMetadata} RecorderMetadata
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RecorderMetadata.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.recorder_state.RecorderMetadata();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.court = reader.string();
                        break;
                    }
                case 3: {
                        message.courtroom = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RecorderMetadata message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof recorder_state.RecorderMetadata
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {recorder_state.RecorderMetadata} RecorderMetadata
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RecorderMetadata.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RecorderMetadata message.
         * @function verify
         * @memberof recorder_state.RecorderMetadata
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RecorderMetadata.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.court != null && message.hasOwnProperty("court"))
                if (!$util.isString(message.court))
                    return "court: string expected";
            if (message.courtroom != null && message.hasOwnProperty("courtroom"))
                if (!$util.isString(message.courtroom))
                    return "courtroom: string expected";
            return null;
        };

        /**
         * Creates a RecorderMetadata message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof recorder_state.RecorderMetadata
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {recorder_state.RecorderMetadata} RecorderMetadata
         */
        RecorderMetadata.fromObject = function fromObject(object) {
            if (object instanceof $root.recorder_state.RecorderMetadata)
                return object;
            let message = new $root.recorder_state.RecorderMetadata();
            if (object.court != null)
                message.court = String(object.court);
            if (object.courtroom != null)
                message.courtroom = String(object.courtroom);
            return message;
        };

        /**
         * Creates a plain object from a RecorderMetadata message. Also converts values to other types if specified.
         * @function toObject
         * @memberof recorder_state.RecorderMetadata
         * @static
         * @param {recorder_state.RecorderMetadata} message RecorderMetadata
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RecorderMetadata.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.court = "";
                object.courtroom = "";
            }
            if (message.court != null && message.hasOwnProperty("court"))
                object.court = message.court;
            if (message.courtroom != null && message.hasOwnProperty("courtroom"))
                object.courtroom = message.courtroom;
            return object;
        };

        /**
         * Converts this RecorderMetadata to JSON.
         * @function toJSON
         * @memberof recorder_state.RecorderMetadata
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RecorderMetadata.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RecorderMetadata
         * @function getTypeUrl
         * @memberof recorder_state.RecorderMetadata
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RecorderMetadata.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/recorder_state.RecorderMetadata";
        };

        return RecorderMetadata;
    })();

    recorder_state.RecorderRecordingState = (function() {

        /**
         * Properties of a RecorderRecordingState.
         * @memberof recorder_state
         * @interface IRecorderRecordingState
         * @property {boolean|null} [isRecording] RecorderRecordingState isRecording
         * @property {number|Long|null} [changeTimestamp] RecorderRecordingState changeTimestamp
         * @property {recorder_state.IAsodRecorderUploadState|null} [uploadState] RecorderRecordingState uploadState
         * @property {string|null} [recordingBlockId] RecorderRecordingState recordingBlockId
         * @property {string|null} [recordingChunkId] RecorderRecordingState recordingChunkId
         */

        /**
         * Constructs a new RecorderRecordingState.
         * @memberof recorder_state
         * @classdesc Represents a RecorderRecordingState.
         * @implements IRecorderRecordingState
         * @constructor
         * @param {recorder_state.IRecorderRecordingState=} [properties] Properties to set
         */
        function RecorderRecordingState(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RecorderRecordingState isRecording.
         * @member {boolean} isRecording
         * @memberof recorder_state.RecorderRecordingState
         * @instance
         */
        RecorderRecordingState.prototype.isRecording = false;

        /**
         * RecorderRecordingState changeTimestamp.
         * @member {number|Long} changeTimestamp
         * @memberof recorder_state.RecorderRecordingState
         * @instance
         */
        RecorderRecordingState.prototype.changeTimestamp = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * RecorderRecordingState uploadState.
         * @member {recorder_state.IAsodRecorderUploadState|null|undefined} uploadState
         * @memberof recorder_state.RecorderRecordingState
         * @instance
         */
        RecorderRecordingState.prototype.uploadState = null;

        /**
         * RecorderRecordingState recordingBlockId.
         * @member {string} recordingBlockId
         * @memberof recorder_state.RecorderRecordingState
         * @instance
         */
        RecorderRecordingState.prototype.recordingBlockId = "";

        /**
         * RecorderRecordingState recordingChunkId.
         * @member {string} recordingChunkId
         * @memberof recorder_state.RecorderRecordingState
         * @instance
         */
        RecorderRecordingState.prototype.recordingChunkId = "";

        /**
         * Creates a new RecorderRecordingState instance using the specified properties.
         * @function create
         * @memberof recorder_state.RecorderRecordingState
         * @static
         * @param {recorder_state.IRecorderRecordingState=} [properties] Properties to set
         * @returns {recorder_state.RecorderRecordingState} RecorderRecordingState instance
         */
        RecorderRecordingState.create = function create(properties) {
            return new RecorderRecordingState(properties);
        };

        /**
         * Encodes the specified RecorderRecordingState message. Does not implicitly {@link recorder_state.RecorderRecordingState.verify|verify} messages.
         * @function encode
         * @memberof recorder_state.RecorderRecordingState
         * @static
         * @param {recorder_state.IRecorderRecordingState} message RecorderRecordingState message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RecorderRecordingState.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.isRecording != null && Object.hasOwnProperty.call(message, "isRecording"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.isRecording);
            if (message.changeTimestamp != null && Object.hasOwnProperty.call(message, "changeTimestamp"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.changeTimestamp);
            if (message.uploadState != null && Object.hasOwnProperty.call(message, "uploadState"))
                $root.recorder_state.AsodRecorderUploadState.encode(message.uploadState, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.recordingBlockId != null && Object.hasOwnProperty.call(message, "recordingBlockId"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.recordingBlockId);
            if (message.recordingChunkId != null && Object.hasOwnProperty.call(message, "recordingChunkId"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.recordingChunkId);
            return writer;
        };

        /**
         * Encodes the specified RecorderRecordingState message, length delimited. Does not implicitly {@link recorder_state.RecorderRecordingState.verify|verify} messages.
         * @function encodeDelimited
         * @memberof recorder_state.RecorderRecordingState
         * @static
         * @param {recorder_state.IRecorderRecordingState} message RecorderRecordingState message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RecorderRecordingState.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RecorderRecordingState message from the specified reader or buffer.
         * @function decode
         * @memberof recorder_state.RecorderRecordingState
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {recorder_state.RecorderRecordingState} RecorderRecordingState
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RecorderRecordingState.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.recorder_state.RecorderRecordingState();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.isRecording = reader.bool();
                        break;
                    }
                case 2: {
                        message.changeTimestamp = reader.uint64();
                        break;
                    }
                case 4: {
                        message.uploadState = $root.recorder_state.AsodRecorderUploadState.decode(reader, reader.uint32());
                        break;
                    }
                case 5: {
                        message.recordingBlockId = reader.string();
                        break;
                    }
                case 6: {
                        message.recordingChunkId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RecorderRecordingState message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof recorder_state.RecorderRecordingState
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {recorder_state.RecorderRecordingState} RecorderRecordingState
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RecorderRecordingState.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RecorderRecordingState message.
         * @function verify
         * @memberof recorder_state.RecorderRecordingState
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RecorderRecordingState.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.isRecording != null && message.hasOwnProperty("isRecording"))
                if (typeof message.isRecording !== "boolean")
                    return "isRecording: boolean expected";
            if (message.changeTimestamp != null && message.hasOwnProperty("changeTimestamp"))
                if (!$util.isInteger(message.changeTimestamp) && !(message.changeTimestamp && $util.isInteger(message.changeTimestamp.low) && $util.isInteger(message.changeTimestamp.high)))
                    return "changeTimestamp: integer|Long expected";
            if (message.uploadState != null && message.hasOwnProperty("uploadState")) {
                let error = $root.recorder_state.AsodRecorderUploadState.verify(message.uploadState);
                if (error)
                    return "uploadState." + error;
            }
            if (message.recordingBlockId != null && message.hasOwnProperty("recordingBlockId"))
                if (!$util.isString(message.recordingBlockId))
                    return "recordingBlockId: string expected";
            if (message.recordingChunkId != null && message.hasOwnProperty("recordingChunkId"))
                if (!$util.isString(message.recordingChunkId))
                    return "recordingChunkId: string expected";
            return null;
        };

        /**
         * Creates a RecorderRecordingState message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof recorder_state.RecorderRecordingState
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {recorder_state.RecorderRecordingState} RecorderRecordingState
         */
        RecorderRecordingState.fromObject = function fromObject(object) {
            if (object instanceof $root.recorder_state.RecorderRecordingState)
                return object;
            let message = new $root.recorder_state.RecorderRecordingState();
            if (object.isRecording != null)
                message.isRecording = Boolean(object.isRecording);
            if (object.changeTimestamp != null)
                if ($util.Long)
                    (message.changeTimestamp = $util.Long.fromValue(object.changeTimestamp)).unsigned = true;
                else if (typeof object.changeTimestamp === "string")
                    message.changeTimestamp = parseInt(object.changeTimestamp, 10);
                else if (typeof object.changeTimestamp === "number")
                    message.changeTimestamp = object.changeTimestamp;
                else if (typeof object.changeTimestamp === "object")
                    message.changeTimestamp = new $util.LongBits(object.changeTimestamp.low >>> 0, object.changeTimestamp.high >>> 0).toNumber(true);
            if (object.uploadState != null) {
                if (typeof object.uploadState !== "object")
                    throw TypeError(".recorder_state.RecorderRecordingState.uploadState: object expected");
                message.uploadState = $root.recorder_state.AsodRecorderUploadState.fromObject(object.uploadState);
            }
            if (object.recordingBlockId != null)
                message.recordingBlockId = String(object.recordingBlockId);
            if (object.recordingChunkId != null)
                message.recordingChunkId = String(object.recordingChunkId);
            return message;
        };

        /**
         * Creates a plain object from a RecorderRecordingState message. Also converts values to other types if specified.
         * @function toObject
         * @memberof recorder_state.RecorderRecordingState
         * @static
         * @param {recorder_state.RecorderRecordingState} message RecorderRecordingState
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RecorderRecordingState.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.isRecording = false;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.changeTimestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.changeTimestamp = options.longs === String ? "0" : 0;
                object.uploadState = null;
                object.recordingBlockId = "";
                object.recordingChunkId = "";
            }
            if (message.isRecording != null && message.hasOwnProperty("isRecording"))
                object.isRecording = message.isRecording;
            if (message.changeTimestamp != null && message.hasOwnProperty("changeTimestamp"))
                if (typeof message.changeTimestamp === "number")
                    object.changeTimestamp = options.longs === String ? String(message.changeTimestamp) : message.changeTimestamp;
                else
                    object.changeTimestamp = options.longs === String ? $util.Long.prototype.toString.call(message.changeTimestamp) : options.longs === Number ? new $util.LongBits(message.changeTimestamp.low >>> 0, message.changeTimestamp.high >>> 0).toNumber(true) : message.changeTimestamp;
            if (message.uploadState != null && message.hasOwnProperty("uploadState"))
                object.uploadState = $root.recorder_state.AsodRecorderUploadState.toObject(message.uploadState, options);
            if (message.recordingBlockId != null && message.hasOwnProperty("recordingBlockId"))
                object.recordingBlockId = message.recordingBlockId;
            if (message.recordingChunkId != null && message.hasOwnProperty("recordingChunkId"))
                object.recordingChunkId = message.recordingChunkId;
            return object;
        };

        /**
         * Converts this RecorderRecordingState to JSON.
         * @function toJSON
         * @memberof recorder_state.RecorderRecordingState
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RecorderRecordingState.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RecorderRecordingState
         * @function getTypeUrl
         * @memberof recorder_state.RecorderRecordingState
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RecorderRecordingState.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/recorder_state.RecorderRecordingState";
        };

        return RecorderRecordingState;
    })();

    recorder_state.AsodRecorderUploadState = (function() {

        /**
         * Properties of an AsodRecorderUploadState.
         * @memberof recorder_state
         * @interface IAsodRecorderUploadState
         * @property {boolean|null} [isUploading] AsodRecorderUploadState isUploading
         * @property {string|null} [error] AsodRecorderUploadState error
         * @property {number|null} [total] AsodRecorderUploadState total
         * @property {number|null} [uploaded] AsodRecorderUploadState uploaded
         */

        /**
         * Constructs a new AsodRecorderUploadState.
         * @memberof recorder_state
         * @classdesc Represents an AsodRecorderUploadState.
         * @implements IAsodRecorderUploadState
         * @constructor
         * @param {recorder_state.IAsodRecorderUploadState=} [properties] Properties to set
         */
        function AsodRecorderUploadState(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AsodRecorderUploadState isUploading.
         * @member {boolean} isUploading
         * @memberof recorder_state.AsodRecorderUploadState
         * @instance
         */
        AsodRecorderUploadState.prototype.isUploading = false;

        /**
         * AsodRecorderUploadState error.
         * @member {string} error
         * @memberof recorder_state.AsodRecorderUploadState
         * @instance
         */
        AsodRecorderUploadState.prototype.error = "";

        /**
         * AsodRecorderUploadState total.
         * @member {number} total
         * @memberof recorder_state.AsodRecorderUploadState
         * @instance
         */
        AsodRecorderUploadState.prototype.total = 0;

        /**
         * AsodRecorderUploadState uploaded.
         * @member {number} uploaded
         * @memberof recorder_state.AsodRecorderUploadState
         * @instance
         */
        AsodRecorderUploadState.prototype.uploaded = 0;

        /**
         * Creates a new AsodRecorderUploadState instance using the specified properties.
         * @function create
         * @memberof recorder_state.AsodRecorderUploadState
         * @static
         * @param {recorder_state.IAsodRecorderUploadState=} [properties] Properties to set
         * @returns {recorder_state.AsodRecorderUploadState} AsodRecorderUploadState instance
         */
        AsodRecorderUploadState.create = function create(properties) {
            return new AsodRecorderUploadState(properties);
        };

        /**
         * Encodes the specified AsodRecorderUploadState message. Does not implicitly {@link recorder_state.AsodRecorderUploadState.verify|verify} messages.
         * @function encode
         * @memberof recorder_state.AsodRecorderUploadState
         * @static
         * @param {recorder_state.IAsodRecorderUploadState} message AsodRecorderUploadState message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodRecorderUploadState.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.isUploading != null && Object.hasOwnProperty.call(message, "isUploading"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.isUploading);
            if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.error);
            if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.total);
            if (message.uploaded != null && Object.hasOwnProperty.call(message, "uploaded"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.uploaded);
            return writer;
        };

        /**
         * Encodes the specified AsodRecorderUploadState message, length delimited. Does not implicitly {@link recorder_state.AsodRecorderUploadState.verify|verify} messages.
         * @function encodeDelimited
         * @memberof recorder_state.AsodRecorderUploadState
         * @static
         * @param {recorder_state.IAsodRecorderUploadState} message AsodRecorderUploadState message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodRecorderUploadState.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AsodRecorderUploadState message from the specified reader or buffer.
         * @function decode
         * @memberof recorder_state.AsodRecorderUploadState
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {recorder_state.AsodRecorderUploadState} AsodRecorderUploadState
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodRecorderUploadState.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.recorder_state.AsodRecorderUploadState();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.isUploading = reader.bool();
                        break;
                    }
                case 2: {
                        message.error = reader.string();
                        break;
                    }
                case 3: {
                        message.total = reader.int32();
                        break;
                    }
                case 4: {
                        message.uploaded = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AsodRecorderUploadState message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof recorder_state.AsodRecorderUploadState
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {recorder_state.AsodRecorderUploadState} AsodRecorderUploadState
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodRecorderUploadState.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AsodRecorderUploadState message.
         * @function verify
         * @memberof recorder_state.AsodRecorderUploadState
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AsodRecorderUploadState.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.isUploading != null && message.hasOwnProperty("isUploading"))
                if (typeof message.isUploading !== "boolean")
                    return "isUploading: boolean expected";
            if (message.error != null && message.hasOwnProperty("error"))
                if (!$util.isString(message.error))
                    return "error: string expected";
            if (message.total != null && message.hasOwnProperty("total"))
                if (!$util.isInteger(message.total))
                    return "total: integer expected";
            if (message.uploaded != null && message.hasOwnProperty("uploaded"))
                if (!$util.isInteger(message.uploaded))
                    return "uploaded: integer expected";
            return null;
        };

        /**
         * Creates an AsodRecorderUploadState message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof recorder_state.AsodRecorderUploadState
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {recorder_state.AsodRecorderUploadState} AsodRecorderUploadState
         */
        AsodRecorderUploadState.fromObject = function fromObject(object) {
            if (object instanceof $root.recorder_state.AsodRecorderUploadState)
                return object;
            let message = new $root.recorder_state.AsodRecorderUploadState();
            if (object.isUploading != null)
                message.isUploading = Boolean(object.isUploading);
            if (object.error != null)
                message.error = String(object.error);
            if (object.total != null)
                message.total = object.total | 0;
            if (object.uploaded != null)
                message.uploaded = object.uploaded | 0;
            return message;
        };

        /**
         * Creates a plain object from an AsodRecorderUploadState message. Also converts values to other types if specified.
         * @function toObject
         * @memberof recorder_state.AsodRecorderUploadState
         * @static
         * @param {recorder_state.AsodRecorderUploadState} message AsodRecorderUploadState
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AsodRecorderUploadState.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.isUploading = false;
                object.error = "";
                object.total = 0;
                object.uploaded = 0;
            }
            if (message.isUploading != null && message.hasOwnProperty("isUploading"))
                object.isUploading = message.isUploading;
            if (message.error != null && message.hasOwnProperty("error"))
                object.error = message.error;
            if (message.total != null && message.hasOwnProperty("total"))
                object.total = message.total;
            if (message.uploaded != null && message.hasOwnProperty("uploaded"))
                object.uploaded = message.uploaded;
            return object;
        };

        /**
         * Converts this AsodRecorderUploadState to JSON.
         * @function toJSON
         * @memberof recorder_state.AsodRecorderUploadState
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AsodRecorderUploadState.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AsodRecorderUploadState
         * @function getTypeUrl
         * @memberof recorder_state.AsodRecorderUploadState
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AsodRecorderUploadState.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/recorder_state.AsodRecorderUploadState";
        };

        return AsodRecorderUploadState;
    })();

    recorder_state.RecorderProjectUploadState = (function() {

        /**
         * Properties of a RecorderProjectUploadState.
         * @memberof recorder_state
         * @interface IRecorderProjectUploadState
         * @property {number|null} [remainingActions] RecorderProjectUploadState remainingActions
         * @property {number|null} [totalActions] RecorderProjectUploadState totalActions
         */

        /**
         * Constructs a new RecorderProjectUploadState.
         * @memberof recorder_state
         * @classdesc Represents a RecorderProjectUploadState.
         * @implements IRecorderProjectUploadState
         * @constructor
         * @param {recorder_state.IRecorderProjectUploadState=} [properties] Properties to set
         */
        function RecorderProjectUploadState(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RecorderProjectUploadState remainingActions.
         * @member {number} remainingActions
         * @memberof recorder_state.RecorderProjectUploadState
         * @instance
         */
        RecorderProjectUploadState.prototype.remainingActions = 0;

        /**
         * RecorderProjectUploadState totalActions.
         * @member {number} totalActions
         * @memberof recorder_state.RecorderProjectUploadState
         * @instance
         */
        RecorderProjectUploadState.prototype.totalActions = 0;

        /**
         * Creates a new RecorderProjectUploadState instance using the specified properties.
         * @function create
         * @memberof recorder_state.RecorderProjectUploadState
         * @static
         * @param {recorder_state.IRecorderProjectUploadState=} [properties] Properties to set
         * @returns {recorder_state.RecorderProjectUploadState} RecorderProjectUploadState instance
         */
        RecorderProjectUploadState.create = function create(properties) {
            return new RecorderProjectUploadState(properties);
        };

        /**
         * Encodes the specified RecorderProjectUploadState message. Does not implicitly {@link recorder_state.RecorderProjectUploadState.verify|verify} messages.
         * @function encode
         * @memberof recorder_state.RecorderProjectUploadState
         * @static
         * @param {recorder_state.IRecorderProjectUploadState} message RecorderProjectUploadState message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RecorderProjectUploadState.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.remainingActions != null && Object.hasOwnProperty.call(message, "remainingActions"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.remainingActions);
            if (message.totalActions != null && Object.hasOwnProperty.call(message, "totalActions"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.totalActions);
            return writer;
        };

        /**
         * Encodes the specified RecorderProjectUploadState message, length delimited. Does not implicitly {@link recorder_state.RecorderProjectUploadState.verify|verify} messages.
         * @function encodeDelimited
         * @memberof recorder_state.RecorderProjectUploadState
         * @static
         * @param {recorder_state.IRecorderProjectUploadState} message RecorderProjectUploadState message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RecorderProjectUploadState.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RecorderProjectUploadState message from the specified reader or buffer.
         * @function decode
         * @memberof recorder_state.RecorderProjectUploadState
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {recorder_state.RecorderProjectUploadState} RecorderProjectUploadState
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RecorderProjectUploadState.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.recorder_state.RecorderProjectUploadState();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.remainingActions = reader.int32();
                        break;
                    }
                case 2: {
                        message.totalActions = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RecorderProjectUploadState message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof recorder_state.RecorderProjectUploadState
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {recorder_state.RecorderProjectUploadState} RecorderProjectUploadState
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RecorderProjectUploadState.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RecorderProjectUploadState message.
         * @function verify
         * @memberof recorder_state.RecorderProjectUploadState
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RecorderProjectUploadState.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.remainingActions != null && message.hasOwnProperty("remainingActions"))
                if (!$util.isInteger(message.remainingActions))
                    return "remainingActions: integer expected";
            if (message.totalActions != null && message.hasOwnProperty("totalActions"))
                if (!$util.isInteger(message.totalActions))
                    return "totalActions: integer expected";
            return null;
        };

        /**
         * Creates a RecorderProjectUploadState message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof recorder_state.RecorderProjectUploadState
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {recorder_state.RecorderProjectUploadState} RecorderProjectUploadState
         */
        RecorderProjectUploadState.fromObject = function fromObject(object) {
            if (object instanceof $root.recorder_state.RecorderProjectUploadState)
                return object;
            let message = new $root.recorder_state.RecorderProjectUploadState();
            if (object.remainingActions != null)
                message.remainingActions = object.remainingActions | 0;
            if (object.totalActions != null)
                message.totalActions = object.totalActions | 0;
            return message;
        };

        /**
         * Creates a plain object from a RecorderProjectUploadState message. Also converts values to other types if specified.
         * @function toObject
         * @memberof recorder_state.RecorderProjectUploadState
         * @static
         * @param {recorder_state.RecorderProjectUploadState} message RecorderProjectUploadState
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RecorderProjectUploadState.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.remainingActions = 0;
                object.totalActions = 0;
            }
            if (message.remainingActions != null && message.hasOwnProperty("remainingActions"))
                object.remainingActions = message.remainingActions;
            if (message.totalActions != null && message.hasOwnProperty("totalActions"))
                object.totalActions = message.totalActions;
            return object;
        };

        /**
         * Converts this RecorderProjectUploadState to JSON.
         * @function toJSON
         * @memberof recorder_state.RecorderProjectUploadState
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RecorderProjectUploadState.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RecorderProjectUploadState
         * @function getTypeUrl
         * @memberof recorder_state.RecorderProjectUploadState
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RecorderProjectUploadState.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/recorder_state.RecorderProjectUploadState";
        };

        return RecorderProjectUploadState;
    })();

    recorder_state.RecorderProjectUploadErrors = (function() {

        /**
         * Properties of a RecorderProjectUploadErrors.
         * @memberof recorder_state
         * @interface IRecorderProjectUploadErrors
         * @property {Array.<recorder_state.IRecorderUploadError>|null} [errors] RecorderProjectUploadErrors errors
         */

        /**
         * Constructs a new RecorderProjectUploadErrors.
         * @memberof recorder_state
         * @classdesc Represents a RecorderProjectUploadErrors.
         * @implements IRecorderProjectUploadErrors
         * @constructor
         * @param {recorder_state.IRecorderProjectUploadErrors=} [properties] Properties to set
         */
        function RecorderProjectUploadErrors(properties) {
            this.errors = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RecorderProjectUploadErrors errors.
         * @member {Array.<recorder_state.IRecorderUploadError>} errors
         * @memberof recorder_state.RecorderProjectUploadErrors
         * @instance
         */
        RecorderProjectUploadErrors.prototype.errors = $util.emptyArray;

        /**
         * Creates a new RecorderProjectUploadErrors instance using the specified properties.
         * @function create
         * @memberof recorder_state.RecorderProjectUploadErrors
         * @static
         * @param {recorder_state.IRecorderProjectUploadErrors=} [properties] Properties to set
         * @returns {recorder_state.RecorderProjectUploadErrors} RecorderProjectUploadErrors instance
         */
        RecorderProjectUploadErrors.create = function create(properties) {
            return new RecorderProjectUploadErrors(properties);
        };

        /**
         * Encodes the specified RecorderProjectUploadErrors message. Does not implicitly {@link recorder_state.RecorderProjectUploadErrors.verify|verify} messages.
         * @function encode
         * @memberof recorder_state.RecorderProjectUploadErrors
         * @static
         * @param {recorder_state.IRecorderProjectUploadErrors} message RecorderProjectUploadErrors message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RecorderProjectUploadErrors.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.errors != null && message.errors.length)
                for (let i = 0; i < message.errors.length; ++i)
                    $root.recorder_state.RecorderUploadError.encode(message.errors[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified RecorderProjectUploadErrors message, length delimited. Does not implicitly {@link recorder_state.RecorderProjectUploadErrors.verify|verify} messages.
         * @function encodeDelimited
         * @memberof recorder_state.RecorderProjectUploadErrors
         * @static
         * @param {recorder_state.IRecorderProjectUploadErrors} message RecorderProjectUploadErrors message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RecorderProjectUploadErrors.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RecorderProjectUploadErrors message from the specified reader or buffer.
         * @function decode
         * @memberof recorder_state.RecorderProjectUploadErrors
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {recorder_state.RecorderProjectUploadErrors} RecorderProjectUploadErrors
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RecorderProjectUploadErrors.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.recorder_state.RecorderProjectUploadErrors();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.errors && message.errors.length))
                            message.errors = [];
                        message.errors.push($root.recorder_state.RecorderUploadError.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RecorderProjectUploadErrors message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof recorder_state.RecorderProjectUploadErrors
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {recorder_state.RecorderProjectUploadErrors} RecorderProjectUploadErrors
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RecorderProjectUploadErrors.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RecorderProjectUploadErrors message.
         * @function verify
         * @memberof recorder_state.RecorderProjectUploadErrors
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RecorderProjectUploadErrors.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.errors != null && message.hasOwnProperty("errors")) {
                if (!Array.isArray(message.errors))
                    return "errors: array expected";
                for (let i = 0; i < message.errors.length; ++i) {
                    let error = $root.recorder_state.RecorderUploadError.verify(message.errors[i]);
                    if (error)
                        return "errors." + error;
                }
            }
            return null;
        };

        /**
         * Creates a RecorderProjectUploadErrors message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof recorder_state.RecorderProjectUploadErrors
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {recorder_state.RecorderProjectUploadErrors} RecorderProjectUploadErrors
         */
        RecorderProjectUploadErrors.fromObject = function fromObject(object) {
            if (object instanceof $root.recorder_state.RecorderProjectUploadErrors)
                return object;
            let message = new $root.recorder_state.RecorderProjectUploadErrors();
            if (object.errors) {
                if (!Array.isArray(object.errors))
                    throw TypeError(".recorder_state.RecorderProjectUploadErrors.errors: array expected");
                message.errors = [];
                for (let i = 0; i < object.errors.length; ++i) {
                    if (typeof object.errors[i] !== "object")
                        throw TypeError(".recorder_state.RecorderProjectUploadErrors.errors: object expected");
                    message.errors[i] = $root.recorder_state.RecorderUploadError.fromObject(object.errors[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a RecorderProjectUploadErrors message. Also converts values to other types if specified.
         * @function toObject
         * @memberof recorder_state.RecorderProjectUploadErrors
         * @static
         * @param {recorder_state.RecorderProjectUploadErrors} message RecorderProjectUploadErrors
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RecorderProjectUploadErrors.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.errors = [];
            if (message.errors && message.errors.length) {
                object.errors = [];
                for (let j = 0; j < message.errors.length; ++j)
                    object.errors[j] = $root.recorder_state.RecorderUploadError.toObject(message.errors[j], options);
            }
            return object;
        };

        /**
         * Converts this RecorderProjectUploadErrors to JSON.
         * @function toJSON
         * @memberof recorder_state.RecorderProjectUploadErrors
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RecorderProjectUploadErrors.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RecorderProjectUploadErrors
         * @function getTypeUrl
         * @memberof recorder_state.RecorderProjectUploadErrors
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RecorderProjectUploadErrors.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/recorder_state.RecorderProjectUploadErrors";
        };

        return RecorderProjectUploadErrors;
    })();

    recorder_state.OnRecorderBoot = (function() {

        /**
         * Properties of an OnRecorderBoot.
         * @memberof recorder_state
         * @interface IOnRecorderBoot
         */

        /**
         * Constructs a new OnRecorderBoot.
         * @memberof recorder_state
         * @classdesc Represents an OnRecorderBoot.
         * @implements IOnRecorderBoot
         * @constructor
         * @param {recorder_state.IOnRecorderBoot=} [properties] Properties to set
         */
        function OnRecorderBoot(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new OnRecorderBoot instance using the specified properties.
         * @function create
         * @memberof recorder_state.OnRecorderBoot
         * @static
         * @param {recorder_state.IOnRecorderBoot=} [properties] Properties to set
         * @returns {recorder_state.OnRecorderBoot} OnRecorderBoot instance
         */
        OnRecorderBoot.create = function create(properties) {
            return new OnRecorderBoot(properties);
        };

        /**
         * Encodes the specified OnRecorderBoot message. Does not implicitly {@link recorder_state.OnRecorderBoot.verify|verify} messages.
         * @function encode
         * @memberof recorder_state.OnRecorderBoot
         * @static
         * @param {recorder_state.IOnRecorderBoot} message OnRecorderBoot message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OnRecorderBoot.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified OnRecorderBoot message, length delimited. Does not implicitly {@link recorder_state.OnRecorderBoot.verify|verify} messages.
         * @function encodeDelimited
         * @memberof recorder_state.OnRecorderBoot
         * @static
         * @param {recorder_state.IOnRecorderBoot} message OnRecorderBoot message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OnRecorderBoot.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an OnRecorderBoot message from the specified reader or buffer.
         * @function decode
         * @memberof recorder_state.OnRecorderBoot
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {recorder_state.OnRecorderBoot} OnRecorderBoot
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OnRecorderBoot.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.recorder_state.OnRecorderBoot();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an OnRecorderBoot message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof recorder_state.OnRecorderBoot
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {recorder_state.OnRecorderBoot} OnRecorderBoot
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OnRecorderBoot.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an OnRecorderBoot message.
         * @function verify
         * @memberof recorder_state.OnRecorderBoot
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        OnRecorderBoot.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an OnRecorderBoot message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof recorder_state.OnRecorderBoot
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {recorder_state.OnRecorderBoot} OnRecorderBoot
         */
        OnRecorderBoot.fromObject = function fromObject(object) {
            if (object instanceof $root.recorder_state.OnRecorderBoot)
                return object;
            return new $root.recorder_state.OnRecorderBoot();
        };

        /**
         * Creates a plain object from an OnRecorderBoot message. Also converts values to other types if specified.
         * @function toObject
         * @memberof recorder_state.OnRecorderBoot
         * @static
         * @param {recorder_state.OnRecorderBoot} message OnRecorderBoot
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        OnRecorderBoot.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this OnRecorderBoot to JSON.
         * @function toJSON
         * @memberof recorder_state.OnRecorderBoot
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        OnRecorderBoot.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for OnRecorderBoot
         * @function getTypeUrl
         * @memberof recorder_state.OnRecorderBoot
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        OnRecorderBoot.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/recorder_state.OnRecorderBoot";
        };

        return OnRecorderBoot;
    })();

    recorder_state.RecorderUploadError = (function() {

        /**
         * Properties of a RecorderUploadError.
         * @memberof recorder_state
         * @interface IRecorderUploadError
         * @property {common.IDate|null} [date] RecorderUploadError date
         * @property {string|null} [action] RecorderUploadError action
         * @property {string|null} [error] RecorderUploadError error
         */

        /**
         * Constructs a new RecorderUploadError.
         * @memberof recorder_state
         * @classdesc Represents a RecorderUploadError.
         * @implements IRecorderUploadError
         * @constructor
         * @param {recorder_state.IRecorderUploadError=} [properties] Properties to set
         */
        function RecorderUploadError(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RecorderUploadError date.
         * @member {common.IDate|null|undefined} date
         * @memberof recorder_state.RecorderUploadError
         * @instance
         */
        RecorderUploadError.prototype.date = null;

        /**
         * RecorderUploadError action.
         * @member {string} action
         * @memberof recorder_state.RecorderUploadError
         * @instance
         */
        RecorderUploadError.prototype.action = "";

        /**
         * RecorderUploadError error.
         * @member {string} error
         * @memberof recorder_state.RecorderUploadError
         * @instance
         */
        RecorderUploadError.prototype.error = "";

        /**
         * Creates a new RecorderUploadError instance using the specified properties.
         * @function create
         * @memberof recorder_state.RecorderUploadError
         * @static
         * @param {recorder_state.IRecorderUploadError=} [properties] Properties to set
         * @returns {recorder_state.RecorderUploadError} RecorderUploadError instance
         */
        RecorderUploadError.create = function create(properties) {
            return new RecorderUploadError(properties);
        };

        /**
         * Encodes the specified RecorderUploadError message. Does not implicitly {@link recorder_state.RecorderUploadError.verify|verify} messages.
         * @function encode
         * @memberof recorder_state.RecorderUploadError
         * @static
         * @param {recorder_state.IRecorderUploadError} message RecorderUploadError message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RecorderUploadError.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.date != null && Object.hasOwnProperty.call(message, "date"))
                $root.common.Date.encode(message.date, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.action != null && Object.hasOwnProperty.call(message, "action"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.action);
            if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.error);
            return writer;
        };

        /**
         * Encodes the specified RecorderUploadError message, length delimited. Does not implicitly {@link recorder_state.RecorderUploadError.verify|verify} messages.
         * @function encodeDelimited
         * @memberof recorder_state.RecorderUploadError
         * @static
         * @param {recorder_state.IRecorderUploadError} message RecorderUploadError message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RecorderUploadError.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RecorderUploadError message from the specified reader or buffer.
         * @function decode
         * @memberof recorder_state.RecorderUploadError
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {recorder_state.RecorderUploadError} RecorderUploadError
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RecorderUploadError.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.recorder_state.RecorderUploadError();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.date = $root.common.Date.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.action = reader.string();
                        break;
                    }
                case 3: {
                        message.error = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RecorderUploadError message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof recorder_state.RecorderUploadError
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {recorder_state.RecorderUploadError} RecorderUploadError
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RecorderUploadError.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RecorderUploadError message.
         * @function verify
         * @memberof recorder_state.RecorderUploadError
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RecorderUploadError.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.date != null && message.hasOwnProperty("date")) {
                let error = $root.common.Date.verify(message.date);
                if (error)
                    return "date." + error;
            }
            if (message.action != null && message.hasOwnProperty("action"))
                if (!$util.isString(message.action))
                    return "action: string expected";
            if (message.error != null && message.hasOwnProperty("error"))
                if (!$util.isString(message.error))
                    return "error: string expected";
            return null;
        };

        /**
         * Creates a RecorderUploadError message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof recorder_state.RecorderUploadError
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {recorder_state.RecorderUploadError} RecorderUploadError
         */
        RecorderUploadError.fromObject = function fromObject(object) {
            if (object instanceof $root.recorder_state.RecorderUploadError)
                return object;
            let message = new $root.recorder_state.RecorderUploadError();
            if (object.date != null) {
                if (typeof object.date !== "object")
                    throw TypeError(".recorder_state.RecorderUploadError.date: object expected");
                message.date = $root.common.Date.fromObject(object.date);
            }
            if (object.action != null)
                message.action = String(object.action);
            if (object.error != null)
                message.error = String(object.error);
            return message;
        };

        /**
         * Creates a plain object from a RecorderUploadError message. Also converts values to other types if specified.
         * @function toObject
         * @memberof recorder_state.RecorderUploadError
         * @static
         * @param {recorder_state.RecorderUploadError} message RecorderUploadError
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RecorderUploadError.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.date = null;
                object.action = "";
                object.error = "";
            }
            if (message.date != null && message.hasOwnProperty("date"))
                object.date = $root.common.Date.toObject(message.date, options);
            if (message.action != null && message.hasOwnProperty("action"))
                object.action = message.action;
            if (message.error != null && message.hasOwnProperty("error"))
                object.error = message.error;
            return object;
        };

        /**
         * Converts this RecorderUploadError to JSON.
         * @function toJSON
         * @memberof recorder_state.RecorderUploadError
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RecorderUploadError.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RecorderUploadError
         * @function getTypeUrl
         * @memberof recorder_state.RecorderUploadError
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RecorderUploadError.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/recorder_state.RecorderUploadError";
        };

        return RecorderUploadError;
    })();

    return recorder_state;
})();

export const common = $root.common = (() => {

    /**
     * Namespace common.
     * @exports common
     * @namespace
     */
    const common = {};

    common.Pagination = (function() {

        /**
         * Properties of a Pagination.
         * @memberof common
         * @interface IPagination
         * @property {number|null} [page] Pagination page
         * @property {number|null} [nPages] Pagination nPages
         * @property {number|null} [pageSize] Pagination pageSize
         * @property {number|null} [total] Pagination total
         */

        /**
         * Constructs a new Pagination.
         * @memberof common
         * @classdesc Represents a Pagination.
         * @implements IPagination
         * @constructor
         * @param {common.IPagination=} [properties] Properties to set
         */
        function Pagination(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Pagination page.
         * @member {number} page
         * @memberof common.Pagination
         * @instance
         */
        Pagination.prototype.page = 0;

        /**
         * Pagination nPages.
         * @member {number} nPages
         * @memberof common.Pagination
         * @instance
         */
        Pagination.prototype.nPages = 0;

        /**
         * Pagination pageSize.
         * @member {number} pageSize
         * @memberof common.Pagination
         * @instance
         */
        Pagination.prototype.pageSize = 0;

        /**
         * Pagination total.
         * @member {number} total
         * @memberof common.Pagination
         * @instance
         */
        Pagination.prototype.total = 0;

        /**
         * Creates a new Pagination instance using the specified properties.
         * @function create
         * @memberof common.Pagination
         * @static
         * @param {common.IPagination=} [properties] Properties to set
         * @returns {common.Pagination} Pagination instance
         */
        Pagination.create = function create(properties) {
            return new Pagination(properties);
        };

        /**
         * Encodes the specified Pagination message. Does not implicitly {@link common.Pagination.verify|verify} messages.
         * @function encode
         * @memberof common.Pagination
         * @static
         * @param {common.IPagination} message Pagination message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Pagination.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.page);
            if (message.nPages != null && Object.hasOwnProperty.call(message, "nPages"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.nPages);
            if (message.pageSize != null && Object.hasOwnProperty.call(message, "pageSize"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.pageSize);
            if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.total);
            return writer;
        };

        /**
         * Encodes the specified Pagination message, length delimited. Does not implicitly {@link common.Pagination.verify|verify} messages.
         * @function encodeDelimited
         * @memberof common.Pagination
         * @static
         * @param {common.IPagination} message Pagination message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Pagination.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Pagination message from the specified reader or buffer.
         * @function decode
         * @memberof common.Pagination
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {common.Pagination} Pagination
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Pagination.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.common.Pagination();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.page = reader.int32();
                        break;
                    }
                case 2: {
                        message.nPages = reader.int32();
                        break;
                    }
                case 3: {
                        message.pageSize = reader.int32();
                        break;
                    }
                case 4: {
                        message.total = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Pagination message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof common.Pagination
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {common.Pagination} Pagination
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Pagination.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Pagination message.
         * @function verify
         * @memberof common.Pagination
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Pagination.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.page != null && message.hasOwnProperty("page"))
                if (!$util.isInteger(message.page))
                    return "page: integer expected";
            if (message.nPages != null && message.hasOwnProperty("nPages"))
                if (!$util.isInteger(message.nPages))
                    return "nPages: integer expected";
            if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                if (!$util.isInteger(message.pageSize))
                    return "pageSize: integer expected";
            if (message.total != null && message.hasOwnProperty("total"))
                if (!$util.isInteger(message.total))
                    return "total: integer expected";
            return null;
        };

        /**
         * Creates a Pagination message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof common.Pagination
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {common.Pagination} Pagination
         */
        Pagination.fromObject = function fromObject(object) {
            if (object instanceof $root.common.Pagination)
                return object;
            let message = new $root.common.Pagination();
            if (object.page != null)
                message.page = object.page | 0;
            if (object.nPages != null)
                message.nPages = object.nPages | 0;
            if (object.pageSize != null)
                message.pageSize = object.pageSize | 0;
            if (object.total != null)
                message.total = object.total | 0;
            return message;
        };

        /**
         * Creates a plain object from a Pagination message. Also converts values to other types if specified.
         * @function toObject
         * @memberof common.Pagination
         * @static
         * @param {common.Pagination} message Pagination
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Pagination.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.page = 0;
                object.nPages = 0;
                object.pageSize = 0;
                object.total = 0;
            }
            if (message.page != null && message.hasOwnProperty("page"))
                object.page = message.page;
            if (message.nPages != null && message.hasOwnProperty("nPages"))
                object.nPages = message.nPages;
            if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                object.pageSize = message.pageSize;
            if (message.total != null && message.hasOwnProperty("total"))
                object.total = message.total;
            return object;
        };

        /**
         * Converts this Pagination to JSON.
         * @function toJSON
         * @memberof common.Pagination
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Pagination.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Pagination
         * @function getTypeUrl
         * @memberof common.Pagination
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Pagination.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/common.Pagination";
        };

        return Pagination;
    })();

    common.DateFilter = (function() {

        /**
         * Properties of a DateFilter.
         * @memberof common
         * @interface IDateFilter
         * @property {number|Long|null} [ts] DateFilter ts
         * @property {common.DateRelation|null} [relation] DateFilter relation
         * @property {common.DateGranularity|null} [granularity] DateFilter granularity
         */

        /**
         * Constructs a new DateFilter.
         * @memberof common
         * @classdesc Represents a DateFilter.
         * @implements IDateFilter
         * @constructor
         * @param {common.IDateFilter=} [properties] Properties to set
         */
        function DateFilter(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DateFilter ts.
         * @member {number|Long} ts
         * @memberof common.DateFilter
         * @instance
         */
        DateFilter.prototype.ts = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * DateFilter relation.
         * @member {common.DateRelation} relation
         * @memberof common.DateFilter
         * @instance
         */
        DateFilter.prototype.relation = 0;

        /**
         * DateFilter granularity.
         * @member {common.DateGranularity} granularity
         * @memberof common.DateFilter
         * @instance
         */
        DateFilter.prototype.granularity = 0;

        /**
         * Creates a new DateFilter instance using the specified properties.
         * @function create
         * @memberof common.DateFilter
         * @static
         * @param {common.IDateFilter=} [properties] Properties to set
         * @returns {common.DateFilter} DateFilter instance
         */
        DateFilter.create = function create(properties) {
            return new DateFilter(properties);
        };

        /**
         * Encodes the specified DateFilter message. Does not implicitly {@link common.DateFilter.verify|verify} messages.
         * @function encode
         * @memberof common.DateFilter
         * @static
         * @param {common.IDateFilter} message DateFilter message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DateFilter.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.ts != null && Object.hasOwnProperty.call(message, "ts"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.ts);
            if (message.relation != null && Object.hasOwnProperty.call(message, "relation"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.relation);
            if (message.granularity != null && Object.hasOwnProperty.call(message, "granularity"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.granularity);
            return writer;
        };

        /**
         * Encodes the specified DateFilter message, length delimited. Does not implicitly {@link common.DateFilter.verify|verify} messages.
         * @function encodeDelimited
         * @memberof common.DateFilter
         * @static
         * @param {common.IDateFilter} message DateFilter message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DateFilter.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DateFilter message from the specified reader or buffer.
         * @function decode
         * @memberof common.DateFilter
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {common.DateFilter} DateFilter
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DateFilter.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.common.DateFilter();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.ts = reader.uint64();
                        break;
                    }
                case 2: {
                        message.relation = reader.int32();
                        break;
                    }
                case 3: {
                        message.granularity = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DateFilter message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof common.DateFilter
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {common.DateFilter} DateFilter
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DateFilter.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DateFilter message.
         * @function verify
         * @memberof common.DateFilter
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DateFilter.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.ts != null && message.hasOwnProperty("ts"))
                if (!$util.isInteger(message.ts) && !(message.ts && $util.isInteger(message.ts.low) && $util.isInteger(message.ts.high)))
                    return "ts: integer|Long expected";
            if (message.relation != null && message.hasOwnProperty("relation"))
                switch (message.relation) {
                default:
                    return "relation: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message.granularity != null && message.hasOwnProperty("granularity"))
                switch (message.granularity) {
                default:
                    return "granularity: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                    break;
                }
            return null;
        };

        /**
         * Creates a DateFilter message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof common.DateFilter
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {common.DateFilter} DateFilter
         */
        DateFilter.fromObject = function fromObject(object) {
            if (object instanceof $root.common.DateFilter)
                return object;
            let message = new $root.common.DateFilter();
            if (object.ts != null)
                if ($util.Long)
                    (message.ts = $util.Long.fromValue(object.ts)).unsigned = true;
                else if (typeof object.ts === "string")
                    message.ts = parseInt(object.ts, 10);
                else if (typeof object.ts === "number")
                    message.ts = object.ts;
                else if (typeof object.ts === "object")
                    message.ts = new $util.LongBits(object.ts.low >>> 0, object.ts.high >>> 0).toNumber(true);
            switch (object.relation) {
            default:
                if (typeof object.relation === "number") {
                    message.relation = object.relation;
                    break;
                }
                break;
            case "dr_eq":
            case 0:
                message.relation = 0;
                break;
            case "dr_gt":
            case 1:
                message.relation = 1;
                break;
            case "dr_lt":
            case 2:
                message.relation = 2;
                break;
            }
            switch (object.granularity) {
            default:
                if (typeof object.granularity === "number") {
                    message.granularity = object.granularity;
                    break;
                }
                break;
            case "no_granularity":
            case 0:
                message.granularity = 0;
                break;
            case "year":
            case 1:
                message.granularity = 1;
                break;
            case "month":
            case 2:
                message.granularity = 2;
                break;
            case "day":
            case 3:
                message.granularity = 3;
                break;
            case "hour":
            case 4:
                message.granularity = 4;
                break;
            case "minute":
            case 5:
                message.granularity = 5;
                break;
            case "second":
            case 6:
                message.granularity = 6;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a DateFilter message. Also converts values to other types if specified.
         * @function toObject
         * @memberof common.DateFilter
         * @static
         * @param {common.DateFilter} message DateFilter
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DateFilter.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.ts = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.ts = options.longs === String ? "0" : 0;
                object.relation = options.enums === String ? "dr_eq" : 0;
                object.granularity = options.enums === String ? "no_granularity" : 0;
            }
            if (message.ts != null && message.hasOwnProperty("ts"))
                if (typeof message.ts === "number")
                    object.ts = options.longs === String ? String(message.ts) : message.ts;
                else
                    object.ts = options.longs === String ? $util.Long.prototype.toString.call(message.ts) : options.longs === Number ? new $util.LongBits(message.ts.low >>> 0, message.ts.high >>> 0).toNumber(true) : message.ts;
            if (message.relation != null && message.hasOwnProperty("relation"))
                object.relation = options.enums === String ? $root.common.DateRelation[message.relation] === undefined ? message.relation : $root.common.DateRelation[message.relation] : message.relation;
            if (message.granularity != null && message.hasOwnProperty("granularity"))
                object.granularity = options.enums === String ? $root.common.DateGranularity[message.granularity] === undefined ? message.granularity : $root.common.DateGranularity[message.granularity] : message.granularity;
            return object;
        };

        /**
         * Converts this DateFilter to JSON.
         * @function toJSON
         * @memberof common.DateFilter
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DateFilter.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DateFilter
         * @function getTypeUrl
         * @memberof common.DateFilter
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DateFilter.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/common.DateFilter";
        };

        return DateFilter;
    })();

    common.Date = (function() {

        /**
         * Properties of a Date.
         * @memberof common
         * @interface IDate
         * @property {string|null} [tz] Date tz
         * @property {number|Long|null} [ts] Date ts
         * @property {common.DateGranularity|null} [granularity] Date granularity
         */

        /**
         * Constructs a new Date.
         * @memberof common
         * @classdesc Represents a Date.
         * @implements IDate
         * @constructor
         * @param {common.IDate=} [properties] Properties to set
         */
        function Date(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Date tz.
         * @member {string} tz
         * @memberof common.Date
         * @instance
         */
        Date.prototype.tz = "";

        /**
         * Date ts.
         * @member {number|Long} ts
         * @memberof common.Date
         * @instance
         */
        Date.prototype.ts = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Date granularity.
         * @member {common.DateGranularity} granularity
         * @memberof common.Date
         * @instance
         */
        Date.prototype.granularity = 0;

        /**
         * Creates a new Date instance using the specified properties.
         * @function create
         * @memberof common.Date
         * @static
         * @param {common.IDate=} [properties] Properties to set
         * @returns {common.Date} Date instance
         */
        Date.create = function create(properties) {
            return new Date(properties);
        };

        /**
         * Encodes the specified Date message. Does not implicitly {@link common.Date.verify|verify} messages.
         * @function encode
         * @memberof common.Date
         * @static
         * @param {common.IDate} message Date message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Date.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.tz != null && Object.hasOwnProperty.call(message, "tz"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.tz);
            if (message.ts != null && Object.hasOwnProperty.call(message, "ts"))
                writer.uint32(/* id 8, wireType 0 =*/64).uint64(message.ts);
            if (message.granularity != null && Object.hasOwnProperty.call(message, "granularity"))
                writer.uint32(/* id 9, wireType 0 =*/72).int32(message.granularity);
            return writer;
        };

        /**
         * Encodes the specified Date message, length delimited. Does not implicitly {@link common.Date.verify|verify} messages.
         * @function encodeDelimited
         * @memberof common.Date
         * @static
         * @param {common.IDate} message Date message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Date.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Date message from the specified reader or buffer.
         * @function decode
         * @memberof common.Date
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {common.Date} Date
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Date.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.common.Date();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 7: {
                        message.tz = reader.string();
                        break;
                    }
                case 8: {
                        message.ts = reader.uint64();
                        break;
                    }
                case 9: {
                        message.granularity = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Date message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof common.Date
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {common.Date} Date
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Date.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Date message.
         * @function verify
         * @memberof common.Date
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Date.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.tz != null && message.hasOwnProperty("tz"))
                if (!$util.isString(message.tz))
                    return "tz: string expected";
            if (message.ts != null && message.hasOwnProperty("ts"))
                if (!$util.isInteger(message.ts) && !(message.ts && $util.isInteger(message.ts.low) && $util.isInteger(message.ts.high)))
                    return "ts: integer|Long expected";
            if (message.granularity != null && message.hasOwnProperty("granularity"))
                switch (message.granularity) {
                default:
                    return "granularity: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                    break;
                }
            return null;
        };

        /**
         * Creates a Date message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof common.Date
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {common.Date} Date
         */
        Date.fromObject = function fromObject(object) {
            if (object instanceof $root.common.Date)
                return object;
            let message = new $root.common.Date();
            if (object.tz != null)
                message.tz = String(object.tz);
            if (object.ts != null)
                if ($util.Long)
                    (message.ts = $util.Long.fromValue(object.ts)).unsigned = true;
                else if (typeof object.ts === "string")
                    message.ts = parseInt(object.ts, 10);
                else if (typeof object.ts === "number")
                    message.ts = object.ts;
                else if (typeof object.ts === "object")
                    message.ts = new $util.LongBits(object.ts.low >>> 0, object.ts.high >>> 0).toNumber(true);
            switch (object.granularity) {
            default:
                if (typeof object.granularity === "number") {
                    message.granularity = object.granularity;
                    break;
                }
                break;
            case "no_granularity":
            case 0:
                message.granularity = 0;
                break;
            case "year":
            case 1:
                message.granularity = 1;
                break;
            case "month":
            case 2:
                message.granularity = 2;
                break;
            case "day":
            case 3:
                message.granularity = 3;
                break;
            case "hour":
            case 4:
                message.granularity = 4;
                break;
            case "minute":
            case 5:
                message.granularity = 5;
                break;
            case "second":
            case 6:
                message.granularity = 6;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a Date message. Also converts values to other types if specified.
         * @function toObject
         * @memberof common.Date
         * @static
         * @param {common.Date} message Date
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Date.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.tz = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.ts = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.ts = options.longs === String ? "0" : 0;
                object.granularity = options.enums === String ? "no_granularity" : 0;
            }
            if (message.tz != null && message.hasOwnProperty("tz"))
                object.tz = message.tz;
            if (message.ts != null && message.hasOwnProperty("ts"))
                if (typeof message.ts === "number")
                    object.ts = options.longs === String ? String(message.ts) : message.ts;
                else
                    object.ts = options.longs === String ? $util.Long.prototype.toString.call(message.ts) : options.longs === Number ? new $util.LongBits(message.ts.low >>> 0, message.ts.high >>> 0).toNumber(true) : message.ts;
            if (message.granularity != null && message.hasOwnProperty("granularity"))
                object.granularity = options.enums === String ? $root.common.DateGranularity[message.granularity] === undefined ? message.granularity : $root.common.DateGranularity[message.granularity] : message.granularity;
            return object;
        };

        /**
         * Converts this Date to JSON.
         * @function toJSON
         * @memberof common.Date
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Date.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Date
         * @function getTypeUrl
         * @memberof common.Date
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Date.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/common.Date";
        };

        return Date;
    })();

    /**
     * DateGranularity enum.
     * @name common.DateGranularity
     * @enum {number}
     * @property {number} no_granularity=0 no_granularity value
     * @property {number} year=1 year value
     * @property {number} month=2 month value
     * @property {number} day=3 day value
     * @property {number} hour=4 hour value
     * @property {number} minute=5 minute value
     * @property {number} second=6 second value
     */
    common.DateGranularity = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "no_granularity"] = 0;
        values[valuesById[1] = "year"] = 1;
        values[valuesById[2] = "month"] = 2;
        values[valuesById[3] = "day"] = 3;
        values[valuesById[4] = "hour"] = 4;
        values[valuesById[5] = "minute"] = 5;
        values[valuesById[6] = "second"] = 6;
        return values;
    })();

    /**
     * DateRelation enum.
     * @name common.DateRelation
     * @enum {number}
     * @property {number} dr_eq=0 dr_eq value
     * @property {number} dr_gt=1 dr_gt value
     * @property {number} dr_lt=2 dr_lt value
     */
    common.DateRelation = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "dr_eq"] = 0;
        values[valuesById[1] = "dr_gt"] = 1;
        values[valuesById[2] = "dr_lt"] = 2;
        return values;
    })();

    /**
     * SortOrder enum.
     * @name common.SortOrder
     * @enum {number}
     * @property {number} so_none=0 so_none value
     * @property {number} so_asc=1 so_asc value
     * @property {number} so_desc=2 so_desc value
     */
    common.SortOrder = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "so_none"] = 0;
        values[valuesById[1] = "so_asc"] = 1;
        values[valuesById[2] = "so_desc"] = 2;
        return values;
    })();

    return common;
})();

export const asod_project = $root.asod_project = (() => {

    /**
     * Namespace asod_project.
     * @exports asod_project
     * @namespace
     */
    const asod_project = {};

    asod_project.LoadAsodProject = (function() {

        /**
         * Properties of a LoadAsodProject.
         * @memberof asod_project
         * @interface ILoadAsodProject
         * @property {string|null} [id] LoadAsodProject id
         * @property {asod_project.IAsodProject|null} [_response] LoadAsodProject _response
         */

        /**
         * Constructs a new LoadAsodProject.
         * @memberof asod_project
         * @classdesc Represents a LoadAsodProject.
         * @implements ILoadAsodProject
         * @constructor
         * @param {asod_project.ILoadAsodProject=} [properties] Properties to set
         */
        function LoadAsodProject(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LoadAsodProject id.
         * @member {string} id
         * @memberof asod_project.LoadAsodProject
         * @instance
         */
        LoadAsodProject.prototype.id = "";

        /**
         * LoadAsodProject _response.
         * @member {asod_project.IAsodProject|null|undefined} _response
         * @memberof asod_project.LoadAsodProject
         * @instance
         */
        LoadAsodProject.prototype._response = null;

        /**
         * Creates a new LoadAsodProject instance using the specified properties.
         * @function create
         * @memberof asod_project.LoadAsodProject
         * @static
         * @param {asod_project.ILoadAsodProject=} [properties] Properties to set
         * @returns {asod_project.LoadAsodProject} LoadAsodProject instance
         */
        LoadAsodProject.create = function create(properties) {
            return new LoadAsodProject(properties);
        };

        /**
         * Encodes the specified LoadAsodProject message. Does not implicitly {@link asod_project.LoadAsodProject.verify|verify} messages.
         * @function encode
         * @memberof asod_project.LoadAsodProject
         * @static
         * @param {asod_project.ILoadAsodProject} message LoadAsodProject message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoadAsodProject.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message._response != null && Object.hasOwnProperty.call(message, "_response"))
                $root.asod_project.AsodProject.encode(message._response, writer.uint32(/* id 99, wireType 2 =*/794).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified LoadAsodProject message, length delimited. Does not implicitly {@link asod_project.LoadAsodProject.verify|verify} messages.
         * @function encodeDelimited
         * @memberof asod_project.LoadAsodProject
         * @static
         * @param {asod_project.ILoadAsodProject} message LoadAsodProject message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoadAsodProject.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LoadAsodProject message from the specified reader or buffer.
         * @function decode
         * @memberof asod_project.LoadAsodProject
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {asod_project.LoadAsodProject} LoadAsodProject
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoadAsodProject.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.asod_project.LoadAsodProject();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 99: {
                        message._response = $root.asod_project.AsodProject.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LoadAsodProject message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof asod_project.LoadAsodProject
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {asod_project.LoadAsodProject} LoadAsodProject
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoadAsodProject.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LoadAsodProject message.
         * @function verify
         * @memberof asod_project.LoadAsodProject
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LoadAsodProject.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message._response != null && message.hasOwnProperty("_response")) {
                let error = $root.asod_project.AsodProject.verify(message._response);
                if (error)
                    return "_response." + error;
            }
            return null;
        };

        /**
         * Creates a LoadAsodProject message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof asod_project.LoadAsodProject
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {asod_project.LoadAsodProject} LoadAsodProject
         */
        LoadAsodProject.fromObject = function fromObject(object) {
            if (object instanceof $root.asod_project.LoadAsodProject)
                return object;
            let message = new $root.asod_project.LoadAsodProject();
            if (object.id != null)
                message.id = String(object.id);
            if (object._response != null) {
                if (typeof object._response !== "object")
                    throw TypeError(".asod_project.LoadAsodProject._response: object expected");
                message._response = $root.asod_project.AsodProject.fromObject(object._response);
            }
            return message;
        };

        /**
         * Creates a plain object from a LoadAsodProject message. Also converts values to other types if specified.
         * @function toObject
         * @memberof asod_project.LoadAsodProject
         * @static
         * @param {asod_project.LoadAsodProject} message LoadAsodProject
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LoadAsodProject.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.id = "";
                object._response = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message._response != null && message.hasOwnProperty("_response"))
                object._response = $root.asod_project.AsodProject.toObject(message._response, options);
            return object;
        };

        /**
         * Converts this LoadAsodProject to JSON.
         * @function toJSON
         * @memberof asod_project.LoadAsodProject
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LoadAsodProject.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LoadAsodProject
         * @function getTypeUrl
         * @memberof asod_project.LoadAsodProject
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LoadAsodProject.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/asod_project.LoadAsodProject";
        };

        return LoadAsodProject;
    })();

    asod_project.AsodProject = (function() {

        /**
         * Properties of an AsodProject.
         * @memberof asod_project
         * @interface IAsodProject
         * @property {string|null} [id] AsodProject id
         * @property {asod_project.IAsodProjectMeta|null} [meta] AsodProject meta
         * @property {Array.<asod_project.IAsodProjectAudioBlock>|null} [audioBlocks] AsodProject audioBlocks
         */

        /**
         * Constructs a new AsodProject.
         * @memberof asod_project
         * @classdesc Represents an AsodProject.
         * @implements IAsodProject
         * @constructor
         * @param {asod_project.IAsodProject=} [properties] Properties to set
         */
        function AsodProject(properties) {
            this.audioBlocks = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AsodProject id.
         * @member {string} id
         * @memberof asod_project.AsodProject
         * @instance
         */
        AsodProject.prototype.id = "";

        /**
         * AsodProject meta.
         * @member {asod_project.IAsodProjectMeta|null|undefined} meta
         * @memberof asod_project.AsodProject
         * @instance
         */
        AsodProject.prototype.meta = null;

        /**
         * AsodProject audioBlocks.
         * @member {Array.<asod_project.IAsodProjectAudioBlock>} audioBlocks
         * @memberof asod_project.AsodProject
         * @instance
         */
        AsodProject.prototype.audioBlocks = $util.emptyArray;

        /**
         * Creates a new AsodProject instance using the specified properties.
         * @function create
         * @memberof asod_project.AsodProject
         * @static
         * @param {asod_project.IAsodProject=} [properties] Properties to set
         * @returns {asod_project.AsodProject} AsodProject instance
         */
        AsodProject.create = function create(properties) {
            return new AsodProject(properties);
        };

        /**
         * Encodes the specified AsodProject message. Does not implicitly {@link asod_project.AsodProject.verify|verify} messages.
         * @function encode
         * @memberof asod_project.AsodProject
         * @static
         * @param {asod_project.IAsodProject} message AsodProject message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodProject.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.meta != null && Object.hasOwnProperty.call(message, "meta"))
                $root.asod_project.AsodProjectMeta.encode(message.meta, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.audioBlocks != null && message.audioBlocks.length)
                for (let i = 0; i < message.audioBlocks.length; ++i)
                    $root.asod_project.AsodProjectAudioBlock.encode(message.audioBlocks[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified AsodProject message, length delimited. Does not implicitly {@link asod_project.AsodProject.verify|verify} messages.
         * @function encodeDelimited
         * @memberof asod_project.AsodProject
         * @static
         * @param {asod_project.IAsodProject} message AsodProject message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodProject.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AsodProject message from the specified reader or buffer.
         * @function decode
         * @memberof asod_project.AsodProject
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {asod_project.AsodProject} AsodProject
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodProject.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.asod_project.AsodProject();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 2: {
                        message.meta = $root.asod_project.AsodProjectMeta.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        if (!(message.audioBlocks && message.audioBlocks.length))
                            message.audioBlocks = [];
                        message.audioBlocks.push($root.asod_project.AsodProjectAudioBlock.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AsodProject message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof asod_project.AsodProject
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {asod_project.AsodProject} AsodProject
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodProject.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AsodProject message.
         * @function verify
         * @memberof asod_project.AsodProject
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AsodProject.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.meta != null && message.hasOwnProperty("meta")) {
                let error = $root.asod_project.AsodProjectMeta.verify(message.meta);
                if (error)
                    return "meta." + error;
            }
            if (message.audioBlocks != null && message.hasOwnProperty("audioBlocks")) {
                if (!Array.isArray(message.audioBlocks))
                    return "audioBlocks: array expected";
                for (let i = 0; i < message.audioBlocks.length; ++i) {
                    let error = $root.asod_project.AsodProjectAudioBlock.verify(message.audioBlocks[i]);
                    if (error)
                        return "audioBlocks." + error;
                }
            }
            return null;
        };

        /**
         * Creates an AsodProject message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof asod_project.AsodProject
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {asod_project.AsodProject} AsodProject
         */
        AsodProject.fromObject = function fromObject(object) {
            if (object instanceof $root.asod_project.AsodProject)
                return object;
            let message = new $root.asod_project.AsodProject();
            if (object.id != null)
                message.id = String(object.id);
            if (object.meta != null) {
                if (typeof object.meta !== "object")
                    throw TypeError(".asod_project.AsodProject.meta: object expected");
                message.meta = $root.asod_project.AsodProjectMeta.fromObject(object.meta);
            }
            if (object.audioBlocks) {
                if (!Array.isArray(object.audioBlocks))
                    throw TypeError(".asod_project.AsodProject.audioBlocks: array expected");
                message.audioBlocks = [];
                for (let i = 0; i < object.audioBlocks.length; ++i) {
                    if (typeof object.audioBlocks[i] !== "object")
                        throw TypeError(".asod_project.AsodProject.audioBlocks: object expected");
                    message.audioBlocks[i] = $root.asod_project.AsodProjectAudioBlock.fromObject(object.audioBlocks[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from an AsodProject message. Also converts values to other types if specified.
         * @function toObject
         * @memberof asod_project.AsodProject
         * @static
         * @param {asod_project.AsodProject} message AsodProject
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AsodProject.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.audioBlocks = [];
            if (options.defaults) {
                object.id = "";
                object.meta = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.meta != null && message.hasOwnProperty("meta"))
                object.meta = $root.asod_project.AsodProjectMeta.toObject(message.meta, options);
            if (message.audioBlocks && message.audioBlocks.length) {
                object.audioBlocks = [];
                for (let j = 0; j < message.audioBlocks.length; ++j)
                    object.audioBlocks[j] = $root.asod_project.AsodProjectAudioBlock.toObject(message.audioBlocks[j], options);
            }
            return object;
        };

        /**
         * Converts this AsodProject to JSON.
         * @function toJSON
         * @memberof asod_project.AsodProject
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AsodProject.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AsodProject
         * @function getTypeUrl
         * @memberof asod_project.AsodProject
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AsodProject.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/asod_project.AsodProject";
        };

        return AsodProject;
    })();

    asod_project.AsodProjectAddComment = (function() {

        /**
         * Properties of an AsodProjectAddComment.
         * @memberof asod_project
         * @interface IAsodProjectAddComment
         * @property {string|null} [projectId] AsodProjectAddComment projectId
         * @property {number|Long|null} [timestamp] AsodProjectAddComment timestamp
         * @property {asod_project.IAsodProjectComment|null} [_response] AsodProjectAddComment _response
         */

        /**
         * Constructs a new AsodProjectAddComment.
         * @memberof asod_project
         * @classdesc Represents an AsodProjectAddComment.
         * @implements IAsodProjectAddComment
         * @constructor
         * @param {asod_project.IAsodProjectAddComment=} [properties] Properties to set
         */
        function AsodProjectAddComment(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AsodProjectAddComment projectId.
         * @member {string} projectId
         * @memberof asod_project.AsodProjectAddComment
         * @instance
         */
        AsodProjectAddComment.prototype.projectId = "";

        /**
         * AsodProjectAddComment timestamp.
         * @member {number|Long} timestamp
         * @memberof asod_project.AsodProjectAddComment
         * @instance
         */
        AsodProjectAddComment.prototype.timestamp = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * AsodProjectAddComment _response.
         * @member {asod_project.IAsodProjectComment|null|undefined} _response
         * @memberof asod_project.AsodProjectAddComment
         * @instance
         */
        AsodProjectAddComment.prototype._response = null;

        /**
         * Creates a new AsodProjectAddComment instance using the specified properties.
         * @function create
         * @memberof asod_project.AsodProjectAddComment
         * @static
         * @param {asod_project.IAsodProjectAddComment=} [properties] Properties to set
         * @returns {asod_project.AsodProjectAddComment} AsodProjectAddComment instance
         */
        AsodProjectAddComment.create = function create(properties) {
            return new AsodProjectAddComment(properties);
        };

        /**
         * Encodes the specified AsodProjectAddComment message. Does not implicitly {@link asod_project.AsodProjectAddComment.verify|verify} messages.
         * @function encode
         * @memberof asod_project.AsodProjectAddComment
         * @static
         * @param {asod_project.IAsodProjectAddComment} message AsodProjectAddComment message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodProjectAddComment.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.projectId != null && Object.hasOwnProperty.call(message, "projectId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.projectId);
            if (message.timestamp != null && Object.hasOwnProperty.call(message, "timestamp"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.timestamp);
            if (message._response != null && Object.hasOwnProperty.call(message, "_response"))
                $root.asod_project.AsodProjectComment.encode(message._response, writer.uint32(/* id 99, wireType 2 =*/794).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified AsodProjectAddComment message, length delimited. Does not implicitly {@link asod_project.AsodProjectAddComment.verify|verify} messages.
         * @function encodeDelimited
         * @memberof asod_project.AsodProjectAddComment
         * @static
         * @param {asod_project.IAsodProjectAddComment} message AsodProjectAddComment message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodProjectAddComment.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AsodProjectAddComment message from the specified reader or buffer.
         * @function decode
         * @memberof asod_project.AsodProjectAddComment
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {asod_project.AsodProjectAddComment} AsodProjectAddComment
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodProjectAddComment.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.asod_project.AsodProjectAddComment();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.projectId = reader.string();
                        break;
                    }
                case 2: {
                        message.timestamp = reader.uint64();
                        break;
                    }
                case 99: {
                        message._response = $root.asod_project.AsodProjectComment.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AsodProjectAddComment message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof asod_project.AsodProjectAddComment
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {asod_project.AsodProjectAddComment} AsodProjectAddComment
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodProjectAddComment.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AsodProjectAddComment message.
         * @function verify
         * @memberof asod_project.AsodProjectAddComment
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AsodProjectAddComment.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.projectId != null && message.hasOwnProperty("projectId"))
                if (!$util.isString(message.projectId))
                    return "projectId: string expected";
            if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                if (!$util.isInteger(message.timestamp) && !(message.timestamp && $util.isInteger(message.timestamp.low) && $util.isInteger(message.timestamp.high)))
                    return "timestamp: integer|Long expected";
            if (message._response != null && message.hasOwnProperty("_response")) {
                let error = $root.asod_project.AsodProjectComment.verify(message._response);
                if (error)
                    return "_response." + error;
            }
            return null;
        };

        /**
         * Creates an AsodProjectAddComment message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof asod_project.AsodProjectAddComment
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {asod_project.AsodProjectAddComment} AsodProjectAddComment
         */
        AsodProjectAddComment.fromObject = function fromObject(object) {
            if (object instanceof $root.asod_project.AsodProjectAddComment)
                return object;
            let message = new $root.asod_project.AsodProjectAddComment();
            if (object.projectId != null)
                message.projectId = String(object.projectId);
            if (object.timestamp != null)
                if ($util.Long)
                    (message.timestamp = $util.Long.fromValue(object.timestamp)).unsigned = true;
                else if (typeof object.timestamp === "string")
                    message.timestamp = parseInt(object.timestamp, 10);
                else if (typeof object.timestamp === "number")
                    message.timestamp = object.timestamp;
                else if (typeof object.timestamp === "object")
                    message.timestamp = new $util.LongBits(object.timestamp.low >>> 0, object.timestamp.high >>> 0).toNumber(true);
            if (object._response != null) {
                if (typeof object._response !== "object")
                    throw TypeError(".asod_project.AsodProjectAddComment._response: object expected");
                message._response = $root.asod_project.AsodProjectComment.fromObject(object._response);
            }
            return message;
        };

        /**
         * Creates a plain object from an AsodProjectAddComment message. Also converts values to other types if specified.
         * @function toObject
         * @memberof asod_project.AsodProjectAddComment
         * @static
         * @param {asod_project.AsodProjectAddComment} message AsodProjectAddComment
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AsodProjectAddComment.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.projectId = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.timestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.timestamp = options.longs === String ? "0" : 0;
                object._response = null;
            }
            if (message.projectId != null && message.hasOwnProperty("projectId"))
                object.projectId = message.projectId;
            if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                if (typeof message.timestamp === "number")
                    object.timestamp = options.longs === String ? String(message.timestamp) : message.timestamp;
                else
                    object.timestamp = options.longs === String ? $util.Long.prototype.toString.call(message.timestamp) : options.longs === Number ? new $util.LongBits(message.timestamp.low >>> 0, message.timestamp.high >>> 0).toNumber(true) : message.timestamp;
            if (message._response != null && message.hasOwnProperty("_response"))
                object._response = $root.asod_project.AsodProjectComment.toObject(message._response, options);
            return object;
        };

        /**
         * Converts this AsodProjectAddComment to JSON.
         * @function toJSON
         * @memberof asod_project.AsodProjectAddComment
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AsodProjectAddComment.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AsodProjectAddComment
         * @function getTypeUrl
         * @memberof asod_project.AsodProjectAddComment
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AsodProjectAddComment.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/asod_project.AsodProjectAddComment";
        };

        return AsodProjectAddComment;
    })();

    asod_project.AsodProjectSaveComment = (function() {

        /**
         * Properties of an AsodProjectSaveComment.
         * @memberof asod_project
         * @interface IAsodProjectSaveComment
         * @property {string|null} [projectId] AsodProjectSaveComment projectId
         * @property {string|null} [blockId] AsodProjectSaveComment blockId
         * @property {asod_project.IAsodProjectComment|null} [comment] AsodProjectSaveComment comment
         */

        /**
         * Constructs a new AsodProjectSaveComment.
         * @memberof asod_project
         * @classdesc Represents an AsodProjectSaveComment.
         * @implements IAsodProjectSaveComment
         * @constructor
         * @param {asod_project.IAsodProjectSaveComment=} [properties] Properties to set
         */
        function AsodProjectSaveComment(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AsodProjectSaveComment projectId.
         * @member {string} projectId
         * @memberof asod_project.AsodProjectSaveComment
         * @instance
         */
        AsodProjectSaveComment.prototype.projectId = "";

        /**
         * AsodProjectSaveComment blockId.
         * @member {string} blockId
         * @memberof asod_project.AsodProjectSaveComment
         * @instance
         */
        AsodProjectSaveComment.prototype.blockId = "";

        /**
         * AsodProjectSaveComment comment.
         * @member {asod_project.IAsodProjectComment|null|undefined} comment
         * @memberof asod_project.AsodProjectSaveComment
         * @instance
         */
        AsodProjectSaveComment.prototype.comment = null;

        /**
         * Creates a new AsodProjectSaveComment instance using the specified properties.
         * @function create
         * @memberof asod_project.AsodProjectSaveComment
         * @static
         * @param {asod_project.IAsodProjectSaveComment=} [properties] Properties to set
         * @returns {asod_project.AsodProjectSaveComment} AsodProjectSaveComment instance
         */
        AsodProjectSaveComment.create = function create(properties) {
            return new AsodProjectSaveComment(properties);
        };

        /**
         * Encodes the specified AsodProjectSaveComment message. Does not implicitly {@link asod_project.AsodProjectSaveComment.verify|verify} messages.
         * @function encode
         * @memberof asod_project.AsodProjectSaveComment
         * @static
         * @param {asod_project.IAsodProjectSaveComment} message AsodProjectSaveComment message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodProjectSaveComment.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.projectId != null && Object.hasOwnProperty.call(message, "projectId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.projectId);
            if (message.blockId != null && Object.hasOwnProperty.call(message, "blockId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.blockId);
            if (message.comment != null && Object.hasOwnProperty.call(message, "comment"))
                $root.asod_project.AsodProjectComment.encode(message.comment, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified AsodProjectSaveComment message, length delimited. Does not implicitly {@link asod_project.AsodProjectSaveComment.verify|verify} messages.
         * @function encodeDelimited
         * @memberof asod_project.AsodProjectSaveComment
         * @static
         * @param {asod_project.IAsodProjectSaveComment} message AsodProjectSaveComment message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodProjectSaveComment.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AsodProjectSaveComment message from the specified reader or buffer.
         * @function decode
         * @memberof asod_project.AsodProjectSaveComment
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {asod_project.AsodProjectSaveComment} AsodProjectSaveComment
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodProjectSaveComment.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.asod_project.AsodProjectSaveComment();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.projectId = reader.string();
                        break;
                    }
                case 2: {
                        message.blockId = reader.string();
                        break;
                    }
                case 3: {
                        message.comment = $root.asod_project.AsodProjectComment.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AsodProjectSaveComment message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof asod_project.AsodProjectSaveComment
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {asod_project.AsodProjectSaveComment} AsodProjectSaveComment
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodProjectSaveComment.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AsodProjectSaveComment message.
         * @function verify
         * @memberof asod_project.AsodProjectSaveComment
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AsodProjectSaveComment.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.projectId != null && message.hasOwnProperty("projectId"))
                if (!$util.isString(message.projectId))
                    return "projectId: string expected";
            if (message.blockId != null && message.hasOwnProperty("blockId"))
                if (!$util.isString(message.blockId))
                    return "blockId: string expected";
            if (message.comment != null && message.hasOwnProperty("comment")) {
                let error = $root.asod_project.AsodProjectComment.verify(message.comment);
                if (error)
                    return "comment." + error;
            }
            return null;
        };

        /**
         * Creates an AsodProjectSaveComment message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof asod_project.AsodProjectSaveComment
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {asod_project.AsodProjectSaveComment} AsodProjectSaveComment
         */
        AsodProjectSaveComment.fromObject = function fromObject(object) {
            if (object instanceof $root.asod_project.AsodProjectSaveComment)
                return object;
            let message = new $root.asod_project.AsodProjectSaveComment();
            if (object.projectId != null)
                message.projectId = String(object.projectId);
            if (object.blockId != null)
                message.blockId = String(object.blockId);
            if (object.comment != null) {
                if (typeof object.comment !== "object")
                    throw TypeError(".asod_project.AsodProjectSaveComment.comment: object expected");
                message.comment = $root.asod_project.AsodProjectComment.fromObject(object.comment);
            }
            return message;
        };

        /**
         * Creates a plain object from an AsodProjectSaveComment message. Also converts values to other types if specified.
         * @function toObject
         * @memberof asod_project.AsodProjectSaveComment
         * @static
         * @param {asod_project.AsodProjectSaveComment} message AsodProjectSaveComment
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AsodProjectSaveComment.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.projectId = "";
                object.blockId = "";
                object.comment = null;
            }
            if (message.projectId != null && message.hasOwnProperty("projectId"))
                object.projectId = message.projectId;
            if (message.blockId != null && message.hasOwnProperty("blockId"))
                object.blockId = message.blockId;
            if (message.comment != null && message.hasOwnProperty("comment"))
                object.comment = $root.asod_project.AsodProjectComment.toObject(message.comment, options);
            return object;
        };

        /**
         * Converts this AsodProjectSaveComment to JSON.
         * @function toJSON
         * @memberof asod_project.AsodProjectSaveComment
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AsodProjectSaveComment.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AsodProjectSaveComment
         * @function getTypeUrl
         * @memberof asod_project.AsodProjectSaveComment
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AsodProjectSaveComment.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/asod_project.AsodProjectSaveComment";
        };

        return AsodProjectSaveComment;
    })();

    asod_project.AsodProjectMeta = (function() {

        /**
         * Properties of an AsodProjectMeta.
         * @memberof asod_project
         * @interface IAsodProjectMeta
         * @property {string|null} [opNumber] AsodProjectMeta opNumber
         * @property {Array.<asod_members.IAsodMember>|null} [members] AsodProjectMeta members
         */

        /**
         * Constructs a new AsodProjectMeta.
         * @memberof asod_project
         * @classdesc Represents an AsodProjectMeta.
         * @implements IAsodProjectMeta
         * @constructor
         * @param {asod_project.IAsodProjectMeta=} [properties] Properties to set
         */
        function AsodProjectMeta(properties) {
            this.members = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AsodProjectMeta opNumber.
         * @member {string} opNumber
         * @memberof asod_project.AsodProjectMeta
         * @instance
         */
        AsodProjectMeta.prototype.opNumber = "";

        /**
         * AsodProjectMeta members.
         * @member {Array.<asod_members.IAsodMember>} members
         * @memberof asod_project.AsodProjectMeta
         * @instance
         */
        AsodProjectMeta.prototype.members = $util.emptyArray;

        /**
         * Creates a new AsodProjectMeta instance using the specified properties.
         * @function create
         * @memberof asod_project.AsodProjectMeta
         * @static
         * @param {asod_project.IAsodProjectMeta=} [properties] Properties to set
         * @returns {asod_project.AsodProjectMeta} AsodProjectMeta instance
         */
        AsodProjectMeta.create = function create(properties) {
            return new AsodProjectMeta(properties);
        };

        /**
         * Encodes the specified AsodProjectMeta message. Does not implicitly {@link asod_project.AsodProjectMeta.verify|verify} messages.
         * @function encode
         * @memberof asod_project.AsodProjectMeta
         * @static
         * @param {asod_project.IAsodProjectMeta} message AsodProjectMeta message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodProjectMeta.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.opNumber != null && Object.hasOwnProperty.call(message, "opNumber"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.opNumber);
            if (message.members != null && message.members.length)
                for (let i = 0; i < message.members.length; ++i)
                    $root.asod_members.AsodMember.encode(message.members[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified AsodProjectMeta message, length delimited. Does not implicitly {@link asod_project.AsodProjectMeta.verify|verify} messages.
         * @function encodeDelimited
         * @memberof asod_project.AsodProjectMeta
         * @static
         * @param {asod_project.IAsodProjectMeta} message AsodProjectMeta message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodProjectMeta.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AsodProjectMeta message from the specified reader or buffer.
         * @function decode
         * @memberof asod_project.AsodProjectMeta
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {asod_project.AsodProjectMeta} AsodProjectMeta
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodProjectMeta.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.asod_project.AsodProjectMeta();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.opNumber = reader.string();
                        break;
                    }
                case 2: {
                        if (!(message.members && message.members.length))
                            message.members = [];
                        message.members.push($root.asod_members.AsodMember.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AsodProjectMeta message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof asod_project.AsodProjectMeta
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {asod_project.AsodProjectMeta} AsodProjectMeta
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodProjectMeta.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AsodProjectMeta message.
         * @function verify
         * @memberof asod_project.AsodProjectMeta
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AsodProjectMeta.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.opNumber != null && message.hasOwnProperty("opNumber"))
                if (!$util.isString(message.opNumber))
                    return "opNumber: string expected";
            if (message.members != null && message.hasOwnProperty("members")) {
                if (!Array.isArray(message.members))
                    return "members: array expected";
                for (let i = 0; i < message.members.length; ++i) {
                    let error = $root.asod_members.AsodMember.verify(message.members[i]);
                    if (error)
                        return "members." + error;
                }
            }
            return null;
        };

        /**
         * Creates an AsodProjectMeta message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof asod_project.AsodProjectMeta
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {asod_project.AsodProjectMeta} AsodProjectMeta
         */
        AsodProjectMeta.fromObject = function fromObject(object) {
            if (object instanceof $root.asod_project.AsodProjectMeta)
                return object;
            let message = new $root.asod_project.AsodProjectMeta();
            if (object.opNumber != null)
                message.opNumber = String(object.opNumber);
            if (object.members) {
                if (!Array.isArray(object.members))
                    throw TypeError(".asod_project.AsodProjectMeta.members: array expected");
                message.members = [];
                for (let i = 0; i < object.members.length; ++i) {
                    if (typeof object.members[i] !== "object")
                        throw TypeError(".asod_project.AsodProjectMeta.members: object expected");
                    message.members[i] = $root.asod_members.AsodMember.fromObject(object.members[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from an AsodProjectMeta message. Also converts values to other types if specified.
         * @function toObject
         * @memberof asod_project.AsodProjectMeta
         * @static
         * @param {asod_project.AsodProjectMeta} message AsodProjectMeta
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AsodProjectMeta.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.members = [];
            if (options.defaults)
                object.opNumber = "";
            if (message.opNumber != null && message.hasOwnProperty("opNumber"))
                object.opNumber = message.opNumber;
            if (message.members && message.members.length) {
                object.members = [];
                for (let j = 0; j < message.members.length; ++j)
                    object.members[j] = $root.asod_members.AsodMember.toObject(message.members[j], options);
            }
            return object;
        };

        /**
         * Converts this AsodProjectMeta to JSON.
         * @function toJSON
         * @memberof asod_project.AsodProjectMeta
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AsodProjectMeta.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AsodProjectMeta
         * @function getTypeUrl
         * @memberof asod_project.AsodProjectMeta
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AsodProjectMeta.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/asod_project.AsodProjectMeta";
        };

        return AsodProjectMeta;
    })();

    asod_project.AsodProjectAudioBlock = (function() {

        /**
         * Properties of an AsodProjectAudioBlock.
         * @memberof asod_project
         * @interface IAsodProjectAudioBlock
         * @property {string|null} [id] AsodProjectAudioBlock id
         * @property {number|Long|null} [timestampStart] AsodProjectAudioBlock timestampStart
         * @property {number|Long|null} [timestampEnd] AsodProjectAudioBlock timestampEnd
         * @property {Array.<asod_project.IAsodProjectComment>|null} [comments] AsodProjectAudioBlock comments
         * @property {asod_audio.IAsodAudio|null} [audio] AsodProjectAudioBlock audio
         */

        /**
         * Constructs a new AsodProjectAudioBlock.
         * @memberof asod_project
         * @classdesc Represents an AsodProjectAudioBlock.
         * @implements IAsodProjectAudioBlock
         * @constructor
         * @param {asod_project.IAsodProjectAudioBlock=} [properties] Properties to set
         */
        function AsodProjectAudioBlock(properties) {
            this.comments = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AsodProjectAudioBlock id.
         * @member {string} id
         * @memberof asod_project.AsodProjectAudioBlock
         * @instance
         */
        AsodProjectAudioBlock.prototype.id = "";

        /**
         * AsodProjectAudioBlock timestampStart.
         * @member {number|Long} timestampStart
         * @memberof asod_project.AsodProjectAudioBlock
         * @instance
         */
        AsodProjectAudioBlock.prototype.timestampStart = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * AsodProjectAudioBlock timestampEnd.
         * @member {number|Long} timestampEnd
         * @memberof asod_project.AsodProjectAudioBlock
         * @instance
         */
        AsodProjectAudioBlock.prototype.timestampEnd = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * AsodProjectAudioBlock comments.
         * @member {Array.<asod_project.IAsodProjectComment>} comments
         * @memberof asod_project.AsodProjectAudioBlock
         * @instance
         */
        AsodProjectAudioBlock.prototype.comments = $util.emptyArray;

        /**
         * AsodProjectAudioBlock audio.
         * @member {asod_audio.IAsodAudio|null|undefined} audio
         * @memberof asod_project.AsodProjectAudioBlock
         * @instance
         */
        AsodProjectAudioBlock.prototype.audio = null;

        /**
         * Creates a new AsodProjectAudioBlock instance using the specified properties.
         * @function create
         * @memberof asod_project.AsodProjectAudioBlock
         * @static
         * @param {asod_project.IAsodProjectAudioBlock=} [properties] Properties to set
         * @returns {asod_project.AsodProjectAudioBlock} AsodProjectAudioBlock instance
         */
        AsodProjectAudioBlock.create = function create(properties) {
            return new AsodProjectAudioBlock(properties);
        };

        /**
         * Encodes the specified AsodProjectAudioBlock message. Does not implicitly {@link asod_project.AsodProjectAudioBlock.verify|verify} messages.
         * @function encode
         * @memberof asod_project.AsodProjectAudioBlock
         * @static
         * @param {asod_project.IAsodProjectAudioBlock} message AsodProjectAudioBlock message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodProjectAudioBlock.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.timestampStart != null && Object.hasOwnProperty.call(message, "timestampStart"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.timestampStart);
            if (message.timestampEnd != null && Object.hasOwnProperty.call(message, "timestampEnd"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.timestampEnd);
            if (message.comments != null && message.comments.length)
                for (let i = 0; i < message.comments.length; ++i)
                    $root.asod_project.AsodProjectComment.encode(message.comments[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.audio != null && Object.hasOwnProperty.call(message, "audio"))
                $root.asod_audio.AsodAudio.encode(message.audio, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified AsodProjectAudioBlock message, length delimited. Does not implicitly {@link asod_project.AsodProjectAudioBlock.verify|verify} messages.
         * @function encodeDelimited
         * @memberof asod_project.AsodProjectAudioBlock
         * @static
         * @param {asod_project.IAsodProjectAudioBlock} message AsodProjectAudioBlock message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodProjectAudioBlock.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AsodProjectAudioBlock message from the specified reader or buffer.
         * @function decode
         * @memberof asod_project.AsodProjectAudioBlock
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {asod_project.AsodProjectAudioBlock} AsodProjectAudioBlock
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodProjectAudioBlock.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.asod_project.AsodProjectAudioBlock();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 2: {
                        message.timestampStart = reader.uint64();
                        break;
                    }
                case 3: {
                        message.timestampEnd = reader.uint64();
                        break;
                    }
                case 4: {
                        if (!(message.comments && message.comments.length))
                            message.comments = [];
                        message.comments.push($root.asod_project.AsodProjectComment.decode(reader, reader.uint32()));
                        break;
                    }
                case 5: {
                        message.audio = $root.asod_audio.AsodAudio.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AsodProjectAudioBlock message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof asod_project.AsodProjectAudioBlock
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {asod_project.AsodProjectAudioBlock} AsodProjectAudioBlock
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodProjectAudioBlock.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AsodProjectAudioBlock message.
         * @function verify
         * @memberof asod_project.AsodProjectAudioBlock
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AsodProjectAudioBlock.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.timestampStart != null && message.hasOwnProperty("timestampStart"))
                if (!$util.isInteger(message.timestampStart) && !(message.timestampStart && $util.isInteger(message.timestampStart.low) && $util.isInteger(message.timestampStart.high)))
                    return "timestampStart: integer|Long expected";
            if (message.timestampEnd != null && message.hasOwnProperty("timestampEnd"))
                if (!$util.isInteger(message.timestampEnd) && !(message.timestampEnd && $util.isInteger(message.timestampEnd.low) && $util.isInteger(message.timestampEnd.high)))
                    return "timestampEnd: integer|Long expected";
            if (message.comments != null && message.hasOwnProperty("comments")) {
                if (!Array.isArray(message.comments))
                    return "comments: array expected";
                for (let i = 0; i < message.comments.length; ++i) {
                    let error = $root.asod_project.AsodProjectComment.verify(message.comments[i]);
                    if (error)
                        return "comments." + error;
                }
            }
            if (message.audio != null && message.hasOwnProperty("audio")) {
                let error = $root.asod_audio.AsodAudio.verify(message.audio);
                if (error)
                    return "audio." + error;
            }
            return null;
        };

        /**
         * Creates an AsodProjectAudioBlock message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof asod_project.AsodProjectAudioBlock
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {asod_project.AsodProjectAudioBlock} AsodProjectAudioBlock
         */
        AsodProjectAudioBlock.fromObject = function fromObject(object) {
            if (object instanceof $root.asod_project.AsodProjectAudioBlock)
                return object;
            let message = new $root.asod_project.AsodProjectAudioBlock();
            if (object.id != null)
                message.id = String(object.id);
            if (object.timestampStart != null)
                if ($util.Long)
                    (message.timestampStart = $util.Long.fromValue(object.timestampStart)).unsigned = true;
                else if (typeof object.timestampStart === "string")
                    message.timestampStart = parseInt(object.timestampStart, 10);
                else if (typeof object.timestampStart === "number")
                    message.timestampStart = object.timestampStart;
                else if (typeof object.timestampStart === "object")
                    message.timestampStart = new $util.LongBits(object.timestampStart.low >>> 0, object.timestampStart.high >>> 0).toNumber(true);
            if (object.timestampEnd != null)
                if ($util.Long)
                    (message.timestampEnd = $util.Long.fromValue(object.timestampEnd)).unsigned = true;
                else if (typeof object.timestampEnd === "string")
                    message.timestampEnd = parseInt(object.timestampEnd, 10);
                else if (typeof object.timestampEnd === "number")
                    message.timestampEnd = object.timestampEnd;
                else if (typeof object.timestampEnd === "object")
                    message.timestampEnd = new $util.LongBits(object.timestampEnd.low >>> 0, object.timestampEnd.high >>> 0).toNumber(true);
            if (object.comments) {
                if (!Array.isArray(object.comments))
                    throw TypeError(".asod_project.AsodProjectAudioBlock.comments: array expected");
                message.comments = [];
                for (let i = 0; i < object.comments.length; ++i) {
                    if (typeof object.comments[i] !== "object")
                        throw TypeError(".asod_project.AsodProjectAudioBlock.comments: object expected");
                    message.comments[i] = $root.asod_project.AsodProjectComment.fromObject(object.comments[i]);
                }
            }
            if (object.audio != null) {
                if (typeof object.audio !== "object")
                    throw TypeError(".asod_project.AsodProjectAudioBlock.audio: object expected");
                message.audio = $root.asod_audio.AsodAudio.fromObject(object.audio);
            }
            return message;
        };

        /**
         * Creates a plain object from an AsodProjectAudioBlock message. Also converts values to other types if specified.
         * @function toObject
         * @memberof asod_project.AsodProjectAudioBlock
         * @static
         * @param {asod_project.AsodProjectAudioBlock} message AsodProjectAudioBlock
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AsodProjectAudioBlock.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.comments = [];
            if (options.defaults) {
                object.id = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.timestampStart = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.timestampStart = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.timestampEnd = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.timestampEnd = options.longs === String ? "0" : 0;
                object.audio = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.timestampStart != null && message.hasOwnProperty("timestampStart"))
                if (typeof message.timestampStart === "number")
                    object.timestampStart = options.longs === String ? String(message.timestampStart) : message.timestampStart;
                else
                    object.timestampStart = options.longs === String ? $util.Long.prototype.toString.call(message.timestampStart) : options.longs === Number ? new $util.LongBits(message.timestampStart.low >>> 0, message.timestampStart.high >>> 0).toNumber(true) : message.timestampStart;
            if (message.timestampEnd != null && message.hasOwnProperty("timestampEnd"))
                if (typeof message.timestampEnd === "number")
                    object.timestampEnd = options.longs === String ? String(message.timestampEnd) : message.timestampEnd;
                else
                    object.timestampEnd = options.longs === String ? $util.Long.prototype.toString.call(message.timestampEnd) : options.longs === Number ? new $util.LongBits(message.timestampEnd.low >>> 0, message.timestampEnd.high >>> 0).toNumber(true) : message.timestampEnd;
            if (message.comments && message.comments.length) {
                object.comments = [];
                for (let j = 0; j < message.comments.length; ++j)
                    object.comments[j] = $root.asod_project.AsodProjectComment.toObject(message.comments[j], options);
            }
            if (message.audio != null && message.hasOwnProperty("audio"))
                object.audio = $root.asod_audio.AsodAudio.toObject(message.audio, options);
            return object;
        };

        /**
         * Converts this AsodProjectAudioBlock to JSON.
         * @function toJSON
         * @memberof asod_project.AsodProjectAudioBlock
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AsodProjectAudioBlock.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AsodProjectAudioBlock
         * @function getTypeUrl
         * @memberof asod_project.AsodProjectAudioBlock
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AsodProjectAudioBlock.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/asod_project.AsodProjectAudioBlock";
        };

        return AsodProjectAudioBlock;
    })();

    asod_project.AsodProjectComment = (function() {

        /**
         * Properties of an AsodProjectComment.
         * @memberof asod_project
         * @interface IAsodProjectComment
         * @property {string|null} [id] AsodProjectComment id
         * @property {number|Long|null} [timestamp] AsodProjectComment timestamp
         * @property {asod_members.IAsodMember|null} [author] AsodProjectComment author
         * @property {boolean|null} ["public"] AsodProjectComment public
         * @property {string|null} [body] AsodProjectComment body
         */

        /**
         * Constructs a new AsodProjectComment.
         * @memberof asod_project
         * @classdesc Represents an AsodProjectComment.
         * @implements IAsodProjectComment
         * @constructor
         * @param {asod_project.IAsodProjectComment=} [properties] Properties to set
         */
        function AsodProjectComment(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AsodProjectComment id.
         * @member {string} id
         * @memberof asod_project.AsodProjectComment
         * @instance
         */
        AsodProjectComment.prototype.id = "";

        /**
         * AsodProjectComment timestamp.
         * @member {number|Long} timestamp
         * @memberof asod_project.AsodProjectComment
         * @instance
         */
        AsodProjectComment.prototype.timestamp = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * AsodProjectComment author.
         * @member {asod_members.IAsodMember|null|undefined} author
         * @memberof asod_project.AsodProjectComment
         * @instance
         */
        AsodProjectComment.prototype.author = null;

        /**
         * AsodProjectComment public.
         * @member {boolean} public
         * @memberof asod_project.AsodProjectComment
         * @instance
         */
        AsodProjectComment.prototype["public"] = false;

        /**
         * AsodProjectComment body.
         * @member {string} body
         * @memberof asod_project.AsodProjectComment
         * @instance
         */
        AsodProjectComment.prototype.body = "";

        /**
         * Creates a new AsodProjectComment instance using the specified properties.
         * @function create
         * @memberof asod_project.AsodProjectComment
         * @static
         * @param {asod_project.IAsodProjectComment=} [properties] Properties to set
         * @returns {asod_project.AsodProjectComment} AsodProjectComment instance
         */
        AsodProjectComment.create = function create(properties) {
            return new AsodProjectComment(properties);
        };

        /**
         * Encodes the specified AsodProjectComment message. Does not implicitly {@link asod_project.AsodProjectComment.verify|verify} messages.
         * @function encode
         * @memberof asod_project.AsodProjectComment
         * @static
         * @param {asod_project.IAsodProjectComment} message AsodProjectComment message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodProjectComment.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.timestamp != null && Object.hasOwnProperty.call(message, "timestamp"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.timestamp);
            if (message.author != null && Object.hasOwnProperty.call(message, "author"))
                $root.asod_members.AsodMember.encode(message.author, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message["public"] != null && Object.hasOwnProperty.call(message, "public"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message["public"]);
            if (message.body != null && Object.hasOwnProperty.call(message, "body"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.body);
            return writer;
        };

        /**
         * Encodes the specified AsodProjectComment message, length delimited. Does not implicitly {@link asod_project.AsodProjectComment.verify|verify} messages.
         * @function encodeDelimited
         * @memberof asod_project.AsodProjectComment
         * @static
         * @param {asod_project.IAsodProjectComment} message AsodProjectComment message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodProjectComment.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AsodProjectComment message from the specified reader or buffer.
         * @function decode
         * @memberof asod_project.AsodProjectComment
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {asod_project.AsodProjectComment} AsodProjectComment
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodProjectComment.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.asod_project.AsodProjectComment();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 2: {
                        message.timestamp = reader.uint64();
                        break;
                    }
                case 3: {
                        message.author = $root.asod_members.AsodMember.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message["public"] = reader.bool();
                        break;
                    }
                case 5: {
                        message.body = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AsodProjectComment message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof asod_project.AsodProjectComment
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {asod_project.AsodProjectComment} AsodProjectComment
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodProjectComment.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AsodProjectComment message.
         * @function verify
         * @memberof asod_project.AsodProjectComment
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AsodProjectComment.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                if (!$util.isInteger(message.timestamp) && !(message.timestamp && $util.isInteger(message.timestamp.low) && $util.isInteger(message.timestamp.high)))
                    return "timestamp: integer|Long expected";
            if (message.author != null && message.hasOwnProperty("author")) {
                let error = $root.asod_members.AsodMember.verify(message.author);
                if (error)
                    return "author." + error;
            }
            if (message["public"] != null && message.hasOwnProperty("public"))
                if (typeof message["public"] !== "boolean")
                    return "public: boolean expected";
            if (message.body != null && message.hasOwnProperty("body"))
                if (!$util.isString(message.body))
                    return "body: string expected";
            return null;
        };

        /**
         * Creates an AsodProjectComment message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof asod_project.AsodProjectComment
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {asod_project.AsodProjectComment} AsodProjectComment
         */
        AsodProjectComment.fromObject = function fromObject(object) {
            if (object instanceof $root.asod_project.AsodProjectComment)
                return object;
            let message = new $root.asod_project.AsodProjectComment();
            if (object.id != null)
                message.id = String(object.id);
            if (object.timestamp != null)
                if ($util.Long)
                    (message.timestamp = $util.Long.fromValue(object.timestamp)).unsigned = true;
                else if (typeof object.timestamp === "string")
                    message.timestamp = parseInt(object.timestamp, 10);
                else if (typeof object.timestamp === "number")
                    message.timestamp = object.timestamp;
                else if (typeof object.timestamp === "object")
                    message.timestamp = new $util.LongBits(object.timestamp.low >>> 0, object.timestamp.high >>> 0).toNumber(true);
            if (object.author != null) {
                if (typeof object.author !== "object")
                    throw TypeError(".asod_project.AsodProjectComment.author: object expected");
                message.author = $root.asod_members.AsodMember.fromObject(object.author);
            }
            if (object["public"] != null)
                message["public"] = Boolean(object["public"]);
            if (object.body != null)
                message.body = String(object.body);
            return message;
        };

        /**
         * Creates a plain object from an AsodProjectComment message. Also converts values to other types if specified.
         * @function toObject
         * @memberof asod_project.AsodProjectComment
         * @static
         * @param {asod_project.AsodProjectComment} message AsodProjectComment
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AsodProjectComment.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.id = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.timestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.timestamp = options.longs === String ? "0" : 0;
                object.author = null;
                object["public"] = false;
                object.body = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                if (typeof message.timestamp === "number")
                    object.timestamp = options.longs === String ? String(message.timestamp) : message.timestamp;
                else
                    object.timestamp = options.longs === String ? $util.Long.prototype.toString.call(message.timestamp) : options.longs === Number ? new $util.LongBits(message.timestamp.low >>> 0, message.timestamp.high >>> 0).toNumber(true) : message.timestamp;
            if (message.author != null && message.hasOwnProperty("author"))
                object.author = $root.asod_members.AsodMember.toObject(message.author, options);
            if (message["public"] != null && message.hasOwnProperty("public"))
                object["public"] = message["public"];
            if (message.body != null && message.hasOwnProperty("body"))
                object.body = message.body;
            return object;
        };

        /**
         * Converts this AsodProjectComment to JSON.
         * @function toJSON
         * @memberof asod_project.AsodProjectComment
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AsodProjectComment.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AsodProjectComment
         * @function getTypeUrl
         * @memberof asod_project.AsodProjectComment
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AsodProjectComment.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/asod_project.AsodProjectComment";
        };

        return AsodProjectComment;
    })();

    return asod_project;
})();

export const asod_members = $root.asod_members = (() => {

    /**
     * Namespace asod_members.
     * @exports asod_members
     * @namespace
     */
    const asod_members = {};

    asod_members.SaveAsodMember = (function() {

        /**
         * Properties of a SaveAsodMember.
         * @memberof asod_members
         * @interface ISaveAsodMember
         * @property {string|null} [sessionId] SaveAsodMember sessionId
         * @property {asod_members.IAsodMember|null} [member] SaveAsodMember member
         */

        /**
         * Constructs a new SaveAsodMember.
         * @memberof asod_members
         * @classdesc Represents a SaveAsodMember.
         * @implements ISaveAsodMember
         * @constructor
         * @param {asod_members.ISaveAsodMember=} [properties] Properties to set
         */
        function SaveAsodMember(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SaveAsodMember sessionId.
         * @member {string} sessionId
         * @memberof asod_members.SaveAsodMember
         * @instance
         */
        SaveAsodMember.prototype.sessionId = "";

        /**
         * SaveAsodMember member.
         * @member {asod_members.IAsodMember|null|undefined} member
         * @memberof asod_members.SaveAsodMember
         * @instance
         */
        SaveAsodMember.prototype.member = null;

        /**
         * Creates a new SaveAsodMember instance using the specified properties.
         * @function create
         * @memberof asod_members.SaveAsodMember
         * @static
         * @param {asod_members.ISaveAsodMember=} [properties] Properties to set
         * @returns {asod_members.SaveAsodMember} SaveAsodMember instance
         */
        SaveAsodMember.create = function create(properties) {
            return new SaveAsodMember(properties);
        };

        /**
         * Encodes the specified SaveAsodMember message. Does not implicitly {@link asod_members.SaveAsodMember.verify|verify} messages.
         * @function encode
         * @memberof asod_members.SaveAsodMember
         * @static
         * @param {asod_members.ISaveAsodMember} message SaveAsodMember message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SaveAsodMember.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sessionId != null && Object.hasOwnProperty.call(message, "sessionId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.sessionId);
            if (message.member != null && Object.hasOwnProperty.call(message, "member"))
                $root.asod_members.AsodMember.encode(message.member, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified SaveAsodMember message, length delimited. Does not implicitly {@link asod_members.SaveAsodMember.verify|verify} messages.
         * @function encodeDelimited
         * @memberof asod_members.SaveAsodMember
         * @static
         * @param {asod_members.ISaveAsodMember} message SaveAsodMember message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SaveAsodMember.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SaveAsodMember message from the specified reader or buffer.
         * @function decode
         * @memberof asod_members.SaveAsodMember
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {asod_members.SaveAsodMember} SaveAsodMember
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SaveAsodMember.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.asod_members.SaveAsodMember();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sessionId = reader.string();
                        break;
                    }
                case 2: {
                        message.member = $root.asod_members.AsodMember.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SaveAsodMember message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof asod_members.SaveAsodMember
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {asod_members.SaveAsodMember} SaveAsodMember
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SaveAsodMember.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SaveAsodMember message.
         * @function verify
         * @memberof asod_members.SaveAsodMember
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SaveAsodMember.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                if (!$util.isString(message.sessionId))
                    return "sessionId: string expected";
            if (message.member != null && message.hasOwnProperty("member")) {
                let error = $root.asod_members.AsodMember.verify(message.member);
                if (error)
                    return "member." + error;
            }
            return null;
        };

        /**
         * Creates a SaveAsodMember message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof asod_members.SaveAsodMember
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {asod_members.SaveAsodMember} SaveAsodMember
         */
        SaveAsodMember.fromObject = function fromObject(object) {
            if (object instanceof $root.asod_members.SaveAsodMember)
                return object;
            let message = new $root.asod_members.SaveAsodMember();
            if (object.sessionId != null)
                message.sessionId = String(object.sessionId);
            if (object.member != null) {
                if (typeof object.member !== "object")
                    throw TypeError(".asod_members.SaveAsodMember.member: object expected");
                message.member = $root.asod_members.AsodMember.fromObject(object.member);
            }
            return message;
        };

        /**
         * Creates a plain object from a SaveAsodMember message. Also converts values to other types if specified.
         * @function toObject
         * @memberof asod_members.SaveAsodMember
         * @static
         * @param {asod_members.SaveAsodMember} message SaveAsodMember
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SaveAsodMember.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.sessionId = "";
                object.member = null;
            }
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                object.sessionId = message.sessionId;
            if (message.member != null && message.hasOwnProperty("member"))
                object.member = $root.asod_members.AsodMember.toObject(message.member, options);
            return object;
        };

        /**
         * Converts this SaveAsodMember to JSON.
         * @function toJSON
         * @memberof asod_members.SaveAsodMember
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SaveAsodMember.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SaveAsodMember
         * @function getTypeUrl
         * @memberof asod_members.SaveAsodMember
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SaveAsodMember.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/asod_members.SaveAsodMember";
        };

        return SaveAsodMember;
    })();

    asod_members.AsodMember = (function() {

        /**
         * Properties of an AsodMember.
         * @memberof asod_members
         * @interface IAsodMember
         * @property {string|null} [id] AsodMember id
         * @property {string|null} [name] AsodMember name
         * @property {string|null} [taxId] AsodMember taxId
         * @property {asod_members.IAsodMemberRole|null} [role] AsodMember role
         * @property {asod_members.IAsodProjectMemberMeta|null} [projectMeta] AsodMember projectMeta
         */

        /**
         * Constructs a new AsodMember.
         * @memberof asod_members
         * @classdesc Represents an AsodMember.
         * @implements IAsodMember
         * @constructor
         * @param {asod_members.IAsodMember=} [properties] Properties to set
         */
        function AsodMember(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AsodMember id.
         * @member {string} id
         * @memberof asod_members.AsodMember
         * @instance
         */
        AsodMember.prototype.id = "";

        /**
         * AsodMember name.
         * @member {string} name
         * @memberof asod_members.AsodMember
         * @instance
         */
        AsodMember.prototype.name = "";

        /**
         * AsodMember taxId.
         * @member {string} taxId
         * @memberof asod_members.AsodMember
         * @instance
         */
        AsodMember.prototype.taxId = "";

        /**
         * AsodMember role.
         * @member {asod_members.IAsodMemberRole|null|undefined} role
         * @memberof asod_members.AsodMember
         * @instance
         */
        AsodMember.prototype.role = null;

        /**
         * AsodMember projectMeta.
         * @member {asod_members.IAsodProjectMemberMeta|null|undefined} projectMeta
         * @memberof asod_members.AsodMember
         * @instance
         */
        AsodMember.prototype.projectMeta = null;

        /**
         * Creates a new AsodMember instance using the specified properties.
         * @function create
         * @memberof asod_members.AsodMember
         * @static
         * @param {asod_members.IAsodMember=} [properties] Properties to set
         * @returns {asod_members.AsodMember} AsodMember instance
         */
        AsodMember.create = function create(properties) {
            return new AsodMember(properties);
        };

        /**
         * Encodes the specified AsodMember message. Does not implicitly {@link asod_members.AsodMember.verify|verify} messages.
         * @function encode
         * @memberof asod_members.AsodMember
         * @static
         * @param {asod_members.IAsodMember} message AsodMember message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodMember.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.taxId != null && Object.hasOwnProperty.call(message, "taxId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.taxId);
            if (message.role != null && Object.hasOwnProperty.call(message, "role"))
                $root.asod_members.AsodMemberRole.encode(message.role, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.projectMeta != null && Object.hasOwnProperty.call(message, "projectMeta"))
                $root.asod_members.AsodProjectMemberMeta.encode(message.projectMeta, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified AsodMember message, length delimited. Does not implicitly {@link asod_members.AsodMember.verify|verify} messages.
         * @function encodeDelimited
         * @memberof asod_members.AsodMember
         * @static
         * @param {asod_members.IAsodMember} message AsodMember message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodMember.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AsodMember message from the specified reader or buffer.
         * @function decode
         * @memberof asod_members.AsodMember
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {asod_members.AsodMember} AsodMember
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodMember.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.asod_members.AsodMember();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                case 3: {
                        message.taxId = reader.string();
                        break;
                    }
                case 4: {
                        message.role = $root.asod_members.AsodMemberRole.decode(reader, reader.uint32());
                        break;
                    }
                case 5: {
                        message.projectMeta = $root.asod_members.AsodProjectMemberMeta.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AsodMember message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof asod_members.AsodMember
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {asod_members.AsodMember} AsodMember
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodMember.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AsodMember message.
         * @function verify
         * @memberof asod_members.AsodMember
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AsodMember.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.taxId != null && message.hasOwnProperty("taxId"))
                if (!$util.isString(message.taxId))
                    return "taxId: string expected";
            if (message.role != null && message.hasOwnProperty("role")) {
                let error = $root.asod_members.AsodMemberRole.verify(message.role);
                if (error)
                    return "role." + error;
            }
            if (message.projectMeta != null && message.hasOwnProperty("projectMeta")) {
                let error = $root.asod_members.AsodProjectMemberMeta.verify(message.projectMeta);
                if (error)
                    return "projectMeta." + error;
            }
            return null;
        };

        /**
         * Creates an AsodMember message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof asod_members.AsodMember
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {asod_members.AsodMember} AsodMember
         */
        AsodMember.fromObject = function fromObject(object) {
            if (object instanceof $root.asod_members.AsodMember)
                return object;
            let message = new $root.asod_members.AsodMember();
            if (object.id != null)
                message.id = String(object.id);
            if (object.name != null)
                message.name = String(object.name);
            if (object.taxId != null)
                message.taxId = String(object.taxId);
            if (object.role != null) {
                if (typeof object.role !== "object")
                    throw TypeError(".asod_members.AsodMember.role: object expected");
                message.role = $root.asod_members.AsodMemberRole.fromObject(object.role);
            }
            if (object.projectMeta != null) {
                if (typeof object.projectMeta !== "object")
                    throw TypeError(".asod_members.AsodMember.projectMeta: object expected");
                message.projectMeta = $root.asod_members.AsodProjectMemberMeta.fromObject(object.projectMeta);
            }
            return message;
        };

        /**
         * Creates a plain object from an AsodMember message. Also converts values to other types if specified.
         * @function toObject
         * @memberof asod_members.AsodMember
         * @static
         * @param {asod_members.AsodMember} message AsodMember
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AsodMember.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.id = "";
                object.name = "";
                object.taxId = "";
                object.role = null;
                object.projectMeta = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.taxId != null && message.hasOwnProperty("taxId"))
                object.taxId = message.taxId;
            if (message.role != null && message.hasOwnProperty("role"))
                object.role = $root.asod_members.AsodMemberRole.toObject(message.role, options);
            if (message.projectMeta != null && message.hasOwnProperty("projectMeta"))
                object.projectMeta = $root.asod_members.AsodProjectMemberMeta.toObject(message.projectMeta, options);
            return object;
        };

        /**
         * Converts this AsodMember to JSON.
         * @function toJSON
         * @memberof asod_members.AsodMember
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AsodMember.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AsodMember
         * @function getTypeUrl
         * @memberof asod_members.AsodMember
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AsodMember.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/asod_members.AsodMember";
        };

        return AsodMember;
    })();

    asod_members.AsodProjectMemberMeta = (function() {

        /**
         * Properties of an AsodProjectMemberMeta.
         * @memberof asod_members
         * @interface IAsodProjectMemberMeta
         * @property {number|null} [audioChannel] AsodProjectMemberMeta audioChannel
         * @property {boolean|null} [accessGranted] AsodProjectMemberMeta accessGranted
         */

        /**
         * Constructs a new AsodProjectMemberMeta.
         * @memberof asod_members
         * @classdesc Represents an AsodProjectMemberMeta.
         * @implements IAsodProjectMemberMeta
         * @constructor
         * @param {asod_members.IAsodProjectMemberMeta=} [properties] Properties to set
         */
        function AsodProjectMemberMeta(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AsodProjectMemberMeta audioChannel.
         * @member {number} audioChannel
         * @memberof asod_members.AsodProjectMemberMeta
         * @instance
         */
        AsodProjectMemberMeta.prototype.audioChannel = 0;

        /**
         * AsodProjectMemberMeta accessGranted.
         * @member {boolean} accessGranted
         * @memberof asod_members.AsodProjectMemberMeta
         * @instance
         */
        AsodProjectMemberMeta.prototype.accessGranted = false;

        /**
         * Creates a new AsodProjectMemberMeta instance using the specified properties.
         * @function create
         * @memberof asod_members.AsodProjectMemberMeta
         * @static
         * @param {asod_members.IAsodProjectMemberMeta=} [properties] Properties to set
         * @returns {asod_members.AsodProjectMemberMeta} AsodProjectMemberMeta instance
         */
        AsodProjectMemberMeta.create = function create(properties) {
            return new AsodProjectMemberMeta(properties);
        };

        /**
         * Encodes the specified AsodProjectMemberMeta message. Does not implicitly {@link asod_members.AsodProjectMemberMeta.verify|verify} messages.
         * @function encode
         * @memberof asod_members.AsodProjectMemberMeta
         * @static
         * @param {asod_members.IAsodProjectMemberMeta} message AsodProjectMemberMeta message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodProjectMemberMeta.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.audioChannel != null && Object.hasOwnProperty.call(message, "audioChannel"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.audioChannel);
            if (message.accessGranted != null && Object.hasOwnProperty.call(message, "accessGranted"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.accessGranted);
            return writer;
        };

        /**
         * Encodes the specified AsodProjectMemberMeta message, length delimited. Does not implicitly {@link asod_members.AsodProjectMemberMeta.verify|verify} messages.
         * @function encodeDelimited
         * @memberof asod_members.AsodProjectMemberMeta
         * @static
         * @param {asod_members.IAsodProjectMemberMeta} message AsodProjectMemberMeta message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodProjectMemberMeta.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AsodProjectMemberMeta message from the specified reader or buffer.
         * @function decode
         * @memberof asod_members.AsodProjectMemberMeta
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {asod_members.AsodProjectMemberMeta} AsodProjectMemberMeta
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodProjectMemberMeta.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.asod_members.AsodProjectMemberMeta();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.audioChannel = reader.int32();
                        break;
                    }
                case 2: {
                        message.accessGranted = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AsodProjectMemberMeta message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof asod_members.AsodProjectMemberMeta
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {asod_members.AsodProjectMemberMeta} AsodProjectMemberMeta
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodProjectMemberMeta.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AsodProjectMemberMeta message.
         * @function verify
         * @memberof asod_members.AsodProjectMemberMeta
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AsodProjectMemberMeta.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.audioChannel != null && message.hasOwnProperty("audioChannel"))
                if (!$util.isInteger(message.audioChannel))
                    return "audioChannel: integer expected";
            if (message.accessGranted != null && message.hasOwnProperty("accessGranted"))
                if (typeof message.accessGranted !== "boolean")
                    return "accessGranted: boolean expected";
            return null;
        };

        /**
         * Creates an AsodProjectMemberMeta message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof asod_members.AsodProjectMemberMeta
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {asod_members.AsodProjectMemberMeta} AsodProjectMemberMeta
         */
        AsodProjectMemberMeta.fromObject = function fromObject(object) {
            if (object instanceof $root.asod_members.AsodProjectMemberMeta)
                return object;
            let message = new $root.asod_members.AsodProjectMemberMeta();
            if (object.audioChannel != null)
                message.audioChannel = object.audioChannel | 0;
            if (object.accessGranted != null)
                message.accessGranted = Boolean(object.accessGranted);
            return message;
        };

        /**
         * Creates a plain object from an AsodProjectMemberMeta message. Also converts values to other types if specified.
         * @function toObject
         * @memberof asod_members.AsodProjectMemberMeta
         * @static
         * @param {asod_members.AsodProjectMemberMeta} message AsodProjectMemberMeta
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AsodProjectMemberMeta.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.audioChannel = 0;
                object.accessGranted = false;
            }
            if (message.audioChannel != null && message.hasOwnProperty("audioChannel"))
                object.audioChannel = message.audioChannel;
            if (message.accessGranted != null && message.hasOwnProperty("accessGranted"))
                object.accessGranted = message.accessGranted;
            return object;
        };

        /**
         * Converts this AsodProjectMemberMeta to JSON.
         * @function toJSON
         * @memberof asod_members.AsodProjectMemberMeta
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AsodProjectMemberMeta.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AsodProjectMemberMeta
         * @function getTypeUrl
         * @memberof asod_members.AsodProjectMemberMeta
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AsodProjectMemberMeta.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/asod_members.AsodProjectMemberMeta";
        };

        return AsodProjectMemberMeta;
    })();

    asod_members.AsodMemberRole = (function() {

        /**
         * Properties of an AsodMemberRole.
         * @memberof asod_members
         * @interface IAsodMemberRole
         * @property {string|null} [id] AsodMemberRole id
         * @property {string|null} [name] AsodMemberRole name
         */

        /**
         * Constructs a new AsodMemberRole.
         * @memberof asod_members
         * @classdesc Represents an AsodMemberRole.
         * @implements IAsodMemberRole
         * @constructor
         * @param {asod_members.IAsodMemberRole=} [properties] Properties to set
         */
        function AsodMemberRole(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AsodMemberRole id.
         * @member {string} id
         * @memberof asod_members.AsodMemberRole
         * @instance
         */
        AsodMemberRole.prototype.id = "";

        /**
         * AsodMemberRole name.
         * @member {string} name
         * @memberof asod_members.AsodMemberRole
         * @instance
         */
        AsodMemberRole.prototype.name = "";

        /**
         * Creates a new AsodMemberRole instance using the specified properties.
         * @function create
         * @memberof asod_members.AsodMemberRole
         * @static
         * @param {asod_members.IAsodMemberRole=} [properties] Properties to set
         * @returns {asod_members.AsodMemberRole} AsodMemberRole instance
         */
        AsodMemberRole.create = function create(properties) {
            return new AsodMemberRole(properties);
        };

        /**
         * Encodes the specified AsodMemberRole message. Does not implicitly {@link asod_members.AsodMemberRole.verify|verify} messages.
         * @function encode
         * @memberof asod_members.AsodMemberRole
         * @static
         * @param {asod_members.IAsodMemberRole} message AsodMemberRole message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodMemberRole.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            return writer;
        };

        /**
         * Encodes the specified AsodMemberRole message, length delimited. Does not implicitly {@link asod_members.AsodMemberRole.verify|verify} messages.
         * @function encodeDelimited
         * @memberof asod_members.AsodMemberRole
         * @static
         * @param {asod_members.IAsodMemberRole} message AsodMemberRole message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodMemberRole.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AsodMemberRole message from the specified reader or buffer.
         * @function decode
         * @memberof asod_members.AsodMemberRole
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {asod_members.AsodMemberRole} AsodMemberRole
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodMemberRole.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.asod_members.AsodMemberRole();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AsodMemberRole message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof asod_members.AsodMemberRole
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {asod_members.AsodMemberRole} AsodMemberRole
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodMemberRole.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AsodMemberRole message.
         * @function verify
         * @memberof asod_members.AsodMemberRole
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AsodMemberRole.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            return null;
        };

        /**
         * Creates an AsodMemberRole message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof asod_members.AsodMemberRole
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {asod_members.AsodMemberRole} AsodMemberRole
         */
        AsodMemberRole.fromObject = function fromObject(object) {
            if (object instanceof $root.asod_members.AsodMemberRole)
                return object;
            let message = new $root.asod_members.AsodMemberRole();
            if (object.id != null)
                message.id = String(object.id);
            if (object.name != null)
                message.name = String(object.name);
            return message;
        };

        /**
         * Creates a plain object from an AsodMemberRole message. Also converts values to other types if specified.
         * @function toObject
         * @memberof asod_members.AsodMemberRole
         * @static
         * @param {asod_members.AsodMemberRole} message AsodMemberRole
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AsodMemberRole.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.id = "";
                object.name = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            return object;
        };

        /**
         * Converts this AsodMemberRole to JSON.
         * @function toJSON
         * @memberof asod_members.AsodMemberRole
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AsodMemberRole.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AsodMemberRole
         * @function getTypeUrl
         * @memberof asod_members.AsodMemberRole
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AsodMemberRole.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/asod_members.AsodMemberRole";
        };

        return AsodMemberRole;
    })();

    return asod_members;
})();

export const asod_audio = $root.asod_audio = (() => {

    /**
     * Namespace asod_audio.
     * @exports asod_audio
     * @namespace
     */
    const asod_audio = {};

    asod_audio.AsodAudio = (function() {

        /**
         * Properties of an AsodAudio.
         * @memberof asod_audio
         * @interface IAsodAudio
         * @property {string|null} [id] AsodAudio id
         * @property {Array.<asod_audio.IAsodAudioChunk>|null} [chunks] AsodAudio chunks
         * @property {Array.<number>|null} [waveform] AsodAudio waveform
         * @property {number|null} [nChannels] AsodAudio nChannels
         */

        /**
         * Constructs a new AsodAudio.
         * @memberof asod_audio
         * @classdesc Represents an AsodAudio.
         * @implements IAsodAudio
         * @constructor
         * @param {asod_audio.IAsodAudio=} [properties] Properties to set
         */
        function AsodAudio(properties) {
            this.chunks = [];
            this.waveform = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AsodAudio id.
         * @member {string} id
         * @memberof asod_audio.AsodAudio
         * @instance
         */
        AsodAudio.prototype.id = "";

        /**
         * AsodAudio chunks.
         * @member {Array.<asod_audio.IAsodAudioChunk>} chunks
         * @memberof asod_audio.AsodAudio
         * @instance
         */
        AsodAudio.prototype.chunks = $util.emptyArray;

        /**
         * AsodAudio waveform.
         * @member {Array.<number>} waveform
         * @memberof asod_audio.AsodAudio
         * @instance
         */
        AsodAudio.prototype.waveform = $util.emptyArray;

        /**
         * AsodAudio nChannels.
         * @member {number} nChannels
         * @memberof asod_audio.AsodAudio
         * @instance
         */
        AsodAudio.prototype.nChannels = 0;

        /**
         * Creates a new AsodAudio instance using the specified properties.
         * @function create
         * @memberof asod_audio.AsodAudio
         * @static
         * @param {asod_audio.IAsodAudio=} [properties] Properties to set
         * @returns {asod_audio.AsodAudio} AsodAudio instance
         */
        AsodAudio.create = function create(properties) {
            return new AsodAudio(properties);
        };

        /**
         * Encodes the specified AsodAudio message. Does not implicitly {@link asod_audio.AsodAudio.verify|verify} messages.
         * @function encode
         * @memberof asod_audio.AsodAudio
         * @static
         * @param {asod_audio.IAsodAudio} message AsodAudio message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodAudio.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.chunks != null && message.chunks.length)
                for (let i = 0; i < message.chunks.length; ++i)
                    $root.asod_audio.AsodAudioChunk.encode(message.chunks[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.waveform != null && message.waveform.length) {
                writer.uint32(/* id 3, wireType 2 =*/26).fork();
                for (let i = 0; i < message.waveform.length; ++i)
                    writer.float(message.waveform[i]);
                writer.ldelim();
            }
            if (message.nChannels != null && Object.hasOwnProperty.call(message, "nChannels"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.nChannels);
            return writer;
        };

        /**
         * Encodes the specified AsodAudio message, length delimited. Does not implicitly {@link asod_audio.AsodAudio.verify|verify} messages.
         * @function encodeDelimited
         * @memberof asod_audio.AsodAudio
         * @static
         * @param {asod_audio.IAsodAudio} message AsodAudio message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodAudio.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AsodAudio message from the specified reader or buffer.
         * @function decode
         * @memberof asod_audio.AsodAudio
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {asod_audio.AsodAudio} AsodAudio
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodAudio.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.asod_audio.AsodAudio();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 2: {
                        if (!(message.chunks && message.chunks.length))
                            message.chunks = [];
                        message.chunks.push($root.asod_audio.AsodAudioChunk.decode(reader, reader.uint32()));
                        break;
                    }
                case 3: {
                        if (!(message.waveform && message.waveform.length))
                            message.waveform = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.waveform.push(reader.float());
                        } else
                            message.waveform.push(reader.float());
                        break;
                    }
                case 4: {
                        message.nChannels = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AsodAudio message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof asod_audio.AsodAudio
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {asod_audio.AsodAudio} AsodAudio
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodAudio.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AsodAudio message.
         * @function verify
         * @memberof asod_audio.AsodAudio
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AsodAudio.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.chunks != null && message.hasOwnProperty("chunks")) {
                if (!Array.isArray(message.chunks))
                    return "chunks: array expected";
                for (let i = 0; i < message.chunks.length; ++i) {
                    let error = $root.asod_audio.AsodAudioChunk.verify(message.chunks[i]);
                    if (error)
                        return "chunks." + error;
                }
            }
            if (message.waveform != null && message.hasOwnProperty("waveform")) {
                if (!Array.isArray(message.waveform))
                    return "waveform: array expected";
                for (let i = 0; i < message.waveform.length; ++i)
                    if (typeof message.waveform[i] !== "number")
                        return "waveform: number[] expected";
            }
            if (message.nChannels != null && message.hasOwnProperty("nChannels"))
                if (!$util.isInteger(message.nChannels))
                    return "nChannels: integer expected";
            return null;
        };

        /**
         * Creates an AsodAudio message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof asod_audio.AsodAudio
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {asod_audio.AsodAudio} AsodAudio
         */
        AsodAudio.fromObject = function fromObject(object) {
            if (object instanceof $root.asod_audio.AsodAudio)
                return object;
            let message = new $root.asod_audio.AsodAudio();
            if (object.id != null)
                message.id = String(object.id);
            if (object.chunks) {
                if (!Array.isArray(object.chunks))
                    throw TypeError(".asod_audio.AsodAudio.chunks: array expected");
                message.chunks = [];
                for (let i = 0; i < object.chunks.length; ++i) {
                    if (typeof object.chunks[i] !== "object")
                        throw TypeError(".asod_audio.AsodAudio.chunks: object expected");
                    message.chunks[i] = $root.asod_audio.AsodAudioChunk.fromObject(object.chunks[i]);
                }
            }
            if (object.waveform) {
                if (!Array.isArray(object.waveform))
                    throw TypeError(".asod_audio.AsodAudio.waveform: array expected");
                message.waveform = [];
                for (let i = 0; i < object.waveform.length; ++i)
                    message.waveform[i] = Number(object.waveform[i]);
            }
            if (object.nChannels != null)
                message.nChannels = object.nChannels | 0;
            return message;
        };

        /**
         * Creates a plain object from an AsodAudio message. Also converts values to other types if specified.
         * @function toObject
         * @memberof asod_audio.AsodAudio
         * @static
         * @param {asod_audio.AsodAudio} message AsodAudio
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AsodAudio.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.chunks = [];
                object.waveform = [];
            }
            if (options.defaults) {
                object.id = "";
                object.nChannels = 0;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.chunks && message.chunks.length) {
                object.chunks = [];
                for (let j = 0; j < message.chunks.length; ++j)
                    object.chunks[j] = $root.asod_audio.AsodAudioChunk.toObject(message.chunks[j], options);
            }
            if (message.waveform && message.waveform.length) {
                object.waveform = [];
                for (let j = 0; j < message.waveform.length; ++j)
                    object.waveform[j] = options.json && !isFinite(message.waveform[j]) ? String(message.waveform[j]) : message.waveform[j];
            }
            if (message.nChannels != null && message.hasOwnProperty("nChannels"))
                object.nChannels = message.nChannels;
            return object;
        };

        /**
         * Converts this AsodAudio to JSON.
         * @function toJSON
         * @memberof asod_audio.AsodAudio
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AsodAudio.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AsodAudio
         * @function getTypeUrl
         * @memberof asod_audio.AsodAudio
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AsodAudio.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/asod_audio.AsodAudio";
        };

        return AsodAudio;
    })();

    asod_audio.AsodAudioChunk = (function() {

        /**
         * Properties of an AsodAudioChunk.
         * @memberof asod_audio
         * @interface IAsodAudioChunk
         * @property {string|null} [id] AsodAudioChunk id
         * @property {number|Long|null} [timestampStart] AsodAudioChunk timestampStart
         * @property {number|Long|null} [timestampEnd] AsodAudioChunk timestampEnd
         * @property {string|null} [src] AsodAudioChunk src
         * @property {asod_audio.AsodAudioChunkStatus|null} [status] AsodAudioChunk status
         */

        /**
         * Constructs a new AsodAudioChunk.
         * @memberof asod_audio
         * @classdesc Represents an AsodAudioChunk.
         * @implements IAsodAudioChunk
         * @constructor
         * @param {asod_audio.IAsodAudioChunk=} [properties] Properties to set
         */
        function AsodAudioChunk(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AsodAudioChunk id.
         * @member {string} id
         * @memberof asod_audio.AsodAudioChunk
         * @instance
         */
        AsodAudioChunk.prototype.id = "";

        /**
         * AsodAudioChunk timestampStart.
         * @member {number|Long} timestampStart
         * @memberof asod_audio.AsodAudioChunk
         * @instance
         */
        AsodAudioChunk.prototype.timestampStart = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * AsodAudioChunk timestampEnd.
         * @member {number|Long} timestampEnd
         * @memberof asod_audio.AsodAudioChunk
         * @instance
         */
        AsodAudioChunk.prototype.timestampEnd = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * AsodAudioChunk src.
         * @member {string} src
         * @memberof asod_audio.AsodAudioChunk
         * @instance
         */
        AsodAudioChunk.prototype.src = "";

        /**
         * AsodAudioChunk status.
         * @member {asod_audio.AsodAudioChunkStatus} status
         * @memberof asod_audio.AsodAudioChunk
         * @instance
         */
        AsodAudioChunk.prototype.status = 0;

        /**
         * Creates a new AsodAudioChunk instance using the specified properties.
         * @function create
         * @memberof asod_audio.AsodAudioChunk
         * @static
         * @param {asod_audio.IAsodAudioChunk=} [properties] Properties to set
         * @returns {asod_audio.AsodAudioChunk} AsodAudioChunk instance
         */
        AsodAudioChunk.create = function create(properties) {
            return new AsodAudioChunk(properties);
        };

        /**
         * Encodes the specified AsodAudioChunk message. Does not implicitly {@link asod_audio.AsodAudioChunk.verify|verify} messages.
         * @function encode
         * @memberof asod_audio.AsodAudioChunk
         * @static
         * @param {asod_audio.IAsodAudioChunk} message AsodAudioChunk message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodAudioChunk.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.timestampStart != null && Object.hasOwnProperty.call(message, "timestampStart"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.timestampStart);
            if (message.timestampEnd != null && Object.hasOwnProperty.call(message, "timestampEnd"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.timestampEnd);
            if (message.src != null && Object.hasOwnProperty.call(message, "src"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.src);
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.status);
            return writer;
        };

        /**
         * Encodes the specified AsodAudioChunk message, length delimited. Does not implicitly {@link asod_audio.AsodAudioChunk.verify|verify} messages.
         * @function encodeDelimited
         * @memberof asod_audio.AsodAudioChunk
         * @static
         * @param {asod_audio.IAsodAudioChunk} message AsodAudioChunk message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodAudioChunk.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AsodAudioChunk message from the specified reader or buffer.
         * @function decode
         * @memberof asod_audio.AsodAudioChunk
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {asod_audio.AsodAudioChunk} AsodAudioChunk
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodAudioChunk.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.asod_audio.AsodAudioChunk();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 2: {
                        message.timestampStart = reader.uint64();
                        break;
                    }
                case 3: {
                        message.timestampEnd = reader.uint64();
                        break;
                    }
                case 4: {
                        message.src = reader.string();
                        break;
                    }
                case 5: {
                        message.status = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AsodAudioChunk message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof asod_audio.AsodAudioChunk
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {asod_audio.AsodAudioChunk} AsodAudioChunk
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodAudioChunk.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AsodAudioChunk message.
         * @function verify
         * @memberof asod_audio.AsodAudioChunk
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AsodAudioChunk.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.timestampStart != null && message.hasOwnProperty("timestampStart"))
                if (!$util.isInteger(message.timestampStart) && !(message.timestampStart && $util.isInteger(message.timestampStart.low) && $util.isInteger(message.timestampStart.high)))
                    return "timestampStart: integer|Long expected";
            if (message.timestampEnd != null && message.hasOwnProperty("timestampEnd"))
                if (!$util.isInteger(message.timestampEnd) && !(message.timestampEnd && $util.isInteger(message.timestampEnd.low) && $util.isInteger(message.timestampEnd.high)))
                    return "timestampEnd: integer|Long expected";
            if (message.src != null && message.hasOwnProperty("src"))
                if (!$util.isString(message.src))
                    return "src: string expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            return null;
        };

        /**
         * Creates an AsodAudioChunk message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof asod_audio.AsodAudioChunk
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {asod_audio.AsodAudioChunk} AsodAudioChunk
         */
        AsodAudioChunk.fromObject = function fromObject(object) {
            if (object instanceof $root.asod_audio.AsodAudioChunk)
                return object;
            let message = new $root.asod_audio.AsodAudioChunk();
            if (object.id != null)
                message.id = String(object.id);
            if (object.timestampStart != null)
                if ($util.Long)
                    (message.timestampStart = $util.Long.fromValue(object.timestampStart)).unsigned = true;
                else if (typeof object.timestampStart === "string")
                    message.timestampStart = parseInt(object.timestampStart, 10);
                else if (typeof object.timestampStart === "number")
                    message.timestampStart = object.timestampStart;
                else if (typeof object.timestampStart === "object")
                    message.timestampStart = new $util.LongBits(object.timestampStart.low >>> 0, object.timestampStart.high >>> 0).toNumber(true);
            if (object.timestampEnd != null)
                if ($util.Long)
                    (message.timestampEnd = $util.Long.fromValue(object.timestampEnd)).unsigned = true;
                else if (typeof object.timestampEnd === "string")
                    message.timestampEnd = parseInt(object.timestampEnd, 10);
                else if (typeof object.timestampEnd === "number")
                    message.timestampEnd = object.timestampEnd;
                else if (typeof object.timestampEnd === "object")
                    message.timestampEnd = new $util.LongBits(object.timestampEnd.low >>> 0, object.timestampEnd.high >>> 0).toNumber(true);
            if (object.src != null)
                message.src = String(object.src);
            switch (object.status) {
            default:
                if (typeof object.status === "number") {
                    message.status = object.status;
                    break;
                }
                break;
            case "aacs_recording":
            case 0:
                message.status = 0;
                break;
            case "aacs_conversion":
            case 1:
                message.status = 1;
                break;
            case "aacs_uploading":
            case 2:
                message.status = 2;
                break;
            case "aacs_uploaded":
            case 3:
                message.status = 3;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from an AsodAudioChunk message. Also converts values to other types if specified.
         * @function toObject
         * @memberof asod_audio.AsodAudioChunk
         * @static
         * @param {asod_audio.AsodAudioChunk} message AsodAudioChunk
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AsodAudioChunk.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.id = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.timestampStart = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.timestampStart = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.timestampEnd = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.timestampEnd = options.longs === String ? "0" : 0;
                object.src = "";
                object.status = options.enums === String ? "aacs_recording" : 0;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.timestampStart != null && message.hasOwnProperty("timestampStart"))
                if (typeof message.timestampStart === "number")
                    object.timestampStart = options.longs === String ? String(message.timestampStart) : message.timestampStart;
                else
                    object.timestampStart = options.longs === String ? $util.Long.prototype.toString.call(message.timestampStart) : options.longs === Number ? new $util.LongBits(message.timestampStart.low >>> 0, message.timestampStart.high >>> 0).toNumber(true) : message.timestampStart;
            if (message.timestampEnd != null && message.hasOwnProperty("timestampEnd"))
                if (typeof message.timestampEnd === "number")
                    object.timestampEnd = options.longs === String ? String(message.timestampEnd) : message.timestampEnd;
                else
                    object.timestampEnd = options.longs === String ? $util.Long.prototype.toString.call(message.timestampEnd) : options.longs === Number ? new $util.LongBits(message.timestampEnd.low >>> 0, message.timestampEnd.high >>> 0).toNumber(true) : message.timestampEnd;
            if (message.src != null && message.hasOwnProperty("src"))
                object.src = message.src;
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.asod_audio.AsodAudioChunkStatus[message.status] === undefined ? message.status : $root.asod_audio.AsodAudioChunkStatus[message.status] : message.status;
            return object;
        };

        /**
         * Converts this AsodAudioChunk to JSON.
         * @function toJSON
         * @memberof asod_audio.AsodAudioChunk
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AsodAudioChunk.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AsodAudioChunk
         * @function getTypeUrl
         * @memberof asod_audio.AsodAudioChunk
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AsodAudioChunk.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/asod_audio.AsodAudioChunk";
        };

        return AsodAudioChunk;
    })();

    /**
     * AsodAudioChunkStatus enum.
     * @name asod_audio.AsodAudioChunkStatus
     * @enum {number}
     * @property {number} aacs_recording=0 aacs_recording value
     * @property {number} aacs_conversion=1 aacs_conversion value
     * @property {number} aacs_uploading=2 aacs_uploading value
     * @property {number} aacs_uploaded=3 aacs_uploaded value
     */
    asod_audio.AsodAudioChunkStatus = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "aacs_recording"] = 0;
        values[valuesById[1] = "aacs_conversion"] = 1;
        values[valuesById[2] = "aacs_uploading"] = 2;
        values[valuesById[3] = "aacs_uploaded"] = 3;
        return values;
    })();

    return asod_audio;
})();

export const api_konstante = $root.api_konstante = (() => {

    /**
     * Namespace api_konstante.
     * @exports api_konstante
     * @namespace
     */
    const api_konstante = {};

    api_konstante.ApiKonstanteGetRoles = (function() {

        /**
         * Properties of an ApiKonstanteGetRoles.
         * @memberof api_konstante
         * @interface IApiKonstanteGetRoles
         */

        /**
         * Constructs a new ApiKonstanteGetRoles.
         * @memberof api_konstante
         * @classdesc Represents an ApiKonstanteGetRoles.
         * @implements IApiKonstanteGetRoles
         * @constructor
         * @param {api_konstante.IApiKonstanteGetRoles=} [properties] Properties to set
         */
        function ApiKonstanteGetRoles(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new ApiKonstanteGetRoles instance using the specified properties.
         * @function create
         * @memberof api_konstante.ApiKonstanteGetRoles
         * @static
         * @param {api_konstante.IApiKonstanteGetRoles=} [properties] Properties to set
         * @returns {api_konstante.ApiKonstanteGetRoles} ApiKonstanteGetRoles instance
         */
        ApiKonstanteGetRoles.create = function create(properties) {
            return new ApiKonstanteGetRoles(properties);
        };

        /**
         * Encodes the specified ApiKonstanteGetRoles message. Does not implicitly {@link api_konstante.ApiKonstanteGetRoles.verify|verify} messages.
         * @function encode
         * @memberof api_konstante.ApiKonstanteGetRoles
         * @static
         * @param {api_konstante.IApiKonstanteGetRoles} message ApiKonstanteGetRoles message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiKonstanteGetRoles.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified ApiKonstanteGetRoles message, length delimited. Does not implicitly {@link api_konstante.ApiKonstanteGetRoles.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_konstante.ApiKonstanteGetRoles
         * @static
         * @param {api_konstante.IApiKonstanteGetRoles} message ApiKonstanteGetRoles message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiKonstanteGetRoles.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiKonstanteGetRoles message from the specified reader or buffer.
         * @function decode
         * @memberof api_konstante.ApiKonstanteGetRoles
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_konstante.ApiKonstanteGetRoles} ApiKonstanteGetRoles
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiKonstanteGetRoles.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_konstante.ApiKonstanteGetRoles();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiKonstanteGetRoles message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_konstante.ApiKonstanteGetRoles
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_konstante.ApiKonstanteGetRoles} ApiKonstanteGetRoles
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiKonstanteGetRoles.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiKonstanteGetRoles message.
         * @function verify
         * @memberof api_konstante.ApiKonstanteGetRoles
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiKonstanteGetRoles.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an ApiKonstanteGetRoles message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_konstante.ApiKonstanteGetRoles
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_konstante.ApiKonstanteGetRoles} ApiKonstanteGetRoles
         */
        ApiKonstanteGetRoles.fromObject = function fromObject(object) {
            if (object instanceof $root.api_konstante.ApiKonstanteGetRoles)
                return object;
            return new $root.api_konstante.ApiKonstanteGetRoles();
        };

        /**
         * Creates a plain object from an ApiKonstanteGetRoles message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_konstante.ApiKonstanteGetRoles
         * @static
         * @param {api_konstante.ApiKonstanteGetRoles} message ApiKonstanteGetRoles
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiKonstanteGetRoles.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this ApiKonstanteGetRoles to JSON.
         * @function toJSON
         * @memberof api_konstante.ApiKonstanteGetRoles
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiKonstanteGetRoles.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiKonstanteGetRoles
         * @function getTypeUrl
         * @memberof api_konstante.ApiKonstanteGetRoles
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiKonstanteGetRoles.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_konstante.ApiKonstanteGetRoles";
        };

        return ApiKonstanteGetRoles;
    })();

    api_konstante.ApiKonstanteRoles = (function() {

        /**
         * Properties of an ApiKonstanteRoles.
         * @memberof api_konstante
         * @interface IApiKonstanteRoles
         * @property {Array.<api_konstante.IApiKonstanteRole>|null} [courtRoles] ApiKonstanteRoles courtRoles
         */

        /**
         * Constructs a new ApiKonstanteRoles.
         * @memberof api_konstante
         * @classdesc Represents an ApiKonstanteRoles.
         * @implements IApiKonstanteRoles
         * @constructor
         * @param {api_konstante.IApiKonstanteRoles=} [properties] Properties to set
         */
        function ApiKonstanteRoles(properties) {
            this.courtRoles = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiKonstanteRoles courtRoles.
         * @member {Array.<api_konstante.IApiKonstanteRole>} courtRoles
         * @memberof api_konstante.ApiKonstanteRoles
         * @instance
         */
        ApiKonstanteRoles.prototype.courtRoles = $util.emptyArray;

        /**
         * Creates a new ApiKonstanteRoles instance using the specified properties.
         * @function create
         * @memberof api_konstante.ApiKonstanteRoles
         * @static
         * @param {api_konstante.IApiKonstanteRoles=} [properties] Properties to set
         * @returns {api_konstante.ApiKonstanteRoles} ApiKonstanteRoles instance
         */
        ApiKonstanteRoles.create = function create(properties) {
            return new ApiKonstanteRoles(properties);
        };

        /**
         * Encodes the specified ApiKonstanteRoles message. Does not implicitly {@link api_konstante.ApiKonstanteRoles.verify|verify} messages.
         * @function encode
         * @memberof api_konstante.ApiKonstanteRoles
         * @static
         * @param {api_konstante.IApiKonstanteRoles} message ApiKonstanteRoles message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiKonstanteRoles.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.courtRoles != null && message.courtRoles.length)
                for (let i = 0; i < message.courtRoles.length; ++i)
                    $root.api_konstante.ApiKonstanteRole.encode(message.courtRoles[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ApiKonstanteRoles message, length delimited. Does not implicitly {@link api_konstante.ApiKonstanteRoles.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_konstante.ApiKonstanteRoles
         * @static
         * @param {api_konstante.IApiKonstanteRoles} message ApiKonstanteRoles message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiKonstanteRoles.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiKonstanteRoles message from the specified reader or buffer.
         * @function decode
         * @memberof api_konstante.ApiKonstanteRoles
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_konstante.ApiKonstanteRoles} ApiKonstanteRoles
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiKonstanteRoles.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_konstante.ApiKonstanteRoles();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.courtRoles && message.courtRoles.length))
                            message.courtRoles = [];
                        message.courtRoles.push($root.api_konstante.ApiKonstanteRole.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiKonstanteRoles message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_konstante.ApiKonstanteRoles
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_konstante.ApiKonstanteRoles} ApiKonstanteRoles
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiKonstanteRoles.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiKonstanteRoles message.
         * @function verify
         * @memberof api_konstante.ApiKonstanteRoles
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiKonstanteRoles.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.courtRoles != null && message.hasOwnProperty("courtRoles")) {
                if (!Array.isArray(message.courtRoles))
                    return "courtRoles: array expected";
                for (let i = 0; i < message.courtRoles.length; ++i) {
                    let error = $root.api_konstante.ApiKonstanteRole.verify(message.courtRoles[i]);
                    if (error)
                        return "courtRoles." + error;
                }
            }
            return null;
        };

        /**
         * Creates an ApiKonstanteRoles message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_konstante.ApiKonstanteRoles
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_konstante.ApiKonstanteRoles} ApiKonstanteRoles
         */
        ApiKonstanteRoles.fromObject = function fromObject(object) {
            if (object instanceof $root.api_konstante.ApiKonstanteRoles)
                return object;
            let message = new $root.api_konstante.ApiKonstanteRoles();
            if (object.courtRoles) {
                if (!Array.isArray(object.courtRoles))
                    throw TypeError(".api_konstante.ApiKonstanteRoles.courtRoles: array expected");
                message.courtRoles = [];
                for (let i = 0; i < object.courtRoles.length; ++i) {
                    if (typeof object.courtRoles[i] !== "object")
                        throw TypeError(".api_konstante.ApiKonstanteRoles.courtRoles: object expected");
                    message.courtRoles[i] = $root.api_konstante.ApiKonstanteRole.fromObject(object.courtRoles[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from an ApiKonstanteRoles message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_konstante.ApiKonstanteRoles
         * @static
         * @param {api_konstante.ApiKonstanteRoles} message ApiKonstanteRoles
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiKonstanteRoles.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.courtRoles = [];
            if (message.courtRoles && message.courtRoles.length) {
                object.courtRoles = [];
                for (let j = 0; j < message.courtRoles.length; ++j)
                    object.courtRoles[j] = $root.api_konstante.ApiKonstanteRole.toObject(message.courtRoles[j], options);
            }
            return object;
        };

        /**
         * Converts this ApiKonstanteRoles to JSON.
         * @function toJSON
         * @memberof api_konstante.ApiKonstanteRoles
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiKonstanteRoles.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiKonstanteRoles
         * @function getTypeUrl
         * @memberof api_konstante.ApiKonstanteRoles
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiKonstanteRoles.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_konstante.ApiKonstanteRoles";
        };

        return ApiKonstanteRoles;
    })();

    api_konstante.ApiKonstanteRole = (function() {

        /**
         * Properties of an ApiKonstanteRole.
         * @memberof api_konstante
         * @interface IApiKonstanteRole
         * @property {string|null} [id] ApiKonstanteRole id
         * @property {string|null} [title] ApiKonstanteRole title
         */

        /**
         * Constructs a new ApiKonstanteRole.
         * @memberof api_konstante
         * @classdesc Represents an ApiKonstanteRole.
         * @implements IApiKonstanteRole
         * @constructor
         * @param {api_konstante.IApiKonstanteRole=} [properties] Properties to set
         */
        function ApiKonstanteRole(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiKonstanteRole id.
         * @member {string} id
         * @memberof api_konstante.ApiKonstanteRole
         * @instance
         */
        ApiKonstanteRole.prototype.id = "";

        /**
         * ApiKonstanteRole title.
         * @member {string} title
         * @memberof api_konstante.ApiKonstanteRole
         * @instance
         */
        ApiKonstanteRole.prototype.title = "";

        /**
         * Creates a new ApiKonstanteRole instance using the specified properties.
         * @function create
         * @memberof api_konstante.ApiKonstanteRole
         * @static
         * @param {api_konstante.IApiKonstanteRole=} [properties] Properties to set
         * @returns {api_konstante.ApiKonstanteRole} ApiKonstanteRole instance
         */
        ApiKonstanteRole.create = function create(properties) {
            return new ApiKonstanteRole(properties);
        };

        /**
         * Encodes the specified ApiKonstanteRole message. Does not implicitly {@link api_konstante.ApiKonstanteRole.verify|verify} messages.
         * @function encode
         * @memberof api_konstante.ApiKonstanteRole
         * @static
         * @param {api_konstante.IApiKonstanteRole} message ApiKonstanteRole message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiKonstanteRole.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
            return writer;
        };

        /**
         * Encodes the specified ApiKonstanteRole message, length delimited. Does not implicitly {@link api_konstante.ApiKonstanteRole.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_konstante.ApiKonstanteRole
         * @static
         * @param {api_konstante.IApiKonstanteRole} message ApiKonstanteRole message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiKonstanteRole.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiKonstanteRole message from the specified reader or buffer.
         * @function decode
         * @memberof api_konstante.ApiKonstanteRole
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_konstante.ApiKonstanteRole} ApiKonstanteRole
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiKonstanteRole.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_konstante.ApiKonstanteRole();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 2: {
                        message.title = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiKonstanteRole message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_konstante.ApiKonstanteRole
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_konstante.ApiKonstanteRole} ApiKonstanteRole
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiKonstanteRole.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiKonstanteRole message.
         * @function verify
         * @memberof api_konstante.ApiKonstanteRole
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiKonstanteRole.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.title != null && message.hasOwnProperty("title"))
                if (!$util.isString(message.title))
                    return "title: string expected";
            return null;
        };

        /**
         * Creates an ApiKonstanteRole message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_konstante.ApiKonstanteRole
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_konstante.ApiKonstanteRole} ApiKonstanteRole
         */
        ApiKonstanteRole.fromObject = function fromObject(object) {
            if (object instanceof $root.api_konstante.ApiKonstanteRole)
                return object;
            let message = new $root.api_konstante.ApiKonstanteRole();
            if (object.id != null)
                message.id = String(object.id);
            if (object.title != null)
                message.title = String(object.title);
            return message;
        };

        /**
         * Creates a plain object from an ApiKonstanteRole message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_konstante.ApiKonstanteRole
         * @static
         * @param {api_konstante.ApiKonstanteRole} message ApiKonstanteRole
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiKonstanteRole.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.id = "";
                object.title = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.title != null && message.hasOwnProperty("title"))
                object.title = message.title;
            return object;
        };

        /**
         * Converts this ApiKonstanteRole to JSON.
         * @function toJSON
         * @memberof api_konstante.ApiKonstanteRole
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiKonstanteRole.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiKonstanteRole
         * @function getTypeUrl
         * @memberof api_konstante.ApiKonstanteRole
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiKonstanteRole.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_konstante.ApiKonstanteRole";
        };

        return ApiKonstanteRole;
    })();

    return api_konstante;
})();

export const api_deeplinks = $root.api_deeplinks = (() => {

    /**
     * Namespace api_deeplinks.
     * @exports api_deeplinks
     * @namespace
     */
    const api_deeplinks = {};

    api_deeplinks.ApiDeeplinksAddDeeplink = (function() {

        /**
         * Properties of an ApiDeeplinksAddDeeplink.
         * @memberof api_deeplinks
         * @interface IApiDeeplinksAddDeeplink
         * @property {string|null} [sessionId] ApiDeeplinksAddDeeplink sessionId
         * @property {string|null} [deeplinkId] ApiDeeplinksAddDeeplink deeplinkId
         * @property {string|null} [url] ApiDeeplinksAddDeeplink url
         * @property {string|null} [name] ApiDeeplinksAddDeeplink name
         * @property {boolean|null} [publiclyAccessible] ApiDeeplinksAddDeeplink publiclyAccessible
         * @property {number|Long|null} [segmentStart] ApiDeeplinksAddDeeplink segmentStart
         * @property {number|Long|null} [segmentEnd] ApiDeeplinksAddDeeplink segmentEnd
         */

        /**
         * Constructs a new ApiDeeplinksAddDeeplink.
         * @memberof api_deeplinks
         * @classdesc Represents an ApiDeeplinksAddDeeplink.
         * @implements IApiDeeplinksAddDeeplink
         * @constructor
         * @param {api_deeplinks.IApiDeeplinksAddDeeplink=} [properties] Properties to set
         */
        function ApiDeeplinksAddDeeplink(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiDeeplinksAddDeeplink sessionId.
         * @member {string} sessionId
         * @memberof api_deeplinks.ApiDeeplinksAddDeeplink
         * @instance
         */
        ApiDeeplinksAddDeeplink.prototype.sessionId = "";

        /**
         * ApiDeeplinksAddDeeplink deeplinkId.
         * @member {string} deeplinkId
         * @memberof api_deeplinks.ApiDeeplinksAddDeeplink
         * @instance
         */
        ApiDeeplinksAddDeeplink.prototype.deeplinkId = "";

        /**
         * ApiDeeplinksAddDeeplink url.
         * @member {string} url
         * @memberof api_deeplinks.ApiDeeplinksAddDeeplink
         * @instance
         */
        ApiDeeplinksAddDeeplink.prototype.url = "";

        /**
         * ApiDeeplinksAddDeeplink name.
         * @member {string} name
         * @memberof api_deeplinks.ApiDeeplinksAddDeeplink
         * @instance
         */
        ApiDeeplinksAddDeeplink.prototype.name = "";

        /**
         * ApiDeeplinksAddDeeplink publiclyAccessible.
         * @member {boolean} publiclyAccessible
         * @memberof api_deeplinks.ApiDeeplinksAddDeeplink
         * @instance
         */
        ApiDeeplinksAddDeeplink.prototype.publiclyAccessible = false;

        /**
         * ApiDeeplinksAddDeeplink segmentStart.
         * @member {number|Long} segmentStart
         * @memberof api_deeplinks.ApiDeeplinksAddDeeplink
         * @instance
         */
        ApiDeeplinksAddDeeplink.prototype.segmentStart = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ApiDeeplinksAddDeeplink segmentEnd.
         * @member {number|Long} segmentEnd
         * @memberof api_deeplinks.ApiDeeplinksAddDeeplink
         * @instance
         */
        ApiDeeplinksAddDeeplink.prototype.segmentEnd = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new ApiDeeplinksAddDeeplink instance using the specified properties.
         * @function create
         * @memberof api_deeplinks.ApiDeeplinksAddDeeplink
         * @static
         * @param {api_deeplinks.IApiDeeplinksAddDeeplink=} [properties] Properties to set
         * @returns {api_deeplinks.ApiDeeplinksAddDeeplink} ApiDeeplinksAddDeeplink instance
         */
        ApiDeeplinksAddDeeplink.create = function create(properties) {
            return new ApiDeeplinksAddDeeplink(properties);
        };

        /**
         * Encodes the specified ApiDeeplinksAddDeeplink message. Does not implicitly {@link api_deeplinks.ApiDeeplinksAddDeeplink.verify|verify} messages.
         * @function encode
         * @memberof api_deeplinks.ApiDeeplinksAddDeeplink
         * @static
         * @param {api_deeplinks.IApiDeeplinksAddDeeplink} message ApiDeeplinksAddDeeplink message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiDeeplinksAddDeeplink.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sessionId != null && Object.hasOwnProperty.call(message, "sessionId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.sessionId);
            if (message.deeplinkId != null && Object.hasOwnProperty.call(message, "deeplinkId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.deeplinkId);
            if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.url);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.name);
            if (message.publiclyAccessible != null && Object.hasOwnProperty.call(message, "publiclyAccessible"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.publiclyAccessible);
            if (message.segmentStart != null && Object.hasOwnProperty.call(message, "segmentStart"))
                writer.uint32(/* id 6, wireType 0 =*/48).uint64(message.segmentStart);
            if (message.segmentEnd != null && Object.hasOwnProperty.call(message, "segmentEnd"))
                writer.uint32(/* id 7, wireType 0 =*/56).uint64(message.segmentEnd);
            return writer;
        };

        /**
         * Encodes the specified ApiDeeplinksAddDeeplink message, length delimited. Does not implicitly {@link api_deeplinks.ApiDeeplinksAddDeeplink.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_deeplinks.ApiDeeplinksAddDeeplink
         * @static
         * @param {api_deeplinks.IApiDeeplinksAddDeeplink} message ApiDeeplinksAddDeeplink message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiDeeplinksAddDeeplink.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiDeeplinksAddDeeplink message from the specified reader or buffer.
         * @function decode
         * @memberof api_deeplinks.ApiDeeplinksAddDeeplink
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_deeplinks.ApiDeeplinksAddDeeplink} ApiDeeplinksAddDeeplink
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiDeeplinksAddDeeplink.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_deeplinks.ApiDeeplinksAddDeeplink();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sessionId = reader.string();
                        break;
                    }
                case 2: {
                        message.deeplinkId = reader.string();
                        break;
                    }
                case 3: {
                        message.url = reader.string();
                        break;
                    }
                case 4: {
                        message.name = reader.string();
                        break;
                    }
                case 5: {
                        message.publiclyAccessible = reader.bool();
                        break;
                    }
                case 6: {
                        message.segmentStart = reader.uint64();
                        break;
                    }
                case 7: {
                        message.segmentEnd = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiDeeplinksAddDeeplink message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_deeplinks.ApiDeeplinksAddDeeplink
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_deeplinks.ApiDeeplinksAddDeeplink} ApiDeeplinksAddDeeplink
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiDeeplinksAddDeeplink.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiDeeplinksAddDeeplink message.
         * @function verify
         * @memberof api_deeplinks.ApiDeeplinksAddDeeplink
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiDeeplinksAddDeeplink.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                if (!$util.isString(message.sessionId))
                    return "sessionId: string expected";
            if (message.deeplinkId != null && message.hasOwnProperty("deeplinkId"))
                if (!$util.isString(message.deeplinkId))
                    return "deeplinkId: string expected";
            if (message.url != null && message.hasOwnProperty("url"))
                if (!$util.isString(message.url))
                    return "url: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.publiclyAccessible != null && message.hasOwnProperty("publiclyAccessible"))
                if (typeof message.publiclyAccessible !== "boolean")
                    return "publiclyAccessible: boolean expected";
            if (message.segmentStart != null && message.hasOwnProperty("segmentStart"))
                if (!$util.isInteger(message.segmentStart) && !(message.segmentStart && $util.isInteger(message.segmentStart.low) && $util.isInteger(message.segmentStart.high)))
                    return "segmentStart: integer|Long expected";
            if (message.segmentEnd != null && message.hasOwnProperty("segmentEnd"))
                if (!$util.isInteger(message.segmentEnd) && !(message.segmentEnd && $util.isInteger(message.segmentEnd.low) && $util.isInteger(message.segmentEnd.high)))
                    return "segmentEnd: integer|Long expected";
            return null;
        };

        /**
         * Creates an ApiDeeplinksAddDeeplink message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_deeplinks.ApiDeeplinksAddDeeplink
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_deeplinks.ApiDeeplinksAddDeeplink} ApiDeeplinksAddDeeplink
         */
        ApiDeeplinksAddDeeplink.fromObject = function fromObject(object) {
            if (object instanceof $root.api_deeplinks.ApiDeeplinksAddDeeplink)
                return object;
            let message = new $root.api_deeplinks.ApiDeeplinksAddDeeplink();
            if (object.sessionId != null)
                message.sessionId = String(object.sessionId);
            if (object.deeplinkId != null)
                message.deeplinkId = String(object.deeplinkId);
            if (object.url != null)
                message.url = String(object.url);
            if (object.name != null)
                message.name = String(object.name);
            if (object.publiclyAccessible != null)
                message.publiclyAccessible = Boolean(object.publiclyAccessible);
            if (object.segmentStart != null)
                if ($util.Long)
                    (message.segmentStart = $util.Long.fromValue(object.segmentStart)).unsigned = true;
                else if (typeof object.segmentStart === "string")
                    message.segmentStart = parseInt(object.segmentStart, 10);
                else if (typeof object.segmentStart === "number")
                    message.segmentStart = object.segmentStart;
                else if (typeof object.segmentStart === "object")
                    message.segmentStart = new $util.LongBits(object.segmentStart.low >>> 0, object.segmentStart.high >>> 0).toNumber(true);
            if (object.segmentEnd != null)
                if ($util.Long)
                    (message.segmentEnd = $util.Long.fromValue(object.segmentEnd)).unsigned = true;
                else if (typeof object.segmentEnd === "string")
                    message.segmentEnd = parseInt(object.segmentEnd, 10);
                else if (typeof object.segmentEnd === "number")
                    message.segmentEnd = object.segmentEnd;
                else if (typeof object.segmentEnd === "object")
                    message.segmentEnd = new $util.LongBits(object.segmentEnd.low >>> 0, object.segmentEnd.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from an ApiDeeplinksAddDeeplink message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_deeplinks.ApiDeeplinksAddDeeplink
         * @static
         * @param {api_deeplinks.ApiDeeplinksAddDeeplink} message ApiDeeplinksAddDeeplink
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiDeeplinksAddDeeplink.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.sessionId = "";
                object.deeplinkId = "";
                object.url = "";
                object.name = "";
                object.publiclyAccessible = false;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.segmentStart = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.segmentStart = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.segmentEnd = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.segmentEnd = options.longs === String ? "0" : 0;
            }
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                object.sessionId = message.sessionId;
            if (message.deeplinkId != null && message.hasOwnProperty("deeplinkId"))
                object.deeplinkId = message.deeplinkId;
            if (message.url != null && message.hasOwnProperty("url"))
                object.url = message.url;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.publiclyAccessible != null && message.hasOwnProperty("publiclyAccessible"))
                object.publiclyAccessible = message.publiclyAccessible;
            if (message.segmentStart != null && message.hasOwnProperty("segmentStart"))
                if (typeof message.segmentStart === "number")
                    object.segmentStart = options.longs === String ? String(message.segmentStart) : message.segmentStart;
                else
                    object.segmentStart = options.longs === String ? $util.Long.prototype.toString.call(message.segmentStart) : options.longs === Number ? new $util.LongBits(message.segmentStart.low >>> 0, message.segmentStart.high >>> 0).toNumber(true) : message.segmentStart;
            if (message.segmentEnd != null && message.hasOwnProperty("segmentEnd"))
                if (typeof message.segmentEnd === "number")
                    object.segmentEnd = options.longs === String ? String(message.segmentEnd) : message.segmentEnd;
                else
                    object.segmentEnd = options.longs === String ? $util.Long.prototype.toString.call(message.segmentEnd) : options.longs === Number ? new $util.LongBits(message.segmentEnd.low >>> 0, message.segmentEnd.high >>> 0).toNumber(true) : message.segmentEnd;
            return object;
        };

        /**
         * Converts this ApiDeeplinksAddDeeplink to JSON.
         * @function toJSON
         * @memberof api_deeplinks.ApiDeeplinksAddDeeplink
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiDeeplinksAddDeeplink.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiDeeplinksAddDeeplink
         * @function getTypeUrl
         * @memberof api_deeplinks.ApiDeeplinksAddDeeplink
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiDeeplinksAddDeeplink.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_deeplinks.ApiDeeplinksAddDeeplink";
        };

        return ApiDeeplinksAddDeeplink;
    })();

    api_deeplinks.ApiDeeplinksUpdateDeeplink = (function() {

        /**
         * Properties of an ApiDeeplinksUpdateDeeplink.
         * @memberof api_deeplinks
         * @interface IApiDeeplinksUpdateDeeplink
         * @property {string|null} [deeplinkId] ApiDeeplinksUpdateDeeplink deeplinkId
         * @property {string|null} [url] ApiDeeplinksUpdateDeeplink url
         * @property {string|null} [name] ApiDeeplinksUpdateDeeplink name
         * @property {boolean|null} [publiclyAccessible] ApiDeeplinksUpdateDeeplink publiclyAccessible
         * @property {number|Long|null} [segmentStart] ApiDeeplinksUpdateDeeplink segmentStart
         * @property {number|Long|null} [segmentEnd] ApiDeeplinksUpdateDeeplink segmentEnd
         */

        /**
         * Constructs a new ApiDeeplinksUpdateDeeplink.
         * @memberof api_deeplinks
         * @classdesc Represents an ApiDeeplinksUpdateDeeplink.
         * @implements IApiDeeplinksUpdateDeeplink
         * @constructor
         * @param {api_deeplinks.IApiDeeplinksUpdateDeeplink=} [properties] Properties to set
         */
        function ApiDeeplinksUpdateDeeplink(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiDeeplinksUpdateDeeplink deeplinkId.
         * @member {string} deeplinkId
         * @memberof api_deeplinks.ApiDeeplinksUpdateDeeplink
         * @instance
         */
        ApiDeeplinksUpdateDeeplink.prototype.deeplinkId = "";

        /**
         * ApiDeeplinksUpdateDeeplink url.
         * @member {string} url
         * @memberof api_deeplinks.ApiDeeplinksUpdateDeeplink
         * @instance
         */
        ApiDeeplinksUpdateDeeplink.prototype.url = "";

        /**
         * ApiDeeplinksUpdateDeeplink name.
         * @member {string} name
         * @memberof api_deeplinks.ApiDeeplinksUpdateDeeplink
         * @instance
         */
        ApiDeeplinksUpdateDeeplink.prototype.name = "";

        /**
         * ApiDeeplinksUpdateDeeplink publiclyAccessible.
         * @member {boolean} publiclyAccessible
         * @memberof api_deeplinks.ApiDeeplinksUpdateDeeplink
         * @instance
         */
        ApiDeeplinksUpdateDeeplink.prototype.publiclyAccessible = false;

        /**
         * ApiDeeplinksUpdateDeeplink segmentStart.
         * @member {number|Long} segmentStart
         * @memberof api_deeplinks.ApiDeeplinksUpdateDeeplink
         * @instance
         */
        ApiDeeplinksUpdateDeeplink.prototype.segmentStart = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ApiDeeplinksUpdateDeeplink segmentEnd.
         * @member {number|Long} segmentEnd
         * @memberof api_deeplinks.ApiDeeplinksUpdateDeeplink
         * @instance
         */
        ApiDeeplinksUpdateDeeplink.prototype.segmentEnd = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new ApiDeeplinksUpdateDeeplink instance using the specified properties.
         * @function create
         * @memberof api_deeplinks.ApiDeeplinksUpdateDeeplink
         * @static
         * @param {api_deeplinks.IApiDeeplinksUpdateDeeplink=} [properties] Properties to set
         * @returns {api_deeplinks.ApiDeeplinksUpdateDeeplink} ApiDeeplinksUpdateDeeplink instance
         */
        ApiDeeplinksUpdateDeeplink.create = function create(properties) {
            return new ApiDeeplinksUpdateDeeplink(properties);
        };

        /**
         * Encodes the specified ApiDeeplinksUpdateDeeplink message. Does not implicitly {@link api_deeplinks.ApiDeeplinksUpdateDeeplink.verify|verify} messages.
         * @function encode
         * @memberof api_deeplinks.ApiDeeplinksUpdateDeeplink
         * @static
         * @param {api_deeplinks.IApiDeeplinksUpdateDeeplink} message ApiDeeplinksUpdateDeeplink message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiDeeplinksUpdateDeeplink.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.deeplinkId != null && Object.hasOwnProperty.call(message, "deeplinkId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.deeplinkId);
            if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.url);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
            if (message.publiclyAccessible != null && Object.hasOwnProperty.call(message, "publiclyAccessible"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.publiclyAccessible);
            if (message.segmentStart != null && Object.hasOwnProperty.call(message, "segmentStart"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint64(message.segmentStart);
            if (message.segmentEnd != null && Object.hasOwnProperty.call(message, "segmentEnd"))
                writer.uint32(/* id 6, wireType 0 =*/48).uint64(message.segmentEnd);
            return writer;
        };

        /**
         * Encodes the specified ApiDeeplinksUpdateDeeplink message, length delimited. Does not implicitly {@link api_deeplinks.ApiDeeplinksUpdateDeeplink.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_deeplinks.ApiDeeplinksUpdateDeeplink
         * @static
         * @param {api_deeplinks.IApiDeeplinksUpdateDeeplink} message ApiDeeplinksUpdateDeeplink message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiDeeplinksUpdateDeeplink.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiDeeplinksUpdateDeeplink message from the specified reader or buffer.
         * @function decode
         * @memberof api_deeplinks.ApiDeeplinksUpdateDeeplink
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_deeplinks.ApiDeeplinksUpdateDeeplink} ApiDeeplinksUpdateDeeplink
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiDeeplinksUpdateDeeplink.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_deeplinks.ApiDeeplinksUpdateDeeplink();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.deeplinkId = reader.string();
                        break;
                    }
                case 2: {
                        message.url = reader.string();
                        break;
                    }
                case 3: {
                        message.name = reader.string();
                        break;
                    }
                case 4: {
                        message.publiclyAccessible = reader.bool();
                        break;
                    }
                case 5: {
                        message.segmentStart = reader.uint64();
                        break;
                    }
                case 6: {
                        message.segmentEnd = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiDeeplinksUpdateDeeplink message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_deeplinks.ApiDeeplinksUpdateDeeplink
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_deeplinks.ApiDeeplinksUpdateDeeplink} ApiDeeplinksUpdateDeeplink
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiDeeplinksUpdateDeeplink.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiDeeplinksUpdateDeeplink message.
         * @function verify
         * @memberof api_deeplinks.ApiDeeplinksUpdateDeeplink
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiDeeplinksUpdateDeeplink.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.deeplinkId != null && message.hasOwnProperty("deeplinkId"))
                if (!$util.isString(message.deeplinkId))
                    return "deeplinkId: string expected";
            if (message.url != null && message.hasOwnProperty("url"))
                if (!$util.isString(message.url))
                    return "url: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.publiclyAccessible != null && message.hasOwnProperty("publiclyAccessible"))
                if (typeof message.publiclyAccessible !== "boolean")
                    return "publiclyAccessible: boolean expected";
            if (message.segmentStart != null && message.hasOwnProperty("segmentStart"))
                if (!$util.isInteger(message.segmentStart) && !(message.segmentStart && $util.isInteger(message.segmentStart.low) && $util.isInteger(message.segmentStart.high)))
                    return "segmentStart: integer|Long expected";
            if (message.segmentEnd != null && message.hasOwnProperty("segmentEnd"))
                if (!$util.isInteger(message.segmentEnd) && !(message.segmentEnd && $util.isInteger(message.segmentEnd.low) && $util.isInteger(message.segmentEnd.high)))
                    return "segmentEnd: integer|Long expected";
            return null;
        };

        /**
         * Creates an ApiDeeplinksUpdateDeeplink message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_deeplinks.ApiDeeplinksUpdateDeeplink
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_deeplinks.ApiDeeplinksUpdateDeeplink} ApiDeeplinksUpdateDeeplink
         */
        ApiDeeplinksUpdateDeeplink.fromObject = function fromObject(object) {
            if (object instanceof $root.api_deeplinks.ApiDeeplinksUpdateDeeplink)
                return object;
            let message = new $root.api_deeplinks.ApiDeeplinksUpdateDeeplink();
            if (object.deeplinkId != null)
                message.deeplinkId = String(object.deeplinkId);
            if (object.url != null)
                message.url = String(object.url);
            if (object.name != null)
                message.name = String(object.name);
            if (object.publiclyAccessible != null)
                message.publiclyAccessible = Boolean(object.publiclyAccessible);
            if (object.segmentStart != null)
                if ($util.Long)
                    (message.segmentStart = $util.Long.fromValue(object.segmentStart)).unsigned = true;
                else if (typeof object.segmentStart === "string")
                    message.segmentStart = parseInt(object.segmentStart, 10);
                else if (typeof object.segmentStart === "number")
                    message.segmentStart = object.segmentStart;
                else if (typeof object.segmentStart === "object")
                    message.segmentStart = new $util.LongBits(object.segmentStart.low >>> 0, object.segmentStart.high >>> 0).toNumber(true);
            if (object.segmentEnd != null)
                if ($util.Long)
                    (message.segmentEnd = $util.Long.fromValue(object.segmentEnd)).unsigned = true;
                else if (typeof object.segmentEnd === "string")
                    message.segmentEnd = parseInt(object.segmentEnd, 10);
                else if (typeof object.segmentEnd === "number")
                    message.segmentEnd = object.segmentEnd;
                else if (typeof object.segmentEnd === "object")
                    message.segmentEnd = new $util.LongBits(object.segmentEnd.low >>> 0, object.segmentEnd.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from an ApiDeeplinksUpdateDeeplink message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_deeplinks.ApiDeeplinksUpdateDeeplink
         * @static
         * @param {api_deeplinks.ApiDeeplinksUpdateDeeplink} message ApiDeeplinksUpdateDeeplink
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiDeeplinksUpdateDeeplink.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.deeplinkId = "";
                object.url = "";
                object.name = "";
                object.publiclyAccessible = false;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.segmentStart = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.segmentStart = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.segmentEnd = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.segmentEnd = options.longs === String ? "0" : 0;
            }
            if (message.deeplinkId != null && message.hasOwnProperty("deeplinkId"))
                object.deeplinkId = message.deeplinkId;
            if (message.url != null && message.hasOwnProperty("url"))
                object.url = message.url;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.publiclyAccessible != null && message.hasOwnProperty("publiclyAccessible"))
                object.publiclyAccessible = message.publiclyAccessible;
            if (message.segmentStart != null && message.hasOwnProperty("segmentStart"))
                if (typeof message.segmentStart === "number")
                    object.segmentStart = options.longs === String ? String(message.segmentStart) : message.segmentStart;
                else
                    object.segmentStart = options.longs === String ? $util.Long.prototype.toString.call(message.segmentStart) : options.longs === Number ? new $util.LongBits(message.segmentStart.low >>> 0, message.segmentStart.high >>> 0).toNumber(true) : message.segmentStart;
            if (message.segmentEnd != null && message.hasOwnProperty("segmentEnd"))
                if (typeof message.segmentEnd === "number")
                    object.segmentEnd = options.longs === String ? String(message.segmentEnd) : message.segmentEnd;
                else
                    object.segmentEnd = options.longs === String ? $util.Long.prototype.toString.call(message.segmentEnd) : options.longs === Number ? new $util.LongBits(message.segmentEnd.low >>> 0, message.segmentEnd.high >>> 0).toNumber(true) : message.segmentEnd;
            return object;
        };

        /**
         * Converts this ApiDeeplinksUpdateDeeplink to JSON.
         * @function toJSON
         * @memberof api_deeplinks.ApiDeeplinksUpdateDeeplink
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiDeeplinksUpdateDeeplink.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiDeeplinksUpdateDeeplink
         * @function getTypeUrl
         * @memberof api_deeplinks.ApiDeeplinksUpdateDeeplink
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiDeeplinksUpdateDeeplink.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_deeplinks.ApiDeeplinksUpdateDeeplink";
        };

        return ApiDeeplinksUpdateDeeplink;
    })();

    api_deeplinks.ApiDeeplinksRemoveDeeplink = (function() {

        /**
         * Properties of an ApiDeeplinksRemoveDeeplink.
         * @memberof api_deeplinks
         * @interface IApiDeeplinksRemoveDeeplink
         * @property {string|null} [deeplinkId] ApiDeeplinksRemoveDeeplink deeplinkId
         */

        /**
         * Constructs a new ApiDeeplinksRemoveDeeplink.
         * @memberof api_deeplinks
         * @classdesc Represents an ApiDeeplinksRemoveDeeplink.
         * @implements IApiDeeplinksRemoveDeeplink
         * @constructor
         * @param {api_deeplinks.IApiDeeplinksRemoveDeeplink=} [properties] Properties to set
         */
        function ApiDeeplinksRemoveDeeplink(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiDeeplinksRemoveDeeplink deeplinkId.
         * @member {string} deeplinkId
         * @memberof api_deeplinks.ApiDeeplinksRemoveDeeplink
         * @instance
         */
        ApiDeeplinksRemoveDeeplink.prototype.deeplinkId = "";

        /**
         * Creates a new ApiDeeplinksRemoveDeeplink instance using the specified properties.
         * @function create
         * @memberof api_deeplinks.ApiDeeplinksRemoveDeeplink
         * @static
         * @param {api_deeplinks.IApiDeeplinksRemoveDeeplink=} [properties] Properties to set
         * @returns {api_deeplinks.ApiDeeplinksRemoveDeeplink} ApiDeeplinksRemoveDeeplink instance
         */
        ApiDeeplinksRemoveDeeplink.create = function create(properties) {
            return new ApiDeeplinksRemoveDeeplink(properties);
        };

        /**
         * Encodes the specified ApiDeeplinksRemoveDeeplink message. Does not implicitly {@link api_deeplinks.ApiDeeplinksRemoveDeeplink.verify|verify} messages.
         * @function encode
         * @memberof api_deeplinks.ApiDeeplinksRemoveDeeplink
         * @static
         * @param {api_deeplinks.IApiDeeplinksRemoveDeeplink} message ApiDeeplinksRemoveDeeplink message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiDeeplinksRemoveDeeplink.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.deeplinkId != null && Object.hasOwnProperty.call(message, "deeplinkId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.deeplinkId);
            return writer;
        };

        /**
         * Encodes the specified ApiDeeplinksRemoveDeeplink message, length delimited. Does not implicitly {@link api_deeplinks.ApiDeeplinksRemoveDeeplink.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_deeplinks.ApiDeeplinksRemoveDeeplink
         * @static
         * @param {api_deeplinks.IApiDeeplinksRemoveDeeplink} message ApiDeeplinksRemoveDeeplink message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiDeeplinksRemoveDeeplink.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiDeeplinksRemoveDeeplink message from the specified reader or buffer.
         * @function decode
         * @memberof api_deeplinks.ApiDeeplinksRemoveDeeplink
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_deeplinks.ApiDeeplinksRemoveDeeplink} ApiDeeplinksRemoveDeeplink
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiDeeplinksRemoveDeeplink.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_deeplinks.ApiDeeplinksRemoveDeeplink();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.deeplinkId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiDeeplinksRemoveDeeplink message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_deeplinks.ApiDeeplinksRemoveDeeplink
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_deeplinks.ApiDeeplinksRemoveDeeplink} ApiDeeplinksRemoveDeeplink
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiDeeplinksRemoveDeeplink.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiDeeplinksRemoveDeeplink message.
         * @function verify
         * @memberof api_deeplinks.ApiDeeplinksRemoveDeeplink
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiDeeplinksRemoveDeeplink.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.deeplinkId != null && message.hasOwnProperty("deeplinkId"))
                if (!$util.isString(message.deeplinkId))
                    return "deeplinkId: string expected";
            return null;
        };

        /**
         * Creates an ApiDeeplinksRemoveDeeplink message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_deeplinks.ApiDeeplinksRemoveDeeplink
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_deeplinks.ApiDeeplinksRemoveDeeplink} ApiDeeplinksRemoveDeeplink
         */
        ApiDeeplinksRemoveDeeplink.fromObject = function fromObject(object) {
            if (object instanceof $root.api_deeplinks.ApiDeeplinksRemoveDeeplink)
                return object;
            let message = new $root.api_deeplinks.ApiDeeplinksRemoveDeeplink();
            if (object.deeplinkId != null)
                message.deeplinkId = String(object.deeplinkId);
            return message;
        };

        /**
         * Creates a plain object from an ApiDeeplinksRemoveDeeplink message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_deeplinks.ApiDeeplinksRemoveDeeplink
         * @static
         * @param {api_deeplinks.ApiDeeplinksRemoveDeeplink} message ApiDeeplinksRemoveDeeplink
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiDeeplinksRemoveDeeplink.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.deeplinkId = "";
            if (message.deeplinkId != null && message.hasOwnProperty("deeplinkId"))
                object.deeplinkId = message.deeplinkId;
            return object;
        };

        /**
         * Converts this ApiDeeplinksRemoveDeeplink to JSON.
         * @function toJSON
         * @memberof api_deeplinks.ApiDeeplinksRemoveDeeplink
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiDeeplinksRemoveDeeplink.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiDeeplinksRemoveDeeplink
         * @function getTypeUrl
         * @memberof api_deeplinks.ApiDeeplinksRemoveDeeplink
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiDeeplinksRemoveDeeplink.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_deeplinks.ApiDeeplinksRemoveDeeplink";
        };

        return ApiDeeplinksRemoveDeeplink;
    })();

    api_deeplinks.ApiDeeplinksGetSessionDeeplinks = (function() {

        /**
         * Properties of an ApiDeeplinksGetSessionDeeplinks.
         * @memberof api_deeplinks
         * @interface IApiDeeplinksGetSessionDeeplinks
         * @property {string|null} [courtCaseSession] ApiDeeplinksGetSessionDeeplinks courtCaseSession
         */

        /**
         * Constructs a new ApiDeeplinksGetSessionDeeplinks.
         * @memberof api_deeplinks
         * @classdesc Represents an ApiDeeplinksGetSessionDeeplinks.
         * @implements IApiDeeplinksGetSessionDeeplinks
         * @constructor
         * @param {api_deeplinks.IApiDeeplinksGetSessionDeeplinks=} [properties] Properties to set
         */
        function ApiDeeplinksGetSessionDeeplinks(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiDeeplinksGetSessionDeeplinks courtCaseSession.
         * @member {string} courtCaseSession
         * @memberof api_deeplinks.ApiDeeplinksGetSessionDeeplinks
         * @instance
         */
        ApiDeeplinksGetSessionDeeplinks.prototype.courtCaseSession = "";

        /**
         * Creates a new ApiDeeplinksGetSessionDeeplinks instance using the specified properties.
         * @function create
         * @memberof api_deeplinks.ApiDeeplinksGetSessionDeeplinks
         * @static
         * @param {api_deeplinks.IApiDeeplinksGetSessionDeeplinks=} [properties] Properties to set
         * @returns {api_deeplinks.ApiDeeplinksGetSessionDeeplinks} ApiDeeplinksGetSessionDeeplinks instance
         */
        ApiDeeplinksGetSessionDeeplinks.create = function create(properties) {
            return new ApiDeeplinksGetSessionDeeplinks(properties);
        };

        /**
         * Encodes the specified ApiDeeplinksGetSessionDeeplinks message. Does not implicitly {@link api_deeplinks.ApiDeeplinksGetSessionDeeplinks.verify|verify} messages.
         * @function encode
         * @memberof api_deeplinks.ApiDeeplinksGetSessionDeeplinks
         * @static
         * @param {api_deeplinks.IApiDeeplinksGetSessionDeeplinks} message ApiDeeplinksGetSessionDeeplinks message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiDeeplinksGetSessionDeeplinks.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.courtCaseSession != null && Object.hasOwnProperty.call(message, "courtCaseSession"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.courtCaseSession);
            return writer;
        };

        /**
         * Encodes the specified ApiDeeplinksGetSessionDeeplinks message, length delimited. Does not implicitly {@link api_deeplinks.ApiDeeplinksGetSessionDeeplinks.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_deeplinks.ApiDeeplinksGetSessionDeeplinks
         * @static
         * @param {api_deeplinks.IApiDeeplinksGetSessionDeeplinks} message ApiDeeplinksGetSessionDeeplinks message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiDeeplinksGetSessionDeeplinks.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiDeeplinksGetSessionDeeplinks message from the specified reader or buffer.
         * @function decode
         * @memberof api_deeplinks.ApiDeeplinksGetSessionDeeplinks
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_deeplinks.ApiDeeplinksGetSessionDeeplinks} ApiDeeplinksGetSessionDeeplinks
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiDeeplinksGetSessionDeeplinks.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_deeplinks.ApiDeeplinksGetSessionDeeplinks();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.courtCaseSession = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiDeeplinksGetSessionDeeplinks message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_deeplinks.ApiDeeplinksGetSessionDeeplinks
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_deeplinks.ApiDeeplinksGetSessionDeeplinks} ApiDeeplinksGetSessionDeeplinks
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiDeeplinksGetSessionDeeplinks.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiDeeplinksGetSessionDeeplinks message.
         * @function verify
         * @memberof api_deeplinks.ApiDeeplinksGetSessionDeeplinks
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiDeeplinksGetSessionDeeplinks.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.courtCaseSession != null && message.hasOwnProperty("courtCaseSession"))
                if (!$util.isString(message.courtCaseSession))
                    return "courtCaseSession: string expected";
            return null;
        };

        /**
         * Creates an ApiDeeplinksGetSessionDeeplinks message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_deeplinks.ApiDeeplinksGetSessionDeeplinks
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_deeplinks.ApiDeeplinksGetSessionDeeplinks} ApiDeeplinksGetSessionDeeplinks
         */
        ApiDeeplinksGetSessionDeeplinks.fromObject = function fromObject(object) {
            if (object instanceof $root.api_deeplinks.ApiDeeplinksGetSessionDeeplinks)
                return object;
            let message = new $root.api_deeplinks.ApiDeeplinksGetSessionDeeplinks();
            if (object.courtCaseSession != null)
                message.courtCaseSession = String(object.courtCaseSession);
            return message;
        };

        /**
         * Creates a plain object from an ApiDeeplinksGetSessionDeeplinks message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_deeplinks.ApiDeeplinksGetSessionDeeplinks
         * @static
         * @param {api_deeplinks.ApiDeeplinksGetSessionDeeplinks} message ApiDeeplinksGetSessionDeeplinks
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiDeeplinksGetSessionDeeplinks.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.courtCaseSession = "";
            if (message.courtCaseSession != null && message.hasOwnProperty("courtCaseSession"))
                object.courtCaseSession = message.courtCaseSession;
            return object;
        };

        /**
         * Converts this ApiDeeplinksGetSessionDeeplinks to JSON.
         * @function toJSON
         * @memberof api_deeplinks.ApiDeeplinksGetSessionDeeplinks
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiDeeplinksGetSessionDeeplinks.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiDeeplinksGetSessionDeeplinks
         * @function getTypeUrl
         * @memberof api_deeplinks.ApiDeeplinksGetSessionDeeplinks
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiDeeplinksGetSessionDeeplinks.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_deeplinks.ApiDeeplinksGetSessionDeeplinks";
        };

        return ApiDeeplinksGetSessionDeeplinks;
    })();

    api_deeplinks.ApiDeeplinksSessionDeeplinks = (function() {

        /**
         * Properties of an ApiDeeplinksSessionDeeplinks.
         * @memberof api_deeplinks
         * @interface IApiDeeplinksSessionDeeplinks
         * @property {string|null} [sourceEventId] ApiDeeplinksSessionDeeplinks sourceEventId
         * @property {Array.<api_deeplinks.IApiDeeplinksSessionDeeplink>|null} [deeplinks] ApiDeeplinksSessionDeeplinks deeplinks
         */

        /**
         * Constructs a new ApiDeeplinksSessionDeeplinks.
         * @memberof api_deeplinks
         * @classdesc Represents an ApiDeeplinksSessionDeeplinks.
         * @implements IApiDeeplinksSessionDeeplinks
         * @constructor
         * @param {api_deeplinks.IApiDeeplinksSessionDeeplinks=} [properties] Properties to set
         */
        function ApiDeeplinksSessionDeeplinks(properties) {
            this.deeplinks = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiDeeplinksSessionDeeplinks sourceEventId.
         * @member {string} sourceEventId
         * @memberof api_deeplinks.ApiDeeplinksSessionDeeplinks
         * @instance
         */
        ApiDeeplinksSessionDeeplinks.prototype.sourceEventId = "";

        /**
         * ApiDeeplinksSessionDeeplinks deeplinks.
         * @member {Array.<api_deeplinks.IApiDeeplinksSessionDeeplink>} deeplinks
         * @memberof api_deeplinks.ApiDeeplinksSessionDeeplinks
         * @instance
         */
        ApiDeeplinksSessionDeeplinks.prototype.deeplinks = $util.emptyArray;

        /**
         * Creates a new ApiDeeplinksSessionDeeplinks instance using the specified properties.
         * @function create
         * @memberof api_deeplinks.ApiDeeplinksSessionDeeplinks
         * @static
         * @param {api_deeplinks.IApiDeeplinksSessionDeeplinks=} [properties] Properties to set
         * @returns {api_deeplinks.ApiDeeplinksSessionDeeplinks} ApiDeeplinksSessionDeeplinks instance
         */
        ApiDeeplinksSessionDeeplinks.create = function create(properties) {
            return new ApiDeeplinksSessionDeeplinks(properties);
        };

        /**
         * Encodes the specified ApiDeeplinksSessionDeeplinks message. Does not implicitly {@link api_deeplinks.ApiDeeplinksSessionDeeplinks.verify|verify} messages.
         * @function encode
         * @memberof api_deeplinks.ApiDeeplinksSessionDeeplinks
         * @static
         * @param {api_deeplinks.IApiDeeplinksSessionDeeplinks} message ApiDeeplinksSessionDeeplinks message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiDeeplinksSessionDeeplinks.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sourceEventId != null && Object.hasOwnProperty.call(message, "sourceEventId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.sourceEventId);
            if (message.deeplinks != null && message.deeplinks.length)
                for (let i = 0; i < message.deeplinks.length; ++i)
                    $root.api_deeplinks.ApiDeeplinksSessionDeeplink.encode(message.deeplinks[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ApiDeeplinksSessionDeeplinks message, length delimited. Does not implicitly {@link api_deeplinks.ApiDeeplinksSessionDeeplinks.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_deeplinks.ApiDeeplinksSessionDeeplinks
         * @static
         * @param {api_deeplinks.IApiDeeplinksSessionDeeplinks} message ApiDeeplinksSessionDeeplinks message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiDeeplinksSessionDeeplinks.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiDeeplinksSessionDeeplinks message from the specified reader or buffer.
         * @function decode
         * @memberof api_deeplinks.ApiDeeplinksSessionDeeplinks
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_deeplinks.ApiDeeplinksSessionDeeplinks} ApiDeeplinksSessionDeeplinks
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiDeeplinksSessionDeeplinks.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_deeplinks.ApiDeeplinksSessionDeeplinks();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sourceEventId = reader.string();
                        break;
                    }
                case 2: {
                        if (!(message.deeplinks && message.deeplinks.length))
                            message.deeplinks = [];
                        message.deeplinks.push($root.api_deeplinks.ApiDeeplinksSessionDeeplink.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiDeeplinksSessionDeeplinks message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_deeplinks.ApiDeeplinksSessionDeeplinks
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_deeplinks.ApiDeeplinksSessionDeeplinks} ApiDeeplinksSessionDeeplinks
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiDeeplinksSessionDeeplinks.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiDeeplinksSessionDeeplinks message.
         * @function verify
         * @memberof api_deeplinks.ApiDeeplinksSessionDeeplinks
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiDeeplinksSessionDeeplinks.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.sourceEventId != null && message.hasOwnProperty("sourceEventId"))
                if (!$util.isString(message.sourceEventId))
                    return "sourceEventId: string expected";
            if (message.deeplinks != null && message.hasOwnProperty("deeplinks")) {
                if (!Array.isArray(message.deeplinks))
                    return "deeplinks: array expected";
                for (let i = 0; i < message.deeplinks.length; ++i) {
                    let error = $root.api_deeplinks.ApiDeeplinksSessionDeeplink.verify(message.deeplinks[i]);
                    if (error)
                        return "deeplinks." + error;
                }
            }
            return null;
        };

        /**
         * Creates an ApiDeeplinksSessionDeeplinks message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_deeplinks.ApiDeeplinksSessionDeeplinks
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_deeplinks.ApiDeeplinksSessionDeeplinks} ApiDeeplinksSessionDeeplinks
         */
        ApiDeeplinksSessionDeeplinks.fromObject = function fromObject(object) {
            if (object instanceof $root.api_deeplinks.ApiDeeplinksSessionDeeplinks)
                return object;
            let message = new $root.api_deeplinks.ApiDeeplinksSessionDeeplinks();
            if (object.sourceEventId != null)
                message.sourceEventId = String(object.sourceEventId);
            if (object.deeplinks) {
                if (!Array.isArray(object.deeplinks))
                    throw TypeError(".api_deeplinks.ApiDeeplinksSessionDeeplinks.deeplinks: array expected");
                message.deeplinks = [];
                for (let i = 0; i < object.deeplinks.length; ++i) {
                    if (typeof object.deeplinks[i] !== "object")
                        throw TypeError(".api_deeplinks.ApiDeeplinksSessionDeeplinks.deeplinks: object expected");
                    message.deeplinks[i] = $root.api_deeplinks.ApiDeeplinksSessionDeeplink.fromObject(object.deeplinks[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from an ApiDeeplinksSessionDeeplinks message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_deeplinks.ApiDeeplinksSessionDeeplinks
         * @static
         * @param {api_deeplinks.ApiDeeplinksSessionDeeplinks} message ApiDeeplinksSessionDeeplinks
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiDeeplinksSessionDeeplinks.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.deeplinks = [];
            if (options.defaults)
                object.sourceEventId = "";
            if (message.sourceEventId != null && message.hasOwnProperty("sourceEventId"))
                object.sourceEventId = message.sourceEventId;
            if (message.deeplinks && message.deeplinks.length) {
                object.deeplinks = [];
                for (let j = 0; j < message.deeplinks.length; ++j)
                    object.deeplinks[j] = $root.api_deeplinks.ApiDeeplinksSessionDeeplink.toObject(message.deeplinks[j], options);
            }
            return object;
        };

        /**
         * Converts this ApiDeeplinksSessionDeeplinks to JSON.
         * @function toJSON
         * @memberof api_deeplinks.ApiDeeplinksSessionDeeplinks
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiDeeplinksSessionDeeplinks.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiDeeplinksSessionDeeplinks
         * @function getTypeUrl
         * @memberof api_deeplinks.ApiDeeplinksSessionDeeplinks
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiDeeplinksSessionDeeplinks.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_deeplinks.ApiDeeplinksSessionDeeplinks";
        };

        return ApiDeeplinksSessionDeeplinks;
    })();

    api_deeplinks.ApiDeeplinksSessionDeeplink = (function() {

        /**
         * Properties of an ApiDeeplinksSessionDeeplink.
         * @memberof api_deeplinks
         * @interface IApiDeeplinksSessionDeeplink
         * @property {string|null} [sessionId] ApiDeeplinksSessionDeeplink sessionId
         * @property {string|null} [deeplinkId] ApiDeeplinksSessionDeeplink deeplinkId
         * @property {string|null} [name] ApiDeeplinksSessionDeeplink name
         * @property {string|null} [url] ApiDeeplinksSessionDeeplink url
         * @property {string|null} [createdBy] ApiDeeplinksSessionDeeplink createdBy
         * @property {boolean|null} [publiclyAccessible] ApiDeeplinksSessionDeeplink publiclyAccessible
         * @property {number|Long|null} [segmentStart] ApiDeeplinksSessionDeeplink segmentStart
         * @property {number|Long|null} [segmentEnd] ApiDeeplinksSessionDeeplink segmentEnd
         * @property {number|Long|null} [createdAt] ApiDeeplinksSessionDeeplink createdAt
         */

        /**
         * Constructs a new ApiDeeplinksSessionDeeplink.
         * @memberof api_deeplinks
         * @classdesc Represents an ApiDeeplinksSessionDeeplink.
         * @implements IApiDeeplinksSessionDeeplink
         * @constructor
         * @param {api_deeplinks.IApiDeeplinksSessionDeeplink=} [properties] Properties to set
         */
        function ApiDeeplinksSessionDeeplink(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiDeeplinksSessionDeeplink sessionId.
         * @member {string} sessionId
         * @memberof api_deeplinks.ApiDeeplinksSessionDeeplink
         * @instance
         */
        ApiDeeplinksSessionDeeplink.prototype.sessionId = "";

        /**
         * ApiDeeplinksSessionDeeplink deeplinkId.
         * @member {string} deeplinkId
         * @memberof api_deeplinks.ApiDeeplinksSessionDeeplink
         * @instance
         */
        ApiDeeplinksSessionDeeplink.prototype.deeplinkId = "";

        /**
         * ApiDeeplinksSessionDeeplink name.
         * @member {string} name
         * @memberof api_deeplinks.ApiDeeplinksSessionDeeplink
         * @instance
         */
        ApiDeeplinksSessionDeeplink.prototype.name = "";

        /**
         * ApiDeeplinksSessionDeeplink url.
         * @member {string} url
         * @memberof api_deeplinks.ApiDeeplinksSessionDeeplink
         * @instance
         */
        ApiDeeplinksSessionDeeplink.prototype.url = "";

        /**
         * ApiDeeplinksSessionDeeplink createdBy.
         * @member {string} createdBy
         * @memberof api_deeplinks.ApiDeeplinksSessionDeeplink
         * @instance
         */
        ApiDeeplinksSessionDeeplink.prototype.createdBy = "";

        /**
         * ApiDeeplinksSessionDeeplink publiclyAccessible.
         * @member {boolean} publiclyAccessible
         * @memberof api_deeplinks.ApiDeeplinksSessionDeeplink
         * @instance
         */
        ApiDeeplinksSessionDeeplink.prototype.publiclyAccessible = false;

        /**
         * ApiDeeplinksSessionDeeplink segmentStart.
         * @member {number|Long} segmentStart
         * @memberof api_deeplinks.ApiDeeplinksSessionDeeplink
         * @instance
         */
        ApiDeeplinksSessionDeeplink.prototype.segmentStart = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ApiDeeplinksSessionDeeplink segmentEnd.
         * @member {number|Long} segmentEnd
         * @memberof api_deeplinks.ApiDeeplinksSessionDeeplink
         * @instance
         */
        ApiDeeplinksSessionDeeplink.prototype.segmentEnd = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ApiDeeplinksSessionDeeplink createdAt.
         * @member {number|Long} createdAt
         * @memberof api_deeplinks.ApiDeeplinksSessionDeeplink
         * @instance
         */
        ApiDeeplinksSessionDeeplink.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new ApiDeeplinksSessionDeeplink instance using the specified properties.
         * @function create
         * @memberof api_deeplinks.ApiDeeplinksSessionDeeplink
         * @static
         * @param {api_deeplinks.IApiDeeplinksSessionDeeplink=} [properties] Properties to set
         * @returns {api_deeplinks.ApiDeeplinksSessionDeeplink} ApiDeeplinksSessionDeeplink instance
         */
        ApiDeeplinksSessionDeeplink.create = function create(properties) {
            return new ApiDeeplinksSessionDeeplink(properties);
        };

        /**
         * Encodes the specified ApiDeeplinksSessionDeeplink message. Does not implicitly {@link api_deeplinks.ApiDeeplinksSessionDeeplink.verify|verify} messages.
         * @function encode
         * @memberof api_deeplinks.ApiDeeplinksSessionDeeplink
         * @static
         * @param {api_deeplinks.IApiDeeplinksSessionDeeplink} message ApiDeeplinksSessionDeeplink message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiDeeplinksSessionDeeplink.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sessionId != null && Object.hasOwnProperty.call(message, "sessionId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.sessionId);
            if (message.deeplinkId != null && Object.hasOwnProperty.call(message, "deeplinkId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.deeplinkId);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
            if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.url);
            if (message.createdBy != null && Object.hasOwnProperty.call(message, "createdBy"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.createdBy);
            if (message.publiclyAccessible != null && Object.hasOwnProperty.call(message, "publiclyAccessible"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.publiclyAccessible);
            if (message.segmentStart != null && Object.hasOwnProperty.call(message, "segmentStart"))
                writer.uint32(/* id 7, wireType 0 =*/56).uint64(message.segmentStart);
            if (message.segmentEnd != null && Object.hasOwnProperty.call(message, "segmentEnd"))
                writer.uint32(/* id 8, wireType 0 =*/64).uint64(message.segmentEnd);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 9, wireType 0 =*/72).uint64(message.createdAt);
            return writer;
        };

        /**
         * Encodes the specified ApiDeeplinksSessionDeeplink message, length delimited. Does not implicitly {@link api_deeplinks.ApiDeeplinksSessionDeeplink.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_deeplinks.ApiDeeplinksSessionDeeplink
         * @static
         * @param {api_deeplinks.IApiDeeplinksSessionDeeplink} message ApiDeeplinksSessionDeeplink message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiDeeplinksSessionDeeplink.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiDeeplinksSessionDeeplink message from the specified reader or buffer.
         * @function decode
         * @memberof api_deeplinks.ApiDeeplinksSessionDeeplink
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_deeplinks.ApiDeeplinksSessionDeeplink} ApiDeeplinksSessionDeeplink
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiDeeplinksSessionDeeplink.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_deeplinks.ApiDeeplinksSessionDeeplink();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sessionId = reader.string();
                        break;
                    }
                case 2: {
                        message.deeplinkId = reader.string();
                        break;
                    }
                case 3: {
                        message.name = reader.string();
                        break;
                    }
                case 4: {
                        message.url = reader.string();
                        break;
                    }
                case 5: {
                        message.createdBy = reader.string();
                        break;
                    }
                case 6: {
                        message.publiclyAccessible = reader.bool();
                        break;
                    }
                case 7: {
                        message.segmentStart = reader.uint64();
                        break;
                    }
                case 8: {
                        message.segmentEnd = reader.uint64();
                        break;
                    }
                case 9: {
                        message.createdAt = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiDeeplinksSessionDeeplink message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_deeplinks.ApiDeeplinksSessionDeeplink
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_deeplinks.ApiDeeplinksSessionDeeplink} ApiDeeplinksSessionDeeplink
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiDeeplinksSessionDeeplink.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiDeeplinksSessionDeeplink message.
         * @function verify
         * @memberof api_deeplinks.ApiDeeplinksSessionDeeplink
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiDeeplinksSessionDeeplink.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                if (!$util.isString(message.sessionId))
                    return "sessionId: string expected";
            if (message.deeplinkId != null && message.hasOwnProperty("deeplinkId"))
                if (!$util.isString(message.deeplinkId))
                    return "deeplinkId: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.url != null && message.hasOwnProperty("url"))
                if (!$util.isString(message.url))
                    return "url: string expected";
            if (message.createdBy != null && message.hasOwnProperty("createdBy"))
                if (!$util.isString(message.createdBy))
                    return "createdBy: string expected";
            if (message.publiclyAccessible != null && message.hasOwnProperty("publiclyAccessible"))
                if (typeof message.publiclyAccessible !== "boolean")
                    return "publiclyAccessible: boolean expected";
            if (message.segmentStart != null && message.hasOwnProperty("segmentStart"))
                if (!$util.isInteger(message.segmentStart) && !(message.segmentStart && $util.isInteger(message.segmentStart.low) && $util.isInteger(message.segmentStart.high)))
                    return "segmentStart: integer|Long expected";
            if (message.segmentEnd != null && message.hasOwnProperty("segmentEnd"))
                if (!$util.isInteger(message.segmentEnd) && !(message.segmentEnd && $util.isInteger(message.segmentEnd.low) && $util.isInteger(message.segmentEnd.high)))
                    return "segmentEnd: integer|Long expected";
            if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                    return "createdAt: integer|Long expected";
            return null;
        };

        /**
         * Creates an ApiDeeplinksSessionDeeplink message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_deeplinks.ApiDeeplinksSessionDeeplink
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_deeplinks.ApiDeeplinksSessionDeeplink} ApiDeeplinksSessionDeeplink
         */
        ApiDeeplinksSessionDeeplink.fromObject = function fromObject(object) {
            if (object instanceof $root.api_deeplinks.ApiDeeplinksSessionDeeplink)
                return object;
            let message = new $root.api_deeplinks.ApiDeeplinksSessionDeeplink();
            if (object.sessionId != null)
                message.sessionId = String(object.sessionId);
            if (object.deeplinkId != null)
                message.deeplinkId = String(object.deeplinkId);
            if (object.name != null)
                message.name = String(object.name);
            if (object.url != null)
                message.url = String(object.url);
            if (object.createdBy != null)
                message.createdBy = String(object.createdBy);
            if (object.publiclyAccessible != null)
                message.publiclyAccessible = Boolean(object.publiclyAccessible);
            if (object.segmentStart != null)
                if ($util.Long)
                    (message.segmentStart = $util.Long.fromValue(object.segmentStart)).unsigned = true;
                else if (typeof object.segmentStart === "string")
                    message.segmentStart = parseInt(object.segmentStart, 10);
                else if (typeof object.segmentStart === "number")
                    message.segmentStart = object.segmentStart;
                else if (typeof object.segmentStart === "object")
                    message.segmentStart = new $util.LongBits(object.segmentStart.low >>> 0, object.segmentStart.high >>> 0).toNumber(true);
            if (object.segmentEnd != null)
                if ($util.Long)
                    (message.segmentEnd = $util.Long.fromValue(object.segmentEnd)).unsigned = true;
                else if (typeof object.segmentEnd === "string")
                    message.segmentEnd = parseInt(object.segmentEnd, 10);
                else if (typeof object.segmentEnd === "number")
                    message.segmentEnd = object.segmentEnd;
                else if (typeof object.segmentEnd === "object")
                    message.segmentEnd = new $util.LongBits(object.segmentEnd.low >>> 0, object.segmentEnd.high >>> 0).toNumber(true);
            if (object.createdAt != null)
                if ($util.Long)
                    (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = true;
                else if (typeof object.createdAt === "string")
                    message.createdAt = parseInt(object.createdAt, 10);
                else if (typeof object.createdAt === "number")
                    message.createdAt = object.createdAt;
                else if (typeof object.createdAt === "object")
                    message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from an ApiDeeplinksSessionDeeplink message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_deeplinks.ApiDeeplinksSessionDeeplink
         * @static
         * @param {api_deeplinks.ApiDeeplinksSessionDeeplink} message ApiDeeplinksSessionDeeplink
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiDeeplinksSessionDeeplink.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.sessionId = "";
                object.deeplinkId = "";
                object.name = "";
                object.url = "";
                object.createdBy = "";
                object.publiclyAccessible = false;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.segmentStart = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.segmentStart = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.segmentEnd = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.segmentEnd = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.createdAt = options.longs === String ? "0" : 0;
            }
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                object.sessionId = message.sessionId;
            if (message.deeplinkId != null && message.hasOwnProperty("deeplinkId"))
                object.deeplinkId = message.deeplinkId;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.url != null && message.hasOwnProperty("url"))
                object.url = message.url;
            if (message.createdBy != null && message.hasOwnProperty("createdBy"))
                object.createdBy = message.createdBy;
            if (message.publiclyAccessible != null && message.hasOwnProperty("publiclyAccessible"))
                object.publiclyAccessible = message.publiclyAccessible;
            if (message.segmentStart != null && message.hasOwnProperty("segmentStart"))
                if (typeof message.segmentStart === "number")
                    object.segmentStart = options.longs === String ? String(message.segmentStart) : message.segmentStart;
                else
                    object.segmentStart = options.longs === String ? $util.Long.prototype.toString.call(message.segmentStart) : options.longs === Number ? new $util.LongBits(message.segmentStart.low >>> 0, message.segmentStart.high >>> 0).toNumber(true) : message.segmentStart;
            if (message.segmentEnd != null && message.hasOwnProperty("segmentEnd"))
                if (typeof message.segmentEnd === "number")
                    object.segmentEnd = options.longs === String ? String(message.segmentEnd) : message.segmentEnd;
                else
                    object.segmentEnd = options.longs === String ? $util.Long.prototype.toString.call(message.segmentEnd) : options.longs === Number ? new $util.LongBits(message.segmentEnd.low >>> 0, message.segmentEnd.high >>> 0).toNumber(true) : message.segmentEnd;
            if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                if (typeof message.createdAt === "number")
                    object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                else
                    object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber(true) : message.createdAt;
            return object;
        };

        /**
         * Converts this ApiDeeplinksSessionDeeplink to JSON.
         * @function toJSON
         * @memberof api_deeplinks.ApiDeeplinksSessionDeeplink
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiDeeplinksSessionDeeplink.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiDeeplinksSessionDeeplink
         * @function getTypeUrl
         * @memberof api_deeplinks.ApiDeeplinksSessionDeeplink
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiDeeplinksSessionDeeplink.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_deeplinks.ApiDeeplinksSessionDeeplink";
        };

        return ApiDeeplinksSessionDeeplink;
    })();

    return api_deeplinks;
})();

export const ws_users = $root.ws_users = (() => {

    /**
     * Namespace ws_users.
     * @exports ws_users
     * @namespace
     */
    const ws_users = {};

    ws_users.LoadAsodUsers = (function() {

        /**
         * Properties of a LoadAsodUsers.
         * @memberof ws_users
         * @interface ILoadAsodUsers
         * @property {string|null} [quickSearch] LoadAsodUsers quickSearch
         * @property {string|null} [court] LoadAsodUsers court
         * @property {string|null} [department] LoadAsodUsers department
         * @property {string|null} [role] LoadAsodUsers role
         * @property {common.IPagination|null} [pagination] LoadAsodUsers pagination
         * @property {ws_users.AsodUsersOrderingTerm|null} [sortBy] LoadAsodUsers sortBy
         * @property {common.SortOrder|null} [sortOrder] LoadAsodUsers sortOrder
         * @property {ws_users.IAsodUsers|null} [_response] LoadAsodUsers _response
         */

        /**
         * Constructs a new LoadAsodUsers.
         * @memberof ws_users
         * @classdesc Represents a LoadAsodUsers.
         * @implements ILoadAsodUsers
         * @constructor
         * @param {ws_users.ILoadAsodUsers=} [properties] Properties to set
         */
        function LoadAsodUsers(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LoadAsodUsers quickSearch.
         * @member {string} quickSearch
         * @memberof ws_users.LoadAsodUsers
         * @instance
         */
        LoadAsodUsers.prototype.quickSearch = "";

        /**
         * LoadAsodUsers court.
         * @member {string} court
         * @memberof ws_users.LoadAsodUsers
         * @instance
         */
        LoadAsodUsers.prototype.court = "";

        /**
         * LoadAsodUsers department.
         * @member {string} department
         * @memberof ws_users.LoadAsodUsers
         * @instance
         */
        LoadAsodUsers.prototype.department = "";

        /**
         * LoadAsodUsers role.
         * @member {string} role
         * @memberof ws_users.LoadAsodUsers
         * @instance
         */
        LoadAsodUsers.prototype.role = "";

        /**
         * LoadAsodUsers pagination.
         * @member {common.IPagination|null|undefined} pagination
         * @memberof ws_users.LoadAsodUsers
         * @instance
         */
        LoadAsodUsers.prototype.pagination = null;

        /**
         * LoadAsodUsers sortBy.
         * @member {ws_users.AsodUsersOrderingTerm} sortBy
         * @memberof ws_users.LoadAsodUsers
         * @instance
         */
        LoadAsodUsers.prototype.sortBy = 0;

        /**
         * LoadAsodUsers sortOrder.
         * @member {common.SortOrder} sortOrder
         * @memberof ws_users.LoadAsodUsers
         * @instance
         */
        LoadAsodUsers.prototype.sortOrder = 0;

        /**
         * LoadAsodUsers _response.
         * @member {ws_users.IAsodUsers|null|undefined} _response
         * @memberof ws_users.LoadAsodUsers
         * @instance
         */
        LoadAsodUsers.prototype._response = null;

        /**
         * Creates a new LoadAsodUsers instance using the specified properties.
         * @function create
         * @memberof ws_users.LoadAsodUsers
         * @static
         * @param {ws_users.ILoadAsodUsers=} [properties] Properties to set
         * @returns {ws_users.LoadAsodUsers} LoadAsodUsers instance
         */
        LoadAsodUsers.create = function create(properties) {
            return new LoadAsodUsers(properties);
        };

        /**
         * Encodes the specified LoadAsodUsers message. Does not implicitly {@link ws_users.LoadAsodUsers.verify|verify} messages.
         * @function encode
         * @memberof ws_users.LoadAsodUsers
         * @static
         * @param {ws_users.ILoadAsodUsers} message LoadAsodUsers message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoadAsodUsers.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.quickSearch != null && Object.hasOwnProperty.call(message, "quickSearch"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.quickSearch);
            if (message.court != null && Object.hasOwnProperty.call(message, "court"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.court);
            if (message.department != null && Object.hasOwnProperty.call(message, "department"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.department);
            if (message.role != null && Object.hasOwnProperty.call(message, "role"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.role);
            if (message.pagination != null && Object.hasOwnProperty.call(message, "pagination"))
                $root.common.Pagination.encode(message.pagination, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.sortBy != null && Object.hasOwnProperty.call(message, "sortBy"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.sortBy);
            if (message.sortOrder != null && Object.hasOwnProperty.call(message, "sortOrder"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.sortOrder);
            if (message._response != null && Object.hasOwnProperty.call(message, "_response"))
                $root.ws_users.AsodUsers.encode(message._response, writer.uint32(/* id 99, wireType 2 =*/794).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified LoadAsodUsers message, length delimited. Does not implicitly {@link ws_users.LoadAsodUsers.verify|verify} messages.
         * @function encodeDelimited
         * @memberof ws_users.LoadAsodUsers
         * @static
         * @param {ws_users.ILoadAsodUsers} message LoadAsodUsers message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoadAsodUsers.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LoadAsodUsers message from the specified reader or buffer.
         * @function decode
         * @memberof ws_users.LoadAsodUsers
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {ws_users.LoadAsodUsers} LoadAsodUsers
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoadAsodUsers.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ws_users.LoadAsodUsers();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.quickSearch = reader.string();
                        break;
                    }
                case 2: {
                        message.court = reader.string();
                        break;
                    }
                case 3: {
                        message.department = reader.string();
                        break;
                    }
                case 4: {
                        message.role = reader.string();
                        break;
                    }
                case 5: {
                        message.pagination = $root.common.Pagination.decode(reader, reader.uint32());
                        break;
                    }
                case 6: {
                        message.sortBy = reader.int32();
                        break;
                    }
                case 7: {
                        message.sortOrder = reader.int32();
                        break;
                    }
                case 99: {
                        message._response = $root.ws_users.AsodUsers.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LoadAsodUsers message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof ws_users.LoadAsodUsers
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {ws_users.LoadAsodUsers} LoadAsodUsers
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoadAsodUsers.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LoadAsodUsers message.
         * @function verify
         * @memberof ws_users.LoadAsodUsers
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LoadAsodUsers.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.quickSearch != null && message.hasOwnProperty("quickSearch"))
                if (!$util.isString(message.quickSearch))
                    return "quickSearch: string expected";
            if (message.court != null && message.hasOwnProperty("court"))
                if (!$util.isString(message.court))
                    return "court: string expected";
            if (message.department != null && message.hasOwnProperty("department"))
                if (!$util.isString(message.department))
                    return "department: string expected";
            if (message.role != null && message.hasOwnProperty("role"))
                if (!$util.isString(message.role))
                    return "role: string expected";
            if (message.pagination != null && message.hasOwnProperty("pagination")) {
                let error = $root.common.Pagination.verify(message.pagination);
                if (error)
                    return "pagination." + error;
            }
            if (message.sortBy != null && message.hasOwnProperty("sortBy"))
                switch (message.sortBy) {
                default:
                    return "sortBy: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                    break;
                }
            if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                switch (message.sortOrder) {
                default:
                    return "sortOrder: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message._response != null && message.hasOwnProperty("_response")) {
                let error = $root.ws_users.AsodUsers.verify(message._response);
                if (error)
                    return "_response." + error;
            }
            return null;
        };

        /**
         * Creates a LoadAsodUsers message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof ws_users.LoadAsodUsers
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {ws_users.LoadAsodUsers} LoadAsodUsers
         */
        LoadAsodUsers.fromObject = function fromObject(object) {
            if (object instanceof $root.ws_users.LoadAsodUsers)
                return object;
            let message = new $root.ws_users.LoadAsodUsers();
            if (object.quickSearch != null)
                message.quickSearch = String(object.quickSearch);
            if (object.court != null)
                message.court = String(object.court);
            if (object.department != null)
                message.department = String(object.department);
            if (object.role != null)
                message.role = String(object.role);
            if (object.pagination != null) {
                if (typeof object.pagination !== "object")
                    throw TypeError(".ws_users.LoadAsodUsers.pagination: object expected");
                message.pagination = $root.common.Pagination.fromObject(object.pagination);
            }
            switch (object.sortBy) {
            default:
                if (typeof object.sortBy === "number") {
                    message.sortBy = object.sortBy;
                    break;
                }
                break;
            case "auot_court":
            case 0:
                message.sortBy = 0;
                break;
            case "auot_name_surname":
            case 1:
                message.sortBy = 1;
                break;
            case "auot_department":
            case 2:
                message.sortBy = 2;
                break;
            case "auot_role":
            case 3:
                message.sortBy = 3;
                break;
            case "auot_last_login":
            case 4:
                message.sortBy = 4;
                break;
            }
            switch (object.sortOrder) {
            default:
                if (typeof object.sortOrder === "number") {
                    message.sortOrder = object.sortOrder;
                    break;
                }
                break;
            case "so_none":
            case 0:
                message.sortOrder = 0;
                break;
            case "so_asc":
            case 1:
                message.sortOrder = 1;
                break;
            case "so_desc":
            case 2:
                message.sortOrder = 2;
                break;
            }
            if (object._response != null) {
                if (typeof object._response !== "object")
                    throw TypeError(".ws_users.LoadAsodUsers._response: object expected");
                message._response = $root.ws_users.AsodUsers.fromObject(object._response);
            }
            return message;
        };

        /**
         * Creates a plain object from a LoadAsodUsers message. Also converts values to other types if specified.
         * @function toObject
         * @memberof ws_users.LoadAsodUsers
         * @static
         * @param {ws_users.LoadAsodUsers} message LoadAsodUsers
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LoadAsodUsers.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.quickSearch = "";
                object.court = "";
                object.department = "";
                object.role = "";
                object.pagination = null;
                object.sortBy = options.enums === String ? "auot_court" : 0;
                object.sortOrder = options.enums === String ? "so_none" : 0;
                object._response = null;
            }
            if (message.quickSearch != null && message.hasOwnProperty("quickSearch"))
                object.quickSearch = message.quickSearch;
            if (message.court != null && message.hasOwnProperty("court"))
                object.court = message.court;
            if (message.department != null && message.hasOwnProperty("department"))
                object.department = message.department;
            if (message.role != null && message.hasOwnProperty("role"))
                object.role = message.role;
            if (message.pagination != null && message.hasOwnProperty("pagination"))
                object.pagination = $root.common.Pagination.toObject(message.pagination, options);
            if (message.sortBy != null && message.hasOwnProperty("sortBy"))
                object.sortBy = options.enums === String ? $root.ws_users.AsodUsersOrderingTerm[message.sortBy] === undefined ? message.sortBy : $root.ws_users.AsodUsersOrderingTerm[message.sortBy] : message.sortBy;
            if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                object.sortOrder = options.enums === String ? $root.common.SortOrder[message.sortOrder] === undefined ? message.sortOrder : $root.common.SortOrder[message.sortOrder] : message.sortOrder;
            if (message._response != null && message.hasOwnProperty("_response"))
                object._response = $root.ws_users.AsodUsers.toObject(message._response, options);
            return object;
        };

        /**
         * Converts this LoadAsodUsers to JSON.
         * @function toJSON
         * @memberof ws_users.LoadAsodUsers
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LoadAsodUsers.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LoadAsodUsers
         * @function getTypeUrl
         * @memberof ws_users.LoadAsodUsers
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LoadAsodUsers.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/ws_users.LoadAsodUsers";
        };

        return LoadAsodUsers;
    })();

    ws_users.AsodUsers = (function() {

        /**
         * Properties of an AsodUsers.
         * @memberof ws_users
         * @interface IAsodUsers
         * @property {Array.<ws_users.IAsodUsersRow>|null} [rows] AsodUsers rows
         * @property {common.IPagination|null} [pagination] AsodUsers pagination
         * @property {ws_users.AsodUsersOrderingTerm|null} [sortBy] AsodUsers sortBy
         * @property {common.SortOrder|null} [sortOrder] AsodUsers sortOrder
         */

        /**
         * Constructs a new AsodUsers.
         * @memberof ws_users
         * @classdesc Represents an AsodUsers.
         * @implements IAsodUsers
         * @constructor
         * @param {ws_users.IAsodUsers=} [properties] Properties to set
         */
        function AsodUsers(properties) {
            this.rows = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AsodUsers rows.
         * @member {Array.<ws_users.IAsodUsersRow>} rows
         * @memberof ws_users.AsodUsers
         * @instance
         */
        AsodUsers.prototype.rows = $util.emptyArray;

        /**
         * AsodUsers pagination.
         * @member {common.IPagination|null|undefined} pagination
         * @memberof ws_users.AsodUsers
         * @instance
         */
        AsodUsers.prototype.pagination = null;

        /**
         * AsodUsers sortBy.
         * @member {ws_users.AsodUsersOrderingTerm} sortBy
         * @memberof ws_users.AsodUsers
         * @instance
         */
        AsodUsers.prototype.sortBy = 0;

        /**
         * AsodUsers sortOrder.
         * @member {common.SortOrder} sortOrder
         * @memberof ws_users.AsodUsers
         * @instance
         */
        AsodUsers.prototype.sortOrder = 0;

        /**
         * Creates a new AsodUsers instance using the specified properties.
         * @function create
         * @memberof ws_users.AsodUsers
         * @static
         * @param {ws_users.IAsodUsers=} [properties] Properties to set
         * @returns {ws_users.AsodUsers} AsodUsers instance
         */
        AsodUsers.create = function create(properties) {
            return new AsodUsers(properties);
        };

        /**
         * Encodes the specified AsodUsers message. Does not implicitly {@link ws_users.AsodUsers.verify|verify} messages.
         * @function encode
         * @memberof ws_users.AsodUsers
         * @static
         * @param {ws_users.IAsodUsers} message AsodUsers message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodUsers.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rows != null && message.rows.length)
                for (let i = 0; i < message.rows.length; ++i)
                    $root.ws_users.AsodUsersRow.encode(message.rows[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.pagination != null && Object.hasOwnProperty.call(message, "pagination"))
                $root.common.Pagination.encode(message.pagination, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.sortBy != null && Object.hasOwnProperty.call(message, "sortBy"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.sortBy);
            if (message.sortOrder != null && Object.hasOwnProperty.call(message, "sortOrder"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.sortOrder);
            return writer;
        };

        /**
         * Encodes the specified AsodUsers message, length delimited. Does not implicitly {@link ws_users.AsodUsers.verify|verify} messages.
         * @function encodeDelimited
         * @memberof ws_users.AsodUsers
         * @static
         * @param {ws_users.IAsodUsers} message AsodUsers message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodUsers.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AsodUsers message from the specified reader or buffer.
         * @function decode
         * @memberof ws_users.AsodUsers
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {ws_users.AsodUsers} AsodUsers
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodUsers.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ws_users.AsodUsers();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.rows && message.rows.length))
                            message.rows = [];
                        message.rows.push($root.ws_users.AsodUsersRow.decode(reader, reader.uint32()));
                        break;
                    }
                case 6: {
                        message.pagination = $root.common.Pagination.decode(reader, reader.uint32());
                        break;
                    }
                case 7: {
                        message.sortBy = reader.int32();
                        break;
                    }
                case 8: {
                        message.sortOrder = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AsodUsers message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof ws_users.AsodUsers
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {ws_users.AsodUsers} AsodUsers
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodUsers.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AsodUsers message.
         * @function verify
         * @memberof ws_users.AsodUsers
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AsodUsers.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.rows != null && message.hasOwnProperty("rows")) {
                if (!Array.isArray(message.rows))
                    return "rows: array expected";
                for (let i = 0; i < message.rows.length; ++i) {
                    let error = $root.ws_users.AsodUsersRow.verify(message.rows[i]);
                    if (error)
                        return "rows." + error;
                }
            }
            if (message.pagination != null && message.hasOwnProperty("pagination")) {
                let error = $root.common.Pagination.verify(message.pagination);
                if (error)
                    return "pagination." + error;
            }
            if (message.sortBy != null && message.hasOwnProperty("sortBy"))
                switch (message.sortBy) {
                default:
                    return "sortBy: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                    break;
                }
            if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                switch (message.sortOrder) {
                default:
                    return "sortOrder: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            return null;
        };

        /**
         * Creates an AsodUsers message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof ws_users.AsodUsers
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {ws_users.AsodUsers} AsodUsers
         */
        AsodUsers.fromObject = function fromObject(object) {
            if (object instanceof $root.ws_users.AsodUsers)
                return object;
            let message = new $root.ws_users.AsodUsers();
            if (object.rows) {
                if (!Array.isArray(object.rows))
                    throw TypeError(".ws_users.AsodUsers.rows: array expected");
                message.rows = [];
                for (let i = 0; i < object.rows.length; ++i) {
                    if (typeof object.rows[i] !== "object")
                        throw TypeError(".ws_users.AsodUsers.rows: object expected");
                    message.rows[i] = $root.ws_users.AsodUsersRow.fromObject(object.rows[i]);
                }
            }
            if (object.pagination != null) {
                if (typeof object.pagination !== "object")
                    throw TypeError(".ws_users.AsodUsers.pagination: object expected");
                message.pagination = $root.common.Pagination.fromObject(object.pagination);
            }
            switch (object.sortBy) {
            default:
                if (typeof object.sortBy === "number") {
                    message.sortBy = object.sortBy;
                    break;
                }
                break;
            case "auot_court":
            case 0:
                message.sortBy = 0;
                break;
            case "auot_name_surname":
            case 1:
                message.sortBy = 1;
                break;
            case "auot_department":
            case 2:
                message.sortBy = 2;
                break;
            case "auot_role":
            case 3:
                message.sortBy = 3;
                break;
            case "auot_last_login":
            case 4:
                message.sortBy = 4;
                break;
            }
            switch (object.sortOrder) {
            default:
                if (typeof object.sortOrder === "number") {
                    message.sortOrder = object.sortOrder;
                    break;
                }
                break;
            case "so_none":
            case 0:
                message.sortOrder = 0;
                break;
            case "so_asc":
            case 1:
                message.sortOrder = 1;
                break;
            case "so_desc":
            case 2:
                message.sortOrder = 2;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from an AsodUsers message. Also converts values to other types if specified.
         * @function toObject
         * @memberof ws_users.AsodUsers
         * @static
         * @param {ws_users.AsodUsers} message AsodUsers
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AsodUsers.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.rows = [];
            if (options.defaults) {
                object.pagination = null;
                object.sortBy = options.enums === String ? "auot_court" : 0;
                object.sortOrder = options.enums === String ? "so_none" : 0;
            }
            if (message.rows && message.rows.length) {
                object.rows = [];
                for (let j = 0; j < message.rows.length; ++j)
                    object.rows[j] = $root.ws_users.AsodUsersRow.toObject(message.rows[j], options);
            }
            if (message.pagination != null && message.hasOwnProperty("pagination"))
                object.pagination = $root.common.Pagination.toObject(message.pagination, options);
            if (message.sortBy != null && message.hasOwnProperty("sortBy"))
                object.sortBy = options.enums === String ? $root.ws_users.AsodUsersOrderingTerm[message.sortBy] === undefined ? message.sortBy : $root.ws_users.AsodUsersOrderingTerm[message.sortBy] : message.sortBy;
            if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                object.sortOrder = options.enums === String ? $root.common.SortOrder[message.sortOrder] === undefined ? message.sortOrder : $root.common.SortOrder[message.sortOrder] : message.sortOrder;
            return object;
        };

        /**
         * Converts this AsodUsers to JSON.
         * @function toJSON
         * @memberof ws_users.AsodUsers
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AsodUsers.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AsodUsers
         * @function getTypeUrl
         * @memberof ws_users.AsodUsers
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AsodUsers.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/ws_users.AsodUsers";
        };

        return AsodUsers;
    })();

    ws_users.AsodUsersRow = (function() {

        /**
         * Properties of an AsodUsersRow.
         * @memberof ws_users
         * @interface IAsodUsersRow
         * @property {string|null} [id] AsodUsersRow id
         * @property {string|null} [court] AsodUsersRow court
         * @property {string|null} [nameSurname] AsodUsersRow nameSurname
         * @property {string|null} [department] AsodUsersRow department
         * @property {string|null} [role] AsodUsersRow role
         * @property {common.IDate|null} [lastLogin] AsodUsersRow lastLogin
         */

        /**
         * Constructs a new AsodUsersRow.
         * @memberof ws_users
         * @classdesc Represents an AsodUsersRow.
         * @implements IAsodUsersRow
         * @constructor
         * @param {ws_users.IAsodUsersRow=} [properties] Properties to set
         */
        function AsodUsersRow(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AsodUsersRow id.
         * @member {string} id
         * @memberof ws_users.AsodUsersRow
         * @instance
         */
        AsodUsersRow.prototype.id = "";

        /**
         * AsodUsersRow court.
         * @member {string} court
         * @memberof ws_users.AsodUsersRow
         * @instance
         */
        AsodUsersRow.prototype.court = "";

        /**
         * AsodUsersRow nameSurname.
         * @member {string} nameSurname
         * @memberof ws_users.AsodUsersRow
         * @instance
         */
        AsodUsersRow.prototype.nameSurname = "";

        /**
         * AsodUsersRow department.
         * @member {string} department
         * @memberof ws_users.AsodUsersRow
         * @instance
         */
        AsodUsersRow.prototype.department = "";

        /**
         * AsodUsersRow role.
         * @member {string} role
         * @memberof ws_users.AsodUsersRow
         * @instance
         */
        AsodUsersRow.prototype.role = "";

        /**
         * AsodUsersRow lastLogin.
         * @member {common.IDate|null|undefined} lastLogin
         * @memberof ws_users.AsodUsersRow
         * @instance
         */
        AsodUsersRow.prototype.lastLogin = null;

        /**
         * Creates a new AsodUsersRow instance using the specified properties.
         * @function create
         * @memberof ws_users.AsodUsersRow
         * @static
         * @param {ws_users.IAsodUsersRow=} [properties] Properties to set
         * @returns {ws_users.AsodUsersRow} AsodUsersRow instance
         */
        AsodUsersRow.create = function create(properties) {
            return new AsodUsersRow(properties);
        };

        /**
         * Encodes the specified AsodUsersRow message. Does not implicitly {@link ws_users.AsodUsersRow.verify|verify} messages.
         * @function encode
         * @memberof ws_users.AsodUsersRow
         * @static
         * @param {ws_users.IAsodUsersRow} message AsodUsersRow message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodUsersRow.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.court != null && Object.hasOwnProperty.call(message, "court"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.court);
            if (message.nameSurname != null && Object.hasOwnProperty.call(message, "nameSurname"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.nameSurname);
            if (message.department != null && Object.hasOwnProperty.call(message, "department"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.department);
            if (message.role != null && Object.hasOwnProperty.call(message, "role"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.role);
            if (message.lastLogin != null && Object.hasOwnProperty.call(message, "lastLogin"))
                $root.common.Date.encode(message.lastLogin, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified AsodUsersRow message, length delimited. Does not implicitly {@link ws_users.AsodUsersRow.verify|verify} messages.
         * @function encodeDelimited
         * @memberof ws_users.AsodUsersRow
         * @static
         * @param {ws_users.IAsodUsersRow} message AsodUsersRow message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodUsersRow.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AsodUsersRow message from the specified reader or buffer.
         * @function decode
         * @memberof ws_users.AsodUsersRow
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {ws_users.AsodUsersRow} AsodUsersRow
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodUsersRow.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ws_users.AsodUsersRow();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 2: {
                        message.court = reader.string();
                        break;
                    }
                case 3: {
                        message.nameSurname = reader.string();
                        break;
                    }
                case 4: {
                        message.department = reader.string();
                        break;
                    }
                case 5: {
                        message.role = reader.string();
                        break;
                    }
                case 6: {
                        message.lastLogin = $root.common.Date.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AsodUsersRow message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof ws_users.AsodUsersRow
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {ws_users.AsodUsersRow} AsodUsersRow
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodUsersRow.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AsodUsersRow message.
         * @function verify
         * @memberof ws_users.AsodUsersRow
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AsodUsersRow.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.court != null && message.hasOwnProperty("court"))
                if (!$util.isString(message.court))
                    return "court: string expected";
            if (message.nameSurname != null && message.hasOwnProperty("nameSurname"))
                if (!$util.isString(message.nameSurname))
                    return "nameSurname: string expected";
            if (message.department != null && message.hasOwnProperty("department"))
                if (!$util.isString(message.department))
                    return "department: string expected";
            if (message.role != null && message.hasOwnProperty("role"))
                if (!$util.isString(message.role))
                    return "role: string expected";
            if (message.lastLogin != null && message.hasOwnProperty("lastLogin")) {
                let error = $root.common.Date.verify(message.lastLogin);
                if (error)
                    return "lastLogin." + error;
            }
            return null;
        };

        /**
         * Creates an AsodUsersRow message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof ws_users.AsodUsersRow
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {ws_users.AsodUsersRow} AsodUsersRow
         */
        AsodUsersRow.fromObject = function fromObject(object) {
            if (object instanceof $root.ws_users.AsodUsersRow)
                return object;
            let message = new $root.ws_users.AsodUsersRow();
            if (object.id != null)
                message.id = String(object.id);
            if (object.court != null)
                message.court = String(object.court);
            if (object.nameSurname != null)
                message.nameSurname = String(object.nameSurname);
            if (object.department != null)
                message.department = String(object.department);
            if (object.role != null)
                message.role = String(object.role);
            if (object.lastLogin != null) {
                if (typeof object.lastLogin !== "object")
                    throw TypeError(".ws_users.AsodUsersRow.lastLogin: object expected");
                message.lastLogin = $root.common.Date.fromObject(object.lastLogin);
            }
            return message;
        };

        /**
         * Creates a plain object from an AsodUsersRow message. Also converts values to other types if specified.
         * @function toObject
         * @memberof ws_users.AsodUsersRow
         * @static
         * @param {ws_users.AsodUsersRow} message AsodUsersRow
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AsodUsersRow.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.id = "";
                object.court = "";
                object.nameSurname = "";
                object.department = "";
                object.role = "";
                object.lastLogin = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.court != null && message.hasOwnProperty("court"))
                object.court = message.court;
            if (message.nameSurname != null && message.hasOwnProperty("nameSurname"))
                object.nameSurname = message.nameSurname;
            if (message.department != null && message.hasOwnProperty("department"))
                object.department = message.department;
            if (message.role != null && message.hasOwnProperty("role"))
                object.role = message.role;
            if (message.lastLogin != null && message.hasOwnProperty("lastLogin"))
                object.lastLogin = $root.common.Date.toObject(message.lastLogin, options);
            return object;
        };

        /**
         * Converts this AsodUsersRow to JSON.
         * @function toJSON
         * @memberof ws_users.AsodUsersRow
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AsodUsersRow.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AsodUsersRow
         * @function getTypeUrl
         * @memberof ws_users.AsodUsersRow
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AsodUsersRow.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/ws_users.AsodUsersRow";
        };

        return AsodUsersRow;
    })();

    /**
     * AsodUsersOrderingTerm enum.
     * @name ws_users.AsodUsersOrderingTerm
     * @enum {number}
     * @property {number} auot_court=0 auot_court value
     * @property {number} auot_name_surname=1 auot_name_surname value
     * @property {number} auot_department=2 auot_department value
     * @property {number} auot_role=3 auot_role value
     * @property {number} auot_last_login=4 auot_last_login value
     */
    ws_users.AsodUsersOrderingTerm = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "auot_court"] = 0;
        values[valuesById[1] = "auot_name_surname"] = 1;
        values[valuesById[2] = "auot_department"] = 2;
        values[valuesById[3] = "auot_role"] = 3;
        values[valuesById[4] = "auot_last_login"] = 4;
        return values;
    })();

    return ws_users;
})();

export const api_obravnave = $root.api_obravnave = (() => {

    /**
     * Namespace api_obravnave.
     * @exports api_obravnave
     * @namespace
     */
    const api_obravnave = {};

    api_obravnave.ApiObravnaveCreateSession = (function() {

        /**
         * Properties of an ApiObravnaveCreateSession.
         * @memberof api_obravnave
         * @interface IApiObravnaveCreateSession
         * @property {string|null} [sessionId] ApiObravnaveCreateSession sessionId
         * @property {string|null} [courtCaseNumber] ApiObravnaveCreateSession courtCaseNumber
         */

        /**
         * Constructs a new ApiObravnaveCreateSession.
         * @memberof api_obravnave
         * @classdesc Represents an ApiObravnaveCreateSession.
         * @implements IApiObravnaveCreateSession
         * @constructor
         * @param {api_obravnave.IApiObravnaveCreateSession=} [properties] Properties to set
         */
        function ApiObravnaveCreateSession(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiObravnaveCreateSession sessionId.
         * @member {string} sessionId
         * @memberof api_obravnave.ApiObravnaveCreateSession
         * @instance
         */
        ApiObravnaveCreateSession.prototype.sessionId = "";

        /**
         * ApiObravnaveCreateSession courtCaseNumber.
         * @member {string} courtCaseNumber
         * @memberof api_obravnave.ApiObravnaveCreateSession
         * @instance
         */
        ApiObravnaveCreateSession.prototype.courtCaseNumber = "";

        /**
         * Creates a new ApiObravnaveCreateSession instance using the specified properties.
         * @function create
         * @memberof api_obravnave.ApiObravnaveCreateSession
         * @static
         * @param {api_obravnave.IApiObravnaveCreateSession=} [properties] Properties to set
         * @returns {api_obravnave.ApiObravnaveCreateSession} ApiObravnaveCreateSession instance
         */
        ApiObravnaveCreateSession.create = function create(properties) {
            return new ApiObravnaveCreateSession(properties);
        };

        /**
         * Encodes the specified ApiObravnaveCreateSession message. Does not implicitly {@link api_obravnave.ApiObravnaveCreateSession.verify|verify} messages.
         * @function encode
         * @memberof api_obravnave.ApiObravnaveCreateSession
         * @static
         * @param {api_obravnave.IApiObravnaveCreateSession} message ApiObravnaveCreateSession message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiObravnaveCreateSession.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sessionId != null && Object.hasOwnProperty.call(message, "sessionId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.sessionId);
            if (message.courtCaseNumber != null && Object.hasOwnProperty.call(message, "courtCaseNumber"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.courtCaseNumber);
            return writer;
        };

        /**
         * Encodes the specified ApiObravnaveCreateSession message, length delimited. Does not implicitly {@link api_obravnave.ApiObravnaveCreateSession.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_obravnave.ApiObravnaveCreateSession
         * @static
         * @param {api_obravnave.IApiObravnaveCreateSession} message ApiObravnaveCreateSession message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiObravnaveCreateSession.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiObravnaveCreateSession message from the specified reader or buffer.
         * @function decode
         * @memberof api_obravnave.ApiObravnaveCreateSession
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_obravnave.ApiObravnaveCreateSession} ApiObravnaveCreateSession
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiObravnaveCreateSession.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_obravnave.ApiObravnaveCreateSession();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sessionId = reader.string();
                        break;
                    }
                case 2: {
                        message.courtCaseNumber = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiObravnaveCreateSession message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_obravnave.ApiObravnaveCreateSession
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_obravnave.ApiObravnaveCreateSession} ApiObravnaveCreateSession
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiObravnaveCreateSession.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiObravnaveCreateSession message.
         * @function verify
         * @memberof api_obravnave.ApiObravnaveCreateSession
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiObravnaveCreateSession.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                if (!$util.isString(message.sessionId))
                    return "sessionId: string expected";
            if (message.courtCaseNumber != null && message.hasOwnProperty("courtCaseNumber"))
                if (!$util.isString(message.courtCaseNumber))
                    return "courtCaseNumber: string expected";
            return null;
        };

        /**
         * Creates an ApiObravnaveCreateSession message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_obravnave.ApiObravnaveCreateSession
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_obravnave.ApiObravnaveCreateSession} ApiObravnaveCreateSession
         */
        ApiObravnaveCreateSession.fromObject = function fromObject(object) {
            if (object instanceof $root.api_obravnave.ApiObravnaveCreateSession)
                return object;
            let message = new $root.api_obravnave.ApiObravnaveCreateSession();
            if (object.sessionId != null)
                message.sessionId = String(object.sessionId);
            if (object.courtCaseNumber != null)
                message.courtCaseNumber = String(object.courtCaseNumber);
            return message;
        };

        /**
         * Creates a plain object from an ApiObravnaveCreateSession message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_obravnave.ApiObravnaveCreateSession
         * @static
         * @param {api_obravnave.ApiObravnaveCreateSession} message ApiObravnaveCreateSession
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiObravnaveCreateSession.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.sessionId = "";
                object.courtCaseNumber = "";
            }
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                object.sessionId = message.sessionId;
            if (message.courtCaseNumber != null && message.hasOwnProperty("courtCaseNumber"))
                object.courtCaseNumber = message.courtCaseNumber;
            return object;
        };

        /**
         * Converts this ApiObravnaveCreateSession to JSON.
         * @function toJSON
         * @memberof api_obravnave.ApiObravnaveCreateSession
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiObravnaveCreateSession.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiObravnaveCreateSession
         * @function getTypeUrl
         * @memberof api_obravnave.ApiObravnaveCreateSession
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiObravnaveCreateSession.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_obravnave.ApiObravnaveCreateSession";
        };

        return ApiObravnaveCreateSession;
    })();

    api_obravnave.ApiObravnaveChangeSessionCourtCaseNumber = (function() {

        /**
         * Properties of an ApiObravnaveChangeSessionCourtCaseNumber.
         * @memberof api_obravnave
         * @interface IApiObravnaveChangeSessionCourtCaseNumber
         * @property {string|null} [sessionId] ApiObravnaveChangeSessionCourtCaseNumber sessionId
         * @property {string|null} [courtCaseNumber] ApiObravnaveChangeSessionCourtCaseNumber courtCaseNumber
         */

        /**
         * Constructs a new ApiObravnaveChangeSessionCourtCaseNumber.
         * @memberof api_obravnave
         * @classdesc Represents an ApiObravnaveChangeSessionCourtCaseNumber.
         * @implements IApiObravnaveChangeSessionCourtCaseNumber
         * @constructor
         * @param {api_obravnave.IApiObravnaveChangeSessionCourtCaseNumber=} [properties] Properties to set
         */
        function ApiObravnaveChangeSessionCourtCaseNumber(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiObravnaveChangeSessionCourtCaseNumber sessionId.
         * @member {string} sessionId
         * @memberof api_obravnave.ApiObravnaveChangeSessionCourtCaseNumber
         * @instance
         */
        ApiObravnaveChangeSessionCourtCaseNumber.prototype.sessionId = "";

        /**
         * ApiObravnaveChangeSessionCourtCaseNumber courtCaseNumber.
         * @member {string} courtCaseNumber
         * @memberof api_obravnave.ApiObravnaveChangeSessionCourtCaseNumber
         * @instance
         */
        ApiObravnaveChangeSessionCourtCaseNumber.prototype.courtCaseNumber = "";

        /**
         * Creates a new ApiObravnaveChangeSessionCourtCaseNumber instance using the specified properties.
         * @function create
         * @memberof api_obravnave.ApiObravnaveChangeSessionCourtCaseNumber
         * @static
         * @param {api_obravnave.IApiObravnaveChangeSessionCourtCaseNumber=} [properties] Properties to set
         * @returns {api_obravnave.ApiObravnaveChangeSessionCourtCaseNumber} ApiObravnaveChangeSessionCourtCaseNumber instance
         */
        ApiObravnaveChangeSessionCourtCaseNumber.create = function create(properties) {
            return new ApiObravnaveChangeSessionCourtCaseNumber(properties);
        };

        /**
         * Encodes the specified ApiObravnaveChangeSessionCourtCaseNumber message. Does not implicitly {@link api_obravnave.ApiObravnaveChangeSessionCourtCaseNumber.verify|verify} messages.
         * @function encode
         * @memberof api_obravnave.ApiObravnaveChangeSessionCourtCaseNumber
         * @static
         * @param {api_obravnave.IApiObravnaveChangeSessionCourtCaseNumber} message ApiObravnaveChangeSessionCourtCaseNumber message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiObravnaveChangeSessionCourtCaseNumber.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sessionId != null && Object.hasOwnProperty.call(message, "sessionId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.sessionId);
            if (message.courtCaseNumber != null && Object.hasOwnProperty.call(message, "courtCaseNumber"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.courtCaseNumber);
            return writer;
        };

        /**
         * Encodes the specified ApiObravnaveChangeSessionCourtCaseNumber message, length delimited. Does not implicitly {@link api_obravnave.ApiObravnaveChangeSessionCourtCaseNumber.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_obravnave.ApiObravnaveChangeSessionCourtCaseNumber
         * @static
         * @param {api_obravnave.IApiObravnaveChangeSessionCourtCaseNumber} message ApiObravnaveChangeSessionCourtCaseNumber message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiObravnaveChangeSessionCourtCaseNumber.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiObravnaveChangeSessionCourtCaseNumber message from the specified reader or buffer.
         * @function decode
         * @memberof api_obravnave.ApiObravnaveChangeSessionCourtCaseNumber
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_obravnave.ApiObravnaveChangeSessionCourtCaseNumber} ApiObravnaveChangeSessionCourtCaseNumber
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiObravnaveChangeSessionCourtCaseNumber.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_obravnave.ApiObravnaveChangeSessionCourtCaseNumber();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sessionId = reader.string();
                        break;
                    }
                case 2: {
                        message.courtCaseNumber = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiObravnaveChangeSessionCourtCaseNumber message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_obravnave.ApiObravnaveChangeSessionCourtCaseNumber
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_obravnave.ApiObravnaveChangeSessionCourtCaseNumber} ApiObravnaveChangeSessionCourtCaseNumber
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiObravnaveChangeSessionCourtCaseNumber.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiObravnaveChangeSessionCourtCaseNumber message.
         * @function verify
         * @memberof api_obravnave.ApiObravnaveChangeSessionCourtCaseNumber
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiObravnaveChangeSessionCourtCaseNumber.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                if (!$util.isString(message.sessionId))
                    return "sessionId: string expected";
            if (message.courtCaseNumber != null && message.hasOwnProperty("courtCaseNumber"))
                if (!$util.isString(message.courtCaseNumber))
                    return "courtCaseNumber: string expected";
            return null;
        };

        /**
         * Creates an ApiObravnaveChangeSessionCourtCaseNumber message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_obravnave.ApiObravnaveChangeSessionCourtCaseNumber
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_obravnave.ApiObravnaveChangeSessionCourtCaseNumber} ApiObravnaveChangeSessionCourtCaseNumber
         */
        ApiObravnaveChangeSessionCourtCaseNumber.fromObject = function fromObject(object) {
            if (object instanceof $root.api_obravnave.ApiObravnaveChangeSessionCourtCaseNumber)
                return object;
            let message = new $root.api_obravnave.ApiObravnaveChangeSessionCourtCaseNumber();
            if (object.sessionId != null)
                message.sessionId = String(object.sessionId);
            if (object.courtCaseNumber != null)
                message.courtCaseNumber = String(object.courtCaseNumber);
            return message;
        };

        /**
         * Creates a plain object from an ApiObravnaveChangeSessionCourtCaseNumber message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_obravnave.ApiObravnaveChangeSessionCourtCaseNumber
         * @static
         * @param {api_obravnave.ApiObravnaveChangeSessionCourtCaseNumber} message ApiObravnaveChangeSessionCourtCaseNumber
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiObravnaveChangeSessionCourtCaseNumber.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.sessionId = "";
                object.courtCaseNumber = "";
            }
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                object.sessionId = message.sessionId;
            if (message.courtCaseNumber != null && message.hasOwnProperty("courtCaseNumber"))
                object.courtCaseNumber = message.courtCaseNumber;
            return object;
        };

        /**
         * Converts this ApiObravnaveChangeSessionCourtCaseNumber to JSON.
         * @function toJSON
         * @memberof api_obravnave.ApiObravnaveChangeSessionCourtCaseNumber
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiObravnaveChangeSessionCourtCaseNumber.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiObravnaveChangeSessionCourtCaseNumber
         * @function getTypeUrl
         * @memberof api_obravnave.ApiObravnaveChangeSessionCourtCaseNumber
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiObravnaveChangeSessionCourtCaseNumber.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_obravnave.ApiObravnaveChangeSessionCourtCaseNumber";
        };

        return ApiObravnaveChangeSessionCourtCaseNumber;
    })();

    api_obravnave.ApiObravnaveCloseSession = (function() {

        /**
         * Properties of an ApiObravnaveCloseSession.
         * @memberof api_obravnave
         * @interface IApiObravnaveCloseSession
         * @property {string|null} [sessionId] ApiObravnaveCloseSession sessionId
         */

        /**
         * Constructs a new ApiObravnaveCloseSession.
         * @memberof api_obravnave
         * @classdesc Represents an ApiObravnaveCloseSession.
         * @implements IApiObravnaveCloseSession
         * @constructor
         * @param {api_obravnave.IApiObravnaveCloseSession=} [properties] Properties to set
         */
        function ApiObravnaveCloseSession(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiObravnaveCloseSession sessionId.
         * @member {string} sessionId
         * @memberof api_obravnave.ApiObravnaveCloseSession
         * @instance
         */
        ApiObravnaveCloseSession.prototype.sessionId = "";

        /**
         * Creates a new ApiObravnaveCloseSession instance using the specified properties.
         * @function create
         * @memberof api_obravnave.ApiObravnaveCloseSession
         * @static
         * @param {api_obravnave.IApiObravnaveCloseSession=} [properties] Properties to set
         * @returns {api_obravnave.ApiObravnaveCloseSession} ApiObravnaveCloseSession instance
         */
        ApiObravnaveCloseSession.create = function create(properties) {
            return new ApiObravnaveCloseSession(properties);
        };

        /**
         * Encodes the specified ApiObravnaveCloseSession message. Does not implicitly {@link api_obravnave.ApiObravnaveCloseSession.verify|verify} messages.
         * @function encode
         * @memberof api_obravnave.ApiObravnaveCloseSession
         * @static
         * @param {api_obravnave.IApiObravnaveCloseSession} message ApiObravnaveCloseSession message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiObravnaveCloseSession.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sessionId != null && Object.hasOwnProperty.call(message, "sessionId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.sessionId);
            return writer;
        };

        /**
         * Encodes the specified ApiObravnaveCloseSession message, length delimited. Does not implicitly {@link api_obravnave.ApiObravnaveCloseSession.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_obravnave.ApiObravnaveCloseSession
         * @static
         * @param {api_obravnave.IApiObravnaveCloseSession} message ApiObravnaveCloseSession message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiObravnaveCloseSession.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiObravnaveCloseSession message from the specified reader or buffer.
         * @function decode
         * @memberof api_obravnave.ApiObravnaveCloseSession
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_obravnave.ApiObravnaveCloseSession} ApiObravnaveCloseSession
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiObravnaveCloseSession.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_obravnave.ApiObravnaveCloseSession();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sessionId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiObravnaveCloseSession message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_obravnave.ApiObravnaveCloseSession
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_obravnave.ApiObravnaveCloseSession} ApiObravnaveCloseSession
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiObravnaveCloseSession.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiObravnaveCloseSession message.
         * @function verify
         * @memberof api_obravnave.ApiObravnaveCloseSession
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiObravnaveCloseSession.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                if (!$util.isString(message.sessionId))
                    return "sessionId: string expected";
            return null;
        };

        /**
         * Creates an ApiObravnaveCloseSession message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_obravnave.ApiObravnaveCloseSession
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_obravnave.ApiObravnaveCloseSession} ApiObravnaveCloseSession
         */
        ApiObravnaveCloseSession.fromObject = function fromObject(object) {
            if (object instanceof $root.api_obravnave.ApiObravnaveCloseSession)
                return object;
            let message = new $root.api_obravnave.ApiObravnaveCloseSession();
            if (object.sessionId != null)
                message.sessionId = String(object.sessionId);
            return message;
        };

        /**
         * Creates a plain object from an ApiObravnaveCloseSession message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_obravnave.ApiObravnaveCloseSession
         * @static
         * @param {api_obravnave.ApiObravnaveCloseSession} message ApiObravnaveCloseSession
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiObravnaveCloseSession.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.sessionId = "";
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                object.sessionId = message.sessionId;
            return object;
        };

        /**
         * Converts this ApiObravnaveCloseSession to JSON.
         * @function toJSON
         * @memberof api_obravnave.ApiObravnaveCloseSession
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiObravnaveCloseSession.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiObravnaveCloseSession
         * @function getTypeUrl
         * @memberof api_obravnave.ApiObravnaveCloseSession
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiObravnaveCloseSession.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_obravnave.ApiObravnaveCloseSession";
        };

        return ApiObravnaveCloseSession;
    })();

    api_obravnave.ApiObravnaveInitiateSegmentUpload = (function() {

        /**
         * Properties of an ApiObravnaveInitiateSegmentUpload.
         * @memberof api_obravnave
         * @interface IApiObravnaveInitiateSegmentUpload
         * @property {string|null} [sessionId] ApiObravnaveInitiateSegmentUpload sessionId
         * @property {string|null} [segmentId] ApiObravnaveInitiateSegmentUpload segmentId
         * @property {number|null} [startTime] ApiObravnaveInitiateSegmentUpload startTime
         * @property {number|null} [endTime] ApiObravnaveInitiateSegmentUpload endTime
         * @property {number|null} [channelCount] ApiObravnaveInitiateSegmentUpload channelCount
         * @property {string|null} [hash] ApiObravnaveInitiateSegmentUpload hash
         * @property {number|null} [order] ApiObravnaveInitiateSegmentUpload order
         * @property {Array.<number>|null} [waveform] ApiObravnaveInitiateSegmentUpload waveform
         */

        /**
         * Constructs a new ApiObravnaveInitiateSegmentUpload.
         * @memberof api_obravnave
         * @classdesc Represents an ApiObravnaveInitiateSegmentUpload.
         * @implements IApiObravnaveInitiateSegmentUpload
         * @constructor
         * @param {api_obravnave.IApiObravnaveInitiateSegmentUpload=} [properties] Properties to set
         */
        function ApiObravnaveInitiateSegmentUpload(properties) {
            this.waveform = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiObravnaveInitiateSegmentUpload sessionId.
         * @member {string} sessionId
         * @memberof api_obravnave.ApiObravnaveInitiateSegmentUpload
         * @instance
         */
        ApiObravnaveInitiateSegmentUpload.prototype.sessionId = "";

        /**
         * ApiObravnaveInitiateSegmentUpload segmentId.
         * @member {string} segmentId
         * @memberof api_obravnave.ApiObravnaveInitiateSegmentUpload
         * @instance
         */
        ApiObravnaveInitiateSegmentUpload.prototype.segmentId = "";

        /**
         * ApiObravnaveInitiateSegmentUpload startTime.
         * @member {number} startTime
         * @memberof api_obravnave.ApiObravnaveInitiateSegmentUpload
         * @instance
         */
        ApiObravnaveInitiateSegmentUpload.prototype.startTime = 0;

        /**
         * ApiObravnaveInitiateSegmentUpload endTime.
         * @member {number} endTime
         * @memberof api_obravnave.ApiObravnaveInitiateSegmentUpload
         * @instance
         */
        ApiObravnaveInitiateSegmentUpload.prototype.endTime = 0;

        /**
         * ApiObravnaveInitiateSegmentUpload channelCount.
         * @member {number} channelCount
         * @memberof api_obravnave.ApiObravnaveInitiateSegmentUpload
         * @instance
         */
        ApiObravnaveInitiateSegmentUpload.prototype.channelCount = 0;

        /**
         * ApiObravnaveInitiateSegmentUpload hash.
         * @member {string} hash
         * @memberof api_obravnave.ApiObravnaveInitiateSegmentUpload
         * @instance
         */
        ApiObravnaveInitiateSegmentUpload.prototype.hash = "";

        /**
         * ApiObravnaveInitiateSegmentUpload order.
         * @member {number} order
         * @memberof api_obravnave.ApiObravnaveInitiateSegmentUpload
         * @instance
         */
        ApiObravnaveInitiateSegmentUpload.prototype.order = 0;

        /**
         * ApiObravnaveInitiateSegmentUpload waveform.
         * @member {Array.<number>} waveform
         * @memberof api_obravnave.ApiObravnaveInitiateSegmentUpload
         * @instance
         */
        ApiObravnaveInitiateSegmentUpload.prototype.waveform = $util.emptyArray;

        /**
         * Creates a new ApiObravnaveInitiateSegmentUpload instance using the specified properties.
         * @function create
         * @memberof api_obravnave.ApiObravnaveInitiateSegmentUpload
         * @static
         * @param {api_obravnave.IApiObravnaveInitiateSegmentUpload=} [properties] Properties to set
         * @returns {api_obravnave.ApiObravnaveInitiateSegmentUpload} ApiObravnaveInitiateSegmentUpload instance
         */
        ApiObravnaveInitiateSegmentUpload.create = function create(properties) {
            return new ApiObravnaveInitiateSegmentUpload(properties);
        };

        /**
         * Encodes the specified ApiObravnaveInitiateSegmentUpload message. Does not implicitly {@link api_obravnave.ApiObravnaveInitiateSegmentUpload.verify|verify} messages.
         * @function encode
         * @memberof api_obravnave.ApiObravnaveInitiateSegmentUpload
         * @static
         * @param {api_obravnave.IApiObravnaveInitiateSegmentUpload} message ApiObravnaveInitiateSegmentUpload message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiObravnaveInitiateSegmentUpload.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sessionId != null && Object.hasOwnProperty.call(message, "sessionId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.sessionId);
            if (message.segmentId != null && Object.hasOwnProperty.call(message, "segmentId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.segmentId);
            if (message.startTime != null && Object.hasOwnProperty.call(message, "startTime"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.startTime);
            if (message.endTime != null && Object.hasOwnProperty.call(message, "endTime"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.endTime);
            if (message.channelCount != null && Object.hasOwnProperty.call(message, "channelCount"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.channelCount);
            if (message.hash != null && Object.hasOwnProperty.call(message, "hash"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.hash);
            if (message.order != null && Object.hasOwnProperty.call(message, "order"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.order);
            if (message.waveform != null && message.waveform.length) {
                writer.uint32(/* id 8, wireType 2 =*/66).fork();
                for (let i = 0; i < message.waveform.length; ++i)
                    writer.float(message.waveform[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Encodes the specified ApiObravnaveInitiateSegmentUpload message, length delimited. Does not implicitly {@link api_obravnave.ApiObravnaveInitiateSegmentUpload.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_obravnave.ApiObravnaveInitiateSegmentUpload
         * @static
         * @param {api_obravnave.IApiObravnaveInitiateSegmentUpload} message ApiObravnaveInitiateSegmentUpload message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiObravnaveInitiateSegmentUpload.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiObravnaveInitiateSegmentUpload message from the specified reader or buffer.
         * @function decode
         * @memberof api_obravnave.ApiObravnaveInitiateSegmentUpload
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_obravnave.ApiObravnaveInitiateSegmentUpload} ApiObravnaveInitiateSegmentUpload
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiObravnaveInitiateSegmentUpload.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_obravnave.ApiObravnaveInitiateSegmentUpload();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sessionId = reader.string();
                        break;
                    }
                case 2: {
                        message.segmentId = reader.string();
                        break;
                    }
                case 3: {
                        message.startTime = reader.int32();
                        break;
                    }
                case 4: {
                        message.endTime = reader.int32();
                        break;
                    }
                case 5: {
                        message.channelCount = reader.int32();
                        break;
                    }
                case 6: {
                        message.hash = reader.string();
                        break;
                    }
                case 7: {
                        message.order = reader.int32();
                        break;
                    }
                case 8: {
                        if (!(message.waveform && message.waveform.length))
                            message.waveform = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.waveform.push(reader.float());
                        } else
                            message.waveform.push(reader.float());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiObravnaveInitiateSegmentUpload message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_obravnave.ApiObravnaveInitiateSegmentUpload
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_obravnave.ApiObravnaveInitiateSegmentUpload} ApiObravnaveInitiateSegmentUpload
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiObravnaveInitiateSegmentUpload.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiObravnaveInitiateSegmentUpload message.
         * @function verify
         * @memberof api_obravnave.ApiObravnaveInitiateSegmentUpload
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiObravnaveInitiateSegmentUpload.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                if (!$util.isString(message.sessionId))
                    return "sessionId: string expected";
            if (message.segmentId != null && message.hasOwnProperty("segmentId"))
                if (!$util.isString(message.segmentId))
                    return "segmentId: string expected";
            if (message.startTime != null && message.hasOwnProperty("startTime"))
                if (!$util.isInteger(message.startTime))
                    return "startTime: integer expected";
            if (message.endTime != null && message.hasOwnProperty("endTime"))
                if (!$util.isInteger(message.endTime))
                    return "endTime: integer expected";
            if (message.channelCount != null && message.hasOwnProperty("channelCount"))
                if (!$util.isInteger(message.channelCount))
                    return "channelCount: integer expected";
            if (message.hash != null && message.hasOwnProperty("hash"))
                if (!$util.isString(message.hash))
                    return "hash: string expected";
            if (message.order != null && message.hasOwnProperty("order"))
                if (!$util.isInteger(message.order))
                    return "order: integer expected";
            if (message.waveform != null && message.hasOwnProperty("waveform")) {
                if (!Array.isArray(message.waveform))
                    return "waveform: array expected";
                for (let i = 0; i < message.waveform.length; ++i)
                    if (typeof message.waveform[i] !== "number")
                        return "waveform: number[] expected";
            }
            return null;
        };

        /**
         * Creates an ApiObravnaveInitiateSegmentUpload message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_obravnave.ApiObravnaveInitiateSegmentUpload
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_obravnave.ApiObravnaveInitiateSegmentUpload} ApiObravnaveInitiateSegmentUpload
         */
        ApiObravnaveInitiateSegmentUpload.fromObject = function fromObject(object) {
            if (object instanceof $root.api_obravnave.ApiObravnaveInitiateSegmentUpload)
                return object;
            let message = new $root.api_obravnave.ApiObravnaveInitiateSegmentUpload();
            if (object.sessionId != null)
                message.sessionId = String(object.sessionId);
            if (object.segmentId != null)
                message.segmentId = String(object.segmentId);
            if (object.startTime != null)
                message.startTime = object.startTime | 0;
            if (object.endTime != null)
                message.endTime = object.endTime | 0;
            if (object.channelCount != null)
                message.channelCount = object.channelCount | 0;
            if (object.hash != null)
                message.hash = String(object.hash);
            if (object.order != null)
                message.order = object.order | 0;
            if (object.waveform) {
                if (!Array.isArray(object.waveform))
                    throw TypeError(".api_obravnave.ApiObravnaveInitiateSegmentUpload.waveform: array expected");
                message.waveform = [];
                for (let i = 0; i < object.waveform.length; ++i)
                    message.waveform[i] = Number(object.waveform[i]);
            }
            return message;
        };

        /**
         * Creates a plain object from an ApiObravnaveInitiateSegmentUpload message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_obravnave.ApiObravnaveInitiateSegmentUpload
         * @static
         * @param {api_obravnave.ApiObravnaveInitiateSegmentUpload} message ApiObravnaveInitiateSegmentUpload
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiObravnaveInitiateSegmentUpload.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.waveform = [];
            if (options.defaults) {
                object.sessionId = "";
                object.segmentId = "";
                object.startTime = 0;
                object.endTime = 0;
                object.channelCount = 0;
                object.hash = "";
                object.order = 0;
            }
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                object.sessionId = message.sessionId;
            if (message.segmentId != null && message.hasOwnProperty("segmentId"))
                object.segmentId = message.segmentId;
            if (message.startTime != null && message.hasOwnProperty("startTime"))
                object.startTime = message.startTime;
            if (message.endTime != null && message.hasOwnProperty("endTime"))
                object.endTime = message.endTime;
            if (message.channelCount != null && message.hasOwnProperty("channelCount"))
                object.channelCount = message.channelCount;
            if (message.hash != null && message.hasOwnProperty("hash"))
                object.hash = message.hash;
            if (message.order != null && message.hasOwnProperty("order"))
                object.order = message.order;
            if (message.waveform && message.waveform.length) {
                object.waveform = [];
                for (let j = 0; j < message.waveform.length; ++j)
                    object.waveform[j] = options.json && !isFinite(message.waveform[j]) ? String(message.waveform[j]) : message.waveform[j];
            }
            return object;
        };

        /**
         * Converts this ApiObravnaveInitiateSegmentUpload to JSON.
         * @function toJSON
         * @memberof api_obravnave.ApiObravnaveInitiateSegmentUpload
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiObravnaveInitiateSegmentUpload.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiObravnaveInitiateSegmentUpload
         * @function getTypeUrl
         * @memberof api_obravnave.ApiObravnaveInitiateSegmentUpload
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiObravnaveInitiateSegmentUpload.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_obravnave.ApiObravnaveInitiateSegmentUpload";
        };

        return ApiObravnaveInitiateSegmentUpload;
    })();

    api_obravnave.ApiObravnaveSegmentUploadInfo = (function() {

        /**
         * Properties of an ApiObravnaveSegmentUploadInfo.
         * @memberof api_obravnave
         * @interface IApiObravnaveSegmentUploadInfo
         * @property {string|null} [sourceEventId] ApiObravnaveSegmentUploadInfo sourceEventId
         * @property {string|null} [sessionId] ApiObravnaveSegmentUploadInfo sessionId
         * @property {string|null} [segmentId] ApiObravnaveSegmentUploadInfo segmentId
         * @property {string|null} [uploadUrl] ApiObravnaveSegmentUploadInfo uploadUrl
         */

        /**
         * Constructs a new ApiObravnaveSegmentUploadInfo.
         * @memberof api_obravnave
         * @classdesc Represents an ApiObravnaveSegmentUploadInfo.
         * @implements IApiObravnaveSegmentUploadInfo
         * @constructor
         * @param {api_obravnave.IApiObravnaveSegmentUploadInfo=} [properties] Properties to set
         */
        function ApiObravnaveSegmentUploadInfo(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiObravnaveSegmentUploadInfo sourceEventId.
         * @member {string} sourceEventId
         * @memberof api_obravnave.ApiObravnaveSegmentUploadInfo
         * @instance
         */
        ApiObravnaveSegmentUploadInfo.prototype.sourceEventId = "";

        /**
         * ApiObravnaveSegmentUploadInfo sessionId.
         * @member {string} sessionId
         * @memberof api_obravnave.ApiObravnaveSegmentUploadInfo
         * @instance
         */
        ApiObravnaveSegmentUploadInfo.prototype.sessionId = "";

        /**
         * ApiObravnaveSegmentUploadInfo segmentId.
         * @member {string} segmentId
         * @memberof api_obravnave.ApiObravnaveSegmentUploadInfo
         * @instance
         */
        ApiObravnaveSegmentUploadInfo.prototype.segmentId = "";

        /**
         * ApiObravnaveSegmentUploadInfo uploadUrl.
         * @member {string} uploadUrl
         * @memberof api_obravnave.ApiObravnaveSegmentUploadInfo
         * @instance
         */
        ApiObravnaveSegmentUploadInfo.prototype.uploadUrl = "";

        /**
         * Creates a new ApiObravnaveSegmentUploadInfo instance using the specified properties.
         * @function create
         * @memberof api_obravnave.ApiObravnaveSegmentUploadInfo
         * @static
         * @param {api_obravnave.IApiObravnaveSegmentUploadInfo=} [properties] Properties to set
         * @returns {api_obravnave.ApiObravnaveSegmentUploadInfo} ApiObravnaveSegmentUploadInfo instance
         */
        ApiObravnaveSegmentUploadInfo.create = function create(properties) {
            return new ApiObravnaveSegmentUploadInfo(properties);
        };

        /**
         * Encodes the specified ApiObravnaveSegmentUploadInfo message. Does not implicitly {@link api_obravnave.ApiObravnaveSegmentUploadInfo.verify|verify} messages.
         * @function encode
         * @memberof api_obravnave.ApiObravnaveSegmentUploadInfo
         * @static
         * @param {api_obravnave.IApiObravnaveSegmentUploadInfo} message ApiObravnaveSegmentUploadInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiObravnaveSegmentUploadInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sourceEventId != null && Object.hasOwnProperty.call(message, "sourceEventId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.sourceEventId);
            if (message.sessionId != null && Object.hasOwnProperty.call(message, "sessionId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.sessionId);
            if (message.segmentId != null && Object.hasOwnProperty.call(message, "segmentId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.segmentId);
            if (message.uploadUrl != null && Object.hasOwnProperty.call(message, "uploadUrl"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.uploadUrl);
            return writer;
        };

        /**
         * Encodes the specified ApiObravnaveSegmentUploadInfo message, length delimited. Does not implicitly {@link api_obravnave.ApiObravnaveSegmentUploadInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_obravnave.ApiObravnaveSegmentUploadInfo
         * @static
         * @param {api_obravnave.IApiObravnaveSegmentUploadInfo} message ApiObravnaveSegmentUploadInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiObravnaveSegmentUploadInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiObravnaveSegmentUploadInfo message from the specified reader or buffer.
         * @function decode
         * @memberof api_obravnave.ApiObravnaveSegmentUploadInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_obravnave.ApiObravnaveSegmentUploadInfo} ApiObravnaveSegmentUploadInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiObravnaveSegmentUploadInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_obravnave.ApiObravnaveSegmentUploadInfo();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sourceEventId = reader.string();
                        break;
                    }
                case 2: {
                        message.sessionId = reader.string();
                        break;
                    }
                case 3: {
                        message.segmentId = reader.string();
                        break;
                    }
                case 4: {
                        message.uploadUrl = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiObravnaveSegmentUploadInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_obravnave.ApiObravnaveSegmentUploadInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_obravnave.ApiObravnaveSegmentUploadInfo} ApiObravnaveSegmentUploadInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiObravnaveSegmentUploadInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiObravnaveSegmentUploadInfo message.
         * @function verify
         * @memberof api_obravnave.ApiObravnaveSegmentUploadInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiObravnaveSegmentUploadInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.sourceEventId != null && message.hasOwnProperty("sourceEventId"))
                if (!$util.isString(message.sourceEventId))
                    return "sourceEventId: string expected";
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                if (!$util.isString(message.sessionId))
                    return "sessionId: string expected";
            if (message.segmentId != null && message.hasOwnProperty("segmentId"))
                if (!$util.isString(message.segmentId))
                    return "segmentId: string expected";
            if (message.uploadUrl != null && message.hasOwnProperty("uploadUrl"))
                if (!$util.isString(message.uploadUrl))
                    return "uploadUrl: string expected";
            return null;
        };

        /**
         * Creates an ApiObravnaveSegmentUploadInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_obravnave.ApiObravnaveSegmentUploadInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_obravnave.ApiObravnaveSegmentUploadInfo} ApiObravnaveSegmentUploadInfo
         */
        ApiObravnaveSegmentUploadInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.api_obravnave.ApiObravnaveSegmentUploadInfo)
                return object;
            let message = new $root.api_obravnave.ApiObravnaveSegmentUploadInfo();
            if (object.sourceEventId != null)
                message.sourceEventId = String(object.sourceEventId);
            if (object.sessionId != null)
                message.sessionId = String(object.sessionId);
            if (object.segmentId != null)
                message.segmentId = String(object.segmentId);
            if (object.uploadUrl != null)
                message.uploadUrl = String(object.uploadUrl);
            return message;
        };

        /**
         * Creates a plain object from an ApiObravnaveSegmentUploadInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_obravnave.ApiObravnaveSegmentUploadInfo
         * @static
         * @param {api_obravnave.ApiObravnaveSegmentUploadInfo} message ApiObravnaveSegmentUploadInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiObravnaveSegmentUploadInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.sourceEventId = "";
                object.sessionId = "";
                object.segmentId = "";
                object.uploadUrl = "";
            }
            if (message.sourceEventId != null && message.hasOwnProperty("sourceEventId"))
                object.sourceEventId = message.sourceEventId;
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                object.sessionId = message.sessionId;
            if (message.segmentId != null && message.hasOwnProperty("segmentId"))
                object.segmentId = message.segmentId;
            if (message.uploadUrl != null && message.hasOwnProperty("uploadUrl"))
                object.uploadUrl = message.uploadUrl;
            return object;
        };

        /**
         * Converts this ApiObravnaveSegmentUploadInfo to JSON.
         * @function toJSON
         * @memberof api_obravnave.ApiObravnaveSegmentUploadInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiObravnaveSegmentUploadInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiObravnaveSegmentUploadInfo
         * @function getTypeUrl
         * @memberof api_obravnave.ApiObravnaveSegmentUploadInfo
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiObravnaveSegmentUploadInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_obravnave.ApiObravnaveSegmentUploadInfo";
        };

        return ApiObravnaveSegmentUploadInfo;
    })();

    return api_obravnave;
})();

export const api_komentarji = $root.api_komentarji = (() => {

    /**
     * Namespace api_komentarji.
     * @exports api_komentarji
     * @namespace
     */
    const api_komentarji = {};

    api_komentarji.ApiKomentarjiAddAnnotation = (function() {

        /**
         * Properties of an ApiKomentarjiAddAnnotation.
         * @memberof api_komentarji
         * @interface IApiKomentarjiAddAnnotation
         * @property {string|null} [sessionId] ApiKomentarjiAddAnnotation sessionId
         * @property {string|null} [annotationId] ApiKomentarjiAddAnnotation annotationId
         * @property {string|null} [content] ApiKomentarjiAddAnnotation content
         * @property {boolean|null} [publiclyAccessible] ApiKomentarjiAddAnnotation publiclyAccessible
         * @property {number|Long|null} [annotationTimestamp] ApiKomentarjiAddAnnotation annotationTimestamp
         */

        /**
         * Constructs a new ApiKomentarjiAddAnnotation.
         * @memberof api_komentarji
         * @classdesc Represents an ApiKomentarjiAddAnnotation.
         * @implements IApiKomentarjiAddAnnotation
         * @constructor
         * @param {api_komentarji.IApiKomentarjiAddAnnotation=} [properties] Properties to set
         */
        function ApiKomentarjiAddAnnotation(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiKomentarjiAddAnnotation sessionId.
         * @member {string} sessionId
         * @memberof api_komentarji.ApiKomentarjiAddAnnotation
         * @instance
         */
        ApiKomentarjiAddAnnotation.prototype.sessionId = "";

        /**
         * ApiKomentarjiAddAnnotation annotationId.
         * @member {string} annotationId
         * @memberof api_komentarji.ApiKomentarjiAddAnnotation
         * @instance
         */
        ApiKomentarjiAddAnnotation.prototype.annotationId = "";

        /**
         * ApiKomentarjiAddAnnotation content.
         * @member {string} content
         * @memberof api_komentarji.ApiKomentarjiAddAnnotation
         * @instance
         */
        ApiKomentarjiAddAnnotation.prototype.content = "";

        /**
         * ApiKomentarjiAddAnnotation publiclyAccessible.
         * @member {boolean} publiclyAccessible
         * @memberof api_komentarji.ApiKomentarjiAddAnnotation
         * @instance
         */
        ApiKomentarjiAddAnnotation.prototype.publiclyAccessible = false;

        /**
         * ApiKomentarjiAddAnnotation annotationTimestamp.
         * @member {number|Long} annotationTimestamp
         * @memberof api_komentarji.ApiKomentarjiAddAnnotation
         * @instance
         */
        ApiKomentarjiAddAnnotation.prototype.annotationTimestamp = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new ApiKomentarjiAddAnnotation instance using the specified properties.
         * @function create
         * @memberof api_komentarji.ApiKomentarjiAddAnnotation
         * @static
         * @param {api_komentarji.IApiKomentarjiAddAnnotation=} [properties] Properties to set
         * @returns {api_komentarji.ApiKomentarjiAddAnnotation} ApiKomentarjiAddAnnotation instance
         */
        ApiKomentarjiAddAnnotation.create = function create(properties) {
            return new ApiKomentarjiAddAnnotation(properties);
        };

        /**
         * Encodes the specified ApiKomentarjiAddAnnotation message. Does not implicitly {@link api_komentarji.ApiKomentarjiAddAnnotation.verify|verify} messages.
         * @function encode
         * @memberof api_komentarji.ApiKomentarjiAddAnnotation
         * @static
         * @param {api_komentarji.IApiKomentarjiAddAnnotation} message ApiKomentarjiAddAnnotation message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiKomentarjiAddAnnotation.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sessionId != null && Object.hasOwnProperty.call(message, "sessionId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.sessionId);
            if (message.annotationId != null && Object.hasOwnProperty.call(message, "annotationId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.annotationId);
            if (message.content != null && Object.hasOwnProperty.call(message, "content"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.content);
            if (message.publiclyAccessible != null && Object.hasOwnProperty.call(message, "publiclyAccessible"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.publiclyAccessible);
            if (message.annotationTimestamp != null && Object.hasOwnProperty.call(message, "annotationTimestamp"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint64(message.annotationTimestamp);
            return writer;
        };

        /**
         * Encodes the specified ApiKomentarjiAddAnnotation message, length delimited. Does not implicitly {@link api_komentarji.ApiKomentarjiAddAnnotation.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_komentarji.ApiKomentarjiAddAnnotation
         * @static
         * @param {api_komentarji.IApiKomentarjiAddAnnotation} message ApiKomentarjiAddAnnotation message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiKomentarjiAddAnnotation.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiKomentarjiAddAnnotation message from the specified reader or buffer.
         * @function decode
         * @memberof api_komentarji.ApiKomentarjiAddAnnotation
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_komentarji.ApiKomentarjiAddAnnotation} ApiKomentarjiAddAnnotation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiKomentarjiAddAnnotation.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_komentarji.ApiKomentarjiAddAnnotation();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sessionId = reader.string();
                        break;
                    }
                case 2: {
                        message.annotationId = reader.string();
                        break;
                    }
                case 3: {
                        message.content = reader.string();
                        break;
                    }
                case 4: {
                        message.publiclyAccessible = reader.bool();
                        break;
                    }
                case 5: {
                        message.annotationTimestamp = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiKomentarjiAddAnnotation message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_komentarji.ApiKomentarjiAddAnnotation
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_komentarji.ApiKomentarjiAddAnnotation} ApiKomentarjiAddAnnotation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiKomentarjiAddAnnotation.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiKomentarjiAddAnnotation message.
         * @function verify
         * @memberof api_komentarji.ApiKomentarjiAddAnnotation
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiKomentarjiAddAnnotation.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                if (!$util.isString(message.sessionId))
                    return "sessionId: string expected";
            if (message.annotationId != null && message.hasOwnProperty("annotationId"))
                if (!$util.isString(message.annotationId))
                    return "annotationId: string expected";
            if (message.content != null && message.hasOwnProperty("content"))
                if (!$util.isString(message.content))
                    return "content: string expected";
            if (message.publiclyAccessible != null && message.hasOwnProperty("publiclyAccessible"))
                if (typeof message.publiclyAccessible !== "boolean")
                    return "publiclyAccessible: boolean expected";
            if (message.annotationTimestamp != null && message.hasOwnProperty("annotationTimestamp"))
                if (!$util.isInteger(message.annotationTimestamp) && !(message.annotationTimestamp && $util.isInteger(message.annotationTimestamp.low) && $util.isInteger(message.annotationTimestamp.high)))
                    return "annotationTimestamp: integer|Long expected";
            return null;
        };

        /**
         * Creates an ApiKomentarjiAddAnnotation message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_komentarji.ApiKomentarjiAddAnnotation
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_komentarji.ApiKomentarjiAddAnnotation} ApiKomentarjiAddAnnotation
         */
        ApiKomentarjiAddAnnotation.fromObject = function fromObject(object) {
            if (object instanceof $root.api_komentarji.ApiKomentarjiAddAnnotation)
                return object;
            let message = new $root.api_komentarji.ApiKomentarjiAddAnnotation();
            if (object.sessionId != null)
                message.sessionId = String(object.sessionId);
            if (object.annotationId != null)
                message.annotationId = String(object.annotationId);
            if (object.content != null)
                message.content = String(object.content);
            if (object.publiclyAccessible != null)
                message.publiclyAccessible = Boolean(object.publiclyAccessible);
            if (object.annotationTimestamp != null)
                if ($util.Long)
                    (message.annotationTimestamp = $util.Long.fromValue(object.annotationTimestamp)).unsigned = true;
                else if (typeof object.annotationTimestamp === "string")
                    message.annotationTimestamp = parseInt(object.annotationTimestamp, 10);
                else if (typeof object.annotationTimestamp === "number")
                    message.annotationTimestamp = object.annotationTimestamp;
                else if (typeof object.annotationTimestamp === "object")
                    message.annotationTimestamp = new $util.LongBits(object.annotationTimestamp.low >>> 0, object.annotationTimestamp.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from an ApiKomentarjiAddAnnotation message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_komentarji.ApiKomentarjiAddAnnotation
         * @static
         * @param {api_komentarji.ApiKomentarjiAddAnnotation} message ApiKomentarjiAddAnnotation
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiKomentarjiAddAnnotation.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.sessionId = "";
                object.annotationId = "";
                object.content = "";
                object.publiclyAccessible = false;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.annotationTimestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.annotationTimestamp = options.longs === String ? "0" : 0;
            }
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                object.sessionId = message.sessionId;
            if (message.annotationId != null && message.hasOwnProperty("annotationId"))
                object.annotationId = message.annotationId;
            if (message.content != null && message.hasOwnProperty("content"))
                object.content = message.content;
            if (message.publiclyAccessible != null && message.hasOwnProperty("publiclyAccessible"))
                object.publiclyAccessible = message.publiclyAccessible;
            if (message.annotationTimestamp != null && message.hasOwnProperty("annotationTimestamp"))
                if (typeof message.annotationTimestamp === "number")
                    object.annotationTimestamp = options.longs === String ? String(message.annotationTimestamp) : message.annotationTimestamp;
                else
                    object.annotationTimestamp = options.longs === String ? $util.Long.prototype.toString.call(message.annotationTimestamp) : options.longs === Number ? new $util.LongBits(message.annotationTimestamp.low >>> 0, message.annotationTimestamp.high >>> 0).toNumber(true) : message.annotationTimestamp;
            return object;
        };

        /**
         * Converts this ApiKomentarjiAddAnnotation to JSON.
         * @function toJSON
         * @memberof api_komentarji.ApiKomentarjiAddAnnotation
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiKomentarjiAddAnnotation.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiKomentarjiAddAnnotation
         * @function getTypeUrl
         * @memberof api_komentarji.ApiKomentarjiAddAnnotation
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiKomentarjiAddAnnotation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_komentarji.ApiKomentarjiAddAnnotation";
        };

        return ApiKomentarjiAddAnnotation;
    })();

    api_komentarji.ApiKomentarjiUpdateAnnotation = (function() {

        /**
         * Properties of an ApiKomentarjiUpdateAnnotation.
         * @memberof api_komentarji
         * @interface IApiKomentarjiUpdateAnnotation
         * @property {string|null} [annotationId] ApiKomentarjiUpdateAnnotation annotationId
         * @property {string|null} [content] ApiKomentarjiUpdateAnnotation content
         * @property {boolean|null} [publiclyAccessible] ApiKomentarjiUpdateAnnotation publiclyAccessible
         * @property {number|Long|null} [annotationTimestamp] ApiKomentarjiUpdateAnnotation annotationTimestamp
         */

        /**
         * Constructs a new ApiKomentarjiUpdateAnnotation.
         * @memberof api_komentarji
         * @classdesc Represents an ApiKomentarjiUpdateAnnotation.
         * @implements IApiKomentarjiUpdateAnnotation
         * @constructor
         * @param {api_komentarji.IApiKomentarjiUpdateAnnotation=} [properties] Properties to set
         */
        function ApiKomentarjiUpdateAnnotation(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiKomentarjiUpdateAnnotation annotationId.
         * @member {string} annotationId
         * @memberof api_komentarji.ApiKomentarjiUpdateAnnotation
         * @instance
         */
        ApiKomentarjiUpdateAnnotation.prototype.annotationId = "";

        /**
         * ApiKomentarjiUpdateAnnotation content.
         * @member {string} content
         * @memberof api_komentarji.ApiKomentarjiUpdateAnnotation
         * @instance
         */
        ApiKomentarjiUpdateAnnotation.prototype.content = "";

        /**
         * ApiKomentarjiUpdateAnnotation publiclyAccessible.
         * @member {boolean} publiclyAccessible
         * @memberof api_komentarji.ApiKomentarjiUpdateAnnotation
         * @instance
         */
        ApiKomentarjiUpdateAnnotation.prototype.publiclyAccessible = false;

        /**
         * ApiKomentarjiUpdateAnnotation annotationTimestamp.
         * @member {number|Long} annotationTimestamp
         * @memberof api_komentarji.ApiKomentarjiUpdateAnnotation
         * @instance
         */
        ApiKomentarjiUpdateAnnotation.prototype.annotationTimestamp = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new ApiKomentarjiUpdateAnnotation instance using the specified properties.
         * @function create
         * @memberof api_komentarji.ApiKomentarjiUpdateAnnotation
         * @static
         * @param {api_komentarji.IApiKomentarjiUpdateAnnotation=} [properties] Properties to set
         * @returns {api_komentarji.ApiKomentarjiUpdateAnnotation} ApiKomentarjiUpdateAnnotation instance
         */
        ApiKomentarjiUpdateAnnotation.create = function create(properties) {
            return new ApiKomentarjiUpdateAnnotation(properties);
        };

        /**
         * Encodes the specified ApiKomentarjiUpdateAnnotation message. Does not implicitly {@link api_komentarji.ApiKomentarjiUpdateAnnotation.verify|verify} messages.
         * @function encode
         * @memberof api_komentarji.ApiKomentarjiUpdateAnnotation
         * @static
         * @param {api_komentarji.IApiKomentarjiUpdateAnnotation} message ApiKomentarjiUpdateAnnotation message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiKomentarjiUpdateAnnotation.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.annotationId != null && Object.hasOwnProperty.call(message, "annotationId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.annotationId);
            if (message.content != null && Object.hasOwnProperty.call(message, "content"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.content);
            if (message.publiclyAccessible != null && Object.hasOwnProperty.call(message, "publiclyAccessible"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.publiclyAccessible);
            if (message.annotationTimestamp != null && Object.hasOwnProperty.call(message, "annotationTimestamp"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.annotationTimestamp);
            return writer;
        };

        /**
         * Encodes the specified ApiKomentarjiUpdateAnnotation message, length delimited. Does not implicitly {@link api_komentarji.ApiKomentarjiUpdateAnnotation.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_komentarji.ApiKomentarjiUpdateAnnotation
         * @static
         * @param {api_komentarji.IApiKomentarjiUpdateAnnotation} message ApiKomentarjiUpdateAnnotation message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiKomentarjiUpdateAnnotation.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiKomentarjiUpdateAnnotation message from the specified reader or buffer.
         * @function decode
         * @memberof api_komentarji.ApiKomentarjiUpdateAnnotation
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_komentarji.ApiKomentarjiUpdateAnnotation} ApiKomentarjiUpdateAnnotation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiKomentarjiUpdateAnnotation.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_komentarji.ApiKomentarjiUpdateAnnotation();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.annotationId = reader.string();
                        break;
                    }
                case 2: {
                        message.content = reader.string();
                        break;
                    }
                case 3: {
                        message.publiclyAccessible = reader.bool();
                        break;
                    }
                case 4: {
                        message.annotationTimestamp = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiKomentarjiUpdateAnnotation message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_komentarji.ApiKomentarjiUpdateAnnotation
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_komentarji.ApiKomentarjiUpdateAnnotation} ApiKomentarjiUpdateAnnotation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiKomentarjiUpdateAnnotation.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiKomentarjiUpdateAnnotation message.
         * @function verify
         * @memberof api_komentarji.ApiKomentarjiUpdateAnnotation
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiKomentarjiUpdateAnnotation.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.annotationId != null && message.hasOwnProperty("annotationId"))
                if (!$util.isString(message.annotationId))
                    return "annotationId: string expected";
            if (message.content != null && message.hasOwnProperty("content"))
                if (!$util.isString(message.content))
                    return "content: string expected";
            if (message.publiclyAccessible != null && message.hasOwnProperty("publiclyAccessible"))
                if (typeof message.publiclyAccessible !== "boolean")
                    return "publiclyAccessible: boolean expected";
            if (message.annotationTimestamp != null && message.hasOwnProperty("annotationTimestamp"))
                if (!$util.isInteger(message.annotationTimestamp) && !(message.annotationTimestamp && $util.isInteger(message.annotationTimestamp.low) && $util.isInteger(message.annotationTimestamp.high)))
                    return "annotationTimestamp: integer|Long expected";
            return null;
        };

        /**
         * Creates an ApiKomentarjiUpdateAnnotation message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_komentarji.ApiKomentarjiUpdateAnnotation
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_komentarji.ApiKomentarjiUpdateAnnotation} ApiKomentarjiUpdateAnnotation
         */
        ApiKomentarjiUpdateAnnotation.fromObject = function fromObject(object) {
            if (object instanceof $root.api_komentarji.ApiKomentarjiUpdateAnnotation)
                return object;
            let message = new $root.api_komentarji.ApiKomentarjiUpdateAnnotation();
            if (object.annotationId != null)
                message.annotationId = String(object.annotationId);
            if (object.content != null)
                message.content = String(object.content);
            if (object.publiclyAccessible != null)
                message.publiclyAccessible = Boolean(object.publiclyAccessible);
            if (object.annotationTimestamp != null)
                if ($util.Long)
                    (message.annotationTimestamp = $util.Long.fromValue(object.annotationTimestamp)).unsigned = true;
                else if (typeof object.annotationTimestamp === "string")
                    message.annotationTimestamp = parseInt(object.annotationTimestamp, 10);
                else if (typeof object.annotationTimestamp === "number")
                    message.annotationTimestamp = object.annotationTimestamp;
                else if (typeof object.annotationTimestamp === "object")
                    message.annotationTimestamp = new $util.LongBits(object.annotationTimestamp.low >>> 0, object.annotationTimestamp.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from an ApiKomentarjiUpdateAnnotation message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_komentarji.ApiKomentarjiUpdateAnnotation
         * @static
         * @param {api_komentarji.ApiKomentarjiUpdateAnnotation} message ApiKomentarjiUpdateAnnotation
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiKomentarjiUpdateAnnotation.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.annotationId = "";
                object.content = "";
                object.publiclyAccessible = false;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.annotationTimestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.annotationTimestamp = options.longs === String ? "0" : 0;
            }
            if (message.annotationId != null && message.hasOwnProperty("annotationId"))
                object.annotationId = message.annotationId;
            if (message.content != null && message.hasOwnProperty("content"))
                object.content = message.content;
            if (message.publiclyAccessible != null && message.hasOwnProperty("publiclyAccessible"))
                object.publiclyAccessible = message.publiclyAccessible;
            if (message.annotationTimestamp != null && message.hasOwnProperty("annotationTimestamp"))
                if (typeof message.annotationTimestamp === "number")
                    object.annotationTimestamp = options.longs === String ? String(message.annotationTimestamp) : message.annotationTimestamp;
                else
                    object.annotationTimestamp = options.longs === String ? $util.Long.prototype.toString.call(message.annotationTimestamp) : options.longs === Number ? new $util.LongBits(message.annotationTimestamp.low >>> 0, message.annotationTimestamp.high >>> 0).toNumber(true) : message.annotationTimestamp;
            return object;
        };

        /**
         * Converts this ApiKomentarjiUpdateAnnotation to JSON.
         * @function toJSON
         * @memberof api_komentarji.ApiKomentarjiUpdateAnnotation
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiKomentarjiUpdateAnnotation.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiKomentarjiUpdateAnnotation
         * @function getTypeUrl
         * @memberof api_komentarji.ApiKomentarjiUpdateAnnotation
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiKomentarjiUpdateAnnotation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_komentarji.ApiKomentarjiUpdateAnnotation";
        };

        return ApiKomentarjiUpdateAnnotation;
    })();

    api_komentarji.ApiKomentarjiRemoveAnnotation = (function() {

        /**
         * Properties of an ApiKomentarjiRemoveAnnotation.
         * @memberof api_komentarji
         * @interface IApiKomentarjiRemoveAnnotation
         * @property {string|null} [annotationId] ApiKomentarjiRemoveAnnotation annotationId
         */

        /**
         * Constructs a new ApiKomentarjiRemoveAnnotation.
         * @memberof api_komentarji
         * @classdesc Represents an ApiKomentarjiRemoveAnnotation.
         * @implements IApiKomentarjiRemoveAnnotation
         * @constructor
         * @param {api_komentarji.IApiKomentarjiRemoveAnnotation=} [properties] Properties to set
         */
        function ApiKomentarjiRemoveAnnotation(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiKomentarjiRemoveAnnotation annotationId.
         * @member {string} annotationId
         * @memberof api_komentarji.ApiKomentarjiRemoveAnnotation
         * @instance
         */
        ApiKomentarjiRemoveAnnotation.prototype.annotationId = "";

        /**
         * Creates a new ApiKomentarjiRemoveAnnotation instance using the specified properties.
         * @function create
         * @memberof api_komentarji.ApiKomentarjiRemoveAnnotation
         * @static
         * @param {api_komentarji.IApiKomentarjiRemoveAnnotation=} [properties] Properties to set
         * @returns {api_komentarji.ApiKomentarjiRemoveAnnotation} ApiKomentarjiRemoveAnnotation instance
         */
        ApiKomentarjiRemoveAnnotation.create = function create(properties) {
            return new ApiKomentarjiRemoveAnnotation(properties);
        };

        /**
         * Encodes the specified ApiKomentarjiRemoveAnnotation message. Does not implicitly {@link api_komentarji.ApiKomentarjiRemoveAnnotation.verify|verify} messages.
         * @function encode
         * @memberof api_komentarji.ApiKomentarjiRemoveAnnotation
         * @static
         * @param {api_komentarji.IApiKomentarjiRemoveAnnotation} message ApiKomentarjiRemoveAnnotation message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiKomentarjiRemoveAnnotation.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.annotationId != null && Object.hasOwnProperty.call(message, "annotationId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.annotationId);
            return writer;
        };

        /**
         * Encodes the specified ApiKomentarjiRemoveAnnotation message, length delimited. Does not implicitly {@link api_komentarji.ApiKomentarjiRemoveAnnotation.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_komentarji.ApiKomentarjiRemoveAnnotation
         * @static
         * @param {api_komentarji.IApiKomentarjiRemoveAnnotation} message ApiKomentarjiRemoveAnnotation message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiKomentarjiRemoveAnnotation.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiKomentarjiRemoveAnnotation message from the specified reader or buffer.
         * @function decode
         * @memberof api_komentarji.ApiKomentarjiRemoveAnnotation
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_komentarji.ApiKomentarjiRemoveAnnotation} ApiKomentarjiRemoveAnnotation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiKomentarjiRemoveAnnotation.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_komentarji.ApiKomentarjiRemoveAnnotation();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.annotationId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiKomentarjiRemoveAnnotation message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_komentarji.ApiKomentarjiRemoveAnnotation
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_komentarji.ApiKomentarjiRemoveAnnotation} ApiKomentarjiRemoveAnnotation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiKomentarjiRemoveAnnotation.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiKomentarjiRemoveAnnotation message.
         * @function verify
         * @memberof api_komentarji.ApiKomentarjiRemoveAnnotation
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiKomentarjiRemoveAnnotation.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.annotationId != null && message.hasOwnProperty("annotationId"))
                if (!$util.isString(message.annotationId))
                    return "annotationId: string expected";
            return null;
        };

        /**
         * Creates an ApiKomentarjiRemoveAnnotation message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_komentarji.ApiKomentarjiRemoveAnnotation
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_komentarji.ApiKomentarjiRemoveAnnotation} ApiKomentarjiRemoveAnnotation
         */
        ApiKomentarjiRemoveAnnotation.fromObject = function fromObject(object) {
            if (object instanceof $root.api_komentarji.ApiKomentarjiRemoveAnnotation)
                return object;
            let message = new $root.api_komentarji.ApiKomentarjiRemoveAnnotation();
            if (object.annotationId != null)
                message.annotationId = String(object.annotationId);
            return message;
        };

        /**
         * Creates a plain object from an ApiKomentarjiRemoveAnnotation message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_komentarji.ApiKomentarjiRemoveAnnotation
         * @static
         * @param {api_komentarji.ApiKomentarjiRemoveAnnotation} message ApiKomentarjiRemoveAnnotation
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiKomentarjiRemoveAnnotation.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.annotationId = "";
            if (message.annotationId != null && message.hasOwnProperty("annotationId"))
                object.annotationId = message.annotationId;
            return object;
        };

        /**
         * Converts this ApiKomentarjiRemoveAnnotation to JSON.
         * @function toJSON
         * @memberof api_komentarji.ApiKomentarjiRemoveAnnotation
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiKomentarjiRemoveAnnotation.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiKomentarjiRemoveAnnotation
         * @function getTypeUrl
         * @memberof api_komentarji.ApiKomentarjiRemoveAnnotation
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiKomentarjiRemoveAnnotation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_komentarji.ApiKomentarjiRemoveAnnotation";
        };

        return ApiKomentarjiRemoveAnnotation;
    })();

    api_komentarji.ApiKomentarjiGetSessionAnnotations = (function() {

        /**
         * Properties of an ApiKomentarjiGetSessionAnnotations.
         * @memberof api_komentarji
         * @interface IApiKomentarjiGetSessionAnnotations
         * @property {string|null} [courtCaseSession] ApiKomentarjiGetSessionAnnotations courtCaseSession
         */

        /**
         * Constructs a new ApiKomentarjiGetSessionAnnotations.
         * @memberof api_komentarji
         * @classdesc Represents an ApiKomentarjiGetSessionAnnotations.
         * @implements IApiKomentarjiGetSessionAnnotations
         * @constructor
         * @param {api_komentarji.IApiKomentarjiGetSessionAnnotations=} [properties] Properties to set
         */
        function ApiKomentarjiGetSessionAnnotations(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiKomentarjiGetSessionAnnotations courtCaseSession.
         * @member {string} courtCaseSession
         * @memberof api_komentarji.ApiKomentarjiGetSessionAnnotations
         * @instance
         */
        ApiKomentarjiGetSessionAnnotations.prototype.courtCaseSession = "";

        /**
         * Creates a new ApiKomentarjiGetSessionAnnotations instance using the specified properties.
         * @function create
         * @memberof api_komentarji.ApiKomentarjiGetSessionAnnotations
         * @static
         * @param {api_komentarji.IApiKomentarjiGetSessionAnnotations=} [properties] Properties to set
         * @returns {api_komentarji.ApiKomentarjiGetSessionAnnotations} ApiKomentarjiGetSessionAnnotations instance
         */
        ApiKomentarjiGetSessionAnnotations.create = function create(properties) {
            return new ApiKomentarjiGetSessionAnnotations(properties);
        };

        /**
         * Encodes the specified ApiKomentarjiGetSessionAnnotations message. Does not implicitly {@link api_komentarji.ApiKomentarjiGetSessionAnnotations.verify|verify} messages.
         * @function encode
         * @memberof api_komentarji.ApiKomentarjiGetSessionAnnotations
         * @static
         * @param {api_komentarji.IApiKomentarjiGetSessionAnnotations} message ApiKomentarjiGetSessionAnnotations message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiKomentarjiGetSessionAnnotations.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.courtCaseSession != null && Object.hasOwnProperty.call(message, "courtCaseSession"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.courtCaseSession);
            return writer;
        };

        /**
         * Encodes the specified ApiKomentarjiGetSessionAnnotations message, length delimited. Does not implicitly {@link api_komentarji.ApiKomentarjiGetSessionAnnotations.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_komentarji.ApiKomentarjiGetSessionAnnotations
         * @static
         * @param {api_komentarji.IApiKomentarjiGetSessionAnnotations} message ApiKomentarjiGetSessionAnnotations message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiKomentarjiGetSessionAnnotations.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiKomentarjiGetSessionAnnotations message from the specified reader or buffer.
         * @function decode
         * @memberof api_komentarji.ApiKomentarjiGetSessionAnnotations
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_komentarji.ApiKomentarjiGetSessionAnnotations} ApiKomentarjiGetSessionAnnotations
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiKomentarjiGetSessionAnnotations.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_komentarji.ApiKomentarjiGetSessionAnnotations();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.courtCaseSession = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiKomentarjiGetSessionAnnotations message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_komentarji.ApiKomentarjiGetSessionAnnotations
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_komentarji.ApiKomentarjiGetSessionAnnotations} ApiKomentarjiGetSessionAnnotations
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiKomentarjiGetSessionAnnotations.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiKomentarjiGetSessionAnnotations message.
         * @function verify
         * @memberof api_komentarji.ApiKomentarjiGetSessionAnnotations
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiKomentarjiGetSessionAnnotations.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.courtCaseSession != null && message.hasOwnProperty("courtCaseSession"))
                if (!$util.isString(message.courtCaseSession))
                    return "courtCaseSession: string expected";
            return null;
        };

        /**
         * Creates an ApiKomentarjiGetSessionAnnotations message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_komentarji.ApiKomentarjiGetSessionAnnotations
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_komentarji.ApiKomentarjiGetSessionAnnotations} ApiKomentarjiGetSessionAnnotations
         */
        ApiKomentarjiGetSessionAnnotations.fromObject = function fromObject(object) {
            if (object instanceof $root.api_komentarji.ApiKomentarjiGetSessionAnnotations)
                return object;
            let message = new $root.api_komentarji.ApiKomentarjiGetSessionAnnotations();
            if (object.courtCaseSession != null)
                message.courtCaseSession = String(object.courtCaseSession);
            return message;
        };

        /**
         * Creates a plain object from an ApiKomentarjiGetSessionAnnotations message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_komentarji.ApiKomentarjiGetSessionAnnotations
         * @static
         * @param {api_komentarji.ApiKomentarjiGetSessionAnnotations} message ApiKomentarjiGetSessionAnnotations
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiKomentarjiGetSessionAnnotations.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.courtCaseSession = "";
            if (message.courtCaseSession != null && message.hasOwnProperty("courtCaseSession"))
                object.courtCaseSession = message.courtCaseSession;
            return object;
        };

        /**
         * Converts this ApiKomentarjiGetSessionAnnotations to JSON.
         * @function toJSON
         * @memberof api_komentarji.ApiKomentarjiGetSessionAnnotations
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiKomentarjiGetSessionAnnotations.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiKomentarjiGetSessionAnnotations
         * @function getTypeUrl
         * @memberof api_komentarji.ApiKomentarjiGetSessionAnnotations
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiKomentarjiGetSessionAnnotations.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_komentarji.ApiKomentarjiGetSessionAnnotations";
        };

        return ApiKomentarjiGetSessionAnnotations;
    })();

    api_komentarji.ApiKomentarjiSessionAnnotations = (function() {

        /**
         * Properties of an ApiKomentarjiSessionAnnotations.
         * @memberof api_komentarji
         * @interface IApiKomentarjiSessionAnnotations
         * @property {string|null} [sourceEventId] ApiKomentarjiSessionAnnotations sourceEventId
         * @property {Array.<api_komentarji.IApiKomentarjiAnnotation>|null} [annotations] ApiKomentarjiSessionAnnotations annotations
         */

        /**
         * Constructs a new ApiKomentarjiSessionAnnotations.
         * @memberof api_komentarji
         * @classdesc Represents an ApiKomentarjiSessionAnnotations.
         * @implements IApiKomentarjiSessionAnnotations
         * @constructor
         * @param {api_komentarji.IApiKomentarjiSessionAnnotations=} [properties] Properties to set
         */
        function ApiKomentarjiSessionAnnotations(properties) {
            this.annotations = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiKomentarjiSessionAnnotations sourceEventId.
         * @member {string} sourceEventId
         * @memberof api_komentarji.ApiKomentarjiSessionAnnotations
         * @instance
         */
        ApiKomentarjiSessionAnnotations.prototype.sourceEventId = "";

        /**
         * ApiKomentarjiSessionAnnotations annotations.
         * @member {Array.<api_komentarji.IApiKomentarjiAnnotation>} annotations
         * @memberof api_komentarji.ApiKomentarjiSessionAnnotations
         * @instance
         */
        ApiKomentarjiSessionAnnotations.prototype.annotations = $util.emptyArray;

        /**
         * Creates a new ApiKomentarjiSessionAnnotations instance using the specified properties.
         * @function create
         * @memberof api_komentarji.ApiKomentarjiSessionAnnotations
         * @static
         * @param {api_komentarji.IApiKomentarjiSessionAnnotations=} [properties] Properties to set
         * @returns {api_komentarji.ApiKomentarjiSessionAnnotations} ApiKomentarjiSessionAnnotations instance
         */
        ApiKomentarjiSessionAnnotations.create = function create(properties) {
            return new ApiKomentarjiSessionAnnotations(properties);
        };

        /**
         * Encodes the specified ApiKomentarjiSessionAnnotations message. Does not implicitly {@link api_komentarji.ApiKomentarjiSessionAnnotations.verify|verify} messages.
         * @function encode
         * @memberof api_komentarji.ApiKomentarjiSessionAnnotations
         * @static
         * @param {api_komentarji.IApiKomentarjiSessionAnnotations} message ApiKomentarjiSessionAnnotations message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiKomentarjiSessionAnnotations.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sourceEventId != null && Object.hasOwnProperty.call(message, "sourceEventId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.sourceEventId);
            if (message.annotations != null && message.annotations.length)
                for (let i = 0; i < message.annotations.length; ++i)
                    $root.api_komentarji.ApiKomentarjiAnnotation.encode(message.annotations[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ApiKomentarjiSessionAnnotations message, length delimited. Does not implicitly {@link api_komentarji.ApiKomentarjiSessionAnnotations.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_komentarji.ApiKomentarjiSessionAnnotations
         * @static
         * @param {api_komentarji.IApiKomentarjiSessionAnnotations} message ApiKomentarjiSessionAnnotations message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiKomentarjiSessionAnnotations.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiKomentarjiSessionAnnotations message from the specified reader or buffer.
         * @function decode
         * @memberof api_komentarji.ApiKomentarjiSessionAnnotations
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_komentarji.ApiKomentarjiSessionAnnotations} ApiKomentarjiSessionAnnotations
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiKomentarjiSessionAnnotations.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_komentarji.ApiKomentarjiSessionAnnotations();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sourceEventId = reader.string();
                        break;
                    }
                case 2: {
                        if (!(message.annotations && message.annotations.length))
                            message.annotations = [];
                        message.annotations.push($root.api_komentarji.ApiKomentarjiAnnotation.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiKomentarjiSessionAnnotations message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_komentarji.ApiKomentarjiSessionAnnotations
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_komentarji.ApiKomentarjiSessionAnnotations} ApiKomentarjiSessionAnnotations
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiKomentarjiSessionAnnotations.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiKomentarjiSessionAnnotations message.
         * @function verify
         * @memberof api_komentarji.ApiKomentarjiSessionAnnotations
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiKomentarjiSessionAnnotations.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.sourceEventId != null && message.hasOwnProperty("sourceEventId"))
                if (!$util.isString(message.sourceEventId))
                    return "sourceEventId: string expected";
            if (message.annotations != null && message.hasOwnProperty("annotations")) {
                if (!Array.isArray(message.annotations))
                    return "annotations: array expected";
                for (let i = 0; i < message.annotations.length; ++i) {
                    let error = $root.api_komentarji.ApiKomentarjiAnnotation.verify(message.annotations[i]);
                    if (error)
                        return "annotations." + error;
                }
            }
            return null;
        };

        /**
         * Creates an ApiKomentarjiSessionAnnotations message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_komentarji.ApiKomentarjiSessionAnnotations
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_komentarji.ApiKomentarjiSessionAnnotations} ApiKomentarjiSessionAnnotations
         */
        ApiKomentarjiSessionAnnotations.fromObject = function fromObject(object) {
            if (object instanceof $root.api_komentarji.ApiKomentarjiSessionAnnotations)
                return object;
            let message = new $root.api_komentarji.ApiKomentarjiSessionAnnotations();
            if (object.sourceEventId != null)
                message.sourceEventId = String(object.sourceEventId);
            if (object.annotations) {
                if (!Array.isArray(object.annotations))
                    throw TypeError(".api_komentarji.ApiKomentarjiSessionAnnotations.annotations: array expected");
                message.annotations = [];
                for (let i = 0; i < object.annotations.length; ++i) {
                    if (typeof object.annotations[i] !== "object")
                        throw TypeError(".api_komentarji.ApiKomentarjiSessionAnnotations.annotations: object expected");
                    message.annotations[i] = $root.api_komentarji.ApiKomentarjiAnnotation.fromObject(object.annotations[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from an ApiKomentarjiSessionAnnotations message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_komentarji.ApiKomentarjiSessionAnnotations
         * @static
         * @param {api_komentarji.ApiKomentarjiSessionAnnotations} message ApiKomentarjiSessionAnnotations
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiKomentarjiSessionAnnotations.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.annotations = [];
            if (options.defaults)
                object.sourceEventId = "";
            if (message.sourceEventId != null && message.hasOwnProperty("sourceEventId"))
                object.sourceEventId = message.sourceEventId;
            if (message.annotations && message.annotations.length) {
                object.annotations = [];
                for (let j = 0; j < message.annotations.length; ++j)
                    object.annotations[j] = $root.api_komentarji.ApiKomentarjiAnnotation.toObject(message.annotations[j], options);
            }
            return object;
        };

        /**
         * Converts this ApiKomentarjiSessionAnnotations to JSON.
         * @function toJSON
         * @memberof api_komentarji.ApiKomentarjiSessionAnnotations
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiKomentarjiSessionAnnotations.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiKomentarjiSessionAnnotations
         * @function getTypeUrl
         * @memberof api_komentarji.ApiKomentarjiSessionAnnotations
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiKomentarjiSessionAnnotations.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_komentarji.ApiKomentarjiSessionAnnotations";
        };

        return ApiKomentarjiSessionAnnotations;
    })();

    api_komentarji.ApiKomentarjiAnnotation = (function() {

        /**
         * Properties of an ApiKomentarjiAnnotation.
         * @memberof api_komentarji
         * @interface IApiKomentarjiAnnotation
         * @property {string|null} [annotationId] ApiKomentarjiAnnotation annotationId
         * @property {string|null} [content] ApiKomentarjiAnnotation content
         * @property {string|null} [createdBy] ApiKomentarjiAnnotation createdBy
         * @property {boolean|null} [publiclyAccessible] ApiKomentarjiAnnotation publiclyAccessible
         * @property {number|Long|null} [createdAt] ApiKomentarjiAnnotation createdAt
         */

        /**
         * Constructs a new ApiKomentarjiAnnotation.
         * @memberof api_komentarji
         * @classdesc Represents an ApiKomentarjiAnnotation.
         * @implements IApiKomentarjiAnnotation
         * @constructor
         * @param {api_komentarji.IApiKomentarjiAnnotation=} [properties] Properties to set
         */
        function ApiKomentarjiAnnotation(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiKomentarjiAnnotation annotationId.
         * @member {string} annotationId
         * @memberof api_komentarji.ApiKomentarjiAnnotation
         * @instance
         */
        ApiKomentarjiAnnotation.prototype.annotationId = "";

        /**
         * ApiKomentarjiAnnotation content.
         * @member {string} content
         * @memberof api_komentarji.ApiKomentarjiAnnotation
         * @instance
         */
        ApiKomentarjiAnnotation.prototype.content = "";

        /**
         * ApiKomentarjiAnnotation createdBy.
         * @member {string} createdBy
         * @memberof api_komentarji.ApiKomentarjiAnnotation
         * @instance
         */
        ApiKomentarjiAnnotation.prototype.createdBy = "";

        /**
         * ApiKomentarjiAnnotation publiclyAccessible.
         * @member {boolean} publiclyAccessible
         * @memberof api_komentarji.ApiKomentarjiAnnotation
         * @instance
         */
        ApiKomentarjiAnnotation.prototype.publiclyAccessible = false;

        /**
         * ApiKomentarjiAnnotation createdAt.
         * @member {number|Long} createdAt
         * @memberof api_komentarji.ApiKomentarjiAnnotation
         * @instance
         */
        ApiKomentarjiAnnotation.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new ApiKomentarjiAnnotation instance using the specified properties.
         * @function create
         * @memberof api_komentarji.ApiKomentarjiAnnotation
         * @static
         * @param {api_komentarji.IApiKomentarjiAnnotation=} [properties] Properties to set
         * @returns {api_komentarji.ApiKomentarjiAnnotation} ApiKomentarjiAnnotation instance
         */
        ApiKomentarjiAnnotation.create = function create(properties) {
            return new ApiKomentarjiAnnotation(properties);
        };

        /**
         * Encodes the specified ApiKomentarjiAnnotation message. Does not implicitly {@link api_komentarji.ApiKomentarjiAnnotation.verify|verify} messages.
         * @function encode
         * @memberof api_komentarji.ApiKomentarjiAnnotation
         * @static
         * @param {api_komentarji.IApiKomentarjiAnnotation} message ApiKomentarjiAnnotation message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiKomentarjiAnnotation.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.annotationId != null && Object.hasOwnProperty.call(message, "annotationId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.annotationId);
            if (message.content != null && Object.hasOwnProperty.call(message, "content"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.content);
            if (message.createdBy != null && Object.hasOwnProperty.call(message, "createdBy"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.createdBy);
            if (message.publiclyAccessible != null && Object.hasOwnProperty.call(message, "publiclyAccessible"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.publiclyAccessible);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint64(message.createdAt);
            return writer;
        };

        /**
         * Encodes the specified ApiKomentarjiAnnotation message, length delimited. Does not implicitly {@link api_komentarji.ApiKomentarjiAnnotation.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_komentarji.ApiKomentarjiAnnotation
         * @static
         * @param {api_komentarji.IApiKomentarjiAnnotation} message ApiKomentarjiAnnotation message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiKomentarjiAnnotation.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiKomentarjiAnnotation message from the specified reader or buffer.
         * @function decode
         * @memberof api_komentarji.ApiKomentarjiAnnotation
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_komentarji.ApiKomentarjiAnnotation} ApiKomentarjiAnnotation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiKomentarjiAnnotation.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_komentarji.ApiKomentarjiAnnotation();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.annotationId = reader.string();
                        break;
                    }
                case 2: {
                        message.content = reader.string();
                        break;
                    }
                case 3: {
                        message.createdBy = reader.string();
                        break;
                    }
                case 4: {
                        message.publiclyAccessible = reader.bool();
                        break;
                    }
                case 5: {
                        message.createdAt = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiKomentarjiAnnotation message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_komentarji.ApiKomentarjiAnnotation
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_komentarji.ApiKomentarjiAnnotation} ApiKomentarjiAnnotation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiKomentarjiAnnotation.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiKomentarjiAnnotation message.
         * @function verify
         * @memberof api_komentarji.ApiKomentarjiAnnotation
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiKomentarjiAnnotation.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.annotationId != null && message.hasOwnProperty("annotationId"))
                if (!$util.isString(message.annotationId))
                    return "annotationId: string expected";
            if (message.content != null && message.hasOwnProperty("content"))
                if (!$util.isString(message.content))
                    return "content: string expected";
            if (message.createdBy != null && message.hasOwnProperty("createdBy"))
                if (!$util.isString(message.createdBy))
                    return "createdBy: string expected";
            if (message.publiclyAccessible != null && message.hasOwnProperty("publiclyAccessible"))
                if (typeof message.publiclyAccessible !== "boolean")
                    return "publiclyAccessible: boolean expected";
            if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                    return "createdAt: integer|Long expected";
            return null;
        };

        /**
         * Creates an ApiKomentarjiAnnotation message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_komentarji.ApiKomentarjiAnnotation
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_komentarji.ApiKomentarjiAnnotation} ApiKomentarjiAnnotation
         */
        ApiKomentarjiAnnotation.fromObject = function fromObject(object) {
            if (object instanceof $root.api_komentarji.ApiKomentarjiAnnotation)
                return object;
            let message = new $root.api_komentarji.ApiKomentarjiAnnotation();
            if (object.annotationId != null)
                message.annotationId = String(object.annotationId);
            if (object.content != null)
                message.content = String(object.content);
            if (object.createdBy != null)
                message.createdBy = String(object.createdBy);
            if (object.publiclyAccessible != null)
                message.publiclyAccessible = Boolean(object.publiclyAccessible);
            if (object.createdAt != null)
                if ($util.Long)
                    (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = true;
                else if (typeof object.createdAt === "string")
                    message.createdAt = parseInt(object.createdAt, 10);
                else if (typeof object.createdAt === "number")
                    message.createdAt = object.createdAt;
                else if (typeof object.createdAt === "object")
                    message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from an ApiKomentarjiAnnotation message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_komentarji.ApiKomentarjiAnnotation
         * @static
         * @param {api_komentarji.ApiKomentarjiAnnotation} message ApiKomentarjiAnnotation
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiKomentarjiAnnotation.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.annotationId = "";
                object.content = "";
                object.createdBy = "";
                object.publiclyAccessible = false;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.createdAt = options.longs === String ? "0" : 0;
            }
            if (message.annotationId != null && message.hasOwnProperty("annotationId"))
                object.annotationId = message.annotationId;
            if (message.content != null && message.hasOwnProperty("content"))
                object.content = message.content;
            if (message.createdBy != null && message.hasOwnProperty("createdBy"))
                object.createdBy = message.createdBy;
            if (message.publiclyAccessible != null && message.hasOwnProperty("publiclyAccessible"))
                object.publiclyAccessible = message.publiclyAccessible;
            if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                if (typeof message.createdAt === "number")
                    object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                else
                    object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber(true) : message.createdAt;
            return object;
        };

        /**
         * Converts this ApiKomentarjiAnnotation to JSON.
         * @function toJSON
         * @memberof api_komentarji.ApiKomentarjiAnnotation
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiKomentarjiAnnotation.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiKomentarjiAnnotation
         * @function getTypeUrl
         * @memberof api_komentarji.ApiKomentarjiAnnotation
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiKomentarjiAnnotation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_komentarji.ApiKomentarjiAnnotation";
        };

        return ApiKomentarjiAnnotation;
    })();

    return api_komentarji;
})();

export const ws_logs = $root.ws_logs = (() => {

    /**
     * Namespace ws_logs.
     * @exports ws_logs
     * @namespace
     */
    const ws_logs = {};

    ws_logs.LoadAsodLogs = (function() {

        /**
         * Properties of a LoadAsodLogs.
         * @memberof ws_logs
         * @interface ILoadAsodLogs
         * @property {string|null} [court] LoadAsodLogs court
         * @property {string|null} [courtroom] LoadAsodLogs courtroom
         * @property {string|null} [action] LoadAsodLogs action
         * @property {string|null} [actionKind] LoadAsodLogs actionKind
         * @property {common.IDateFilter|null} [dateFrom] LoadAsodLogs dateFrom
         * @property {common.IDateFilter|null} [dateTo] LoadAsodLogs dateTo
         * @property {common.IPagination|null} [pagination] LoadAsodLogs pagination
         * @property {ws_logs.AsodLogsOrderingTerm|null} [sortBy] LoadAsodLogs sortBy
         * @property {common.SortOrder|null} [sortOrder] LoadAsodLogs sortOrder
         * @property {ws_logs.IAsodLogs|null} [_response] LoadAsodLogs _response
         */

        /**
         * Constructs a new LoadAsodLogs.
         * @memberof ws_logs
         * @classdesc Represents a LoadAsodLogs.
         * @implements ILoadAsodLogs
         * @constructor
         * @param {ws_logs.ILoadAsodLogs=} [properties] Properties to set
         */
        function LoadAsodLogs(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LoadAsodLogs court.
         * @member {string} court
         * @memberof ws_logs.LoadAsodLogs
         * @instance
         */
        LoadAsodLogs.prototype.court = "";

        /**
         * LoadAsodLogs courtroom.
         * @member {string} courtroom
         * @memberof ws_logs.LoadAsodLogs
         * @instance
         */
        LoadAsodLogs.prototype.courtroom = "";

        /**
         * LoadAsodLogs action.
         * @member {string} action
         * @memberof ws_logs.LoadAsodLogs
         * @instance
         */
        LoadAsodLogs.prototype.action = "";

        /**
         * LoadAsodLogs actionKind.
         * @member {string} actionKind
         * @memberof ws_logs.LoadAsodLogs
         * @instance
         */
        LoadAsodLogs.prototype.actionKind = "";

        /**
         * LoadAsodLogs dateFrom.
         * @member {common.IDateFilter|null|undefined} dateFrom
         * @memberof ws_logs.LoadAsodLogs
         * @instance
         */
        LoadAsodLogs.prototype.dateFrom = null;

        /**
         * LoadAsodLogs dateTo.
         * @member {common.IDateFilter|null|undefined} dateTo
         * @memberof ws_logs.LoadAsodLogs
         * @instance
         */
        LoadAsodLogs.prototype.dateTo = null;

        /**
         * LoadAsodLogs pagination.
         * @member {common.IPagination|null|undefined} pagination
         * @memberof ws_logs.LoadAsodLogs
         * @instance
         */
        LoadAsodLogs.prototype.pagination = null;

        /**
         * LoadAsodLogs sortBy.
         * @member {ws_logs.AsodLogsOrderingTerm} sortBy
         * @memberof ws_logs.LoadAsodLogs
         * @instance
         */
        LoadAsodLogs.prototype.sortBy = 0;

        /**
         * LoadAsodLogs sortOrder.
         * @member {common.SortOrder} sortOrder
         * @memberof ws_logs.LoadAsodLogs
         * @instance
         */
        LoadAsodLogs.prototype.sortOrder = 0;

        /**
         * LoadAsodLogs _response.
         * @member {ws_logs.IAsodLogs|null|undefined} _response
         * @memberof ws_logs.LoadAsodLogs
         * @instance
         */
        LoadAsodLogs.prototype._response = null;

        /**
         * Creates a new LoadAsodLogs instance using the specified properties.
         * @function create
         * @memberof ws_logs.LoadAsodLogs
         * @static
         * @param {ws_logs.ILoadAsodLogs=} [properties] Properties to set
         * @returns {ws_logs.LoadAsodLogs} LoadAsodLogs instance
         */
        LoadAsodLogs.create = function create(properties) {
            return new LoadAsodLogs(properties);
        };

        /**
         * Encodes the specified LoadAsodLogs message. Does not implicitly {@link ws_logs.LoadAsodLogs.verify|verify} messages.
         * @function encode
         * @memberof ws_logs.LoadAsodLogs
         * @static
         * @param {ws_logs.ILoadAsodLogs} message LoadAsodLogs message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoadAsodLogs.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.court != null && Object.hasOwnProperty.call(message, "court"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.court);
            if (message.courtroom != null && Object.hasOwnProperty.call(message, "courtroom"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.courtroom);
            if (message.action != null && Object.hasOwnProperty.call(message, "action"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.action);
            if (message.actionKind != null && Object.hasOwnProperty.call(message, "actionKind"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.actionKind);
            if (message.dateFrom != null && Object.hasOwnProperty.call(message, "dateFrom"))
                $root.common.DateFilter.encode(message.dateFrom, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.dateTo != null && Object.hasOwnProperty.call(message, "dateTo"))
                $root.common.DateFilter.encode(message.dateTo, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.pagination != null && Object.hasOwnProperty.call(message, "pagination"))
                $root.common.Pagination.encode(message.pagination, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.sortBy != null && Object.hasOwnProperty.call(message, "sortBy"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.sortBy);
            if (message.sortOrder != null && Object.hasOwnProperty.call(message, "sortOrder"))
                writer.uint32(/* id 9, wireType 0 =*/72).int32(message.sortOrder);
            if (message._response != null && Object.hasOwnProperty.call(message, "_response"))
                $root.ws_logs.AsodLogs.encode(message._response, writer.uint32(/* id 99, wireType 2 =*/794).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified LoadAsodLogs message, length delimited. Does not implicitly {@link ws_logs.LoadAsodLogs.verify|verify} messages.
         * @function encodeDelimited
         * @memberof ws_logs.LoadAsodLogs
         * @static
         * @param {ws_logs.ILoadAsodLogs} message LoadAsodLogs message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoadAsodLogs.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LoadAsodLogs message from the specified reader or buffer.
         * @function decode
         * @memberof ws_logs.LoadAsodLogs
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {ws_logs.LoadAsodLogs} LoadAsodLogs
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoadAsodLogs.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ws_logs.LoadAsodLogs();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.court = reader.string();
                        break;
                    }
                case 2: {
                        message.courtroom = reader.string();
                        break;
                    }
                case 3: {
                        message.action = reader.string();
                        break;
                    }
                case 4: {
                        message.actionKind = reader.string();
                        break;
                    }
                case 5: {
                        message.dateFrom = $root.common.DateFilter.decode(reader, reader.uint32());
                        break;
                    }
                case 6: {
                        message.dateTo = $root.common.DateFilter.decode(reader, reader.uint32());
                        break;
                    }
                case 7: {
                        message.pagination = $root.common.Pagination.decode(reader, reader.uint32());
                        break;
                    }
                case 8: {
                        message.sortBy = reader.int32();
                        break;
                    }
                case 9: {
                        message.sortOrder = reader.int32();
                        break;
                    }
                case 99: {
                        message._response = $root.ws_logs.AsodLogs.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LoadAsodLogs message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof ws_logs.LoadAsodLogs
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {ws_logs.LoadAsodLogs} LoadAsodLogs
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoadAsodLogs.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LoadAsodLogs message.
         * @function verify
         * @memberof ws_logs.LoadAsodLogs
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LoadAsodLogs.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.court != null && message.hasOwnProperty("court"))
                if (!$util.isString(message.court))
                    return "court: string expected";
            if (message.courtroom != null && message.hasOwnProperty("courtroom"))
                if (!$util.isString(message.courtroom))
                    return "courtroom: string expected";
            if (message.action != null && message.hasOwnProperty("action"))
                if (!$util.isString(message.action))
                    return "action: string expected";
            if (message.actionKind != null && message.hasOwnProperty("actionKind"))
                if (!$util.isString(message.actionKind))
                    return "actionKind: string expected";
            if (message.dateFrom != null && message.hasOwnProperty("dateFrom")) {
                let error = $root.common.DateFilter.verify(message.dateFrom);
                if (error)
                    return "dateFrom." + error;
            }
            if (message.dateTo != null && message.hasOwnProperty("dateTo")) {
                let error = $root.common.DateFilter.verify(message.dateTo);
                if (error)
                    return "dateTo." + error;
            }
            if (message.pagination != null && message.hasOwnProperty("pagination")) {
                let error = $root.common.Pagination.verify(message.pagination);
                if (error)
                    return "pagination." + error;
            }
            if (message.sortBy != null && message.hasOwnProperty("sortBy"))
                switch (message.sortBy) {
                default:
                    return "sortBy: enum value expected";
                case 0:
                case 1:
                    break;
                }
            if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                switch (message.sortOrder) {
                default:
                    return "sortOrder: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message._response != null && message.hasOwnProperty("_response")) {
                let error = $root.ws_logs.AsodLogs.verify(message._response);
                if (error)
                    return "_response." + error;
            }
            return null;
        };

        /**
         * Creates a LoadAsodLogs message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof ws_logs.LoadAsodLogs
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {ws_logs.LoadAsodLogs} LoadAsodLogs
         */
        LoadAsodLogs.fromObject = function fromObject(object) {
            if (object instanceof $root.ws_logs.LoadAsodLogs)
                return object;
            let message = new $root.ws_logs.LoadAsodLogs();
            if (object.court != null)
                message.court = String(object.court);
            if (object.courtroom != null)
                message.courtroom = String(object.courtroom);
            if (object.action != null)
                message.action = String(object.action);
            if (object.actionKind != null)
                message.actionKind = String(object.actionKind);
            if (object.dateFrom != null) {
                if (typeof object.dateFrom !== "object")
                    throw TypeError(".ws_logs.LoadAsodLogs.dateFrom: object expected");
                message.dateFrom = $root.common.DateFilter.fromObject(object.dateFrom);
            }
            if (object.dateTo != null) {
                if (typeof object.dateTo !== "object")
                    throw TypeError(".ws_logs.LoadAsodLogs.dateTo: object expected");
                message.dateTo = $root.common.DateFilter.fromObject(object.dateTo);
            }
            if (object.pagination != null) {
                if (typeof object.pagination !== "object")
                    throw TypeError(".ws_logs.LoadAsodLogs.pagination: object expected");
                message.pagination = $root.common.Pagination.fromObject(object.pagination);
            }
            switch (object.sortBy) {
            default:
                if (typeof object.sortBy === "number") {
                    message.sortBy = object.sortBy;
                    break;
                }
                break;
            case "alot_date":
            case 0:
                message.sortBy = 0;
                break;
            case "alot_court":
            case 1:
                message.sortBy = 1;
                break;
            }
            switch (object.sortOrder) {
            default:
                if (typeof object.sortOrder === "number") {
                    message.sortOrder = object.sortOrder;
                    break;
                }
                break;
            case "so_none":
            case 0:
                message.sortOrder = 0;
                break;
            case "so_asc":
            case 1:
                message.sortOrder = 1;
                break;
            case "so_desc":
            case 2:
                message.sortOrder = 2;
                break;
            }
            if (object._response != null) {
                if (typeof object._response !== "object")
                    throw TypeError(".ws_logs.LoadAsodLogs._response: object expected");
                message._response = $root.ws_logs.AsodLogs.fromObject(object._response);
            }
            return message;
        };

        /**
         * Creates a plain object from a LoadAsodLogs message. Also converts values to other types if specified.
         * @function toObject
         * @memberof ws_logs.LoadAsodLogs
         * @static
         * @param {ws_logs.LoadAsodLogs} message LoadAsodLogs
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LoadAsodLogs.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.court = "";
                object.courtroom = "";
                object.action = "";
                object.actionKind = "";
                object.dateFrom = null;
                object.dateTo = null;
                object.pagination = null;
                object.sortBy = options.enums === String ? "alot_date" : 0;
                object.sortOrder = options.enums === String ? "so_none" : 0;
                object._response = null;
            }
            if (message.court != null && message.hasOwnProperty("court"))
                object.court = message.court;
            if (message.courtroom != null && message.hasOwnProperty("courtroom"))
                object.courtroom = message.courtroom;
            if (message.action != null && message.hasOwnProperty("action"))
                object.action = message.action;
            if (message.actionKind != null && message.hasOwnProperty("actionKind"))
                object.actionKind = message.actionKind;
            if (message.dateFrom != null && message.hasOwnProperty("dateFrom"))
                object.dateFrom = $root.common.DateFilter.toObject(message.dateFrom, options);
            if (message.dateTo != null && message.hasOwnProperty("dateTo"))
                object.dateTo = $root.common.DateFilter.toObject(message.dateTo, options);
            if (message.pagination != null && message.hasOwnProperty("pagination"))
                object.pagination = $root.common.Pagination.toObject(message.pagination, options);
            if (message.sortBy != null && message.hasOwnProperty("sortBy"))
                object.sortBy = options.enums === String ? $root.ws_logs.AsodLogsOrderingTerm[message.sortBy] === undefined ? message.sortBy : $root.ws_logs.AsodLogsOrderingTerm[message.sortBy] : message.sortBy;
            if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                object.sortOrder = options.enums === String ? $root.common.SortOrder[message.sortOrder] === undefined ? message.sortOrder : $root.common.SortOrder[message.sortOrder] : message.sortOrder;
            if (message._response != null && message.hasOwnProperty("_response"))
                object._response = $root.ws_logs.AsodLogs.toObject(message._response, options);
            return object;
        };

        /**
         * Converts this LoadAsodLogs to JSON.
         * @function toJSON
         * @memberof ws_logs.LoadAsodLogs
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LoadAsodLogs.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LoadAsodLogs
         * @function getTypeUrl
         * @memberof ws_logs.LoadAsodLogs
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LoadAsodLogs.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/ws_logs.LoadAsodLogs";
        };

        return LoadAsodLogs;
    })();

    ws_logs.AsodLogs = (function() {

        /**
         * Properties of an AsodLogs.
         * @memberof ws_logs
         * @interface IAsodLogs
         * @property {Array.<api_logs.IApiLogsAuditLog>|null} [rows] AsodLogs rows
         * @property {common.IPagination|null} [pagination] AsodLogs pagination
         * @property {ws_logs.AsodLogsOrderingTerm|null} [sortBy] AsodLogs sortBy
         * @property {common.SortOrder|null} [sortOrder] AsodLogs sortOrder
         */

        /**
         * Constructs a new AsodLogs.
         * @memberof ws_logs
         * @classdesc Represents an AsodLogs.
         * @implements IAsodLogs
         * @constructor
         * @param {ws_logs.IAsodLogs=} [properties] Properties to set
         */
        function AsodLogs(properties) {
            this.rows = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AsodLogs rows.
         * @member {Array.<api_logs.IApiLogsAuditLog>} rows
         * @memberof ws_logs.AsodLogs
         * @instance
         */
        AsodLogs.prototype.rows = $util.emptyArray;

        /**
         * AsodLogs pagination.
         * @member {common.IPagination|null|undefined} pagination
         * @memberof ws_logs.AsodLogs
         * @instance
         */
        AsodLogs.prototype.pagination = null;

        /**
         * AsodLogs sortBy.
         * @member {ws_logs.AsodLogsOrderingTerm} sortBy
         * @memberof ws_logs.AsodLogs
         * @instance
         */
        AsodLogs.prototype.sortBy = 0;

        /**
         * AsodLogs sortOrder.
         * @member {common.SortOrder} sortOrder
         * @memberof ws_logs.AsodLogs
         * @instance
         */
        AsodLogs.prototype.sortOrder = 0;

        /**
         * Creates a new AsodLogs instance using the specified properties.
         * @function create
         * @memberof ws_logs.AsodLogs
         * @static
         * @param {ws_logs.IAsodLogs=} [properties] Properties to set
         * @returns {ws_logs.AsodLogs} AsodLogs instance
         */
        AsodLogs.create = function create(properties) {
            return new AsodLogs(properties);
        };

        /**
         * Encodes the specified AsodLogs message. Does not implicitly {@link ws_logs.AsodLogs.verify|verify} messages.
         * @function encode
         * @memberof ws_logs.AsodLogs
         * @static
         * @param {ws_logs.IAsodLogs} message AsodLogs message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodLogs.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rows != null && message.rows.length)
                for (let i = 0; i < message.rows.length; ++i)
                    $root.api_logs.ApiLogsAuditLog.encode(message.rows[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.pagination != null && Object.hasOwnProperty.call(message, "pagination"))
                $root.common.Pagination.encode(message.pagination, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.sortBy != null && Object.hasOwnProperty.call(message, "sortBy"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.sortBy);
            if (message.sortOrder != null && Object.hasOwnProperty.call(message, "sortOrder"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.sortOrder);
            return writer;
        };

        /**
         * Encodes the specified AsodLogs message, length delimited. Does not implicitly {@link ws_logs.AsodLogs.verify|verify} messages.
         * @function encodeDelimited
         * @memberof ws_logs.AsodLogs
         * @static
         * @param {ws_logs.IAsodLogs} message AsodLogs message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodLogs.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AsodLogs message from the specified reader or buffer.
         * @function decode
         * @memberof ws_logs.AsodLogs
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {ws_logs.AsodLogs} AsodLogs
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodLogs.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ws_logs.AsodLogs();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.rows && message.rows.length))
                            message.rows = [];
                        message.rows.push($root.api_logs.ApiLogsAuditLog.decode(reader, reader.uint32()));
                        break;
                    }
                case 6: {
                        message.pagination = $root.common.Pagination.decode(reader, reader.uint32());
                        break;
                    }
                case 7: {
                        message.sortBy = reader.int32();
                        break;
                    }
                case 8: {
                        message.sortOrder = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AsodLogs message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof ws_logs.AsodLogs
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {ws_logs.AsodLogs} AsodLogs
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodLogs.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AsodLogs message.
         * @function verify
         * @memberof ws_logs.AsodLogs
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AsodLogs.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.rows != null && message.hasOwnProperty("rows")) {
                if (!Array.isArray(message.rows))
                    return "rows: array expected";
                for (let i = 0; i < message.rows.length; ++i) {
                    let error = $root.api_logs.ApiLogsAuditLog.verify(message.rows[i]);
                    if (error)
                        return "rows." + error;
                }
            }
            if (message.pagination != null && message.hasOwnProperty("pagination")) {
                let error = $root.common.Pagination.verify(message.pagination);
                if (error)
                    return "pagination." + error;
            }
            if (message.sortBy != null && message.hasOwnProperty("sortBy"))
                switch (message.sortBy) {
                default:
                    return "sortBy: enum value expected";
                case 0:
                case 1:
                    break;
                }
            if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                switch (message.sortOrder) {
                default:
                    return "sortOrder: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            return null;
        };

        /**
         * Creates an AsodLogs message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof ws_logs.AsodLogs
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {ws_logs.AsodLogs} AsodLogs
         */
        AsodLogs.fromObject = function fromObject(object) {
            if (object instanceof $root.ws_logs.AsodLogs)
                return object;
            let message = new $root.ws_logs.AsodLogs();
            if (object.rows) {
                if (!Array.isArray(object.rows))
                    throw TypeError(".ws_logs.AsodLogs.rows: array expected");
                message.rows = [];
                for (let i = 0; i < object.rows.length; ++i) {
                    if (typeof object.rows[i] !== "object")
                        throw TypeError(".ws_logs.AsodLogs.rows: object expected");
                    message.rows[i] = $root.api_logs.ApiLogsAuditLog.fromObject(object.rows[i]);
                }
            }
            if (object.pagination != null) {
                if (typeof object.pagination !== "object")
                    throw TypeError(".ws_logs.AsodLogs.pagination: object expected");
                message.pagination = $root.common.Pagination.fromObject(object.pagination);
            }
            switch (object.sortBy) {
            default:
                if (typeof object.sortBy === "number") {
                    message.sortBy = object.sortBy;
                    break;
                }
                break;
            case "alot_date":
            case 0:
                message.sortBy = 0;
                break;
            case "alot_court":
            case 1:
                message.sortBy = 1;
                break;
            }
            switch (object.sortOrder) {
            default:
                if (typeof object.sortOrder === "number") {
                    message.sortOrder = object.sortOrder;
                    break;
                }
                break;
            case "so_none":
            case 0:
                message.sortOrder = 0;
                break;
            case "so_asc":
            case 1:
                message.sortOrder = 1;
                break;
            case "so_desc":
            case 2:
                message.sortOrder = 2;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from an AsodLogs message. Also converts values to other types if specified.
         * @function toObject
         * @memberof ws_logs.AsodLogs
         * @static
         * @param {ws_logs.AsodLogs} message AsodLogs
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AsodLogs.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.rows = [];
            if (options.defaults) {
                object.pagination = null;
                object.sortBy = options.enums === String ? "alot_date" : 0;
                object.sortOrder = options.enums === String ? "so_none" : 0;
            }
            if (message.rows && message.rows.length) {
                object.rows = [];
                for (let j = 0; j < message.rows.length; ++j)
                    object.rows[j] = $root.api_logs.ApiLogsAuditLog.toObject(message.rows[j], options);
            }
            if (message.pagination != null && message.hasOwnProperty("pagination"))
                object.pagination = $root.common.Pagination.toObject(message.pagination, options);
            if (message.sortBy != null && message.hasOwnProperty("sortBy"))
                object.sortBy = options.enums === String ? $root.ws_logs.AsodLogsOrderingTerm[message.sortBy] === undefined ? message.sortBy : $root.ws_logs.AsodLogsOrderingTerm[message.sortBy] : message.sortBy;
            if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                object.sortOrder = options.enums === String ? $root.common.SortOrder[message.sortOrder] === undefined ? message.sortOrder : $root.common.SortOrder[message.sortOrder] : message.sortOrder;
            return object;
        };

        /**
         * Converts this AsodLogs to JSON.
         * @function toJSON
         * @memberof ws_logs.AsodLogs
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AsodLogs.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AsodLogs
         * @function getTypeUrl
         * @memberof ws_logs.AsodLogs
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AsodLogs.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/ws_logs.AsodLogs";
        };

        return AsodLogs;
    })();

    ws_logs.AsodLogMessage = (function() {

        /**
         * Properties of an AsodLogMessage.
         * @memberof ws_logs
         * @interface IAsodLogMessage
         * @property {string|null} [label] AsodLogMessage label
         * @property {string|null} [message] AsodLogMessage message
         * @property {boolean|null} [isError] AsodLogMessage isError
         */

        /**
         * Constructs a new AsodLogMessage.
         * @memberof ws_logs
         * @classdesc Represents an AsodLogMessage.
         * @implements IAsodLogMessage
         * @constructor
         * @param {ws_logs.IAsodLogMessage=} [properties] Properties to set
         */
        function AsodLogMessage(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AsodLogMessage label.
         * @member {string} label
         * @memberof ws_logs.AsodLogMessage
         * @instance
         */
        AsodLogMessage.prototype.label = "";

        /**
         * AsodLogMessage message.
         * @member {string} message
         * @memberof ws_logs.AsodLogMessage
         * @instance
         */
        AsodLogMessage.prototype.message = "";

        /**
         * AsodLogMessage isError.
         * @member {boolean} isError
         * @memberof ws_logs.AsodLogMessage
         * @instance
         */
        AsodLogMessage.prototype.isError = false;

        /**
         * Creates a new AsodLogMessage instance using the specified properties.
         * @function create
         * @memberof ws_logs.AsodLogMessage
         * @static
         * @param {ws_logs.IAsodLogMessage=} [properties] Properties to set
         * @returns {ws_logs.AsodLogMessage} AsodLogMessage instance
         */
        AsodLogMessage.create = function create(properties) {
            return new AsodLogMessage(properties);
        };

        /**
         * Encodes the specified AsodLogMessage message. Does not implicitly {@link ws_logs.AsodLogMessage.verify|verify} messages.
         * @function encode
         * @memberof ws_logs.AsodLogMessage
         * @static
         * @param {ws_logs.IAsodLogMessage} message AsodLogMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodLogMessage.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.label);
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.message);
            if (message.isError != null && Object.hasOwnProperty.call(message, "isError"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.isError);
            return writer;
        };

        /**
         * Encodes the specified AsodLogMessage message, length delimited. Does not implicitly {@link ws_logs.AsodLogMessage.verify|verify} messages.
         * @function encodeDelimited
         * @memberof ws_logs.AsodLogMessage
         * @static
         * @param {ws_logs.IAsodLogMessage} message AsodLogMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodLogMessage.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AsodLogMessage message from the specified reader or buffer.
         * @function decode
         * @memberof ws_logs.AsodLogMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {ws_logs.AsodLogMessage} AsodLogMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodLogMessage.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ws_logs.AsodLogMessage();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.label = reader.string();
                        break;
                    }
                case 2: {
                        message.message = reader.string();
                        break;
                    }
                case 3: {
                        message.isError = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AsodLogMessage message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof ws_logs.AsodLogMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {ws_logs.AsodLogMessage} AsodLogMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodLogMessage.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AsodLogMessage message.
         * @function verify
         * @memberof ws_logs.AsodLogMessage
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AsodLogMessage.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.label != null && message.hasOwnProperty("label"))
                if (!$util.isString(message.label))
                    return "label: string expected";
            if (message.message != null && message.hasOwnProperty("message"))
                if (!$util.isString(message.message))
                    return "message: string expected";
            if (message.isError != null && message.hasOwnProperty("isError"))
                if (typeof message.isError !== "boolean")
                    return "isError: boolean expected";
            return null;
        };

        /**
         * Creates an AsodLogMessage message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof ws_logs.AsodLogMessage
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {ws_logs.AsodLogMessage} AsodLogMessage
         */
        AsodLogMessage.fromObject = function fromObject(object) {
            if (object instanceof $root.ws_logs.AsodLogMessage)
                return object;
            let message = new $root.ws_logs.AsodLogMessage();
            if (object.label != null)
                message.label = String(object.label);
            if (object.message != null)
                message.message = String(object.message);
            if (object.isError != null)
                message.isError = Boolean(object.isError);
            return message;
        };

        /**
         * Creates a plain object from an AsodLogMessage message. Also converts values to other types if specified.
         * @function toObject
         * @memberof ws_logs.AsodLogMessage
         * @static
         * @param {ws_logs.AsodLogMessage} message AsodLogMessage
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AsodLogMessage.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.label = "";
                object.message = "";
                object.isError = false;
            }
            if (message.label != null && message.hasOwnProperty("label"))
                object.label = message.label;
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = message.message;
            if (message.isError != null && message.hasOwnProperty("isError"))
                object.isError = message.isError;
            return object;
        };

        /**
         * Converts this AsodLogMessage to JSON.
         * @function toJSON
         * @memberof ws_logs.AsodLogMessage
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AsodLogMessage.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AsodLogMessage
         * @function getTypeUrl
         * @memberof ws_logs.AsodLogMessage
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AsodLogMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/ws_logs.AsodLogMessage";
        };

        return AsodLogMessage;
    })();

    /**
     * AsodLogsOrderingTerm enum.
     * @name ws_logs.AsodLogsOrderingTerm
     * @enum {number}
     * @property {number} alot_date=0 alot_date value
     * @property {number} alot_court=1 alot_court value
     */
    ws_logs.AsodLogsOrderingTerm = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "alot_date"] = 0;
        values[valuesById[1] = "alot_court"] = 1;
        return values;
    })();

    return ws_logs;
})();

export const api_logs = $root.api_logs = (() => {

    /**
     * Namespace api_logs.
     * @exports api_logs
     * @namespace
     */
    const api_logs = {};

    api_logs.ApiLogsGetAuditLogs = (function() {

        /**
         * Properties of an ApiLogsGetAuditLogs.
         * @memberof api_logs
         * @interface IApiLogsGetAuditLogs
         * @property {string|null} [courtFilter] ApiLogsGetAuditLogs courtFilter
         * @property {string|null} [courtRoomFilter] ApiLogsGetAuditLogs courtRoomFilter
         * @property {string|null} [actionFilter] ApiLogsGetAuditLogs actionFilter
         * @property {string|null} [actionKindFilter] ApiLogsGetAuditLogs actionKindFilter
         * @property {number|null} [epochFrom] ApiLogsGetAuditLogs epochFrom
         * @property {number|null} [epochTo] ApiLogsGetAuditLogs epochTo
         * @property {number|null} [offset] ApiLogsGetAuditLogs offset
         * @property {number|null} [pageSize] ApiLogsGetAuditLogs pageSize
         */

        /**
         * Constructs a new ApiLogsGetAuditLogs.
         * @memberof api_logs
         * @classdesc Represents an ApiLogsGetAuditLogs.
         * @implements IApiLogsGetAuditLogs
         * @constructor
         * @param {api_logs.IApiLogsGetAuditLogs=} [properties] Properties to set
         */
        function ApiLogsGetAuditLogs(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiLogsGetAuditLogs courtFilter.
         * @member {string} courtFilter
         * @memberof api_logs.ApiLogsGetAuditLogs
         * @instance
         */
        ApiLogsGetAuditLogs.prototype.courtFilter = "";

        /**
         * ApiLogsGetAuditLogs courtRoomFilter.
         * @member {string} courtRoomFilter
         * @memberof api_logs.ApiLogsGetAuditLogs
         * @instance
         */
        ApiLogsGetAuditLogs.prototype.courtRoomFilter = "";

        /**
         * ApiLogsGetAuditLogs actionFilter.
         * @member {string} actionFilter
         * @memberof api_logs.ApiLogsGetAuditLogs
         * @instance
         */
        ApiLogsGetAuditLogs.prototype.actionFilter = "";

        /**
         * ApiLogsGetAuditLogs actionKindFilter.
         * @member {string} actionKindFilter
         * @memberof api_logs.ApiLogsGetAuditLogs
         * @instance
         */
        ApiLogsGetAuditLogs.prototype.actionKindFilter = "";

        /**
         * ApiLogsGetAuditLogs epochFrom.
         * @member {number} epochFrom
         * @memberof api_logs.ApiLogsGetAuditLogs
         * @instance
         */
        ApiLogsGetAuditLogs.prototype.epochFrom = 0;

        /**
         * ApiLogsGetAuditLogs epochTo.
         * @member {number} epochTo
         * @memberof api_logs.ApiLogsGetAuditLogs
         * @instance
         */
        ApiLogsGetAuditLogs.prototype.epochTo = 0;

        /**
         * ApiLogsGetAuditLogs offset.
         * @member {number} offset
         * @memberof api_logs.ApiLogsGetAuditLogs
         * @instance
         */
        ApiLogsGetAuditLogs.prototype.offset = 0;

        /**
         * ApiLogsGetAuditLogs pageSize.
         * @member {number} pageSize
         * @memberof api_logs.ApiLogsGetAuditLogs
         * @instance
         */
        ApiLogsGetAuditLogs.prototype.pageSize = 0;

        /**
         * Creates a new ApiLogsGetAuditLogs instance using the specified properties.
         * @function create
         * @memberof api_logs.ApiLogsGetAuditLogs
         * @static
         * @param {api_logs.IApiLogsGetAuditLogs=} [properties] Properties to set
         * @returns {api_logs.ApiLogsGetAuditLogs} ApiLogsGetAuditLogs instance
         */
        ApiLogsGetAuditLogs.create = function create(properties) {
            return new ApiLogsGetAuditLogs(properties);
        };

        /**
         * Encodes the specified ApiLogsGetAuditLogs message. Does not implicitly {@link api_logs.ApiLogsGetAuditLogs.verify|verify} messages.
         * @function encode
         * @memberof api_logs.ApiLogsGetAuditLogs
         * @static
         * @param {api_logs.IApiLogsGetAuditLogs} message ApiLogsGetAuditLogs message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiLogsGetAuditLogs.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.courtFilter != null && Object.hasOwnProperty.call(message, "courtFilter"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.courtFilter);
            if (message.courtRoomFilter != null && Object.hasOwnProperty.call(message, "courtRoomFilter"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.courtRoomFilter);
            if (message.actionFilter != null && Object.hasOwnProperty.call(message, "actionFilter"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.actionFilter);
            if (message.actionKindFilter != null && Object.hasOwnProperty.call(message, "actionKindFilter"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.actionKindFilter);
            if (message.epochFrom != null && Object.hasOwnProperty.call(message, "epochFrom"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.epochFrom);
            if (message.epochTo != null && Object.hasOwnProperty.call(message, "epochTo"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.epochTo);
            if (message.offset != null && Object.hasOwnProperty.call(message, "offset"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.offset);
            if (message.pageSize != null && Object.hasOwnProperty.call(message, "pageSize"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.pageSize);
            return writer;
        };

        /**
         * Encodes the specified ApiLogsGetAuditLogs message, length delimited. Does not implicitly {@link api_logs.ApiLogsGetAuditLogs.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_logs.ApiLogsGetAuditLogs
         * @static
         * @param {api_logs.IApiLogsGetAuditLogs} message ApiLogsGetAuditLogs message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiLogsGetAuditLogs.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiLogsGetAuditLogs message from the specified reader or buffer.
         * @function decode
         * @memberof api_logs.ApiLogsGetAuditLogs
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_logs.ApiLogsGetAuditLogs} ApiLogsGetAuditLogs
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiLogsGetAuditLogs.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_logs.ApiLogsGetAuditLogs();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.courtFilter = reader.string();
                        break;
                    }
                case 2: {
                        message.courtRoomFilter = reader.string();
                        break;
                    }
                case 3: {
                        message.actionFilter = reader.string();
                        break;
                    }
                case 4: {
                        message.actionKindFilter = reader.string();
                        break;
                    }
                case 5: {
                        message.epochFrom = reader.int32();
                        break;
                    }
                case 6: {
                        message.epochTo = reader.int32();
                        break;
                    }
                case 7: {
                        message.offset = reader.int32();
                        break;
                    }
                case 8: {
                        message.pageSize = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiLogsGetAuditLogs message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_logs.ApiLogsGetAuditLogs
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_logs.ApiLogsGetAuditLogs} ApiLogsGetAuditLogs
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiLogsGetAuditLogs.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiLogsGetAuditLogs message.
         * @function verify
         * @memberof api_logs.ApiLogsGetAuditLogs
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiLogsGetAuditLogs.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.courtFilter != null && message.hasOwnProperty("courtFilter"))
                if (!$util.isString(message.courtFilter))
                    return "courtFilter: string expected";
            if (message.courtRoomFilter != null && message.hasOwnProperty("courtRoomFilter"))
                if (!$util.isString(message.courtRoomFilter))
                    return "courtRoomFilter: string expected";
            if (message.actionFilter != null && message.hasOwnProperty("actionFilter"))
                if (!$util.isString(message.actionFilter))
                    return "actionFilter: string expected";
            if (message.actionKindFilter != null && message.hasOwnProperty("actionKindFilter"))
                if (!$util.isString(message.actionKindFilter))
                    return "actionKindFilter: string expected";
            if (message.epochFrom != null && message.hasOwnProperty("epochFrom"))
                if (!$util.isInteger(message.epochFrom))
                    return "epochFrom: integer expected";
            if (message.epochTo != null && message.hasOwnProperty("epochTo"))
                if (!$util.isInteger(message.epochTo))
                    return "epochTo: integer expected";
            if (message.offset != null && message.hasOwnProperty("offset"))
                if (!$util.isInteger(message.offset))
                    return "offset: integer expected";
            if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                if (!$util.isInteger(message.pageSize))
                    return "pageSize: integer expected";
            return null;
        };

        /**
         * Creates an ApiLogsGetAuditLogs message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_logs.ApiLogsGetAuditLogs
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_logs.ApiLogsGetAuditLogs} ApiLogsGetAuditLogs
         */
        ApiLogsGetAuditLogs.fromObject = function fromObject(object) {
            if (object instanceof $root.api_logs.ApiLogsGetAuditLogs)
                return object;
            let message = new $root.api_logs.ApiLogsGetAuditLogs();
            if (object.courtFilter != null)
                message.courtFilter = String(object.courtFilter);
            if (object.courtRoomFilter != null)
                message.courtRoomFilter = String(object.courtRoomFilter);
            if (object.actionFilter != null)
                message.actionFilter = String(object.actionFilter);
            if (object.actionKindFilter != null)
                message.actionKindFilter = String(object.actionKindFilter);
            if (object.epochFrom != null)
                message.epochFrom = object.epochFrom | 0;
            if (object.epochTo != null)
                message.epochTo = object.epochTo | 0;
            if (object.offset != null)
                message.offset = object.offset | 0;
            if (object.pageSize != null)
                message.pageSize = object.pageSize | 0;
            return message;
        };

        /**
         * Creates a plain object from an ApiLogsGetAuditLogs message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_logs.ApiLogsGetAuditLogs
         * @static
         * @param {api_logs.ApiLogsGetAuditLogs} message ApiLogsGetAuditLogs
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiLogsGetAuditLogs.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.courtFilter = "";
                object.courtRoomFilter = "";
                object.actionFilter = "";
                object.actionKindFilter = "";
                object.epochFrom = 0;
                object.epochTo = 0;
                object.offset = 0;
                object.pageSize = 0;
            }
            if (message.courtFilter != null && message.hasOwnProperty("courtFilter"))
                object.courtFilter = message.courtFilter;
            if (message.courtRoomFilter != null && message.hasOwnProperty("courtRoomFilter"))
                object.courtRoomFilter = message.courtRoomFilter;
            if (message.actionFilter != null && message.hasOwnProperty("actionFilter"))
                object.actionFilter = message.actionFilter;
            if (message.actionKindFilter != null && message.hasOwnProperty("actionKindFilter"))
                object.actionKindFilter = message.actionKindFilter;
            if (message.epochFrom != null && message.hasOwnProperty("epochFrom"))
                object.epochFrom = message.epochFrom;
            if (message.epochTo != null && message.hasOwnProperty("epochTo"))
                object.epochTo = message.epochTo;
            if (message.offset != null && message.hasOwnProperty("offset"))
                object.offset = message.offset;
            if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                object.pageSize = message.pageSize;
            return object;
        };

        /**
         * Converts this ApiLogsGetAuditLogs to JSON.
         * @function toJSON
         * @memberof api_logs.ApiLogsGetAuditLogs
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiLogsGetAuditLogs.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiLogsGetAuditLogs
         * @function getTypeUrl
         * @memberof api_logs.ApiLogsGetAuditLogs
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiLogsGetAuditLogs.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_logs.ApiLogsGetAuditLogs";
        };

        return ApiLogsGetAuditLogs;
    })();

    api_logs.ApiLogsAuditLogs = (function() {

        /**
         * Properties of an ApiLogsAuditLogs.
         * @memberof api_logs
         * @interface IApiLogsAuditLogs
         * @property {string|null} [eventId] ApiLogsAuditLogs eventId
         * @property {Array.<api_logs.IApiLogsAuditLog>|null} [auditLogs] ApiLogsAuditLogs auditLogs
         * @property {number|null} [offset] ApiLogsAuditLogs offset
         * @property {number|null} [pageSize] ApiLogsAuditLogs pageSize
         * @property {number|null} [lastItemIndex] ApiLogsAuditLogs lastItemIndex
         */

        /**
         * Constructs a new ApiLogsAuditLogs.
         * @memberof api_logs
         * @classdesc Represents an ApiLogsAuditLogs.
         * @implements IApiLogsAuditLogs
         * @constructor
         * @param {api_logs.IApiLogsAuditLogs=} [properties] Properties to set
         */
        function ApiLogsAuditLogs(properties) {
            this.auditLogs = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiLogsAuditLogs eventId.
         * @member {string} eventId
         * @memberof api_logs.ApiLogsAuditLogs
         * @instance
         */
        ApiLogsAuditLogs.prototype.eventId = "";

        /**
         * ApiLogsAuditLogs auditLogs.
         * @member {Array.<api_logs.IApiLogsAuditLog>} auditLogs
         * @memberof api_logs.ApiLogsAuditLogs
         * @instance
         */
        ApiLogsAuditLogs.prototype.auditLogs = $util.emptyArray;

        /**
         * ApiLogsAuditLogs offset.
         * @member {number} offset
         * @memberof api_logs.ApiLogsAuditLogs
         * @instance
         */
        ApiLogsAuditLogs.prototype.offset = 0;

        /**
         * ApiLogsAuditLogs pageSize.
         * @member {number} pageSize
         * @memberof api_logs.ApiLogsAuditLogs
         * @instance
         */
        ApiLogsAuditLogs.prototype.pageSize = 0;

        /**
         * ApiLogsAuditLogs lastItemIndex.
         * @member {number} lastItemIndex
         * @memberof api_logs.ApiLogsAuditLogs
         * @instance
         */
        ApiLogsAuditLogs.prototype.lastItemIndex = 0;

        /**
         * Creates a new ApiLogsAuditLogs instance using the specified properties.
         * @function create
         * @memberof api_logs.ApiLogsAuditLogs
         * @static
         * @param {api_logs.IApiLogsAuditLogs=} [properties] Properties to set
         * @returns {api_logs.ApiLogsAuditLogs} ApiLogsAuditLogs instance
         */
        ApiLogsAuditLogs.create = function create(properties) {
            return new ApiLogsAuditLogs(properties);
        };

        /**
         * Encodes the specified ApiLogsAuditLogs message. Does not implicitly {@link api_logs.ApiLogsAuditLogs.verify|verify} messages.
         * @function encode
         * @memberof api_logs.ApiLogsAuditLogs
         * @static
         * @param {api_logs.IApiLogsAuditLogs} message ApiLogsAuditLogs message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiLogsAuditLogs.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.eventId != null && Object.hasOwnProperty.call(message, "eventId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.eventId);
            if (message.auditLogs != null && message.auditLogs.length)
                for (let i = 0; i < message.auditLogs.length; ++i)
                    $root.api_logs.ApiLogsAuditLog.encode(message.auditLogs[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.offset != null && Object.hasOwnProperty.call(message, "offset"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.offset);
            if (message.pageSize != null && Object.hasOwnProperty.call(message, "pageSize"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.pageSize);
            if (message.lastItemIndex != null && Object.hasOwnProperty.call(message, "lastItemIndex"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.lastItemIndex);
            return writer;
        };

        /**
         * Encodes the specified ApiLogsAuditLogs message, length delimited. Does not implicitly {@link api_logs.ApiLogsAuditLogs.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_logs.ApiLogsAuditLogs
         * @static
         * @param {api_logs.IApiLogsAuditLogs} message ApiLogsAuditLogs message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiLogsAuditLogs.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiLogsAuditLogs message from the specified reader or buffer.
         * @function decode
         * @memberof api_logs.ApiLogsAuditLogs
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_logs.ApiLogsAuditLogs} ApiLogsAuditLogs
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiLogsAuditLogs.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_logs.ApiLogsAuditLogs();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.eventId = reader.string();
                        break;
                    }
                case 2: {
                        if (!(message.auditLogs && message.auditLogs.length))
                            message.auditLogs = [];
                        message.auditLogs.push($root.api_logs.ApiLogsAuditLog.decode(reader, reader.uint32()));
                        break;
                    }
                case 3: {
                        message.offset = reader.int32();
                        break;
                    }
                case 4: {
                        message.pageSize = reader.int32();
                        break;
                    }
                case 5: {
                        message.lastItemIndex = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiLogsAuditLogs message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_logs.ApiLogsAuditLogs
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_logs.ApiLogsAuditLogs} ApiLogsAuditLogs
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiLogsAuditLogs.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiLogsAuditLogs message.
         * @function verify
         * @memberof api_logs.ApiLogsAuditLogs
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiLogsAuditLogs.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.eventId != null && message.hasOwnProperty("eventId"))
                if (!$util.isString(message.eventId))
                    return "eventId: string expected";
            if (message.auditLogs != null && message.hasOwnProperty("auditLogs")) {
                if (!Array.isArray(message.auditLogs))
                    return "auditLogs: array expected";
                for (let i = 0; i < message.auditLogs.length; ++i) {
                    let error = $root.api_logs.ApiLogsAuditLog.verify(message.auditLogs[i]);
                    if (error)
                        return "auditLogs." + error;
                }
            }
            if (message.offset != null && message.hasOwnProperty("offset"))
                if (!$util.isInteger(message.offset))
                    return "offset: integer expected";
            if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                if (!$util.isInteger(message.pageSize))
                    return "pageSize: integer expected";
            if (message.lastItemIndex != null && message.hasOwnProperty("lastItemIndex"))
                if (!$util.isInteger(message.lastItemIndex))
                    return "lastItemIndex: integer expected";
            return null;
        };

        /**
         * Creates an ApiLogsAuditLogs message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_logs.ApiLogsAuditLogs
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_logs.ApiLogsAuditLogs} ApiLogsAuditLogs
         */
        ApiLogsAuditLogs.fromObject = function fromObject(object) {
            if (object instanceof $root.api_logs.ApiLogsAuditLogs)
                return object;
            let message = new $root.api_logs.ApiLogsAuditLogs();
            if (object.eventId != null)
                message.eventId = String(object.eventId);
            if (object.auditLogs) {
                if (!Array.isArray(object.auditLogs))
                    throw TypeError(".api_logs.ApiLogsAuditLogs.auditLogs: array expected");
                message.auditLogs = [];
                for (let i = 0; i < object.auditLogs.length; ++i) {
                    if (typeof object.auditLogs[i] !== "object")
                        throw TypeError(".api_logs.ApiLogsAuditLogs.auditLogs: object expected");
                    message.auditLogs[i] = $root.api_logs.ApiLogsAuditLog.fromObject(object.auditLogs[i]);
                }
            }
            if (object.offset != null)
                message.offset = object.offset | 0;
            if (object.pageSize != null)
                message.pageSize = object.pageSize | 0;
            if (object.lastItemIndex != null)
                message.lastItemIndex = object.lastItemIndex | 0;
            return message;
        };

        /**
         * Creates a plain object from an ApiLogsAuditLogs message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_logs.ApiLogsAuditLogs
         * @static
         * @param {api_logs.ApiLogsAuditLogs} message ApiLogsAuditLogs
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiLogsAuditLogs.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.auditLogs = [];
            if (options.defaults) {
                object.eventId = "";
                object.offset = 0;
                object.pageSize = 0;
                object.lastItemIndex = 0;
            }
            if (message.eventId != null && message.hasOwnProperty("eventId"))
                object.eventId = message.eventId;
            if (message.auditLogs && message.auditLogs.length) {
                object.auditLogs = [];
                for (let j = 0; j < message.auditLogs.length; ++j)
                    object.auditLogs[j] = $root.api_logs.ApiLogsAuditLog.toObject(message.auditLogs[j], options);
            }
            if (message.offset != null && message.hasOwnProperty("offset"))
                object.offset = message.offset;
            if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                object.pageSize = message.pageSize;
            if (message.lastItemIndex != null && message.hasOwnProperty("lastItemIndex"))
                object.lastItemIndex = message.lastItemIndex;
            return object;
        };

        /**
         * Converts this ApiLogsAuditLogs to JSON.
         * @function toJSON
         * @memberof api_logs.ApiLogsAuditLogs
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiLogsAuditLogs.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiLogsAuditLogs
         * @function getTypeUrl
         * @memberof api_logs.ApiLogsAuditLogs
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiLogsAuditLogs.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_logs.ApiLogsAuditLogs";
        };

        return ApiLogsAuditLogs;
    })();

    api_logs.ApiLogsAuditLog = (function() {

        /**
         * Properties of an ApiLogsAuditLog.
         * @memberof api_logs
         * @interface IApiLogsAuditLog
         * @property {string|null} [id] ApiLogsAuditLog id
         * @property {string|null} [eventId] ApiLogsAuditLog eventId
         * @property {string|null} [rawEvent] ApiLogsAuditLog rawEvent
         * @property {string|null} [action] ApiLogsAuditLog action
         * @property {string|null} [actionKind] ApiLogsAuditLog actionKind
         * @property {string|null} [deviceType] ApiLogsAuditLog deviceType
         * @property {string|null} [recordId] ApiLogsAuditLog recordId
         * @property {string|null} [courtName] ApiLogsAuditLog courtName
         * @property {string|null} [courtRoomName] ApiLogsAuditLog courtRoomName
         * @property {number|null} [timestamp] ApiLogsAuditLog timestamp
         * @property {string|null} [userDisplayName] ApiLogsAuditLog userDisplayName
         */

        /**
         * Constructs a new ApiLogsAuditLog.
         * @memberof api_logs
         * @classdesc Represents an ApiLogsAuditLog.
         * @implements IApiLogsAuditLog
         * @constructor
         * @param {api_logs.IApiLogsAuditLog=} [properties] Properties to set
         */
        function ApiLogsAuditLog(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiLogsAuditLog id.
         * @member {string} id
         * @memberof api_logs.ApiLogsAuditLog
         * @instance
         */
        ApiLogsAuditLog.prototype.id = "";

        /**
         * ApiLogsAuditLog eventId.
         * @member {string} eventId
         * @memberof api_logs.ApiLogsAuditLog
         * @instance
         */
        ApiLogsAuditLog.prototype.eventId = "";

        /**
         * ApiLogsAuditLog rawEvent.
         * @member {string} rawEvent
         * @memberof api_logs.ApiLogsAuditLog
         * @instance
         */
        ApiLogsAuditLog.prototype.rawEvent = "";

        /**
         * ApiLogsAuditLog action.
         * @member {string} action
         * @memberof api_logs.ApiLogsAuditLog
         * @instance
         */
        ApiLogsAuditLog.prototype.action = "";

        /**
         * ApiLogsAuditLog actionKind.
         * @member {string} actionKind
         * @memberof api_logs.ApiLogsAuditLog
         * @instance
         */
        ApiLogsAuditLog.prototype.actionKind = "";

        /**
         * ApiLogsAuditLog deviceType.
         * @member {string} deviceType
         * @memberof api_logs.ApiLogsAuditLog
         * @instance
         */
        ApiLogsAuditLog.prototype.deviceType = "";

        /**
         * ApiLogsAuditLog recordId.
         * @member {string} recordId
         * @memberof api_logs.ApiLogsAuditLog
         * @instance
         */
        ApiLogsAuditLog.prototype.recordId = "";

        /**
         * ApiLogsAuditLog courtName.
         * @member {string} courtName
         * @memberof api_logs.ApiLogsAuditLog
         * @instance
         */
        ApiLogsAuditLog.prototype.courtName = "";

        /**
         * ApiLogsAuditLog courtRoomName.
         * @member {string} courtRoomName
         * @memberof api_logs.ApiLogsAuditLog
         * @instance
         */
        ApiLogsAuditLog.prototype.courtRoomName = "";

        /**
         * ApiLogsAuditLog timestamp.
         * @member {number} timestamp
         * @memberof api_logs.ApiLogsAuditLog
         * @instance
         */
        ApiLogsAuditLog.prototype.timestamp = 0;

        /**
         * ApiLogsAuditLog userDisplayName.
         * @member {string} userDisplayName
         * @memberof api_logs.ApiLogsAuditLog
         * @instance
         */
        ApiLogsAuditLog.prototype.userDisplayName = "";

        /**
         * Creates a new ApiLogsAuditLog instance using the specified properties.
         * @function create
         * @memberof api_logs.ApiLogsAuditLog
         * @static
         * @param {api_logs.IApiLogsAuditLog=} [properties] Properties to set
         * @returns {api_logs.ApiLogsAuditLog} ApiLogsAuditLog instance
         */
        ApiLogsAuditLog.create = function create(properties) {
            return new ApiLogsAuditLog(properties);
        };

        /**
         * Encodes the specified ApiLogsAuditLog message. Does not implicitly {@link api_logs.ApiLogsAuditLog.verify|verify} messages.
         * @function encode
         * @memberof api_logs.ApiLogsAuditLog
         * @static
         * @param {api_logs.IApiLogsAuditLog} message ApiLogsAuditLog message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiLogsAuditLog.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.eventId != null && Object.hasOwnProperty.call(message, "eventId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.eventId);
            if (message.rawEvent != null && Object.hasOwnProperty.call(message, "rawEvent"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.rawEvent);
            if (message.action != null && Object.hasOwnProperty.call(message, "action"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.action);
            if (message.actionKind != null && Object.hasOwnProperty.call(message, "actionKind"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.actionKind);
            if (message.deviceType != null && Object.hasOwnProperty.call(message, "deviceType"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.deviceType);
            if (message.recordId != null && Object.hasOwnProperty.call(message, "recordId"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.recordId);
            if (message.courtName != null && Object.hasOwnProperty.call(message, "courtName"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.courtName);
            if (message.courtRoomName != null && Object.hasOwnProperty.call(message, "courtRoomName"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.courtRoomName);
            if (message.timestamp != null && Object.hasOwnProperty.call(message, "timestamp"))
                writer.uint32(/* id 10, wireType 0 =*/80).int32(message.timestamp);
            if (message.userDisplayName != null && Object.hasOwnProperty.call(message, "userDisplayName"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.userDisplayName);
            return writer;
        };

        /**
         * Encodes the specified ApiLogsAuditLog message, length delimited. Does not implicitly {@link api_logs.ApiLogsAuditLog.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_logs.ApiLogsAuditLog
         * @static
         * @param {api_logs.IApiLogsAuditLog} message ApiLogsAuditLog message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiLogsAuditLog.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiLogsAuditLog message from the specified reader or buffer.
         * @function decode
         * @memberof api_logs.ApiLogsAuditLog
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_logs.ApiLogsAuditLog} ApiLogsAuditLog
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiLogsAuditLog.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_logs.ApiLogsAuditLog();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 2: {
                        message.eventId = reader.string();
                        break;
                    }
                case 3: {
                        message.rawEvent = reader.string();
                        break;
                    }
                case 4: {
                        message.action = reader.string();
                        break;
                    }
                case 5: {
                        message.actionKind = reader.string();
                        break;
                    }
                case 6: {
                        message.deviceType = reader.string();
                        break;
                    }
                case 7: {
                        message.recordId = reader.string();
                        break;
                    }
                case 8: {
                        message.courtName = reader.string();
                        break;
                    }
                case 9: {
                        message.courtRoomName = reader.string();
                        break;
                    }
                case 10: {
                        message.timestamp = reader.int32();
                        break;
                    }
                case 11: {
                        message.userDisplayName = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiLogsAuditLog message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_logs.ApiLogsAuditLog
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_logs.ApiLogsAuditLog} ApiLogsAuditLog
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiLogsAuditLog.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiLogsAuditLog message.
         * @function verify
         * @memberof api_logs.ApiLogsAuditLog
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiLogsAuditLog.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.eventId != null && message.hasOwnProperty("eventId"))
                if (!$util.isString(message.eventId))
                    return "eventId: string expected";
            if (message.rawEvent != null && message.hasOwnProperty("rawEvent"))
                if (!$util.isString(message.rawEvent))
                    return "rawEvent: string expected";
            if (message.action != null && message.hasOwnProperty("action"))
                if (!$util.isString(message.action))
                    return "action: string expected";
            if (message.actionKind != null && message.hasOwnProperty("actionKind"))
                if (!$util.isString(message.actionKind))
                    return "actionKind: string expected";
            if (message.deviceType != null && message.hasOwnProperty("deviceType"))
                if (!$util.isString(message.deviceType))
                    return "deviceType: string expected";
            if (message.recordId != null && message.hasOwnProperty("recordId"))
                if (!$util.isString(message.recordId))
                    return "recordId: string expected";
            if (message.courtName != null && message.hasOwnProperty("courtName"))
                if (!$util.isString(message.courtName))
                    return "courtName: string expected";
            if (message.courtRoomName != null && message.hasOwnProperty("courtRoomName"))
                if (!$util.isString(message.courtRoomName))
                    return "courtRoomName: string expected";
            if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                if (!$util.isInteger(message.timestamp))
                    return "timestamp: integer expected";
            if (message.userDisplayName != null && message.hasOwnProperty("userDisplayName"))
                if (!$util.isString(message.userDisplayName))
                    return "userDisplayName: string expected";
            return null;
        };

        /**
         * Creates an ApiLogsAuditLog message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_logs.ApiLogsAuditLog
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_logs.ApiLogsAuditLog} ApiLogsAuditLog
         */
        ApiLogsAuditLog.fromObject = function fromObject(object) {
            if (object instanceof $root.api_logs.ApiLogsAuditLog)
                return object;
            let message = new $root.api_logs.ApiLogsAuditLog();
            if (object.id != null)
                message.id = String(object.id);
            if (object.eventId != null)
                message.eventId = String(object.eventId);
            if (object.rawEvent != null)
                message.rawEvent = String(object.rawEvent);
            if (object.action != null)
                message.action = String(object.action);
            if (object.actionKind != null)
                message.actionKind = String(object.actionKind);
            if (object.deviceType != null)
                message.deviceType = String(object.deviceType);
            if (object.recordId != null)
                message.recordId = String(object.recordId);
            if (object.courtName != null)
                message.courtName = String(object.courtName);
            if (object.courtRoomName != null)
                message.courtRoomName = String(object.courtRoomName);
            if (object.timestamp != null)
                message.timestamp = object.timestamp | 0;
            if (object.userDisplayName != null)
                message.userDisplayName = String(object.userDisplayName);
            return message;
        };

        /**
         * Creates a plain object from an ApiLogsAuditLog message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_logs.ApiLogsAuditLog
         * @static
         * @param {api_logs.ApiLogsAuditLog} message ApiLogsAuditLog
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiLogsAuditLog.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.id = "";
                object.eventId = "";
                object.rawEvent = "";
                object.action = "";
                object.actionKind = "";
                object.deviceType = "";
                object.recordId = "";
                object.courtName = "";
                object.courtRoomName = "";
                object.timestamp = 0;
                object.userDisplayName = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.eventId != null && message.hasOwnProperty("eventId"))
                object.eventId = message.eventId;
            if (message.rawEvent != null && message.hasOwnProperty("rawEvent"))
                object.rawEvent = message.rawEvent;
            if (message.action != null && message.hasOwnProperty("action"))
                object.action = message.action;
            if (message.actionKind != null && message.hasOwnProperty("actionKind"))
                object.actionKind = message.actionKind;
            if (message.deviceType != null && message.hasOwnProperty("deviceType"))
                object.deviceType = message.deviceType;
            if (message.recordId != null && message.hasOwnProperty("recordId"))
                object.recordId = message.recordId;
            if (message.courtName != null && message.hasOwnProperty("courtName"))
                object.courtName = message.courtName;
            if (message.courtRoomName != null && message.hasOwnProperty("courtRoomName"))
                object.courtRoomName = message.courtRoomName;
            if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                object.timestamp = message.timestamp;
            if (message.userDisplayName != null && message.hasOwnProperty("userDisplayName"))
                object.userDisplayName = message.userDisplayName;
            return object;
        };

        /**
         * Converts this ApiLogsAuditLog to JSON.
         * @function toJSON
         * @memberof api_logs.ApiLogsAuditLog
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiLogsAuditLog.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiLogsAuditLog
         * @function getTypeUrl
         * @memberof api_logs.ApiLogsAuditLog
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiLogsAuditLog.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_logs.ApiLogsAuditLog";
        };

        return ApiLogsAuditLog;
    })();

    return api_logs;
})();

export const ws_home = $root.ws_home = (() => {

    /**
     * Namespace ws_home.
     * @exports ws_home
     * @namespace
     */
    const ws_home = {};

    ws_home.LoadAsodProjects = (function() {

        /**
         * Properties of a LoadAsodProjects.
         * @memberof ws_home
         * @interface ILoadAsodProjects
         * @property {string|null} [quickSearch] LoadAsodProjects quickSearch
         * @property {string|null} [court] LoadAsodProjects court
         * @property {string|null} [courtRoom] LoadAsodProjects courtRoom
         * @property {string|null} [opNumber] LoadAsodProjects opNumber
         * @property {common.IDateFilter|null} [dateFrom] LoadAsodProjects dateFrom
         * @property {common.IDateFilter|null} [dateTo] LoadAsodProjects dateTo
         * @property {common.IPagination|null} [pagination] LoadAsodProjects pagination
         * @property {ws_home.AsodProjectsOrderingTerm|null} [sortBy] LoadAsodProjects sortBy
         * @property {common.SortOrder|null} [sortOrder] LoadAsodProjects sortOrder
         * @property {ws_home.IAsodProjects|null} [_response] LoadAsodProjects _response
         */

        /**
         * Constructs a new LoadAsodProjects.
         * @memberof ws_home
         * @classdesc Represents a LoadAsodProjects.
         * @implements ILoadAsodProjects
         * @constructor
         * @param {ws_home.ILoadAsodProjects=} [properties] Properties to set
         */
        function LoadAsodProjects(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LoadAsodProjects quickSearch.
         * @member {string} quickSearch
         * @memberof ws_home.LoadAsodProjects
         * @instance
         */
        LoadAsodProjects.prototype.quickSearch = "";

        /**
         * LoadAsodProjects court.
         * @member {string} court
         * @memberof ws_home.LoadAsodProjects
         * @instance
         */
        LoadAsodProjects.prototype.court = "";

        /**
         * LoadAsodProjects courtRoom.
         * @member {string} courtRoom
         * @memberof ws_home.LoadAsodProjects
         * @instance
         */
        LoadAsodProjects.prototype.courtRoom = "";

        /**
         * LoadAsodProjects opNumber.
         * @member {string} opNumber
         * @memberof ws_home.LoadAsodProjects
         * @instance
         */
        LoadAsodProjects.prototype.opNumber = "";

        /**
         * LoadAsodProjects dateFrom.
         * @member {common.IDateFilter|null|undefined} dateFrom
         * @memberof ws_home.LoadAsodProjects
         * @instance
         */
        LoadAsodProjects.prototype.dateFrom = null;

        /**
         * LoadAsodProjects dateTo.
         * @member {common.IDateFilter|null|undefined} dateTo
         * @memberof ws_home.LoadAsodProjects
         * @instance
         */
        LoadAsodProjects.prototype.dateTo = null;

        /**
         * LoadAsodProjects pagination.
         * @member {common.IPagination|null|undefined} pagination
         * @memberof ws_home.LoadAsodProjects
         * @instance
         */
        LoadAsodProjects.prototype.pagination = null;

        /**
         * LoadAsodProjects sortBy.
         * @member {ws_home.AsodProjectsOrderingTerm} sortBy
         * @memberof ws_home.LoadAsodProjects
         * @instance
         */
        LoadAsodProjects.prototype.sortBy = 0;

        /**
         * LoadAsodProjects sortOrder.
         * @member {common.SortOrder} sortOrder
         * @memberof ws_home.LoadAsodProjects
         * @instance
         */
        LoadAsodProjects.prototype.sortOrder = 0;

        /**
         * LoadAsodProjects _response.
         * @member {ws_home.IAsodProjects|null|undefined} _response
         * @memberof ws_home.LoadAsodProjects
         * @instance
         */
        LoadAsodProjects.prototype._response = null;

        /**
         * Creates a new LoadAsodProjects instance using the specified properties.
         * @function create
         * @memberof ws_home.LoadAsodProjects
         * @static
         * @param {ws_home.ILoadAsodProjects=} [properties] Properties to set
         * @returns {ws_home.LoadAsodProjects} LoadAsodProjects instance
         */
        LoadAsodProjects.create = function create(properties) {
            return new LoadAsodProjects(properties);
        };

        /**
         * Encodes the specified LoadAsodProjects message. Does not implicitly {@link ws_home.LoadAsodProjects.verify|verify} messages.
         * @function encode
         * @memberof ws_home.LoadAsodProjects
         * @static
         * @param {ws_home.ILoadAsodProjects} message LoadAsodProjects message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoadAsodProjects.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.quickSearch != null && Object.hasOwnProperty.call(message, "quickSearch"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.quickSearch);
            if (message.court != null && Object.hasOwnProperty.call(message, "court"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.court);
            if (message.courtRoom != null && Object.hasOwnProperty.call(message, "courtRoom"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.courtRoom);
            if (message.opNumber != null && Object.hasOwnProperty.call(message, "opNumber"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.opNumber);
            if (message.dateFrom != null && Object.hasOwnProperty.call(message, "dateFrom"))
                $root.common.DateFilter.encode(message.dateFrom, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.dateTo != null && Object.hasOwnProperty.call(message, "dateTo"))
                $root.common.DateFilter.encode(message.dateTo, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.pagination != null && Object.hasOwnProperty.call(message, "pagination"))
                $root.common.Pagination.encode(message.pagination, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.sortBy != null && Object.hasOwnProperty.call(message, "sortBy"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.sortBy);
            if (message.sortOrder != null && Object.hasOwnProperty.call(message, "sortOrder"))
                writer.uint32(/* id 9, wireType 0 =*/72).int32(message.sortOrder);
            if (message._response != null && Object.hasOwnProperty.call(message, "_response"))
                $root.ws_home.AsodProjects.encode(message._response, writer.uint32(/* id 99, wireType 2 =*/794).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified LoadAsodProjects message, length delimited. Does not implicitly {@link ws_home.LoadAsodProjects.verify|verify} messages.
         * @function encodeDelimited
         * @memberof ws_home.LoadAsodProjects
         * @static
         * @param {ws_home.ILoadAsodProjects} message LoadAsodProjects message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoadAsodProjects.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LoadAsodProjects message from the specified reader or buffer.
         * @function decode
         * @memberof ws_home.LoadAsodProjects
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {ws_home.LoadAsodProjects} LoadAsodProjects
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoadAsodProjects.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ws_home.LoadAsodProjects();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.quickSearch = reader.string();
                        break;
                    }
                case 2: {
                        message.court = reader.string();
                        break;
                    }
                case 3: {
                        message.courtRoom = reader.string();
                        break;
                    }
                case 4: {
                        message.opNumber = reader.string();
                        break;
                    }
                case 5: {
                        message.dateFrom = $root.common.DateFilter.decode(reader, reader.uint32());
                        break;
                    }
                case 6: {
                        message.dateTo = $root.common.DateFilter.decode(reader, reader.uint32());
                        break;
                    }
                case 7: {
                        message.pagination = $root.common.Pagination.decode(reader, reader.uint32());
                        break;
                    }
                case 8: {
                        message.sortBy = reader.int32();
                        break;
                    }
                case 9: {
                        message.sortOrder = reader.int32();
                        break;
                    }
                case 99: {
                        message._response = $root.ws_home.AsodProjects.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LoadAsodProjects message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof ws_home.LoadAsodProjects
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {ws_home.LoadAsodProjects} LoadAsodProjects
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoadAsodProjects.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LoadAsodProjects message.
         * @function verify
         * @memberof ws_home.LoadAsodProjects
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LoadAsodProjects.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.quickSearch != null && message.hasOwnProperty("quickSearch"))
                if (!$util.isString(message.quickSearch))
                    return "quickSearch: string expected";
            if (message.court != null && message.hasOwnProperty("court"))
                if (!$util.isString(message.court))
                    return "court: string expected";
            if (message.courtRoom != null && message.hasOwnProperty("courtRoom"))
                if (!$util.isString(message.courtRoom))
                    return "courtRoom: string expected";
            if (message.opNumber != null && message.hasOwnProperty("opNumber"))
                if (!$util.isString(message.opNumber))
                    return "opNumber: string expected";
            if (message.dateFrom != null && message.hasOwnProperty("dateFrom")) {
                let error = $root.common.DateFilter.verify(message.dateFrom);
                if (error)
                    return "dateFrom." + error;
            }
            if (message.dateTo != null && message.hasOwnProperty("dateTo")) {
                let error = $root.common.DateFilter.verify(message.dateTo);
                if (error)
                    return "dateTo." + error;
            }
            if (message.pagination != null && message.hasOwnProperty("pagination")) {
                let error = $root.common.Pagination.verify(message.pagination);
                if (error)
                    return "pagination." + error;
            }
            if (message.sortBy != null && message.hasOwnProperty("sortBy"))
                switch (message.sortBy) {
                default:
                    return "sortBy: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                switch (message.sortOrder) {
                default:
                    return "sortOrder: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message._response != null && message.hasOwnProperty("_response")) {
                let error = $root.ws_home.AsodProjects.verify(message._response);
                if (error)
                    return "_response." + error;
            }
            return null;
        };

        /**
         * Creates a LoadAsodProjects message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof ws_home.LoadAsodProjects
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {ws_home.LoadAsodProjects} LoadAsodProjects
         */
        LoadAsodProjects.fromObject = function fromObject(object) {
            if (object instanceof $root.ws_home.LoadAsodProjects)
                return object;
            let message = new $root.ws_home.LoadAsodProjects();
            if (object.quickSearch != null)
                message.quickSearch = String(object.quickSearch);
            if (object.court != null)
                message.court = String(object.court);
            if (object.courtRoom != null)
                message.courtRoom = String(object.courtRoom);
            if (object.opNumber != null)
                message.opNumber = String(object.opNumber);
            if (object.dateFrom != null) {
                if (typeof object.dateFrom !== "object")
                    throw TypeError(".ws_home.LoadAsodProjects.dateFrom: object expected");
                message.dateFrom = $root.common.DateFilter.fromObject(object.dateFrom);
            }
            if (object.dateTo != null) {
                if (typeof object.dateTo !== "object")
                    throw TypeError(".ws_home.LoadAsodProjects.dateTo: object expected");
                message.dateTo = $root.common.DateFilter.fromObject(object.dateTo);
            }
            if (object.pagination != null) {
                if (typeof object.pagination !== "object")
                    throw TypeError(".ws_home.LoadAsodProjects.pagination: object expected");
                message.pagination = $root.common.Pagination.fromObject(object.pagination);
            }
            switch (object.sortBy) {
            default:
                if (typeof object.sortBy === "number") {
                    message.sortBy = object.sortBy;
                    break;
                }
                break;
            case "apot_court":
            case 0:
                message.sortBy = 0;
                break;
            case "apot_court_room":
            case 1:
                message.sortBy = 1;
                break;
            case "apot_op_number":
            case 2:
                message.sortBy = 2;
                break;
            case "apot_date":
            case 3:
                message.sortBy = 3;
                break;
            }
            switch (object.sortOrder) {
            default:
                if (typeof object.sortOrder === "number") {
                    message.sortOrder = object.sortOrder;
                    break;
                }
                break;
            case "so_none":
            case 0:
                message.sortOrder = 0;
                break;
            case "so_asc":
            case 1:
                message.sortOrder = 1;
                break;
            case "so_desc":
            case 2:
                message.sortOrder = 2;
                break;
            }
            if (object._response != null) {
                if (typeof object._response !== "object")
                    throw TypeError(".ws_home.LoadAsodProjects._response: object expected");
                message._response = $root.ws_home.AsodProjects.fromObject(object._response);
            }
            return message;
        };

        /**
         * Creates a plain object from a LoadAsodProjects message. Also converts values to other types if specified.
         * @function toObject
         * @memberof ws_home.LoadAsodProjects
         * @static
         * @param {ws_home.LoadAsodProjects} message LoadAsodProjects
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LoadAsodProjects.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.quickSearch = "";
                object.court = "";
                object.courtRoom = "";
                object.opNumber = "";
                object.dateFrom = null;
                object.dateTo = null;
                object.pagination = null;
                object.sortBy = options.enums === String ? "apot_court" : 0;
                object.sortOrder = options.enums === String ? "so_none" : 0;
                object._response = null;
            }
            if (message.quickSearch != null && message.hasOwnProperty("quickSearch"))
                object.quickSearch = message.quickSearch;
            if (message.court != null && message.hasOwnProperty("court"))
                object.court = message.court;
            if (message.courtRoom != null && message.hasOwnProperty("courtRoom"))
                object.courtRoom = message.courtRoom;
            if (message.opNumber != null && message.hasOwnProperty("opNumber"))
                object.opNumber = message.opNumber;
            if (message.dateFrom != null && message.hasOwnProperty("dateFrom"))
                object.dateFrom = $root.common.DateFilter.toObject(message.dateFrom, options);
            if (message.dateTo != null && message.hasOwnProperty("dateTo"))
                object.dateTo = $root.common.DateFilter.toObject(message.dateTo, options);
            if (message.pagination != null && message.hasOwnProperty("pagination"))
                object.pagination = $root.common.Pagination.toObject(message.pagination, options);
            if (message.sortBy != null && message.hasOwnProperty("sortBy"))
                object.sortBy = options.enums === String ? $root.ws_home.AsodProjectsOrderingTerm[message.sortBy] === undefined ? message.sortBy : $root.ws_home.AsodProjectsOrderingTerm[message.sortBy] : message.sortBy;
            if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                object.sortOrder = options.enums === String ? $root.common.SortOrder[message.sortOrder] === undefined ? message.sortOrder : $root.common.SortOrder[message.sortOrder] : message.sortOrder;
            if (message._response != null && message.hasOwnProperty("_response"))
                object._response = $root.ws_home.AsodProjects.toObject(message._response, options);
            return object;
        };

        /**
         * Converts this LoadAsodProjects to JSON.
         * @function toJSON
         * @memberof ws_home.LoadAsodProjects
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LoadAsodProjects.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LoadAsodProjects
         * @function getTypeUrl
         * @memberof ws_home.LoadAsodProjects
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LoadAsodProjects.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/ws_home.LoadAsodProjects";
        };

        return LoadAsodProjects;
    })();

    ws_home.AsodProjects = (function() {

        /**
         * Properties of an AsodProjects.
         * @memberof ws_home
         * @interface IAsodProjects
         * @property {Array.<ws_home.IAsodProjectRow>|null} [rows] AsodProjects rows
         * @property {common.IPagination|null} [pagination] AsodProjects pagination
         * @property {ws_home.AsodProjectsOrderingTerm|null} [sortBy] AsodProjects sortBy
         * @property {common.SortOrder|null} [sortOrder] AsodProjects sortOrder
         */

        /**
         * Constructs a new AsodProjects.
         * @memberof ws_home
         * @classdesc Represents an AsodProjects.
         * @implements IAsodProjects
         * @constructor
         * @param {ws_home.IAsodProjects=} [properties] Properties to set
         */
        function AsodProjects(properties) {
            this.rows = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AsodProjects rows.
         * @member {Array.<ws_home.IAsodProjectRow>} rows
         * @memberof ws_home.AsodProjects
         * @instance
         */
        AsodProjects.prototype.rows = $util.emptyArray;

        /**
         * AsodProjects pagination.
         * @member {common.IPagination|null|undefined} pagination
         * @memberof ws_home.AsodProjects
         * @instance
         */
        AsodProjects.prototype.pagination = null;

        /**
         * AsodProjects sortBy.
         * @member {ws_home.AsodProjectsOrderingTerm} sortBy
         * @memberof ws_home.AsodProjects
         * @instance
         */
        AsodProjects.prototype.sortBy = 0;

        /**
         * AsodProjects sortOrder.
         * @member {common.SortOrder} sortOrder
         * @memberof ws_home.AsodProjects
         * @instance
         */
        AsodProjects.prototype.sortOrder = 0;

        /**
         * Creates a new AsodProjects instance using the specified properties.
         * @function create
         * @memberof ws_home.AsodProjects
         * @static
         * @param {ws_home.IAsodProjects=} [properties] Properties to set
         * @returns {ws_home.AsodProjects} AsodProjects instance
         */
        AsodProjects.create = function create(properties) {
            return new AsodProjects(properties);
        };

        /**
         * Encodes the specified AsodProjects message. Does not implicitly {@link ws_home.AsodProjects.verify|verify} messages.
         * @function encode
         * @memberof ws_home.AsodProjects
         * @static
         * @param {ws_home.IAsodProjects} message AsodProjects message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodProjects.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rows != null && message.rows.length)
                for (let i = 0; i < message.rows.length; ++i)
                    $root.ws_home.AsodProjectRow.encode(message.rows[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.pagination != null && Object.hasOwnProperty.call(message, "pagination"))
                $root.common.Pagination.encode(message.pagination, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.sortBy != null && Object.hasOwnProperty.call(message, "sortBy"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.sortBy);
            if (message.sortOrder != null && Object.hasOwnProperty.call(message, "sortOrder"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.sortOrder);
            return writer;
        };

        /**
         * Encodes the specified AsodProjects message, length delimited. Does not implicitly {@link ws_home.AsodProjects.verify|verify} messages.
         * @function encodeDelimited
         * @memberof ws_home.AsodProjects
         * @static
         * @param {ws_home.IAsodProjects} message AsodProjects message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodProjects.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AsodProjects message from the specified reader or buffer.
         * @function decode
         * @memberof ws_home.AsodProjects
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {ws_home.AsodProjects} AsodProjects
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodProjects.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ws_home.AsodProjects();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.rows && message.rows.length))
                            message.rows = [];
                        message.rows.push($root.ws_home.AsodProjectRow.decode(reader, reader.uint32()));
                        break;
                    }
                case 6: {
                        message.pagination = $root.common.Pagination.decode(reader, reader.uint32());
                        break;
                    }
                case 7: {
                        message.sortBy = reader.int32();
                        break;
                    }
                case 8: {
                        message.sortOrder = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AsodProjects message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof ws_home.AsodProjects
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {ws_home.AsodProjects} AsodProjects
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodProjects.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AsodProjects message.
         * @function verify
         * @memberof ws_home.AsodProjects
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AsodProjects.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.rows != null && message.hasOwnProperty("rows")) {
                if (!Array.isArray(message.rows))
                    return "rows: array expected";
                for (let i = 0; i < message.rows.length; ++i) {
                    let error = $root.ws_home.AsodProjectRow.verify(message.rows[i]);
                    if (error)
                        return "rows." + error;
                }
            }
            if (message.pagination != null && message.hasOwnProperty("pagination")) {
                let error = $root.common.Pagination.verify(message.pagination);
                if (error)
                    return "pagination." + error;
            }
            if (message.sortBy != null && message.hasOwnProperty("sortBy"))
                switch (message.sortBy) {
                default:
                    return "sortBy: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                switch (message.sortOrder) {
                default:
                    return "sortOrder: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            return null;
        };

        /**
         * Creates an AsodProjects message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof ws_home.AsodProjects
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {ws_home.AsodProjects} AsodProjects
         */
        AsodProjects.fromObject = function fromObject(object) {
            if (object instanceof $root.ws_home.AsodProjects)
                return object;
            let message = new $root.ws_home.AsodProjects();
            if (object.rows) {
                if (!Array.isArray(object.rows))
                    throw TypeError(".ws_home.AsodProjects.rows: array expected");
                message.rows = [];
                for (let i = 0; i < object.rows.length; ++i) {
                    if (typeof object.rows[i] !== "object")
                        throw TypeError(".ws_home.AsodProjects.rows: object expected");
                    message.rows[i] = $root.ws_home.AsodProjectRow.fromObject(object.rows[i]);
                }
            }
            if (object.pagination != null) {
                if (typeof object.pagination !== "object")
                    throw TypeError(".ws_home.AsodProjects.pagination: object expected");
                message.pagination = $root.common.Pagination.fromObject(object.pagination);
            }
            switch (object.sortBy) {
            default:
                if (typeof object.sortBy === "number") {
                    message.sortBy = object.sortBy;
                    break;
                }
                break;
            case "apot_court":
            case 0:
                message.sortBy = 0;
                break;
            case "apot_court_room":
            case 1:
                message.sortBy = 1;
                break;
            case "apot_op_number":
            case 2:
                message.sortBy = 2;
                break;
            case "apot_date":
            case 3:
                message.sortBy = 3;
                break;
            }
            switch (object.sortOrder) {
            default:
                if (typeof object.sortOrder === "number") {
                    message.sortOrder = object.sortOrder;
                    break;
                }
                break;
            case "so_none":
            case 0:
                message.sortOrder = 0;
                break;
            case "so_asc":
            case 1:
                message.sortOrder = 1;
                break;
            case "so_desc":
            case 2:
                message.sortOrder = 2;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from an AsodProjects message. Also converts values to other types if specified.
         * @function toObject
         * @memberof ws_home.AsodProjects
         * @static
         * @param {ws_home.AsodProjects} message AsodProjects
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AsodProjects.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.rows = [];
            if (options.defaults) {
                object.pagination = null;
                object.sortBy = options.enums === String ? "apot_court" : 0;
                object.sortOrder = options.enums === String ? "so_none" : 0;
            }
            if (message.rows && message.rows.length) {
                object.rows = [];
                for (let j = 0; j < message.rows.length; ++j)
                    object.rows[j] = $root.ws_home.AsodProjectRow.toObject(message.rows[j], options);
            }
            if (message.pagination != null && message.hasOwnProperty("pagination"))
                object.pagination = $root.common.Pagination.toObject(message.pagination, options);
            if (message.sortBy != null && message.hasOwnProperty("sortBy"))
                object.sortBy = options.enums === String ? $root.ws_home.AsodProjectsOrderingTerm[message.sortBy] === undefined ? message.sortBy : $root.ws_home.AsodProjectsOrderingTerm[message.sortBy] : message.sortBy;
            if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                object.sortOrder = options.enums === String ? $root.common.SortOrder[message.sortOrder] === undefined ? message.sortOrder : $root.common.SortOrder[message.sortOrder] : message.sortOrder;
            return object;
        };

        /**
         * Converts this AsodProjects to JSON.
         * @function toJSON
         * @memberof ws_home.AsodProjects
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AsodProjects.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AsodProjects
         * @function getTypeUrl
         * @memberof ws_home.AsodProjects
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AsodProjects.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/ws_home.AsodProjects";
        };

        return AsodProjects;
    })();

    ws_home.AsodProjectRow = (function() {

        /**
         * Properties of an AsodProjectRow.
         * @memberof ws_home
         * @interface IAsodProjectRow
         * @property {string|null} [id] AsodProjectRow id
         * @property {string|null} [court] AsodProjectRow court
         * @property {string|null} [courtRoom] AsodProjectRow courtRoom
         * @property {string|null} [opNumber] AsodProjectRow opNumber
         * @property {Array.<ws_home.IAsodProjectRowParticipant>|null} [participants] AsodProjectRow participants
         * @property {common.IDate|null} [date] AsodProjectRow date
         */

        /**
         * Constructs a new AsodProjectRow.
         * @memberof ws_home
         * @classdesc Represents an AsodProjectRow.
         * @implements IAsodProjectRow
         * @constructor
         * @param {ws_home.IAsodProjectRow=} [properties] Properties to set
         */
        function AsodProjectRow(properties) {
            this.participants = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AsodProjectRow id.
         * @member {string} id
         * @memberof ws_home.AsodProjectRow
         * @instance
         */
        AsodProjectRow.prototype.id = "";

        /**
         * AsodProjectRow court.
         * @member {string} court
         * @memberof ws_home.AsodProjectRow
         * @instance
         */
        AsodProjectRow.prototype.court = "";

        /**
         * AsodProjectRow courtRoom.
         * @member {string} courtRoom
         * @memberof ws_home.AsodProjectRow
         * @instance
         */
        AsodProjectRow.prototype.courtRoom = "";

        /**
         * AsodProjectRow opNumber.
         * @member {string} opNumber
         * @memberof ws_home.AsodProjectRow
         * @instance
         */
        AsodProjectRow.prototype.opNumber = "";

        /**
         * AsodProjectRow participants.
         * @member {Array.<ws_home.IAsodProjectRowParticipant>} participants
         * @memberof ws_home.AsodProjectRow
         * @instance
         */
        AsodProjectRow.prototype.participants = $util.emptyArray;

        /**
         * AsodProjectRow date.
         * @member {common.IDate|null|undefined} date
         * @memberof ws_home.AsodProjectRow
         * @instance
         */
        AsodProjectRow.prototype.date = null;

        /**
         * Creates a new AsodProjectRow instance using the specified properties.
         * @function create
         * @memberof ws_home.AsodProjectRow
         * @static
         * @param {ws_home.IAsodProjectRow=} [properties] Properties to set
         * @returns {ws_home.AsodProjectRow} AsodProjectRow instance
         */
        AsodProjectRow.create = function create(properties) {
            return new AsodProjectRow(properties);
        };

        /**
         * Encodes the specified AsodProjectRow message. Does not implicitly {@link ws_home.AsodProjectRow.verify|verify} messages.
         * @function encode
         * @memberof ws_home.AsodProjectRow
         * @static
         * @param {ws_home.IAsodProjectRow} message AsodProjectRow message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodProjectRow.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.court != null && Object.hasOwnProperty.call(message, "court"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.court);
            if (message.courtRoom != null && Object.hasOwnProperty.call(message, "courtRoom"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.courtRoom);
            if (message.opNumber != null && Object.hasOwnProperty.call(message, "opNumber"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.opNumber);
            if (message.participants != null && message.participants.length)
                for (let i = 0; i < message.participants.length; ++i)
                    $root.ws_home.AsodProjectRowParticipant.encode(message.participants[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.date != null && Object.hasOwnProperty.call(message, "date"))
                $root.common.Date.encode(message.date, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified AsodProjectRow message, length delimited. Does not implicitly {@link ws_home.AsodProjectRow.verify|verify} messages.
         * @function encodeDelimited
         * @memberof ws_home.AsodProjectRow
         * @static
         * @param {ws_home.IAsodProjectRow} message AsodProjectRow message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodProjectRow.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AsodProjectRow message from the specified reader or buffer.
         * @function decode
         * @memberof ws_home.AsodProjectRow
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {ws_home.AsodProjectRow} AsodProjectRow
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodProjectRow.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ws_home.AsodProjectRow();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 2: {
                        message.court = reader.string();
                        break;
                    }
                case 3: {
                        message.courtRoom = reader.string();
                        break;
                    }
                case 4: {
                        message.opNumber = reader.string();
                        break;
                    }
                case 5: {
                        if (!(message.participants && message.participants.length))
                            message.participants = [];
                        message.participants.push($root.ws_home.AsodProjectRowParticipant.decode(reader, reader.uint32()));
                        break;
                    }
                case 6: {
                        message.date = $root.common.Date.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AsodProjectRow message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof ws_home.AsodProjectRow
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {ws_home.AsodProjectRow} AsodProjectRow
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodProjectRow.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AsodProjectRow message.
         * @function verify
         * @memberof ws_home.AsodProjectRow
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AsodProjectRow.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.court != null && message.hasOwnProperty("court"))
                if (!$util.isString(message.court))
                    return "court: string expected";
            if (message.courtRoom != null && message.hasOwnProperty("courtRoom"))
                if (!$util.isString(message.courtRoom))
                    return "courtRoom: string expected";
            if (message.opNumber != null && message.hasOwnProperty("opNumber"))
                if (!$util.isString(message.opNumber))
                    return "opNumber: string expected";
            if (message.participants != null && message.hasOwnProperty("participants")) {
                if (!Array.isArray(message.participants))
                    return "participants: array expected";
                for (let i = 0; i < message.participants.length; ++i) {
                    let error = $root.ws_home.AsodProjectRowParticipant.verify(message.participants[i]);
                    if (error)
                        return "participants." + error;
                }
            }
            if (message.date != null && message.hasOwnProperty("date")) {
                let error = $root.common.Date.verify(message.date);
                if (error)
                    return "date." + error;
            }
            return null;
        };

        /**
         * Creates an AsodProjectRow message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof ws_home.AsodProjectRow
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {ws_home.AsodProjectRow} AsodProjectRow
         */
        AsodProjectRow.fromObject = function fromObject(object) {
            if (object instanceof $root.ws_home.AsodProjectRow)
                return object;
            let message = new $root.ws_home.AsodProjectRow();
            if (object.id != null)
                message.id = String(object.id);
            if (object.court != null)
                message.court = String(object.court);
            if (object.courtRoom != null)
                message.courtRoom = String(object.courtRoom);
            if (object.opNumber != null)
                message.opNumber = String(object.opNumber);
            if (object.participants) {
                if (!Array.isArray(object.participants))
                    throw TypeError(".ws_home.AsodProjectRow.participants: array expected");
                message.participants = [];
                for (let i = 0; i < object.participants.length; ++i) {
                    if (typeof object.participants[i] !== "object")
                        throw TypeError(".ws_home.AsodProjectRow.participants: object expected");
                    message.participants[i] = $root.ws_home.AsodProjectRowParticipant.fromObject(object.participants[i]);
                }
            }
            if (object.date != null) {
                if (typeof object.date !== "object")
                    throw TypeError(".ws_home.AsodProjectRow.date: object expected");
                message.date = $root.common.Date.fromObject(object.date);
            }
            return message;
        };

        /**
         * Creates a plain object from an AsodProjectRow message. Also converts values to other types if specified.
         * @function toObject
         * @memberof ws_home.AsodProjectRow
         * @static
         * @param {ws_home.AsodProjectRow} message AsodProjectRow
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AsodProjectRow.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.participants = [];
            if (options.defaults) {
                object.id = "";
                object.court = "";
                object.courtRoom = "";
                object.opNumber = "";
                object.date = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.court != null && message.hasOwnProperty("court"))
                object.court = message.court;
            if (message.courtRoom != null && message.hasOwnProperty("courtRoom"))
                object.courtRoom = message.courtRoom;
            if (message.opNumber != null && message.hasOwnProperty("opNumber"))
                object.opNumber = message.opNumber;
            if (message.participants && message.participants.length) {
                object.participants = [];
                for (let j = 0; j < message.participants.length; ++j)
                    object.participants[j] = $root.ws_home.AsodProjectRowParticipant.toObject(message.participants[j], options);
            }
            if (message.date != null && message.hasOwnProperty("date"))
                object.date = $root.common.Date.toObject(message.date, options);
            return object;
        };

        /**
         * Converts this AsodProjectRow to JSON.
         * @function toJSON
         * @memberof ws_home.AsodProjectRow
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AsodProjectRow.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AsodProjectRow
         * @function getTypeUrl
         * @memberof ws_home.AsodProjectRow
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AsodProjectRow.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/ws_home.AsodProjectRow";
        };

        return AsodProjectRow;
    })();

    ws_home.AsodProjectRowParticipant = (function() {

        /**
         * Properties of an AsodProjectRowParticipant.
         * @memberof ws_home
         * @interface IAsodProjectRowParticipant
         * @property {string|null} [name] AsodProjectRowParticipant name
         * @property {string|null} [role] AsodProjectRowParticipant role
         */

        /**
         * Constructs a new AsodProjectRowParticipant.
         * @memberof ws_home
         * @classdesc Represents an AsodProjectRowParticipant.
         * @implements IAsodProjectRowParticipant
         * @constructor
         * @param {ws_home.IAsodProjectRowParticipant=} [properties] Properties to set
         */
        function AsodProjectRowParticipant(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AsodProjectRowParticipant name.
         * @member {string} name
         * @memberof ws_home.AsodProjectRowParticipant
         * @instance
         */
        AsodProjectRowParticipant.prototype.name = "";

        /**
         * AsodProjectRowParticipant role.
         * @member {string} role
         * @memberof ws_home.AsodProjectRowParticipant
         * @instance
         */
        AsodProjectRowParticipant.prototype.role = "";

        /**
         * Creates a new AsodProjectRowParticipant instance using the specified properties.
         * @function create
         * @memberof ws_home.AsodProjectRowParticipant
         * @static
         * @param {ws_home.IAsodProjectRowParticipant=} [properties] Properties to set
         * @returns {ws_home.AsodProjectRowParticipant} AsodProjectRowParticipant instance
         */
        AsodProjectRowParticipant.create = function create(properties) {
            return new AsodProjectRowParticipant(properties);
        };

        /**
         * Encodes the specified AsodProjectRowParticipant message. Does not implicitly {@link ws_home.AsodProjectRowParticipant.verify|verify} messages.
         * @function encode
         * @memberof ws_home.AsodProjectRowParticipant
         * @static
         * @param {ws_home.IAsodProjectRowParticipant} message AsodProjectRowParticipant message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodProjectRowParticipant.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.role != null && Object.hasOwnProperty.call(message, "role"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.role);
            return writer;
        };

        /**
         * Encodes the specified AsodProjectRowParticipant message, length delimited. Does not implicitly {@link ws_home.AsodProjectRowParticipant.verify|verify} messages.
         * @function encodeDelimited
         * @memberof ws_home.AsodProjectRowParticipant
         * @static
         * @param {ws_home.IAsodProjectRowParticipant} message AsodProjectRowParticipant message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodProjectRowParticipant.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AsodProjectRowParticipant message from the specified reader or buffer.
         * @function decode
         * @memberof ws_home.AsodProjectRowParticipant
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {ws_home.AsodProjectRowParticipant} AsodProjectRowParticipant
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodProjectRowParticipant.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ws_home.AsodProjectRowParticipant();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.name = reader.string();
                        break;
                    }
                case 2: {
                        message.role = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AsodProjectRowParticipant message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof ws_home.AsodProjectRowParticipant
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {ws_home.AsodProjectRowParticipant} AsodProjectRowParticipant
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodProjectRowParticipant.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AsodProjectRowParticipant message.
         * @function verify
         * @memberof ws_home.AsodProjectRowParticipant
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AsodProjectRowParticipant.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.role != null && message.hasOwnProperty("role"))
                if (!$util.isString(message.role))
                    return "role: string expected";
            return null;
        };

        /**
         * Creates an AsodProjectRowParticipant message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof ws_home.AsodProjectRowParticipant
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {ws_home.AsodProjectRowParticipant} AsodProjectRowParticipant
         */
        AsodProjectRowParticipant.fromObject = function fromObject(object) {
            if (object instanceof $root.ws_home.AsodProjectRowParticipant)
                return object;
            let message = new $root.ws_home.AsodProjectRowParticipant();
            if (object.name != null)
                message.name = String(object.name);
            if (object.role != null)
                message.role = String(object.role);
            return message;
        };

        /**
         * Creates a plain object from an AsodProjectRowParticipant message. Also converts values to other types if specified.
         * @function toObject
         * @memberof ws_home.AsodProjectRowParticipant
         * @static
         * @param {ws_home.AsodProjectRowParticipant} message AsodProjectRowParticipant
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AsodProjectRowParticipant.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.name = "";
                object.role = "";
            }
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.role != null && message.hasOwnProperty("role"))
                object.role = message.role;
            return object;
        };

        /**
         * Converts this AsodProjectRowParticipant to JSON.
         * @function toJSON
         * @memberof ws_home.AsodProjectRowParticipant
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AsodProjectRowParticipant.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AsodProjectRowParticipant
         * @function getTypeUrl
         * @memberof ws_home.AsodProjectRowParticipant
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AsodProjectRowParticipant.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/ws_home.AsodProjectRowParticipant";
        };

        return AsodProjectRowParticipant;
    })();

    /**
     * AsodProjectsOrderingTerm enum.
     * @name ws_home.AsodProjectsOrderingTerm
     * @enum {number}
     * @property {number} apot_court=0 apot_court value
     * @property {number} apot_court_room=1 apot_court_room value
     * @property {number} apot_op_number=2 apot_op_number value
     * @property {number} apot_date=3 apot_date value
     */
    ws_home.AsodProjectsOrderingTerm = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "apot_court"] = 0;
        values[valuesById[1] = "apot_court_room"] = 1;
        values[valuesById[2] = "apot_op_number"] = 2;
        values[valuesById[3] = "apot_date"] = 3;
        return values;
    })();

    return ws_home;
})();

export const ws_management = $root.ws_management = (() => {

    /**
     * Namespace ws_management.
     * @exports ws_management
     * @namespace
     */
    const ws_management = {};

    ws_management.LoadAsodManagement = (function() {

        /**
         * Properties of a LoadAsodManagement.
         * @memberof ws_management
         * @interface ILoadAsodManagement
         * @property {string|null} [quickSearch] LoadAsodManagement quickSearch
         * @property {string|null} [court] LoadAsodManagement court
         * @property {string|null} [courtRoom] LoadAsodManagement courtRoom
         * @property {string|null} [department] LoadAsodManagement department
         * @property {common.IPagination|null} [pagination] LoadAsodManagement pagination
         * @property {ws_management.AsodManagementOrderingTerm|null} [sortBy] LoadAsodManagement sortBy
         * @property {common.SortOrder|null} [sortOrder] LoadAsodManagement sortOrder
         * @property {ws_management.IAsodManagement|null} [_response] LoadAsodManagement _response
         */

        /**
         * Constructs a new LoadAsodManagement.
         * @memberof ws_management
         * @classdesc Represents a LoadAsodManagement.
         * @implements ILoadAsodManagement
         * @constructor
         * @param {ws_management.ILoadAsodManagement=} [properties] Properties to set
         */
        function LoadAsodManagement(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LoadAsodManagement quickSearch.
         * @member {string} quickSearch
         * @memberof ws_management.LoadAsodManagement
         * @instance
         */
        LoadAsodManagement.prototype.quickSearch = "";

        /**
         * LoadAsodManagement court.
         * @member {string} court
         * @memberof ws_management.LoadAsodManagement
         * @instance
         */
        LoadAsodManagement.prototype.court = "";

        /**
         * LoadAsodManagement courtRoom.
         * @member {string} courtRoom
         * @memberof ws_management.LoadAsodManagement
         * @instance
         */
        LoadAsodManagement.prototype.courtRoom = "";

        /**
         * LoadAsodManagement department.
         * @member {string} department
         * @memberof ws_management.LoadAsodManagement
         * @instance
         */
        LoadAsodManagement.prototype.department = "";

        /**
         * LoadAsodManagement pagination.
         * @member {common.IPagination|null|undefined} pagination
         * @memberof ws_management.LoadAsodManagement
         * @instance
         */
        LoadAsodManagement.prototype.pagination = null;

        /**
         * LoadAsodManagement sortBy.
         * @member {ws_management.AsodManagementOrderingTerm} sortBy
         * @memberof ws_management.LoadAsodManagement
         * @instance
         */
        LoadAsodManagement.prototype.sortBy = 0;

        /**
         * LoadAsodManagement sortOrder.
         * @member {common.SortOrder} sortOrder
         * @memberof ws_management.LoadAsodManagement
         * @instance
         */
        LoadAsodManagement.prototype.sortOrder = 0;

        /**
         * LoadAsodManagement _response.
         * @member {ws_management.IAsodManagement|null|undefined} _response
         * @memberof ws_management.LoadAsodManagement
         * @instance
         */
        LoadAsodManagement.prototype._response = null;

        /**
         * Creates a new LoadAsodManagement instance using the specified properties.
         * @function create
         * @memberof ws_management.LoadAsodManagement
         * @static
         * @param {ws_management.ILoadAsodManagement=} [properties] Properties to set
         * @returns {ws_management.LoadAsodManagement} LoadAsodManagement instance
         */
        LoadAsodManagement.create = function create(properties) {
            return new LoadAsodManagement(properties);
        };

        /**
         * Encodes the specified LoadAsodManagement message. Does not implicitly {@link ws_management.LoadAsodManagement.verify|verify} messages.
         * @function encode
         * @memberof ws_management.LoadAsodManagement
         * @static
         * @param {ws_management.ILoadAsodManagement} message LoadAsodManagement message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoadAsodManagement.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.quickSearch != null && Object.hasOwnProperty.call(message, "quickSearch"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.quickSearch);
            if (message.court != null && Object.hasOwnProperty.call(message, "court"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.court);
            if (message.courtRoom != null && Object.hasOwnProperty.call(message, "courtRoom"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.courtRoom);
            if (message.department != null && Object.hasOwnProperty.call(message, "department"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.department);
            if (message.pagination != null && Object.hasOwnProperty.call(message, "pagination"))
                $root.common.Pagination.encode(message.pagination, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.sortBy != null && Object.hasOwnProperty.call(message, "sortBy"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.sortBy);
            if (message.sortOrder != null && Object.hasOwnProperty.call(message, "sortOrder"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.sortOrder);
            if (message._response != null && Object.hasOwnProperty.call(message, "_response"))
                $root.ws_management.AsodManagement.encode(message._response, writer.uint32(/* id 99, wireType 2 =*/794).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified LoadAsodManagement message, length delimited. Does not implicitly {@link ws_management.LoadAsodManagement.verify|verify} messages.
         * @function encodeDelimited
         * @memberof ws_management.LoadAsodManagement
         * @static
         * @param {ws_management.ILoadAsodManagement} message LoadAsodManagement message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoadAsodManagement.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LoadAsodManagement message from the specified reader or buffer.
         * @function decode
         * @memberof ws_management.LoadAsodManagement
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {ws_management.LoadAsodManagement} LoadAsodManagement
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoadAsodManagement.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ws_management.LoadAsodManagement();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.quickSearch = reader.string();
                        break;
                    }
                case 2: {
                        message.court = reader.string();
                        break;
                    }
                case 3: {
                        message.courtRoom = reader.string();
                        break;
                    }
                case 4: {
                        message.department = reader.string();
                        break;
                    }
                case 5: {
                        message.pagination = $root.common.Pagination.decode(reader, reader.uint32());
                        break;
                    }
                case 6: {
                        message.sortBy = reader.int32();
                        break;
                    }
                case 7: {
                        message.sortOrder = reader.int32();
                        break;
                    }
                case 99: {
                        message._response = $root.ws_management.AsodManagement.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LoadAsodManagement message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof ws_management.LoadAsodManagement
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {ws_management.LoadAsodManagement} LoadAsodManagement
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoadAsodManagement.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LoadAsodManagement message.
         * @function verify
         * @memberof ws_management.LoadAsodManagement
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LoadAsodManagement.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.quickSearch != null && message.hasOwnProperty("quickSearch"))
                if (!$util.isString(message.quickSearch))
                    return "quickSearch: string expected";
            if (message.court != null && message.hasOwnProperty("court"))
                if (!$util.isString(message.court))
                    return "court: string expected";
            if (message.courtRoom != null && message.hasOwnProperty("courtRoom"))
                if (!$util.isString(message.courtRoom))
                    return "courtRoom: string expected";
            if (message.department != null && message.hasOwnProperty("department"))
                if (!$util.isString(message.department))
                    return "department: string expected";
            if (message.pagination != null && message.hasOwnProperty("pagination")) {
                let error = $root.common.Pagination.verify(message.pagination);
                if (error)
                    return "pagination." + error;
            }
            if (message.sortBy != null && message.hasOwnProperty("sortBy"))
                switch (message.sortBy) {
                default:
                    return "sortBy: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                    break;
                }
            if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                switch (message.sortOrder) {
                default:
                    return "sortOrder: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message._response != null && message.hasOwnProperty("_response")) {
                let error = $root.ws_management.AsodManagement.verify(message._response);
                if (error)
                    return "_response." + error;
            }
            return null;
        };

        /**
         * Creates a LoadAsodManagement message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof ws_management.LoadAsodManagement
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {ws_management.LoadAsodManagement} LoadAsodManagement
         */
        LoadAsodManagement.fromObject = function fromObject(object) {
            if (object instanceof $root.ws_management.LoadAsodManagement)
                return object;
            let message = new $root.ws_management.LoadAsodManagement();
            if (object.quickSearch != null)
                message.quickSearch = String(object.quickSearch);
            if (object.court != null)
                message.court = String(object.court);
            if (object.courtRoom != null)
                message.courtRoom = String(object.courtRoom);
            if (object.department != null)
                message.department = String(object.department);
            if (object.pagination != null) {
                if (typeof object.pagination !== "object")
                    throw TypeError(".ws_management.LoadAsodManagement.pagination: object expected");
                message.pagination = $root.common.Pagination.fromObject(object.pagination);
            }
            switch (object.sortBy) {
            default:
                if (typeof object.sortBy === "number") {
                    message.sortBy = object.sortBy;
                    break;
                }
                break;
            case "amot_court":
            case 0:
                message.sortBy = 0;
                break;
            case "amot_department":
            case 1:
                message.sortBy = 1;
                break;
            case "amot_courtroom":
            case 2:
                message.sortBy = 2;
                break;
            case "amot_name":
            case 3:
                message.sortBy = 3;
                break;
            case "amot_version":
            case 4:
                message.sortBy = 4;
                break;
            case "amot_status":
            case 5:
                message.sortBy = 5;
                break;
            case "amot_storage":
            case 6:
                message.sortBy = 6;
                break;
            }
            switch (object.sortOrder) {
            default:
                if (typeof object.sortOrder === "number") {
                    message.sortOrder = object.sortOrder;
                    break;
                }
                break;
            case "so_none":
            case 0:
                message.sortOrder = 0;
                break;
            case "so_asc":
            case 1:
                message.sortOrder = 1;
                break;
            case "so_desc":
            case 2:
                message.sortOrder = 2;
                break;
            }
            if (object._response != null) {
                if (typeof object._response !== "object")
                    throw TypeError(".ws_management.LoadAsodManagement._response: object expected");
                message._response = $root.ws_management.AsodManagement.fromObject(object._response);
            }
            return message;
        };

        /**
         * Creates a plain object from a LoadAsodManagement message. Also converts values to other types if specified.
         * @function toObject
         * @memberof ws_management.LoadAsodManagement
         * @static
         * @param {ws_management.LoadAsodManagement} message LoadAsodManagement
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LoadAsodManagement.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.quickSearch = "";
                object.court = "";
                object.courtRoom = "";
                object.department = "";
                object.pagination = null;
                object.sortBy = options.enums === String ? "amot_court" : 0;
                object.sortOrder = options.enums === String ? "so_none" : 0;
                object._response = null;
            }
            if (message.quickSearch != null && message.hasOwnProperty("quickSearch"))
                object.quickSearch = message.quickSearch;
            if (message.court != null && message.hasOwnProperty("court"))
                object.court = message.court;
            if (message.courtRoom != null && message.hasOwnProperty("courtRoom"))
                object.courtRoom = message.courtRoom;
            if (message.department != null && message.hasOwnProperty("department"))
                object.department = message.department;
            if (message.pagination != null && message.hasOwnProperty("pagination"))
                object.pagination = $root.common.Pagination.toObject(message.pagination, options);
            if (message.sortBy != null && message.hasOwnProperty("sortBy"))
                object.sortBy = options.enums === String ? $root.ws_management.AsodManagementOrderingTerm[message.sortBy] === undefined ? message.sortBy : $root.ws_management.AsodManagementOrderingTerm[message.sortBy] : message.sortBy;
            if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                object.sortOrder = options.enums === String ? $root.common.SortOrder[message.sortOrder] === undefined ? message.sortOrder : $root.common.SortOrder[message.sortOrder] : message.sortOrder;
            if (message._response != null && message.hasOwnProperty("_response"))
                object._response = $root.ws_management.AsodManagement.toObject(message._response, options);
            return object;
        };

        /**
         * Converts this LoadAsodManagement to JSON.
         * @function toJSON
         * @memberof ws_management.LoadAsodManagement
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LoadAsodManagement.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LoadAsodManagement
         * @function getTypeUrl
         * @memberof ws_management.LoadAsodManagement
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LoadAsodManagement.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/ws_management.LoadAsodManagement";
        };

        return LoadAsodManagement;
    })();

    ws_management.AsodManagement = (function() {

        /**
         * Properties of an AsodManagement.
         * @memberof ws_management
         * @interface IAsodManagement
         * @property {Array.<ws_management.IAsodManagementRow>|null} [rows] AsodManagement rows
         * @property {common.IPagination|null} [pagination] AsodManagement pagination
         * @property {ws_management.AsodManagementOrderingTerm|null} [sortBy] AsodManagement sortBy
         * @property {common.SortOrder|null} [sortOrder] AsodManagement sortOrder
         */

        /**
         * Constructs a new AsodManagement.
         * @memberof ws_management
         * @classdesc Represents an AsodManagement.
         * @implements IAsodManagement
         * @constructor
         * @param {ws_management.IAsodManagement=} [properties] Properties to set
         */
        function AsodManagement(properties) {
            this.rows = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AsodManagement rows.
         * @member {Array.<ws_management.IAsodManagementRow>} rows
         * @memberof ws_management.AsodManagement
         * @instance
         */
        AsodManagement.prototype.rows = $util.emptyArray;

        /**
         * AsodManagement pagination.
         * @member {common.IPagination|null|undefined} pagination
         * @memberof ws_management.AsodManagement
         * @instance
         */
        AsodManagement.prototype.pagination = null;

        /**
         * AsodManagement sortBy.
         * @member {ws_management.AsodManagementOrderingTerm} sortBy
         * @memberof ws_management.AsodManagement
         * @instance
         */
        AsodManagement.prototype.sortBy = 0;

        /**
         * AsodManagement sortOrder.
         * @member {common.SortOrder} sortOrder
         * @memberof ws_management.AsodManagement
         * @instance
         */
        AsodManagement.prototype.sortOrder = 0;

        /**
         * Creates a new AsodManagement instance using the specified properties.
         * @function create
         * @memberof ws_management.AsodManagement
         * @static
         * @param {ws_management.IAsodManagement=} [properties] Properties to set
         * @returns {ws_management.AsodManagement} AsodManagement instance
         */
        AsodManagement.create = function create(properties) {
            return new AsodManagement(properties);
        };

        /**
         * Encodes the specified AsodManagement message. Does not implicitly {@link ws_management.AsodManagement.verify|verify} messages.
         * @function encode
         * @memberof ws_management.AsodManagement
         * @static
         * @param {ws_management.IAsodManagement} message AsodManagement message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodManagement.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rows != null && message.rows.length)
                for (let i = 0; i < message.rows.length; ++i)
                    $root.ws_management.AsodManagementRow.encode(message.rows[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.pagination != null && Object.hasOwnProperty.call(message, "pagination"))
                $root.common.Pagination.encode(message.pagination, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.sortBy != null && Object.hasOwnProperty.call(message, "sortBy"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.sortBy);
            if (message.sortOrder != null && Object.hasOwnProperty.call(message, "sortOrder"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.sortOrder);
            return writer;
        };

        /**
         * Encodes the specified AsodManagement message, length delimited. Does not implicitly {@link ws_management.AsodManagement.verify|verify} messages.
         * @function encodeDelimited
         * @memberof ws_management.AsodManagement
         * @static
         * @param {ws_management.IAsodManagement} message AsodManagement message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodManagement.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AsodManagement message from the specified reader or buffer.
         * @function decode
         * @memberof ws_management.AsodManagement
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {ws_management.AsodManagement} AsodManagement
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodManagement.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ws_management.AsodManagement();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.rows && message.rows.length))
                            message.rows = [];
                        message.rows.push($root.ws_management.AsodManagementRow.decode(reader, reader.uint32()));
                        break;
                    }
                case 6: {
                        message.pagination = $root.common.Pagination.decode(reader, reader.uint32());
                        break;
                    }
                case 7: {
                        message.sortBy = reader.int32();
                        break;
                    }
                case 8: {
                        message.sortOrder = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AsodManagement message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof ws_management.AsodManagement
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {ws_management.AsodManagement} AsodManagement
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodManagement.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AsodManagement message.
         * @function verify
         * @memberof ws_management.AsodManagement
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AsodManagement.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.rows != null && message.hasOwnProperty("rows")) {
                if (!Array.isArray(message.rows))
                    return "rows: array expected";
                for (let i = 0; i < message.rows.length; ++i) {
                    let error = $root.ws_management.AsodManagementRow.verify(message.rows[i]);
                    if (error)
                        return "rows." + error;
                }
            }
            if (message.pagination != null && message.hasOwnProperty("pagination")) {
                let error = $root.common.Pagination.verify(message.pagination);
                if (error)
                    return "pagination." + error;
            }
            if (message.sortBy != null && message.hasOwnProperty("sortBy"))
                switch (message.sortBy) {
                default:
                    return "sortBy: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                    break;
                }
            if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                switch (message.sortOrder) {
                default:
                    return "sortOrder: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            return null;
        };

        /**
         * Creates an AsodManagement message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof ws_management.AsodManagement
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {ws_management.AsodManagement} AsodManagement
         */
        AsodManagement.fromObject = function fromObject(object) {
            if (object instanceof $root.ws_management.AsodManagement)
                return object;
            let message = new $root.ws_management.AsodManagement();
            if (object.rows) {
                if (!Array.isArray(object.rows))
                    throw TypeError(".ws_management.AsodManagement.rows: array expected");
                message.rows = [];
                for (let i = 0; i < object.rows.length; ++i) {
                    if (typeof object.rows[i] !== "object")
                        throw TypeError(".ws_management.AsodManagement.rows: object expected");
                    message.rows[i] = $root.ws_management.AsodManagementRow.fromObject(object.rows[i]);
                }
            }
            if (object.pagination != null) {
                if (typeof object.pagination !== "object")
                    throw TypeError(".ws_management.AsodManagement.pagination: object expected");
                message.pagination = $root.common.Pagination.fromObject(object.pagination);
            }
            switch (object.sortBy) {
            default:
                if (typeof object.sortBy === "number") {
                    message.sortBy = object.sortBy;
                    break;
                }
                break;
            case "amot_court":
            case 0:
                message.sortBy = 0;
                break;
            case "amot_department":
            case 1:
                message.sortBy = 1;
                break;
            case "amot_courtroom":
            case 2:
                message.sortBy = 2;
                break;
            case "amot_name":
            case 3:
                message.sortBy = 3;
                break;
            case "amot_version":
            case 4:
                message.sortBy = 4;
                break;
            case "amot_status":
            case 5:
                message.sortBy = 5;
                break;
            case "amot_storage":
            case 6:
                message.sortBy = 6;
                break;
            }
            switch (object.sortOrder) {
            default:
                if (typeof object.sortOrder === "number") {
                    message.sortOrder = object.sortOrder;
                    break;
                }
                break;
            case "so_none":
            case 0:
                message.sortOrder = 0;
                break;
            case "so_asc":
            case 1:
                message.sortOrder = 1;
                break;
            case "so_desc":
            case 2:
                message.sortOrder = 2;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from an AsodManagement message. Also converts values to other types if specified.
         * @function toObject
         * @memberof ws_management.AsodManagement
         * @static
         * @param {ws_management.AsodManagement} message AsodManagement
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AsodManagement.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.rows = [];
            if (options.defaults) {
                object.pagination = null;
                object.sortBy = options.enums === String ? "amot_court" : 0;
                object.sortOrder = options.enums === String ? "so_none" : 0;
            }
            if (message.rows && message.rows.length) {
                object.rows = [];
                for (let j = 0; j < message.rows.length; ++j)
                    object.rows[j] = $root.ws_management.AsodManagementRow.toObject(message.rows[j], options);
            }
            if (message.pagination != null && message.hasOwnProperty("pagination"))
                object.pagination = $root.common.Pagination.toObject(message.pagination, options);
            if (message.sortBy != null && message.hasOwnProperty("sortBy"))
                object.sortBy = options.enums === String ? $root.ws_management.AsodManagementOrderingTerm[message.sortBy] === undefined ? message.sortBy : $root.ws_management.AsodManagementOrderingTerm[message.sortBy] : message.sortBy;
            if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                object.sortOrder = options.enums === String ? $root.common.SortOrder[message.sortOrder] === undefined ? message.sortOrder : $root.common.SortOrder[message.sortOrder] : message.sortOrder;
            return object;
        };

        /**
         * Converts this AsodManagement to JSON.
         * @function toJSON
         * @memberof ws_management.AsodManagement
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AsodManagement.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AsodManagement
         * @function getTypeUrl
         * @memberof ws_management.AsodManagement
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AsodManagement.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/ws_management.AsodManagement";
        };

        return AsodManagement;
    })();

    ws_management.AsodManagementRow = (function() {

        /**
         * Properties of an AsodManagementRow.
         * @memberof ws_management
         * @interface IAsodManagementRow
         * @property {string|null} [id] AsodManagementRow id
         * @property {string|null} [court] AsodManagementRow court
         * @property {string|null} [department] AsodManagementRow department
         * @property {string|null} [courtRoom] AsodManagementRow courtRoom
         * @property {string|null} [name] AsodManagementRow name
         * @property {string|null} [version] AsodManagementRow version
         * @property {ws_management.AsodManagementVersionStatus|null} [versionStatus] AsodManagementRow versionStatus
         * @property {ws_management.AsodManagementStatus|null} [status] AsodManagementRow status
         * @property {common.IDate|null} [statusTime] AsodManagementRow statusTime
         * @property {number|null} [storageTotalGb] AsodManagementRow storageTotalGb
         * @property {number|null} [storageUsedGb] AsodManagementRow storageUsedGb
         */

        /**
         * Constructs a new AsodManagementRow.
         * @memberof ws_management
         * @classdesc Represents an AsodManagementRow.
         * @implements IAsodManagementRow
         * @constructor
         * @param {ws_management.IAsodManagementRow=} [properties] Properties to set
         */
        function AsodManagementRow(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AsodManagementRow id.
         * @member {string} id
         * @memberof ws_management.AsodManagementRow
         * @instance
         */
        AsodManagementRow.prototype.id = "";

        /**
         * AsodManagementRow court.
         * @member {string} court
         * @memberof ws_management.AsodManagementRow
         * @instance
         */
        AsodManagementRow.prototype.court = "";

        /**
         * AsodManagementRow department.
         * @member {string} department
         * @memberof ws_management.AsodManagementRow
         * @instance
         */
        AsodManagementRow.prototype.department = "";

        /**
         * AsodManagementRow courtRoom.
         * @member {string} courtRoom
         * @memberof ws_management.AsodManagementRow
         * @instance
         */
        AsodManagementRow.prototype.courtRoom = "";

        /**
         * AsodManagementRow name.
         * @member {string} name
         * @memberof ws_management.AsodManagementRow
         * @instance
         */
        AsodManagementRow.prototype.name = "";

        /**
         * AsodManagementRow version.
         * @member {string} version
         * @memberof ws_management.AsodManagementRow
         * @instance
         */
        AsodManagementRow.prototype.version = "";

        /**
         * AsodManagementRow versionStatus.
         * @member {ws_management.AsodManagementVersionStatus} versionStatus
         * @memberof ws_management.AsodManagementRow
         * @instance
         */
        AsodManagementRow.prototype.versionStatus = 0;

        /**
         * AsodManagementRow status.
         * @member {ws_management.AsodManagementStatus} status
         * @memberof ws_management.AsodManagementRow
         * @instance
         */
        AsodManagementRow.prototype.status = 0;

        /**
         * AsodManagementRow statusTime.
         * @member {common.IDate|null|undefined} statusTime
         * @memberof ws_management.AsodManagementRow
         * @instance
         */
        AsodManagementRow.prototype.statusTime = null;

        /**
         * AsodManagementRow storageTotalGb.
         * @member {number} storageTotalGb
         * @memberof ws_management.AsodManagementRow
         * @instance
         */
        AsodManagementRow.prototype.storageTotalGb = 0;

        /**
         * AsodManagementRow storageUsedGb.
         * @member {number} storageUsedGb
         * @memberof ws_management.AsodManagementRow
         * @instance
         */
        AsodManagementRow.prototype.storageUsedGb = 0;

        /**
         * Creates a new AsodManagementRow instance using the specified properties.
         * @function create
         * @memberof ws_management.AsodManagementRow
         * @static
         * @param {ws_management.IAsodManagementRow=} [properties] Properties to set
         * @returns {ws_management.AsodManagementRow} AsodManagementRow instance
         */
        AsodManagementRow.create = function create(properties) {
            return new AsodManagementRow(properties);
        };

        /**
         * Encodes the specified AsodManagementRow message. Does not implicitly {@link ws_management.AsodManagementRow.verify|verify} messages.
         * @function encode
         * @memberof ws_management.AsodManagementRow
         * @static
         * @param {ws_management.IAsodManagementRow} message AsodManagementRow message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodManagementRow.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.court != null && Object.hasOwnProperty.call(message, "court"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.court);
            if (message.department != null && Object.hasOwnProperty.call(message, "department"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.department);
            if (message.courtRoom != null && Object.hasOwnProperty.call(message, "courtRoom"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.courtRoom);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.name);
            if (message.version != null && Object.hasOwnProperty.call(message, "version"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.version);
            if (message.versionStatus != null && Object.hasOwnProperty.call(message, "versionStatus"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.versionStatus);
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 9, wireType 0 =*/72).int32(message.status);
            if (message.statusTime != null && Object.hasOwnProperty.call(message, "statusTime"))
                $root.common.Date.encode(message.statusTime, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.storageTotalGb != null && Object.hasOwnProperty.call(message, "storageTotalGb"))
                writer.uint32(/* id 11, wireType 5 =*/93).float(message.storageTotalGb);
            if (message.storageUsedGb != null && Object.hasOwnProperty.call(message, "storageUsedGb"))
                writer.uint32(/* id 12, wireType 5 =*/101).float(message.storageUsedGb);
            return writer;
        };

        /**
         * Encodes the specified AsodManagementRow message, length delimited. Does not implicitly {@link ws_management.AsodManagementRow.verify|verify} messages.
         * @function encodeDelimited
         * @memberof ws_management.AsodManagementRow
         * @static
         * @param {ws_management.IAsodManagementRow} message AsodManagementRow message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodManagementRow.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AsodManagementRow message from the specified reader or buffer.
         * @function decode
         * @memberof ws_management.AsodManagementRow
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {ws_management.AsodManagementRow} AsodManagementRow
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodManagementRow.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ws_management.AsodManagementRow();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 2: {
                        message.court = reader.string();
                        break;
                    }
                case 3: {
                        message.department = reader.string();
                        break;
                    }
                case 4: {
                        message.courtRoom = reader.string();
                        break;
                    }
                case 5: {
                        message.name = reader.string();
                        break;
                    }
                case 6: {
                        message.version = reader.string();
                        break;
                    }
                case 7: {
                        message.versionStatus = reader.int32();
                        break;
                    }
                case 9: {
                        message.status = reader.int32();
                        break;
                    }
                case 10: {
                        message.statusTime = $root.common.Date.decode(reader, reader.uint32());
                        break;
                    }
                case 11: {
                        message.storageTotalGb = reader.float();
                        break;
                    }
                case 12: {
                        message.storageUsedGb = reader.float();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AsodManagementRow message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof ws_management.AsodManagementRow
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {ws_management.AsodManagementRow} AsodManagementRow
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodManagementRow.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AsodManagementRow message.
         * @function verify
         * @memberof ws_management.AsodManagementRow
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AsodManagementRow.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.court != null && message.hasOwnProperty("court"))
                if (!$util.isString(message.court))
                    return "court: string expected";
            if (message.department != null && message.hasOwnProperty("department"))
                if (!$util.isString(message.department))
                    return "department: string expected";
            if (message.courtRoom != null && message.hasOwnProperty("courtRoom"))
                if (!$util.isString(message.courtRoom))
                    return "courtRoom: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.version != null && message.hasOwnProperty("version"))
                if (!$util.isString(message.version))
                    return "version: string expected";
            if (message.versionStatus != null && message.hasOwnProperty("versionStatus"))
                switch (message.versionStatus) {
                default:
                    return "versionStatus: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message.statusTime != null && message.hasOwnProperty("statusTime")) {
                let error = $root.common.Date.verify(message.statusTime);
                if (error)
                    return "statusTime." + error;
            }
            if (message.storageTotalGb != null && message.hasOwnProperty("storageTotalGb"))
                if (typeof message.storageTotalGb !== "number")
                    return "storageTotalGb: number expected";
            if (message.storageUsedGb != null && message.hasOwnProperty("storageUsedGb"))
                if (typeof message.storageUsedGb !== "number")
                    return "storageUsedGb: number expected";
            return null;
        };

        /**
         * Creates an AsodManagementRow message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof ws_management.AsodManagementRow
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {ws_management.AsodManagementRow} AsodManagementRow
         */
        AsodManagementRow.fromObject = function fromObject(object) {
            if (object instanceof $root.ws_management.AsodManagementRow)
                return object;
            let message = new $root.ws_management.AsodManagementRow();
            if (object.id != null)
                message.id = String(object.id);
            if (object.court != null)
                message.court = String(object.court);
            if (object.department != null)
                message.department = String(object.department);
            if (object.courtRoom != null)
                message.courtRoom = String(object.courtRoom);
            if (object.name != null)
                message.name = String(object.name);
            if (object.version != null)
                message.version = String(object.version);
            switch (object.versionStatus) {
            default:
                if (typeof object.versionStatus === "number") {
                    message.versionStatus = object.versionStatus;
                    break;
                }
                break;
            case "amvs_latest":
            case 0:
                message.versionStatus = 0;
                break;
            case "amvs_outdated":
            case 1:
                message.versionStatus = 1;
                break;
            case "amvs_update_scheduled":
            case 2:
                message.versionStatus = 2;
                break;
            }
            switch (object.status) {
            default:
                if (typeof object.status === "number") {
                    message.status = object.status;
                    break;
                }
                break;
            case "ams_unknown":
            case 0:
                message.status = 0;
                break;
            case "ams_online":
            case 1:
                message.status = 1;
                break;
            case "ams_offline":
            case 2:
                message.status = 2;
                break;
            }
            if (object.statusTime != null) {
                if (typeof object.statusTime !== "object")
                    throw TypeError(".ws_management.AsodManagementRow.statusTime: object expected");
                message.statusTime = $root.common.Date.fromObject(object.statusTime);
            }
            if (object.storageTotalGb != null)
                message.storageTotalGb = Number(object.storageTotalGb);
            if (object.storageUsedGb != null)
                message.storageUsedGb = Number(object.storageUsedGb);
            return message;
        };

        /**
         * Creates a plain object from an AsodManagementRow message. Also converts values to other types if specified.
         * @function toObject
         * @memberof ws_management.AsodManagementRow
         * @static
         * @param {ws_management.AsodManagementRow} message AsodManagementRow
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AsodManagementRow.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.id = "";
                object.court = "";
                object.department = "";
                object.courtRoom = "";
                object.name = "";
                object.version = "";
                object.versionStatus = options.enums === String ? "amvs_latest" : 0;
                object.status = options.enums === String ? "ams_unknown" : 0;
                object.statusTime = null;
                object.storageTotalGb = 0;
                object.storageUsedGb = 0;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.court != null && message.hasOwnProperty("court"))
                object.court = message.court;
            if (message.department != null && message.hasOwnProperty("department"))
                object.department = message.department;
            if (message.courtRoom != null && message.hasOwnProperty("courtRoom"))
                object.courtRoom = message.courtRoom;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.version != null && message.hasOwnProperty("version"))
                object.version = message.version;
            if (message.versionStatus != null && message.hasOwnProperty("versionStatus"))
                object.versionStatus = options.enums === String ? $root.ws_management.AsodManagementVersionStatus[message.versionStatus] === undefined ? message.versionStatus : $root.ws_management.AsodManagementVersionStatus[message.versionStatus] : message.versionStatus;
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.ws_management.AsodManagementStatus[message.status] === undefined ? message.status : $root.ws_management.AsodManagementStatus[message.status] : message.status;
            if (message.statusTime != null && message.hasOwnProperty("statusTime"))
                object.statusTime = $root.common.Date.toObject(message.statusTime, options);
            if (message.storageTotalGb != null && message.hasOwnProperty("storageTotalGb"))
                object.storageTotalGb = options.json && !isFinite(message.storageTotalGb) ? String(message.storageTotalGb) : message.storageTotalGb;
            if (message.storageUsedGb != null && message.hasOwnProperty("storageUsedGb"))
                object.storageUsedGb = options.json && !isFinite(message.storageUsedGb) ? String(message.storageUsedGb) : message.storageUsedGb;
            return object;
        };

        /**
         * Converts this AsodManagementRow to JSON.
         * @function toJSON
         * @memberof ws_management.AsodManagementRow
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AsodManagementRow.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AsodManagementRow
         * @function getTypeUrl
         * @memberof ws_management.AsodManagementRow
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AsodManagementRow.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/ws_management.AsodManagementRow";
        };

        return AsodManagementRow;
    })();

    ws_management.AsodManagementUpgrade = (function() {

        /**
         * Properties of an AsodManagementUpgrade.
         * @memberof ws_management
         * @interface IAsodManagementUpgrade
         * @property {Array.<string>|null} [devices] AsodManagementUpgrade devices
         * @property {boolean|null} [upgradeAll] AsodManagementUpgrade upgradeAll
         * @property {common.IDate|null} [upgradeTimeFrom] AsodManagementUpgrade upgradeTimeFrom
         * @property {common.IDate|null} [upgradeTimeTo] AsodManagementUpgrade upgradeTimeTo
         */

        /**
         * Constructs a new AsodManagementUpgrade.
         * @memberof ws_management
         * @classdesc Represents an AsodManagementUpgrade.
         * @implements IAsodManagementUpgrade
         * @constructor
         * @param {ws_management.IAsodManagementUpgrade=} [properties] Properties to set
         */
        function AsodManagementUpgrade(properties) {
            this.devices = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AsodManagementUpgrade devices.
         * @member {Array.<string>} devices
         * @memberof ws_management.AsodManagementUpgrade
         * @instance
         */
        AsodManagementUpgrade.prototype.devices = $util.emptyArray;

        /**
         * AsodManagementUpgrade upgradeAll.
         * @member {boolean} upgradeAll
         * @memberof ws_management.AsodManagementUpgrade
         * @instance
         */
        AsodManagementUpgrade.prototype.upgradeAll = false;

        /**
         * AsodManagementUpgrade upgradeTimeFrom.
         * @member {common.IDate|null|undefined} upgradeTimeFrom
         * @memberof ws_management.AsodManagementUpgrade
         * @instance
         */
        AsodManagementUpgrade.prototype.upgradeTimeFrom = null;

        /**
         * AsodManagementUpgrade upgradeTimeTo.
         * @member {common.IDate|null|undefined} upgradeTimeTo
         * @memberof ws_management.AsodManagementUpgrade
         * @instance
         */
        AsodManagementUpgrade.prototype.upgradeTimeTo = null;

        /**
         * Creates a new AsodManagementUpgrade instance using the specified properties.
         * @function create
         * @memberof ws_management.AsodManagementUpgrade
         * @static
         * @param {ws_management.IAsodManagementUpgrade=} [properties] Properties to set
         * @returns {ws_management.AsodManagementUpgrade} AsodManagementUpgrade instance
         */
        AsodManagementUpgrade.create = function create(properties) {
            return new AsodManagementUpgrade(properties);
        };

        /**
         * Encodes the specified AsodManagementUpgrade message. Does not implicitly {@link ws_management.AsodManagementUpgrade.verify|verify} messages.
         * @function encode
         * @memberof ws_management.AsodManagementUpgrade
         * @static
         * @param {ws_management.IAsodManagementUpgrade} message AsodManagementUpgrade message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodManagementUpgrade.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.devices != null && message.devices.length)
                for (let i = 0; i < message.devices.length; ++i)
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.devices[i]);
            if (message.upgradeAll != null && Object.hasOwnProperty.call(message, "upgradeAll"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.upgradeAll);
            if (message.upgradeTimeFrom != null && Object.hasOwnProperty.call(message, "upgradeTimeFrom"))
                $root.common.Date.encode(message.upgradeTimeFrom, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.upgradeTimeTo != null && Object.hasOwnProperty.call(message, "upgradeTimeTo"))
                $root.common.Date.encode(message.upgradeTimeTo, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified AsodManagementUpgrade message, length delimited. Does not implicitly {@link ws_management.AsodManagementUpgrade.verify|verify} messages.
         * @function encodeDelimited
         * @memberof ws_management.AsodManagementUpgrade
         * @static
         * @param {ws_management.IAsodManagementUpgrade} message AsodManagementUpgrade message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodManagementUpgrade.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AsodManagementUpgrade message from the specified reader or buffer.
         * @function decode
         * @memberof ws_management.AsodManagementUpgrade
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {ws_management.AsodManagementUpgrade} AsodManagementUpgrade
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodManagementUpgrade.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ws_management.AsodManagementUpgrade();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 2: {
                        if (!(message.devices && message.devices.length))
                            message.devices = [];
                        message.devices.push(reader.string());
                        break;
                    }
                case 3: {
                        message.upgradeAll = reader.bool();
                        break;
                    }
                case 4: {
                        message.upgradeTimeFrom = $root.common.Date.decode(reader, reader.uint32());
                        break;
                    }
                case 5: {
                        message.upgradeTimeTo = $root.common.Date.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AsodManagementUpgrade message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof ws_management.AsodManagementUpgrade
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {ws_management.AsodManagementUpgrade} AsodManagementUpgrade
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodManagementUpgrade.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AsodManagementUpgrade message.
         * @function verify
         * @memberof ws_management.AsodManagementUpgrade
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AsodManagementUpgrade.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.devices != null && message.hasOwnProperty("devices")) {
                if (!Array.isArray(message.devices))
                    return "devices: array expected";
                for (let i = 0; i < message.devices.length; ++i)
                    if (!$util.isString(message.devices[i]))
                        return "devices: string[] expected";
            }
            if (message.upgradeAll != null && message.hasOwnProperty("upgradeAll"))
                if (typeof message.upgradeAll !== "boolean")
                    return "upgradeAll: boolean expected";
            if (message.upgradeTimeFrom != null && message.hasOwnProperty("upgradeTimeFrom")) {
                let error = $root.common.Date.verify(message.upgradeTimeFrom);
                if (error)
                    return "upgradeTimeFrom." + error;
            }
            if (message.upgradeTimeTo != null && message.hasOwnProperty("upgradeTimeTo")) {
                let error = $root.common.Date.verify(message.upgradeTimeTo);
                if (error)
                    return "upgradeTimeTo." + error;
            }
            return null;
        };

        /**
         * Creates an AsodManagementUpgrade message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof ws_management.AsodManagementUpgrade
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {ws_management.AsodManagementUpgrade} AsodManagementUpgrade
         */
        AsodManagementUpgrade.fromObject = function fromObject(object) {
            if (object instanceof $root.ws_management.AsodManagementUpgrade)
                return object;
            let message = new $root.ws_management.AsodManagementUpgrade();
            if (object.devices) {
                if (!Array.isArray(object.devices))
                    throw TypeError(".ws_management.AsodManagementUpgrade.devices: array expected");
                message.devices = [];
                for (let i = 0; i < object.devices.length; ++i)
                    message.devices[i] = String(object.devices[i]);
            }
            if (object.upgradeAll != null)
                message.upgradeAll = Boolean(object.upgradeAll);
            if (object.upgradeTimeFrom != null) {
                if (typeof object.upgradeTimeFrom !== "object")
                    throw TypeError(".ws_management.AsodManagementUpgrade.upgradeTimeFrom: object expected");
                message.upgradeTimeFrom = $root.common.Date.fromObject(object.upgradeTimeFrom);
            }
            if (object.upgradeTimeTo != null) {
                if (typeof object.upgradeTimeTo !== "object")
                    throw TypeError(".ws_management.AsodManagementUpgrade.upgradeTimeTo: object expected");
                message.upgradeTimeTo = $root.common.Date.fromObject(object.upgradeTimeTo);
            }
            return message;
        };

        /**
         * Creates a plain object from an AsodManagementUpgrade message. Also converts values to other types if specified.
         * @function toObject
         * @memberof ws_management.AsodManagementUpgrade
         * @static
         * @param {ws_management.AsodManagementUpgrade} message AsodManagementUpgrade
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AsodManagementUpgrade.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.devices = [];
            if (options.defaults) {
                object.upgradeAll = false;
                object.upgradeTimeFrom = null;
                object.upgradeTimeTo = null;
            }
            if (message.devices && message.devices.length) {
                object.devices = [];
                for (let j = 0; j < message.devices.length; ++j)
                    object.devices[j] = message.devices[j];
            }
            if (message.upgradeAll != null && message.hasOwnProperty("upgradeAll"))
                object.upgradeAll = message.upgradeAll;
            if (message.upgradeTimeFrom != null && message.hasOwnProperty("upgradeTimeFrom"))
                object.upgradeTimeFrom = $root.common.Date.toObject(message.upgradeTimeFrom, options);
            if (message.upgradeTimeTo != null && message.hasOwnProperty("upgradeTimeTo"))
                object.upgradeTimeTo = $root.common.Date.toObject(message.upgradeTimeTo, options);
            return object;
        };

        /**
         * Converts this AsodManagementUpgrade to JSON.
         * @function toJSON
         * @memberof ws_management.AsodManagementUpgrade
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AsodManagementUpgrade.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AsodManagementUpgrade
         * @function getTypeUrl
         * @memberof ws_management.AsodManagementUpgrade
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AsodManagementUpgrade.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/ws_management.AsodManagementUpgrade";
        };

        return AsodManagementUpgrade;
    })();

    ws_management.AsodManagementUpgradeInProgress = (function() {

        /**
         * Properties of an AsodManagementUpgradeInProgress.
         * @memberof ws_management
         * @interface IAsodManagementUpgradeInProgress
         * @property {number|null} [nPending] AsodManagementUpgradeInProgress nPending
         * @property {common.IDate|null} [upgradeTimeFrom] AsodManagementUpgradeInProgress upgradeTimeFrom
         * @property {common.IDate|null} [upgradeTimeTo] AsodManagementUpgradeInProgress upgradeTimeTo
         */

        /**
         * Constructs a new AsodManagementUpgradeInProgress.
         * @memberof ws_management
         * @classdesc Represents an AsodManagementUpgradeInProgress.
         * @implements IAsodManagementUpgradeInProgress
         * @constructor
         * @param {ws_management.IAsodManagementUpgradeInProgress=} [properties] Properties to set
         */
        function AsodManagementUpgradeInProgress(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AsodManagementUpgradeInProgress nPending.
         * @member {number} nPending
         * @memberof ws_management.AsodManagementUpgradeInProgress
         * @instance
         */
        AsodManagementUpgradeInProgress.prototype.nPending = 0;

        /**
         * AsodManagementUpgradeInProgress upgradeTimeFrom.
         * @member {common.IDate|null|undefined} upgradeTimeFrom
         * @memberof ws_management.AsodManagementUpgradeInProgress
         * @instance
         */
        AsodManagementUpgradeInProgress.prototype.upgradeTimeFrom = null;

        /**
         * AsodManagementUpgradeInProgress upgradeTimeTo.
         * @member {common.IDate|null|undefined} upgradeTimeTo
         * @memberof ws_management.AsodManagementUpgradeInProgress
         * @instance
         */
        AsodManagementUpgradeInProgress.prototype.upgradeTimeTo = null;

        /**
         * Creates a new AsodManagementUpgradeInProgress instance using the specified properties.
         * @function create
         * @memberof ws_management.AsodManagementUpgradeInProgress
         * @static
         * @param {ws_management.IAsodManagementUpgradeInProgress=} [properties] Properties to set
         * @returns {ws_management.AsodManagementUpgradeInProgress} AsodManagementUpgradeInProgress instance
         */
        AsodManagementUpgradeInProgress.create = function create(properties) {
            return new AsodManagementUpgradeInProgress(properties);
        };

        /**
         * Encodes the specified AsodManagementUpgradeInProgress message. Does not implicitly {@link ws_management.AsodManagementUpgradeInProgress.verify|verify} messages.
         * @function encode
         * @memberof ws_management.AsodManagementUpgradeInProgress
         * @static
         * @param {ws_management.IAsodManagementUpgradeInProgress} message AsodManagementUpgradeInProgress message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodManagementUpgradeInProgress.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.nPending != null && Object.hasOwnProperty.call(message, "nPending"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.nPending);
            if (message.upgradeTimeFrom != null && Object.hasOwnProperty.call(message, "upgradeTimeFrom"))
                $root.common.Date.encode(message.upgradeTimeFrom, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.upgradeTimeTo != null && Object.hasOwnProperty.call(message, "upgradeTimeTo"))
                $root.common.Date.encode(message.upgradeTimeTo, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified AsodManagementUpgradeInProgress message, length delimited. Does not implicitly {@link ws_management.AsodManagementUpgradeInProgress.verify|verify} messages.
         * @function encodeDelimited
         * @memberof ws_management.AsodManagementUpgradeInProgress
         * @static
         * @param {ws_management.IAsodManagementUpgradeInProgress} message AsodManagementUpgradeInProgress message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodManagementUpgradeInProgress.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AsodManagementUpgradeInProgress message from the specified reader or buffer.
         * @function decode
         * @memberof ws_management.AsodManagementUpgradeInProgress
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {ws_management.AsodManagementUpgradeInProgress} AsodManagementUpgradeInProgress
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodManagementUpgradeInProgress.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ws_management.AsodManagementUpgradeInProgress();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.nPending = reader.int32();
                        break;
                    }
                case 2: {
                        message.upgradeTimeFrom = $root.common.Date.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.upgradeTimeTo = $root.common.Date.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AsodManagementUpgradeInProgress message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof ws_management.AsodManagementUpgradeInProgress
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {ws_management.AsodManagementUpgradeInProgress} AsodManagementUpgradeInProgress
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodManagementUpgradeInProgress.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AsodManagementUpgradeInProgress message.
         * @function verify
         * @memberof ws_management.AsodManagementUpgradeInProgress
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AsodManagementUpgradeInProgress.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.nPending != null && message.hasOwnProperty("nPending"))
                if (!$util.isInteger(message.nPending))
                    return "nPending: integer expected";
            if (message.upgradeTimeFrom != null && message.hasOwnProperty("upgradeTimeFrom")) {
                let error = $root.common.Date.verify(message.upgradeTimeFrom);
                if (error)
                    return "upgradeTimeFrom." + error;
            }
            if (message.upgradeTimeTo != null && message.hasOwnProperty("upgradeTimeTo")) {
                let error = $root.common.Date.verify(message.upgradeTimeTo);
                if (error)
                    return "upgradeTimeTo." + error;
            }
            return null;
        };

        /**
         * Creates an AsodManagementUpgradeInProgress message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof ws_management.AsodManagementUpgradeInProgress
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {ws_management.AsodManagementUpgradeInProgress} AsodManagementUpgradeInProgress
         */
        AsodManagementUpgradeInProgress.fromObject = function fromObject(object) {
            if (object instanceof $root.ws_management.AsodManagementUpgradeInProgress)
                return object;
            let message = new $root.ws_management.AsodManagementUpgradeInProgress();
            if (object.nPending != null)
                message.nPending = object.nPending | 0;
            if (object.upgradeTimeFrom != null) {
                if (typeof object.upgradeTimeFrom !== "object")
                    throw TypeError(".ws_management.AsodManagementUpgradeInProgress.upgradeTimeFrom: object expected");
                message.upgradeTimeFrom = $root.common.Date.fromObject(object.upgradeTimeFrom);
            }
            if (object.upgradeTimeTo != null) {
                if (typeof object.upgradeTimeTo !== "object")
                    throw TypeError(".ws_management.AsodManagementUpgradeInProgress.upgradeTimeTo: object expected");
                message.upgradeTimeTo = $root.common.Date.fromObject(object.upgradeTimeTo);
            }
            return message;
        };

        /**
         * Creates a plain object from an AsodManagementUpgradeInProgress message. Also converts values to other types if specified.
         * @function toObject
         * @memberof ws_management.AsodManagementUpgradeInProgress
         * @static
         * @param {ws_management.AsodManagementUpgradeInProgress} message AsodManagementUpgradeInProgress
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AsodManagementUpgradeInProgress.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.nPending = 0;
                object.upgradeTimeFrom = null;
                object.upgradeTimeTo = null;
            }
            if (message.nPending != null && message.hasOwnProperty("nPending"))
                object.nPending = message.nPending;
            if (message.upgradeTimeFrom != null && message.hasOwnProperty("upgradeTimeFrom"))
                object.upgradeTimeFrom = $root.common.Date.toObject(message.upgradeTimeFrom, options);
            if (message.upgradeTimeTo != null && message.hasOwnProperty("upgradeTimeTo"))
                object.upgradeTimeTo = $root.common.Date.toObject(message.upgradeTimeTo, options);
            return object;
        };

        /**
         * Converts this AsodManagementUpgradeInProgress to JSON.
         * @function toJSON
         * @memberof ws_management.AsodManagementUpgradeInProgress
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AsodManagementUpgradeInProgress.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AsodManagementUpgradeInProgress
         * @function getTypeUrl
         * @memberof ws_management.AsodManagementUpgradeInProgress
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AsodManagementUpgradeInProgress.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/ws_management.AsodManagementUpgradeInProgress";
        };

        return AsodManagementUpgradeInProgress;
    })();

    /**
     * AsodManagementVersionStatus enum.
     * @name ws_management.AsodManagementVersionStatus
     * @enum {number}
     * @property {number} amvs_latest=0 amvs_latest value
     * @property {number} amvs_outdated=1 amvs_outdated value
     * @property {number} amvs_update_scheduled=2 amvs_update_scheduled value
     */
    ws_management.AsodManagementVersionStatus = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "amvs_latest"] = 0;
        values[valuesById[1] = "amvs_outdated"] = 1;
        values[valuesById[2] = "amvs_update_scheduled"] = 2;
        return values;
    })();

    /**
     * AsodManagementStatus enum.
     * @name ws_management.AsodManagementStatus
     * @enum {number}
     * @property {number} ams_unknown=0 ams_unknown value
     * @property {number} ams_online=1 ams_online value
     * @property {number} ams_offline=2 ams_offline value
     */
    ws_management.AsodManagementStatus = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "ams_unknown"] = 0;
        values[valuesById[1] = "ams_online"] = 1;
        values[valuesById[2] = "ams_offline"] = 2;
        return values;
    })();

    /**
     * AsodManagementOrderingTerm enum.
     * @name ws_management.AsodManagementOrderingTerm
     * @enum {number}
     * @property {number} amot_court=0 amot_court value
     * @property {number} amot_department=1 amot_department value
     * @property {number} amot_courtroom=2 amot_courtroom value
     * @property {number} amot_name=3 amot_name value
     * @property {number} amot_version=4 amot_version value
     * @property {number} amot_status=5 amot_status value
     * @property {number} amot_storage=6 amot_storage value
     */
    ws_management.AsodManagementOrderingTerm = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "amot_court"] = 0;
        values[valuesById[1] = "amot_department"] = 1;
        values[valuesById[2] = "amot_courtroom"] = 2;
        values[valuesById[3] = "amot_name"] = 3;
        values[valuesById[4] = "amot_version"] = 4;
        values[valuesById[5] = "amot_status"] = 5;
        values[valuesById[6] = "amot_storage"] = 6;
        return values;
    })();

    return ws_management;
})();

export const api_pregled = $root.api_pregled = (() => {

    /**
     * Namespace api_pregled.
     * @exports api_pregled
     * @namespace
     */
    const api_pregled = {};

    api_pregled.ApiPregledGetSessions = (function() {

        /**
         * Properties of an ApiPregledGetSessions.
         * @memberof api_pregled
         * @interface IApiPregledGetSessions
         * @property {string|null} [courtCaseNumberFilter] ApiPregledGetSessions courtCaseNumberFilter
         * @property {string|null} [courtFilter] ApiPregledGetSessions courtFilter
         * @property {string|null} [courtroomFilter] ApiPregledGetSessions courtroomFilter
         * @property {number|null} [dateFromFilter] ApiPregledGetSessions dateFromFilter
         * @property {number|null} [dateToFilter] ApiPregledGetSessions dateToFilter
         * @property {number|null} [offset] ApiPregledGetSessions offset
         * @property {number|null} [pageSize] ApiPregledGetSessions pageSize
         */

        /**
         * Constructs a new ApiPregledGetSessions.
         * @memberof api_pregled
         * @classdesc Represents an ApiPregledGetSessions.
         * @implements IApiPregledGetSessions
         * @constructor
         * @param {api_pregled.IApiPregledGetSessions=} [properties] Properties to set
         */
        function ApiPregledGetSessions(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiPregledGetSessions courtCaseNumberFilter.
         * @member {string} courtCaseNumberFilter
         * @memberof api_pregled.ApiPregledGetSessions
         * @instance
         */
        ApiPregledGetSessions.prototype.courtCaseNumberFilter = "";

        /**
         * ApiPregledGetSessions courtFilter.
         * @member {string} courtFilter
         * @memberof api_pregled.ApiPregledGetSessions
         * @instance
         */
        ApiPregledGetSessions.prototype.courtFilter = "";

        /**
         * ApiPregledGetSessions courtroomFilter.
         * @member {string} courtroomFilter
         * @memberof api_pregled.ApiPregledGetSessions
         * @instance
         */
        ApiPregledGetSessions.prototype.courtroomFilter = "";

        /**
         * ApiPregledGetSessions dateFromFilter.
         * @member {number} dateFromFilter
         * @memberof api_pregled.ApiPregledGetSessions
         * @instance
         */
        ApiPregledGetSessions.prototype.dateFromFilter = 0;

        /**
         * ApiPregledGetSessions dateToFilter.
         * @member {number} dateToFilter
         * @memberof api_pregled.ApiPregledGetSessions
         * @instance
         */
        ApiPregledGetSessions.prototype.dateToFilter = 0;

        /**
         * ApiPregledGetSessions offset.
         * @member {number} offset
         * @memberof api_pregled.ApiPregledGetSessions
         * @instance
         */
        ApiPregledGetSessions.prototype.offset = 0;

        /**
         * ApiPregledGetSessions pageSize.
         * @member {number} pageSize
         * @memberof api_pregled.ApiPregledGetSessions
         * @instance
         */
        ApiPregledGetSessions.prototype.pageSize = 0;

        /**
         * Creates a new ApiPregledGetSessions instance using the specified properties.
         * @function create
         * @memberof api_pregled.ApiPregledGetSessions
         * @static
         * @param {api_pregled.IApiPregledGetSessions=} [properties] Properties to set
         * @returns {api_pregled.ApiPregledGetSessions} ApiPregledGetSessions instance
         */
        ApiPregledGetSessions.create = function create(properties) {
            return new ApiPregledGetSessions(properties);
        };

        /**
         * Encodes the specified ApiPregledGetSessions message. Does not implicitly {@link api_pregled.ApiPregledGetSessions.verify|verify} messages.
         * @function encode
         * @memberof api_pregled.ApiPregledGetSessions
         * @static
         * @param {api_pregled.IApiPregledGetSessions} message ApiPregledGetSessions message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiPregledGetSessions.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.courtCaseNumberFilter != null && Object.hasOwnProperty.call(message, "courtCaseNumberFilter"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.courtCaseNumberFilter);
            if (message.courtFilter != null && Object.hasOwnProperty.call(message, "courtFilter"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.courtFilter);
            if (message.courtroomFilter != null && Object.hasOwnProperty.call(message, "courtroomFilter"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.courtroomFilter);
            if (message.dateFromFilter != null && Object.hasOwnProperty.call(message, "dateFromFilter"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.dateFromFilter);
            if (message.dateToFilter != null && Object.hasOwnProperty.call(message, "dateToFilter"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.dateToFilter);
            if (message.offset != null && Object.hasOwnProperty.call(message, "offset"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.offset);
            if (message.pageSize != null && Object.hasOwnProperty.call(message, "pageSize"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.pageSize);
            return writer;
        };

        /**
         * Encodes the specified ApiPregledGetSessions message, length delimited. Does not implicitly {@link api_pregled.ApiPregledGetSessions.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_pregled.ApiPregledGetSessions
         * @static
         * @param {api_pregled.IApiPregledGetSessions} message ApiPregledGetSessions message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiPregledGetSessions.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiPregledGetSessions message from the specified reader or buffer.
         * @function decode
         * @memberof api_pregled.ApiPregledGetSessions
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_pregled.ApiPregledGetSessions} ApiPregledGetSessions
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiPregledGetSessions.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_pregled.ApiPregledGetSessions();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.courtCaseNumberFilter = reader.string();
                        break;
                    }
                case 2: {
                        message.courtFilter = reader.string();
                        break;
                    }
                case 3: {
                        message.courtroomFilter = reader.string();
                        break;
                    }
                case 4: {
                        message.dateFromFilter = reader.int32();
                        break;
                    }
                case 5: {
                        message.dateToFilter = reader.int32();
                        break;
                    }
                case 6: {
                        message.offset = reader.int32();
                        break;
                    }
                case 7: {
                        message.pageSize = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiPregledGetSessions message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_pregled.ApiPregledGetSessions
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_pregled.ApiPregledGetSessions} ApiPregledGetSessions
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiPregledGetSessions.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiPregledGetSessions message.
         * @function verify
         * @memberof api_pregled.ApiPregledGetSessions
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiPregledGetSessions.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.courtCaseNumberFilter != null && message.hasOwnProperty("courtCaseNumberFilter"))
                if (!$util.isString(message.courtCaseNumberFilter))
                    return "courtCaseNumberFilter: string expected";
            if (message.courtFilter != null && message.hasOwnProperty("courtFilter"))
                if (!$util.isString(message.courtFilter))
                    return "courtFilter: string expected";
            if (message.courtroomFilter != null && message.hasOwnProperty("courtroomFilter"))
                if (!$util.isString(message.courtroomFilter))
                    return "courtroomFilter: string expected";
            if (message.dateFromFilter != null && message.hasOwnProperty("dateFromFilter"))
                if (!$util.isInteger(message.dateFromFilter))
                    return "dateFromFilter: integer expected";
            if (message.dateToFilter != null && message.hasOwnProperty("dateToFilter"))
                if (!$util.isInteger(message.dateToFilter))
                    return "dateToFilter: integer expected";
            if (message.offset != null && message.hasOwnProperty("offset"))
                if (!$util.isInteger(message.offset))
                    return "offset: integer expected";
            if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                if (!$util.isInteger(message.pageSize))
                    return "pageSize: integer expected";
            return null;
        };

        /**
         * Creates an ApiPregledGetSessions message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_pregled.ApiPregledGetSessions
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_pregled.ApiPregledGetSessions} ApiPregledGetSessions
         */
        ApiPregledGetSessions.fromObject = function fromObject(object) {
            if (object instanceof $root.api_pregled.ApiPregledGetSessions)
                return object;
            let message = new $root.api_pregled.ApiPregledGetSessions();
            if (object.courtCaseNumberFilter != null)
                message.courtCaseNumberFilter = String(object.courtCaseNumberFilter);
            if (object.courtFilter != null)
                message.courtFilter = String(object.courtFilter);
            if (object.courtroomFilter != null)
                message.courtroomFilter = String(object.courtroomFilter);
            if (object.dateFromFilter != null)
                message.dateFromFilter = object.dateFromFilter | 0;
            if (object.dateToFilter != null)
                message.dateToFilter = object.dateToFilter | 0;
            if (object.offset != null)
                message.offset = object.offset | 0;
            if (object.pageSize != null)
                message.pageSize = object.pageSize | 0;
            return message;
        };

        /**
         * Creates a plain object from an ApiPregledGetSessions message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_pregled.ApiPregledGetSessions
         * @static
         * @param {api_pregled.ApiPregledGetSessions} message ApiPregledGetSessions
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiPregledGetSessions.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.courtCaseNumberFilter = "";
                object.courtFilter = "";
                object.courtroomFilter = "";
                object.dateFromFilter = 0;
                object.dateToFilter = 0;
                object.offset = 0;
                object.pageSize = 0;
            }
            if (message.courtCaseNumberFilter != null && message.hasOwnProperty("courtCaseNumberFilter"))
                object.courtCaseNumberFilter = message.courtCaseNumberFilter;
            if (message.courtFilter != null && message.hasOwnProperty("courtFilter"))
                object.courtFilter = message.courtFilter;
            if (message.courtroomFilter != null && message.hasOwnProperty("courtroomFilter"))
                object.courtroomFilter = message.courtroomFilter;
            if (message.dateFromFilter != null && message.hasOwnProperty("dateFromFilter"))
                object.dateFromFilter = message.dateFromFilter;
            if (message.dateToFilter != null && message.hasOwnProperty("dateToFilter"))
                object.dateToFilter = message.dateToFilter;
            if (message.offset != null && message.hasOwnProperty("offset"))
                object.offset = message.offset;
            if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                object.pageSize = message.pageSize;
            return object;
        };

        /**
         * Converts this ApiPregledGetSessions to JSON.
         * @function toJSON
         * @memberof api_pregled.ApiPregledGetSessions
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiPregledGetSessions.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiPregledGetSessions
         * @function getTypeUrl
         * @memberof api_pregled.ApiPregledGetSessions
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiPregledGetSessions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_pregled.ApiPregledGetSessions";
        };

        return ApiPregledGetSessions;
    })();

    api_pregled.ApiPregledSessions = (function() {

        /**
         * Properties of an ApiPregledSessions.
         * @memberof api_pregled
         * @interface IApiPregledSessions
         * @property {string|null} [sourceEventId] ApiPregledSessions sourceEventId
         * @property {Array.<api_pregled.IApiPregledSession>|null} [sessions] ApiPregledSessions sessions
         * @property {number|null} [offset] ApiPregledSessions offset
         * @property {number|null} [pageSize] ApiPregledSessions pageSize
         * @property {number|null} [lastItemIndex] ApiPregledSessions lastItemIndex
         */

        /**
         * Constructs a new ApiPregledSessions.
         * @memberof api_pregled
         * @classdesc Represents an ApiPregledSessions.
         * @implements IApiPregledSessions
         * @constructor
         * @param {api_pregled.IApiPregledSessions=} [properties] Properties to set
         */
        function ApiPregledSessions(properties) {
            this.sessions = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiPregledSessions sourceEventId.
         * @member {string} sourceEventId
         * @memberof api_pregled.ApiPregledSessions
         * @instance
         */
        ApiPregledSessions.prototype.sourceEventId = "";

        /**
         * ApiPregledSessions sessions.
         * @member {Array.<api_pregled.IApiPregledSession>} sessions
         * @memberof api_pregled.ApiPregledSessions
         * @instance
         */
        ApiPregledSessions.prototype.sessions = $util.emptyArray;

        /**
         * ApiPregledSessions offset.
         * @member {number} offset
         * @memberof api_pregled.ApiPregledSessions
         * @instance
         */
        ApiPregledSessions.prototype.offset = 0;

        /**
         * ApiPregledSessions pageSize.
         * @member {number} pageSize
         * @memberof api_pregled.ApiPregledSessions
         * @instance
         */
        ApiPregledSessions.prototype.pageSize = 0;

        /**
         * ApiPregledSessions lastItemIndex.
         * @member {number} lastItemIndex
         * @memberof api_pregled.ApiPregledSessions
         * @instance
         */
        ApiPregledSessions.prototype.lastItemIndex = 0;

        /**
         * Creates a new ApiPregledSessions instance using the specified properties.
         * @function create
         * @memberof api_pregled.ApiPregledSessions
         * @static
         * @param {api_pregled.IApiPregledSessions=} [properties] Properties to set
         * @returns {api_pregled.ApiPregledSessions} ApiPregledSessions instance
         */
        ApiPregledSessions.create = function create(properties) {
            return new ApiPregledSessions(properties);
        };

        /**
         * Encodes the specified ApiPregledSessions message. Does not implicitly {@link api_pregled.ApiPregledSessions.verify|verify} messages.
         * @function encode
         * @memberof api_pregled.ApiPregledSessions
         * @static
         * @param {api_pregled.IApiPregledSessions} message ApiPregledSessions message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiPregledSessions.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sourceEventId != null && Object.hasOwnProperty.call(message, "sourceEventId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.sourceEventId);
            if (message.sessions != null && message.sessions.length)
                for (let i = 0; i < message.sessions.length; ++i)
                    $root.api_pregled.ApiPregledSession.encode(message.sessions[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.offset != null && Object.hasOwnProperty.call(message, "offset"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.offset);
            if (message.pageSize != null && Object.hasOwnProperty.call(message, "pageSize"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.pageSize);
            if (message.lastItemIndex != null && Object.hasOwnProperty.call(message, "lastItemIndex"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.lastItemIndex);
            return writer;
        };

        /**
         * Encodes the specified ApiPregledSessions message, length delimited. Does not implicitly {@link api_pregled.ApiPregledSessions.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_pregled.ApiPregledSessions
         * @static
         * @param {api_pregled.IApiPregledSessions} message ApiPregledSessions message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiPregledSessions.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiPregledSessions message from the specified reader or buffer.
         * @function decode
         * @memberof api_pregled.ApiPregledSessions
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_pregled.ApiPregledSessions} ApiPregledSessions
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiPregledSessions.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_pregled.ApiPregledSessions();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sourceEventId = reader.string();
                        break;
                    }
                case 2: {
                        if (!(message.sessions && message.sessions.length))
                            message.sessions = [];
                        message.sessions.push($root.api_pregled.ApiPregledSession.decode(reader, reader.uint32()));
                        break;
                    }
                case 3: {
                        message.offset = reader.int32();
                        break;
                    }
                case 4: {
                        message.pageSize = reader.int32();
                        break;
                    }
                case 5: {
                        message.lastItemIndex = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiPregledSessions message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_pregled.ApiPregledSessions
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_pregled.ApiPregledSessions} ApiPregledSessions
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiPregledSessions.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiPregledSessions message.
         * @function verify
         * @memberof api_pregled.ApiPregledSessions
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiPregledSessions.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.sourceEventId != null && message.hasOwnProperty("sourceEventId"))
                if (!$util.isString(message.sourceEventId))
                    return "sourceEventId: string expected";
            if (message.sessions != null && message.hasOwnProperty("sessions")) {
                if (!Array.isArray(message.sessions))
                    return "sessions: array expected";
                for (let i = 0; i < message.sessions.length; ++i) {
                    let error = $root.api_pregled.ApiPregledSession.verify(message.sessions[i]);
                    if (error)
                        return "sessions." + error;
                }
            }
            if (message.offset != null && message.hasOwnProperty("offset"))
                if (!$util.isInteger(message.offset))
                    return "offset: integer expected";
            if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                if (!$util.isInteger(message.pageSize))
                    return "pageSize: integer expected";
            if (message.lastItemIndex != null && message.hasOwnProperty("lastItemIndex"))
                if (!$util.isInteger(message.lastItemIndex))
                    return "lastItemIndex: integer expected";
            return null;
        };

        /**
         * Creates an ApiPregledSessions message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_pregled.ApiPregledSessions
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_pregled.ApiPregledSessions} ApiPregledSessions
         */
        ApiPregledSessions.fromObject = function fromObject(object) {
            if (object instanceof $root.api_pregled.ApiPregledSessions)
                return object;
            let message = new $root.api_pregled.ApiPregledSessions();
            if (object.sourceEventId != null)
                message.sourceEventId = String(object.sourceEventId);
            if (object.sessions) {
                if (!Array.isArray(object.sessions))
                    throw TypeError(".api_pregled.ApiPregledSessions.sessions: array expected");
                message.sessions = [];
                for (let i = 0; i < object.sessions.length; ++i) {
                    if (typeof object.sessions[i] !== "object")
                        throw TypeError(".api_pregled.ApiPregledSessions.sessions: object expected");
                    message.sessions[i] = $root.api_pregled.ApiPregledSession.fromObject(object.sessions[i]);
                }
            }
            if (object.offset != null)
                message.offset = object.offset | 0;
            if (object.pageSize != null)
                message.pageSize = object.pageSize | 0;
            if (object.lastItemIndex != null)
                message.lastItemIndex = object.lastItemIndex | 0;
            return message;
        };

        /**
         * Creates a plain object from an ApiPregledSessions message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_pregled.ApiPregledSessions
         * @static
         * @param {api_pregled.ApiPregledSessions} message ApiPregledSessions
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiPregledSessions.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.sessions = [];
            if (options.defaults) {
                object.sourceEventId = "";
                object.offset = 0;
                object.pageSize = 0;
                object.lastItemIndex = 0;
            }
            if (message.sourceEventId != null && message.hasOwnProperty("sourceEventId"))
                object.sourceEventId = message.sourceEventId;
            if (message.sessions && message.sessions.length) {
                object.sessions = [];
                for (let j = 0; j < message.sessions.length; ++j)
                    object.sessions[j] = $root.api_pregled.ApiPregledSession.toObject(message.sessions[j], options);
            }
            if (message.offset != null && message.hasOwnProperty("offset"))
                object.offset = message.offset;
            if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                object.pageSize = message.pageSize;
            if (message.lastItemIndex != null && message.hasOwnProperty("lastItemIndex"))
                object.lastItemIndex = message.lastItemIndex;
            return object;
        };

        /**
         * Converts this ApiPregledSessions to JSON.
         * @function toJSON
         * @memberof api_pregled.ApiPregledSessions
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiPregledSessions.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiPregledSessions
         * @function getTypeUrl
         * @memberof api_pregled.ApiPregledSessions
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiPregledSessions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_pregled.ApiPregledSessions";
        };

        return ApiPregledSessions;
    })();

    api_pregled.ApiPregledSession = (function() {

        /**
         * Properties of an ApiPregledSession.
         * @memberof api_pregled
         * @interface IApiPregledSession
         * @property {string|null} [sessionId] ApiPregledSession sessionId
         * @property {string|null} [court] ApiPregledSession court
         * @property {string|null} [courtroom] ApiPregledSession courtroom
         * @property {string|null} [courtCaseNumber] ApiPregledSession courtCaseNumber
         * @property {Array.<api_pregled.IApiPregledParticipant>|null} [participants] ApiPregledSession participants
         * @property {number|null} [sessionStart] ApiPregledSession sessionStart
         * @property {number|null} [sessionEnd] ApiPregledSession sessionEnd
         */

        /**
         * Constructs a new ApiPregledSession.
         * @memberof api_pregled
         * @classdesc Represents an ApiPregledSession.
         * @implements IApiPregledSession
         * @constructor
         * @param {api_pregled.IApiPregledSession=} [properties] Properties to set
         */
        function ApiPregledSession(properties) {
            this.participants = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiPregledSession sessionId.
         * @member {string} sessionId
         * @memberof api_pregled.ApiPregledSession
         * @instance
         */
        ApiPregledSession.prototype.sessionId = "";

        /**
         * ApiPregledSession court.
         * @member {string} court
         * @memberof api_pregled.ApiPregledSession
         * @instance
         */
        ApiPregledSession.prototype.court = "";

        /**
         * ApiPregledSession courtroom.
         * @member {string} courtroom
         * @memberof api_pregled.ApiPregledSession
         * @instance
         */
        ApiPregledSession.prototype.courtroom = "";

        /**
         * ApiPregledSession courtCaseNumber.
         * @member {string} courtCaseNumber
         * @memberof api_pregled.ApiPregledSession
         * @instance
         */
        ApiPregledSession.prototype.courtCaseNumber = "";

        /**
         * ApiPregledSession participants.
         * @member {Array.<api_pregled.IApiPregledParticipant>} participants
         * @memberof api_pregled.ApiPregledSession
         * @instance
         */
        ApiPregledSession.prototype.participants = $util.emptyArray;

        /**
         * ApiPregledSession sessionStart.
         * @member {number} sessionStart
         * @memberof api_pregled.ApiPregledSession
         * @instance
         */
        ApiPregledSession.prototype.sessionStart = 0;

        /**
         * ApiPregledSession sessionEnd.
         * @member {number} sessionEnd
         * @memberof api_pregled.ApiPregledSession
         * @instance
         */
        ApiPregledSession.prototype.sessionEnd = 0;

        /**
         * Creates a new ApiPregledSession instance using the specified properties.
         * @function create
         * @memberof api_pregled.ApiPregledSession
         * @static
         * @param {api_pregled.IApiPregledSession=} [properties] Properties to set
         * @returns {api_pregled.ApiPregledSession} ApiPregledSession instance
         */
        ApiPregledSession.create = function create(properties) {
            return new ApiPregledSession(properties);
        };

        /**
         * Encodes the specified ApiPregledSession message. Does not implicitly {@link api_pregled.ApiPregledSession.verify|verify} messages.
         * @function encode
         * @memberof api_pregled.ApiPregledSession
         * @static
         * @param {api_pregled.IApiPregledSession} message ApiPregledSession message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiPregledSession.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sessionId != null && Object.hasOwnProperty.call(message, "sessionId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.sessionId);
            if (message.court != null && Object.hasOwnProperty.call(message, "court"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.court);
            if (message.courtroom != null && Object.hasOwnProperty.call(message, "courtroom"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.courtroom);
            if (message.courtCaseNumber != null && Object.hasOwnProperty.call(message, "courtCaseNumber"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.courtCaseNumber);
            if (message.participants != null && message.participants.length)
                for (let i = 0; i < message.participants.length; ++i)
                    $root.api_pregled.ApiPregledParticipant.encode(message.participants[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.sessionStart != null && Object.hasOwnProperty.call(message, "sessionStart"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.sessionStart);
            if (message.sessionEnd != null && Object.hasOwnProperty.call(message, "sessionEnd"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.sessionEnd);
            return writer;
        };

        /**
         * Encodes the specified ApiPregledSession message, length delimited. Does not implicitly {@link api_pregled.ApiPregledSession.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_pregled.ApiPregledSession
         * @static
         * @param {api_pregled.IApiPregledSession} message ApiPregledSession message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiPregledSession.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiPregledSession message from the specified reader or buffer.
         * @function decode
         * @memberof api_pregled.ApiPregledSession
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_pregled.ApiPregledSession} ApiPregledSession
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiPregledSession.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_pregled.ApiPregledSession();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sessionId = reader.string();
                        break;
                    }
                case 2: {
                        message.court = reader.string();
                        break;
                    }
                case 3: {
                        message.courtroom = reader.string();
                        break;
                    }
                case 4: {
                        message.courtCaseNumber = reader.string();
                        break;
                    }
                case 5: {
                        if (!(message.participants && message.participants.length))
                            message.participants = [];
                        message.participants.push($root.api_pregled.ApiPregledParticipant.decode(reader, reader.uint32()));
                        break;
                    }
                case 6: {
                        message.sessionStart = reader.int32();
                        break;
                    }
                case 7: {
                        message.sessionEnd = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiPregledSession message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_pregled.ApiPregledSession
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_pregled.ApiPregledSession} ApiPregledSession
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiPregledSession.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiPregledSession message.
         * @function verify
         * @memberof api_pregled.ApiPregledSession
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiPregledSession.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                if (!$util.isString(message.sessionId))
                    return "sessionId: string expected";
            if (message.court != null && message.hasOwnProperty("court"))
                if (!$util.isString(message.court))
                    return "court: string expected";
            if (message.courtroom != null && message.hasOwnProperty("courtroom"))
                if (!$util.isString(message.courtroom))
                    return "courtroom: string expected";
            if (message.courtCaseNumber != null && message.hasOwnProperty("courtCaseNumber"))
                if (!$util.isString(message.courtCaseNumber))
                    return "courtCaseNumber: string expected";
            if (message.participants != null && message.hasOwnProperty("participants")) {
                if (!Array.isArray(message.participants))
                    return "participants: array expected";
                for (let i = 0; i < message.participants.length; ++i) {
                    let error = $root.api_pregled.ApiPregledParticipant.verify(message.participants[i]);
                    if (error)
                        return "participants." + error;
                }
            }
            if (message.sessionStart != null && message.hasOwnProperty("sessionStart"))
                if (!$util.isInteger(message.sessionStart))
                    return "sessionStart: integer expected";
            if (message.sessionEnd != null && message.hasOwnProperty("sessionEnd"))
                if (!$util.isInteger(message.sessionEnd))
                    return "sessionEnd: integer expected";
            return null;
        };

        /**
         * Creates an ApiPregledSession message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_pregled.ApiPregledSession
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_pregled.ApiPregledSession} ApiPregledSession
         */
        ApiPregledSession.fromObject = function fromObject(object) {
            if (object instanceof $root.api_pregled.ApiPregledSession)
                return object;
            let message = new $root.api_pregled.ApiPregledSession();
            if (object.sessionId != null)
                message.sessionId = String(object.sessionId);
            if (object.court != null)
                message.court = String(object.court);
            if (object.courtroom != null)
                message.courtroom = String(object.courtroom);
            if (object.courtCaseNumber != null)
                message.courtCaseNumber = String(object.courtCaseNumber);
            if (object.participants) {
                if (!Array.isArray(object.participants))
                    throw TypeError(".api_pregled.ApiPregledSession.participants: array expected");
                message.participants = [];
                for (let i = 0; i < object.participants.length; ++i) {
                    if (typeof object.participants[i] !== "object")
                        throw TypeError(".api_pregled.ApiPregledSession.participants: object expected");
                    message.participants[i] = $root.api_pregled.ApiPregledParticipant.fromObject(object.participants[i]);
                }
            }
            if (object.sessionStart != null)
                message.sessionStart = object.sessionStart | 0;
            if (object.sessionEnd != null)
                message.sessionEnd = object.sessionEnd | 0;
            return message;
        };

        /**
         * Creates a plain object from an ApiPregledSession message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_pregled.ApiPregledSession
         * @static
         * @param {api_pregled.ApiPregledSession} message ApiPregledSession
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiPregledSession.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.participants = [];
            if (options.defaults) {
                object.sessionId = "";
                object.court = "";
                object.courtroom = "";
                object.courtCaseNumber = "";
                object.sessionStart = 0;
                object.sessionEnd = 0;
            }
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                object.sessionId = message.sessionId;
            if (message.court != null && message.hasOwnProperty("court"))
                object.court = message.court;
            if (message.courtroom != null && message.hasOwnProperty("courtroom"))
                object.courtroom = message.courtroom;
            if (message.courtCaseNumber != null && message.hasOwnProperty("courtCaseNumber"))
                object.courtCaseNumber = message.courtCaseNumber;
            if (message.participants && message.participants.length) {
                object.participants = [];
                for (let j = 0; j < message.participants.length; ++j)
                    object.participants[j] = $root.api_pregled.ApiPregledParticipant.toObject(message.participants[j], options);
            }
            if (message.sessionStart != null && message.hasOwnProperty("sessionStart"))
                object.sessionStart = message.sessionStart;
            if (message.sessionEnd != null && message.hasOwnProperty("sessionEnd"))
                object.sessionEnd = message.sessionEnd;
            return object;
        };

        /**
         * Converts this ApiPregledSession to JSON.
         * @function toJSON
         * @memberof api_pregled.ApiPregledSession
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiPregledSession.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiPregledSession
         * @function getTypeUrl
         * @memberof api_pregled.ApiPregledSession
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiPregledSession.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_pregled.ApiPregledSession";
        };

        return ApiPregledSession;
    })();

    api_pregled.ApiPregledParticipant = (function() {

        /**
         * Properties of an ApiPregledParticipant.
         * @memberof api_pregled
         * @interface IApiPregledParticipant
         * @property {string|null} [participationId] ApiPregledParticipant participationId
         * @property {string|null} [personId] ApiPregledParticipant personId
         * @property {string|null} [displayName] ApiPregledParticipant displayName
         * @property {string|null} [roleId] ApiPregledParticipant roleId
         * @property {number|null} [channelNumber] ApiPregledParticipant channelNumber
         * @property {boolean|null} [publicAccess] ApiPregledParticipant publicAccess
         * @property {string|null} [participantTaxId] ApiPregledParticipant participantTaxId
         */

        /**
         * Constructs a new ApiPregledParticipant.
         * @memberof api_pregled
         * @classdesc Represents an ApiPregledParticipant.
         * @implements IApiPregledParticipant
         * @constructor
         * @param {api_pregled.IApiPregledParticipant=} [properties] Properties to set
         */
        function ApiPregledParticipant(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiPregledParticipant participationId.
         * @member {string} participationId
         * @memberof api_pregled.ApiPregledParticipant
         * @instance
         */
        ApiPregledParticipant.prototype.participationId = "";

        /**
         * ApiPregledParticipant personId.
         * @member {string} personId
         * @memberof api_pregled.ApiPregledParticipant
         * @instance
         */
        ApiPregledParticipant.prototype.personId = "";

        /**
         * ApiPregledParticipant displayName.
         * @member {string} displayName
         * @memberof api_pregled.ApiPregledParticipant
         * @instance
         */
        ApiPregledParticipant.prototype.displayName = "";

        /**
         * ApiPregledParticipant roleId.
         * @member {string} roleId
         * @memberof api_pregled.ApiPregledParticipant
         * @instance
         */
        ApiPregledParticipant.prototype.roleId = "";

        /**
         * ApiPregledParticipant channelNumber.
         * @member {number} channelNumber
         * @memberof api_pregled.ApiPregledParticipant
         * @instance
         */
        ApiPregledParticipant.prototype.channelNumber = 0;

        /**
         * ApiPregledParticipant publicAccess.
         * @member {boolean} publicAccess
         * @memberof api_pregled.ApiPregledParticipant
         * @instance
         */
        ApiPregledParticipant.prototype.publicAccess = false;

        /**
         * ApiPregledParticipant participantTaxId.
         * @member {string} participantTaxId
         * @memberof api_pregled.ApiPregledParticipant
         * @instance
         */
        ApiPregledParticipant.prototype.participantTaxId = "";

        /**
         * Creates a new ApiPregledParticipant instance using the specified properties.
         * @function create
         * @memberof api_pregled.ApiPregledParticipant
         * @static
         * @param {api_pregled.IApiPregledParticipant=} [properties] Properties to set
         * @returns {api_pregled.ApiPregledParticipant} ApiPregledParticipant instance
         */
        ApiPregledParticipant.create = function create(properties) {
            return new ApiPregledParticipant(properties);
        };

        /**
         * Encodes the specified ApiPregledParticipant message. Does not implicitly {@link api_pregled.ApiPregledParticipant.verify|verify} messages.
         * @function encode
         * @memberof api_pregled.ApiPregledParticipant
         * @static
         * @param {api_pregled.IApiPregledParticipant} message ApiPregledParticipant message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiPregledParticipant.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.participationId != null && Object.hasOwnProperty.call(message, "participationId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.participationId);
            if (message.personId != null && Object.hasOwnProperty.call(message, "personId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.personId);
            if (message.displayName != null && Object.hasOwnProperty.call(message, "displayName"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.displayName);
            if (message.roleId != null && Object.hasOwnProperty.call(message, "roleId"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.roleId);
            if (message.channelNumber != null && Object.hasOwnProperty.call(message, "channelNumber"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.channelNumber);
            if (message.publicAccess != null && Object.hasOwnProperty.call(message, "publicAccess"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.publicAccess);
            if (message.participantTaxId != null && Object.hasOwnProperty.call(message, "participantTaxId"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.participantTaxId);
            return writer;
        };

        /**
         * Encodes the specified ApiPregledParticipant message, length delimited. Does not implicitly {@link api_pregled.ApiPregledParticipant.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_pregled.ApiPregledParticipant
         * @static
         * @param {api_pregled.IApiPregledParticipant} message ApiPregledParticipant message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiPregledParticipant.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiPregledParticipant message from the specified reader or buffer.
         * @function decode
         * @memberof api_pregled.ApiPregledParticipant
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_pregled.ApiPregledParticipant} ApiPregledParticipant
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiPregledParticipant.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_pregled.ApiPregledParticipant();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.participationId = reader.string();
                        break;
                    }
                case 2: {
                        message.personId = reader.string();
                        break;
                    }
                case 3: {
                        message.displayName = reader.string();
                        break;
                    }
                case 4: {
                        message.roleId = reader.string();
                        break;
                    }
                case 5: {
                        message.channelNumber = reader.int32();
                        break;
                    }
                case 6: {
                        message.publicAccess = reader.bool();
                        break;
                    }
                case 7: {
                        message.participantTaxId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiPregledParticipant message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_pregled.ApiPregledParticipant
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_pregled.ApiPregledParticipant} ApiPregledParticipant
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiPregledParticipant.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiPregledParticipant message.
         * @function verify
         * @memberof api_pregled.ApiPregledParticipant
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiPregledParticipant.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.participationId != null && message.hasOwnProperty("participationId"))
                if (!$util.isString(message.participationId))
                    return "participationId: string expected";
            if (message.personId != null && message.hasOwnProperty("personId"))
                if (!$util.isString(message.personId))
                    return "personId: string expected";
            if (message.displayName != null && message.hasOwnProperty("displayName"))
                if (!$util.isString(message.displayName))
                    return "displayName: string expected";
            if (message.roleId != null && message.hasOwnProperty("roleId"))
                if (!$util.isString(message.roleId))
                    return "roleId: string expected";
            if (message.channelNumber != null && message.hasOwnProperty("channelNumber"))
                if (!$util.isInteger(message.channelNumber))
                    return "channelNumber: integer expected";
            if (message.publicAccess != null && message.hasOwnProperty("publicAccess"))
                if (typeof message.publicAccess !== "boolean")
                    return "publicAccess: boolean expected";
            if (message.participantTaxId != null && message.hasOwnProperty("participantTaxId"))
                if (!$util.isString(message.participantTaxId))
                    return "participantTaxId: string expected";
            return null;
        };

        /**
         * Creates an ApiPregledParticipant message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_pregled.ApiPregledParticipant
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_pregled.ApiPregledParticipant} ApiPregledParticipant
         */
        ApiPregledParticipant.fromObject = function fromObject(object) {
            if (object instanceof $root.api_pregled.ApiPregledParticipant)
                return object;
            let message = new $root.api_pregled.ApiPregledParticipant();
            if (object.participationId != null)
                message.participationId = String(object.participationId);
            if (object.personId != null)
                message.personId = String(object.personId);
            if (object.displayName != null)
                message.displayName = String(object.displayName);
            if (object.roleId != null)
                message.roleId = String(object.roleId);
            if (object.channelNumber != null)
                message.channelNumber = object.channelNumber | 0;
            if (object.publicAccess != null)
                message.publicAccess = Boolean(object.publicAccess);
            if (object.participantTaxId != null)
                message.participantTaxId = String(object.participantTaxId);
            return message;
        };

        /**
         * Creates a plain object from an ApiPregledParticipant message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_pregled.ApiPregledParticipant
         * @static
         * @param {api_pregled.ApiPregledParticipant} message ApiPregledParticipant
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiPregledParticipant.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.participationId = "";
                object.personId = "";
                object.displayName = "";
                object.roleId = "";
                object.channelNumber = 0;
                object.publicAccess = false;
                object.participantTaxId = "";
            }
            if (message.participationId != null && message.hasOwnProperty("participationId"))
                object.participationId = message.participationId;
            if (message.personId != null && message.hasOwnProperty("personId"))
                object.personId = message.personId;
            if (message.displayName != null && message.hasOwnProperty("displayName"))
                object.displayName = message.displayName;
            if (message.roleId != null && message.hasOwnProperty("roleId"))
                object.roleId = message.roleId;
            if (message.channelNumber != null && message.hasOwnProperty("channelNumber"))
                object.channelNumber = message.channelNumber;
            if (message.publicAccess != null && message.hasOwnProperty("publicAccess"))
                object.publicAccess = message.publicAccess;
            if (message.participantTaxId != null && message.hasOwnProperty("participantTaxId"))
                object.participantTaxId = message.participantTaxId;
            return object;
        };

        /**
         * Converts this ApiPregledParticipant to JSON.
         * @function toJSON
         * @memberof api_pregled.ApiPregledParticipant
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiPregledParticipant.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiPregledParticipant
         * @function getTypeUrl
         * @memberof api_pregled.ApiPregledParticipant
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiPregledParticipant.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_pregled.ApiPregledParticipant";
        };

        return ApiPregledParticipant;
    })();

    api_pregled.ApiPregledGetPackagedSession = (function() {

        /**
         * Properties of an ApiPregledGetPackagedSession.
         * @memberof api_pregled
         * @interface IApiPregledGetPackagedSession
         * @property {string|null} [sessionId] ApiPregledGetPackagedSession sessionId
         */

        /**
         * Constructs a new ApiPregledGetPackagedSession.
         * @memberof api_pregled
         * @classdesc Represents an ApiPregledGetPackagedSession.
         * @implements IApiPregledGetPackagedSession
         * @constructor
         * @param {api_pregled.IApiPregledGetPackagedSession=} [properties] Properties to set
         */
        function ApiPregledGetPackagedSession(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiPregledGetPackagedSession sessionId.
         * @member {string} sessionId
         * @memberof api_pregled.ApiPregledGetPackagedSession
         * @instance
         */
        ApiPregledGetPackagedSession.prototype.sessionId = "";

        /**
         * Creates a new ApiPregledGetPackagedSession instance using the specified properties.
         * @function create
         * @memberof api_pregled.ApiPregledGetPackagedSession
         * @static
         * @param {api_pregled.IApiPregledGetPackagedSession=} [properties] Properties to set
         * @returns {api_pregled.ApiPregledGetPackagedSession} ApiPregledGetPackagedSession instance
         */
        ApiPregledGetPackagedSession.create = function create(properties) {
            return new ApiPregledGetPackagedSession(properties);
        };

        /**
         * Encodes the specified ApiPregledGetPackagedSession message. Does not implicitly {@link api_pregled.ApiPregledGetPackagedSession.verify|verify} messages.
         * @function encode
         * @memberof api_pregled.ApiPregledGetPackagedSession
         * @static
         * @param {api_pregled.IApiPregledGetPackagedSession} message ApiPregledGetPackagedSession message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiPregledGetPackagedSession.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sessionId != null && Object.hasOwnProperty.call(message, "sessionId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.sessionId);
            return writer;
        };

        /**
         * Encodes the specified ApiPregledGetPackagedSession message, length delimited. Does not implicitly {@link api_pregled.ApiPregledGetPackagedSession.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_pregled.ApiPregledGetPackagedSession
         * @static
         * @param {api_pregled.IApiPregledGetPackagedSession} message ApiPregledGetPackagedSession message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiPregledGetPackagedSession.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiPregledGetPackagedSession message from the specified reader or buffer.
         * @function decode
         * @memberof api_pregled.ApiPregledGetPackagedSession
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_pregled.ApiPregledGetPackagedSession} ApiPregledGetPackagedSession
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiPregledGetPackagedSession.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_pregled.ApiPregledGetPackagedSession();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sessionId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiPregledGetPackagedSession message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_pregled.ApiPregledGetPackagedSession
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_pregled.ApiPregledGetPackagedSession} ApiPregledGetPackagedSession
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiPregledGetPackagedSession.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiPregledGetPackagedSession message.
         * @function verify
         * @memberof api_pregled.ApiPregledGetPackagedSession
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiPregledGetPackagedSession.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                if (!$util.isString(message.sessionId))
                    return "sessionId: string expected";
            return null;
        };

        /**
         * Creates an ApiPregledGetPackagedSession message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_pregled.ApiPregledGetPackagedSession
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_pregled.ApiPregledGetPackagedSession} ApiPregledGetPackagedSession
         */
        ApiPregledGetPackagedSession.fromObject = function fromObject(object) {
            if (object instanceof $root.api_pregled.ApiPregledGetPackagedSession)
                return object;
            let message = new $root.api_pregled.ApiPregledGetPackagedSession();
            if (object.sessionId != null)
                message.sessionId = String(object.sessionId);
            return message;
        };

        /**
         * Creates a plain object from an ApiPregledGetPackagedSession message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_pregled.ApiPregledGetPackagedSession
         * @static
         * @param {api_pregled.ApiPregledGetPackagedSession} message ApiPregledGetPackagedSession
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiPregledGetPackagedSession.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.sessionId = "";
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                object.sessionId = message.sessionId;
            return object;
        };

        /**
         * Converts this ApiPregledGetPackagedSession to JSON.
         * @function toJSON
         * @memberof api_pregled.ApiPregledGetPackagedSession
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiPregledGetPackagedSession.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiPregledGetPackagedSession
         * @function getTypeUrl
         * @memberof api_pregled.ApiPregledGetPackagedSession
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiPregledGetPackagedSession.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_pregled.ApiPregledGetPackagedSession";
        };

        return ApiPregledGetPackagedSession;
    })();

    api_pregled.ApiPregledPackagedSession = (function() {

        /**
         * Properties of an ApiPregledPackagedSession.
         * @memberof api_pregled
         * @interface IApiPregledPackagedSession
         * @property {string|null} [sourceEventId] ApiPregledPackagedSession sourceEventId
         * @property {string|null} [sessionId] ApiPregledPackagedSession sessionId
         * @property {string|null} [courtCaseNumber] ApiPregledPackagedSession courtCaseNumber
         * @property {string|null} [court] ApiPregledPackagedSession court
         * @property {string|null} [courtroom] ApiPregledPackagedSession courtroom
         * @property {string|null} [recordingUrl] ApiPregledPackagedSession recordingUrl
         * @property {Array.<api_pregled.IApiPregledParticipant>|null} [participants] ApiPregledPackagedSession participants
         * @property {Array.<api_pregled.IApiPregledAnnotation>|null} [annotations] ApiPregledPackagedSession annotations
         * @property {Array.<api_pregled.IApiPregledAttachment>|null} [attachments] ApiPregledPackagedSession attachments
         * @property {Array.<api_pregled.IAsodPregledDeeplink>|null} [deeplinks] ApiPregledPackagedSession deeplinks
         * @property {Array.<api_pregled.IAsodPregledSegment>|null} [segments] ApiPregledPackagedSession segments
         */

        /**
         * Constructs a new ApiPregledPackagedSession.
         * @memberof api_pregled
         * @classdesc Represents an ApiPregledPackagedSession.
         * @implements IApiPregledPackagedSession
         * @constructor
         * @param {api_pregled.IApiPregledPackagedSession=} [properties] Properties to set
         */
        function ApiPregledPackagedSession(properties) {
            this.participants = [];
            this.annotations = [];
            this.attachments = [];
            this.deeplinks = [];
            this.segments = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiPregledPackagedSession sourceEventId.
         * @member {string} sourceEventId
         * @memberof api_pregled.ApiPregledPackagedSession
         * @instance
         */
        ApiPregledPackagedSession.prototype.sourceEventId = "";

        /**
         * ApiPregledPackagedSession sessionId.
         * @member {string} sessionId
         * @memberof api_pregled.ApiPregledPackagedSession
         * @instance
         */
        ApiPregledPackagedSession.prototype.sessionId = "";

        /**
         * ApiPregledPackagedSession courtCaseNumber.
         * @member {string} courtCaseNumber
         * @memberof api_pregled.ApiPregledPackagedSession
         * @instance
         */
        ApiPregledPackagedSession.prototype.courtCaseNumber = "";

        /**
         * ApiPregledPackagedSession court.
         * @member {string} court
         * @memberof api_pregled.ApiPregledPackagedSession
         * @instance
         */
        ApiPregledPackagedSession.prototype.court = "";

        /**
         * ApiPregledPackagedSession courtroom.
         * @member {string} courtroom
         * @memberof api_pregled.ApiPregledPackagedSession
         * @instance
         */
        ApiPregledPackagedSession.prototype.courtroom = "";

        /**
         * ApiPregledPackagedSession recordingUrl.
         * @member {string} recordingUrl
         * @memberof api_pregled.ApiPregledPackagedSession
         * @instance
         */
        ApiPregledPackagedSession.prototype.recordingUrl = "";

        /**
         * ApiPregledPackagedSession participants.
         * @member {Array.<api_pregled.IApiPregledParticipant>} participants
         * @memberof api_pregled.ApiPregledPackagedSession
         * @instance
         */
        ApiPregledPackagedSession.prototype.participants = $util.emptyArray;

        /**
         * ApiPregledPackagedSession annotations.
         * @member {Array.<api_pregled.IApiPregledAnnotation>} annotations
         * @memberof api_pregled.ApiPregledPackagedSession
         * @instance
         */
        ApiPregledPackagedSession.prototype.annotations = $util.emptyArray;

        /**
         * ApiPregledPackagedSession attachments.
         * @member {Array.<api_pregled.IApiPregledAttachment>} attachments
         * @memberof api_pregled.ApiPregledPackagedSession
         * @instance
         */
        ApiPregledPackagedSession.prototype.attachments = $util.emptyArray;

        /**
         * ApiPregledPackagedSession deeplinks.
         * @member {Array.<api_pregled.IAsodPregledDeeplink>} deeplinks
         * @memberof api_pregled.ApiPregledPackagedSession
         * @instance
         */
        ApiPregledPackagedSession.prototype.deeplinks = $util.emptyArray;

        /**
         * ApiPregledPackagedSession segments.
         * @member {Array.<api_pregled.IAsodPregledSegment>} segments
         * @memberof api_pregled.ApiPregledPackagedSession
         * @instance
         */
        ApiPregledPackagedSession.prototype.segments = $util.emptyArray;

        /**
         * Creates a new ApiPregledPackagedSession instance using the specified properties.
         * @function create
         * @memberof api_pregled.ApiPregledPackagedSession
         * @static
         * @param {api_pregled.IApiPregledPackagedSession=} [properties] Properties to set
         * @returns {api_pregled.ApiPregledPackagedSession} ApiPregledPackagedSession instance
         */
        ApiPregledPackagedSession.create = function create(properties) {
            return new ApiPregledPackagedSession(properties);
        };

        /**
         * Encodes the specified ApiPregledPackagedSession message. Does not implicitly {@link api_pregled.ApiPregledPackagedSession.verify|verify} messages.
         * @function encode
         * @memberof api_pregled.ApiPregledPackagedSession
         * @static
         * @param {api_pregled.IApiPregledPackagedSession} message ApiPregledPackagedSession message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiPregledPackagedSession.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sourceEventId != null && Object.hasOwnProperty.call(message, "sourceEventId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.sourceEventId);
            if (message.sessionId != null && Object.hasOwnProperty.call(message, "sessionId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.sessionId);
            if (message.courtCaseNumber != null && Object.hasOwnProperty.call(message, "courtCaseNumber"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.courtCaseNumber);
            if (message.court != null && Object.hasOwnProperty.call(message, "court"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.court);
            if (message.courtroom != null && Object.hasOwnProperty.call(message, "courtroom"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.courtroom);
            if (message.recordingUrl != null && Object.hasOwnProperty.call(message, "recordingUrl"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.recordingUrl);
            if (message.participants != null && message.participants.length)
                for (let i = 0; i < message.participants.length; ++i)
                    $root.api_pregled.ApiPregledParticipant.encode(message.participants[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.annotations != null && message.annotations.length)
                for (let i = 0; i < message.annotations.length; ++i)
                    $root.api_pregled.ApiPregledAnnotation.encode(message.annotations[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.attachments != null && message.attachments.length)
                for (let i = 0; i < message.attachments.length; ++i)
                    $root.api_pregled.ApiPregledAttachment.encode(message.attachments[i], writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.deeplinks != null && message.deeplinks.length)
                for (let i = 0; i < message.deeplinks.length; ++i)
                    $root.api_pregled.AsodPregledDeeplink.encode(message.deeplinks[i], writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.segments != null && message.segments.length)
                for (let i = 0; i < message.segments.length; ++i)
                    $root.api_pregled.AsodPregledSegment.encode(message.segments[i], writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ApiPregledPackagedSession message, length delimited. Does not implicitly {@link api_pregled.ApiPregledPackagedSession.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_pregled.ApiPregledPackagedSession
         * @static
         * @param {api_pregled.IApiPregledPackagedSession} message ApiPregledPackagedSession message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiPregledPackagedSession.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiPregledPackagedSession message from the specified reader or buffer.
         * @function decode
         * @memberof api_pregled.ApiPregledPackagedSession
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_pregled.ApiPregledPackagedSession} ApiPregledPackagedSession
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiPregledPackagedSession.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_pregled.ApiPregledPackagedSession();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sourceEventId = reader.string();
                        break;
                    }
                case 2: {
                        message.sessionId = reader.string();
                        break;
                    }
                case 3: {
                        message.courtCaseNumber = reader.string();
                        break;
                    }
                case 4: {
                        message.court = reader.string();
                        break;
                    }
                case 5: {
                        message.courtroom = reader.string();
                        break;
                    }
                case 6: {
                        message.recordingUrl = reader.string();
                        break;
                    }
                case 7: {
                        if (!(message.participants && message.participants.length))
                            message.participants = [];
                        message.participants.push($root.api_pregled.ApiPregledParticipant.decode(reader, reader.uint32()));
                        break;
                    }
                case 8: {
                        if (!(message.annotations && message.annotations.length))
                            message.annotations = [];
                        message.annotations.push($root.api_pregled.ApiPregledAnnotation.decode(reader, reader.uint32()));
                        break;
                    }
                case 9: {
                        if (!(message.attachments && message.attachments.length))
                            message.attachments = [];
                        message.attachments.push($root.api_pregled.ApiPregledAttachment.decode(reader, reader.uint32()));
                        break;
                    }
                case 10: {
                        if (!(message.deeplinks && message.deeplinks.length))
                            message.deeplinks = [];
                        message.deeplinks.push($root.api_pregled.AsodPregledDeeplink.decode(reader, reader.uint32()));
                        break;
                    }
                case 11: {
                        if (!(message.segments && message.segments.length))
                            message.segments = [];
                        message.segments.push($root.api_pregled.AsodPregledSegment.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiPregledPackagedSession message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_pregled.ApiPregledPackagedSession
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_pregled.ApiPregledPackagedSession} ApiPregledPackagedSession
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiPregledPackagedSession.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiPregledPackagedSession message.
         * @function verify
         * @memberof api_pregled.ApiPregledPackagedSession
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiPregledPackagedSession.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.sourceEventId != null && message.hasOwnProperty("sourceEventId"))
                if (!$util.isString(message.sourceEventId))
                    return "sourceEventId: string expected";
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                if (!$util.isString(message.sessionId))
                    return "sessionId: string expected";
            if (message.courtCaseNumber != null && message.hasOwnProperty("courtCaseNumber"))
                if (!$util.isString(message.courtCaseNumber))
                    return "courtCaseNumber: string expected";
            if (message.court != null && message.hasOwnProperty("court"))
                if (!$util.isString(message.court))
                    return "court: string expected";
            if (message.courtroom != null && message.hasOwnProperty("courtroom"))
                if (!$util.isString(message.courtroom))
                    return "courtroom: string expected";
            if (message.recordingUrl != null && message.hasOwnProperty("recordingUrl"))
                if (!$util.isString(message.recordingUrl))
                    return "recordingUrl: string expected";
            if (message.participants != null && message.hasOwnProperty("participants")) {
                if (!Array.isArray(message.participants))
                    return "participants: array expected";
                for (let i = 0; i < message.participants.length; ++i) {
                    let error = $root.api_pregled.ApiPregledParticipant.verify(message.participants[i]);
                    if (error)
                        return "participants." + error;
                }
            }
            if (message.annotations != null && message.hasOwnProperty("annotations")) {
                if (!Array.isArray(message.annotations))
                    return "annotations: array expected";
                for (let i = 0; i < message.annotations.length; ++i) {
                    let error = $root.api_pregled.ApiPregledAnnotation.verify(message.annotations[i]);
                    if (error)
                        return "annotations." + error;
                }
            }
            if (message.attachments != null && message.hasOwnProperty("attachments")) {
                if (!Array.isArray(message.attachments))
                    return "attachments: array expected";
                for (let i = 0; i < message.attachments.length; ++i) {
                    let error = $root.api_pregled.ApiPregledAttachment.verify(message.attachments[i]);
                    if (error)
                        return "attachments." + error;
                }
            }
            if (message.deeplinks != null && message.hasOwnProperty("deeplinks")) {
                if (!Array.isArray(message.deeplinks))
                    return "deeplinks: array expected";
                for (let i = 0; i < message.deeplinks.length; ++i) {
                    let error = $root.api_pregled.AsodPregledDeeplink.verify(message.deeplinks[i]);
                    if (error)
                        return "deeplinks." + error;
                }
            }
            if (message.segments != null && message.hasOwnProperty("segments")) {
                if (!Array.isArray(message.segments))
                    return "segments: array expected";
                for (let i = 0; i < message.segments.length; ++i) {
                    let error = $root.api_pregled.AsodPregledSegment.verify(message.segments[i]);
                    if (error)
                        return "segments." + error;
                }
            }
            return null;
        };

        /**
         * Creates an ApiPregledPackagedSession message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_pregled.ApiPregledPackagedSession
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_pregled.ApiPregledPackagedSession} ApiPregledPackagedSession
         */
        ApiPregledPackagedSession.fromObject = function fromObject(object) {
            if (object instanceof $root.api_pregled.ApiPregledPackagedSession)
                return object;
            let message = new $root.api_pregled.ApiPregledPackagedSession();
            if (object.sourceEventId != null)
                message.sourceEventId = String(object.sourceEventId);
            if (object.sessionId != null)
                message.sessionId = String(object.sessionId);
            if (object.courtCaseNumber != null)
                message.courtCaseNumber = String(object.courtCaseNumber);
            if (object.court != null)
                message.court = String(object.court);
            if (object.courtroom != null)
                message.courtroom = String(object.courtroom);
            if (object.recordingUrl != null)
                message.recordingUrl = String(object.recordingUrl);
            if (object.participants) {
                if (!Array.isArray(object.participants))
                    throw TypeError(".api_pregled.ApiPregledPackagedSession.participants: array expected");
                message.participants = [];
                for (let i = 0; i < object.participants.length; ++i) {
                    if (typeof object.participants[i] !== "object")
                        throw TypeError(".api_pregled.ApiPregledPackagedSession.participants: object expected");
                    message.participants[i] = $root.api_pregled.ApiPregledParticipant.fromObject(object.participants[i]);
                }
            }
            if (object.annotations) {
                if (!Array.isArray(object.annotations))
                    throw TypeError(".api_pregled.ApiPregledPackagedSession.annotations: array expected");
                message.annotations = [];
                for (let i = 0; i < object.annotations.length; ++i) {
                    if (typeof object.annotations[i] !== "object")
                        throw TypeError(".api_pregled.ApiPregledPackagedSession.annotations: object expected");
                    message.annotations[i] = $root.api_pregled.ApiPregledAnnotation.fromObject(object.annotations[i]);
                }
            }
            if (object.attachments) {
                if (!Array.isArray(object.attachments))
                    throw TypeError(".api_pregled.ApiPregledPackagedSession.attachments: array expected");
                message.attachments = [];
                for (let i = 0; i < object.attachments.length; ++i) {
                    if (typeof object.attachments[i] !== "object")
                        throw TypeError(".api_pregled.ApiPregledPackagedSession.attachments: object expected");
                    message.attachments[i] = $root.api_pregled.ApiPregledAttachment.fromObject(object.attachments[i]);
                }
            }
            if (object.deeplinks) {
                if (!Array.isArray(object.deeplinks))
                    throw TypeError(".api_pregled.ApiPregledPackagedSession.deeplinks: array expected");
                message.deeplinks = [];
                for (let i = 0; i < object.deeplinks.length; ++i) {
                    if (typeof object.deeplinks[i] !== "object")
                        throw TypeError(".api_pregled.ApiPregledPackagedSession.deeplinks: object expected");
                    message.deeplinks[i] = $root.api_pregled.AsodPregledDeeplink.fromObject(object.deeplinks[i]);
                }
            }
            if (object.segments) {
                if (!Array.isArray(object.segments))
                    throw TypeError(".api_pregled.ApiPregledPackagedSession.segments: array expected");
                message.segments = [];
                for (let i = 0; i < object.segments.length; ++i) {
                    if (typeof object.segments[i] !== "object")
                        throw TypeError(".api_pregled.ApiPregledPackagedSession.segments: object expected");
                    message.segments[i] = $root.api_pregled.AsodPregledSegment.fromObject(object.segments[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from an ApiPregledPackagedSession message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_pregled.ApiPregledPackagedSession
         * @static
         * @param {api_pregled.ApiPregledPackagedSession} message ApiPregledPackagedSession
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiPregledPackagedSession.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.participants = [];
                object.annotations = [];
                object.attachments = [];
                object.deeplinks = [];
                object.segments = [];
            }
            if (options.defaults) {
                object.sourceEventId = "";
                object.sessionId = "";
                object.courtCaseNumber = "";
                object.court = "";
                object.courtroom = "";
                object.recordingUrl = "";
            }
            if (message.sourceEventId != null && message.hasOwnProperty("sourceEventId"))
                object.sourceEventId = message.sourceEventId;
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                object.sessionId = message.sessionId;
            if (message.courtCaseNumber != null && message.hasOwnProperty("courtCaseNumber"))
                object.courtCaseNumber = message.courtCaseNumber;
            if (message.court != null && message.hasOwnProperty("court"))
                object.court = message.court;
            if (message.courtroom != null && message.hasOwnProperty("courtroom"))
                object.courtroom = message.courtroom;
            if (message.recordingUrl != null && message.hasOwnProperty("recordingUrl"))
                object.recordingUrl = message.recordingUrl;
            if (message.participants && message.participants.length) {
                object.participants = [];
                for (let j = 0; j < message.participants.length; ++j)
                    object.participants[j] = $root.api_pregled.ApiPregledParticipant.toObject(message.participants[j], options);
            }
            if (message.annotations && message.annotations.length) {
                object.annotations = [];
                for (let j = 0; j < message.annotations.length; ++j)
                    object.annotations[j] = $root.api_pregled.ApiPregledAnnotation.toObject(message.annotations[j], options);
            }
            if (message.attachments && message.attachments.length) {
                object.attachments = [];
                for (let j = 0; j < message.attachments.length; ++j)
                    object.attachments[j] = $root.api_pregled.ApiPregledAttachment.toObject(message.attachments[j], options);
            }
            if (message.deeplinks && message.deeplinks.length) {
                object.deeplinks = [];
                for (let j = 0; j < message.deeplinks.length; ++j)
                    object.deeplinks[j] = $root.api_pregled.AsodPregledDeeplink.toObject(message.deeplinks[j], options);
            }
            if (message.segments && message.segments.length) {
                object.segments = [];
                for (let j = 0; j < message.segments.length; ++j)
                    object.segments[j] = $root.api_pregled.AsodPregledSegment.toObject(message.segments[j], options);
            }
            return object;
        };

        /**
         * Converts this ApiPregledPackagedSession to JSON.
         * @function toJSON
         * @memberof api_pregled.ApiPregledPackagedSession
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiPregledPackagedSession.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiPregledPackagedSession
         * @function getTypeUrl
         * @memberof api_pregled.ApiPregledPackagedSession
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiPregledPackagedSession.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_pregled.ApiPregledPackagedSession";
        };

        return ApiPregledPackagedSession;
    })();

    api_pregled.ApiPregledAnnotation = (function() {

        /**
         * Properties of an ApiPregledAnnotation.
         * @memberof api_pregled
         * @interface IApiPregledAnnotation
         * @property {string|null} [annotationId] ApiPregledAnnotation annotationId
         * @property {string|null} [content] ApiPregledAnnotation content
         * @property {string|null} [createdBy] ApiPregledAnnotation createdBy
         * @property {string|null} [createdByUsername] ApiPregledAnnotation createdByUsername
         * @property {boolean|null} [publiclyAccessible] ApiPregledAnnotation publiclyAccessible
         * @property {number|Long|null} [createdAt] ApiPregledAnnotation createdAt
         */

        /**
         * Constructs a new ApiPregledAnnotation.
         * @memberof api_pregled
         * @classdesc Represents an ApiPregledAnnotation.
         * @implements IApiPregledAnnotation
         * @constructor
         * @param {api_pregled.IApiPregledAnnotation=} [properties] Properties to set
         */
        function ApiPregledAnnotation(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiPregledAnnotation annotationId.
         * @member {string} annotationId
         * @memberof api_pregled.ApiPregledAnnotation
         * @instance
         */
        ApiPregledAnnotation.prototype.annotationId = "";

        /**
         * ApiPregledAnnotation content.
         * @member {string} content
         * @memberof api_pregled.ApiPregledAnnotation
         * @instance
         */
        ApiPregledAnnotation.prototype.content = "";

        /**
         * ApiPregledAnnotation createdBy.
         * @member {string} createdBy
         * @memberof api_pregled.ApiPregledAnnotation
         * @instance
         */
        ApiPregledAnnotation.prototype.createdBy = "";

        /**
         * ApiPregledAnnotation createdByUsername.
         * @member {string} createdByUsername
         * @memberof api_pregled.ApiPregledAnnotation
         * @instance
         */
        ApiPregledAnnotation.prototype.createdByUsername = "";

        /**
         * ApiPregledAnnotation publiclyAccessible.
         * @member {boolean} publiclyAccessible
         * @memberof api_pregled.ApiPregledAnnotation
         * @instance
         */
        ApiPregledAnnotation.prototype.publiclyAccessible = false;

        /**
         * ApiPregledAnnotation createdAt.
         * @member {number|Long} createdAt
         * @memberof api_pregled.ApiPregledAnnotation
         * @instance
         */
        ApiPregledAnnotation.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new ApiPregledAnnotation instance using the specified properties.
         * @function create
         * @memberof api_pregled.ApiPregledAnnotation
         * @static
         * @param {api_pregled.IApiPregledAnnotation=} [properties] Properties to set
         * @returns {api_pregled.ApiPregledAnnotation} ApiPregledAnnotation instance
         */
        ApiPregledAnnotation.create = function create(properties) {
            return new ApiPregledAnnotation(properties);
        };

        /**
         * Encodes the specified ApiPregledAnnotation message. Does not implicitly {@link api_pregled.ApiPregledAnnotation.verify|verify} messages.
         * @function encode
         * @memberof api_pregled.ApiPregledAnnotation
         * @static
         * @param {api_pregled.IApiPregledAnnotation} message ApiPregledAnnotation message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiPregledAnnotation.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.annotationId != null && Object.hasOwnProperty.call(message, "annotationId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.annotationId);
            if (message.content != null && Object.hasOwnProperty.call(message, "content"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.content);
            if (message.createdBy != null && Object.hasOwnProperty.call(message, "createdBy"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.createdBy);
            if (message.createdByUsername != null && Object.hasOwnProperty.call(message, "createdByUsername"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.createdByUsername);
            if (message.publiclyAccessible != null && Object.hasOwnProperty.call(message, "publiclyAccessible"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.publiclyAccessible);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 6, wireType 0 =*/48).uint64(message.createdAt);
            return writer;
        };

        /**
         * Encodes the specified ApiPregledAnnotation message, length delimited. Does not implicitly {@link api_pregled.ApiPregledAnnotation.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_pregled.ApiPregledAnnotation
         * @static
         * @param {api_pregled.IApiPregledAnnotation} message ApiPregledAnnotation message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiPregledAnnotation.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiPregledAnnotation message from the specified reader or buffer.
         * @function decode
         * @memberof api_pregled.ApiPregledAnnotation
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_pregled.ApiPregledAnnotation} ApiPregledAnnotation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiPregledAnnotation.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_pregled.ApiPregledAnnotation();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.annotationId = reader.string();
                        break;
                    }
                case 2: {
                        message.content = reader.string();
                        break;
                    }
                case 3: {
                        message.createdBy = reader.string();
                        break;
                    }
                case 4: {
                        message.createdByUsername = reader.string();
                        break;
                    }
                case 5: {
                        message.publiclyAccessible = reader.bool();
                        break;
                    }
                case 6: {
                        message.createdAt = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiPregledAnnotation message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_pregled.ApiPregledAnnotation
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_pregled.ApiPregledAnnotation} ApiPregledAnnotation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiPregledAnnotation.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiPregledAnnotation message.
         * @function verify
         * @memberof api_pregled.ApiPregledAnnotation
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiPregledAnnotation.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.annotationId != null && message.hasOwnProperty("annotationId"))
                if (!$util.isString(message.annotationId))
                    return "annotationId: string expected";
            if (message.content != null && message.hasOwnProperty("content"))
                if (!$util.isString(message.content))
                    return "content: string expected";
            if (message.createdBy != null && message.hasOwnProperty("createdBy"))
                if (!$util.isString(message.createdBy))
                    return "createdBy: string expected";
            if (message.createdByUsername != null && message.hasOwnProperty("createdByUsername"))
                if (!$util.isString(message.createdByUsername))
                    return "createdByUsername: string expected";
            if (message.publiclyAccessible != null && message.hasOwnProperty("publiclyAccessible"))
                if (typeof message.publiclyAccessible !== "boolean")
                    return "publiclyAccessible: boolean expected";
            if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                    return "createdAt: integer|Long expected";
            return null;
        };

        /**
         * Creates an ApiPregledAnnotation message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_pregled.ApiPregledAnnotation
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_pregled.ApiPregledAnnotation} ApiPregledAnnotation
         */
        ApiPregledAnnotation.fromObject = function fromObject(object) {
            if (object instanceof $root.api_pregled.ApiPregledAnnotation)
                return object;
            let message = new $root.api_pregled.ApiPregledAnnotation();
            if (object.annotationId != null)
                message.annotationId = String(object.annotationId);
            if (object.content != null)
                message.content = String(object.content);
            if (object.createdBy != null)
                message.createdBy = String(object.createdBy);
            if (object.createdByUsername != null)
                message.createdByUsername = String(object.createdByUsername);
            if (object.publiclyAccessible != null)
                message.publiclyAccessible = Boolean(object.publiclyAccessible);
            if (object.createdAt != null)
                if ($util.Long)
                    (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = true;
                else if (typeof object.createdAt === "string")
                    message.createdAt = parseInt(object.createdAt, 10);
                else if (typeof object.createdAt === "number")
                    message.createdAt = object.createdAt;
                else if (typeof object.createdAt === "object")
                    message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from an ApiPregledAnnotation message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_pregled.ApiPregledAnnotation
         * @static
         * @param {api_pregled.ApiPregledAnnotation} message ApiPregledAnnotation
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiPregledAnnotation.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.annotationId = "";
                object.content = "";
                object.createdBy = "";
                object.createdByUsername = "";
                object.publiclyAccessible = false;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.createdAt = options.longs === String ? "0" : 0;
            }
            if (message.annotationId != null && message.hasOwnProperty("annotationId"))
                object.annotationId = message.annotationId;
            if (message.content != null && message.hasOwnProperty("content"))
                object.content = message.content;
            if (message.createdBy != null && message.hasOwnProperty("createdBy"))
                object.createdBy = message.createdBy;
            if (message.createdByUsername != null && message.hasOwnProperty("createdByUsername"))
                object.createdByUsername = message.createdByUsername;
            if (message.publiclyAccessible != null && message.hasOwnProperty("publiclyAccessible"))
                object.publiclyAccessible = message.publiclyAccessible;
            if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                if (typeof message.createdAt === "number")
                    object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                else
                    object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber(true) : message.createdAt;
            return object;
        };

        /**
         * Converts this ApiPregledAnnotation to JSON.
         * @function toJSON
         * @memberof api_pregled.ApiPregledAnnotation
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiPregledAnnotation.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiPregledAnnotation
         * @function getTypeUrl
         * @memberof api_pregled.ApiPregledAnnotation
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiPregledAnnotation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_pregled.ApiPregledAnnotation";
        };

        return ApiPregledAnnotation;
    })();

    api_pregled.ApiPregledAttachment = (function() {

        /**
         * Properties of an ApiPregledAttachment.
         * @memberof api_pregled
         * @interface IApiPregledAttachment
         * @property {string|null} [attachmentId] ApiPregledAttachment attachmentId
         * @property {string|null} [filename] ApiPregledAttachment filename
         * @property {string|null} [hash] ApiPregledAttachment hash
         * @property {string|null} [url] ApiPregledAttachment url
         * @property {string|null} [createdBy] ApiPregledAttachment createdBy
         * @property {string|null} [createdByUsername] ApiPregledAttachment createdByUsername
         * @property {boolean|null} [publiclyAccessible] ApiPregledAttachment publiclyAccessible
         * @property {number|Long|null} [createdAt] ApiPregledAttachment createdAt
         */

        /**
         * Constructs a new ApiPregledAttachment.
         * @memberof api_pregled
         * @classdesc Represents an ApiPregledAttachment.
         * @implements IApiPregledAttachment
         * @constructor
         * @param {api_pregled.IApiPregledAttachment=} [properties] Properties to set
         */
        function ApiPregledAttachment(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiPregledAttachment attachmentId.
         * @member {string} attachmentId
         * @memberof api_pregled.ApiPregledAttachment
         * @instance
         */
        ApiPregledAttachment.prototype.attachmentId = "";

        /**
         * ApiPregledAttachment filename.
         * @member {string} filename
         * @memberof api_pregled.ApiPregledAttachment
         * @instance
         */
        ApiPregledAttachment.prototype.filename = "";

        /**
         * ApiPregledAttachment hash.
         * @member {string} hash
         * @memberof api_pregled.ApiPregledAttachment
         * @instance
         */
        ApiPregledAttachment.prototype.hash = "";

        /**
         * ApiPregledAttachment url.
         * @member {string} url
         * @memberof api_pregled.ApiPregledAttachment
         * @instance
         */
        ApiPregledAttachment.prototype.url = "";

        /**
         * ApiPregledAttachment createdBy.
         * @member {string} createdBy
         * @memberof api_pregled.ApiPregledAttachment
         * @instance
         */
        ApiPregledAttachment.prototype.createdBy = "";

        /**
         * ApiPregledAttachment createdByUsername.
         * @member {string} createdByUsername
         * @memberof api_pregled.ApiPregledAttachment
         * @instance
         */
        ApiPregledAttachment.prototype.createdByUsername = "";

        /**
         * ApiPregledAttachment publiclyAccessible.
         * @member {boolean} publiclyAccessible
         * @memberof api_pregled.ApiPregledAttachment
         * @instance
         */
        ApiPregledAttachment.prototype.publiclyAccessible = false;

        /**
         * ApiPregledAttachment createdAt.
         * @member {number|Long} createdAt
         * @memberof api_pregled.ApiPregledAttachment
         * @instance
         */
        ApiPregledAttachment.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new ApiPregledAttachment instance using the specified properties.
         * @function create
         * @memberof api_pregled.ApiPregledAttachment
         * @static
         * @param {api_pregled.IApiPregledAttachment=} [properties] Properties to set
         * @returns {api_pregled.ApiPregledAttachment} ApiPregledAttachment instance
         */
        ApiPregledAttachment.create = function create(properties) {
            return new ApiPregledAttachment(properties);
        };

        /**
         * Encodes the specified ApiPregledAttachment message. Does not implicitly {@link api_pregled.ApiPregledAttachment.verify|verify} messages.
         * @function encode
         * @memberof api_pregled.ApiPregledAttachment
         * @static
         * @param {api_pregled.IApiPregledAttachment} message ApiPregledAttachment message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiPregledAttachment.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.attachmentId != null && Object.hasOwnProperty.call(message, "attachmentId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.attachmentId);
            if (message.filename != null && Object.hasOwnProperty.call(message, "filename"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.filename);
            if (message.hash != null && Object.hasOwnProperty.call(message, "hash"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.hash);
            if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.url);
            if (message.createdBy != null && Object.hasOwnProperty.call(message, "createdBy"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.createdBy);
            if (message.createdByUsername != null && Object.hasOwnProperty.call(message, "createdByUsername"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.createdByUsername);
            if (message.publiclyAccessible != null && Object.hasOwnProperty.call(message, "publiclyAccessible"))
                writer.uint32(/* id 7, wireType 0 =*/56).bool(message.publiclyAccessible);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 8, wireType 0 =*/64).uint64(message.createdAt);
            return writer;
        };

        /**
         * Encodes the specified ApiPregledAttachment message, length delimited. Does not implicitly {@link api_pregled.ApiPregledAttachment.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_pregled.ApiPregledAttachment
         * @static
         * @param {api_pregled.IApiPregledAttachment} message ApiPregledAttachment message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiPregledAttachment.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiPregledAttachment message from the specified reader or buffer.
         * @function decode
         * @memberof api_pregled.ApiPregledAttachment
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_pregled.ApiPregledAttachment} ApiPregledAttachment
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiPregledAttachment.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_pregled.ApiPregledAttachment();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.attachmentId = reader.string();
                        break;
                    }
                case 2: {
                        message.filename = reader.string();
                        break;
                    }
                case 3: {
                        message.hash = reader.string();
                        break;
                    }
                case 4: {
                        message.url = reader.string();
                        break;
                    }
                case 5: {
                        message.createdBy = reader.string();
                        break;
                    }
                case 6: {
                        message.createdByUsername = reader.string();
                        break;
                    }
                case 7: {
                        message.publiclyAccessible = reader.bool();
                        break;
                    }
                case 8: {
                        message.createdAt = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiPregledAttachment message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_pregled.ApiPregledAttachment
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_pregled.ApiPregledAttachment} ApiPregledAttachment
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiPregledAttachment.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiPregledAttachment message.
         * @function verify
         * @memberof api_pregled.ApiPregledAttachment
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiPregledAttachment.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.attachmentId != null && message.hasOwnProperty("attachmentId"))
                if (!$util.isString(message.attachmentId))
                    return "attachmentId: string expected";
            if (message.filename != null && message.hasOwnProperty("filename"))
                if (!$util.isString(message.filename))
                    return "filename: string expected";
            if (message.hash != null && message.hasOwnProperty("hash"))
                if (!$util.isString(message.hash))
                    return "hash: string expected";
            if (message.url != null && message.hasOwnProperty("url"))
                if (!$util.isString(message.url))
                    return "url: string expected";
            if (message.createdBy != null && message.hasOwnProperty("createdBy"))
                if (!$util.isString(message.createdBy))
                    return "createdBy: string expected";
            if (message.createdByUsername != null && message.hasOwnProperty("createdByUsername"))
                if (!$util.isString(message.createdByUsername))
                    return "createdByUsername: string expected";
            if (message.publiclyAccessible != null && message.hasOwnProperty("publiclyAccessible"))
                if (typeof message.publiclyAccessible !== "boolean")
                    return "publiclyAccessible: boolean expected";
            if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                    return "createdAt: integer|Long expected";
            return null;
        };

        /**
         * Creates an ApiPregledAttachment message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_pregled.ApiPregledAttachment
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_pregled.ApiPregledAttachment} ApiPregledAttachment
         */
        ApiPregledAttachment.fromObject = function fromObject(object) {
            if (object instanceof $root.api_pregled.ApiPregledAttachment)
                return object;
            let message = new $root.api_pregled.ApiPregledAttachment();
            if (object.attachmentId != null)
                message.attachmentId = String(object.attachmentId);
            if (object.filename != null)
                message.filename = String(object.filename);
            if (object.hash != null)
                message.hash = String(object.hash);
            if (object.url != null)
                message.url = String(object.url);
            if (object.createdBy != null)
                message.createdBy = String(object.createdBy);
            if (object.createdByUsername != null)
                message.createdByUsername = String(object.createdByUsername);
            if (object.publiclyAccessible != null)
                message.publiclyAccessible = Boolean(object.publiclyAccessible);
            if (object.createdAt != null)
                if ($util.Long)
                    (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = true;
                else if (typeof object.createdAt === "string")
                    message.createdAt = parseInt(object.createdAt, 10);
                else if (typeof object.createdAt === "number")
                    message.createdAt = object.createdAt;
                else if (typeof object.createdAt === "object")
                    message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from an ApiPregledAttachment message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_pregled.ApiPregledAttachment
         * @static
         * @param {api_pregled.ApiPregledAttachment} message ApiPregledAttachment
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiPregledAttachment.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.attachmentId = "";
                object.filename = "";
                object.hash = "";
                object.url = "";
                object.createdBy = "";
                object.createdByUsername = "";
                object.publiclyAccessible = false;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.createdAt = options.longs === String ? "0" : 0;
            }
            if (message.attachmentId != null && message.hasOwnProperty("attachmentId"))
                object.attachmentId = message.attachmentId;
            if (message.filename != null && message.hasOwnProperty("filename"))
                object.filename = message.filename;
            if (message.hash != null && message.hasOwnProperty("hash"))
                object.hash = message.hash;
            if (message.url != null && message.hasOwnProperty("url"))
                object.url = message.url;
            if (message.createdBy != null && message.hasOwnProperty("createdBy"))
                object.createdBy = message.createdBy;
            if (message.createdByUsername != null && message.hasOwnProperty("createdByUsername"))
                object.createdByUsername = message.createdByUsername;
            if (message.publiclyAccessible != null && message.hasOwnProperty("publiclyAccessible"))
                object.publiclyAccessible = message.publiclyAccessible;
            if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                if (typeof message.createdAt === "number")
                    object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                else
                    object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber(true) : message.createdAt;
            return object;
        };

        /**
         * Converts this ApiPregledAttachment to JSON.
         * @function toJSON
         * @memberof api_pregled.ApiPregledAttachment
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiPregledAttachment.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiPregledAttachment
         * @function getTypeUrl
         * @memberof api_pregled.ApiPregledAttachment
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiPregledAttachment.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_pregled.ApiPregledAttachment";
        };

        return ApiPregledAttachment;
    })();

    api_pregled.AsodPregledDeeplink = (function() {

        /**
         * Properties of an AsodPregledDeeplink.
         * @memberof api_pregled
         * @interface IAsodPregledDeeplink
         * @property {string|null} [deeplinkId] AsodPregledDeeplink deeplinkId
         * @property {string|null} [name] AsodPregledDeeplink name
         * @property {string|null} [url] AsodPregledDeeplink url
         * @property {string|null} [createdBy] AsodPregledDeeplink createdBy
         * @property {string|null} [createdByUsername] AsodPregledDeeplink createdByUsername
         * @property {boolean|null} [publiclyAccessible] AsodPregledDeeplink publiclyAccessible
         * @property {number|Long|null} [segmentStart] AsodPregledDeeplink segmentStart
         * @property {number|Long|null} [segmentEnd] AsodPregledDeeplink segmentEnd
         * @property {number|Long|null} [createdAt] AsodPregledDeeplink createdAt
         */

        /**
         * Constructs a new AsodPregledDeeplink.
         * @memberof api_pregled
         * @classdesc Represents an AsodPregledDeeplink.
         * @implements IAsodPregledDeeplink
         * @constructor
         * @param {api_pregled.IAsodPregledDeeplink=} [properties] Properties to set
         */
        function AsodPregledDeeplink(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AsodPregledDeeplink deeplinkId.
         * @member {string} deeplinkId
         * @memberof api_pregled.AsodPregledDeeplink
         * @instance
         */
        AsodPregledDeeplink.prototype.deeplinkId = "";

        /**
         * AsodPregledDeeplink name.
         * @member {string} name
         * @memberof api_pregled.AsodPregledDeeplink
         * @instance
         */
        AsodPregledDeeplink.prototype.name = "";

        /**
         * AsodPregledDeeplink url.
         * @member {string} url
         * @memberof api_pregled.AsodPregledDeeplink
         * @instance
         */
        AsodPregledDeeplink.prototype.url = "";

        /**
         * AsodPregledDeeplink createdBy.
         * @member {string} createdBy
         * @memberof api_pregled.AsodPregledDeeplink
         * @instance
         */
        AsodPregledDeeplink.prototype.createdBy = "";

        /**
         * AsodPregledDeeplink createdByUsername.
         * @member {string} createdByUsername
         * @memberof api_pregled.AsodPregledDeeplink
         * @instance
         */
        AsodPregledDeeplink.prototype.createdByUsername = "";

        /**
         * AsodPregledDeeplink publiclyAccessible.
         * @member {boolean} publiclyAccessible
         * @memberof api_pregled.AsodPregledDeeplink
         * @instance
         */
        AsodPregledDeeplink.prototype.publiclyAccessible = false;

        /**
         * AsodPregledDeeplink segmentStart.
         * @member {number|Long} segmentStart
         * @memberof api_pregled.AsodPregledDeeplink
         * @instance
         */
        AsodPregledDeeplink.prototype.segmentStart = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * AsodPregledDeeplink segmentEnd.
         * @member {number|Long} segmentEnd
         * @memberof api_pregled.AsodPregledDeeplink
         * @instance
         */
        AsodPregledDeeplink.prototype.segmentEnd = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * AsodPregledDeeplink createdAt.
         * @member {number|Long} createdAt
         * @memberof api_pregled.AsodPregledDeeplink
         * @instance
         */
        AsodPregledDeeplink.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new AsodPregledDeeplink instance using the specified properties.
         * @function create
         * @memberof api_pregled.AsodPregledDeeplink
         * @static
         * @param {api_pregled.IAsodPregledDeeplink=} [properties] Properties to set
         * @returns {api_pregled.AsodPregledDeeplink} AsodPregledDeeplink instance
         */
        AsodPregledDeeplink.create = function create(properties) {
            return new AsodPregledDeeplink(properties);
        };

        /**
         * Encodes the specified AsodPregledDeeplink message. Does not implicitly {@link api_pregled.AsodPregledDeeplink.verify|verify} messages.
         * @function encode
         * @memberof api_pregled.AsodPregledDeeplink
         * @static
         * @param {api_pregled.IAsodPregledDeeplink} message AsodPregledDeeplink message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodPregledDeeplink.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.deeplinkId != null && Object.hasOwnProperty.call(message, "deeplinkId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.deeplinkId);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.url);
            if (message.createdBy != null && Object.hasOwnProperty.call(message, "createdBy"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.createdBy);
            if (message.createdByUsername != null && Object.hasOwnProperty.call(message, "createdByUsername"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.createdByUsername);
            if (message.publiclyAccessible != null && Object.hasOwnProperty.call(message, "publiclyAccessible"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.publiclyAccessible);
            if (message.segmentStart != null && Object.hasOwnProperty.call(message, "segmentStart"))
                writer.uint32(/* id 7, wireType 0 =*/56).uint64(message.segmentStart);
            if (message.segmentEnd != null && Object.hasOwnProperty.call(message, "segmentEnd"))
                writer.uint32(/* id 8, wireType 0 =*/64).uint64(message.segmentEnd);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 9, wireType 0 =*/72).uint64(message.createdAt);
            return writer;
        };

        /**
         * Encodes the specified AsodPregledDeeplink message, length delimited. Does not implicitly {@link api_pregled.AsodPregledDeeplink.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_pregled.AsodPregledDeeplink
         * @static
         * @param {api_pregled.IAsodPregledDeeplink} message AsodPregledDeeplink message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodPregledDeeplink.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AsodPregledDeeplink message from the specified reader or buffer.
         * @function decode
         * @memberof api_pregled.AsodPregledDeeplink
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_pregled.AsodPregledDeeplink} AsodPregledDeeplink
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodPregledDeeplink.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_pregled.AsodPregledDeeplink();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.deeplinkId = reader.string();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                case 3: {
                        message.url = reader.string();
                        break;
                    }
                case 4: {
                        message.createdBy = reader.string();
                        break;
                    }
                case 5: {
                        message.createdByUsername = reader.string();
                        break;
                    }
                case 6: {
                        message.publiclyAccessible = reader.bool();
                        break;
                    }
                case 7: {
                        message.segmentStart = reader.uint64();
                        break;
                    }
                case 8: {
                        message.segmentEnd = reader.uint64();
                        break;
                    }
                case 9: {
                        message.createdAt = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AsodPregledDeeplink message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_pregled.AsodPregledDeeplink
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_pregled.AsodPregledDeeplink} AsodPregledDeeplink
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodPregledDeeplink.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AsodPregledDeeplink message.
         * @function verify
         * @memberof api_pregled.AsodPregledDeeplink
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AsodPregledDeeplink.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.deeplinkId != null && message.hasOwnProperty("deeplinkId"))
                if (!$util.isString(message.deeplinkId))
                    return "deeplinkId: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.url != null && message.hasOwnProperty("url"))
                if (!$util.isString(message.url))
                    return "url: string expected";
            if (message.createdBy != null && message.hasOwnProperty("createdBy"))
                if (!$util.isString(message.createdBy))
                    return "createdBy: string expected";
            if (message.createdByUsername != null && message.hasOwnProperty("createdByUsername"))
                if (!$util.isString(message.createdByUsername))
                    return "createdByUsername: string expected";
            if (message.publiclyAccessible != null && message.hasOwnProperty("publiclyAccessible"))
                if (typeof message.publiclyAccessible !== "boolean")
                    return "publiclyAccessible: boolean expected";
            if (message.segmentStart != null && message.hasOwnProperty("segmentStart"))
                if (!$util.isInteger(message.segmentStart) && !(message.segmentStart && $util.isInteger(message.segmentStart.low) && $util.isInteger(message.segmentStart.high)))
                    return "segmentStart: integer|Long expected";
            if (message.segmentEnd != null && message.hasOwnProperty("segmentEnd"))
                if (!$util.isInteger(message.segmentEnd) && !(message.segmentEnd && $util.isInteger(message.segmentEnd.low) && $util.isInteger(message.segmentEnd.high)))
                    return "segmentEnd: integer|Long expected";
            if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                    return "createdAt: integer|Long expected";
            return null;
        };

        /**
         * Creates an AsodPregledDeeplink message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_pregled.AsodPregledDeeplink
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_pregled.AsodPregledDeeplink} AsodPregledDeeplink
         */
        AsodPregledDeeplink.fromObject = function fromObject(object) {
            if (object instanceof $root.api_pregled.AsodPregledDeeplink)
                return object;
            let message = new $root.api_pregled.AsodPregledDeeplink();
            if (object.deeplinkId != null)
                message.deeplinkId = String(object.deeplinkId);
            if (object.name != null)
                message.name = String(object.name);
            if (object.url != null)
                message.url = String(object.url);
            if (object.createdBy != null)
                message.createdBy = String(object.createdBy);
            if (object.createdByUsername != null)
                message.createdByUsername = String(object.createdByUsername);
            if (object.publiclyAccessible != null)
                message.publiclyAccessible = Boolean(object.publiclyAccessible);
            if (object.segmentStart != null)
                if ($util.Long)
                    (message.segmentStart = $util.Long.fromValue(object.segmentStart)).unsigned = true;
                else if (typeof object.segmentStart === "string")
                    message.segmentStart = parseInt(object.segmentStart, 10);
                else if (typeof object.segmentStart === "number")
                    message.segmentStart = object.segmentStart;
                else if (typeof object.segmentStart === "object")
                    message.segmentStart = new $util.LongBits(object.segmentStart.low >>> 0, object.segmentStart.high >>> 0).toNumber(true);
            if (object.segmentEnd != null)
                if ($util.Long)
                    (message.segmentEnd = $util.Long.fromValue(object.segmentEnd)).unsigned = true;
                else if (typeof object.segmentEnd === "string")
                    message.segmentEnd = parseInt(object.segmentEnd, 10);
                else if (typeof object.segmentEnd === "number")
                    message.segmentEnd = object.segmentEnd;
                else if (typeof object.segmentEnd === "object")
                    message.segmentEnd = new $util.LongBits(object.segmentEnd.low >>> 0, object.segmentEnd.high >>> 0).toNumber(true);
            if (object.createdAt != null)
                if ($util.Long)
                    (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = true;
                else if (typeof object.createdAt === "string")
                    message.createdAt = parseInt(object.createdAt, 10);
                else if (typeof object.createdAt === "number")
                    message.createdAt = object.createdAt;
                else if (typeof object.createdAt === "object")
                    message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from an AsodPregledDeeplink message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_pregled.AsodPregledDeeplink
         * @static
         * @param {api_pregled.AsodPregledDeeplink} message AsodPregledDeeplink
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AsodPregledDeeplink.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.deeplinkId = "";
                object.name = "";
                object.url = "";
                object.createdBy = "";
                object.createdByUsername = "";
                object.publiclyAccessible = false;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.segmentStart = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.segmentStart = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.segmentEnd = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.segmentEnd = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.createdAt = options.longs === String ? "0" : 0;
            }
            if (message.deeplinkId != null && message.hasOwnProperty("deeplinkId"))
                object.deeplinkId = message.deeplinkId;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.url != null && message.hasOwnProperty("url"))
                object.url = message.url;
            if (message.createdBy != null && message.hasOwnProperty("createdBy"))
                object.createdBy = message.createdBy;
            if (message.createdByUsername != null && message.hasOwnProperty("createdByUsername"))
                object.createdByUsername = message.createdByUsername;
            if (message.publiclyAccessible != null && message.hasOwnProperty("publiclyAccessible"))
                object.publiclyAccessible = message.publiclyAccessible;
            if (message.segmentStart != null && message.hasOwnProperty("segmentStart"))
                if (typeof message.segmentStart === "number")
                    object.segmentStart = options.longs === String ? String(message.segmentStart) : message.segmentStart;
                else
                    object.segmentStart = options.longs === String ? $util.Long.prototype.toString.call(message.segmentStart) : options.longs === Number ? new $util.LongBits(message.segmentStart.low >>> 0, message.segmentStart.high >>> 0).toNumber(true) : message.segmentStart;
            if (message.segmentEnd != null && message.hasOwnProperty("segmentEnd"))
                if (typeof message.segmentEnd === "number")
                    object.segmentEnd = options.longs === String ? String(message.segmentEnd) : message.segmentEnd;
                else
                    object.segmentEnd = options.longs === String ? $util.Long.prototype.toString.call(message.segmentEnd) : options.longs === Number ? new $util.LongBits(message.segmentEnd.low >>> 0, message.segmentEnd.high >>> 0).toNumber(true) : message.segmentEnd;
            if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                if (typeof message.createdAt === "number")
                    object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                else
                    object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber(true) : message.createdAt;
            return object;
        };

        /**
         * Converts this AsodPregledDeeplink to JSON.
         * @function toJSON
         * @memberof api_pregled.AsodPregledDeeplink
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AsodPregledDeeplink.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AsodPregledDeeplink
         * @function getTypeUrl
         * @memberof api_pregled.AsodPregledDeeplink
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AsodPregledDeeplink.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_pregled.AsodPregledDeeplink";
        };

        return AsodPregledDeeplink;
    })();

    api_pregled.AsodPregledSegment = (function() {

        /**
         * Properties of an AsodPregledSegment.
         * @memberof api_pregled
         * @interface IAsodPregledSegment
         * @property {string|null} [segmentId] AsodPregledSegment segmentId
         * @property {string|null} [url] AsodPregledSegment url
         * @property {number|null} [order] AsodPregledSegment order
         * @property {number|Long|null} [startTime] AsodPregledSegment startTime
         * @property {number|Long|null} [endTime] AsodPregledSegment endTime
         * @property {number|null} [channelCount] AsodPregledSegment channelCount
         * @property {Array.<number>|null} [waveform] AsodPregledSegment waveform
         * @property {string|null} [hash] AsodPregledSegment hash
         */

        /**
         * Constructs a new AsodPregledSegment.
         * @memberof api_pregled
         * @classdesc Represents an AsodPregledSegment.
         * @implements IAsodPregledSegment
         * @constructor
         * @param {api_pregled.IAsodPregledSegment=} [properties] Properties to set
         */
        function AsodPregledSegment(properties) {
            this.waveform = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AsodPregledSegment segmentId.
         * @member {string} segmentId
         * @memberof api_pregled.AsodPregledSegment
         * @instance
         */
        AsodPregledSegment.prototype.segmentId = "";

        /**
         * AsodPregledSegment url.
         * @member {string} url
         * @memberof api_pregled.AsodPregledSegment
         * @instance
         */
        AsodPregledSegment.prototype.url = "";

        /**
         * AsodPregledSegment order.
         * @member {number} order
         * @memberof api_pregled.AsodPregledSegment
         * @instance
         */
        AsodPregledSegment.prototype.order = 0;

        /**
         * AsodPregledSegment startTime.
         * @member {number|Long} startTime
         * @memberof api_pregled.AsodPregledSegment
         * @instance
         */
        AsodPregledSegment.prototype.startTime = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * AsodPregledSegment endTime.
         * @member {number|Long} endTime
         * @memberof api_pregled.AsodPregledSegment
         * @instance
         */
        AsodPregledSegment.prototype.endTime = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * AsodPregledSegment channelCount.
         * @member {number} channelCount
         * @memberof api_pregled.AsodPregledSegment
         * @instance
         */
        AsodPregledSegment.prototype.channelCount = 0;

        /**
         * AsodPregledSegment waveform.
         * @member {Array.<number>} waveform
         * @memberof api_pregled.AsodPregledSegment
         * @instance
         */
        AsodPregledSegment.prototype.waveform = $util.emptyArray;

        /**
         * AsodPregledSegment hash.
         * @member {string} hash
         * @memberof api_pregled.AsodPregledSegment
         * @instance
         */
        AsodPregledSegment.prototype.hash = "";

        /**
         * Creates a new AsodPregledSegment instance using the specified properties.
         * @function create
         * @memberof api_pregled.AsodPregledSegment
         * @static
         * @param {api_pregled.IAsodPregledSegment=} [properties] Properties to set
         * @returns {api_pregled.AsodPregledSegment} AsodPregledSegment instance
         */
        AsodPregledSegment.create = function create(properties) {
            return new AsodPregledSegment(properties);
        };

        /**
         * Encodes the specified AsodPregledSegment message. Does not implicitly {@link api_pregled.AsodPregledSegment.verify|verify} messages.
         * @function encode
         * @memberof api_pregled.AsodPregledSegment
         * @static
         * @param {api_pregled.IAsodPregledSegment} message AsodPregledSegment message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodPregledSegment.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.segmentId != null && Object.hasOwnProperty.call(message, "segmentId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.segmentId);
            if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.url);
            if (message.order != null && Object.hasOwnProperty.call(message, "order"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.order);
            if (message.startTime != null && Object.hasOwnProperty.call(message, "startTime"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.startTime);
            if (message.endTime != null && Object.hasOwnProperty.call(message, "endTime"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint64(message.endTime);
            if (message.channelCount != null && Object.hasOwnProperty.call(message, "channelCount"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.channelCount);
            if (message.waveform != null && message.waveform.length) {
                writer.uint32(/* id 7, wireType 2 =*/58).fork();
                for (let i = 0; i < message.waveform.length; ++i)
                    writer.float(message.waveform[i]);
                writer.ldelim();
            }
            if (message.hash != null && Object.hasOwnProperty.call(message, "hash"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.hash);
            return writer;
        };

        /**
         * Encodes the specified AsodPregledSegment message, length delimited. Does not implicitly {@link api_pregled.AsodPregledSegment.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_pregled.AsodPregledSegment
         * @static
         * @param {api_pregled.IAsodPregledSegment} message AsodPregledSegment message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodPregledSegment.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AsodPregledSegment message from the specified reader or buffer.
         * @function decode
         * @memberof api_pregled.AsodPregledSegment
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_pregled.AsodPregledSegment} AsodPregledSegment
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodPregledSegment.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_pregled.AsodPregledSegment();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.segmentId = reader.string();
                        break;
                    }
                case 2: {
                        message.url = reader.string();
                        break;
                    }
                case 3: {
                        message.order = reader.int32();
                        break;
                    }
                case 4: {
                        message.startTime = reader.uint64();
                        break;
                    }
                case 5: {
                        message.endTime = reader.uint64();
                        break;
                    }
                case 6: {
                        message.channelCount = reader.int32();
                        break;
                    }
                case 7: {
                        if (!(message.waveform && message.waveform.length))
                            message.waveform = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.waveform.push(reader.float());
                        } else
                            message.waveform.push(reader.float());
                        break;
                    }
                case 8: {
                        message.hash = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AsodPregledSegment message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_pregled.AsodPregledSegment
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_pregled.AsodPregledSegment} AsodPregledSegment
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodPregledSegment.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AsodPregledSegment message.
         * @function verify
         * @memberof api_pregled.AsodPregledSegment
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AsodPregledSegment.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.segmentId != null && message.hasOwnProperty("segmentId"))
                if (!$util.isString(message.segmentId))
                    return "segmentId: string expected";
            if (message.url != null && message.hasOwnProperty("url"))
                if (!$util.isString(message.url))
                    return "url: string expected";
            if (message.order != null && message.hasOwnProperty("order"))
                if (!$util.isInteger(message.order))
                    return "order: integer expected";
            if (message.startTime != null && message.hasOwnProperty("startTime"))
                if (!$util.isInteger(message.startTime) && !(message.startTime && $util.isInteger(message.startTime.low) && $util.isInteger(message.startTime.high)))
                    return "startTime: integer|Long expected";
            if (message.endTime != null && message.hasOwnProperty("endTime"))
                if (!$util.isInteger(message.endTime) && !(message.endTime && $util.isInteger(message.endTime.low) && $util.isInteger(message.endTime.high)))
                    return "endTime: integer|Long expected";
            if (message.channelCount != null && message.hasOwnProperty("channelCount"))
                if (!$util.isInteger(message.channelCount))
                    return "channelCount: integer expected";
            if (message.waveform != null && message.hasOwnProperty("waveform")) {
                if (!Array.isArray(message.waveform))
                    return "waveform: array expected";
                for (let i = 0; i < message.waveform.length; ++i)
                    if (typeof message.waveform[i] !== "number")
                        return "waveform: number[] expected";
            }
            if (message.hash != null && message.hasOwnProperty("hash"))
                if (!$util.isString(message.hash))
                    return "hash: string expected";
            return null;
        };

        /**
         * Creates an AsodPregledSegment message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_pregled.AsodPregledSegment
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_pregled.AsodPregledSegment} AsodPregledSegment
         */
        AsodPregledSegment.fromObject = function fromObject(object) {
            if (object instanceof $root.api_pregled.AsodPregledSegment)
                return object;
            let message = new $root.api_pregled.AsodPregledSegment();
            if (object.segmentId != null)
                message.segmentId = String(object.segmentId);
            if (object.url != null)
                message.url = String(object.url);
            if (object.order != null)
                message.order = object.order | 0;
            if (object.startTime != null)
                if ($util.Long)
                    (message.startTime = $util.Long.fromValue(object.startTime)).unsigned = true;
                else if (typeof object.startTime === "string")
                    message.startTime = parseInt(object.startTime, 10);
                else if (typeof object.startTime === "number")
                    message.startTime = object.startTime;
                else if (typeof object.startTime === "object")
                    message.startTime = new $util.LongBits(object.startTime.low >>> 0, object.startTime.high >>> 0).toNumber(true);
            if (object.endTime != null)
                if ($util.Long)
                    (message.endTime = $util.Long.fromValue(object.endTime)).unsigned = true;
                else if (typeof object.endTime === "string")
                    message.endTime = parseInt(object.endTime, 10);
                else if (typeof object.endTime === "number")
                    message.endTime = object.endTime;
                else if (typeof object.endTime === "object")
                    message.endTime = new $util.LongBits(object.endTime.low >>> 0, object.endTime.high >>> 0).toNumber(true);
            if (object.channelCount != null)
                message.channelCount = object.channelCount | 0;
            if (object.waveform) {
                if (!Array.isArray(object.waveform))
                    throw TypeError(".api_pregled.AsodPregledSegment.waveform: array expected");
                message.waveform = [];
                for (let i = 0; i < object.waveform.length; ++i)
                    message.waveform[i] = Number(object.waveform[i]);
            }
            if (object.hash != null)
                message.hash = String(object.hash);
            return message;
        };

        /**
         * Creates a plain object from an AsodPregledSegment message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_pregled.AsodPregledSegment
         * @static
         * @param {api_pregled.AsodPregledSegment} message AsodPregledSegment
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AsodPregledSegment.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.waveform = [];
            if (options.defaults) {
                object.segmentId = "";
                object.url = "";
                object.order = 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.startTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.startTime = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.endTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.endTime = options.longs === String ? "0" : 0;
                object.channelCount = 0;
                object.hash = "";
            }
            if (message.segmentId != null && message.hasOwnProperty("segmentId"))
                object.segmentId = message.segmentId;
            if (message.url != null && message.hasOwnProperty("url"))
                object.url = message.url;
            if (message.order != null && message.hasOwnProperty("order"))
                object.order = message.order;
            if (message.startTime != null && message.hasOwnProperty("startTime"))
                if (typeof message.startTime === "number")
                    object.startTime = options.longs === String ? String(message.startTime) : message.startTime;
                else
                    object.startTime = options.longs === String ? $util.Long.prototype.toString.call(message.startTime) : options.longs === Number ? new $util.LongBits(message.startTime.low >>> 0, message.startTime.high >>> 0).toNumber(true) : message.startTime;
            if (message.endTime != null && message.hasOwnProperty("endTime"))
                if (typeof message.endTime === "number")
                    object.endTime = options.longs === String ? String(message.endTime) : message.endTime;
                else
                    object.endTime = options.longs === String ? $util.Long.prototype.toString.call(message.endTime) : options.longs === Number ? new $util.LongBits(message.endTime.low >>> 0, message.endTime.high >>> 0).toNumber(true) : message.endTime;
            if (message.channelCount != null && message.hasOwnProperty("channelCount"))
                object.channelCount = message.channelCount;
            if (message.waveform && message.waveform.length) {
                object.waveform = [];
                for (let j = 0; j < message.waveform.length; ++j)
                    object.waveform[j] = options.json && !isFinite(message.waveform[j]) ? String(message.waveform[j]) : message.waveform[j];
            }
            if (message.hash != null && message.hasOwnProperty("hash"))
                object.hash = message.hash;
            return object;
        };

        /**
         * Converts this AsodPregledSegment to JSON.
         * @function toJSON
         * @memberof api_pregled.AsodPregledSegment
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AsodPregledSegment.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AsodPregledSegment
         * @function getTypeUrl
         * @memberof api_pregled.AsodPregledSegment
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AsodPregledSegment.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_pregled.AsodPregledSegment";
        };

        return AsodPregledSegment;
    })();

    return api_pregled;
})();

export const api_pairing = $root.api_pairing = (() => {

    /**
     * Namespace api_pairing.
     * @exports api_pairing
     * @namespace
     */
    const api_pairing = {};

    api_pairing.ApiPairingCertificateSigningRequest = (function() {

        /**
         * Properties of an ApiPairingCertificateSigningRequest.
         * @memberof api_pairing
         * @interface IApiPairingCertificateSigningRequest
         * @property {string|null} [csr] ApiPairingCertificateSigningRequest csr
         * @property {string|null} [type] ApiPairingCertificateSigningRequest type
         */

        /**
         * Constructs a new ApiPairingCertificateSigningRequest.
         * @memberof api_pairing
         * @classdesc Represents an ApiPairingCertificateSigningRequest.
         * @implements IApiPairingCertificateSigningRequest
         * @constructor
         * @param {api_pairing.IApiPairingCertificateSigningRequest=} [properties] Properties to set
         */
        function ApiPairingCertificateSigningRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiPairingCertificateSigningRequest csr.
         * @member {string} csr
         * @memberof api_pairing.ApiPairingCertificateSigningRequest
         * @instance
         */
        ApiPairingCertificateSigningRequest.prototype.csr = "";

        /**
         * ApiPairingCertificateSigningRequest type.
         * @member {string} type
         * @memberof api_pairing.ApiPairingCertificateSigningRequest
         * @instance
         */
        ApiPairingCertificateSigningRequest.prototype.type = "";

        /**
         * Creates a new ApiPairingCertificateSigningRequest instance using the specified properties.
         * @function create
         * @memberof api_pairing.ApiPairingCertificateSigningRequest
         * @static
         * @param {api_pairing.IApiPairingCertificateSigningRequest=} [properties] Properties to set
         * @returns {api_pairing.ApiPairingCertificateSigningRequest} ApiPairingCertificateSigningRequest instance
         */
        ApiPairingCertificateSigningRequest.create = function create(properties) {
            return new ApiPairingCertificateSigningRequest(properties);
        };

        /**
         * Encodes the specified ApiPairingCertificateSigningRequest message. Does not implicitly {@link api_pairing.ApiPairingCertificateSigningRequest.verify|verify} messages.
         * @function encode
         * @memberof api_pairing.ApiPairingCertificateSigningRequest
         * @static
         * @param {api_pairing.IApiPairingCertificateSigningRequest} message ApiPairingCertificateSigningRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiPairingCertificateSigningRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.csr != null && Object.hasOwnProperty.call(message, "csr"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.csr);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.type);
            return writer;
        };

        /**
         * Encodes the specified ApiPairingCertificateSigningRequest message, length delimited. Does not implicitly {@link api_pairing.ApiPairingCertificateSigningRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_pairing.ApiPairingCertificateSigningRequest
         * @static
         * @param {api_pairing.IApiPairingCertificateSigningRequest} message ApiPairingCertificateSigningRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiPairingCertificateSigningRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiPairingCertificateSigningRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api_pairing.ApiPairingCertificateSigningRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_pairing.ApiPairingCertificateSigningRequest} ApiPairingCertificateSigningRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiPairingCertificateSigningRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_pairing.ApiPairingCertificateSigningRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.csr = reader.string();
                        break;
                    }
                case 2: {
                        message.type = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiPairingCertificateSigningRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_pairing.ApiPairingCertificateSigningRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_pairing.ApiPairingCertificateSigningRequest} ApiPairingCertificateSigningRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiPairingCertificateSigningRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiPairingCertificateSigningRequest message.
         * @function verify
         * @memberof api_pairing.ApiPairingCertificateSigningRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiPairingCertificateSigningRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.csr != null && message.hasOwnProperty("csr"))
                if (!$util.isString(message.csr))
                    return "csr: string expected";
            if (message.type != null && message.hasOwnProperty("type"))
                if (!$util.isString(message.type))
                    return "type: string expected";
            return null;
        };

        /**
         * Creates an ApiPairingCertificateSigningRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_pairing.ApiPairingCertificateSigningRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_pairing.ApiPairingCertificateSigningRequest} ApiPairingCertificateSigningRequest
         */
        ApiPairingCertificateSigningRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api_pairing.ApiPairingCertificateSigningRequest)
                return object;
            let message = new $root.api_pairing.ApiPairingCertificateSigningRequest();
            if (object.csr != null)
                message.csr = String(object.csr);
            if (object.type != null)
                message.type = String(object.type);
            return message;
        };

        /**
         * Creates a plain object from an ApiPairingCertificateSigningRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_pairing.ApiPairingCertificateSigningRequest
         * @static
         * @param {api_pairing.ApiPairingCertificateSigningRequest} message ApiPairingCertificateSigningRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiPairingCertificateSigningRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.csr = "";
                object.type = "";
            }
            if (message.csr != null && message.hasOwnProperty("csr"))
                object.csr = message.csr;
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = message.type;
            return object;
        };

        /**
         * Converts this ApiPairingCertificateSigningRequest to JSON.
         * @function toJSON
         * @memberof api_pairing.ApiPairingCertificateSigningRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiPairingCertificateSigningRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiPairingCertificateSigningRequest
         * @function getTypeUrl
         * @memberof api_pairing.ApiPairingCertificateSigningRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiPairingCertificateSigningRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_pairing.ApiPairingCertificateSigningRequest";
        };

        return ApiPairingCertificateSigningRequest;
    })();

    api_pairing.ApiPairingCsrResponse = (function() {

        /**
         * Properties of an ApiPairingCsrResponse.
         * @memberof api_pairing
         * @interface IApiPairingCsrResponse
         * @property {string|null} [certId] ApiPairingCsrResponse certId
         * @property {string|null} [status] ApiPairingCsrResponse status
         * @property {string|null} [type] ApiPairingCsrResponse type
         * @property {string|null} [certificate] ApiPairingCsrResponse certificate
         */

        /**
         * Constructs a new ApiPairingCsrResponse.
         * @memberof api_pairing
         * @classdesc Represents an ApiPairingCsrResponse.
         * @implements IApiPairingCsrResponse
         * @constructor
         * @param {api_pairing.IApiPairingCsrResponse=} [properties] Properties to set
         */
        function ApiPairingCsrResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiPairingCsrResponse certId.
         * @member {string} certId
         * @memberof api_pairing.ApiPairingCsrResponse
         * @instance
         */
        ApiPairingCsrResponse.prototype.certId = "";

        /**
         * ApiPairingCsrResponse status.
         * @member {string} status
         * @memberof api_pairing.ApiPairingCsrResponse
         * @instance
         */
        ApiPairingCsrResponse.prototype.status = "";

        /**
         * ApiPairingCsrResponse type.
         * @member {string} type
         * @memberof api_pairing.ApiPairingCsrResponse
         * @instance
         */
        ApiPairingCsrResponse.prototype.type = "";

        /**
         * ApiPairingCsrResponse certificate.
         * @member {string} certificate
         * @memberof api_pairing.ApiPairingCsrResponse
         * @instance
         */
        ApiPairingCsrResponse.prototype.certificate = "";

        /**
         * Creates a new ApiPairingCsrResponse instance using the specified properties.
         * @function create
         * @memberof api_pairing.ApiPairingCsrResponse
         * @static
         * @param {api_pairing.IApiPairingCsrResponse=} [properties] Properties to set
         * @returns {api_pairing.ApiPairingCsrResponse} ApiPairingCsrResponse instance
         */
        ApiPairingCsrResponse.create = function create(properties) {
            return new ApiPairingCsrResponse(properties);
        };

        /**
         * Encodes the specified ApiPairingCsrResponse message. Does not implicitly {@link api_pairing.ApiPairingCsrResponse.verify|verify} messages.
         * @function encode
         * @memberof api_pairing.ApiPairingCsrResponse
         * @static
         * @param {api_pairing.IApiPairingCsrResponse} message ApiPairingCsrResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiPairingCsrResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.certId != null && Object.hasOwnProperty.call(message, "certId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.certId);
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.status);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.type);
            if (message.certificate != null && Object.hasOwnProperty.call(message, "certificate"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.certificate);
            return writer;
        };

        /**
         * Encodes the specified ApiPairingCsrResponse message, length delimited. Does not implicitly {@link api_pairing.ApiPairingCsrResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_pairing.ApiPairingCsrResponse
         * @static
         * @param {api_pairing.IApiPairingCsrResponse} message ApiPairingCsrResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiPairingCsrResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiPairingCsrResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api_pairing.ApiPairingCsrResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_pairing.ApiPairingCsrResponse} ApiPairingCsrResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiPairingCsrResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_pairing.ApiPairingCsrResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.certId = reader.string();
                        break;
                    }
                case 2: {
                        message.status = reader.string();
                        break;
                    }
                case 3: {
                        message.type = reader.string();
                        break;
                    }
                case 4: {
                        message.certificate = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiPairingCsrResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_pairing.ApiPairingCsrResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_pairing.ApiPairingCsrResponse} ApiPairingCsrResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiPairingCsrResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiPairingCsrResponse message.
         * @function verify
         * @memberof api_pairing.ApiPairingCsrResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiPairingCsrResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.certId != null && message.hasOwnProperty("certId"))
                if (!$util.isString(message.certId))
                    return "certId: string expected";
            if (message.status != null && message.hasOwnProperty("status"))
                if (!$util.isString(message.status))
                    return "status: string expected";
            if (message.type != null && message.hasOwnProperty("type"))
                if (!$util.isString(message.type))
                    return "type: string expected";
            if (message.certificate != null && message.hasOwnProperty("certificate"))
                if (!$util.isString(message.certificate))
                    return "certificate: string expected";
            return null;
        };

        /**
         * Creates an ApiPairingCsrResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_pairing.ApiPairingCsrResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_pairing.ApiPairingCsrResponse} ApiPairingCsrResponse
         */
        ApiPairingCsrResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api_pairing.ApiPairingCsrResponse)
                return object;
            let message = new $root.api_pairing.ApiPairingCsrResponse();
            if (object.certId != null)
                message.certId = String(object.certId);
            if (object.status != null)
                message.status = String(object.status);
            if (object.type != null)
                message.type = String(object.type);
            if (object.certificate != null)
                message.certificate = String(object.certificate);
            return message;
        };

        /**
         * Creates a plain object from an ApiPairingCsrResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_pairing.ApiPairingCsrResponse
         * @static
         * @param {api_pairing.ApiPairingCsrResponse} message ApiPairingCsrResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiPairingCsrResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.certId = "";
                object.status = "";
                object.type = "";
                object.certificate = "";
            }
            if (message.certId != null && message.hasOwnProperty("certId"))
                object.certId = message.certId;
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = message.status;
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = message.type;
            if (message.certificate != null && message.hasOwnProperty("certificate"))
                object.certificate = message.certificate;
            return object;
        };

        /**
         * Converts this ApiPairingCsrResponse to JSON.
         * @function toJSON
         * @memberof api_pairing.ApiPairingCsrResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiPairingCsrResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiPairingCsrResponse
         * @function getTypeUrl
         * @memberof api_pairing.ApiPairingCsrResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiPairingCsrResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_pairing.ApiPairingCsrResponse";
        };

        return ApiPairingCsrResponse;
    })();

    api_pairing.ApiPairingGetCertificate = (function() {

        /**
         * Properties of an ApiPairingGetCertificate.
         * @memberof api_pairing
         * @interface IApiPairingGetCertificate
         * @property {string|null} [certificateId] ApiPairingGetCertificate certificateId
         */

        /**
         * Constructs a new ApiPairingGetCertificate.
         * @memberof api_pairing
         * @classdesc Represents an ApiPairingGetCertificate.
         * @implements IApiPairingGetCertificate
         * @constructor
         * @param {api_pairing.IApiPairingGetCertificate=} [properties] Properties to set
         */
        function ApiPairingGetCertificate(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiPairingGetCertificate certificateId.
         * @member {string} certificateId
         * @memberof api_pairing.ApiPairingGetCertificate
         * @instance
         */
        ApiPairingGetCertificate.prototype.certificateId = "";

        /**
         * Creates a new ApiPairingGetCertificate instance using the specified properties.
         * @function create
         * @memberof api_pairing.ApiPairingGetCertificate
         * @static
         * @param {api_pairing.IApiPairingGetCertificate=} [properties] Properties to set
         * @returns {api_pairing.ApiPairingGetCertificate} ApiPairingGetCertificate instance
         */
        ApiPairingGetCertificate.create = function create(properties) {
            return new ApiPairingGetCertificate(properties);
        };

        /**
         * Encodes the specified ApiPairingGetCertificate message. Does not implicitly {@link api_pairing.ApiPairingGetCertificate.verify|verify} messages.
         * @function encode
         * @memberof api_pairing.ApiPairingGetCertificate
         * @static
         * @param {api_pairing.IApiPairingGetCertificate} message ApiPairingGetCertificate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiPairingGetCertificate.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.certificateId != null && Object.hasOwnProperty.call(message, "certificateId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.certificateId);
            return writer;
        };

        /**
         * Encodes the specified ApiPairingGetCertificate message, length delimited. Does not implicitly {@link api_pairing.ApiPairingGetCertificate.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_pairing.ApiPairingGetCertificate
         * @static
         * @param {api_pairing.IApiPairingGetCertificate} message ApiPairingGetCertificate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiPairingGetCertificate.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiPairingGetCertificate message from the specified reader or buffer.
         * @function decode
         * @memberof api_pairing.ApiPairingGetCertificate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_pairing.ApiPairingGetCertificate} ApiPairingGetCertificate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiPairingGetCertificate.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_pairing.ApiPairingGetCertificate();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.certificateId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiPairingGetCertificate message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_pairing.ApiPairingGetCertificate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_pairing.ApiPairingGetCertificate} ApiPairingGetCertificate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiPairingGetCertificate.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiPairingGetCertificate message.
         * @function verify
         * @memberof api_pairing.ApiPairingGetCertificate
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiPairingGetCertificate.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.certificateId != null && message.hasOwnProperty("certificateId"))
                if (!$util.isString(message.certificateId))
                    return "certificateId: string expected";
            return null;
        };

        /**
         * Creates an ApiPairingGetCertificate message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_pairing.ApiPairingGetCertificate
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_pairing.ApiPairingGetCertificate} ApiPairingGetCertificate
         */
        ApiPairingGetCertificate.fromObject = function fromObject(object) {
            if (object instanceof $root.api_pairing.ApiPairingGetCertificate)
                return object;
            let message = new $root.api_pairing.ApiPairingGetCertificate();
            if (object.certificateId != null)
                message.certificateId = String(object.certificateId);
            return message;
        };

        /**
         * Creates a plain object from an ApiPairingGetCertificate message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_pairing.ApiPairingGetCertificate
         * @static
         * @param {api_pairing.ApiPairingGetCertificate} message ApiPairingGetCertificate
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiPairingGetCertificate.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.certificateId = "";
            if (message.certificateId != null && message.hasOwnProperty("certificateId"))
                object.certificateId = message.certificateId;
            return object;
        };

        /**
         * Converts this ApiPairingGetCertificate to JSON.
         * @function toJSON
         * @memberof api_pairing.ApiPairingGetCertificate
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiPairingGetCertificate.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiPairingGetCertificate
         * @function getTypeUrl
         * @memberof api_pairing.ApiPairingGetCertificate
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiPairingGetCertificate.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_pairing.ApiPairingGetCertificate";
        };

        return ApiPairingGetCertificate;
    })();

    api_pairing.ApiPairingGetCourts = (function() {

        /**
         * Properties of an ApiPairingGetCourts.
         * @memberof api_pairing
         * @interface IApiPairingGetCourts
         */

        /**
         * Constructs a new ApiPairingGetCourts.
         * @memberof api_pairing
         * @classdesc Represents an ApiPairingGetCourts.
         * @implements IApiPairingGetCourts
         * @constructor
         * @param {api_pairing.IApiPairingGetCourts=} [properties] Properties to set
         */
        function ApiPairingGetCourts(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new ApiPairingGetCourts instance using the specified properties.
         * @function create
         * @memberof api_pairing.ApiPairingGetCourts
         * @static
         * @param {api_pairing.IApiPairingGetCourts=} [properties] Properties to set
         * @returns {api_pairing.ApiPairingGetCourts} ApiPairingGetCourts instance
         */
        ApiPairingGetCourts.create = function create(properties) {
            return new ApiPairingGetCourts(properties);
        };

        /**
         * Encodes the specified ApiPairingGetCourts message. Does not implicitly {@link api_pairing.ApiPairingGetCourts.verify|verify} messages.
         * @function encode
         * @memberof api_pairing.ApiPairingGetCourts
         * @static
         * @param {api_pairing.IApiPairingGetCourts} message ApiPairingGetCourts message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiPairingGetCourts.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified ApiPairingGetCourts message, length delimited. Does not implicitly {@link api_pairing.ApiPairingGetCourts.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_pairing.ApiPairingGetCourts
         * @static
         * @param {api_pairing.IApiPairingGetCourts} message ApiPairingGetCourts message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiPairingGetCourts.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiPairingGetCourts message from the specified reader or buffer.
         * @function decode
         * @memberof api_pairing.ApiPairingGetCourts
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_pairing.ApiPairingGetCourts} ApiPairingGetCourts
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiPairingGetCourts.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_pairing.ApiPairingGetCourts();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiPairingGetCourts message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_pairing.ApiPairingGetCourts
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_pairing.ApiPairingGetCourts} ApiPairingGetCourts
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiPairingGetCourts.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiPairingGetCourts message.
         * @function verify
         * @memberof api_pairing.ApiPairingGetCourts
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiPairingGetCourts.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an ApiPairingGetCourts message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_pairing.ApiPairingGetCourts
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_pairing.ApiPairingGetCourts} ApiPairingGetCourts
         */
        ApiPairingGetCourts.fromObject = function fromObject(object) {
            if (object instanceof $root.api_pairing.ApiPairingGetCourts)
                return object;
            return new $root.api_pairing.ApiPairingGetCourts();
        };

        /**
         * Creates a plain object from an ApiPairingGetCourts message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_pairing.ApiPairingGetCourts
         * @static
         * @param {api_pairing.ApiPairingGetCourts} message ApiPairingGetCourts
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiPairingGetCourts.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this ApiPairingGetCourts to JSON.
         * @function toJSON
         * @memberof api_pairing.ApiPairingGetCourts
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiPairingGetCourts.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiPairingGetCourts
         * @function getTypeUrl
         * @memberof api_pairing.ApiPairingGetCourts
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiPairingGetCourts.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_pairing.ApiPairingGetCourts";
        };

        return ApiPairingGetCourts;
    })();

    api_pairing.ApiPairingCourts = (function() {

        /**
         * Properties of an ApiPairingCourts.
         * @memberof api_pairing
         * @interface IApiPairingCourts
         * @property {Array.<api_pairing.IApiPairingCourt>|null} [courts] ApiPairingCourts courts
         */

        /**
         * Constructs a new ApiPairingCourts.
         * @memberof api_pairing
         * @classdesc Represents an ApiPairingCourts.
         * @implements IApiPairingCourts
         * @constructor
         * @param {api_pairing.IApiPairingCourts=} [properties] Properties to set
         */
        function ApiPairingCourts(properties) {
            this.courts = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiPairingCourts courts.
         * @member {Array.<api_pairing.IApiPairingCourt>} courts
         * @memberof api_pairing.ApiPairingCourts
         * @instance
         */
        ApiPairingCourts.prototype.courts = $util.emptyArray;

        /**
         * Creates a new ApiPairingCourts instance using the specified properties.
         * @function create
         * @memberof api_pairing.ApiPairingCourts
         * @static
         * @param {api_pairing.IApiPairingCourts=} [properties] Properties to set
         * @returns {api_pairing.ApiPairingCourts} ApiPairingCourts instance
         */
        ApiPairingCourts.create = function create(properties) {
            return new ApiPairingCourts(properties);
        };

        /**
         * Encodes the specified ApiPairingCourts message. Does not implicitly {@link api_pairing.ApiPairingCourts.verify|verify} messages.
         * @function encode
         * @memberof api_pairing.ApiPairingCourts
         * @static
         * @param {api_pairing.IApiPairingCourts} message ApiPairingCourts message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiPairingCourts.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.courts != null && message.courts.length)
                for (let i = 0; i < message.courts.length; ++i)
                    $root.api_pairing.ApiPairingCourt.encode(message.courts[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ApiPairingCourts message, length delimited. Does not implicitly {@link api_pairing.ApiPairingCourts.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_pairing.ApiPairingCourts
         * @static
         * @param {api_pairing.IApiPairingCourts} message ApiPairingCourts message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiPairingCourts.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiPairingCourts message from the specified reader or buffer.
         * @function decode
         * @memberof api_pairing.ApiPairingCourts
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_pairing.ApiPairingCourts} ApiPairingCourts
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiPairingCourts.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_pairing.ApiPairingCourts();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.courts && message.courts.length))
                            message.courts = [];
                        message.courts.push($root.api_pairing.ApiPairingCourt.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiPairingCourts message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_pairing.ApiPairingCourts
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_pairing.ApiPairingCourts} ApiPairingCourts
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiPairingCourts.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiPairingCourts message.
         * @function verify
         * @memberof api_pairing.ApiPairingCourts
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiPairingCourts.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.courts != null && message.hasOwnProperty("courts")) {
                if (!Array.isArray(message.courts))
                    return "courts: array expected";
                for (let i = 0; i < message.courts.length; ++i) {
                    let error = $root.api_pairing.ApiPairingCourt.verify(message.courts[i]);
                    if (error)
                        return "courts." + error;
                }
            }
            return null;
        };

        /**
         * Creates an ApiPairingCourts message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_pairing.ApiPairingCourts
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_pairing.ApiPairingCourts} ApiPairingCourts
         */
        ApiPairingCourts.fromObject = function fromObject(object) {
            if (object instanceof $root.api_pairing.ApiPairingCourts)
                return object;
            let message = new $root.api_pairing.ApiPairingCourts();
            if (object.courts) {
                if (!Array.isArray(object.courts))
                    throw TypeError(".api_pairing.ApiPairingCourts.courts: array expected");
                message.courts = [];
                for (let i = 0; i < object.courts.length; ++i) {
                    if (typeof object.courts[i] !== "object")
                        throw TypeError(".api_pairing.ApiPairingCourts.courts: object expected");
                    message.courts[i] = $root.api_pairing.ApiPairingCourt.fromObject(object.courts[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from an ApiPairingCourts message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_pairing.ApiPairingCourts
         * @static
         * @param {api_pairing.ApiPairingCourts} message ApiPairingCourts
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiPairingCourts.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.courts = [];
            if (message.courts && message.courts.length) {
                object.courts = [];
                for (let j = 0; j < message.courts.length; ++j)
                    object.courts[j] = $root.api_pairing.ApiPairingCourt.toObject(message.courts[j], options);
            }
            return object;
        };

        /**
         * Converts this ApiPairingCourts to JSON.
         * @function toJSON
         * @memberof api_pairing.ApiPairingCourts
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiPairingCourts.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiPairingCourts
         * @function getTypeUrl
         * @memberof api_pairing.ApiPairingCourts
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiPairingCourts.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_pairing.ApiPairingCourts";
        };

        return ApiPairingCourts;
    })();

    api_pairing.ApiPairingCourt = (function() {

        /**
         * Properties of an ApiPairingCourt.
         * @memberof api_pairing
         * @interface IApiPairingCourt
         * @property {string|null} [id] ApiPairingCourt id
         * @property {string|null} [name] ApiPairingCourt name
         */

        /**
         * Constructs a new ApiPairingCourt.
         * @memberof api_pairing
         * @classdesc Represents an ApiPairingCourt.
         * @implements IApiPairingCourt
         * @constructor
         * @param {api_pairing.IApiPairingCourt=} [properties] Properties to set
         */
        function ApiPairingCourt(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiPairingCourt id.
         * @member {string} id
         * @memberof api_pairing.ApiPairingCourt
         * @instance
         */
        ApiPairingCourt.prototype.id = "";

        /**
         * ApiPairingCourt name.
         * @member {string} name
         * @memberof api_pairing.ApiPairingCourt
         * @instance
         */
        ApiPairingCourt.prototype.name = "";

        /**
         * Creates a new ApiPairingCourt instance using the specified properties.
         * @function create
         * @memberof api_pairing.ApiPairingCourt
         * @static
         * @param {api_pairing.IApiPairingCourt=} [properties] Properties to set
         * @returns {api_pairing.ApiPairingCourt} ApiPairingCourt instance
         */
        ApiPairingCourt.create = function create(properties) {
            return new ApiPairingCourt(properties);
        };

        /**
         * Encodes the specified ApiPairingCourt message. Does not implicitly {@link api_pairing.ApiPairingCourt.verify|verify} messages.
         * @function encode
         * @memberof api_pairing.ApiPairingCourt
         * @static
         * @param {api_pairing.IApiPairingCourt} message ApiPairingCourt message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiPairingCourt.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            return writer;
        };

        /**
         * Encodes the specified ApiPairingCourt message, length delimited. Does not implicitly {@link api_pairing.ApiPairingCourt.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_pairing.ApiPairingCourt
         * @static
         * @param {api_pairing.IApiPairingCourt} message ApiPairingCourt message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiPairingCourt.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiPairingCourt message from the specified reader or buffer.
         * @function decode
         * @memberof api_pairing.ApiPairingCourt
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_pairing.ApiPairingCourt} ApiPairingCourt
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiPairingCourt.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_pairing.ApiPairingCourt();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiPairingCourt message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_pairing.ApiPairingCourt
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_pairing.ApiPairingCourt} ApiPairingCourt
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiPairingCourt.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiPairingCourt message.
         * @function verify
         * @memberof api_pairing.ApiPairingCourt
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiPairingCourt.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            return null;
        };

        /**
         * Creates an ApiPairingCourt message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_pairing.ApiPairingCourt
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_pairing.ApiPairingCourt} ApiPairingCourt
         */
        ApiPairingCourt.fromObject = function fromObject(object) {
            if (object instanceof $root.api_pairing.ApiPairingCourt)
                return object;
            let message = new $root.api_pairing.ApiPairingCourt();
            if (object.id != null)
                message.id = String(object.id);
            if (object.name != null)
                message.name = String(object.name);
            return message;
        };

        /**
         * Creates a plain object from an ApiPairingCourt message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_pairing.ApiPairingCourt
         * @static
         * @param {api_pairing.ApiPairingCourt} message ApiPairingCourt
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiPairingCourt.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.id = "";
                object.name = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            return object;
        };

        /**
         * Converts this ApiPairingCourt to JSON.
         * @function toJSON
         * @memberof api_pairing.ApiPairingCourt
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiPairingCourt.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiPairingCourt
         * @function getTypeUrl
         * @memberof api_pairing.ApiPairingCourt
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiPairingCourt.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_pairing.ApiPairingCourt";
        };

        return ApiPairingCourt;
    })();

    api_pairing.ApiPairingGetCourtrooms = (function() {

        /**
         * Properties of an ApiPairingGetCourtrooms.
         * @memberof api_pairing
         * @interface IApiPairingGetCourtrooms
         */

        /**
         * Constructs a new ApiPairingGetCourtrooms.
         * @memberof api_pairing
         * @classdesc Represents an ApiPairingGetCourtrooms.
         * @implements IApiPairingGetCourtrooms
         * @constructor
         * @param {api_pairing.IApiPairingGetCourtrooms=} [properties] Properties to set
         */
        function ApiPairingGetCourtrooms(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new ApiPairingGetCourtrooms instance using the specified properties.
         * @function create
         * @memberof api_pairing.ApiPairingGetCourtrooms
         * @static
         * @param {api_pairing.IApiPairingGetCourtrooms=} [properties] Properties to set
         * @returns {api_pairing.ApiPairingGetCourtrooms} ApiPairingGetCourtrooms instance
         */
        ApiPairingGetCourtrooms.create = function create(properties) {
            return new ApiPairingGetCourtrooms(properties);
        };

        /**
         * Encodes the specified ApiPairingGetCourtrooms message. Does not implicitly {@link api_pairing.ApiPairingGetCourtrooms.verify|verify} messages.
         * @function encode
         * @memberof api_pairing.ApiPairingGetCourtrooms
         * @static
         * @param {api_pairing.IApiPairingGetCourtrooms} message ApiPairingGetCourtrooms message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiPairingGetCourtrooms.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified ApiPairingGetCourtrooms message, length delimited. Does not implicitly {@link api_pairing.ApiPairingGetCourtrooms.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_pairing.ApiPairingGetCourtrooms
         * @static
         * @param {api_pairing.IApiPairingGetCourtrooms} message ApiPairingGetCourtrooms message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiPairingGetCourtrooms.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiPairingGetCourtrooms message from the specified reader or buffer.
         * @function decode
         * @memberof api_pairing.ApiPairingGetCourtrooms
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_pairing.ApiPairingGetCourtrooms} ApiPairingGetCourtrooms
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiPairingGetCourtrooms.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_pairing.ApiPairingGetCourtrooms();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiPairingGetCourtrooms message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_pairing.ApiPairingGetCourtrooms
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_pairing.ApiPairingGetCourtrooms} ApiPairingGetCourtrooms
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiPairingGetCourtrooms.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiPairingGetCourtrooms message.
         * @function verify
         * @memberof api_pairing.ApiPairingGetCourtrooms
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiPairingGetCourtrooms.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an ApiPairingGetCourtrooms message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_pairing.ApiPairingGetCourtrooms
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_pairing.ApiPairingGetCourtrooms} ApiPairingGetCourtrooms
         */
        ApiPairingGetCourtrooms.fromObject = function fromObject(object) {
            if (object instanceof $root.api_pairing.ApiPairingGetCourtrooms)
                return object;
            return new $root.api_pairing.ApiPairingGetCourtrooms();
        };

        /**
         * Creates a plain object from an ApiPairingGetCourtrooms message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_pairing.ApiPairingGetCourtrooms
         * @static
         * @param {api_pairing.ApiPairingGetCourtrooms} message ApiPairingGetCourtrooms
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiPairingGetCourtrooms.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this ApiPairingGetCourtrooms to JSON.
         * @function toJSON
         * @memberof api_pairing.ApiPairingGetCourtrooms
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiPairingGetCourtrooms.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiPairingGetCourtrooms
         * @function getTypeUrl
         * @memberof api_pairing.ApiPairingGetCourtrooms
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiPairingGetCourtrooms.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_pairing.ApiPairingGetCourtrooms";
        };

        return ApiPairingGetCourtrooms;
    })();

    api_pairing.ApiPairingCourtrooms = (function() {

        /**
         * Properties of an ApiPairingCourtrooms.
         * @memberof api_pairing
         * @interface IApiPairingCourtrooms
         * @property {Array.<api_pairing.IApiPairingCourtroom>|null} [courtrooms] ApiPairingCourtrooms courtrooms
         */

        /**
         * Constructs a new ApiPairingCourtrooms.
         * @memberof api_pairing
         * @classdesc Represents an ApiPairingCourtrooms.
         * @implements IApiPairingCourtrooms
         * @constructor
         * @param {api_pairing.IApiPairingCourtrooms=} [properties] Properties to set
         */
        function ApiPairingCourtrooms(properties) {
            this.courtrooms = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiPairingCourtrooms courtrooms.
         * @member {Array.<api_pairing.IApiPairingCourtroom>} courtrooms
         * @memberof api_pairing.ApiPairingCourtrooms
         * @instance
         */
        ApiPairingCourtrooms.prototype.courtrooms = $util.emptyArray;

        /**
         * Creates a new ApiPairingCourtrooms instance using the specified properties.
         * @function create
         * @memberof api_pairing.ApiPairingCourtrooms
         * @static
         * @param {api_pairing.IApiPairingCourtrooms=} [properties] Properties to set
         * @returns {api_pairing.ApiPairingCourtrooms} ApiPairingCourtrooms instance
         */
        ApiPairingCourtrooms.create = function create(properties) {
            return new ApiPairingCourtrooms(properties);
        };

        /**
         * Encodes the specified ApiPairingCourtrooms message. Does not implicitly {@link api_pairing.ApiPairingCourtrooms.verify|verify} messages.
         * @function encode
         * @memberof api_pairing.ApiPairingCourtrooms
         * @static
         * @param {api_pairing.IApiPairingCourtrooms} message ApiPairingCourtrooms message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiPairingCourtrooms.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.courtrooms != null && message.courtrooms.length)
                for (let i = 0; i < message.courtrooms.length; ++i)
                    $root.api_pairing.ApiPairingCourtroom.encode(message.courtrooms[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ApiPairingCourtrooms message, length delimited. Does not implicitly {@link api_pairing.ApiPairingCourtrooms.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_pairing.ApiPairingCourtrooms
         * @static
         * @param {api_pairing.IApiPairingCourtrooms} message ApiPairingCourtrooms message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiPairingCourtrooms.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiPairingCourtrooms message from the specified reader or buffer.
         * @function decode
         * @memberof api_pairing.ApiPairingCourtrooms
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_pairing.ApiPairingCourtrooms} ApiPairingCourtrooms
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiPairingCourtrooms.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_pairing.ApiPairingCourtrooms();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.courtrooms && message.courtrooms.length))
                            message.courtrooms = [];
                        message.courtrooms.push($root.api_pairing.ApiPairingCourtroom.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiPairingCourtrooms message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_pairing.ApiPairingCourtrooms
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_pairing.ApiPairingCourtrooms} ApiPairingCourtrooms
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiPairingCourtrooms.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiPairingCourtrooms message.
         * @function verify
         * @memberof api_pairing.ApiPairingCourtrooms
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiPairingCourtrooms.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.courtrooms != null && message.hasOwnProperty("courtrooms")) {
                if (!Array.isArray(message.courtrooms))
                    return "courtrooms: array expected";
                for (let i = 0; i < message.courtrooms.length; ++i) {
                    let error = $root.api_pairing.ApiPairingCourtroom.verify(message.courtrooms[i]);
                    if (error)
                        return "courtrooms." + error;
                }
            }
            return null;
        };

        /**
         * Creates an ApiPairingCourtrooms message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_pairing.ApiPairingCourtrooms
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_pairing.ApiPairingCourtrooms} ApiPairingCourtrooms
         */
        ApiPairingCourtrooms.fromObject = function fromObject(object) {
            if (object instanceof $root.api_pairing.ApiPairingCourtrooms)
                return object;
            let message = new $root.api_pairing.ApiPairingCourtrooms();
            if (object.courtrooms) {
                if (!Array.isArray(object.courtrooms))
                    throw TypeError(".api_pairing.ApiPairingCourtrooms.courtrooms: array expected");
                message.courtrooms = [];
                for (let i = 0; i < object.courtrooms.length; ++i) {
                    if (typeof object.courtrooms[i] !== "object")
                        throw TypeError(".api_pairing.ApiPairingCourtrooms.courtrooms: object expected");
                    message.courtrooms[i] = $root.api_pairing.ApiPairingCourtroom.fromObject(object.courtrooms[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from an ApiPairingCourtrooms message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_pairing.ApiPairingCourtrooms
         * @static
         * @param {api_pairing.ApiPairingCourtrooms} message ApiPairingCourtrooms
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiPairingCourtrooms.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.courtrooms = [];
            if (message.courtrooms && message.courtrooms.length) {
                object.courtrooms = [];
                for (let j = 0; j < message.courtrooms.length; ++j)
                    object.courtrooms[j] = $root.api_pairing.ApiPairingCourtroom.toObject(message.courtrooms[j], options);
            }
            return object;
        };

        /**
         * Converts this ApiPairingCourtrooms to JSON.
         * @function toJSON
         * @memberof api_pairing.ApiPairingCourtrooms
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiPairingCourtrooms.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiPairingCourtrooms
         * @function getTypeUrl
         * @memberof api_pairing.ApiPairingCourtrooms
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiPairingCourtrooms.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_pairing.ApiPairingCourtrooms";
        };

        return ApiPairingCourtrooms;
    })();

    api_pairing.ApiPairingCourtroom = (function() {

        /**
         * Properties of an ApiPairingCourtroom.
         * @memberof api_pairing
         * @interface IApiPairingCourtroom
         * @property {string|null} [id] ApiPairingCourtroom id
         * @property {string|null} [courtId] ApiPairingCourtroom courtId
         * @property {string|null} [title] ApiPairingCourtroom title
         */

        /**
         * Constructs a new ApiPairingCourtroom.
         * @memberof api_pairing
         * @classdesc Represents an ApiPairingCourtroom.
         * @implements IApiPairingCourtroom
         * @constructor
         * @param {api_pairing.IApiPairingCourtroom=} [properties] Properties to set
         */
        function ApiPairingCourtroom(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiPairingCourtroom id.
         * @member {string} id
         * @memberof api_pairing.ApiPairingCourtroom
         * @instance
         */
        ApiPairingCourtroom.prototype.id = "";

        /**
         * ApiPairingCourtroom courtId.
         * @member {string} courtId
         * @memberof api_pairing.ApiPairingCourtroom
         * @instance
         */
        ApiPairingCourtroom.prototype.courtId = "";

        /**
         * ApiPairingCourtroom title.
         * @member {string} title
         * @memberof api_pairing.ApiPairingCourtroom
         * @instance
         */
        ApiPairingCourtroom.prototype.title = "";

        /**
         * Creates a new ApiPairingCourtroom instance using the specified properties.
         * @function create
         * @memberof api_pairing.ApiPairingCourtroom
         * @static
         * @param {api_pairing.IApiPairingCourtroom=} [properties] Properties to set
         * @returns {api_pairing.ApiPairingCourtroom} ApiPairingCourtroom instance
         */
        ApiPairingCourtroom.create = function create(properties) {
            return new ApiPairingCourtroom(properties);
        };

        /**
         * Encodes the specified ApiPairingCourtroom message. Does not implicitly {@link api_pairing.ApiPairingCourtroom.verify|verify} messages.
         * @function encode
         * @memberof api_pairing.ApiPairingCourtroom
         * @static
         * @param {api_pairing.IApiPairingCourtroom} message ApiPairingCourtroom message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiPairingCourtroom.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.courtId != null && Object.hasOwnProperty.call(message, "courtId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.courtId);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.title);
            return writer;
        };

        /**
         * Encodes the specified ApiPairingCourtroom message, length delimited. Does not implicitly {@link api_pairing.ApiPairingCourtroom.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_pairing.ApiPairingCourtroom
         * @static
         * @param {api_pairing.IApiPairingCourtroom} message ApiPairingCourtroom message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiPairingCourtroom.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiPairingCourtroom message from the specified reader or buffer.
         * @function decode
         * @memberof api_pairing.ApiPairingCourtroom
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_pairing.ApiPairingCourtroom} ApiPairingCourtroom
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiPairingCourtroom.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_pairing.ApiPairingCourtroom();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 2: {
                        message.courtId = reader.string();
                        break;
                    }
                case 3: {
                        message.title = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiPairingCourtroom message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_pairing.ApiPairingCourtroom
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_pairing.ApiPairingCourtroom} ApiPairingCourtroom
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiPairingCourtroom.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiPairingCourtroom message.
         * @function verify
         * @memberof api_pairing.ApiPairingCourtroom
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiPairingCourtroom.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.courtId != null && message.hasOwnProperty("courtId"))
                if (!$util.isString(message.courtId))
                    return "courtId: string expected";
            if (message.title != null && message.hasOwnProperty("title"))
                if (!$util.isString(message.title))
                    return "title: string expected";
            return null;
        };

        /**
         * Creates an ApiPairingCourtroom message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_pairing.ApiPairingCourtroom
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_pairing.ApiPairingCourtroom} ApiPairingCourtroom
         */
        ApiPairingCourtroom.fromObject = function fromObject(object) {
            if (object instanceof $root.api_pairing.ApiPairingCourtroom)
                return object;
            let message = new $root.api_pairing.ApiPairingCourtroom();
            if (object.id != null)
                message.id = String(object.id);
            if (object.courtId != null)
                message.courtId = String(object.courtId);
            if (object.title != null)
                message.title = String(object.title);
            return message;
        };

        /**
         * Creates a plain object from an ApiPairingCourtroom message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_pairing.ApiPairingCourtroom
         * @static
         * @param {api_pairing.ApiPairingCourtroom} message ApiPairingCourtroom
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiPairingCourtroom.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.id = "";
                object.courtId = "";
                object.title = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.courtId != null && message.hasOwnProperty("courtId"))
                object.courtId = message.courtId;
            if (message.title != null && message.hasOwnProperty("title"))
                object.title = message.title;
            return object;
        };

        /**
         * Converts this ApiPairingCourtroom to JSON.
         * @function toJSON
         * @memberof api_pairing.ApiPairingCourtroom
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiPairingCourtroom.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiPairingCourtroom
         * @function getTypeUrl
         * @memberof api_pairing.ApiPairingCourtroom
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiPairingCourtroom.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_pairing.ApiPairingCourtroom";
        };

        return ApiPairingCourtroom;
    })();

    return api_pairing;
})();

export const asod_recorder_audio = $root.asod_recorder_audio = (() => {

    /**
     * Namespace asod_recorder_audio.
     * @exports asod_recorder_audio
     * @namespace
     */
    const asod_recorder_audio = {};

    asod_recorder_audio.AsodRecorderAudioChannels = (function() {

        /**
         * Properties of an AsodRecorderAudioChannels.
         * @memberof asod_recorder_audio
         * @interface IAsodRecorderAudioChannels
         * @property {Array.<asod_recorder_audio.IAsodRecorderAudioChannel>|null} [channels] AsodRecorderAudioChannels channels
         */

        /**
         * Constructs a new AsodRecorderAudioChannels.
         * @memberof asod_recorder_audio
         * @classdesc Represents an AsodRecorderAudioChannels.
         * @implements IAsodRecorderAudioChannels
         * @constructor
         * @param {asod_recorder_audio.IAsodRecorderAudioChannels=} [properties] Properties to set
         */
        function AsodRecorderAudioChannels(properties) {
            this.channels = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AsodRecorderAudioChannels channels.
         * @member {Array.<asod_recorder_audio.IAsodRecorderAudioChannel>} channels
         * @memberof asod_recorder_audio.AsodRecorderAudioChannels
         * @instance
         */
        AsodRecorderAudioChannels.prototype.channels = $util.emptyArray;

        /**
         * Creates a new AsodRecorderAudioChannels instance using the specified properties.
         * @function create
         * @memberof asod_recorder_audio.AsodRecorderAudioChannels
         * @static
         * @param {asod_recorder_audio.IAsodRecorderAudioChannels=} [properties] Properties to set
         * @returns {asod_recorder_audio.AsodRecorderAudioChannels} AsodRecorderAudioChannels instance
         */
        AsodRecorderAudioChannels.create = function create(properties) {
            return new AsodRecorderAudioChannels(properties);
        };

        /**
         * Encodes the specified AsodRecorderAudioChannels message. Does not implicitly {@link asod_recorder_audio.AsodRecorderAudioChannels.verify|verify} messages.
         * @function encode
         * @memberof asod_recorder_audio.AsodRecorderAudioChannels
         * @static
         * @param {asod_recorder_audio.IAsodRecorderAudioChannels} message AsodRecorderAudioChannels message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodRecorderAudioChannels.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.channels != null && message.channels.length)
                for (let i = 0; i < message.channels.length; ++i)
                    $root.asod_recorder_audio.AsodRecorderAudioChannel.encode(message.channels[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified AsodRecorderAudioChannels message, length delimited. Does not implicitly {@link asod_recorder_audio.AsodRecorderAudioChannels.verify|verify} messages.
         * @function encodeDelimited
         * @memberof asod_recorder_audio.AsodRecorderAudioChannels
         * @static
         * @param {asod_recorder_audio.IAsodRecorderAudioChannels} message AsodRecorderAudioChannels message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodRecorderAudioChannels.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AsodRecorderAudioChannels message from the specified reader or buffer.
         * @function decode
         * @memberof asod_recorder_audio.AsodRecorderAudioChannels
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {asod_recorder_audio.AsodRecorderAudioChannels} AsodRecorderAudioChannels
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodRecorderAudioChannels.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.asod_recorder_audio.AsodRecorderAudioChannels();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.channels && message.channels.length))
                            message.channels = [];
                        message.channels.push($root.asod_recorder_audio.AsodRecorderAudioChannel.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AsodRecorderAudioChannels message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof asod_recorder_audio.AsodRecorderAudioChannels
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {asod_recorder_audio.AsodRecorderAudioChannels} AsodRecorderAudioChannels
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodRecorderAudioChannels.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AsodRecorderAudioChannels message.
         * @function verify
         * @memberof asod_recorder_audio.AsodRecorderAudioChannels
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AsodRecorderAudioChannels.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.channels != null && message.hasOwnProperty("channels")) {
                if (!Array.isArray(message.channels))
                    return "channels: array expected";
                for (let i = 0; i < message.channels.length; ++i) {
                    let error = $root.asod_recorder_audio.AsodRecorderAudioChannel.verify(message.channels[i]);
                    if (error)
                        return "channels." + error;
                }
            }
            return null;
        };

        /**
         * Creates an AsodRecorderAudioChannels message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof asod_recorder_audio.AsodRecorderAudioChannels
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {asod_recorder_audio.AsodRecorderAudioChannels} AsodRecorderAudioChannels
         */
        AsodRecorderAudioChannels.fromObject = function fromObject(object) {
            if (object instanceof $root.asod_recorder_audio.AsodRecorderAudioChannels)
                return object;
            let message = new $root.asod_recorder_audio.AsodRecorderAudioChannels();
            if (object.channels) {
                if (!Array.isArray(object.channels))
                    throw TypeError(".asod_recorder_audio.AsodRecorderAudioChannels.channels: array expected");
                message.channels = [];
                for (let i = 0; i < object.channels.length; ++i) {
                    if (typeof object.channels[i] !== "object")
                        throw TypeError(".asod_recorder_audio.AsodRecorderAudioChannels.channels: object expected");
                    message.channels[i] = $root.asod_recorder_audio.AsodRecorderAudioChannel.fromObject(object.channels[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from an AsodRecorderAudioChannels message. Also converts values to other types if specified.
         * @function toObject
         * @memberof asod_recorder_audio.AsodRecorderAudioChannels
         * @static
         * @param {asod_recorder_audio.AsodRecorderAudioChannels} message AsodRecorderAudioChannels
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AsodRecorderAudioChannels.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.channels = [];
            if (message.channels && message.channels.length) {
                object.channels = [];
                for (let j = 0; j < message.channels.length; ++j)
                    object.channels[j] = $root.asod_recorder_audio.AsodRecorderAudioChannel.toObject(message.channels[j], options);
            }
            return object;
        };

        /**
         * Converts this AsodRecorderAudioChannels to JSON.
         * @function toJSON
         * @memberof asod_recorder_audio.AsodRecorderAudioChannels
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AsodRecorderAudioChannels.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AsodRecorderAudioChannels
         * @function getTypeUrl
         * @memberof asod_recorder_audio.AsodRecorderAudioChannels
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AsodRecorderAudioChannels.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/asod_recorder_audio.AsodRecorderAudioChannels";
        };

        return AsodRecorderAudioChannels;
    })();

    asod_recorder_audio.AsodRecorderAudioDevices = (function() {

        /**
         * Properties of an AsodRecorderAudioDevices.
         * @memberof asod_recorder_audio
         * @interface IAsodRecorderAudioDevices
         * @property {Array.<asod_recorder_audio.IAsodRecorderAudioDevice>|null} [devices] AsodRecorderAudioDevices devices
         */

        /**
         * Constructs a new AsodRecorderAudioDevices.
         * @memberof asod_recorder_audio
         * @classdesc Represents an AsodRecorderAudioDevices.
         * @implements IAsodRecorderAudioDevices
         * @constructor
         * @param {asod_recorder_audio.IAsodRecorderAudioDevices=} [properties] Properties to set
         */
        function AsodRecorderAudioDevices(properties) {
            this.devices = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AsodRecorderAudioDevices devices.
         * @member {Array.<asod_recorder_audio.IAsodRecorderAudioDevice>} devices
         * @memberof asod_recorder_audio.AsodRecorderAudioDevices
         * @instance
         */
        AsodRecorderAudioDevices.prototype.devices = $util.emptyArray;

        /**
         * Creates a new AsodRecorderAudioDevices instance using the specified properties.
         * @function create
         * @memberof asod_recorder_audio.AsodRecorderAudioDevices
         * @static
         * @param {asod_recorder_audio.IAsodRecorderAudioDevices=} [properties] Properties to set
         * @returns {asod_recorder_audio.AsodRecorderAudioDevices} AsodRecorderAudioDevices instance
         */
        AsodRecorderAudioDevices.create = function create(properties) {
            return new AsodRecorderAudioDevices(properties);
        };

        /**
         * Encodes the specified AsodRecorderAudioDevices message. Does not implicitly {@link asod_recorder_audio.AsodRecorderAudioDevices.verify|verify} messages.
         * @function encode
         * @memberof asod_recorder_audio.AsodRecorderAudioDevices
         * @static
         * @param {asod_recorder_audio.IAsodRecorderAudioDevices} message AsodRecorderAudioDevices message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodRecorderAudioDevices.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.devices != null && message.devices.length)
                for (let i = 0; i < message.devices.length; ++i)
                    $root.asod_recorder_audio.AsodRecorderAudioDevice.encode(message.devices[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified AsodRecorderAudioDevices message, length delimited. Does not implicitly {@link asod_recorder_audio.AsodRecorderAudioDevices.verify|verify} messages.
         * @function encodeDelimited
         * @memberof asod_recorder_audio.AsodRecorderAudioDevices
         * @static
         * @param {asod_recorder_audio.IAsodRecorderAudioDevices} message AsodRecorderAudioDevices message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodRecorderAudioDevices.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AsodRecorderAudioDevices message from the specified reader or buffer.
         * @function decode
         * @memberof asod_recorder_audio.AsodRecorderAudioDevices
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {asod_recorder_audio.AsodRecorderAudioDevices} AsodRecorderAudioDevices
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodRecorderAudioDevices.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.asod_recorder_audio.AsodRecorderAudioDevices();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.devices && message.devices.length))
                            message.devices = [];
                        message.devices.push($root.asod_recorder_audio.AsodRecorderAudioDevice.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AsodRecorderAudioDevices message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof asod_recorder_audio.AsodRecorderAudioDevices
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {asod_recorder_audio.AsodRecorderAudioDevices} AsodRecorderAudioDevices
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodRecorderAudioDevices.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AsodRecorderAudioDevices message.
         * @function verify
         * @memberof asod_recorder_audio.AsodRecorderAudioDevices
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AsodRecorderAudioDevices.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.devices != null && message.hasOwnProperty("devices")) {
                if (!Array.isArray(message.devices))
                    return "devices: array expected";
                for (let i = 0; i < message.devices.length; ++i) {
                    let error = $root.asod_recorder_audio.AsodRecorderAudioDevice.verify(message.devices[i]);
                    if (error)
                        return "devices." + error;
                }
            }
            return null;
        };

        /**
         * Creates an AsodRecorderAudioDevices message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof asod_recorder_audio.AsodRecorderAudioDevices
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {asod_recorder_audio.AsodRecorderAudioDevices} AsodRecorderAudioDevices
         */
        AsodRecorderAudioDevices.fromObject = function fromObject(object) {
            if (object instanceof $root.asod_recorder_audio.AsodRecorderAudioDevices)
                return object;
            let message = new $root.asod_recorder_audio.AsodRecorderAudioDevices();
            if (object.devices) {
                if (!Array.isArray(object.devices))
                    throw TypeError(".asod_recorder_audio.AsodRecorderAudioDevices.devices: array expected");
                message.devices = [];
                for (let i = 0; i < object.devices.length; ++i) {
                    if (typeof object.devices[i] !== "object")
                        throw TypeError(".asod_recorder_audio.AsodRecorderAudioDevices.devices: object expected");
                    message.devices[i] = $root.asod_recorder_audio.AsodRecorderAudioDevice.fromObject(object.devices[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from an AsodRecorderAudioDevices message. Also converts values to other types if specified.
         * @function toObject
         * @memberof asod_recorder_audio.AsodRecorderAudioDevices
         * @static
         * @param {asod_recorder_audio.AsodRecorderAudioDevices} message AsodRecorderAudioDevices
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AsodRecorderAudioDevices.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.devices = [];
            if (message.devices && message.devices.length) {
                object.devices = [];
                for (let j = 0; j < message.devices.length; ++j)
                    object.devices[j] = $root.asod_recorder_audio.AsodRecorderAudioDevice.toObject(message.devices[j], options);
            }
            return object;
        };

        /**
         * Converts this AsodRecorderAudioDevices to JSON.
         * @function toJSON
         * @memberof asod_recorder_audio.AsodRecorderAudioDevices
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AsodRecorderAudioDevices.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AsodRecorderAudioDevices
         * @function getTypeUrl
         * @memberof asod_recorder_audio.AsodRecorderAudioDevices
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AsodRecorderAudioDevices.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/asod_recorder_audio.AsodRecorderAudioDevices";
        };

        return AsodRecorderAudioDevices;
    })();

    asod_recorder_audio.AsodRecorderAudioToggleRecording = (function() {

        /**
         * Properties of an AsodRecorderAudioToggleRecording.
         * @memberof asod_recorder_audio
         * @interface IAsodRecorderAudioToggleRecording
         */

        /**
         * Constructs a new AsodRecorderAudioToggleRecording.
         * @memberof asod_recorder_audio
         * @classdesc Represents an AsodRecorderAudioToggleRecording.
         * @implements IAsodRecorderAudioToggleRecording
         * @constructor
         * @param {asod_recorder_audio.IAsodRecorderAudioToggleRecording=} [properties] Properties to set
         */
        function AsodRecorderAudioToggleRecording(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new AsodRecorderAudioToggleRecording instance using the specified properties.
         * @function create
         * @memberof asod_recorder_audio.AsodRecorderAudioToggleRecording
         * @static
         * @param {asod_recorder_audio.IAsodRecorderAudioToggleRecording=} [properties] Properties to set
         * @returns {asod_recorder_audio.AsodRecorderAudioToggleRecording} AsodRecorderAudioToggleRecording instance
         */
        AsodRecorderAudioToggleRecording.create = function create(properties) {
            return new AsodRecorderAudioToggleRecording(properties);
        };

        /**
         * Encodes the specified AsodRecorderAudioToggleRecording message. Does not implicitly {@link asod_recorder_audio.AsodRecorderAudioToggleRecording.verify|verify} messages.
         * @function encode
         * @memberof asod_recorder_audio.AsodRecorderAudioToggleRecording
         * @static
         * @param {asod_recorder_audio.IAsodRecorderAudioToggleRecording} message AsodRecorderAudioToggleRecording message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodRecorderAudioToggleRecording.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified AsodRecorderAudioToggleRecording message, length delimited. Does not implicitly {@link asod_recorder_audio.AsodRecorderAudioToggleRecording.verify|verify} messages.
         * @function encodeDelimited
         * @memberof asod_recorder_audio.AsodRecorderAudioToggleRecording
         * @static
         * @param {asod_recorder_audio.IAsodRecorderAudioToggleRecording} message AsodRecorderAudioToggleRecording message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodRecorderAudioToggleRecording.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AsodRecorderAudioToggleRecording message from the specified reader or buffer.
         * @function decode
         * @memberof asod_recorder_audio.AsodRecorderAudioToggleRecording
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {asod_recorder_audio.AsodRecorderAudioToggleRecording} AsodRecorderAudioToggleRecording
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodRecorderAudioToggleRecording.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.asod_recorder_audio.AsodRecorderAudioToggleRecording();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AsodRecorderAudioToggleRecording message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof asod_recorder_audio.AsodRecorderAudioToggleRecording
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {asod_recorder_audio.AsodRecorderAudioToggleRecording} AsodRecorderAudioToggleRecording
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodRecorderAudioToggleRecording.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AsodRecorderAudioToggleRecording message.
         * @function verify
         * @memberof asod_recorder_audio.AsodRecorderAudioToggleRecording
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AsodRecorderAudioToggleRecording.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an AsodRecorderAudioToggleRecording message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof asod_recorder_audio.AsodRecorderAudioToggleRecording
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {asod_recorder_audio.AsodRecorderAudioToggleRecording} AsodRecorderAudioToggleRecording
         */
        AsodRecorderAudioToggleRecording.fromObject = function fromObject(object) {
            if (object instanceof $root.asod_recorder_audio.AsodRecorderAudioToggleRecording)
                return object;
            return new $root.asod_recorder_audio.AsodRecorderAudioToggleRecording();
        };

        /**
         * Creates a plain object from an AsodRecorderAudioToggleRecording message. Also converts values to other types if specified.
         * @function toObject
         * @memberof asod_recorder_audio.AsodRecorderAudioToggleRecording
         * @static
         * @param {asod_recorder_audio.AsodRecorderAudioToggleRecording} message AsodRecorderAudioToggleRecording
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AsodRecorderAudioToggleRecording.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this AsodRecorderAudioToggleRecording to JSON.
         * @function toJSON
         * @memberof asod_recorder_audio.AsodRecorderAudioToggleRecording
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AsodRecorderAudioToggleRecording.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AsodRecorderAudioToggleRecording
         * @function getTypeUrl
         * @memberof asod_recorder_audio.AsodRecorderAudioToggleRecording
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AsodRecorderAudioToggleRecording.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/asod_recorder_audio.AsodRecorderAudioToggleRecording";
        };

        return AsodRecorderAudioToggleRecording;
    })();

    asod_recorder_audio.AsodRecorderAudioChannel = (function() {

        /**
         * Properties of an AsodRecorderAudioChannel.
         * @memberof asod_recorder_audio
         * @interface IAsodRecorderAudioChannel
         * @property {number|null} [number] AsodRecorderAudioChannel number
         * @property {asod_recorder_audio.AsodRecorderAudioChannelStatus|null} [status] AsodRecorderAudioChannel status
         * @property {number|null} [level] AsodRecorderAudioChannel level
         */

        /**
         * Constructs a new AsodRecorderAudioChannel.
         * @memberof asod_recorder_audio
         * @classdesc Represents an AsodRecorderAudioChannel.
         * @implements IAsodRecorderAudioChannel
         * @constructor
         * @param {asod_recorder_audio.IAsodRecorderAudioChannel=} [properties] Properties to set
         */
        function AsodRecorderAudioChannel(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AsodRecorderAudioChannel number.
         * @member {number} number
         * @memberof asod_recorder_audio.AsodRecorderAudioChannel
         * @instance
         */
        AsodRecorderAudioChannel.prototype.number = 0;

        /**
         * AsodRecorderAudioChannel status.
         * @member {asod_recorder_audio.AsodRecorderAudioChannelStatus} status
         * @memberof asod_recorder_audio.AsodRecorderAudioChannel
         * @instance
         */
        AsodRecorderAudioChannel.prototype.status = 0;

        /**
         * AsodRecorderAudioChannel level.
         * @member {number} level
         * @memberof asod_recorder_audio.AsodRecorderAudioChannel
         * @instance
         */
        AsodRecorderAudioChannel.prototype.level = 0;

        /**
         * Creates a new AsodRecorderAudioChannel instance using the specified properties.
         * @function create
         * @memberof asod_recorder_audio.AsodRecorderAudioChannel
         * @static
         * @param {asod_recorder_audio.IAsodRecorderAudioChannel=} [properties] Properties to set
         * @returns {asod_recorder_audio.AsodRecorderAudioChannel} AsodRecorderAudioChannel instance
         */
        AsodRecorderAudioChannel.create = function create(properties) {
            return new AsodRecorderAudioChannel(properties);
        };

        /**
         * Encodes the specified AsodRecorderAudioChannel message. Does not implicitly {@link asod_recorder_audio.AsodRecorderAudioChannel.verify|verify} messages.
         * @function encode
         * @memberof asod_recorder_audio.AsodRecorderAudioChannel
         * @static
         * @param {asod_recorder_audio.IAsodRecorderAudioChannel} message AsodRecorderAudioChannel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodRecorderAudioChannel.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.number != null && Object.hasOwnProperty.call(message, "number"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.number);
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.status);
            if (message.level != null && Object.hasOwnProperty.call(message, "level"))
                writer.uint32(/* id 3, wireType 1 =*/25).double(message.level);
            return writer;
        };

        /**
         * Encodes the specified AsodRecorderAudioChannel message, length delimited. Does not implicitly {@link asod_recorder_audio.AsodRecorderAudioChannel.verify|verify} messages.
         * @function encodeDelimited
         * @memberof asod_recorder_audio.AsodRecorderAudioChannel
         * @static
         * @param {asod_recorder_audio.IAsodRecorderAudioChannel} message AsodRecorderAudioChannel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodRecorderAudioChannel.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AsodRecorderAudioChannel message from the specified reader or buffer.
         * @function decode
         * @memberof asod_recorder_audio.AsodRecorderAudioChannel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {asod_recorder_audio.AsodRecorderAudioChannel} AsodRecorderAudioChannel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodRecorderAudioChannel.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.asod_recorder_audio.AsodRecorderAudioChannel();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.number = reader.int32();
                        break;
                    }
                case 2: {
                        message.status = reader.int32();
                        break;
                    }
                case 3: {
                        message.level = reader.double();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AsodRecorderAudioChannel message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof asod_recorder_audio.AsodRecorderAudioChannel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {asod_recorder_audio.AsodRecorderAudioChannel} AsodRecorderAudioChannel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodRecorderAudioChannel.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AsodRecorderAudioChannel message.
         * @function verify
         * @memberof asod_recorder_audio.AsodRecorderAudioChannel
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AsodRecorderAudioChannel.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.number != null && message.hasOwnProperty("number"))
                if (!$util.isInteger(message.number))
                    return "number: integer expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            if (message.level != null && message.hasOwnProperty("level"))
                if (typeof message.level !== "number")
                    return "level: number expected";
            return null;
        };

        /**
         * Creates an AsodRecorderAudioChannel message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof asod_recorder_audio.AsodRecorderAudioChannel
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {asod_recorder_audio.AsodRecorderAudioChannel} AsodRecorderAudioChannel
         */
        AsodRecorderAudioChannel.fromObject = function fromObject(object) {
            if (object instanceof $root.asod_recorder_audio.AsodRecorderAudioChannel)
                return object;
            let message = new $root.asod_recorder_audio.AsodRecorderAudioChannel();
            if (object.number != null)
                message.number = object.number | 0;
            switch (object.status) {
            default:
                if (typeof object.status === "number") {
                    message.status = object.status;
                    break;
                }
                break;
            case "aracs_unknown":
            case 0:
                message.status = 0;
                break;
            case "aracs_muted":
            case 1:
                message.status = 1;
                break;
            case "aracs_low_volume":
            case 2:
                message.status = 2;
                break;
            case "aracs_active":
            case 3:
                message.status = 3;
                break;
            }
            if (object.level != null)
                message.level = Number(object.level);
            return message;
        };

        /**
         * Creates a plain object from an AsodRecorderAudioChannel message. Also converts values to other types if specified.
         * @function toObject
         * @memberof asod_recorder_audio.AsodRecorderAudioChannel
         * @static
         * @param {asod_recorder_audio.AsodRecorderAudioChannel} message AsodRecorderAudioChannel
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AsodRecorderAudioChannel.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.number = 0;
                object.status = options.enums === String ? "aracs_unknown" : 0;
                object.level = 0;
            }
            if (message.number != null && message.hasOwnProperty("number"))
                object.number = message.number;
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.asod_recorder_audio.AsodRecorderAudioChannelStatus[message.status] === undefined ? message.status : $root.asod_recorder_audio.AsodRecorderAudioChannelStatus[message.status] : message.status;
            if (message.level != null && message.hasOwnProperty("level"))
                object.level = options.json && !isFinite(message.level) ? String(message.level) : message.level;
            return object;
        };

        /**
         * Converts this AsodRecorderAudioChannel to JSON.
         * @function toJSON
         * @memberof asod_recorder_audio.AsodRecorderAudioChannel
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AsodRecorderAudioChannel.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AsodRecorderAudioChannel
         * @function getTypeUrl
         * @memberof asod_recorder_audio.AsodRecorderAudioChannel
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AsodRecorderAudioChannel.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/asod_recorder_audio.AsodRecorderAudioChannel";
        };

        return AsodRecorderAudioChannel;
    })();

    asod_recorder_audio.AsodRecorderAudioDevice = (function() {

        /**
         * Properties of an AsodRecorderAudioDevice.
         * @memberof asod_recorder_audio
         * @interface IAsodRecorderAudioDevice
         * @property {number|null} [id] AsodRecorderAudioDevice id
         * @property {string|null} [name] AsodRecorderAudioDevice name
         * @property {number|null} [maxInputChannels] AsodRecorderAudioDevice maxInputChannels
         * @property {number|null} [defaultSamplerate] AsodRecorderAudioDevice defaultSamplerate
         * @property {boolean|null} [selected] AsodRecorderAudioDevice selected
         */

        /**
         * Constructs a new AsodRecorderAudioDevice.
         * @memberof asod_recorder_audio
         * @classdesc Represents an AsodRecorderAudioDevice.
         * @implements IAsodRecorderAudioDevice
         * @constructor
         * @param {asod_recorder_audio.IAsodRecorderAudioDevice=} [properties] Properties to set
         */
        function AsodRecorderAudioDevice(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AsodRecorderAudioDevice id.
         * @member {number} id
         * @memberof asod_recorder_audio.AsodRecorderAudioDevice
         * @instance
         */
        AsodRecorderAudioDevice.prototype.id = 0;

        /**
         * AsodRecorderAudioDevice name.
         * @member {string} name
         * @memberof asod_recorder_audio.AsodRecorderAudioDevice
         * @instance
         */
        AsodRecorderAudioDevice.prototype.name = "";

        /**
         * AsodRecorderAudioDevice maxInputChannels.
         * @member {number} maxInputChannels
         * @memberof asod_recorder_audio.AsodRecorderAudioDevice
         * @instance
         */
        AsodRecorderAudioDevice.prototype.maxInputChannels = 0;

        /**
         * AsodRecorderAudioDevice defaultSamplerate.
         * @member {number} defaultSamplerate
         * @memberof asod_recorder_audio.AsodRecorderAudioDevice
         * @instance
         */
        AsodRecorderAudioDevice.prototype.defaultSamplerate = 0;

        /**
         * AsodRecorderAudioDevice selected.
         * @member {boolean} selected
         * @memberof asod_recorder_audio.AsodRecorderAudioDevice
         * @instance
         */
        AsodRecorderAudioDevice.prototype.selected = false;

        /**
         * Creates a new AsodRecorderAudioDevice instance using the specified properties.
         * @function create
         * @memberof asod_recorder_audio.AsodRecorderAudioDevice
         * @static
         * @param {asod_recorder_audio.IAsodRecorderAudioDevice=} [properties] Properties to set
         * @returns {asod_recorder_audio.AsodRecorderAudioDevice} AsodRecorderAudioDevice instance
         */
        AsodRecorderAudioDevice.create = function create(properties) {
            return new AsodRecorderAudioDevice(properties);
        };

        /**
         * Encodes the specified AsodRecorderAudioDevice message. Does not implicitly {@link asod_recorder_audio.AsodRecorderAudioDevice.verify|verify} messages.
         * @function encode
         * @memberof asod_recorder_audio.AsodRecorderAudioDevice
         * @static
         * @param {asod_recorder_audio.IAsodRecorderAudioDevice} message AsodRecorderAudioDevice message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodRecorderAudioDevice.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.maxInputChannels != null && Object.hasOwnProperty.call(message, "maxInputChannels"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.maxInputChannels);
            if (message.defaultSamplerate != null && Object.hasOwnProperty.call(message, "defaultSamplerate"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.defaultSamplerate);
            if (message.selected != null && Object.hasOwnProperty.call(message, "selected"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.selected);
            return writer;
        };

        /**
         * Encodes the specified AsodRecorderAudioDevice message, length delimited. Does not implicitly {@link asod_recorder_audio.AsodRecorderAudioDevice.verify|verify} messages.
         * @function encodeDelimited
         * @memberof asod_recorder_audio.AsodRecorderAudioDevice
         * @static
         * @param {asod_recorder_audio.IAsodRecorderAudioDevice} message AsodRecorderAudioDevice message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodRecorderAudioDevice.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AsodRecorderAudioDevice message from the specified reader or buffer.
         * @function decode
         * @memberof asod_recorder_audio.AsodRecorderAudioDevice
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {asod_recorder_audio.AsodRecorderAudioDevice} AsodRecorderAudioDevice
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodRecorderAudioDevice.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.asod_recorder_audio.AsodRecorderAudioDevice();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.int32();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                case 3: {
                        message.maxInputChannels = reader.int32();
                        break;
                    }
                case 4: {
                        message.defaultSamplerate = reader.int32();
                        break;
                    }
                case 5: {
                        message.selected = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AsodRecorderAudioDevice message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof asod_recorder_audio.AsodRecorderAudioDevice
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {asod_recorder_audio.AsodRecorderAudioDevice} AsodRecorderAudioDevice
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodRecorderAudioDevice.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AsodRecorderAudioDevice message.
         * @function verify
         * @memberof asod_recorder_audio.AsodRecorderAudioDevice
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AsodRecorderAudioDevice.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isInteger(message.id))
                    return "id: integer expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.maxInputChannels != null && message.hasOwnProperty("maxInputChannels"))
                if (!$util.isInteger(message.maxInputChannels))
                    return "maxInputChannels: integer expected";
            if (message.defaultSamplerate != null && message.hasOwnProperty("defaultSamplerate"))
                if (!$util.isInteger(message.defaultSamplerate))
                    return "defaultSamplerate: integer expected";
            if (message.selected != null && message.hasOwnProperty("selected"))
                if (typeof message.selected !== "boolean")
                    return "selected: boolean expected";
            return null;
        };

        /**
         * Creates an AsodRecorderAudioDevice message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof asod_recorder_audio.AsodRecorderAudioDevice
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {asod_recorder_audio.AsodRecorderAudioDevice} AsodRecorderAudioDevice
         */
        AsodRecorderAudioDevice.fromObject = function fromObject(object) {
            if (object instanceof $root.asod_recorder_audio.AsodRecorderAudioDevice)
                return object;
            let message = new $root.asod_recorder_audio.AsodRecorderAudioDevice();
            if (object.id != null)
                message.id = object.id | 0;
            if (object.name != null)
                message.name = String(object.name);
            if (object.maxInputChannels != null)
                message.maxInputChannels = object.maxInputChannels | 0;
            if (object.defaultSamplerate != null)
                message.defaultSamplerate = object.defaultSamplerate | 0;
            if (object.selected != null)
                message.selected = Boolean(object.selected);
            return message;
        };

        /**
         * Creates a plain object from an AsodRecorderAudioDevice message. Also converts values to other types if specified.
         * @function toObject
         * @memberof asod_recorder_audio.AsodRecorderAudioDevice
         * @static
         * @param {asod_recorder_audio.AsodRecorderAudioDevice} message AsodRecorderAudioDevice
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AsodRecorderAudioDevice.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.id = 0;
                object.name = "";
                object.maxInputChannels = 0;
                object.defaultSamplerate = 0;
                object.selected = false;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.maxInputChannels != null && message.hasOwnProperty("maxInputChannels"))
                object.maxInputChannels = message.maxInputChannels;
            if (message.defaultSamplerate != null && message.hasOwnProperty("defaultSamplerate"))
                object.defaultSamplerate = message.defaultSamplerate;
            if (message.selected != null && message.hasOwnProperty("selected"))
                object.selected = message.selected;
            return object;
        };

        /**
         * Converts this AsodRecorderAudioDevice to JSON.
         * @function toJSON
         * @memberof asod_recorder_audio.AsodRecorderAudioDevice
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AsodRecorderAudioDevice.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AsodRecorderAudioDevice
         * @function getTypeUrl
         * @memberof asod_recorder_audio.AsodRecorderAudioDevice
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AsodRecorderAudioDevice.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/asod_recorder_audio.AsodRecorderAudioDevice";
        };

        return AsodRecorderAudioDevice;
    })();

    /**
     * AsodRecorderAudioChannelStatus enum.
     * @name asod_recorder_audio.AsodRecorderAudioChannelStatus
     * @enum {number}
     * @property {number} aracs_unknown=0 aracs_unknown value
     * @property {number} aracs_muted=1 aracs_muted value
     * @property {number} aracs_low_volume=2 aracs_low_volume value
     * @property {number} aracs_active=3 aracs_active value
     */
    asod_recorder_audio.AsodRecorderAudioChannelStatus = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "aracs_unknown"] = 0;
        values[valuesById[1] = "aracs_muted"] = 1;
        values[valuesById[2] = "aracs_low_volume"] = 2;
        values[valuesById[3] = "aracs_active"] = 3;
        return values;
    })();

    return asod_recorder_audio;
})();

export const api_snemalniki = $root.api_snemalniki = (() => {

    /**
     * Namespace api_snemalniki.
     * @exports api_snemalniki
     * @namespace
     */
    const api_snemalniki = {};

    api_snemalniki.ApiSnemalnikiGetRecorders = (function() {

        /**
         * Properties of an ApiSnemalnikiGetRecorders.
         * @memberof api_snemalniki
         * @interface IApiSnemalnikiGetRecorders
         * @property {string|null} [name] ApiSnemalnikiGetRecorders name
         * @property {string|null} [court] ApiSnemalnikiGetRecorders court
         * @property {string|null} [courtroom] ApiSnemalnikiGetRecorders courtroom
         * @property {string|null} [courtSection] ApiSnemalnikiGetRecorders courtSection
         * @property {number|null} [offset] ApiSnemalnikiGetRecorders offset
         * @property {number|null} [pageSize] ApiSnemalnikiGetRecorders pageSize
         */

        /**
         * Constructs a new ApiSnemalnikiGetRecorders.
         * @memberof api_snemalniki
         * @classdesc Represents an ApiSnemalnikiGetRecorders.
         * @implements IApiSnemalnikiGetRecorders
         * @constructor
         * @param {api_snemalniki.IApiSnemalnikiGetRecorders=} [properties] Properties to set
         */
        function ApiSnemalnikiGetRecorders(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiSnemalnikiGetRecorders name.
         * @member {string} name
         * @memberof api_snemalniki.ApiSnemalnikiGetRecorders
         * @instance
         */
        ApiSnemalnikiGetRecorders.prototype.name = "";

        /**
         * ApiSnemalnikiGetRecorders court.
         * @member {string} court
         * @memberof api_snemalniki.ApiSnemalnikiGetRecorders
         * @instance
         */
        ApiSnemalnikiGetRecorders.prototype.court = "";

        /**
         * ApiSnemalnikiGetRecorders courtroom.
         * @member {string} courtroom
         * @memberof api_snemalniki.ApiSnemalnikiGetRecorders
         * @instance
         */
        ApiSnemalnikiGetRecorders.prototype.courtroom = "";

        /**
         * ApiSnemalnikiGetRecorders courtSection.
         * @member {string} courtSection
         * @memberof api_snemalniki.ApiSnemalnikiGetRecorders
         * @instance
         */
        ApiSnemalnikiGetRecorders.prototype.courtSection = "";

        /**
         * ApiSnemalnikiGetRecorders offset.
         * @member {number} offset
         * @memberof api_snemalniki.ApiSnemalnikiGetRecorders
         * @instance
         */
        ApiSnemalnikiGetRecorders.prototype.offset = 0;

        /**
         * ApiSnemalnikiGetRecorders pageSize.
         * @member {number} pageSize
         * @memberof api_snemalniki.ApiSnemalnikiGetRecorders
         * @instance
         */
        ApiSnemalnikiGetRecorders.prototype.pageSize = 0;

        /**
         * Creates a new ApiSnemalnikiGetRecorders instance using the specified properties.
         * @function create
         * @memberof api_snemalniki.ApiSnemalnikiGetRecorders
         * @static
         * @param {api_snemalniki.IApiSnemalnikiGetRecorders=} [properties] Properties to set
         * @returns {api_snemalniki.ApiSnemalnikiGetRecorders} ApiSnemalnikiGetRecorders instance
         */
        ApiSnemalnikiGetRecorders.create = function create(properties) {
            return new ApiSnemalnikiGetRecorders(properties);
        };

        /**
         * Encodes the specified ApiSnemalnikiGetRecorders message. Does not implicitly {@link api_snemalniki.ApiSnemalnikiGetRecorders.verify|verify} messages.
         * @function encode
         * @memberof api_snemalniki.ApiSnemalnikiGetRecorders
         * @static
         * @param {api_snemalniki.IApiSnemalnikiGetRecorders} message ApiSnemalnikiGetRecorders message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiSnemalnikiGetRecorders.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.court != null && Object.hasOwnProperty.call(message, "court"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.court);
            if (message.courtroom != null && Object.hasOwnProperty.call(message, "courtroom"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.courtroom);
            if (message.courtSection != null && Object.hasOwnProperty.call(message, "courtSection"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.courtSection);
            if (message.offset != null && Object.hasOwnProperty.call(message, "offset"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.offset);
            if (message.pageSize != null && Object.hasOwnProperty.call(message, "pageSize"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.pageSize);
            return writer;
        };

        /**
         * Encodes the specified ApiSnemalnikiGetRecorders message, length delimited. Does not implicitly {@link api_snemalniki.ApiSnemalnikiGetRecorders.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_snemalniki.ApiSnemalnikiGetRecorders
         * @static
         * @param {api_snemalniki.IApiSnemalnikiGetRecorders} message ApiSnemalnikiGetRecorders message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiSnemalnikiGetRecorders.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiSnemalnikiGetRecorders message from the specified reader or buffer.
         * @function decode
         * @memberof api_snemalniki.ApiSnemalnikiGetRecorders
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_snemalniki.ApiSnemalnikiGetRecorders} ApiSnemalnikiGetRecorders
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiSnemalnikiGetRecorders.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_snemalniki.ApiSnemalnikiGetRecorders();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.name = reader.string();
                        break;
                    }
                case 2: {
                        message.court = reader.string();
                        break;
                    }
                case 3: {
                        message.courtroom = reader.string();
                        break;
                    }
                case 4: {
                        message.courtSection = reader.string();
                        break;
                    }
                case 5: {
                        message.offset = reader.int32();
                        break;
                    }
                case 6: {
                        message.pageSize = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiSnemalnikiGetRecorders message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_snemalniki.ApiSnemalnikiGetRecorders
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_snemalniki.ApiSnemalnikiGetRecorders} ApiSnemalnikiGetRecorders
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiSnemalnikiGetRecorders.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiSnemalnikiGetRecorders message.
         * @function verify
         * @memberof api_snemalniki.ApiSnemalnikiGetRecorders
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiSnemalnikiGetRecorders.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.court != null && message.hasOwnProperty("court"))
                if (!$util.isString(message.court))
                    return "court: string expected";
            if (message.courtroom != null && message.hasOwnProperty("courtroom"))
                if (!$util.isString(message.courtroom))
                    return "courtroom: string expected";
            if (message.courtSection != null && message.hasOwnProperty("courtSection"))
                if (!$util.isString(message.courtSection))
                    return "courtSection: string expected";
            if (message.offset != null && message.hasOwnProperty("offset"))
                if (!$util.isInteger(message.offset))
                    return "offset: integer expected";
            if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                if (!$util.isInteger(message.pageSize))
                    return "pageSize: integer expected";
            return null;
        };

        /**
         * Creates an ApiSnemalnikiGetRecorders message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_snemalniki.ApiSnemalnikiGetRecorders
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_snemalniki.ApiSnemalnikiGetRecorders} ApiSnemalnikiGetRecorders
         */
        ApiSnemalnikiGetRecorders.fromObject = function fromObject(object) {
            if (object instanceof $root.api_snemalniki.ApiSnemalnikiGetRecorders)
                return object;
            let message = new $root.api_snemalniki.ApiSnemalnikiGetRecorders();
            if (object.name != null)
                message.name = String(object.name);
            if (object.court != null)
                message.court = String(object.court);
            if (object.courtroom != null)
                message.courtroom = String(object.courtroom);
            if (object.courtSection != null)
                message.courtSection = String(object.courtSection);
            if (object.offset != null)
                message.offset = object.offset | 0;
            if (object.pageSize != null)
                message.pageSize = object.pageSize | 0;
            return message;
        };

        /**
         * Creates a plain object from an ApiSnemalnikiGetRecorders message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_snemalniki.ApiSnemalnikiGetRecorders
         * @static
         * @param {api_snemalniki.ApiSnemalnikiGetRecorders} message ApiSnemalnikiGetRecorders
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiSnemalnikiGetRecorders.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.name = "";
                object.court = "";
                object.courtroom = "";
                object.courtSection = "";
                object.offset = 0;
                object.pageSize = 0;
            }
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.court != null && message.hasOwnProperty("court"))
                object.court = message.court;
            if (message.courtroom != null && message.hasOwnProperty("courtroom"))
                object.courtroom = message.courtroom;
            if (message.courtSection != null && message.hasOwnProperty("courtSection"))
                object.courtSection = message.courtSection;
            if (message.offset != null && message.hasOwnProperty("offset"))
                object.offset = message.offset;
            if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                object.pageSize = message.pageSize;
            return object;
        };

        /**
         * Converts this ApiSnemalnikiGetRecorders to JSON.
         * @function toJSON
         * @memberof api_snemalniki.ApiSnemalnikiGetRecorders
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiSnemalnikiGetRecorders.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiSnemalnikiGetRecorders
         * @function getTypeUrl
         * @memberof api_snemalniki.ApiSnemalnikiGetRecorders
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiSnemalnikiGetRecorders.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_snemalniki.ApiSnemalnikiGetRecorders";
        };

        return ApiSnemalnikiGetRecorders;
    })();

    api_snemalniki.ApiSnemalnikiRecorders = (function() {

        /**
         * Properties of an ApiSnemalnikiRecorders.
         * @memberof api_snemalniki
         * @interface IApiSnemalnikiRecorders
         * @property {string|null} [sourceEventId] ApiSnemalnikiRecorders sourceEventId
         * @property {Array.<api_snemalniki.IApiSnemalnikiRecorder>|null} [recorders] ApiSnemalnikiRecorders recorders
         * @property {number|null} [offset] ApiSnemalnikiRecorders offset
         * @property {number|null} [pageSize] ApiSnemalnikiRecorders pageSize
         * @property {number|null} [lastItemIndex] ApiSnemalnikiRecorders lastItemIndex
         */

        /**
         * Constructs a new ApiSnemalnikiRecorders.
         * @memberof api_snemalniki
         * @classdesc Represents an ApiSnemalnikiRecorders.
         * @implements IApiSnemalnikiRecorders
         * @constructor
         * @param {api_snemalniki.IApiSnemalnikiRecorders=} [properties] Properties to set
         */
        function ApiSnemalnikiRecorders(properties) {
            this.recorders = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiSnemalnikiRecorders sourceEventId.
         * @member {string} sourceEventId
         * @memberof api_snemalniki.ApiSnemalnikiRecorders
         * @instance
         */
        ApiSnemalnikiRecorders.prototype.sourceEventId = "";

        /**
         * ApiSnemalnikiRecorders recorders.
         * @member {Array.<api_snemalniki.IApiSnemalnikiRecorder>} recorders
         * @memberof api_snemalniki.ApiSnemalnikiRecorders
         * @instance
         */
        ApiSnemalnikiRecorders.prototype.recorders = $util.emptyArray;

        /**
         * ApiSnemalnikiRecorders offset.
         * @member {number} offset
         * @memberof api_snemalniki.ApiSnemalnikiRecorders
         * @instance
         */
        ApiSnemalnikiRecorders.prototype.offset = 0;

        /**
         * ApiSnemalnikiRecorders pageSize.
         * @member {number} pageSize
         * @memberof api_snemalniki.ApiSnemalnikiRecorders
         * @instance
         */
        ApiSnemalnikiRecorders.prototype.pageSize = 0;

        /**
         * ApiSnemalnikiRecorders lastItemIndex.
         * @member {number} lastItemIndex
         * @memberof api_snemalniki.ApiSnemalnikiRecorders
         * @instance
         */
        ApiSnemalnikiRecorders.prototype.lastItemIndex = 0;

        /**
         * Creates a new ApiSnemalnikiRecorders instance using the specified properties.
         * @function create
         * @memberof api_snemalniki.ApiSnemalnikiRecorders
         * @static
         * @param {api_snemalniki.IApiSnemalnikiRecorders=} [properties] Properties to set
         * @returns {api_snemalniki.ApiSnemalnikiRecorders} ApiSnemalnikiRecorders instance
         */
        ApiSnemalnikiRecorders.create = function create(properties) {
            return new ApiSnemalnikiRecorders(properties);
        };

        /**
         * Encodes the specified ApiSnemalnikiRecorders message. Does not implicitly {@link api_snemalniki.ApiSnemalnikiRecorders.verify|verify} messages.
         * @function encode
         * @memberof api_snemalniki.ApiSnemalnikiRecorders
         * @static
         * @param {api_snemalniki.IApiSnemalnikiRecorders} message ApiSnemalnikiRecorders message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiSnemalnikiRecorders.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sourceEventId != null && Object.hasOwnProperty.call(message, "sourceEventId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.sourceEventId);
            if (message.recorders != null && message.recorders.length)
                for (let i = 0; i < message.recorders.length; ++i)
                    $root.api_snemalniki.ApiSnemalnikiRecorder.encode(message.recorders[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.offset != null && Object.hasOwnProperty.call(message, "offset"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.offset);
            if (message.pageSize != null && Object.hasOwnProperty.call(message, "pageSize"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.pageSize);
            if (message.lastItemIndex != null && Object.hasOwnProperty.call(message, "lastItemIndex"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.lastItemIndex);
            return writer;
        };

        /**
         * Encodes the specified ApiSnemalnikiRecorders message, length delimited. Does not implicitly {@link api_snemalniki.ApiSnemalnikiRecorders.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_snemalniki.ApiSnemalnikiRecorders
         * @static
         * @param {api_snemalniki.IApiSnemalnikiRecorders} message ApiSnemalnikiRecorders message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiSnemalnikiRecorders.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiSnemalnikiRecorders message from the specified reader or buffer.
         * @function decode
         * @memberof api_snemalniki.ApiSnemalnikiRecorders
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_snemalniki.ApiSnemalnikiRecorders} ApiSnemalnikiRecorders
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiSnemalnikiRecorders.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_snemalniki.ApiSnemalnikiRecorders();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sourceEventId = reader.string();
                        break;
                    }
                case 2: {
                        if (!(message.recorders && message.recorders.length))
                            message.recorders = [];
                        message.recorders.push($root.api_snemalniki.ApiSnemalnikiRecorder.decode(reader, reader.uint32()));
                        break;
                    }
                case 3: {
                        message.offset = reader.int32();
                        break;
                    }
                case 4: {
                        message.pageSize = reader.int32();
                        break;
                    }
                case 5: {
                        message.lastItemIndex = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiSnemalnikiRecorders message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_snemalniki.ApiSnemalnikiRecorders
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_snemalniki.ApiSnemalnikiRecorders} ApiSnemalnikiRecorders
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiSnemalnikiRecorders.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiSnemalnikiRecorders message.
         * @function verify
         * @memberof api_snemalniki.ApiSnemalnikiRecorders
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiSnemalnikiRecorders.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.sourceEventId != null && message.hasOwnProperty("sourceEventId"))
                if (!$util.isString(message.sourceEventId))
                    return "sourceEventId: string expected";
            if (message.recorders != null && message.hasOwnProperty("recorders")) {
                if (!Array.isArray(message.recorders))
                    return "recorders: array expected";
                for (let i = 0; i < message.recorders.length; ++i) {
                    let error = $root.api_snemalniki.ApiSnemalnikiRecorder.verify(message.recorders[i]);
                    if (error)
                        return "recorders." + error;
                }
            }
            if (message.offset != null && message.hasOwnProperty("offset"))
                if (!$util.isInteger(message.offset))
                    return "offset: integer expected";
            if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                if (!$util.isInteger(message.pageSize))
                    return "pageSize: integer expected";
            if (message.lastItemIndex != null && message.hasOwnProperty("lastItemIndex"))
                if (!$util.isInteger(message.lastItemIndex))
                    return "lastItemIndex: integer expected";
            return null;
        };

        /**
         * Creates an ApiSnemalnikiRecorders message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_snemalniki.ApiSnemalnikiRecorders
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_snemalniki.ApiSnemalnikiRecorders} ApiSnemalnikiRecorders
         */
        ApiSnemalnikiRecorders.fromObject = function fromObject(object) {
            if (object instanceof $root.api_snemalniki.ApiSnemalnikiRecorders)
                return object;
            let message = new $root.api_snemalniki.ApiSnemalnikiRecorders();
            if (object.sourceEventId != null)
                message.sourceEventId = String(object.sourceEventId);
            if (object.recorders) {
                if (!Array.isArray(object.recorders))
                    throw TypeError(".api_snemalniki.ApiSnemalnikiRecorders.recorders: array expected");
                message.recorders = [];
                for (let i = 0; i < object.recorders.length; ++i) {
                    if (typeof object.recorders[i] !== "object")
                        throw TypeError(".api_snemalniki.ApiSnemalnikiRecorders.recorders: object expected");
                    message.recorders[i] = $root.api_snemalniki.ApiSnemalnikiRecorder.fromObject(object.recorders[i]);
                }
            }
            if (object.offset != null)
                message.offset = object.offset | 0;
            if (object.pageSize != null)
                message.pageSize = object.pageSize | 0;
            if (object.lastItemIndex != null)
                message.lastItemIndex = object.lastItemIndex | 0;
            return message;
        };

        /**
         * Creates a plain object from an ApiSnemalnikiRecorders message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_snemalniki.ApiSnemalnikiRecorders
         * @static
         * @param {api_snemalniki.ApiSnemalnikiRecorders} message ApiSnemalnikiRecorders
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiSnemalnikiRecorders.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.recorders = [];
            if (options.defaults) {
                object.sourceEventId = "";
                object.offset = 0;
                object.pageSize = 0;
                object.lastItemIndex = 0;
            }
            if (message.sourceEventId != null && message.hasOwnProperty("sourceEventId"))
                object.sourceEventId = message.sourceEventId;
            if (message.recorders && message.recorders.length) {
                object.recorders = [];
                for (let j = 0; j < message.recorders.length; ++j)
                    object.recorders[j] = $root.api_snemalniki.ApiSnemalnikiRecorder.toObject(message.recorders[j], options);
            }
            if (message.offset != null && message.hasOwnProperty("offset"))
                object.offset = message.offset;
            if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                object.pageSize = message.pageSize;
            if (message.lastItemIndex != null && message.hasOwnProperty("lastItemIndex"))
                object.lastItemIndex = message.lastItemIndex;
            return object;
        };

        /**
         * Converts this ApiSnemalnikiRecorders to JSON.
         * @function toJSON
         * @memberof api_snemalniki.ApiSnemalnikiRecorders
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiSnemalnikiRecorders.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiSnemalnikiRecorders
         * @function getTypeUrl
         * @memberof api_snemalniki.ApiSnemalnikiRecorders
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiSnemalnikiRecorders.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_snemalniki.ApiSnemalnikiRecorders";
        };

        return ApiSnemalnikiRecorders;
    })();

    api_snemalniki.ApiSnemalnikiRecorder = (function() {

        /**
         * Properties of an ApiSnemalnikiRecorder.
         * @memberof api_snemalniki
         * @interface IApiSnemalnikiRecorder
         * @property {string|null} [recorderId] ApiSnemalnikiRecorder recorderId
         * @property {string|null} [recorderName] ApiSnemalnikiRecorder recorderName
         * @property {string|null} [courtName] ApiSnemalnikiRecorder courtName
         * @property {string|null} [courtSection] ApiSnemalnikiRecorder courtSection
         * @property {string|null} [courtroom] ApiSnemalnikiRecorder courtroom
         * @property {string|null} [softwareVersion] ApiSnemalnikiRecorder softwareVersion
         * @property {string|null} [status] ApiSnemalnikiRecorder status
         * @property {number|Long|null} [diskCapacity] ApiSnemalnikiRecorder diskCapacity
         * @property {number|Long|null} [diskAmountFilled] ApiSnemalnikiRecorder diskAmountFilled
         * @property {number|Long|null} [statusTime] ApiSnemalnikiRecorder statusTime
         */

        /**
         * Constructs a new ApiSnemalnikiRecorder.
         * @memberof api_snemalniki
         * @classdesc Represents an ApiSnemalnikiRecorder.
         * @implements IApiSnemalnikiRecorder
         * @constructor
         * @param {api_snemalniki.IApiSnemalnikiRecorder=} [properties] Properties to set
         */
        function ApiSnemalnikiRecorder(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiSnemalnikiRecorder recorderId.
         * @member {string} recorderId
         * @memberof api_snemalniki.ApiSnemalnikiRecorder
         * @instance
         */
        ApiSnemalnikiRecorder.prototype.recorderId = "";

        /**
         * ApiSnemalnikiRecorder recorderName.
         * @member {string} recorderName
         * @memberof api_snemalniki.ApiSnemalnikiRecorder
         * @instance
         */
        ApiSnemalnikiRecorder.prototype.recorderName = "";

        /**
         * ApiSnemalnikiRecorder courtName.
         * @member {string} courtName
         * @memberof api_snemalniki.ApiSnemalnikiRecorder
         * @instance
         */
        ApiSnemalnikiRecorder.prototype.courtName = "";

        /**
         * ApiSnemalnikiRecorder courtSection.
         * @member {string} courtSection
         * @memberof api_snemalniki.ApiSnemalnikiRecorder
         * @instance
         */
        ApiSnemalnikiRecorder.prototype.courtSection = "";

        /**
         * ApiSnemalnikiRecorder courtroom.
         * @member {string} courtroom
         * @memberof api_snemalniki.ApiSnemalnikiRecorder
         * @instance
         */
        ApiSnemalnikiRecorder.prototype.courtroom = "";

        /**
         * ApiSnemalnikiRecorder softwareVersion.
         * @member {string} softwareVersion
         * @memberof api_snemalniki.ApiSnemalnikiRecorder
         * @instance
         */
        ApiSnemalnikiRecorder.prototype.softwareVersion = "";

        /**
         * ApiSnemalnikiRecorder status.
         * @member {string} status
         * @memberof api_snemalniki.ApiSnemalnikiRecorder
         * @instance
         */
        ApiSnemalnikiRecorder.prototype.status = "";

        /**
         * ApiSnemalnikiRecorder diskCapacity.
         * @member {number|Long} diskCapacity
         * @memberof api_snemalniki.ApiSnemalnikiRecorder
         * @instance
         */
        ApiSnemalnikiRecorder.prototype.diskCapacity = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ApiSnemalnikiRecorder diskAmountFilled.
         * @member {number|Long} diskAmountFilled
         * @memberof api_snemalniki.ApiSnemalnikiRecorder
         * @instance
         */
        ApiSnemalnikiRecorder.prototype.diskAmountFilled = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ApiSnemalnikiRecorder statusTime.
         * @member {number|Long} statusTime
         * @memberof api_snemalniki.ApiSnemalnikiRecorder
         * @instance
         */
        ApiSnemalnikiRecorder.prototype.statusTime = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new ApiSnemalnikiRecorder instance using the specified properties.
         * @function create
         * @memberof api_snemalniki.ApiSnemalnikiRecorder
         * @static
         * @param {api_snemalniki.IApiSnemalnikiRecorder=} [properties] Properties to set
         * @returns {api_snemalniki.ApiSnemalnikiRecorder} ApiSnemalnikiRecorder instance
         */
        ApiSnemalnikiRecorder.create = function create(properties) {
            return new ApiSnemalnikiRecorder(properties);
        };

        /**
         * Encodes the specified ApiSnemalnikiRecorder message. Does not implicitly {@link api_snemalniki.ApiSnemalnikiRecorder.verify|verify} messages.
         * @function encode
         * @memberof api_snemalniki.ApiSnemalnikiRecorder
         * @static
         * @param {api_snemalniki.IApiSnemalnikiRecorder} message ApiSnemalnikiRecorder message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiSnemalnikiRecorder.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.recorderId != null && Object.hasOwnProperty.call(message, "recorderId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.recorderId);
            if (message.recorderName != null && Object.hasOwnProperty.call(message, "recorderName"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.recorderName);
            if (message.courtName != null && Object.hasOwnProperty.call(message, "courtName"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.courtName);
            if (message.courtSection != null && Object.hasOwnProperty.call(message, "courtSection"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.courtSection);
            if (message.courtroom != null && Object.hasOwnProperty.call(message, "courtroom"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.courtroom);
            if (message.softwareVersion != null && Object.hasOwnProperty.call(message, "softwareVersion"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.softwareVersion);
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.status);
            if (message.diskCapacity != null && Object.hasOwnProperty.call(message, "diskCapacity"))
                writer.uint32(/* id 8, wireType 0 =*/64).uint64(message.diskCapacity);
            if (message.diskAmountFilled != null && Object.hasOwnProperty.call(message, "diskAmountFilled"))
                writer.uint32(/* id 9, wireType 0 =*/72).uint64(message.diskAmountFilled);
            if (message.statusTime != null && Object.hasOwnProperty.call(message, "statusTime"))
                writer.uint32(/* id 10, wireType 0 =*/80).uint64(message.statusTime);
            return writer;
        };

        /**
         * Encodes the specified ApiSnemalnikiRecorder message, length delimited. Does not implicitly {@link api_snemalniki.ApiSnemalnikiRecorder.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_snemalniki.ApiSnemalnikiRecorder
         * @static
         * @param {api_snemalniki.IApiSnemalnikiRecorder} message ApiSnemalnikiRecorder message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiSnemalnikiRecorder.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiSnemalnikiRecorder message from the specified reader or buffer.
         * @function decode
         * @memberof api_snemalniki.ApiSnemalnikiRecorder
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_snemalniki.ApiSnemalnikiRecorder} ApiSnemalnikiRecorder
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiSnemalnikiRecorder.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_snemalniki.ApiSnemalnikiRecorder();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.recorderId = reader.string();
                        break;
                    }
                case 2: {
                        message.recorderName = reader.string();
                        break;
                    }
                case 3: {
                        message.courtName = reader.string();
                        break;
                    }
                case 4: {
                        message.courtSection = reader.string();
                        break;
                    }
                case 5: {
                        message.courtroom = reader.string();
                        break;
                    }
                case 6: {
                        message.softwareVersion = reader.string();
                        break;
                    }
                case 7: {
                        message.status = reader.string();
                        break;
                    }
                case 8: {
                        message.diskCapacity = reader.uint64();
                        break;
                    }
                case 9: {
                        message.diskAmountFilled = reader.uint64();
                        break;
                    }
                case 10: {
                        message.statusTime = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiSnemalnikiRecorder message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_snemalniki.ApiSnemalnikiRecorder
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_snemalniki.ApiSnemalnikiRecorder} ApiSnemalnikiRecorder
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiSnemalnikiRecorder.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiSnemalnikiRecorder message.
         * @function verify
         * @memberof api_snemalniki.ApiSnemalnikiRecorder
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiSnemalnikiRecorder.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.recorderId != null && message.hasOwnProperty("recorderId"))
                if (!$util.isString(message.recorderId))
                    return "recorderId: string expected";
            if (message.recorderName != null && message.hasOwnProperty("recorderName"))
                if (!$util.isString(message.recorderName))
                    return "recorderName: string expected";
            if (message.courtName != null && message.hasOwnProperty("courtName"))
                if (!$util.isString(message.courtName))
                    return "courtName: string expected";
            if (message.courtSection != null && message.hasOwnProperty("courtSection"))
                if (!$util.isString(message.courtSection))
                    return "courtSection: string expected";
            if (message.courtroom != null && message.hasOwnProperty("courtroom"))
                if (!$util.isString(message.courtroom))
                    return "courtroom: string expected";
            if (message.softwareVersion != null && message.hasOwnProperty("softwareVersion"))
                if (!$util.isString(message.softwareVersion))
                    return "softwareVersion: string expected";
            if (message.status != null && message.hasOwnProperty("status"))
                if (!$util.isString(message.status))
                    return "status: string expected";
            if (message.diskCapacity != null && message.hasOwnProperty("diskCapacity"))
                if (!$util.isInteger(message.diskCapacity) && !(message.diskCapacity && $util.isInteger(message.diskCapacity.low) && $util.isInteger(message.diskCapacity.high)))
                    return "diskCapacity: integer|Long expected";
            if (message.diskAmountFilled != null && message.hasOwnProperty("diskAmountFilled"))
                if (!$util.isInteger(message.diskAmountFilled) && !(message.diskAmountFilled && $util.isInteger(message.diskAmountFilled.low) && $util.isInteger(message.diskAmountFilled.high)))
                    return "diskAmountFilled: integer|Long expected";
            if (message.statusTime != null && message.hasOwnProperty("statusTime"))
                if (!$util.isInteger(message.statusTime) && !(message.statusTime && $util.isInteger(message.statusTime.low) && $util.isInteger(message.statusTime.high)))
                    return "statusTime: integer|Long expected";
            return null;
        };

        /**
         * Creates an ApiSnemalnikiRecorder message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_snemalniki.ApiSnemalnikiRecorder
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_snemalniki.ApiSnemalnikiRecorder} ApiSnemalnikiRecorder
         */
        ApiSnemalnikiRecorder.fromObject = function fromObject(object) {
            if (object instanceof $root.api_snemalniki.ApiSnemalnikiRecorder)
                return object;
            let message = new $root.api_snemalniki.ApiSnemalnikiRecorder();
            if (object.recorderId != null)
                message.recorderId = String(object.recorderId);
            if (object.recorderName != null)
                message.recorderName = String(object.recorderName);
            if (object.courtName != null)
                message.courtName = String(object.courtName);
            if (object.courtSection != null)
                message.courtSection = String(object.courtSection);
            if (object.courtroom != null)
                message.courtroom = String(object.courtroom);
            if (object.softwareVersion != null)
                message.softwareVersion = String(object.softwareVersion);
            if (object.status != null)
                message.status = String(object.status);
            if (object.diskCapacity != null)
                if ($util.Long)
                    (message.diskCapacity = $util.Long.fromValue(object.diskCapacity)).unsigned = true;
                else if (typeof object.diskCapacity === "string")
                    message.diskCapacity = parseInt(object.diskCapacity, 10);
                else if (typeof object.diskCapacity === "number")
                    message.diskCapacity = object.diskCapacity;
                else if (typeof object.diskCapacity === "object")
                    message.diskCapacity = new $util.LongBits(object.diskCapacity.low >>> 0, object.diskCapacity.high >>> 0).toNumber(true);
            if (object.diskAmountFilled != null)
                if ($util.Long)
                    (message.diskAmountFilled = $util.Long.fromValue(object.diskAmountFilled)).unsigned = true;
                else if (typeof object.diskAmountFilled === "string")
                    message.diskAmountFilled = parseInt(object.diskAmountFilled, 10);
                else if (typeof object.diskAmountFilled === "number")
                    message.diskAmountFilled = object.diskAmountFilled;
                else if (typeof object.diskAmountFilled === "object")
                    message.diskAmountFilled = new $util.LongBits(object.diskAmountFilled.low >>> 0, object.diskAmountFilled.high >>> 0).toNumber(true);
            if (object.statusTime != null)
                if ($util.Long)
                    (message.statusTime = $util.Long.fromValue(object.statusTime)).unsigned = true;
                else if (typeof object.statusTime === "string")
                    message.statusTime = parseInt(object.statusTime, 10);
                else if (typeof object.statusTime === "number")
                    message.statusTime = object.statusTime;
                else if (typeof object.statusTime === "object")
                    message.statusTime = new $util.LongBits(object.statusTime.low >>> 0, object.statusTime.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from an ApiSnemalnikiRecorder message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_snemalniki.ApiSnemalnikiRecorder
         * @static
         * @param {api_snemalniki.ApiSnemalnikiRecorder} message ApiSnemalnikiRecorder
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiSnemalnikiRecorder.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.recorderId = "";
                object.recorderName = "";
                object.courtName = "";
                object.courtSection = "";
                object.courtroom = "";
                object.softwareVersion = "";
                object.status = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.diskCapacity = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.diskCapacity = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.diskAmountFilled = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.diskAmountFilled = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.statusTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.statusTime = options.longs === String ? "0" : 0;
            }
            if (message.recorderId != null && message.hasOwnProperty("recorderId"))
                object.recorderId = message.recorderId;
            if (message.recorderName != null && message.hasOwnProperty("recorderName"))
                object.recorderName = message.recorderName;
            if (message.courtName != null && message.hasOwnProperty("courtName"))
                object.courtName = message.courtName;
            if (message.courtSection != null && message.hasOwnProperty("courtSection"))
                object.courtSection = message.courtSection;
            if (message.courtroom != null && message.hasOwnProperty("courtroom"))
                object.courtroom = message.courtroom;
            if (message.softwareVersion != null && message.hasOwnProperty("softwareVersion"))
                object.softwareVersion = message.softwareVersion;
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = message.status;
            if (message.diskCapacity != null && message.hasOwnProperty("diskCapacity"))
                if (typeof message.diskCapacity === "number")
                    object.diskCapacity = options.longs === String ? String(message.diskCapacity) : message.diskCapacity;
                else
                    object.diskCapacity = options.longs === String ? $util.Long.prototype.toString.call(message.diskCapacity) : options.longs === Number ? new $util.LongBits(message.diskCapacity.low >>> 0, message.diskCapacity.high >>> 0).toNumber(true) : message.diskCapacity;
            if (message.diskAmountFilled != null && message.hasOwnProperty("diskAmountFilled"))
                if (typeof message.diskAmountFilled === "number")
                    object.diskAmountFilled = options.longs === String ? String(message.diskAmountFilled) : message.diskAmountFilled;
                else
                    object.diskAmountFilled = options.longs === String ? $util.Long.prototype.toString.call(message.diskAmountFilled) : options.longs === Number ? new $util.LongBits(message.diskAmountFilled.low >>> 0, message.diskAmountFilled.high >>> 0).toNumber(true) : message.diskAmountFilled;
            if (message.statusTime != null && message.hasOwnProperty("statusTime"))
                if (typeof message.statusTime === "number")
                    object.statusTime = options.longs === String ? String(message.statusTime) : message.statusTime;
                else
                    object.statusTime = options.longs === String ? $util.Long.prototype.toString.call(message.statusTime) : options.longs === Number ? new $util.LongBits(message.statusTime.low >>> 0, message.statusTime.high >>> 0).toNumber(true) : message.statusTime;
            return object;
        };

        /**
         * Converts this ApiSnemalnikiRecorder to JSON.
         * @function toJSON
         * @memberof api_snemalniki.ApiSnemalnikiRecorder
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiSnemalnikiRecorder.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiSnemalnikiRecorder
         * @function getTypeUrl
         * @memberof api_snemalniki.ApiSnemalnikiRecorder
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiSnemalnikiRecorder.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_snemalniki.ApiSnemalnikiRecorder";
        };

        return ApiSnemalnikiRecorder;
    })();

    api_snemalniki.ApiSnemalnikiUpdateRecorders = (function() {

        /**
         * Properties of an ApiSnemalnikiUpdateRecorders.
         * @memberof api_snemalniki
         * @interface IApiSnemalnikiUpdateRecorders
         * @property {Array.<string>|null} [recorderIds] ApiSnemalnikiUpdateRecorders recorderIds
         * @property {number|Long|null} [fromTimestamp] ApiSnemalnikiUpdateRecorders fromTimestamp
         * @property {number|Long|null} [toTimestamp] ApiSnemalnikiUpdateRecorders toTimestamp
         */

        /**
         * Constructs a new ApiSnemalnikiUpdateRecorders.
         * @memberof api_snemalniki
         * @classdesc Represents an ApiSnemalnikiUpdateRecorders.
         * @implements IApiSnemalnikiUpdateRecorders
         * @constructor
         * @param {api_snemalniki.IApiSnemalnikiUpdateRecorders=} [properties] Properties to set
         */
        function ApiSnemalnikiUpdateRecorders(properties) {
            this.recorderIds = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiSnemalnikiUpdateRecorders recorderIds.
         * @member {Array.<string>} recorderIds
         * @memberof api_snemalniki.ApiSnemalnikiUpdateRecorders
         * @instance
         */
        ApiSnemalnikiUpdateRecorders.prototype.recorderIds = $util.emptyArray;

        /**
         * ApiSnemalnikiUpdateRecorders fromTimestamp.
         * @member {number|Long} fromTimestamp
         * @memberof api_snemalniki.ApiSnemalnikiUpdateRecorders
         * @instance
         */
        ApiSnemalnikiUpdateRecorders.prototype.fromTimestamp = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ApiSnemalnikiUpdateRecorders toTimestamp.
         * @member {number|Long} toTimestamp
         * @memberof api_snemalniki.ApiSnemalnikiUpdateRecorders
         * @instance
         */
        ApiSnemalnikiUpdateRecorders.prototype.toTimestamp = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new ApiSnemalnikiUpdateRecorders instance using the specified properties.
         * @function create
         * @memberof api_snemalniki.ApiSnemalnikiUpdateRecorders
         * @static
         * @param {api_snemalniki.IApiSnemalnikiUpdateRecorders=} [properties] Properties to set
         * @returns {api_snemalniki.ApiSnemalnikiUpdateRecorders} ApiSnemalnikiUpdateRecorders instance
         */
        ApiSnemalnikiUpdateRecorders.create = function create(properties) {
            return new ApiSnemalnikiUpdateRecorders(properties);
        };

        /**
         * Encodes the specified ApiSnemalnikiUpdateRecorders message. Does not implicitly {@link api_snemalniki.ApiSnemalnikiUpdateRecorders.verify|verify} messages.
         * @function encode
         * @memberof api_snemalniki.ApiSnemalnikiUpdateRecorders
         * @static
         * @param {api_snemalniki.IApiSnemalnikiUpdateRecorders} message ApiSnemalnikiUpdateRecorders message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiSnemalnikiUpdateRecorders.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.recorderIds != null && message.recorderIds.length)
                for (let i = 0; i < message.recorderIds.length; ++i)
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.recorderIds[i]);
            if (message.fromTimestamp != null && Object.hasOwnProperty.call(message, "fromTimestamp"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.fromTimestamp);
            if (message.toTimestamp != null && Object.hasOwnProperty.call(message, "toTimestamp"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.toTimestamp);
            return writer;
        };

        /**
         * Encodes the specified ApiSnemalnikiUpdateRecorders message, length delimited. Does not implicitly {@link api_snemalniki.ApiSnemalnikiUpdateRecorders.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_snemalniki.ApiSnemalnikiUpdateRecorders
         * @static
         * @param {api_snemalniki.IApiSnemalnikiUpdateRecorders} message ApiSnemalnikiUpdateRecorders message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiSnemalnikiUpdateRecorders.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiSnemalnikiUpdateRecorders message from the specified reader or buffer.
         * @function decode
         * @memberof api_snemalniki.ApiSnemalnikiUpdateRecorders
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_snemalniki.ApiSnemalnikiUpdateRecorders} ApiSnemalnikiUpdateRecorders
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiSnemalnikiUpdateRecorders.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_snemalniki.ApiSnemalnikiUpdateRecorders();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.recorderIds && message.recorderIds.length))
                            message.recorderIds = [];
                        message.recorderIds.push(reader.string());
                        break;
                    }
                case 2: {
                        message.fromTimestamp = reader.uint64();
                        break;
                    }
                case 3: {
                        message.toTimestamp = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiSnemalnikiUpdateRecorders message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_snemalniki.ApiSnemalnikiUpdateRecorders
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_snemalniki.ApiSnemalnikiUpdateRecorders} ApiSnemalnikiUpdateRecorders
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiSnemalnikiUpdateRecorders.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiSnemalnikiUpdateRecorders message.
         * @function verify
         * @memberof api_snemalniki.ApiSnemalnikiUpdateRecorders
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiSnemalnikiUpdateRecorders.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.recorderIds != null && message.hasOwnProperty("recorderIds")) {
                if (!Array.isArray(message.recorderIds))
                    return "recorderIds: array expected";
                for (let i = 0; i < message.recorderIds.length; ++i)
                    if (!$util.isString(message.recorderIds[i]))
                        return "recorderIds: string[] expected";
            }
            if (message.fromTimestamp != null && message.hasOwnProperty("fromTimestamp"))
                if (!$util.isInteger(message.fromTimestamp) && !(message.fromTimestamp && $util.isInteger(message.fromTimestamp.low) && $util.isInteger(message.fromTimestamp.high)))
                    return "fromTimestamp: integer|Long expected";
            if (message.toTimestamp != null && message.hasOwnProperty("toTimestamp"))
                if (!$util.isInteger(message.toTimestamp) && !(message.toTimestamp && $util.isInteger(message.toTimestamp.low) && $util.isInteger(message.toTimestamp.high)))
                    return "toTimestamp: integer|Long expected";
            return null;
        };

        /**
         * Creates an ApiSnemalnikiUpdateRecorders message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_snemalniki.ApiSnemalnikiUpdateRecorders
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_snemalniki.ApiSnemalnikiUpdateRecorders} ApiSnemalnikiUpdateRecorders
         */
        ApiSnemalnikiUpdateRecorders.fromObject = function fromObject(object) {
            if (object instanceof $root.api_snemalniki.ApiSnemalnikiUpdateRecorders)
                return object;
            let message = new $root.api_snemalniki.ApiSnemalnikiUpdateRecorders();
            if (object.recorderIds) {
                if (!Array.isArray(object.recorderIds))
                    throw TypeError(".api_snemalniki.ApiSnemalnikiUpdateRecorders.recorderIds: array expected");
                message.recorderIds = [];
                for (let i = 0; i < object.recorderIds.length; ++i)
                    message.recorderIds[i] = String(object.recorderIds[i]);
            }
            if (object.fromTimestamp != null)
                if ($util.Long)
                    (message.fromTimestamp = $util.Long.fromValue(object.fromTimestamp)).unsigned = true;
                else if (typeof object.fromTimestamp === "string")
                    message.fromTimestamp = parseInt(object.fromTimestamp, 10);
                else if (typeof object.fromTimestamp === "number")
                    message.fromTimestamp = object.fromTimestamp;
                else if (typeof object.fromTimestamp === "object")
                    message.fromTimestamp = new $util.LongBits(object.fromTimestamp.low >>> 0, object.fromTimestamp.high >>> 0).toNumber(true);
            if (object.toTimestamp != null)
                if ($util.Long)
                    (message.toTimestamp = $util.Long.fromValue(object.toTimestamp)).unsigned = true;
                else if (typeof object.toTimestamp === "string")
                    message.toTimestamp = parseInt(object.toTimestamp, 10);
                else if (typeof object.toTimestamp === "number")
                    message.toTimestamp = object.toTimestamp;
                else if (typeof object.toTimestamp === "object")
                    message.toTimestamp = new $util.LongBits(object.toTimestamp.low >>> 0, object.toTimestamp.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from an ApiSnemalnikiUpdateRecorders message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_snemalniki.ApiSnemalnikiUpdateRecorders
         * @static
         * @param {api_snemalniki.ApiSnemalnikiUpdateRecorders} message ApiSnemalnikiUpdateRecorders
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiSnemalnikiUpdateRecorders.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.recorderIds = [];
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.fromTimestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.fromTimestamp = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.toTimestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.toTimestamp = options.longs === String ? "0" : 0;
            }
            if (message.recorderIds && message.recorderIds.length) {
                object.recorderIds = [];
                for (let j = 0; j < message.recorderIds.length; ++j)
                    object.recorderIds[j] = message.recorderIds[j];
            }
            if (message.fromTimestamp != null && message.hasOwnProperty("fromTimestamp"))
                if (typeof message.fromTimestamp === "number")
                    object.fromTimestamp = options.longs === String ? String(message.fromTimestamp) : message.fromTimestamp;
                else
                    object.fromTimestamp = options.longs === String ? $util.Long.prototype.toString.call(message.fromTimestamp) : options.longs === Number ? new $util.LongBits(message.fromTimestamp.low >>> 0, message.fromTimestamp.high >>> 0).toNumber(true) : message.fromTimestamp;
            if (message.toTimestamp != null && message.hasOwnProperty("toTimestamp"))
                if (typeof message.toTimestamp === "number")
                    object.toTimestamp = options.longs === String ? String(message.toTimestamp) : message.toTimestamp;
                else
                    object.toTimestamp = options.longs === String ? $util.Long.prototype.toString.call(message.toTimestamp) : options.longs === Number ? new $util.LongBits(message.toTimestamp.low >>> 0, message.toTimestamp.high >>> 0).toNumber(true) : message.toTimestamp;
            return object;
        };

        /**
         * Converts this ApiSnemalnikiUpdateRecorders to JSON.
         * @function toJSON
         * @memberof api_snemalniki.ApiSnemalnikiUpdateRecorders
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiSnemalnikiUpdateRecorders.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiSnemalnikiUpdateRecorders
         * @function getTypeUrl
         * @memberof api_snemalniki.ApiSnemalnikiUpdateRecorders
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiSnemalnikiUpdateRecorders.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_snemalniki.ApiSnemalnikiUpdateRecorders";
        };

        return ApiSnemalnikiUpdateRecorders;
    })();

    api_snemalniki.ApiSnemalnikiUpdateAllRecorders = (function() {

        /**
         * Properties of an ApiSnemalnikiUpdateAllRecorders.
         * @memberof api_snemalniki
         * @interface IApiSnemalnikiUpdateAllRecorders
         * @property {number|Long|null} [fromTimestamp] ApiSnemalnikiUpdateAllRecorders fromTimestamp
         * @property {number|Long|null} [toTimestamp] ApiSnemalnikiUpdateAllRecorders toTimestamp
         */

        /**
         * Constructs a new ApiSnemalnikiUpdateAllRecorders.
         * @memberof api_snemalniki
         * @classdesc Represents an ApiSnemalnikiUpdateAllRecorders.
         * @implements IApiSnemalnikiUpdateAllRecorders
         * @constructor
         * @param {api_snemalniki.IApiSnemalnikiUpdateAllRecorders=} [properties] Properties to set
         */
        function ApiSnemalnikiUpdateAllRecorders(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiSnemalnikiUpdateAllRecorders fromTimestamp.
         * @member {number|Long} fromTimestamp
         * @memberof api_snemalniki.ApiSnemalnikiUpdateAllRecorders
         * @instance
         */
        ApiSnemalnikiUpdateAllRecorders.prototype.fromTimestamp = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ApiSnemalnikiUpdateAllRecorders toTimestamp.
         * @member {number|Long} toTimestamp
         * @memberof api_snemalniki.ApiSnemalnikiUpdateAllRecorders
         * @instance
         */
        ApiSnemalnikiUpdateAllRecorders.prototype.toTimestamp = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new ApiSnemalnikiUpdateAllRecorders instance using the specified properties.
         * @function create
         * @memberof api_snemalniki.ApiSnemalnikiUpdateAllRecorders
         * @static
         * @param {api_snemalniki.IApiSnemalnikiUpdateAllRecorders=} [properties] Properties to set
         * @returns {api_snemalniki.ApiSnemalnikiUpdateAllRecorders} ApiSnemalnikiUpdateAllRecorders instance
         */
        ApiSnemalnikiUpdateAllRecorders.create = function create(properties) {
            return new ApiSnemalnikiUpdateAllRecorders(properties);
        };

        /**
         * Encodes the specified ApiSnemalnikiUpdateAllRecorders message. Does not implicitly {@link api_snemalniki.ApiSnemalnikiUpdateAllRecorders.verify|verify} messages.
         * @function encode
         * @memberof api_snemalniki.ApiSnemalnikiUpdateAllRecorders
         * @static
         * @param {api_snemalniki.IApiSnemalnikiUpdateAllRecorders} message ApiSnemalnikiUpdateAllRecorders message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiSnemalnikiUpdateAllRecorders.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.fromTimestamp != null && Object.hasOwnProperty.call(message, "fromTimestamp"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.fromTimestamp);
            if (message.toTimestamp != null && Object.hasOwnProperty.call(message, "toTimestamp"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.toTimestamp);
            return writer;
        };

        /**
         * Encodes the specified ApiSnemalnikiUpdateAllRecorders message, length delimited. Does not implicitly {@link api_snemalniki.ApiSnemalnikiUpdateAllRecorders.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_snemalniki.ApiSnemalnikiUpdateAllRecorders
         * @static
         * @param {api_snemalniki.IApiSnemalnikiUpdateAllRecorders} message ApiSnemalnikiUpdateAllRecorders message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiSnemalnikiUpdateAllRecorders.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiSnemalnikiUpdateAllRecorders message from the specified reader or buffer.
         * @function decode
         * @memberof api_snemalniki.ApiSnemalnikiUpdateAllRecorders
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_snemalniki.ApiSnemalnikiUpdateAllRecorders} ApiSnemalnikiUpdateAllRecorders
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiSnemalnikiUpdateAllRecorders.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_snemalniki.ApiSnemalnikiUpdateAllRecorders();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.fromTimestamp = reader.uint64();
                        break;
                    }
                case 2: {
                        message.toTimestamp = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiSnemalnikiUpdateAllRecorders message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_snemalniki.ApiSnemalnikiUpdateAllRecorders
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_snemalniki.ApiSnemalnikiUpdateAllRecorders} ApiSnemalnikiUpdateAllRecorders
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiSnemalnikiUpdateAllRecorders.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiSnemalnikiUpdateAllRecorders message.
         * @function verify
         * @memberof api_snemalniki.ApiSnemalnikiUpdateAllRecorders
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiSnemalnikiUpdateAllRecorders.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.fromTimestamp != null && message.hasOwnProperty("fromTimestamp"))
                if (!$util.isInteger(message.fromTimestamp) && !(message.fromTimestamp && $util.isInteger(message.fromTimestamp.low) && $util.isInteger(message.fromTimestamp.high)))
                    return "fromTimestamp: integer|Long expected";
            if (message.toTimestamp != null && message.hasOwnProperty("toTimestamp"))
                if (!$util.isInteger(message.toTimestamp) && !(message.toTimestamp && $util.isInteger(message.toTimestamp.low) && $util.isInteger(message.toTimestamp.high)))
                    return "toTimestamp: integer|Long expected";
            return null;
        };

        /**
         * Creates an ApiSnemalnikiUpdateAllRecorders message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_snemalniki.ApiSnemalnikiUpdateAllRecorders
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_snemalniki.ApiSnemalnikiUpdateAllRecorders} ApiSnemalnikiUpdateAllRecorders
         */
        ApiSnemalnikiUpdateAllRecorders.fromObject = function fromObject(object) {
            if (object instanceof $root.api_snemalniki.ApiSnemalnikiUpdateAllRecorders)
                return object;
            let message = new $root.api_snemalniki.ApiSnemalnikiUpdateAllRecorders();
            if (object.fromTimestamp != null)
                if ($util.Long)
                    (message.fromTimestamp = $util.Long.fromValue(object.fromTimestamp)).unsigned = true;
                else if (typeof object.fromTimestamp === "string")
                    message.fromTimestamp = parseInt(object.fromTimestamp, 10);
                else if (typeof object.fromTimestamp === "number")
                    message.fromTimestamp = object.fromTimestamp;
                else if (typeof object.fromTimestamp === "object")
                    message.fromTimestamp = new $util.LongBits(object.fromTimestamp.low >>> 0, object.fromTimestamp.high >>> 0).toNumber(true);
            if (object.toTimestamp != null)
                if ($util.Long)
                    (message.toTimestamp = $util.Long.fromValue(object.toTimestamp)).unsigned = true;
                else if (typeof object.toTimestamp === "string")
                    message.toTimestamp = parseInt(object.toTimestamp, 10);
                else if (typeof object.toTimestamp === "number")
                    message.toTimestamp = object.toTimestamp;
                else if (typeof object.toTimestamp === "object")
                    message.toTimestamp = new $util.LongBits(object.toTimestamp.low >>> 0, object.toTimestamp.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from an ApiSnemalnikiUpdateAllRecorders message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_snemalniki.ApiSnemalnikiUpdateAllRecorders
         * @static
         * @param {api_snemalniki.ApiSnemalnikiUpdateAllRecorders} message ApiSnemalnikiUpdateAllRecorders
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiSnemalnikiUpdateAllRecorders.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.fromTimestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.fromTimestamp = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.toTimestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.toTimestamp = options.longs === String ? "0" : 0;
            }
            if (message.fromTimestamp != null && message.hasOwnProperty("fromTimestamp"))
                if (typeof message.fromTimestamp === "number")
                    object.fromTimestamp = options.longs === String ? String(message.fromTimestamp) : message.fromTimestamp;
                else
                    object.fromTimestamp = options.longs === String ? $util.Long.prototype.toString.call(message.fromTimestamp) : options.longs === Number ? new $util.LongBits(message.fromTimestamp.low >>> 0, message.fromTimestamp.high >>> 0).toNumber(true) : message.fromTimestamp;
            if (message.toTimestamp != null && message.hasOwnProperty("toTimestamp"))
                if (typeof message.toTimestamp === "number")
                    object.toTimestamp = options.longs === String ? String(message.toTimestamp) : message.toTimestamp;
                else
                    object.toTimestamp = options.longs === String ? $util.Long.prototype.toString.call(message.toTimestamp) : options.longs === Number ? new $util.LongBits(message.toTimestamp.low >>> 0, message.toTimestamp.high >>> 0).toNumber(true) : message.toTimestamp;
            return object;
        };

        /**
         * Converts this ApiSnemalnikiUpdateAllRecorders to JSON.
         * @function toJSON
         * @memberof api_snemalniki.ApiSnemalnikiUpdateAllRecorders
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiSnemalnikiUpdateAllRecorders.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiSnemalnikiUpdateAllRecorders
         * @function getTypeUrl
         * @memberof api_snemalniki.ApiSnemalnikiUpdateAllRecorders
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiSnemalnikiUpdateAllRecorders.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_snemalniki.ApiSnemalnikiUpdateAllRecorders";
        };

        return ApiSnemalnikiUpdateAllRecorders;
    })();

    api_snemalniki.ApiSnemalnikiCancelUpdateRecorders = (function() {

        /**
         * Properties of an ApiSnemalnikiCancelUpdateRecorders.
         * @memberof api_snemalniki
         * @interface IApiSnemalnikiCancelUpdateRecorders
         * @property {string|null} [updateId] ApiSnemalnikiCancelUpdateRecorders updateId
         */

        /**
         * Constructs a new ApiSnemalnikiCancelUpdateRecorders.
         * @memberof api_snemalniki
         * @classdesc Represents an ApiSnemalnikiCancelUpdateRecorders.
         * @implements IApiSnemalnikiCancelUpdateRecorders
         * @constructor
         * @param {api_snemalniki.IApiSnemalnikiCancelUpdateRecorders=} [properties] Properties to set
         */
        function ApiSnemalnikiCancelUpdateRecorders(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiSnemalnikiCancelUpdateRecorders updateId.
         * @member {string} updateId
         * @memberof api_snemalniki.ApiSnemalnikiCancelUpdateRecorders
         * @instance
         */
        ApiSnemalnikiCancelUpdateRecorders.prototype.updateId = "";

        /**
         * Creates a new ApiSnemalnikiCancelUpdateRecorders instance using the specified properties.
         * @function create
         * @memberof api_snemalniki.ApiSnemalnikiCancelUpdateRecorders
         * @static
         * @param {api_snemalniki.IApiSnemalnikiCancelUpdateRecorders=} [properties] Properties to set
         * @returns {api_snemalniki.ApiSnemalnikiCancelUpdateRecorders} ApiSnemalnikiCancelUpdateRecorders instance
         */
        ApiSnemalnikiCancelUpdateRecorders.create = function create(properties) {
            return new ApiSnemalnikiCancelUpdateRecorders(properties);
        };

        /**
         * Encodes the specified ApiSnemalnikiCancelUpdateRecorders message. Does not implicitly {@link api_snemalniki.ApiSnemalnikiCancelUpdateRecorders.verify|verify} messages.
         * @function encode
         * @memberof api_snemalniki.ApiSnemalnikiCancelUpdateRecorders
         * @static
         * @param {api_snemalniki.IApiSnemalnikiCancelUpdateRecorders} message ApiSnemalnikiCancelUpdateRecorders message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiSnemalnikiCancelUpdateRecorders.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.updateId != null && Object.hasOwnProperty.call(message, "updateId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.updateId);
            return writer;
        };

        /**
         * Encodes the specified ApiSnemalnikiCancelUpdateRecorders message, length delimited. Does not implicitly {@link api_snemalniki.ApiSnemalnikiCancelUpdateRecorders.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_snemalniki.ApiSnemalnikiCancelUpdateRecorders
         * @static
         * @param {api_snemalniki.IApiSnemalnikiCancelUpdateRecorders} message ApiSnemalnikiCancelUpdateRecorders message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiSnemalnikiCancelUpdateRecorders.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiSnemalnikiCancelUpdateRecorders message from the specified reader or buffer.
         * @function decode
         * @memberof api_snemalniki.ApiSnemalnikiCancelUpdateRecorders
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_snemalniki.ApiSnemalnikiCancelUpdateRecorders} ApiSnemalnikiCancelUpdateRecorders
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiSnemalnikiCancelUpdateRecorders.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_snemalniki.ApiSnemalnikiCancelUpdateRecorders();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.updateId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiSnemalnikiCancelUpdateRecorders message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_snemalniki.ApiSnemalnikiCancelUpdateRecorders
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_snemalniki.ApiSnemalnikiCancelUpdateRecorders} ApiSnemalnikiCancelUpdateRecorders
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiSnemalnikiCancelUpdateRecorders.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiSnemalnikiCancelUpdateRecorders message.
         * @function verify
         * @memberof api_snemalniki.ApiSnemalnikiCancelUpdateRecorders
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiSnemalnikiCancelUpdateRecorders.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.updateId != null && message.hasOwnProperty("updateId"))
                if (!$util.isString(message.updateId))
                    return "updateId: string expected";
            return null;
        };

        /**
         * Creates an ApiSnemalnikiCancelUpdateRecorders message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_snemalniki.ApiSnemalnikiCancelUpdateRecorders
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_snemalniki.ApiSnemalnikiCancelUpdateRecorders} ApiSnemalnikiCancelUpdateRecorders
         */
        ApiSnemalnikiCancelUpdateRecorders.fromObject = function fromObject(object) {
            if (object instanceof $root.api_snemalniki.ApiSnemalnikiCancelUpdateRecorders)
                return object;
            let message = new $root.api_snemalniki.ApiSnemalnikiCancelUpdateRecorders();
            if (object.updateId != null)
                message.updateId = String(object.updateId);
            return message;
        };

        /**
         * Creates a plain object from an ApiSnemalnikiCancelUpdateRecorders message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_snemalniki.ApiSnemalnikiCancelUpdateRecorders
         * @static
         * @param {api_snemalniki.ApiSnemalnikiCancelUpdateRecorders} message ApiSnemalnikiCancelUpdateRecorders
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiSnemalnikiCancelUpdateRecorders.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.updateId = "";
            if (message.updateId != null && message.hasOwnProperty("updateId"))
                object.updateId = message.updateId;
            return object;
        };

        /**
         * Converts this ApiSnemalnikiCancelUpdateRecorders to JSON.
         * @function toJSON
         * @memberof api_snemalniki.ApiSnemalnikiCancelUpdateRecorders
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiSnemalnikiCancelUpdateRecorders.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiSnemalnikiCancelUpdateRecorders
         * @function getTypeUrl
         * @memberof api_snemalniki.ApiSnemalnikiCancelUpdateRecorders
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiSnemalnikiCancelUpdateRecorders.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_snemalniki.ApiSnemalnikiCancelUpdateRecorders";
        };

        return ApiSnemalnikiCancelUpdateRecorders;
    })();

    api_snemalniki.ApiSnemalnikiUpdateRecorderDiskCapacity = (function() {

        /**
         * Properties of an ApiSnemalnikiUpdateRecorderDiskCapacity.
         * @memberof api_snemalniki
         * @interface IApiSnemalnikiUpdateRecorderDiskCapacity
         * @property {number|Long|null} [diskCapacity] ApiSnemalnikiUpdateRecorderDiskCapacity diskCapacity
         */

        /**
         * Constructs a new ApiSnemalnikiUpdateRecorderDiskCapacity.
         * @memberof api_snemalniki
         * @classdesc Represents an ApiSnemalnikiUpdateRecorderDiskCapacity.
         * @implements IApiSnemalnikiUpdateRecorderDiskCapacity
         * @constructor
         * @param {api_snemalniki.IApiSnemalnikiUpdateRecorderDiskCapacity=} [properties] Properties to set
         */
        function ApiSnemalnikiUpdateRecorderDiskCapacity(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiSnemalnikiUpdateRecorderDiskCapacity diskCapacity.
         * @member {number|Long} diskCapacity
         * @memberof api_snemalniki.ApiSnemalnikiUpdateRecorderDiskCapacity
         * @instance
         */
        ApiSnemalnikiUpdateRecorderDiskCapacity.prototype.diskCapacity = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new ApiSnemalnikiUpdateRecorderDiskCapacity instance using the specified properties.
         * @function create
         * @memberof api_snemalniki.ApiSnemalnikiUpdateRecorderDiskCapacity
         * @static
         * @param {api_snemalniki.IApiSnemalnikiUpdateRecorderDiskCapacity=} [properties] Properties to set
         * @returns {api_snemalniki.ApiSnemalnikiUpdateRecorderDiskCapacity} ApiSnemalnikiUpdateRecorderDiskCapacity instance
         */
        ApiSnemalnikiUpdateRecorderDiskCapacity.create = function create(properties) {
            return new ApiSnemalnikiUpdateRecorderDiskCapacity(properties);
        };

        /**
         * Encodes the specified ApiSnemalnikiUpdateRecorderDiskCapacity message. Does not implicitly {@link api_snemalniki.ApiSnemalnikiUpdateRecorderDiskCapacity.verify|verify} messages.
         * @function encode
         * @memberof api_snemalniki.ApiSnemalnikiUpdateRecorderDiskCapacity
         * @static
         * @param {api_snemalniki.IApiSnemalnikiUpdateRecorderDiskCapacity} message ApiSnemalnikiUpdateRecorderDiskCapacity message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiSnemalnikiUpdateRecorderDiskCapacity.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.diskCapacity != null && Object.hasOwnProperty.call(message, "diskCapacity"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.diskCapacity);
            return writer;
        };

        /**
         * Encodes the specified ApiSnemalnikiUpdateRecorderDiskCapacity message, length delimited. Does not implicitly {@link api_snemalniki.ApiSnemalnikiUpdateRecorderDiskCapacity.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_snemalniki.ApiSnemalnikiUpdateRecorderDiskCapacity
         * @static
         * @param {api_snemalniki.IApiSnemalnikiUpdateRecorderDiskCapacity} message ApiSnemalnikiUpdateRecorderDiskCapacity message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiSnemalnikiUpdateRecorderDiskCapacity.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiSnemalnikiUpdateRecorderDiskCapacity message from the specified reader or buffer.
         * @function decode
         * @memberof api_snemalniki.ApiSnemalnikiUpdateRecorderDiskCapacity
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_snemalniki.ApiSnemalnikiUpdateRecorderDiskCapacity} ApiSnemalnikiUpdateRecorderDiskCapacity
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiSnemalnikiUpdateRecorderDiskCapacity.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_snemalniki.ApiSnemalnikiUpdateRecorderDiskCapacity();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.diskCapacity = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiSnemalnikiUpdateRecorderDiskCapacity message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_snemalniki.ApiSnemalnikiUpdateRecorderDiskCapacity
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_snemalniki.ApiSnemalnikiUpdateRecorderDiskCapacity} ApiSnemalnikiUpdateRecorderDiskCapacity
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiSnemalnikiUpdateRecorderDiskCapacity.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiSnemalnikiUpdateRecorderDiskCapacity message.
         * @function verify
         * @memberof api_snemalniki.ApiSnemalnikiUpdateRecorderDiskCapacity
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiSnemalnikiUpdateRecorderDiskCapacity.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.diskCapacity != null && message.hasOwnProperty("diskCapacity"))
                if (!$util.isInteger(message.diskCapacity) && !(message.diskCapacity && $util.isInteger(message.diskCapacity.low) && $util.isInteger(message.diskCapacity.high)))
                    return "diskCapacity: integer|Long expected";
            return null;
        };

        /**
         * Creates an ApiSnemalnikiUpdateRecorderDiskCapacity message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_snemalniki.ApiSnemalnikiUpdateRecorderDiskCapacity
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_snemalniki.ApiSnemalnikiUpdateRecorderDiskCapacity} ApiSnemalnikiUpdateRecorderDiskCapacity
         */
        ApiSnemalnikiUpdateRecorderDiskCapacity.fromObject = function fromObject(object) {
            if (object instanceof $root.api_snemalniki.ApiSnemalnikiUpdateRecorderDiskCapacity)
                return object;
            let message = new $root.api_snemalniki.ApiSnemalnikiUpdateRecorderDiskCapacity();
            if (object.diskCapacity != null)
                if ($util.Long)
                    (message.diskCapacity = $util.Long.fromValue(object.diskCapacity)).unsigned = false;
                else if (typeof object.diskCapacity === "string")
                    message.diskCapacity = parseInt(object.diskCapacity, 10);
                else if (typeof object.diskCapacity === "number")
                    message.diskCapacity = object.diskCapacity;
                else if (typeof object.diskCapacity === "object")
                    message.diskCapacity = new $util.LongBits(object.diskCapacity.low >>> 0, object.diskCapacity.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from an ApiSnemalnikiUpdateRecorderDiskCapacity message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_snemalniki.ApiSnemalnikiUpdateRecorderDiskCapacity
         * @static
         * @param {api_snemalniki.ApiSnemalnikiUpdateRecorderDiskCapacity} message ApiSnemalnikiUpdateRecorderDiskCapacity
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiSnemalnikiUpdateRecorderDiskCapacity.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.diskCapacity = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.diskCapacity = options.longs === String ? "0" : 0;
            if (message.diskCapacity != null && message.hasOwnProperty("diskCapacity"))
                if (typeof message.diskCapacity === "number")
                    object.diskCapacity = options.longs === String ? String(message.diskCapacity) : message.diskCapacity;
                else
                    object.diskCapacity = options.longs === String ? $util.Long.prototype.toString.call(message.diskCapacity) : options.longs === Number ? new $util.LongBits(message.diskCapacity.low >>> 0, message.diskCapacity.high >>> 0).toNumber() : message.diskCapacity;
            return object;
        };

        /**
         * Converts this ApiSnemalnikiUpdateRecorderDiskCapacity to JSON.
         * @function toJSON
         * @memberof api_snemalniki.ApiSnemalnikiUpdateRecorderDiskCapacity
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiSnemalnikiUpdateRecorderDiskCapacity.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiSnemalnikiUpdateRecorderDiskCapacity
         * @function getTypeUrl
         * @memberof api_snemalniki.ApiSnemalnikiUpdateRecorderDiskCapacity
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiSnemalnikiUpdateRecorderDiskCapacity.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_snemalniki.ApiSnemalnikiUpdateRecorderDiskCapacity";
        };

        return ApiSnemalnikiUpdateRecorderDiskCapacity;
    })();

    api_snemalniki.ApiSnemalnikiUpdateRecorderDiskAmountFilled = (function() {

        /**
         * Properties of an ApiSnemalnikiUpdateRecorderDiskAmountFilled.
         * @memberof api_snemalniki
         * @interface IApiSnemalnikiUpdateRecorderDiskAmountFilled
         * @property {number|Long|null} [diskAmountFilled] ApiSnemalnikiUpdateRecorderDiskAmountFilled diskAmountFilled
         */

        /**
         * Constructs a new ApiSnemalnikiUpdateRecorderDiskAmountFilled.
         * @memberof api_snemalniki
         * @classdesc Represents an ApiSnemalnikiUpdateRecorderDiskAmountFilled.
         * @implements IApiSnemalnikiUpdateRecorderDiskAmountFilled
         * @constructor
         * @param {api_snemalniki.IApiSnemalnikiUpdateRecorderDiskAmountFilled=} [properties] Properties to set
         */
        function ApiSnemalnikiUpdateRecorderDiskAmountFilled(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiSnemalnikiUpdateRecorderDiskAmountFilled diskAmountFilled.
         * @member {number|Long} diskAmountFilled
         * @memberof api_snemalniki.ApiSnemalnikiUpdateRecorderDiskAmountFilled
         * @instance
         */
        ApiSnemalnikiUpdateRecorderDiskAmountFilled.prototype.diskAmountFilled = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new ApiSnemalnikiUpdateRecorderDiskAmountFilled instance using the specified properties.
         * @function create
         * @memberof api_snemalniki.ApiSnemalnikiUpdateRecorderDiskAmountFilled
         * @static
         * @param {api_snemalniki.IApiSnemalnikiUpdateRecorderDiskAmountFilled=} [properties] Properties to set
         * @returns {api_snemalniki.ApiSnemalnikiUpdateRecorderDiskAmountFilled} ApiSnemalnikiUpdateRecorderDiskAmountFilled instance
         */
        ApiSnemalnikiUpdateRecorderDiskAmountFilled.create = function create(properties) {
            return new ApiSnemalnikiUpdateRecorderDiskAmountFilled(properties);
        };

        /**
         * Encodes the specified ApiSnemalnikiUpdateRecorderDiskAmountFilled message. Does not implicitly {@link api_snemalniki.ApiSnemalnikiUpdateRecorderDiskAmountFilled.verify|verify} messages.
         * @function encode
         * @memberof api_snemalniki.ApiSnemalnikiUpdateRecorderDiskAmountFilled
         * @static
         * @param {api_snemalniki.IApiSnemalnikiUpdateRecorderDiskAmountFilled} message ApiSnemalnikiUpdateRecorderDiskAmountFilled message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiSnemalnikiUpdateRecorderDiskAmountFilled.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.diskAmountFilled != null && Object.hasOwnProperty.call(message, "diskAmountFilled"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.diskAmountFilled);
            return writer;
        };

        /**
         * Encodes the specified ApiSnemalnikiUpdateRecorderDiskAmountFilled message, length delimited. Does not implicitly {@link api_snemalniki.ApiSnemalnikiUpdateRecorderDiskAmountFilled.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_snemalniki.ApiSnemalnikiUpdateRecorderDiskAmountFilled
         * @static
         * @param {api_snemalniki.IApiSnemalnikiUpdateRecorderDiskAmountFilled} message ApiSnemalnikiUpdateRecorderDiskAmountFilled message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiSnemalnikiUpdateRecorderDiskAmountFilled.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiSnemalnikiUpdateRecorderDiskAmountFilled message from the specified reader or buffer.
         * @function decode
         * @memberof api_snemalniki.ApiSnemalnikiUpdateRecorderDiskAmountFilled
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_snemalniki.ApiSnemalnikiUpdateRecorderDiskAmountFilled} ApiSnemalnikiUpdateRecorderDiskAmountFilled
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiSnemalnikiUpdateRecorderDiskAmountFilled.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_snemalniki.ApiSnemalnikiUpdateRecorderDiskAmountFilled();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.diskAmountFilled = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiSnemalnikiUpdateRecorderDiskAmountFilled message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_snemalniki.ApiSnemalnikiUpdateRecorderDiskAmountFilled
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_snemalniki.ApiSnemalnikiUpdateRecorderDiskAmountFilled} ApiSnemalnikiUpdateRecorderDiskAmountFilled
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiSnemalnikiUpdateRecorderDiskAmountFilled.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiSnemalnikiUpdateRecorderDiskAmountFilled message.
         * @function verify
         * @memberof api_snemalniki.ApiSnemalnikiUpdateRecorderDiskAmountFilled
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiSnemalnikiUpdateRecorderDiskAmountFilled.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.diskAmountFilled != null && message.hasOwnProperty("diskAmountFilled"))
                if (!$util.isInteger(message.diskAmountFilled) && !(message.diskAmountFilled && $util.isInteger(message.diskAmountFilled.low) && $util.isInteger(message.diskAmountFilled.high)))
                    return "diskAmountFilled: integer|Long expected";
            return null;
        };

        /**
         * Creates an ApiSnemalnikiUpdateRecorderDiskAmountFilled message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_snemalniki.ApiSnemalnikiUpdateRecorderDiskAmountFilled
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_snemalniki.ApiSnemalnikiUpdateRecorderDiskAmountFilled} ApiSnemalnikiUpdateRecorderDiskAmountFilled
         */
        ApiSnemalnikiUpdateRecorderDiskAmountFilled.fromObject = function fromObject(object) {
            if (object instanceof $root.api_snemalniki.ApiSnemalnikiUpdateRecorderDiskAmountFilled)
                return object;
            let message = new $root.api_snemalniki.ApiSnemalnikiUpdateRecorderDiskAmountFilled();
            if (object.diskAmountFilled != null)
                if ($util.Long)
                    (message.diskAmountFilled = $util.Long.fromValue(object.diskAmountFilled)).unsigned = false;
                else if (typeof object.diskAmountFilled === "string")
                    message.diskAmountFilled = parseInt(object.diskAmountFilled, 10);
                else if (typeof object.diskAmountFilled === "number")
                    message.diskAmountFilled = object.diskAmountFilled;
                else if (typeof object.diskAmountFilled === "object")
                    message.diskAmountFilled = new $util.LongBits(object.diskAmountFilled.low >>> 0, object.diskAmountFilled.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from an ApiSnemalnikiUpdateRecorderDiskAmountFilled message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_snemalniki.ApiSnemalnikiUpdateRecorderDiskAmountFilled
         * @static
         * @param {api_snemalniki.ApiSnemalnikiUpdateRecorderDiskAmountFilled} message ApiSnemalnikiUpdateRecorderDiskAmountFilled
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiSnemalnikiUpdateRecorderDiskAmountFilled.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.diskAmountFilled = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.diskAmountFilled = options.longs === String ? "0" : 0;
            if (message.diskAmountFilled != null && message.hasOwnProperty("diskAmountFilled"))
                if (typeof message.diskAmountFilled === "number")
                    object.diskAmountFilled = options.longs === String ? String(message.diskAmountFilled) : message.diskAmountFilled;
                else
                    object.diskAmountFilled = options.longs === String ? $util.Long.prototype.toString.call(message.diskAmountFilled) : options.longs === Number ? new $util.LongBits(message.diskAmountFilled.low >>> 0, message.diskAmountFilled.high >>> 0).toNumber() : message.diskAmountFilled;
            return object;
        };

        /**
         * Converts this ApiSnemalnikiUpdateRecorderDiskAmountFilled to JSON.
         * @function toJSON
         * @memberof api_snemalniki.ApiSnemalnikiUpdateRecorderDiskAmountFilled
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiSnemalnikiUpdateRecorderDiskAmountFilled.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiSnemalnikiUpdateRecorderDiskAmountFilled
         * @function getTypeUrl
         * @memberof api_snemalniki.ApiSnemalnikiUpdateRecorderDiskAmountFilled
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiSnemalnikiUpdateRecorderDiskAmountFilled.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_snemalniki.ApiSnemalnikiUpdateRecorderDiskAmountFilled";
        };

        return ApiSnemalnikiUpdateRecorderDiskAmountFilled;
    })();

    return api_snemalniki;
})();

export const asod_auth = $root.asod_auth = (() => {

    /**
     * Namespace asod_auth.
     * @exports asod_auth
     * @namespace
     */
    const asod_auth = {};

    asod_auth.AsodLogout = (function() {

        /**
         * Properties of an AsodLogout.
         * @memberof asod_auth
         * @interface IAsodLogout
         */

        /**
         * Constructs a new AsodLogout.
         * @memberof asod_auth
         * @classdesc Represents an AsodLogout.
         * @implements IAsodLogout
         * @constructor
         * @param {asod_auth.IAsodLogout=} [properties] Properties to set
         */
        function AsodLogout(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new AsodLogout instance using the specified properties.
         * @function create
         * @memberof asod_auth.AsodLogout
         * @static
         * @param {asod_auth.IAsodLogout=} [properties] Properties to set
         * @returns {asod_auth.AsodLogout} AsodLogout instance
         */
        AsodLogout.create = function create(properties) {
            return new AsodLogout(properties);
        };

        /**
         * Encodes the specified AsodLogout message. Does not implicitly {@link asod_auth.AsodLogout.verify|verify} messages.
         * @function encode
         * @memberof asod_auth.AsodLogout
         * @static
         * @param {asod_auth.IAsodLogout} message AsodLogout message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodLogout.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified AsodLogout message, length delimited. Does not implicitly {@link asod_auth.AsodLogout.verify|verify} messages.
         * @function encodeDelimited
         * @memberof asod_auth.AsodLogout
         * @static
         * @param {asod_auth.IAsodLogout} message AsodLogout message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodLogout.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AsodLogout message from the specified reader or buffer.
         * @function decode
         * @memberof asod_auth.AsodLogout
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {asod_auth.AsodLogout} AsodLogout
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodLogout.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.asod_auth.AsodLogout();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AsodLogout message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof asod_auth.AsodLogout
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {asod_auth.AsodLogout} AsodLogout
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodLogout.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AsodLogout message.
         * @function verify
         * @memberof asod_auth.AsodLogout
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AsodLogout.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an AsodLogout message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof asod_auth.AsodLogout
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {asod_auth.AsodLogout} AsodLogout
         */
        AsodLogout.fromObject = function fromObject(object) {
            if (object instanceof $root.asod_auth.AsodLogout)
                return object;
            return new $root.asod_auth.AsodLogout();
        };

        /**
         * Creates a plain object from an AsodLogout message. Also converts values to other types if specified.
         * @function toObject
         * @memberof asod_auth.AsodLogout
         * @static
         * @param {asod_auth.AsodLogout} message AsodLogout
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AsodLogout.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this AsodLogout to JSON.
         * @function toJSON
         * @memberof asod_auth.AsodLogout
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AsodLogout.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AsodLogout
         * @function getTypeUrl
         * @memberof asod_auth.AsodLogout
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AsodLogout.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/asod_auth.AsodLogout";
        };

        return AsodLogout;
    })();

    return asod_auth;
})();

export const ws_project = $root.ws_project = (() => {

    /**
     * Namespace ws_project.
     * @exports ws_project
     * @namespace
     */
    const ws_project = {};

    ws_project.LoadAsodUsers = (function() {

        /**
         * Properties of a LoadAsodUsers.
         * @memberof ws_project
         * @interface ILoadAsodUsers
         * @property {string|null} [quickSearch] LoadAsodUsers quickSearch
         * @property {string|null} [court] LoadAsodUsers court
         * @property {string|null} [department] LoadAsodUsers department
         * @property {string|null} [role] LoadAsodUsers role
         * @property {common.IPagination|null} [pagination] LoadAsodUsers pagination
         * @property {ws_project.AsodUsersOrderingTerm|null} [sortBy] LoadAsodUsers sortBy
         * @property {common.SortOrder|null} [sortOrder] LoadAsodUsers sortOrder
         * @property {ws_project.IAsodUsers|null} [_response] LoadAsodUsers _response
         */

        /**
         * Constructs a new LoadAsodUsers.
         * @memberof ws_project
         * @classdesc Represents a LoadAsodUsers.
         * @implements ILoadAsodUsers
         * @constructor
         * @param {ws_project.ILoadAsodUsers=} [properties] Properties to set
         */
        function LoadAsodUsers(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LoadAsodUsers quickSearch.
         * @member {string} quickSearch
         * @memberof ws_project.LoadAsodUsers
         * @instance
         */
        LoadAsodUsers.prototype.quickSearch = "";

        /**
         * LoadAsodUsers court.
         * @member {string} court
         * @memberof ws_project.LoadAsodUsers
         * @instance
         */
        LoadAsodUsers.prototype.court = "";

        /**
         * LoadAsodUsers department.
         * @member {string} department
         * @memberof ws_project.LoadAsodUsers
         * @instance
         */
        LoadAsodUsers.prototype.department = "";

        /**
         * LoadAsodUsers role.
         * @member {string} role
         * @memberof ws_project.LoadAsodUsers
         * @instance
         */
        LoadAsodUsers.prototype.role = "";

        /**
         * LoadAsodUsers pagination.
         * @member {common.IPagination|null|undefined} pagination
         * @memberof ws_project.LoadAsodUsers
         * @instance
         */
        LoadAsodUsers.prototype.pagination = null;

        /**
         * LoadAsodUsers sortBy.
         * @member {ws_project.AsodUsersOrderingTerm} sortBy
         * @memberof ws_project.LoadAsodUsers
         * @instance
         */
        LoadAsodUsers.prototype.sortBy = 0;

        /**
         * LoadAsodUsers sortOrder.
         * @member {common.SortOrder} sortOrder
         * @memberof ws_project.LoadAsodUsers
         * @instance
         */
        LoadAsodUsers.prototype.sortOrder = 0;

        /**
         * LoadAsodUsers _response.
         * @member {ws_project.IAsodUsers|null|undefined} _response
         * @memberof ws_project.LoadAsodUsers
         * @instance
         */
        LoadAsodUsers.prototype._response = null;

        /**
         * Creates a new LoadAsodUsers instance using the specified properties.
         * @function create
         * @memberof ws_project.LoadAsodUsers
         * @static
         * @param {ws_project.ILoadAsodUsers=} [properties] Properties to set
         * @returns {ws_project.LoadAsodUsers} LoadAsodUsers instance
         */
        LoadAsodUsers.create = function create(properties) {
            return new LoadAsodUsers(properties);
        };

        /**
         * Encodes the specified LoadAsodUsers message. Does not implicitly {@link ws_project.LoadAsodUsers.verify|verify} messages.
         * @function encode
         * @memberof ws_project.LoadAsodUsers
         * @static
         * @param {ws_project.ILoadAsodUsers} message LoadAsodUsers message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoadAsodUsers.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.quickSearch != null && Object.hasOwnProperty.call(message, "quickSearch"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.quickSearch);
            if (message.court != null && Object.hasOwnProperty.call(message, "court"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.court);
            if (message.department != null && Object.hasOwnProperty.call(message, "department"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.department);
            if (message.role != null && Object.hasOwnProperty.call(message, "role"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.role);
            if (message.pagination != null && Object.hasOwnProperty.call(message, "pagination"))
                $root.common.Pagination.encode(message.pagination, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.sortBy != null && Object.hasOwnProperty.call(message, "sortBy"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.sortBy);
            if (message.sortOrder != null && Object.hasOwnProperty.call(message, "sortOrder"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.sortOrder);
            if (message._response != null && Object.hasOwnProperty.call(message, "_response"))
                $root.ws_project.AsodUsers.encode(message._response, writer.uint32(/* id 99, wireType 2 =*/794).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified LoadAsodUsers message, length delimited. Does not implicitly {@link ws_project.LoadAsodUsers.verify|verify} messages.
         * @function encodeDelimited
         * @memberof ws_project.LoadAsodUsers
         * @static
         * @param {ws_project.ILoadAsodUsers} message LoadAsodUsers message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoadAsodUsers.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LoadAsodUsers message from the specified reader or buffer.
         * @function decode
         * @memberof ws_project.LoadAsodUsers
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {ws_project.LoadAsodUsers} LoadAsodUsers
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoadAsodUsers.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ws_project.LoadAsodUsers();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.quickSearch = reader.string();
                        break;
                    }
                case 2: {
                        message.court = reader.string();
                        break;
                    }
                case 3: {
                        message.department = reader.string();
                        break;
                    }
                case 4: {
                        message.role = reader.string();
                        break;
                    }
                case 5: {
                        message.pagination = $root.common.Pagination.decode(reader, reader.uint32());
                        break;
                    }
                case 6: {
                        message.sortBy = reader.int32();
                        break;
                    }
                case 7: {
                        message.sortOrder = reader.int32();
                        break;
                    }
                case 99: {
                        message._response = $root.ws_project.AsodUsers.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LoadAsodUsers message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof ws_project.LoadAsodUsers
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {ws_project.LoadAsodUsers} LoadAsodUsers
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoadAsodUsers.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LoadAsodUsers message.
         * @function verify
         * @memberof ws_project.LoadAsodUsers
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LoadAsodUsers.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.quickSearch != null && message.hasOwnProperty("quickSearch"))
                if (!$util.isString(message.quickSearch))
                    return "quickSearch: string expected";
            if (message.court != null && message.hasOwnProperty("court"))
                if (!$util.isString(message.court))
                    return "court: string expected";
            if (message.department != null && message.hasOwnProperty("department"))
                if (!$util.isString(message.department))
                    return "department: string expected";
            if (message.role != null && message.hasOwnProperty("role"))
                if (!$util.isString(message.role))
                    return "role: string expected";
            if (message.pagination != null && message.hasOwnProperty("pagination")) {
                let error = $root.common.Pagination.verify(message.pagination);
                if (error)
                    return "pagination." + error;
            }
            if (message.sortBy != null && message.hasOwnProperty("sortBy"))
                switch (message.sortBy) {
                default:
                    return "sortBy: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                    break;
                }
            if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                switch (message.sortOrder) {
                default:
                    return "sortOrder: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message._response != null && message.hasOwnProperty("_response")) {
                let error = $root.ws_project.AsodUsers.verify(message._response);
                if (error)
                    return "_response." + error;
            }
            return null;
        };

        /**
         * Creates a LoadAsodUsers message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof ws_project.LoadAsodUsers
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {ws_project.LoadAsodUsers} LoadAsodUsers
         */
        LoadAsodUsers.fromObject = function fromObject(object) {
            if (object instanceof $root.ws_project.LoadAsodUsers)
                return object;
            let message = new $root.ws_project.LoadAsodUsers();
            if (object.quickSearch != null)
                message.quickSearch = String(object.quickSearch);
            if (object.court != null)
                message.court = String(object.court);
            if (object.department != null)
                message.department = String(object.department);
            if (object.role != null)
                message.role = String(object.role);
            if (object.pagination != null) {
                if (typeof object.pagination !== "object")
                    throw TypeError(".ws_project.LoadAsodUsers.pagination: object expected");
                message.pagination = $root.common.Pagination.fromObject(object.pagination);
            }
            switch (object.sortBy) {
            default:
                if (typeof object.sortBy === "number") {
                    message.sortBy = object.sortBy;
                    break;
                }
                break;
            case "auot_court":
            case 0:
                message.sortBy = 0;
                break;
            case "auot_name_surname":
            case 1:
                message.sortBy = 1;
                break;
            case "auot_department":
            case 2:
                message.sortBy = 2;
                break;
            case "auot_role":
            case 3:
                message.sortBy = 3;
                break;
            case "auot_last_login":
            case 4:
                message.sortBy = 4;
                break;
            }
            switch (object.sortOrder) {
            default:
                if (typeof object.sortOrder === "number") {
                    message.sortOrder = object.sortOrder;
                    break;
                }
                break;
            case "so_none":
            case 0:
                message.sortOrder = 0;
                break;
            case "so_asc":
            case 1:
                message.sortOrder = 1;
                break;
            case "so_desc":
            case 2:
                message.sortOrder = 2;
                break;
            }
            if (object._response != null) {
                if (typeof object._response !== "object")
                    throw TypeError(".ws_project.LoadAsodUsers._response: object expected");
                message._response = $root.ws_project.AsodUsers.fromObject(object._response);
            }
            return message;
        };

        /**
         * Creates a plain object from a LoadAsodUsers message. Also converts values to other types if specified.
         * @function toObject
         * @memberof ws_project.LoadAsodUsers
         * @static
         * @param {ws_project.LoadAsodUsers} message LoadAsodUsers
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LoadAsodUsers.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.quickSearch = "";
                object.court = "";
                object.department = "";
                object.role = "";
                object.pagination = null;
                object.sortBy = options.enums === String ? "auot_court" : 0;
                object.sortOrder = options.enums === String ? "so_none" : 0;
                object._response = null;
            }
            if (message.quickSearch != null && message.hasOwnProperty("quickSearch"))
                object.quickSearch = message.quickSearch;
            if (message.court != null && message.hasOwnProperty("court"))
                object.court = message.court;
            if (message.department != null && message.hasOwnProperty("department"))
                object.department = message.department;
            if (message.role != null && message.hasOwnProperty("role"))
                object.role = message.role;
            if (message.pagination != null && message.hasOwnProperty("pagination"))
                object.pagination = $root.common.Pagination.toObject(message.pagination, options);
            if (message.sortBy != null && message.hasOwnProperty("sortBy"))
                object.sortBy = options.enums === String ? $root.ws_project.AsodUsersOrderingTerm[message.sortBy] === undefined ? message.sortBy : $root.ws_project.AsodUsersOrderingTerm[message.sortBy] : message.sortBy;
            if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                object.sortOrder = options.enums === String ? $root.common.SortOrder[message.sortOrder] === undefined ? message.sortOrder : $root.common.SortOrder[message.sortOrder] : message.sortOrder;
            if (message._response != null && message.hasOwnProperty("_response"))
                object._response = $root.ws_project.AsodUsers.toObject(message._response, options);
            return object;
        };

        /**
         * Converts this LoadAsodUsers to JSON.
         * @function toJSON
         * @memberof ws_project.LoadAsodUsers
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LoadAsodUsers.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LoadAsodUsers
         * @function getTypeUrl
         * @memberof ws_project.LoadAsodUsers
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LoadAsodUsers.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/ws_project.LoadAsodUsers";
        };

        return LoadAsodUsers;
    })();

    ws_project.AsodUsers = (function() {

        /**
         * Properties of an AsodUsers.
         * @memberof ws_project
         * @interface IAsodUsers
         * @property {Array.<ws_project.IAsodUsersRow>|null} [rows] AsodUsers rows
         * @property {common.IPagination|null} [pagination] AsodUsers pagination
         * @property {ws_project.AsodUsersOrderingTerm|null} [sortBy] AsodUsers sortBy
         * @property {common.SortOrder|null} [sortOrder] AsodUsers sortOrder
         */

        /**
         * Constructs a new AsodUsers.
         * @memberof ws_project
         * @classdesc Represents an AsodUsers.
         * @implements IAsodUsers
         * @constructor
         * @param {ws_project.IAsodUsers=} [properties] Properties to set
         */
        function AsodUsers(properties) {
            this.rows = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AsodUsers rows.
         * @member {Array.<ws_project.IAsodUsersRow>} rows
         * @memberof ws_project.AsodUsers
         * @instance
         */
        AsodUsers.prototype.rows = $util.emptyArray;

        /**
         * AsodUsers pagination.
         * @member {common.IPagination|null|undefined} pagination
         * @memberof ws_project.AsodUsers
         * @instance
         */
        AsodUsers.prototype.pagination = null;

        /**
         * AsodUsers sortBy.
         * @member {ws_project.AsodUsersOrderingTerm} sortBy
         * @memberof ws_project.AsodUsers
         * @instance
         */
        AsodUsers.prototype.sortBy = 0;

        /**
         * AsodUsers sortOrder.
         * @member {common.SortOrder} sortOrder
         * @memberof ws_project.AsodUsers
         * @instance
         */
        AsodUsers.prototype.sortOrder = 0;

        /**
         * Creates a new AsodUsers instance using the specified properties.
         * @function create
         * @memberof ws_project.AsodUsers
         * @static
         * @param {ws_project.IAsodUsers=} [properties] Properties to set
         * @returns {ws_project.AsodUsers} AsodUsers instance
         */
        AsodUsers.create = function create(properties) {
            return new AsodUsers(properties);
        };

        /**
         * Encodes the specified AsodUsers message. Does not implicitly {@link ws_project.AsodUsers.verify|verify} messages.
         * @function encode
         * @memberof ws_project.AsodUsers
         * @static
         * @param {ws_project.IAsodUsers} message AsodUsers message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodUsers.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rows != null && message.rows.length)
                for (let i = 0; i < message.rows.length; ++i)
                    $root.ws_project.AsodUsersRow.encode(message.rows[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.pagination != null && Object.hasOwnProperty.call(message, "pagination"))
                $root.common.Pagination.encode(message.pagination, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.sortBy != null && Object.hasOwnProperty.call(message, "sortBy"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.sortBy);
            if (message.sortOrder != null && Object.hasOwnProperty.call(message, "sortOrder"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.sortOrder);
            return writer;
        };

        /**
         * Encodes the specified AsodUsers message, length delimited. Does not implicitly {@link ws_project.AsodUsers.verify|verify} messages.
         * @function encodeDelimited
         * @memberof ws_project.AsodUsers
         * @static
         * @param {ws_project.IAsodUsers} message AsodUsers message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodUsers.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AsodUsers message from the specified reader or buffer.
         * @function decode
         * @memberof ws_project.AsodUsers
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {ws_project.AsodUsers} AsodUsers
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodUsers.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ws_project.AsodUsers();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.rows && message.rows.length))
                            message.rows = [];
                        message.rows.push($root.ws_project.AsodUsersRow.decode(reader, reader.uint32()));
                        break;
                    }
                case 6: {
                        message.pagination = $root.common.Pagination.decode(reader, reader.uint32());
                        break;
                    }
                case 7: {
                        message.sortBy = reader.int32();
                        break;
                    }
                case 8: {
                        message.sortOrder = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AsodUsers message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof ws_project.AsodUsers
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {ws_project.AsodUsers} AsodUsers
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodUsers.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AsodUsers message.
         * @function verify
         * @memberof ws_project.AsodUsers
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AsodUsers.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.rows != null && message.hasOwnProperty("rows")) {
                if (!Array.isArray(message.rows))
                    return "rows: array expected";
                for (let i = 0; i < message.rows.length; ++i) {
                    let error = $root.ws_project.AsodUsersRow.verify(message.rows[i]);
                    if (error)
                        return "rows." + error;
                }
            }
            if (message.pagination != null && message.hasOwnProperty("pagination")) {
                let error = $root.common.Pagination.verify(message.pagination);
                if (error)
                    return "pagination." + error;
            }
            if (message.sortBy != null && message.hasOwnProperty("sortBy"))
                switch (message.sortBy) {
                default:
                    return "sortBy: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                    break;
                }
            if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                switch (message.sortOrder) {
                default:
                    return "sortOrder: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            return null;
        };

        /**
         * Creates an AsodUsers message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof ws_project.AsodUsers
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {ws_project.AsodUsers} AsodUsers
         */
        AsodUsers.fromObject = function fromObject(object) {
            if (object instanceof $root.ws_project.AsodUsers)
                return object;
            let message = new $root.ws_project.AsodUsers();
            if (object.rows) {
                if (!Array.isArray(object.rows))
                    throw TypeError(".ws_project.AsodUsers.rows: array expected");
                message.rows = [];
                for (let i = 0; i < object.rows.length; ++i) {
                    if (typeof object.rows[i] !== "object")
                        throw TypeError(".ws_project.AsodUsers.rows: object expected");
                    message.rows[i] = $root.ws_project.AsodUsersRow.fromObject(object.rows[i]);
                }
            }
            if (object.pagination != null) {
                if (typeof object.pagination !== "object")
                    throw TypeError(".ws_project.AsodUsers.pagination: object expected");
                message.pagination = $root.common.Pagination.fromObject(object.pagination);
            }
            switch (object.sortBy) {
            default:
                if (typeof object.sortBy === "number") {
                    message.sortBy = object.sortBy;
                    break;
                }
                break;
            case "auot_court":
            case 0:
                message.sortBy = 0;
                break;
            case "auot_name_surname":
            case 1:
                message.sortBy = 1;
                break;
            case "auot_department":
            case 2:
                message.sortBy = 2;
                break;
            case "auot_role":
            case 3:
                message.sortBy = 3;
                break;
            case "auot_last_login":
            case 4:
                message.sortBy = 4;
                break;
            }
            switch (object.sortOrder) {
            default:
                if (typeof object.sortOrder === "number") {
                    message.sortOrder = object.sortOrder;
                    break;
                }
                break;
            case "so_none":
            case 0:
                message.sortOrder = 0;
                break;
            case "so_asc":
            case 1:
                message.sortOrder = 1;
                break;
            case "so_desc":
            case 2:
                message.sortOrder = 2;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from an AsodUsers message. Also converts values to other types if specified.
         * @function toObject
         * @memberof ws_project.AsodUsers
         * @static
         * @param {ws_project.AsodUsers} message AsodUsers
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AsodUsers.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.rows = [];
            if (options.defaults) {
                object.pagination = null;
                object.sortBy = options.enums === String ? "auot_court" : 0;
                object.sortOrder = options.enums === String ? "so_none" : 0;
            }
            if (message.rows && message.rows.length) {
                object.rows = [];
                for (let j = 0; j < message.rows.length; ++j)
                    object.rows[j] = $root.ws_project.AsodUsersRow.toObject(message.rows[j], options);
            }
            if (message.pagination != null && message.hasOwnProperty("pagination"))
                object.pagination = $root.common.Pagination.toObject(message.pagination, options);
            if (message.sortBy != null && message.hasOwnProperty("sortBy"))
                object.sortBy = options.enums === String ? $root.ws_project.AsodUsersOrderingTerm[message.sortBy] === undefined ? message.sortBy : $root.ws_project.AsodUsersOrderingTerm[message.sortBy] : message.sortBy;
            if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                object.sortOrder = options.enums === String ? $root.common.SortOrder[message.sortOrder] === undefined ? message.sortOrder : $root.common.SortOrder[message.sortOrder] : message.sortOrder;
            return object;
        };

        /**
         * Converts this AsodUsers to JSON.
         * @function toJSON
         * @memberof ws_project.AsodUsers
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AsodUsers.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AsodUsers
         * @function getTypeUrl
         * @memberof ws_project.AsodUsers
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AsodUsers.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/ws_project.AsodUsers";
        };

        return AsodUsers;
    })();

    ws_project.AsodUsersRow = (function() {

        /**
         * Properties of an AsodUsersRow.
         * @memberof ws_project
         * @interface IAsodUsersRow
         * @property {string|null} [id] AsodUsersRow id
         * @property {string|null} [court] AsodUsersRow court
         * @property {string|null} [nameSurname] AsodUsersRow nameSurname
         * @property {string|null} [department] AsodUsersRow department
         * @property {string|null} [role] AsodUsersRow role
         * @property {common.IDate|null} [lastLogin] AsodUsersRow lastLogin
         */

        /**
         * Constructs a new AsodUsersRow.
         * @memberof ws_project
         * @classdesc Represents an AsodUsersRow.
         * @implements IAsodUsersRow
         * @constructor
         * @param {ws_project.IAsodUsersRow=} [properties] Properties to set
         */
        function AsodUsersRow(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AsodUsersRow id.
         * @member {string} id
         * @memberof ws_project.AsodUsersRow
         * @instance
         */
        AsodUsersRow.prototype.id = "";

        /**
         * AsodUsersRow court.
         * @member {string} court
         * @memberof ws_project.AsodUsersRow
         * @instance
         */
        AsodUsersRow.prototype.court = "";

        /**
         * AsodUsersRow nameSurname.
         * @member {string} nameSurname
         * @memberof ws_project.AsodUsersRow
         * @instance
         */
        AsodUsersRow.prototype.nameSurname = "";

        /**
         * AsodUsersRow department.
         * @member {string} department
         * @memberof ws_project.AsodUsersRow
         * @instance
         */
        AsodUsersRow.prototype.department = "";

        /**
         * AsodUsersRow role.
         * @member {string} role
         * @memberof ws_project.AsodUsersRow
         * @instance
         */
        AsodUsersRow.prototype.role = "";

        /**
         * AsodUsersRow lastLogin.
         * @member {common.IDate|null|undefined} lastLogin
         * @memberof ws_project.AsodUsersRow
         * @instance
         */
        AsodUsersRow.prototype.lastLogin = null;

        /**
         * Creates a new AsodUsersRow instance using the specified properties.
         * @function create
         * @memberof ws_project.AsodUsersRow
         * @static
         * @param {ws_project.IAsodUsersRow=} [properties] Properties to set
         * @returns {ws_project.AsodUsersRow} AsodUsersRow instance
         */
        AsodUsersRow.create = function create(properties) {
            return new AsodUsersRow(properties);
        };

        /**
         * Encodes the specified AsodUsersRow message. Does not implicitly {@link ws_project.AsodUsersRow.verify|verify} messages.
         * @function encode
         * @memberof ws_project.AsodUsersRow
         * @static
         * @param {ws_project.IAsodUsersRow} message AsodUsersRow message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodUsersRow.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.court != null && Object.hasOwnProperty.call(message, "court"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.court);
            if (message.nameSurname != null && Object.hasOwnProperty.call(message, "nameSurname"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.nameSurname);
            if (message.department != null && Object.hasOwnProperty.call(message, "department"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.department);
            if (message.role != null && Object.hasOwnProperty.call(message, "role"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.role);
            if (message.lastLogin != null && Object.hasOwnProperty.call(message, "lastLogin"))
                $root.common.Date.encode(message.lastLogin, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified AsodUsersRow message, length delimited. Does not implicitly {@link ws_project.AsodUsersRow.verify|verify} messages.
         * @function encodeDelimited
         * @memberof ws_project.AsodUsersRow
         * @static
         * @param {ws_project.IAsodUsersRow} message AsodUsersRow message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodUsersRow.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AsodUsersRow message from the specified reader or buffer.
         * @function decode
         * @memberof ws_project.AsodUsersRow
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {ws_project.AsodUsersRow} AsodUsersRow
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodUsersRow.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ws_project.AsodUsersRow();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 2: {
                        message.court = reader.string();
                        break;
                    }
                case 3: {
                        message.nameSurname = reader.string();
                        break;
                    }
                case 4: {
                        message.department = reader.string();
                        break;
                    }
                case 5: {
                        message.role = reader.string();
                        break;
                    }
                case 6: {
                        message.lastLogin = $root.common.Date.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AsodUsersRow message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof ws_project.AsodUsersRow
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {ws_project.AsodUsersRow} AsodUsersRow
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodUsersRow.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AsodUsersRow message.
         * @function verify
         * @memberof ws_project.AsodUsersRow
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AsodUsersRow.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.court != null && message.hasOwnProperty("court"))
                if (!$util.isString(message.court))
                    return "court: string expected";
            if (message.nameSurname != null && message.hasOwnProperty("nameSurname"))
                if (!$util.isString(message.nameSurname))
                    return "nameSurname: string expected";
            if (message.department != null && message.hasOwnProperty("department"))
                if (!$util.isString(message.department))
                    return "department: string expected";
            if (message.role != null && message.hasOwnProperty("role"))
                if (!$util.isString(message.role))
                    return "role: string expected";
            if (message.lastLogin != null && message.hasOwnProperty("lastLogin")) {
                let error = $root.common.Date.verify(message.lastLogin);
                if (error)
                    return "lastLogin." + error;
            }
            return null;
        };

        /**
         * Creates an AsodUsersRow message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof ws_project.AsodUsersRow
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {ws_project.AsodUsersRow} AsodUsersRow
         */
        AsodUsersRow.fromObject = function fromObject(object) {
            if (object instanceof $root.ws_project.AsodUsersRow)
                return object;
            let message = new $root.ws_project.AsodUsersRow();
            if (object.id != null)
                message.id = String(object.id);
            if (object.court != null)
                message.court = String(object.court);
            if (object.nameSurname != null)
                message.nameSurname = String(object.nameSurname);
            if (object.department != null)
                message.department = String(object.department);
            if (object.role != null)
                message.role = String(object.role);
            if (object.lastLogin != null) {
                if (typeof object.lastLogin !== "object")
                    throw TypeError(".ws_project.AsodUsersRow.lastLogin: object expected");
                message.lastLogin = $root.common.Date.fromObject(object.lastLogin);
            }
            return message;
        };

        /**
         * Creates a plain object from an AsodUsersRow message. Also converts values to other types if specified.
         * @function toObject
         * @memberof ws_project.AsodUsersRow
         * @static
         * @param {ws_project.AsodUsersRow} message AsodUsersRow
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AsodUsersRow.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.id = "";
                object.court = "";
                object.nameSurname = "";
                object.department = "";
                object.role = "";
                object.lastLogin = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.court != null && message.hasOwnProperty("court"))
                object.court = message.court;
            if (message.nameSurname != null && message.hasOwnProperty("nameSurname"))
                object.nameSurname = message.nameSurname;
            if (message.department != null && message.hasOwnProperty("department"))
                object.department = message.department;
            if (message.role != null && message.hasOwnProperty("role"))
                object.role = message.role;
            if (message.lastLogin != null && message.hasOwnProperty("lastLogin"))
                object.lastLogin = $root.common.Date.toObject(message.lastLogin, options);
            return object;
        };

        /**
         * Converts this AsodUsersRow to JSON.
         * @function toJSON
         * @memberof ws_project.AsodUsersRow
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AsodUsersRow.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AsodUsersRow
         * @function getTypeUrl
         * @memberof ws_project.AsodUsersRow
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AsodUsersRow.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/ws_project.AsodUsersRow";
        };

        return AsodUsersRow;
    })();

    /**
     * AsodUsersOrderingTerm enum.
     * @name ws_project.AsodUsersOrderingTerm
     * @enum {number}
     * @property {number} auot_court=0 auot_court value
     * @property {number} auot_name_surname=1 auot_name_surname value
     * @property {number} auot_department=2 auot_department value
     * @property {number} auot_role=3 auot_role value
     * @property {number} auot_last_login=4 auot_last_login value
     */
    ws_project.AsodUsersOrderingTerm = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "auot_court"] = 0;
        values[valuesById[1] = "auot_name_surname"] = 1;
        values[valuesById[2] = "auot_department"] = 2;
        values[valuesById[3] = "auot_role"] = 3;
        values[valuesById[4] = "auot_last_login"] = 4;
        return values;
    })();

    return ws_project;
})();

export const api_prijava = $root.api_prijava = (() => {

    /**
     * Namespace api_prijava.
     * @exports api_prijava
     * @namespace
     */
    const api_prijava = {};

    api_prijava.ApiPrijavaRequestAuth = (function() {

        /**
         * Properties of an ApiPrijavaRequestAuth.
         * @memberof api_prijava
         * @interface IApiPrijavaRequestAuth
         * @property {string|null} [redirectUrl] ApiPrijavaRequestAuth redirectUrl
         */

        /**
         * Constructs a new ApiPrijavaRequestAuth.
         * @memberof api_prijava
         * @classdesc Represents an ApiPrijavaRequestAuth.
         * @implements IApiPrijavaRequestAuth
         * @constructor
         * @param {api_prijava.IApiPrijavaRequestAuth=} [properties] Properties to set
         */
        function ApiPrijavaRequestAuth(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiPrijavaRequestAuth redirectUrl.
         * @member {string} redirectUrl
         * @memberof api_prijava.ApiPrijavaRequestAuth
         * @instance
         */
        ApiPrijavaRequestAuth.prototype.redirectUrl = "";

        /**
         * Creates a new ApiPrijavaRequestAuth instance using the specified properties.
         * @function create
         * @memberof api_prijava.ApiPrijavaRequestAuth
         * @static
         * @param {api_prijava.IApiPrijavaRequestAuth=} [properties] Properties to set
         * @returns {api_prijava.ApiPrijavaRequestAuth} ApiPrijavaRequestAuth instance
         */
        ApiPrijavaRequestAuth.create = function create(properties) {
            return new ApiPrijavaRequestAuth(properties);
        };

        /**
         * Encodes the specified ApiPrijavaRequestAuth message. Does not implicitly {@link api_prijava.ApiPrijavaRequestAuth.verify|verify} messages.
         * @function encode
         * @memberof api_prijava.ApiPrijavaRequestAuth
         * @static
         * @param {api_prijava.IApiPrijavaRequestAuth} message ApiPrijavaRequestAuth message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiPrijavaRequestAuth.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.redirectUrl != null && Object.hasOwnProperty.call(message, "redirectUrl"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.redirectUrl);
            return writer;
        };

        /**
         * Encodes the specified ApiPrijavaRequestAuth message, length delimited. Does not implicitly {@link api_prijava.ApiPrijavaRequestAuth.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_prijava.ApiPrijavaRequestAuth
         * @static
         * @param {api_prijava.IApiPrijavaRequestAuth} message ApiPrijavaRequestAuth message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiPrijavaRequestAuth.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiPrijavaRequestAuth message from the specified reader or buffer.
         * @function decode
         * @memberof api_prijava.ApiPrijavaRequestAuth
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_prijava.ApiPrijavaRequestAuth} ApiPrijavaRequestAuth
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiPrijavaRequestAuth.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_prijava.ApiPrijavaRequestAuth();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.redirectUrl = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiPrijavaRequestAuth message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_prijava.ApiPrijavaRequestAuth
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_prijava.ApiPrijavaRequestAuth} ApiPrijavaRequestAuth
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiPrijavaRequestAuth.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiPrijavaRequestAuth message.
         * @function verify
         * @memberof api_prijava.ApiPrijavaRequestAuth
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiPrijavaRequestAuth.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.redirectUrl != null && message.hasOwnProperty("redirectUrl"))
                if (!$util.isString(message.redirectUrl))
                    return "redirectUrl: string expected";
            return null;
        };

        /**
         * Creates an ApiPrijavaRequestAuth message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_prijava.ApiPrijavaRequestAuth
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_prijava.ApiPrijavaRequestAuth} ApiPrijavaRequestAuth
         */
        ApiPrijavaRequestAuth.fromObject = function fromObject(object) {
            if (object instanceof $root.api_prijava.ApiPrijavaRequestAuth)
                return object;
            let message = new $root.api_prijava.ApiPrijavaRequestAuth();
            if (object.redirectUrl != null)
                message.redirectUrl = String(object.redirectUrl);
            return message;
        };

        /**
         * Creates a plain object from an ApiPrijavaRequestAuth message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_prijava.ApiPrijavaRequestAuth
         * @static
         * @param {api_prijava.ApiPrijavaRequestAuth} message ApiPrijavaRequestAuth
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiPrijavaRequestAuth.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.redirectUrl = "";
            if (message.redirectUrl != null && message.hasOwnProperty("redirectUrl"))
                object.redirectUrl = message.redirectUrl;
            return object;
        };

        /**
         * Converts this ApiPrijavaRequestAuth to JSON.
         * @function toJSON
         * @memberof api_prijava.ApiPrijavaRequestAuth
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiPrijavaRequestAuth.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiPrijavaRequestAuth
         * @function getTypeUrl
         * @memberof api_prijava.ApiPrijavaRequestAuth
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiPrijavaRequestAuth.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_prijava.ApiPrijavaRequestAuth";
        };

        return ApiPrijavaRequestAuth;
    })();

    api_prijava.ApiPrijavaAuthChallenge = (function() {

        /**
         * Properties of an ApiPrijavaAuthChallenge.
         * @memberof api_prijava
         * @interface IApiPrijavaAuthChallenge
         * @property {string|null} [sessionId] ApiPrijavaAuthChallenge sessionId
         * @property {string|null} [ssoRedirectUrl] ApiPrijavaAuthChallenge ssoRedirectUrl
         */

        /**
         * Constructs a new ApiPrijavaAuthChallenge.
         * @memberof api_prijava
         * @classdesc Represents an ApiPrijavaAuthChallenge.
         * @implements IApiPrijavaAuthChallenge
         * @constructor
         * @param {api_prijava.IApiPrijavaAuthChallenge=} [properties] Properties to set
         */
        function ApiPrijavaAuthChallenge(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiPrijavaAuthChallenge sessionId.
         * @member {string} sessionId
         * @memberof api_prijava.ApiPrijavaAuthChallenge
         * @instance
         */
        ApiPrijavaAuthChallenge.prototype.sessionId = "";

        /**
         * ApiPrijavaAuthChallenge ssoRedirectUrl.
         * @member {string} ssoRedirectUrl
         * @memberof api_prijava.ApiPrijavaAuthChallenge
         * @instance
         */
        ApiPrijavaAuthChallenge.prototype.ssoRedirectUrl = "";

        /**
         * Creates a new ApiPrijavaAuthChallenge instance using the specified properties.
         * @function create
         * @memberof api_prijava.ApiPrijavaAuthChallenge
         * @static
         * @param {api_prijava.IApiPrijavaAuthChallenge=} [properties] Properties to set
         * @returns {api_prijava.ApiPrijavaAuthChallenge} ApiPrijavaAuthChallenge instance
         */
        ApiPrijavaAuthChallenge.create = function create(properties) {
            return new ApiPrijavaAuthChallenge(properties);
        };

        /**
         * Encodes the specified ApiPrijavaAuthChallenge message. Does not implicitly {@link api_prijava.ApiPrijavaAuthChallenge.verify|verify} messages.
         * @function encode
         * @memberof api_prijava.ApiPrijavaAuthChallenge
         * @static
         * @param {api_prijava.IApiPrijavaAuthChallenge} message ApiPrijavaAuthChallenge message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiPrijavaAuthChallenge.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sessionId != null && Object.hasOwnProperty.call(message, "sessionId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.sessionId);
            if (message.ssoRedirectUrl != null && Object.hasOwnProperty.call(message, "ssoRedirectUrl"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.ssoRedirectUrl);
            return writer;
        };

        /**
         * Encodes the specified ApiPrijavaAuthChallenge message, length delimited. Does not implicitly {@link api_prijava.ApiPrijavaAuthChallenge.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_prijava.ApiPrijavaAuthChallenge
         * @static
         * @param {api_prijava.IApiPrijavaAuthChallenge} message ApiPrijavaAuthChallenge message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiPrijavaAuthChallenge.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiPrijavaAuthChallenge message from the specified reader or buffer.
         * @function decode
         * @memberof api_prijava.ApiPrijavaAuthChallenge
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_prijava.ApiPrijavaAuthChallenge} ApiPrijavaAuthChallenge
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiPrijavaAuthChallenge.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_prijava.ApiPrijavaAuthChallenge();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 2: {
                        message.sessionId = reader.string();
                        break;
                    }
                case 3: {
                        message.ssoRedirectUrl = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiPrijavaAuthChallenge message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_prijava.ApiPrijavaAuthChallenge
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_prijava.ApiPrijavaAuthChallenge} ApiPrijavaAuthChallenge
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiPrijavaAuthChallenge.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiPrijavaAuthChallenge message.
         * @function verify
         * @memberof api_prijava.ApiPrijavaAuthChallenge
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiPrijavaAuthChallenge.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                if (!$util.isString(message.sessionId))
                    return "sessionId: string expected";
            if (message.ssoRedirectUrl != null && message.hasOwnProperty("ssoRedirectUrl"))
                if (!$util.isString(message.ssoRedirectUrl))
                    return "ssoRedirectUrl: string expected";
            return null;
        };

        /**
         * Creates an ApiPrijavaAuthChallenge message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_prijava.ApiPrijavaAuthChallenge
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_prijava.ApiPrijavaAuthChallenge} ApiPrijavaAuthChallenge
         */
        ApiPrijavaAuthChallenge.fromObject = function fromObject(object) {
            if (object instanceof $root.api_prijava.ApiPrijavaAuthChallenge)
                return object;
            let message = new $root.api_prijava.ApiPrijavaAuthChallenge();
            if (object.sessionId != null)
                message.sessionId = String(object.sessionId);
            if (object.ssoRedirectUrl != null)
                message.ssoRedirectUrl = String(object.ssoRedirectUrl);
            return message;
        };

        /**
         * Creates a plain object from an ApiPrijavaAuthChallenge message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_prijava.ApiPrijavaAuthChallenge
         * @static
         * @param {api_prijava.ApiPrijavaAuthChallenge} message ApiPrijavaAuthChallenge
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiPrijavaAuthChallenge.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.sessionId = "";
                object.ssoRedirectUrl = "";
            }
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                object.sessionId = message.sessionId;
            if (message.ssoRedirectUrl != null && message.hasOwnProperty("ssoRedirectUrl"))
                object.ssoRedirectUrl = message.ssoRedirectUrl;
            return object;
        };

        /**
         * Converts this ApiPrijavaAuthChallenge to JSON.
         * @function toJSON
         * @memberof api_prijava.ApiPrijavaAuthChallenge
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiPrijavaAuthChallenge.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiPrijavaAuthChallenge
         * @function getTypeUrl
         * @memberof api_prijava.ApiPrijavaAuthChallenge
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiPrijavaAuthChallenge.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_prijava.ApiPrijavaAuthChallenge";
        };

        return ApiPrijavaAuthChallenge;
    })();

    api_prijava.ApiPrijavaAuthToken = (function() {

        /**
         * Properties of an ApiPrijavaAuthToken.
         * @memberof api_prijava
         * @interface IApiPrijavaAuthToken
         * @property {string|null} [sessionId] ApiPrijavaAuthToken sessionId
         * @property {string|null} [authToken] ApiPrijavaAuthToken authToken
         */

        /**
         * Constructs a new ApiPrijavaAuthToken.
         * @memberof api_prijava
         * @classdesc Represents an ApiPrijavaAuthToken.
         * @implements IApiPrijavaAuthToken
         * @constructor
         * @param {api_prijava.IApiPrijavaAuthToken=} [properties] Properties to set
         */
        function ApiPrijavaAuthToken(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiPrijavaAuthToken sessionId.
         * @member {string} sessionId
         * @memberof api_prijava.ApiPrijavaAuthToken
         * @instance
         */
        ApiPrijavaAuthToken.prototype.sessionId = "";

        /**
         * ApiPrijavaAuthToken authToken.
         * @member {string} authToken
         * @memberof api_prijava.ApiPrijavaAuthToken
         * @instance
         */
        ApiPrijavaAuthToken.prototype.authToken = "";

        /**
         * Creates a new ApiPrijavaAuthToken instance using the specified properties.
         * @function create
         * @memberof api_prijava.ApiPrijavaAuthToken
         * @static
         * @param {api_prijava.IApiPrijavaAuthToken=} [properties] Properties to set
         * @returns {api_prijava.ApiPrijavaAuthToken} ApiPrijavaAuthToken instance
         */
        ApiPrijavaAuthToken.create = function create(properties) {
            return new ApiPrijavaAuthToken(properties);
        };

        /**
         * Encodes the specified ApiPrijavaAuthToken message. Does not implicitly {@link api_prijava.ApiPrijavaAuthToken.verify|verify} messages.
         * @function encode
         * @memberof api_prijava.ApiPrijavaAuthToken
         * @static
         * @param {api_prijava.IApiPrijavaAuthToken} message ApiPrijavaAuthToken message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiPrijavaAuthToken.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sessionId != null && Object.hasOwnProperty.call(message, "sessionId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.sessionId);
            if (message.authToken != null && Object.hasOwnProperty.call(message, "authToken"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.authToken);
            return writer;
        };

        /**
         * Encodes the specified ApiPrijavaAuthToken message, length delimited. Does not implicitly {@link api_prijava.ApiPrijavaAuthToken.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_prijava.ApiPrijavaAuthToken
         * @static
         * @param {api_prijava.IApiPrijavaAuthToken} message ApiPrijavaAuthToken message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiPrijavaAuthToken.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiPrijavaAuthToken message from the specified reader or buffer.
         * @function decode
         * @memberof api_prijava.ApiPrijavaAuthToken
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_prijava.ApiPrijavaAuthToken} ApiPrijavaAuthToken
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiPrijavaAuthToken.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_prijava.ApiPrijavaAuthToken();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sessionId = reader.string();
                        break;
                    }
                case 2: {
                        message.authToken = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiPrijavaAuthToken message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_prijava.ApiPrijavaAuthToken
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_prijava.ApiPrijavaAuthToken} ApiPrijavaAuthToken
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiPrijavaAuthToken.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiPrijavaAuthToken message.
         * @function verify
         * @memberof api_prijava.ApiPrijavaAuthToken
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiPrijavaAuthToken.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                if (!$util.isString(message.sessionId))
                    return "sessionId: string expected";
            if (message.authToken != null && message.hasOwnProperty("authToken"))
                if (!$util.isString(message.authToken))
                    return "authToken: string expected";
            return null;
        };

        /**
         * Creates an ApiPrijavaAuthToken message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_prijava.ApiPrijavaAuthToken
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_prijava.ApiPrijavaAuthToken} ApiPrijavaAuthToken
         */
        ApiPrijavaAuthToken.fromObject = function fromObject(object) {
            if (object instanceof $root.api_prijava.ApiPrijavaAuthToken)
                return object;
            let message = new $root.api_prijava.ApiPrijavaAuthToken();
            if (object.sessionId != null)
                message.sessionId = String(object.sessionId);
            if (object.authToken != null)
                message.authToken = String(object.authToken);
            return message;
        };

        /**
         * Creates a plain object from an ApiPrijavaAuthToken message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_prijava.ApiPrijavaAuthToken
         * @static
         * @param {api_prijava.ApiPrijavaAuthToken} message ApiPrijavaAuthToken
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiPrijavaAuthToken.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.sessionId = "";
                object.authToken = "";
            }
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                object.sessionId = message.sessionId;
            if (message.authToken != null && message.hasOwnProperty("authToken"))
                object.authToken = message.authToken;
            return object;
        };

        /**
         * Converts this ApiPrijavaAuthToken to JSON.
         * @function toJSON
         * @memberof api_prijava.ApiPrijavaAuthToken
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiPrijavaAuthToken.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiPrijavaAuthToken
         * @function getTypeUrl
         * @memberof api_prijava.ApiPrijavaAuthToken
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiPrijavaAuthToken.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_prijava.ApiPrijavaAuthToken";
        };

        return ApiPrijavaAuthToken;
    })();

    api_prijava.ApiPrijavaRenewAuthToken = (function() {

        /**
         * Properties of an ApiPrijavaRenewAuthToken.
         * @memberof api_prijava
         * @interface IApiPrijavaRenewAuthToken
         */

        /**
         * Constructs a new ApiPrijavaRenewAuthToken.
         * @memberof api_prijava
         * @classdesc Represents an ApiPrijavaRenewAuthToken.
         * @implements IApiPrijavaRenewAuthToken
         * @constructor
         * @param {api_prijava.IApiPrijavaRenewAuthToken=} [properties] Properties to set
         */
        function ApiPrijavaRenewAuthToken(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new ApiPrijavaRenewAuthToken instance using the specified properties.
         * @function create
         * @memberof api_prijava.ApiPrijavaRenewAuthToken
         * @static
         * @param {api_prijava.IApiPrijavaRenewAuthToken=} [properties] Properties to set
         * @returns {api_prijava.ApiPrijavaRenewAuthToken} ApiPrijavaRenewAuthToken instance
         */
        ApiPrijavaRenewAuthToken.create = function create(properties) {
            return new ApiPrijavaRenewAuthToken(properties);
        };

        /**
         * Encodes the specified ApiPrijavaRenewAuthToken message. Does not implicitly {@link api_prijava.ApiPrijavaRenewAuthToken.verify|verify} messages.
         * @function encode
         * @memberof api_prijava.ApiPrijavaRenewAuthToken
         * @static
         * @param {api_prijava.IApiPrijavaRenewAuthToken} message ApiPrijavaRenewAuthToken message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiPrijavaRenewAuthToken.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified ApiPrijavaRenewAuthToken message, length delimited. Does not implicitly {@link api_prijava.ApiPrijavaRenewAuthToken.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_prijava.ApiPrijavaRenewAuthToken
         * @static
         * @param {api_prijava.IApiPrijavaRenewAuthToken} message ApiPrijavaRenewAuthToken message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiPrijavaRenewAuthToken.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiPrijavaRenewAuthToken message from the specified reader or buffer.
         * @function decode
         * @memberof api_prijava.ApiPrijavaRenewAuthToken
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_prijava.ApiPrijavaRenewAuthToken} ApiPrijavaRenewAuthToken
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiPrijavaRenewAuthToken.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_prijava.ApiPrijavaRenewAuthToken();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiPrijavaRenewAuthToken message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_prijava.ApiPrijavaRenewAuthToken
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_prijava.ApiPrijavaRenewAuthToken} ApiPrijavaRenewAuthToken
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiPrijavaRenewAuthToken.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiPrijavaRenewAuthToken message.
         * @function verify
         * @memberof api_prijava.ApiPrijavaRenewAuthToken
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiPrijavaRenewAuthToken.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an ApiPrijavaRenewAuthToken message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_prijava.ApiPrijavaRenewAuthToken
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_prijava.ApiPrijavaRenewAuthToken} ApiPrijavaRenewAuthToken
         */
        ApiPrijavaRenewAuthToken.fromObject = function fromObject(object) {
            if (object instanceof $root.api_prijava.ApiPrijavaRenewAuthToken)
                return object;
            return new $root.api_prijava.ApiPrijavaRenewAuthToken();
        };

        /**
         * Creates a plain object from an ApiPrijavaRenewAuthToken message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_prijava.ApiPrijavaRenewAuthToken
         * @static
         * @param {api_prijava.ApiPrijavaRenewAuthToken} message ApiPrijavaRenewAuthToken
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiPrijavaRenewAuthToken.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this ApiPrijavaRenewAuthToken to JSON.
         * @function toJSON
         * @memberof api_prijava.ApiPrijavaRenewAuthToken
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiPrijavaRenewAuthToken.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiPrijavaRenewAuthToken
         * @function getTypeUrl
         * @memberof api_prijava.ApiPrijavaRenewAuthToken
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiPrijavaRenewAuthToken.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_prijava.ApiPrijavaRenewAuthToken";
        };

        return ApiPrijavaRenewAuthToken;
    })();

    api_prijava.ApiPrijavaLogout = (function() {

        /**
         * Properties of an ApiPrijavaLogout.
         * @memberof api_prijava
         * @interface IApiPrijavaLogout
         */

        /**
         * Constructs a new ApiPrijavaLogout.
         * @memberof api_prijava
         * @classdesc Represents an ApiPrijavaLogout.
         * @implements IApiPrijavaLogout
         * @constructor
         * @param {api_prijava.IApiPrijavaLogout=} [properties] Properties to set
         */
        function ApiPrijavaLogout(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new ApiPrijavaLogout instance using the specified properties.
         * @function create
         * @memberof api_prijava.ApiPrijavaLogout
         * @static
         * @param {api_prijava.IApiPrijavaLogout=} [properties] Properties to set
         * @returns {api_prijava.ApiPrijavaLogout} ApiPrijavaLogout instance
         */
        ApiPrijavaLogout.create = function create(properties) {
            return new ApiPrijavaLogout(properties);
        };

        /**
         * Encodes the specified ApiPrijavaLogout message. Does not implicitly {@link api_prijava.ApiPrijavaLogout.verify|verify} messages.
         * @function encode
         * @memberof api_prijava.ApiPrijavaLogout
         * @static
         * @param {api_prijava.IApiPrijavaLogout} message ApiPrijavaLogout message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiPrijavaLogout.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified ApiPrijavaLogout message, length delimited. Does not implicitly {@link api_prijava.ApiPrijavaLogout.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_prijava.ApiPrijavaLogout
         * @static
         * @param {api_prijava.IApiPrijavaLogout} message ApiPrijavaLogout message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiPrijavaLogout.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiPrijavaLogout message from the specified reader or buffer.
         * @function decode
         * @memberof api_prijava.ApiPrijavaLogout
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_prijava.ApiPrijavaLogout} ApiPrijavaLogout
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiPrijavaLogout.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_prijava.ApiPrijavaLogout();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiPrijavaLogout message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_prijava.ApiPrijavaLogout
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_prijava.ApiPrijavaLogout} ApiPrijavaLogout
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiPrijavaLogout.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiPrijavaLogout message.
         * @function verify
         * @memberof api_prijava.ApiPrijavaLogout
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiPrijavaLogout.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an ApiPrijavaLogout message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_prijava.ApiPrijavaLogout
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_prijava.ApiPrijavaLogout} ApiPrijavaLogout
         */
        ApiPrijavaLogout.fromObject = function fromObject(object) {
            if (object instanceof $root.api_prijava.ApiPrijavaLogout)
                return object;
            return new $root.api_prijava.ApiPrijavaLogout();
        };

        /**
         * Creates a plain object from an ApiPrijavaLogout message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_prijava.ApiPrijavaLogout
         * @static
         * @param {api_prijava.ApiPrijavaLogout} message ApiPrijavaLogout
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiPrijavaLogout.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this ApiPrijavaLogout to JSON.
         * @function toJSON
         * @memberof api_prijava.ApiPrijavaLogout
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiPrijavaLogout.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiPrijavaLogout
         * @function getTypeUrl
         * @memberof api_prijava.ApiPrijavaLogout
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiPrijavaLogout.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_prijava.ApiPrijavaLogout";
        };

        return ApiPrijavaLogout;
    })();

    return api_prijava;
})();

export const table = $root.table = (() => {

    /**
     * Namespace table.
     * @exports table
     * @namespace
     */
    const table = {};

    table.Table = (function() {

        /**
         * Properties of a Table.
         * @memberof table
         * @interface ITable
         * @property {string|null} [title] Table title
         * @property {Array.<table.ITableHeaderCell>|null} [header] Table header
         * @property {Array.<table.ITableRow>|null} [r] Table r
         * @property {common.IPagination|null} [pagination] Table pagination
         * @property {string|null} [minWidth] Table minWidth
         * @property {Array.<string>|null} [activeRows] Table activeRows
         */

        /**
         * Constructs a new Table.
         * @memberof table
         * @classdesc Represents a Table.
         * @implements ITable
         * @constructor
         * @param {table.ITable=} [properties] Properties to set
         */
        function Table(properties) {
            this.header = [];
            this.r = [];
            this.activeRows = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Table title.
         * @member {string} title
         * @memberof table.Table
         * @instance
         */
        Table.prototype.title = "";

        /**
         * Table header.
         * @member {Array.<table.ITableHeaderCell>} header
         * @memberof table.Table
         * @instance
         */
        Table.prototype.header = $util.emptyArray;

        /**
         * Table r.
         * @member {Array.<table.ITableRow>} r
         * @memberof table.Table
         * @instance
         */
        Table.prototype.r = $util.emptyArray;

        /**
         * Table pagination.
         * @member {common.IPagination|null|undefined} pagination
         * @memberof table.Table
         * @instance
         */
        Table.prototype.pagination = null;

        /**
         * Table minWidth.
         * @member {string} minWidth
         * @memberof table.Table
         * @instance
         */
        Table.prototype.minWidth = "";

        /**
         * Table activeRows.
         * @member {Array.<string>} activeRows
         * @memberof table.Table
         * @instance
         */
        Table.prototype.activeRows = $util.emptyArray;

        /**
         * Creates a new Table instance using the specified properties.
         * @function create
         * @memberof table.Table
         * @static
         * @param {table.ITable=} [properties] Properties to set
         * @returns {table.Table} Table instance
         */
        Table.create = function create(properties) {
            return new Table(properties);
        };

        /**
         * Encodes the specified Table message. Does not implicitly {@link table.Table.verify|verify} messages.
         * @function encode
         * @memberof table.Table
         * @static
         * @param {table.ITable} message Table message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Table.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.title);
            if (message.header != null && message.header.length)
                for (let i = 0; i < message.header.length; ++i)
                    $root.table.TableHeaderCell.encode(message.header[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.r != null && message.r.length)
                for (let i = 0; i < message.r.length; ++i)
                    $root.table.TableRow.encode(message.r[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.pagination != null && Object.hasOwnProperty.call(message, "pagination"))
                $root.common.Pagination.encode(message.pagination, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.minWidth != null && Object.hasOwnProperty.call(message, "minWidth"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.minWidth);
            if (message.activeRows != null && message.activeRows.length)
                for (let i = 0; i < message.activeRows.length; ++i)
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.activeRows[i]);
            return writer;
        };

        /**
         * Encodes the specified Table message, length delimited. Does not implicitly {@link table.Table.verify|verify} messages.
         * @function encodeDelimited
         * @memberof table.Table
         * @static
         * @param {table.ITable} message Table message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Table.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Table message from the specified reader or buffer.
         * @function decode
         * @memberof table.Table
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {table.Table} Table
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Table.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.table.Table();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.title = reader.string();
                        break;
                    }
                case 2: {
                        if (!(message.header && message.header.length))
                            message.header = [];
                        message.header.push($root.table.TableHeaderCell.decode(reader, reader.uint32()));
                        break;
                    }
                case 3: {
                        if (!(message.r && message.r.length))
                            message.r = [];
                        message.r.push($root.table.TableRow.decode(reader, reader.uint32()));
                        break;
                    }
                case 4: {
                        message.pagination = $root.common.Pagination.decode(reader, reader.uint32());
                        break;
                    }
                case 5: {
                        message.minWidth = reader.string();
                        break;
                    }
                case 6: {
                        if (!(message.activeRows && message.activeRows.length))
                            message.activeRows = [];
                        message.activeRows.push(reader.string());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Table message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof table.Table
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {table.Table} Table
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Table.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Table message.
         * @function verify
         * @memberof table.Table
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Table.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.title != null && message.hasOwnProperty("title"))
                if (!$util.isString(message.title))
                    return "title: string expected";
            if (message.header != null && message.hasOwnProperty("header")) {
                if (!Array.isArray(message.header))
                    return "header: array expected";
                for (let i = 0; i < message.header.length; ++i) {
                    let error = $root.table.TableHeaderCell.verify(message.header[i]);
                    if (error)
                        return "header." + error;
                }
            }
            if (message.r != null && message.hasOwnProperty("r")) {
                if (!Array.isArray(message.r))
                    return "r: array expected";
                for (let i = 0; i < message.r.length; ++i) {
                    let error = $root.table.TableRow.verify(message.r[i]);
                    if (error)
                        return "r." + error;
                }
            }
            if (message.pagination != null && message.hasOwnProperty("pagination")) {
                let error = $root.common.Pagination.verify(message.pagination);
                if (error)
                    return "pagination." + error;
            }
            if (message.minWidth != null && message.hasOwnProperty("minWidth"))
                if (!$util.isString(message.minWidth))
                    return "minWidth: string expected";
            if (message.activeRows != null && message.hasOwnProperty("activeRows")) {
                if (!Array.isArray(message.activeRows))
                    return "activeRows: array expected";
                for (let i = 0; i < message.activeRows.length; ++i)
                    if (!$util.isString(message.activeRows[i]))
                        return "activeRows: string[] expected";
            }
            return null;
        };

        /**
         * Creates a Table message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof table.Table
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {table.Table} Table
         */
        Table.fromObject = function fromObject(object) {
            if (object instanceof $root.table.Table)
                return object;
            let message = new $root.table.Table();
            if (object.title != null)
                message.title = String(object.title);
            if (object.header) {
                if (!Array.isArray(object.header))
                    throw TypeError(".table.Table.header: array expected");
                message.header = [];
                for (let i = 0; i < object.header.length; ++i) {
                    if (typeof object.header[i] !== "object")
                        throw TypeError(".table.Table.header: object expected");
                    message.header[i] = $root.table.TableHeaderCell.fromObject(object.header[i]);
                }
            }
            if (object.r) {
                if (!Array.isArray(object.r))
                    throw TypeError(".table.Table.r: array expected");
                message.r = [];
                for (let i = 0; i < object.r.length; ++i) {
                    if (typeof object.r[i] !== "object")
                        throw TypeError(".table.Table.r: object expected");
                    message.r[i] = $root.table.TableRow.fromObject(object.r[i]);
                }
            }
            if (object.pagination != null) {
                if (typeof object.pagination !== "object")
                    throw TypeError(".table.Table.pagination: object expected");
                message.pagination = $root.common.Pagination.fromObject(object.pagination);
            }
            if (object.minWidth != null)
                message.minWidth = String(object.minWidth);
            if (object.activeRows) {
                if (!Array.isArray(object.activeRows))
                    throw TypeError(".table.Table.activeRows: array expected");
                message.activeRows = [];
                for (let i = 0; i < object.activeRows.length; ++i)
                    message.activeRows[i] = String(object.activeRows[i]);
            }
            return message;
        };

        /**
         * Creates a plain object from a Table message. Also converts values to other types if specified.
         * @function toObject
         * @memberof table.Table
         * @static
         * @param {table.Table} message Table
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Table.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.header = [];
                object.r = [];
                object.activeRows = [];
            }
            if (options.defaults) {
                object.title = "";
                object.pagination = null;
                object.minWidth = "";
            }
            if (message.title != null && message.hasOwnProperty("title"))
                object.title = message.title;
            if (message.header && message.header.length) {
                object.header = [];
                for (let j = 0; j < message.header.length; ++j)
                    object.header[j] = $root.table.TableHeaderCell.toObject(message.header[j], options);
            }
            if (message.r && message.r.length) {
                object.r = [];
                for (let j = 0; j < message.r.length; ++j)
                    object.r[j] = $root.table.TableRow.toObject(message.r[j], options);
            }
            if (message.pagination != null && message.hasOwnProperty("pagination"))
                object.pagination = $root.common.Pagination.toObject(message.pagination, options);
            if (message.minWidth != null && message.hasOwnProperty("minWidth"))
                object.minWidth = message.minWidth;
            if (message.activeRows && message.activeRows.length) {
                object.activeRows = [];
                for (let j = 0; j < message.activeRows.length; ++j)
                    object.activeRows[j] = message.activeRows[j];
            }
            return object;
        };

        /**
         * Converts this Table to JSON.
         * @function toJSON
         * @memberof table.Table
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Table.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Table
         * @function getTypeUrl
         * @memberof table.Table
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Table.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/table.Table";
        };

        return Table;
    })();

    table.TableRow = (function() {

        /**
         * Properties of a TableRow.
         * @memberof table
         * @interface ITableRow
         * @property {Array.<table.ITableCell>|null} [c] TableRow c
         * @property {boolean|null} [selected] TableRow selected
         * @property {string|null} [id] TableRow id
         */

        /**
         * Constructs a new TableRow.
         * @memberof table
         * @classdesc Represents a TableRow.
         * @implements ITableRow
         * @constructor
         * @param {table.ITableRow=} [properties] Properties to set
         */
        function TableRow(properties) {
            this.c = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TableRow c.
         * @member {Array.<table.ITableCell>} c
         * @memberof table.TableRow
         * @instance
         */
        TableRow.prototype.c = $util.emptyArray;

        /**
         * TableRow selected.
         * @member {boolean} selected
         * @memberof table.TableRow
         * @instance
         */
        TableRow.prototype.selected = false;

        /**
         * TableRow id.
         * @member {string} id
         * @memberof table.TableRow
         * @instance
         */
        TableRow.prototype.id = "";

        /**
         * Creates a new TableRow instance using the specified properties.
         * @function create
         * @memberof table.TableRow
         * @static
         * @param {table.ITableRow=} [properties] Properties to set
         * @returns {table.TableRow} TableRow instance
         */
        TableRow.create = function create(properties) {
            return new TableRow(properties);
        };

        /**
         * Encodes the specified TableRow message. Does not implicitly {@link table.TableRow.verify|verify} messages.
         * @function encode
         * @memberof table.TableRow
         * @static
         * @param {table.ITableRow} message TableRow message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TableRow.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.c != null && message.c.length)
                for (let i = 0; i < message.c.length; ++i)
                    $root.table.TableCell.encode(message.c[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.selected != null && Object.hasOwnProperty.call(message, "selected"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.selected);
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.id);
            return writer;
        };

        /**
         * Encodes the specified TableRow message, length delimited. Does not implicitly {@link table.TableRow.verify|verify} messages.
         * @function encodeDelimited
         * @memberof table.TableRow
         * @static
         * @param {table.ITableRow} message TableRow message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TableRow.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TableRow message from the specified reader or buffer.
         * @function decode
         * @memberof table.TableRow
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {table.TableRow} TableRow
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TableRow.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.table.TableRow();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.c && message.c.length))
                            message.c = [];
                        message.c.push($root.table.TableCell.decode(reader, reader.uint32()));
                        break;
                    }
                case 2: {
                        message.selected = reader.bool();
                        break;
                    }
                case 3: {
                        message.id = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TableRow message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof table.TableRow
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {table.TableRow} TableRow
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TableRow.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TableRow message.
         * @function verify
         * @memberof table.TableRow
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TableRow.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.c != null && message.hasOwnProperty("c")) {
                if (!Array.isArray(message.c))
                    return "c: array expected";
                for (let i = 0; i < message.c.length; ++i) {
                    let error = $root.table.TableCell.verify(message.c[i]);
                    if (error)
                        return "c." + error;
                }
            }
            if (message.selected != null && message.hasOwnProperty("selected"))
                if (typeof message.selected !== "boolean")
                    return "selected: boolean expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            return null;
        };

        /**
         * Creates a TableRow message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof table.TableRow
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {table.TableRow} TableRow
         */
        TableRow.fromObject = function fromObject(object) {
            if (object instanceof $root.table.TableRow)
                return object;
            let message = new $root.table.TableRow();
            if (object.c) {
                if (!Array.isArray(object.c))
                    throw TypeError(".table.TableRow.c: array expected");
                message.c = [];
                for (let i = 0; i < object.c.length; ++i) {
                    if (typeof object.c[i] !== "object")
                        throw TypeError(".table.TableRow.c: object expected");
                    message.c[i] = $root.table.TableCell.fromObject(object.c[i]);
                }
            }
            if (object.selected != null)
                message.selected = Boolean(object.selected);
            if (object.id != null)
                message.id = String(object.id);
            return message;
        };

        /**
         * Creates a plain object from a TableRow message. Also converts values to other types if specified.
         * @function toObject
         * @memberof table.TableRow
         * @static
         * @param {table.TableRow} message TableRow
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TableRow.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.c = [];
            if (options.defaults) {
                object.selected = false;
                object.id = "";
            }
            if (message.c && message.c.length) {
                object.c = [];
                for (let j = 0; j < message.c.length; ++j)
                    object.c[j] = $root.table.TableCell.toObject(message.c[j], options);
            }
            if (message.selected != null && message.hasOwnProperty("selected"))
                object.selected = message.selected;
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            return object;
        };

        /**
         * Converts this TableRow to JSON.
         * @function toJSON
         * @memberof table.TableRow
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TableRow.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TableRow
         * @function getTypeUrl
         * @memberof table.TableRow
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TableRow.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/table.TableRow";
        };

        return TableRow;
    })();

    table.TableHeaderCell = (function() {

        /**
         * Properties of a TableHeaderCell.
         * @memberof table
         * @interface ITableHeaderCell
         * @property {string|null} [id] TableHeaderCell id
         * @property {string|null} [name] TableHeaderCell name
         * @property {common.SortOrder|null} [ordering] TableHeaderCell ordering
         * @property {boolean|null} [sortable] TableHeaderCell sortable
         * @property {number|null} [maxCharacters] TableHeaderCell maxCharacters
         * @property {number|null} [flex] TableHeaderCell flex
         */

        /**
         * Constructs a new TableHeaderCell.
         * @memberof table
         * @classdesc Represents a TableHeaderCell.
         * @implements ITableHeaderCell
         * @constructor
         * @param {table.ITableHeaderCell=} [properties] Properties to set
         */
        function TableHeaderCell(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TableHeaderCell id.
         * @member {string} id
         * @memberof table.TableHeaderCell
         * @instance
         */
        TableHeaderCell.prototype.id = "";

        /**
         * TableHeaderCell name.
         * @member {string} name
         * @memberof table.TableHeaderCell
         * @instance
         */
        TableHeaderCell.prototype.name = "";

        /**
         * TableHeaderCell ordering.
         * @member {common.SortOrder} ordering
         * @memberof table.TableHeaderCell
         * @instance
         */
        TableHeaderCell.prototype.ordering = 0;

        /**
         * TableHeaderCell sortable.
         * @member {boolean} sortable
         * @memberof table.TableHeaderCell
         * @instance
         */
        TableHeaderCell.prototype.sortable = false;

        /**
         * TableHeaderCell maxCharacters.
         * @member {number} maxCharacters
         * @memberof table.TableHeaderCell
         * @instance
         */
        TableHeaderCell.prototype.maxCharacters = 0;

        /**
         * TableHeaderCell flex.
         * @member {number} flex
         * @memberof table.TableHeaderCell
         * @instance
         */
        TableHeaderCell.prototype.flex = 0;

        /**
         * Creates a new TableHeaderCell instance using the specified properties.
         * @function create
         * @memberof table.TableHeaderCell
         * @static
         * @param {table.ITableHeaderCell=} [properties] Properties to set
         * @returns {table.TableHeaderCell} TableHeaderCell instance
         */
        TableHeaderCell.create = function create(properties) {
            return new TableHeaderCell(properties);
        };

        /**
         * Encodes the specified TableHeaderCell message. Does not implicitly {@link table.TableHeaderCell.verify|verify} messages.
         * @function encode
         * @memberof table.TableHeaderCell
         * @static
         * @param {table.ITableHeaderCell} message TableHeaderCell message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TableHeaderCell.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.ordering != null && Object.hasOwnProperty.call(message, "ordering"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.ordering);
            if (message.sortable != null && Object.hasOwnProperty.call(message, "sortable"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.sortable);
            if (message.maxCharacters != null && Object.hasOwnProperty.call(message, "maxCharacters"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.maxCharacters);
            if (message.flex != null && Object.hasOwnProperty.call(message, "flex"))
                writer.uint32(/* id 6, wireType 1 =*/49).double(message.flex);
            return writer;
        };

        /**
         * Encodes the specified TableHeaderCell message, length delimited. Does not implicitly {@link table.TableHeaderCell.verify|verify} messages.
         * @function encodeDelimited
         * @memberof table.TableHeaderCell
         * @static
         * @param {table.ITableHeaderCell} message TableHeaderCell message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TableHeaderCell.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TableHeaderCell message from the specified reader or buffer.
         * @function decode
         * @memberof table.TableHeaderCell
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {table.TableHeaderCell} TableHeaderCell
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TableHeaderCell.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.table.TableHeaderCell();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                case 3: {
                        message.ordering = reader.int32();
                        break;
                    }
                case 4: {
                        message.sortable = reader.bool();
                        break;
                    }
                case 5: {
                        message.maxCharacters = reader.int32();
                        break;
                    }
                case 6: {
                        message.flex = reader.double();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TableHeaderCell message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof table.TableHeaderCell
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {table.TableHeaderCell} TableHeaderCell
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TableHeaderCell.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TableHeaderCell message.
         * @function verify
         * @memberof table.TableHeaderCell
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TableHeaderCell.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.ordering != null && message.hasOwnProperty("ordering"))
                switch (message.ordering) {
                default:
                    return "ordering: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message.sortable != null && message.hasOwnProperty("sortable"))
                if (typeof message.sortable !== "boolean")
                    return "sortable: boolean expected";
            if (message.maxCharacters != null && message.hasOwnProperty("maxCharacters"))
                if (!$util.isInteger(message.maxCharacters))
                    return "maxCharacters: integer expected";
            if (message.flex != null && message.hasOwnProperty("flex"))
                if (typeof message.flex !== "number")
                    return "flex: number expected";
            return null;
        };

        /**
         * Creates a TableHeaderCell message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof table.TableHeaderCell
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {table.TableHeaderCell} TableHeaderCell
         */
        TableHeaderCell.fromObject = function fromObject(object) {
            if (object instanceof $root.table.TableHeaderCell)
                return object;
            let message = new $root.table.TableHeaderCell();
            if (object.id != null)
                message.id = String(object.id);
            if (object.name != null)
                message.name = String(object.name);
            switch (object.ordering) {
            default:
                if (typeof object.ordering === "number") {
                    message.ordering = object.ordering;
                    break;
                }
                break;
            case "so_none":
            case 0:
                message.ordering = 0;
                break;
            case "so_asc":
            case 1:
                message.ordering = 1;
                break;
            case "so_desc":
            case 2:
                message.ordering = 2;
                break;
            }
            if (object.sortable != null)
                message.sortable = Boolean(object.sortable);
            if (object.maxCharacters != null)
                message.maxCharacters = object.maxCharacters | 0;
            if (object.flex != null)
                message.flex = Number(object.flex);
            return message;
        };

        /**
         * Creates a plain object from a TableHeaderCell message. Also converts values to other types if specified.
         * @function toObject
         * @memberof table.TableHeaderCell
         * @static
         * @param {table.TableHeaderCell} message TableHeaderCell
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TableHeaderCell.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.id = "";
                object.name = "";
                object.ordering = options.enums === String ? "so_none" : 0;
                object.sortable = false;
                object.maxCharacters = 0;
                object.flex = 0;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.ordering != null && message.hasOwnProperty("ordering"))
                object.ordering = options.enums === String ? $root.common.SortOrder[message.ordering] === undefined ? message.ordering : $root.common.SortOrder[message.ordering] : message.ordering;
            if (message.sortable != null && message.hasOwnProperty("sortable"))
                object.sortable = message.sortable;
            if (message.maxCharacters != null && message.hasOwnProperty("maxCharacters"))
                object.maxCharacters = message.maxCharacters;
            if (message.flex != null && message.hasOwnProperty("flex"))
                object.flex = options.json && !isFinite(message.flex) ? String(message.flex) : message.flex;
            return object;
        };

        /**
         * Converts this TableHeaderCell to JSON.
         * @function toJSON
         * @memberof table.TableHeaderCell
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TableHeaderCell.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TableHeaderCell
         * @function getTypeUrl
         * @memberof table.TableHeaderCell
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TableHeaderCell.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/table.TableHeaderCell";
        };

        return TableHeaderCell;
    })();

    table.TableCell = (function() {

        /**
         * Properties of a TableCell.
         * @memberof table
         * @interface ITableCell
         * @property {string|null} [id] TableCell id
         * @property {table.ITableCellText|null} [t] TableCell t
         * @property {table.ITableCellNumber|null} [n] TableCell n
         * @property {table.ITableCellDate|null} [d] TableCell d
         * @property {table.ITableCellCheck|null} [c] TableCell c
         */

        /**
         * Constructs a new TableCell.
         * @memberof table
         * @classdesc Represents a TableCell.
         * @implements ITableCell
         * @constructor
         * @param {table.ITableCell=} [properties] Properties to set
         */
        function TableCell(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TableCell id.
         * @member {string} id
         * @memberof table.TableCell
         * @instance
         */
        TableCell.prototype.id = "";

        /**
         * TableCell t.
         * @member {table.ITableCellText|null|undefined} t
         * @memberof table.TableCell
         * @instance
         */
        TableCell.prototype.t = null;

        /**
         * TableCell n.
         * @member {table.ITableCellNumber|null|undefined} n
         * @memberof table.TableCell
         * @instance
         */
        TableCell.prototype.n = null;

        /**
         * TableCell d.
         * @member {table.ITableCellDate|null|undefined} d
         * @memberof table.TableCell
         * @instance
         */
        TableCell.prototype.d = null;

        /**
         * TableCell c.
         * @member {table.ITableCellCheck|null|undefined} c
         * @memberof table.TableCell
         * @instance
         */
        TableCell.prototype.c = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * TableCell value.
         * @member {"t"|"n"|"d"|"c"|undefined} value
         * @memberof table.TableCell
         * @instance
         */
        Object.defineProperty(TableCell.prototype, "value", {
            get: $util.oneOfGetter($oneOfFields = ["t", "n", "d", "c"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new TableCell instance using the specified properties.
         * @function create
         * @memberof table.TableCell
         * @static
         * @param {table.ITableCell=} [properties] Properties to set
         * @returns {table.TableCell} TableCell instance
         */
        TableCell.create = function create(properties) {
            return new TableCell(properties);
        };

        /**
         * Encodes the specified TableCell message. Does not implicitly {@link table.TableCell.verify|verify} messages.
         * @function encode
         * @memberof table.TableCell
         * @static
         * @param {table.ITableCell} message TableCell message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TableCell.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.t != null && Object.hasOwnProperty.call(message, "t"))
                $root.table.TableCellText.encode(message.t, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.n != null && Object.hasOwnProperty.call(message, "n"))
                $root.table.TableCellNumber.encode(message.n, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.d != null && Object.hasOwnProperty.call(message, "d"))
                $root.table.TableCellDate.encode(message.d, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.c != null && Object.hasOwnProperty.call(message, "c"))
                $root.table.TableCellCheck.encode(message.c, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified TableCell message, length delimited. Does not implicitly {@link table.TableCell.verify|verify} messages.
         * @function encodeDelimited
         * @memberof table.TableCell
         * @static
         * @param {table.ITableCell} message TableCell message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TableCell.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TableCell message from the specified reader or buffer.
         * @function decode
         * @memberof table.TableCell
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {table.TableCell} TableCell
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TableCell.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.table.TableCell();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 2: {
                        message.t = $root.table.TableCellText.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.n = $root.table.TableCellNumber.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.d = $root.table.TableCellDate.decode(reader, reader.uint32());
                        break;
                    }
                case 5: {
                        message.c = $root.table.TableCellCheck.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TableCell message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof table.TableCell
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {table.TableCell} TableCell
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TableCell.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TableCell message.
         * @function verify
         * @memberof table.TableCell
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TableCell.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.t != null && message.hasOwnProperty("t")) {
                properties.value = 1;
                {
                    let error = $root.table.TableCellText.verify(message.t);
                    if (error)
                        return "t." + error;
                }
            }
            if (message.n != null && message.hasOwnProperty("n")) {
                if (properties.value === 1)
                    return "value: multiple values";
                properties.value = 1;
                {
                    let error = $root.table.TableCellNumber.verify(message.n);
                    if (error)
                        return "n." + error;
                }
            }
            if (message.d != null && message.hasOwnProperty("d")) {
                if (properties.value === 1)
                    return "value: multiple values";
                properties.value = 1;
                {
                    let error = $root.table.TableCellDate.verify(message.d);
                    if (error)
                        return "d." + error;
                }
            }
            if (message.c != null && message.hasOwnProperty("c")) {
                if (properties.value === 1)
                    return "value: multiple values";
                properties.value = 1;
                {
                    let error = $root.table.TableCellCheck.verify(message.c);
                    if (error)
                        return "c." + error;
                }
            }
            return null;
        };

        /**
         * Creates a TableCell message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof table.TableCell
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {table.TableCell} TableCell
         */
        TableCell.fromObject = function fromObject(object) {
            if (object instanceof $root.table.TableCell)
                return object;
            let message = new $root.table.TableCell();
            if (object.id != null)
                message.id = String(object.id);
            if (object.t != null) {
                if (typeof object.t !== "object")
                    throw TypeError(".table.TableCell.t: object expected");
                message.t = $root.table.TableCellText.fromObject(object.t);
            }
            if (object.n != null) {
                if (typeof object.n !== "object")
                    throw TypeError(".table.TableCell.n: object expected");
                message.n = $root.table.TableCellNumber.fromObject(object.n);
            }
            if (object.d != null) {
                if (typeof object.d !== "object")
                    throw TypeError(".table.TableCell.d: object expected");
                message.d = $root.table.TableCellDate.fromObject(object.d);
            }
            if (object.c != null) {
                if (typeof object.c !== "object")
                    throw TypeError(".table.TableCell.c: object expected");
                message.c = $root.table.TableCellCheck.fromObject(object.c);
            }
            return message;
        };

        /**
         * Creates a plain object from a TableCell message. Also converts values to other types if specified.
         * @function toObject
         * @memberof table.TableCell
         * @static
         * @param {table.TableCell} message TableCell
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TableCell.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.id = "";
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.t != null && message.hasOwnProperty("t")) {
                object.t = $root.table.TableCellText.toObject(message.t, options);
                if (options.oneofs)
                    object.value = "t";
            }
            if (message.n != null && message.hasOwnProperty("n")) {
                object.n = $root.table.TableCellNumber.toObject(message.n, options);
                if (options.oneofs)
                    object.value = "n";
            }
            if (message.d != null && message.hasOwnProperty("d")) {
                object.d = $root.table.TableCellDate.toObject(message.d, options);
                if (options.oneofs)
                    object.value = "d";
            }
            if (message.c != null && message.hasOwnProperty("c")) {
                object.c = $root.table.TableCellCheck.toObject(message.c, options);
                if (options.oneofs)
                    object.value = "c";
            }
            return object;
        };

        /**
         * Converts this TableCell to JSON.
         * @function toJSON
         * @memberof table.TableCell
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TableCell.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TableCell
         * @function getTypeUrl
         * @memberof table.TableCell
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TableCell.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/table.TableCell";
        };

        return TableCell;
    })();

    table.TableCellText = (function() {

        /**
         * Properties of a TableCellText.
         * @memberof table
         * @interface ITableCellText
         * @property {string|null} [value] TableCellText value
         */

        /**
         * Constructs a new TableCellText.
         * @memberof table
         * @classdesc Represents a TableCellText.
         * @implements ITableCellText
         * @constructor
         * @param {table.ITableCellText=} [properties] Properties to set
         */
        function TableCellText(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TableCellText value.
         * @member {string} value
         * @memberof table.TableCellText
         * @instance
         */
        TableCellText.prototype.value = "";

        /**
         * Creates a new TableCellText instance using the specified properties.
         * @function create
         * @memberof table.TableCellText
         * @static
         * @param {table.ITableCellText=} [properties] Properties to set
         * @returns {table.TableCellText} TableCellText instance
         */
        TableCellText.create = function create(properties) {
            return new TableCellText(properties);
        };

        /**
         * Encodes the specified TableCellText message. Does not implicitly {@link table.TableCellText.verify|verify} messages.
         * @function encode
         * @memberof table.TableCellText
         * @static
         * @param {table.ITableCellText} message TableCellText message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TableCellText.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.value);
            return writer;
        };

        /**
         * Encodes the specified TableCellText message, length delimited. Does not implicitly {@link table.TableCellText.verify|verify} messages.
         * @function encodeDelimited
         * @memberof table.TableCellText
         * @static
         * @param {table.ITableCellText} message TableCellText message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TableCellText.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TableCellText message from the specified reader or buffer.
         * @function decode
         * @memberof table.TableCellText
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {table.TableCellText} TableCellText
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TableCellText.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.table.TableCellText();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.value = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TableCellText message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof table.TableCellText
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {table.TableCellText} TableCellText
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TableCellText.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TableCellText message.
         * @function verify
         * @memberof table.TableCellText
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TableCellText.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.value != null && message.hasOwnProperty("value"))
                if (!$util.isString(message.value))
                    return "value: string expected";
            return null;
        };

        /**
         * Creates a TableCellText message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof table.TableCellText
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {table.TableCellText} TableCellText
         */
        TableCellText.fromObject = function fromObject(object) {
            if (object instanceof $root.table.TableCellText)
                return object;
            let message = new $root.table.TableCellText();
            if (object.value != null)
                message.value = String(object.value);
            return message;
        };

        /**
         * Creates a plain object from a TableCellText message. Also converts values to other types if specified.
         * @function toObject
         * @memberof table.TableCellText
         * @static
         * @param {table.TableCellText} message TableCellText
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TableCellText.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.value = "";
            if (message.value != null && message.hasOwnProperty("value"))
                object.value = message.value;
            return object;
        };

        /**
         * Converts this TableCellText to JSON.
         * @function toJSON
         * @memberof table.TableCellText
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TableCellText.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TableCellText
         * @function getTypeUrl
         * @memberof table.TableCellText
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TableCellText.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/table.TableCellText";
        };

        return TableCellText;
    })();

    table.TableCellNumber = (function() {

        /**
         * Properties of a TableCellNumber.
         * @memberof table
         * @interface ITableCellNumber
         * @property {number|null} [value] TableCellNumber value
         * @property {number|null} [precision] TableCellNumber precision
         * @property {string|null} [unit] TableCellNumber unit
         */

        /**
         * Constructs a new TableCellNumber.
         * @memberof table
         * @classdesc Represents a TableCellNumber.
         * @implements ITableCellNumber
         * @constructor
         * @param {table.ITableCellNumber=} [properties] Properties to set
         */
        function TableCellNumber(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TableCellNumber value.
         * @member {number} value
         * @memberof table.TableCellNumber
         * @instance
         */
        TableCellNumber.prototype.value = 0;

        /**
         * TableCellNumber precision.
         * @member {number} precision
         * @memberof table.TableCellNumber
         * @instance
         */
        TableCellNumber.prototype.precision = 0;

        /**
         * TableCellNumber unit.
         * @member {string} unit
         * @memberof table.TableCellNumber
         * @instance
         */
        TableCellNumber.prototype.unit = "";

        /**
         * Creates a new TableCellNumber instance using the specified properties.
         * @function create
         * @memberof table.TableCellNumber
         * @static
         * @param {table.ITableCellNumber=} [properties] Properties to set
         * @returns {table.TableCellNumber} TableCellNumber instance
         */
        TableCellNumber.create = function create(properties) {
            return new TableCellNumber(properties);
        };

        /**
         * Encodes the specified TableCellNumber message. Does not implicitly {@link table.TableCellNumber.verify|verify} messages.
         * @function encode
         * @memberof table.TableCellNumber
         * @static
         * @param {table.ITableCellNumber} message TableCellNumber message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TableCellNumber.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 1, wireType 5 =*/13).float(message.value);
            if (message.precision != null && Object.hasOwnProperty.call(message, "precision"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.precision);
            if (message.unit != null && Object.hasOwnProperty.call(message, "unit"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.unit);
            return writer;
        };

        /**
         * Encodes the specified TableCellNumber message, length delimited. Does not implicitly {@link table.TableCellNumber.verify|verify} messages.
         * @function encodeDelimited
         * @memberof table.TableCellNumber
         * @static
         * @param {table.ITableCellNumber} message TableCellNumber message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TableCellNumber.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TableCellNumber message from the specified reader or buffer.
         * @function decode
         * @memberof table.TableCellNumber
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {table.TableCellNumber} TableCellNumber
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TableCellNumber.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.table.TableCellNumber();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.value = reader.float();
                        break;
                    }
                case 2: {
                        message.precision = reader.int32();
                        break;
                    }
                case 3: {
                        message.unit = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TableCellNumber message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof table.TableCellNumber
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {table.TableCellNumber} TableCellNumber
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TableCellNumber.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TableCellNumber message.
         * @function verify
         * @memberof table.TableCellNumber
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TableCellNumber.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.value != null && message.hasOwnProperty("value"))
                if (typeof message.value !== "number")
                    return "value: number expected";
            if (message.precision != null && message.hasOwnProperty("precision"))
                if (!$util.isInteger(message.precision))
                    return "precision: integer expected";
            if (message.unit != null && message.hasOwnProperty("unit"))
                if (!$util.isString(message.unit))
                    return "unit: string expected";
            return null;
        };

        /**
         * Creates a TableCellNumber message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof table.TableCellNumber
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {table.TableCellNumber} TableCellNumber
         */
        TableCellNumber.fromObject = function fromObject(object) {
            if (object instanceof $root.table.TableCellNumber)
                return object;
            let message = new $root.table.TableCellNumber();
            if (object.value != null)
                message.value = Number(object.value);
            if (object.precision != null)
                message.precision = object.precision | 0;
            if (object.unit != null)
                message.unit = String(object.unit);
            return message;
        };

        /**
         * Creates a plain object from a TableCellNumber message. Also converts values to other types if specified.
         * @function toObject
         * @memberof table.TableCellNumber
         * @static
         * @param {table.TableCellNumber} message TableCellNumber
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TableCellNumber.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.value = 0;
                object.precision = 0;
                object.unit = "";
            }
            if (message.value != null && message.hasOwnProperty("value"))
                object.value = options.json && !isFinite(message.value) ? String(message.value) : message.value;
            if (message.precision != null && message.hasOwnProperty("precision"))
                object.precision = message.precision;
            if (message.unit != null && message.hasOwnProperty("unit"))
                object.unit = message.unit;
            return object;
        };

        /**
         * Converts this TableCellNumber to JSON.
         * @function toJSON
         * @memberof table.TableCellNumber
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TableCellNumber.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TableCellNumber
         * @function getTypeUrl
         * @memberof table.TableCellNumber
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TableCellNumber.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/table.TableCellNumber";
        };

        return TableCellNumber;
    })();

    table.TableCellDate = (function() {

        /**
         * Properties of a TableCellDate.
         * @memberof table
         * @interface ITableCellDate
         * @property {common.IDate|null} [value] TableCellDate value
         */

        /**
         * Constructs a new TableCellDate.
         * @memberof table
         * @classdesc Represents a TableCellDate.
         * @implements ITableCellDate
         * @constructor
         * @param {table.ITableCellDate=} [properties] Properties to set
         */
        function TableCellDate(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TableCellDate value.
         * @member {common.IDate|null|undefined} value
         * @memberof table.TableCellDate
         * @instance
         */
        TableCellDate.prototype.value = null;

        /**
         * Creates a new TableCellDate instance using the specified properties.
         * @function create
         * @memberof table.TableCellDate
         * @static
         * @param {table.ITableCellDate=} [properties] Properties to set
         * @returns {table.TableCellDate} TableCellDate instance
         */
        TableCellDate.create = function create(properties) {
            return new TableCellDate(properties);
        };

        /**
         * Encodes the specified TableCellDate message. Does not implicitly {@link table.TableCellDate.verify|verify} messages.
         * @function encode
         * @memberof table.TableCellDate
         * @static
         * @param {table.ITableCellDate} message TableCellDate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TableCellDate.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                $root.common.Date.encode(message.value, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified TableCellDate message, length delimited. Does not implicitly {@link table.TableCellDate.verify|verify} messages.
         * @function encodeDelimited
         * @memberof table.TableCellDate
         * @static
         * @param {table.ITableCellDate} message TableCellDate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TableCellDate.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TableCellDate message from the specified reader or buffer.
         * @function decode
         * @memberof table.TableCellDate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {table.TableCellDate} TableCellDate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TableCellDate.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.table.TableCellDate();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.value = $root.common.Date.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TableCellDate message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof table.TableCellDate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {table.TableCellDate} TableCellDate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TableCellDate.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TableCellDate message.
         * @function verify
         * @memberof table.TableCellDate
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TableCellDate.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.value != null && message.hasOwnProperty("value")) {
                let error = $root.common.Date.verify(message.value);
                if (error)
                    return "value." + error;
            }
            return null;
        };

        /**
         * Creates a TableCellDate message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof table.TableCellDate
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {table.TableCellDate} TableCellDate
         */
        TableCellDate.fromObject = function fromObject(object) {
            if (object instanceof $root.table.TableCellDate)
                return object;
            let message = new $root.table.TableCellDate();
            if (object.value != null) {
                if (typeof object.value !== "object")
                    throw TypeError(".table.TableCellDate.value: object expected");
                message.value = $root.common.Date.fromObject(object.value);
            }
            return message;
        };

        /**
         * Creates a plain object from a TableCellDate message. Also converts values to other types if specified.
         * @function toObject
         * @memberof table.TableCellDate
         * @static
         * @param {table.TableCellDate} message TableCellDate
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TableCellDate.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.value = null;
            if (message.value != null && message.hasOwnProperty("value"))
                object.value = $root.common.Date.toObject(message.value, options);
            return object;
        };

        /**
         * Converts this TableCellDate to JSON.
         * @function toJSON
         * @memberof table.TableCellDate
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TableCellDate.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TableCellDate
         * @function getTypeUrl
         * @memberof table.TableCellDate
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TableCellDate.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/table.TableCellDate";
        };

        return TableCellDate;
    })();

    table.TableCellCheck = (function() {

        /**
         * Properties of a TableCellCheck.
         * @memberof table
         * @interface ITableCellCheck
         * @property {boolean|null} [value] TableCellCheck value
         */

        /**
         * Constructs a new TableCellCheck.
         * @memberof table
         * @classdesc Represents a TableCellCheck.
         * @implements ITableCellCheck
         * @constructor
         * @param {table.ITableCellCheck=} [properties] Properties to set
         */
        function TableCellCheck(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TableCellCheck value.
         * @member {boolean} value
         * @memberof table.TableCellCheck
         * @instance
         */
        TableCellCheck.prototype.value = false;

        /**
         * Creates a new TableCellCheck instance using the specified properties.
         * @function create
         * @memberof table.TableCellCheck
         * @static
         * @param {table.ITableCellCheck=} [properties] Properties to set
         * @returns {table.TableCellCheck} TableCellCheck instance
         */
        TableCellCheck.create = function create(properties) {
            return new TableCellCheck(properties);
        };

        /**
         * Encodes the specified TableCellCheck message. Does not implicitly {@link table.TableCellCheck.verify|verify} messages.
         * @function encode
         * @memberof table.TableCellCheck
         * @static
         * @param {table.ITableCellCheck} message TableCellCheck message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TableCellCheck.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.value);
            return writer;
        };

        /**
         * Encodes the specified TableCellCheck message, length delimited. Does not implicitly {@link table.TableCellCheck.verify|verify} messages.
         * @function encodeDelimited
         * @memberof table.TableCellCheck
         * @static
         * @param {table.ITableCellCheck} message TableCellCheck message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TableCellCheck.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TableCellCheck message from the specified reader or buffer.
         * @function decode
         * @memberof table.TableCellCheck
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {table.TableCellCheck} TableCellCheck
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TableCellCheck.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.table.TableCellCheck();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.value = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TableCellCheck message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof table.TableCellCheck
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {table.TableCellCheck} TableCellCheck
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TableCellCheck.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TableCellCheck message.
         * @function verify
         * @memberof table.TableCellCheck
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TableCellCheck.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.value != null && message.hasOwnProperty("value"))
                if (typeof message.value !== "boolean")
                    return "value: boolean expected";
            return null;
        };

        /**
         * Creates a TableCellCheck message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof table.TableCellCheck
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {table.TableCellCheck} TableCellCheck
         */
        TableCellCheck.fromObject = function fromObject(object) {
            if (object instanceof $root.table.TableCellCheck)
                return object;
            let message = new $root.table.TableCellCheck();
            if (object.value != null)
                message.value = Boolean(object.value);
            return message;
        };

        /**
         * Creates a plain object from a TableCellCheck message. Also converts values to other types if specified.
         * @function toObject
         * @memberof table.TableCellCheck
         * @static
         * @param {table.TableCellCheck} message TableCellCheck
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TableCellCheck.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.value = false;
            if (message.value != null && message.hasOwnProperty("value"))
                object.value = message.value;
            return object;
        };

        /**
         * Converts this TableCellCheck to JSON.
         * @function toJSON
         * @memberof table.TableCellCheck
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TableCellCheck.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TableCellCheck
         * @function getTypeUrl
         * @memberof table.TableCellCheck
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TableCellCheck.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/table.TableCellCheck";
        };

        return TableCellCheck;
    })();

    return table;
})();

export const api_general = $root.api_general = (() => {

    /**
     * Namespace api_general.
     * @exports api_general
     * @namespace
     */
    const api_general = {};

    api_general.ApiGeneralWhoAmI = (function() {

        /**
         * Properties of an ApiGeneralWhoAmI.
         * @memberof api_general
         * @interface IApiGeneralWhoAmI
         */

        /**
         * Constructs a new ApiGeneralWhoAmI.
         * @memberof api_general
         * @classdesc Represents an ApiGeneralWhoAmI.
         * @implements IApiGeneralWhoAmI
         * @constructor
         * @param {api_general.IApiGeneralWhoAmI=} [properties] Properties to set
         */
        function ApiGeneralWhoAmI(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new ApiGeneralWhoAmI instance using the specified properties.
         * @function create
         * @memberof api_general.ApiGeneralWhoAmI
         * @static
         * @param {api_general.IApiGeneralWhoAmI=} [properties] Properties to set
         * @returns {api_general.ApiGeneralWhoAmI} ApiGeneralWhoAmI instance
         */
        ApiGeneralWhoAmI.create = function create(properties) {
            return new ApiGeneralWhoAmI(properties);
        };

        /**
         * Encodes the specified ApiGeneralWhoAmI message. Does not implicitly {@link api_general.ApiGeneralWhoAmI.verify|verify} messages.
         * @function encode
         * @memberof api_general.ApiGeneralWhoAmI
         * @static
         * @param {api_general.IApiGeneralWhoAmI} message ApiGeneralWhoAmI message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiGeneralWhoAmI.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified ApiGeneralWhoAmI message, length delimited. Does not implicitly {@link api_general.ApiGeneralWhoAmI.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_general.ApiGeneralWhoAmI
         * @static
         * @param {api_general.IApiGeneralWhoAmI} message ApiGeneralWhoAmI message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiGeneralWhoAmI.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiGeneralWhoAmI message from the specified reader or buffer.
         * @function decode
         * @memberof api_general.ApiGeneralWhoAmI
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_general.ApiGeneralWhoAmI} ApiGeneralWhoAmI
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiGeneralWhoAmI.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_general.ApiGeneralWhoAmI();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiGeneralWhoAmI message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_general.ApiGeneralWhoAmI
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_general.ApiGeneralWhoAmI} ApiGeneralWhoAmI
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiGeneralWhoAmI.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiGeneralWhoAmI message.
         * @function verify
         * @memberof api_general.ApiGeneralWhoAmI
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiGeneralWhoAmI.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an ApiGeneralWhoAmI message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_general.ApiGeneralWhoAmI
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_general.ApiGeneralWhoAmI} ApiGeneralWhoAmI
         */
        ApiGeneralWhoAmI.fromObject = function fromObject(object) {
            if (object instanceof $root.api_general.ApiGeneralWhoAmI)
                return object;
            return new $root.api_general.ApiGeneralWhoAmI();
        };

        /**
         * Creates a plain object from an ApiGeneralWhoAmI message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_general.ApiGeneralWhoAmI
         * @static
         * @param {api_general.ApiGeneralWhoAmI} message ApiGeneralWhoAmI
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiGeneralWhoAmI.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this ApiGeneralWhoAmI to JSON.
         * @function toJSON
         * @memberof api_general.ApiGeneralWhoAmI
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiGeneralWhoAmI.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiGeneralWhoAmI
         * @function getTypeUrl
         * @memberof api_general.ApiGeneralWhoAmI
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiGeneralWhoAmI.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_general.ApiGeneralWhoAmI";
        };

        return ApiGeneralWhoAmI;
    })();

    api_general.ApiGeneralWhoAmIResponse = (function() {

        /**
         * Properties of an ApiGeneralWhoAmIResponse.
         * @memberof api_general
         * @interface IApiGeneralWhoAmIResponse
         * @property {string|null} [userId] ApiGeneralWhoAmIResponse userId
         * @property {string|null} [taxId] ApiGeneralWhoAmIResponse taxId
         * @property {string|null} [displayName] ApiGeneralWhoAmIResponse displayName
         * @property {string|null} [courtRoleId] ApiGeneralWhoAmIResponse courtRoleId
         * @property {Array.<api_general.IApiGeneralAllowedFeature>|null} [allowedFeatures] ApiGeneralWhoAmIResponse allowedFeatures
         */

        /**
         * Constructs a new ApiGeneralWhoAmIResponse.
         * @memberof api_general
         * @classdesc Represents an ApiGeneralWhoAmIResponse.
         * @implements IApiGeneralWhoAmIResponse
         * @constructor
         * @param {api_general.IApiGeneralWhoAmIResponse=} [properties] Properties to set
         */
        function ApiGeneralWhoAmIResponse(properties) {
            this.allowedFeatures = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiGeneralWhoAmIResponse userId.
         * @member {string} userId
         * @memberof api_general.ApiGeneralWhoAmIResponse
         * @instance
         */
        ApiGeneralWhoAmIResponse.prototype.userId = "";

        /**
         * ApiGeneralWhoAmIResponse taxId.
         * @member {string} taxId
         * @memberof api_general.ApiGeneralWhoAmIResponse
         * @instance
         */
        ApiGeneralWhoAmIResponse.prototype.taxId = "";

        /**
         * ApiGeneralWhoAmIResponse displayName.
         * @member {string} displayName
         * @memberof api_general.ApiGeneralWhoAmIResponse
         * @instance
         */
        ApiGeneralWhoAmIResponse.prototype.displayName = "";

        /**
         * ApiGeneralWhoAmIResponse courtRoleId.
         * @member {string} courtRoleId
         * @memberof api_general.ApiGeneralWhoAmIResponse
         * @instance
         */
        ApiGeneralWhoAmIResponse.prototype.courtRoleId = "";

        /**
         * ApiGeneralWhoAmIResponse allowedFeatures.
         * @member {Array.<api_general.IApiGeneralAllowedFeature>} allowedFeatures
         * @memberof api_general.ApiGeneralWhoAmIResponse
         * @instance
         */
        ApiGeneralWhoAmIResponse.prototype.allowedFeatures = $util.emptyArray;

        /**
         * Creates a new ApiGeneralWhoAmIResponse instance using the specified properties.
         * @function create
         * @memberof api_general.ApiGeneralWhoAmIResponse
         * @static
         * @param {api_general.IApiGeneralWhoAmIResponse=} [properties] Properties to set
         * @returns {api_general.ApiGeneralWhoAmIResponse} ApiGeneralWhoAmIResponse instance
         */
        ApiGeneralWhoAmIResponse.create = function create(properties) {
            return new ApiGeneralWhoAmIResponse(properties);
        };

        /**
         * Encodes the specified ApiGeneralWhoAmIResponse message. Does not implicitly {@link api_general.ApiGeneralWhoAmIResponse.verify|verify} messages.
         * @function encode
         * @memberof api_general.ApiGeneralWhoAmIResponse
         * @static
         * @param {api_general.IApiGeneralWhoAmIResponse} message ApiGeneralWhoAmIResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiGeneralWhoAmIResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
            if (message.taxId != null && Object.hasOwnProperty.call(message, "taxId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.taxId);
            if (message.displayName != null && Object.hasOwnProperty.call(message, "displayName"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.displayName);
            if (message.courtRoleId != null && Object.hasOwnProperty.call(message, "courtRoleId"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.courtRoleId);
            if (message.allowedFeatures != null && message.allowedFeatures.length)
                for (let i = 0; i < message.allowedFeatures.length; ++i)
                    $root.api_general.ApiGeneralAllowedFeature.encode(message.allowedFeatures[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ApiGeneralWhoAmIResponse message, length delimited. Does not implicitly {@link api_general.ApiGeneralWhoAmIResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_general.ApiGeneralWhoAmIResponse
         * @static
         * @param {api_general.IApiGeneralWhoAmIResponse} message ApiGeneralWhoAmIResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiGeneralWhoAmIResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiGeneralWhoAmIResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api_general.ApiGeneralWhoAmIResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_general.ApiGeneralWhoAmIResponse} ApiGeneralWhoAmIResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiGeneralWhoAmIResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_general.ApiGeneralWhoAmIResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.userId = reader.string();
                        break;
                    }
                case 2: {
                        message.taxId = reader.string();
                        break;
                    }
                case 3: {
                        message.displayName = reader.string();
                        break;
                    }
                case 4: {
                        message.courtRoleId = reader.string();
                        break;
                    }
                case 5: {
                        if (!(message.allowedFeatures && message.allowedFeatures.length))
                            message.allowedFeatures = [];
                        message.allowedFeatures.push($root.api_general.ApiGeneralAllowedFeature.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiGeneralWhoAmIResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_general.ApiGeneralWhoAmIResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_general.ApiGeneralWhoAmIResponse} ApiGeneralWhoAmIResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiGeneralWhoAmIResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiGeneralWhoAmIResponse message.
         * @function verify
         * @memberof api_general.ApiGeneralWhoAmIResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiGeneralWhoAmIResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.userId != null && message.hasOwnProperty("userId"))
                if (!$util.isString(message.userId))
                    return "userId: string expected";
            if (message.taxId != null && message.hasOwnProperty("taxId"))
                if (!$util.isString(message.taxId))
                    return "taxId: string expected";
            if (message.displayName != null && message.hasOwnProperty("displayName"))
                if (!$util.isString(message.displayName))
                    return "displayName: string expected";
            if (message.courtRoleId != null && message.hasOwnProperty("courtRoleId"))
                if (!$util.isString(message.courtRoleId))
                    return "courtRoleId: string expected";
            if (message.allowedFeatures != null && message.hasOwnProperty("allowedFeatures")) {
                if (!Array.isArray(message.allowedFeatures))
                    return "allowedFeatures: array expected";
                for (let i = 0; i < message.allowedFeatures.length; ++i) {
                    let error = $root.api_general.ApiGeneralAllowedFeature.verify(message.allowedFeatures[i]);
                    if (error)
                        return "allowedFeatures." + error;
                }
            }
            return null;
        };

        /**
         * Creates an ApiGeneralWhoAmIResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_general.ApiGeneralWhoAmIResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_general.ApiGeneralWhoAmIResponse} ApiGeneralWhoAmIResponse
         */
        ApiGeneralWhoAmIResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api_general.ApiGeneralWhoAmIResponse)
                return object;
            let message = new $root.api_general.ApiGeneralWhoAmIResponse();
            if (object.userId != null)
                message.userId = String(object.userId);
            if (object.taxId != null)
                message.taxId = String(object.taxId);
            if (object.displayName != null)
                message.displayName = String(object.displayName);
            if (object.courtRoleId != null)
                message.courtRoleId = String(object.courtRoleId);
            if (object.allowedFeatures) {
                if (!Array.isArray(object.allowedFeatures))
                    throw TypeError(".api_general.ApiGeneralWhoAmIResponse.allowedFeatures: array expected");
                message.allowedFeatures = [];
                for (let i = 0; i < object.allowedFeatures.length; ++i) {
                    if (typeof object.allowedFeatures[i] !== "object")
                        throw TypeError(".api_general.ApiGeneralWhoAmIResponse.allowedFeatures: object expected");
                    message.allowedFeatures[i] = $root.api_general.ApiGeneralAllowedFeature.fromObject(object.allowedFeatures[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from an ApiGeneralWhoAmIResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_general.ApiGeneralWhoAmIResponse
         * @static
         * @param {api_general.ApiGeneralWhoAmIResponse} message ApiGeneralWhoAmIResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiGeneralWhoAmIResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.allowedFeatures = [];
            if (options.defaults) {
                object.userId = "";
                object.taxId = "";
                object.displayName = "";
                object.courtRoleId = "";
            }
            if (message.userId != null && message.hasOwnProperty("userId"))
                object.userId = message.userId;
            if (message.taxId != null && message.hasOwnProperty("taxId"))
                object.taxId = message.taxId;
            if (message.displayName != null && message.hasOwnProperty("displayName"))
                object.displayName = message.displayName;
            if (message.courtRoleId != null && message.hasOwnProperty("courtRoleId"))
                object.courtRoleId = message.courtRoleId;
            if (message.allowedFeatures && message.allowedFeatures.length) {
                object.allowedFeatures = [];
                for (let j = 0; j < message.allowedFeatures.length; ++j)
                    object.allowedFeatures[j] = $root.api_general.ApiGeneralAllowedFeature.toObject(message.allowedFeatures[j], options);
            }
            return object;
        };

        /**
         * Converts this ApiGeneralWhoAmIResponse to JSON.
         * @function toJSON
         * @memberof api_general.ApiGeneralWhoAmIResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiGeneralWhoAmIResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiGeneralWhoAmIResponse
         * @function getTypeUrl
         * @memberof api_general.ApiGeneralWhoAmIResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiGeneralWhoAmIResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_general.ApiGeneralWhoAmIResponse";
        };

        return ApiGeneralWhoAmIResponse;
    })();

    api_general.ApiGeneralAllowedFeature = (function() {

        /**
         * Properties of an ApiGeneralAllowedFeature.
         * @memberof api_general
         * @interface IApiGeneralAllowedFeature
         * @property {string|null} [feature] ApiGeneralAllowedFeature feature
         * @property {string|null} [action] ApiGeneralAllowedFeature action
         */

        /**
         * Constructs a new ApiGeneralAllowedFeature.
         * @memberof api_general
         * @classdesc Represents an ApiGeneralAllowedFeature.
         * @implements IApiGeneralAllowedFeature
         * @constructor
         * @param {api_general.IApiGeneralAllowedFeature=} [properties] Properties to set
         */
        function ApiGeneralAllowedFeature(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiGeneralAllowedFeature feature.
         * @member {string} feature
         * @memberof api_general.ApiGeneralAllowedFeature
         * @instance
         */
        ApiGeneralAllowedFeature.prototype.feature = "";

        /**
         * ApiGeneralAllowedFeature action.
         * @member {string} action
         * @memberof api_general.ApiGeneralAllowedFeature
         * @instance
         */
        ApiGeneralAllowedFeature.prototype.action = "";

        /**
         * Creates a new ApiGeneralAllowedFeature instance using the specified properties.
         * @function create
         * @memberof api_general.ApiGeneralAllowedFeature
         * @static
         * @param {api_general.IApiGeneralAllowedFeature=} [properties] Properties to set
         * @returns {api_general.ApiGeneralAllowedFeature} ApiGeneralAllowedFeature instance
         */
        ApiGeneralAllowedFeature.create = function create(properties) {
            return new ApiGeneralAllowedFeature(properties);
        };

        /**
         * Encodes the specified ApiGeneralAllowedFeature message. Does not implicitly {@link api_general.ApiGeneralAllowedFeature.verify|verify} messages.
         * @function encode
         * @memberof api_general.ApiGeneralAllowedFeature
         * @static
         * @param {api_general.IApiGeneralAllowedFeature} message ApiGeneralAllowedFeature message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiGeneralAllowedFeature.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.feature != null && Object.hasOwnProperty.call(message, "feature"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.feature);
            if (message.action != null && Object.hasOwnProperty.call(message, "action"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.action);
            return writer;
        };

        /**
         * Encodes the specified ApiGeneralAllowedFeature message, length delimited. Does not implicitly {@link api_general.ApiGeneralAllowedFeature.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_general.ApiGeneralAllowedFeature
         * @static
         * @param {api_general.IApiGeneralAllowedFeature} message ApiGeneralAllowedFeature message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiGeneralAllowedFeature.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiGeneralAllowedFeature message from the specified reader or buffer.
         * @function decode
         * @memberof api_general.ApiGeneralAllowedFeature
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_general.ApiGeneralAllowedFeature} ApiGeneralAllowedFeature
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiGeneralAllowedFeature.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_general.ApiGeneralAllowedFeature();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.feature = reader.string();
                        break;
                    }
                case 2: {
                        message.action = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiGeneralAllowedFeature message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_general.ApiGeneralAllowedFeature
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_general.ApiGeneralAllowedFeature} ApiGeneralAllowedFeature
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiGeneralAllowedFeature.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiGeneralAllowedFeature message.
         * @function verify
         * @memberof api_general.ApiGeneralAllowedFeature
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiGeneralAllowedFeature.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.feature != null && message.hasOwnProperty("feature"))
                if (!$util.isString(message.feature))
                    return "feature: string expected";
            if (message.action != null && message.hasOwnProperty("action"))
                if (!$util.isString(message.action))
                    return "action: string expected";
            return null;
        };

        /**
         * Creates an ApiGeneralAllowedFeature message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_general.ApiGeneralAllowedFeature
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_general.ApiGeneralAllowedFeature} ApiGeneralAllowedFeature
         */
        ApiGeneralAllowedFeature.fromObject = function fromObject(object) {
            if (object instanceof $root.api_general.ApiGeneralAllowedFeature)
                return object;
            let message = new $root.api_general.ApiGeneralAllowedFeature();
            if (object.feature != null)
                message.feature = String(object.feature);
            if (object.action != null)
                message.action = String(object.action);
            return message;
        };

        /**
         * Creates a plain object from an ApiGeneralAllowedFeature message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_general.ApiGeneralAllowedFeature
         * @static
         * @param {api_general.ApiGeneralAllowedFeature} message ApiGeneralAllowedFeature
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiGeneralAllowedFeature.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.feature = "";
                object.action = "";
            }
            if (message.feature != null && message.hasOwnProperty("feature"))
                object.feature = message.feature;
            if (message.action != null && message.hasOwnProperty("action"))
                object.action = message.action;
            return object;
        };

        /**
         * Converts this ApiGeneralAllowedFeature to JSON.
         * @function toJSON
         * @memberof api_general.ApiGeneralAllowedFeature
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiGeneralAllowedFeature.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiGeneralAllowedFeature
         * @function getTypeUrl
         * @memberof api_general.ApiGeneralAllowedFeature
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiGeneralAllowedFeature.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_general.ApiGeneralAllowedFeature";
        };

        return ApiGeneralAllowedFeature;
    })();

    api_general.ApiGeneralWhoArePersonUuids = (function() {

        /**
         * Properties of an ApiGeneralWhoArePersonUuids.
         * @memberof api_general
         * @interface IApiGeneralWhoArePersonUuids
         * @property {Array.<string>|null} [uuids] ApiGeneralWhoArePersonUuids uuids
         */

        /**
         * Constructs a new ApiGeneralWhoArePersonUuids.
         * @memberof api_general
         * @classdesc Represents an ApiGeneralWhoArePersonUuids.
         * @implements IApiGeneralWhoArePersonUuids
         * @constructor
         * @param {api_general.IApiGeneralWhoArePersonUuids=} [properties] Properties to set
         */
        function ApiGeneralWhoArePersonUuids(properties) {
            this.uuids = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiGeneralWhoArePersonUuids uuids.
         * @member {Array.<string>} uuids
         * @memberof api_general.ApiGeneralWhoArePersonUuids
         * @instance
         */
        ApiGeneralWhoArePersonUuids.prototype.uuids = $util.emptyArray;

        /**
         * Creates a new ApiGeneralWhoArePersonUuids instance using the specified properties.
         * @function create
         * @memberof api_general.ApiGeneralWhoArePersonUuids
         * @static
         * @param {api_general.IApiGeneralWhoArePersonUuids=} [properties] Properties to set
         * @returns {api_general.ApiGeneralWhoArePersonUuids} ApiGeneralWhoArePersonUuids instance
         */
        ApiGeneralWhoArePersonUuids.create = function create(properties) {
            return new ApiGeneralWhoArePersonUuids(properties);
        };

        /**
         * Encodes the specified ApiGeneralWhoArePersonUuids message. Does not implicitly {@link api_general.ApiGeneralWhoArePersonUuids.verify|verify} messages.
         * @function encode
         * @memberof api_general.ApiGeneralWhoArePersonUuids
         * @static
         * @param {api_general.IApiGeneralWhoArePersonUuids} message ApiGeneralWhoArePersonUuids message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiGeneralWhoArePersonUuids.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.uuids != null && message.uuids.length)
                for (let i = 0; i < message.uuids.length; ++i)
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.uuids[i]);
            return writer;
        };

        /**
         * Encodes the specified ApiGeneralWhoArePersonUuids message, length delimited. Does not implicitly {@link api_general.ApiGeneralWhoArePersonUuids.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_general.ApiGeneralWhoArePersonUuids
         * @static
         * @param {api_general.IApiGeneralWhoArePersonUuids} message ApiGeneralWhoArePersonUuids message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiGeneralWhoArePersonUuids.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiGeneralWhoArePersonUuids message from the specified reader or buffer.
         * @function decode
         * @memberof api_general.ApiGeneralWhoArePersonUuids
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_general.ApiGeneralWhoArePersonUuids} ApiGeneralWhoArePersonUuids
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiGeneralWhoArePersonUuids.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_general.ApiGeneralWhoArePersonUuids();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.uuids && message.uuids.length))
                            message.uuids = [];
                        message.uuids.push(reader.string());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiGeneralWhoArePersonUuids message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_general.ApiGeneralWhoArePersonUuids
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_general.ApiGeneralWhoArePersonUuids} ApiGeneralWhoArePersonUuids
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiGeneralWhoArePersonUuids.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiGeneralWhoArePersonUuids message.
         * @function verify
         * @memberof api_general.ApiGeneralWhoArePersonUuids
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiGeneralWhoArePersonUuids.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.uuids != null && message.hasOwnProperty("uuids")) {
                if (!Array.isArray(message.uuids))
                    return "uuids: array expected";
                for (let i = 0; i < message.uuids.length; ++i)
                    if (!$util.isString(message.uuids[i]))
                        return "uuids: string[] expected";
            }
            return null;
        };

        /**
         * Creates an ApiGeneralWhoArePersonUuids message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_general.ApiGeneralWhoArePersonUuids
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_general.ApiGeneralWhoArePersonUuids} ApiGeneralWhoArePersonUuids
         */
        ApiGeneralWhoArePersonUuids.fromObject = function fromObject(object) {
            if (object instanceof $root.api_general.ApiGeneralWhoArePersonUuids)
                return object;
            let message = new $root.api_general.ApiGeneralWhoArePersonUuids();
            if (object.uuids) {
                if (!Array.isArray(object.uuids))
                    throw TypeError(".api_general.ApiGeneralWhoArePersonUuids.uuids: array expected");
                message.uuids = [];
                for (let i = 0; i < object.uuids.length; ++i)
                    message.uuids[i] = String(object.uuids[i]);
            }
            return message;
        };

        /**
         * Creates a plain object from an ApiGeneralWhoArePersonUuids message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_general.ApiGeneralWhoArePersonUuids
         * @static
         * @param {api_general.ApiGeneralWhoArePersonUuids} message ApiGeneralWhoArePersonUuids
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiGeneralWhoArePersonUuids.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.uuids = [];
            if (message.uuids && message.uuids.length) {
                object.uuids = [];
                for (let j = 0; j < message.uuids.length; ++j)
                    object.uuids[j] = message.uuids[j];
            }
            return object;
        };

        /**
         * Converts this ApiGeneralWhoArePersonUuids to JSON.
         * @function toJSON
         * @memberof api_general.ApiGeneralWhoArePersonUuids
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiGeneralWhoArePersonUuids.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiGeneralWhoArePersonUuids
         * @function getTypeUrl
         * @memberof api_general.ApiGeneralWhoArePersonUuids
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiGeneralWhoArePersonUuids.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_general.ApiGeneralWhoArePersonUuids";
        };

        return ApiGeneralWhoArePersonUuids;
    })();

    api_general.ApiGeneralWhoArePersonUuidsResponse = (function() {

        /**
         * Properties of an ApiGeneralWhoArePersonUuidsResponse.
         * @memberof api_general
         * @interface IApiGeneralWhoArePersonUuidsResponse
         * @property {string|null} [sourceEventId] ApiGeneralWhoArePersonUuidsResponse sourceEventId
         * @property {Array.<api_general.IApiGeneralWhoArePersonUuidsResponseEntry>|null} [persons] ApiGeneralWhoArePersonUuidsResponse persons
         */

        /**
         * Constructs a new ApiGeneralWhoArePersonUuidsResponse.
         * @memberof api_general
         * @classdesc Represents an ApiGeneralWhoArePersonUuidsResponse.
         * @implements IApiGeneralWhoArePersonUuidsResponse
         * @constructor
         * @param {api_general.IApiGeneralWhoArePersonUuidsResponse=} [properties] Properties to set
         */
        function ApiGeneralWhoArePersonUuidsResponse(properties) {
            this.persons = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiGeneralWhoArePersonUuidsResponse sourceEventId.
         * @member {string} sourceEventId
         * @memberof api_general.ApiGeneralWhoArePersonUuidsResponse
         * @instance
         */
        ApiGeneralWhoArePersonUuidsResponse.prototype.sourceEventId = "";

        /**
         * ApiGeneralWhoArePersonUuidsResponse persons.
         * @member {Array.<api_general.IApiGeneralWhoArePersonUuidsResponseEntry>} persons
         * @memberof api_general.ApiGeneralWhoArePersonUuidsResponse
         * @instance
         */
        ApiGeneralWhoArePersonUuidsResponse.prototype.persons = $util.emptyArray;

        /**
         * Creates a new ApiGeneralWhoArePersonUuidsResponse instance using the specified properties.
         * @function create
         * @memberof api_general.ApiGeneralWhoArePersonUuidsResponse
         * @static
         * @param {api_general.IApiGeneralWhoArePersonUuidsResponse=} [properties] Properties to set
         * @returns {api_general.ApiGeneralWhoArePersonUuidsResponse} ApiGeneralWhoArePersonUuidsResponse instance
         */
        ApiGeneralWhoArePersonUuidsResponse.create = function create(properties) {
            return new ApiGeneralWhoArePersonUuidsResponse(properties);
        };

        /**
         * Encodes the specified ApiGeneralWhoArePersonUuidsResponse message. Does not implicitly {@link api_general.ApiGeneralWhoArePersonUuidsResponse.verify|verify} messages.
         * @function encode
         * @memberof api_general.ApiGeneralWhoArePersonUuidsResponse
         * @static
         * @param {api_general.IApiGeneralWhoArePersonUuidsResponse} message ApiGeneralWhoArePersonUuidsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiGeneralWhoArePersonUuidsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sourceEventId != null && Object.hasOwnProperty.call(message, "sourceEventId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.sourceEventId);
            if (message.persons != null && message.persons.length)
                for (let i = 0; i < message.persons.length; ++i)
                    $root.api_general.ApiGeneralWhoArePersonUuidsResponseEntry.encode(message.persons[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ApiGeneralWhoArePersonUuidsResponse message, length delimited. Does not implicitly {@link api_general.ApiGeneralWhoArePersonUuidsResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_general.ApiGeneralWhoArePersonUuidsResponse
         * @static
         * @param {api_general.IApiGeneralWhoArePersonUuidsResponse} message ApiGeneralWhoArePersonUuidsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiGeneralWhoArePersonUuidsResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiGeneralWhoArePersonUuidsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api_general.ApiGeneralWhoArePersonUuidsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_general.ApiGeneralWhoArePersonUuidsResponse} ApiGeneralWhoArePersonUuidsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiGeneralWhoArePersonUuidsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_general.ApiGeneralWhoArePersonUuidsResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sourceEventId = reader.string();
                        break;
                    }
                case 2: {
                        if (!(message.persons && message.persons.length))
                            message.persons = [];
                        message.persons.push($root.api_general.ApiGeneralWhoArePersonUuidsResponseEntry.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiGeneralWhoArePersonUuidsResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_general.ApiGeneralWhoArePersonUuidsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_general.ApiGeneralWhoArePersonUuidsResponse} ApiGeneralWhoArePersonUuidsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiGeneralWhoArePersonUuidsResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiGeneralWhoArePersonUuidsResponse message.
         * @function verify
         * @memberof api_general.ApiGeneralWhoArePersonUuidsResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiGeneralWhoArePersonUuidsResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.sourceEventId != null && message.hasOwnProperty("sourceEventId"))
                if (!$util.isString(message.sourceEventId))
                    return "sourceEventId: string expected";
            if (message.persons != null && message.hasOwnProperty("persons")) {
                if (!Array.isArray(message.persons))
                    return "persons: array expected";
                for (let i = 0; i < message.persons.length; ++i) {
                    let error = $root.api_general.ApiGeneralWhoArePersonUuidsResponseEntry.verify(message.persons[i]);
                    if (error)
                        return "persons." + error;
                }
            }
            return null;
        };

        /**
         * Creates an ApiGeneralWhoArePersonUuidsResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_general.ApiGeneralWhoArePersonUuidsResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_general.ApiGeneralWhoArePersonUuidsResponse} ApiGeneralWhoArePersonUuidsResponse
         */
        ApiGeneralWhoArePersonUuidsResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api_general.ApiGeneralWhoArePersonUuidsResponse)
                return object;
            let message = new $root.api_general.ApiGeneralWhoArePersonUuidsResponse();
            if (object.sourceEventId != null)
                message.sourceEventId = String(object.sourceEventId);
            if (object.persons) {
                if (!Array.isArray(object.persons))
                    throw TypeError(".api_general.ApiGeneralWhoArePersonUuidsResponse.persons: array expected");
                message.persons = [];
                for (let i = 0; i < object.persons.length; ++i) {
                    if (typeof object.persons[i] !== "object")
                        throw TypeError(".api_general.ApiGeneralWhoArePersonUuidsResponse.persons: object expected");
                    message.persons[i] = $root.api_general.ApiGeneralWhoArePersonUuidsResponseEntry.fromObject(object.persons[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from an ApiGeneralWhoArePersonUuidsResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_general.ApiGeneralWhoArePersonUuidsResponse
         * @static
         * @param {api_general.ApiGeneralWhoArePersonUuidsResponse} message ApiGeneralWhoArePersonUuidsResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiGeneralWhoArePersonUuidsResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.persons = [];
            if (options.defaults)
                object.sourceEventId = "";
            if (message.sourceEventId != null && message.hasOwnProperty("sourceEventId"))
                object.sourceEventId = message.sourceEventId;
            if (message.persons && message.persons.length) {
                object.persons = [];
                for (let j = 0; j < message.persons.length; ++j)
                    object.persons[j] = $root.api_general.ApiGeneralWhoArePersonUuidsResponseEntry.toObject(message.persons[j], options);
            }
            return object;
        };

        /**
         * Converts this ApiGeneralWhoArePersonUuidsResponse to JSON.
         * @function toJSON
         * @memberof api_general.ApiGeneralWhoArePersonUuidsResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiGeneralWhoArePersonUuidsResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiGeneralWhoArePersonUuidsResponse
         * @function getTypeUrl
         * @memberof api_general.ApiGeneralWhoArePersonUuidsResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiGeneralWhoArePersonUuidsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_general.ApiGeneralWhoArePersonUuidsResponse";
        };

        return ApiGeneralWhoArePersonUuidsResponse;
    })();

    api_general.ApiGeneralWhoArePersonUuidsResponseEntry = (function() {

        /**
         * Properties of an ApiGeneralWhoArePersonUuidsResponseEntry.
         * @memberof api_general
         * @interface IApiGeneralWhoArePersonUuidsResponseEntry
         * @property {string|null} [personId] ApiGeneralWhoArePersonUuidsResponseEntry personId
         * @property {string|null} [taxId] ApiGeneralWhoArePersonUuidsResponseEntry taxId
         * @property {string|null} [nameSurname] ApiGeneralWhoArePersonUuidsResponseEntry nameSurname
         */

        /**
         * Constructs a new ApiGeneralWhoArePersonUuidsResponseEntry.
         * @memberof api_general
         * @classdesc Represents an ApiGeneralWhoArePersonUuidsResponseEntry.
         * @implements IApiGeneralWhoArePersonUuidsResponseEntry
         * @constructor
         * @param {api_general.IApiGeneralWhoArePersonUuidsResponseEntry=} [properties] Properties to set
         */
        function ApiGeneralWhoArePersonUuidsResponseEntry(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiGeneralWhoArePersonUuidsResponseEntry personId.
         * @member {string} personId
         * @memberof api_general.ApiGeneralWhoArePersonUuidsResponseEntry
         * @instance
         */
        ApiGeneralWhoArePersonUuidsResponseEntry.prototype.personId = "";

        /**
         * ApiGeneralWhoArePersonUuidsResponseEntry taxId.
         * @member {string} taxId
         * @memberof api_general.ApiGeneralWhoArePersonUuidsResponseEntry
         * @instance
         */
        ApiGeneralWhoArePersonUuidsResponseEntry.prototype.taxId = "";

        /**
         * ApiGeneralWhoArePersonUuidsResponseEntry nameSurname.
         * @member {string} nameSurname
         * @memberof api_general.ApiGeneralWhoArePersonUuidsResponseEntry
         * @instance
         */
        ApiGeneralWhoArePersonUuidsResponseEntry.prototype.nameSurname = "";

        /**
         * Creates a new ApiGeneralWhoArePersonUuidsResponseEntry instance using the specified properties.
         * @function create
         * @memberof api_general.ApiGeneralWhoArePersonUuidsResponseEntry
         * @static
         * @param {api_general.IApiGeneralWhoArePersonUuidsResponseEntry=} [properties] Properties to set
         * @returns {api_general.ApiGeneralWhoArePersonUuidsResponseEntry} ApiGeneralWhoArePersonUuidsResponseEntry instance
         */
        ApiGeneralWhoArePersonUuidsResponseEntry.create = function create(properties) {
            return new ApiGeneralWhoArePersonUuidsResponseEntry(properties);
        };

        /**
         * Encodes the specified ApiGeneralWhoArePersonUuidsResponseEntry message. Does not implicitly {@link api_general.ApiGeneralWhoArePersonUuidsResponseEntry.verify|verify} messages.
         * @function encode
         * @memberof api_general.ApiGeneralWhoArePersonUuidsResponseEntry
         * @static
         * @param {api_general.IApiGeneralWhoArePersonUuidsResponseEntry} message ApiGeneralWhoArePersonUuidsResponseEntry message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiGeneralWhoArePersonUuidsResponseEntry.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.personId != null && Object.hasOwnProperty.call(message, "personId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.personId);
            if (message.taxId != null && Object.hasOwnProperty.call(message, "taxId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.taxId);
            if (message.nameSurname != null && Object.hasOwnProperty.call(message, "nameSurname"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.nameSurname);
            return writer;
        };

        /**
         * Encodes the specified ApiGeneralWhoArePersonUuidsResponseEntry message, length delimited. Does not implicitly {@link api_general.ApiGeneralWhoArePersonUuidsResponseEntry.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_general.ApiGeneralWhoArePersonUuidsResponseEntry
         * @static
         * @param {api_general.IApiGeneralWhoArePersonUuidsResponseEntry} message ApiGeneralWhoArePersonUuidsResponseEntry message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiGeneralWhoArePersonUuidsResponseEntry.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiGeneralWhoArePersonUuidsResponseEntry message from the specified reader or buffer.
         * @function decode
         * @memberof api_general.ApiGeneralWhoArePersonUuidsResponseEntry
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_general.ApiGeneralWhoArePersonUuidsResponseEntry} ApiGeneralWhoArePersonUuidsResponseEntry
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiGeneralWhoArePersonUuidsResponseEntry.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_general.ApiGeneralWhoArePersonUuidsResponseEntry();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.personId = reader.string();
                        break;
                    }
                case 2: {
                        message.taxId = reader.string();
                        break;
                    }
                case 3: {
                        message.nameSurname = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiGeneralWhoArePersonUuidsResponseEntry message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_general.ApiGeneralWhoArePersonUuidsResponseEntry
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_general.ApiGeneralWhoArePersonUuidsResponseEntry} ApiGeneralWhoArePersonUuidsResponseEntry
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiGeneralWhoArePersonUuidsResponseEntry.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiGeneralWhoArePersonUuidsResponseEntry message.
         * @function verify
         * @memberof api_general.ApiGeneralWhoArePersonUuidsResponseEntry
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiGeneralWhoArePersonUuidsResponseEntry.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.personId != null && message.hasOwnProperty("personId"))
                if (!$util.isString(message.personId))
                    return "personId: string expected";
            if (message.taxId != null && message.hasOwnProperty("taxId"))
                if (!$util.isString(message.taxId))
                    return "taxId: string expected";
            if (message.nameSurname != null && message.hasOwnProperty("nameSurname"))
                if (!$util.isString(message.nameSurname))
                    return "nameSurname: string expected";
            return null;
        };

        /**
         * Creates an ApiGeneralWhoArePersonUuidsResponseEntry message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_general.ApiGeneralWhoArePersonUuidsResponseEntry
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_general.ApiGeneralWhoArePersonUuidsResponseEntry} ApiGeneralWhoArePersonUuidsResponseEntry
         */
        ApiGeneralWhoArePersonUuidsResponseEntry.fromObject = function fromObject(object) {
            if (object instanceof $root.api_general.ApiGeneralWhoArePersonUuidsResponseEntry)
                return object;
            let message = new $root.api_general.ApiGeneralWhoArePersonUuidsResponseEntry();
            if (object.personId != null)
                message.personId = String(object.personId);
            if (object.taxId != null)
                message.taxId = String(object.taxId);
            if (object.nameSurname != null)
                message.nameSurname = String(object.nameSurname);
            return message;
        };

        /**
         * Creates a plain object from an ApiGeneralWhoArePersonUuidsResponseEntry message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_general.ApiGeneralWhoArePersonUuidsResponseEntry
         * @static
         * @param {api_general.ApiGeneralWhoArePersonUuidsResponseEntry} message ApiGeneralWhoArePersonUuidsResponseEntry
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiGeneralWhoArePersonUuidsResponseEntry.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.personId = "";
                object.taxId = "";
                object.nameSurname = "";
            }
            if (message.personId != null && message.hasOwnProperty("personId"))
                object.personId = message.personId;
            if (message.taxId != null && message.hasOwnProperty("taxId"))
                object.taxId = message.taxId;
            if (message.nameSurname != null && message.hasOwnProperty("nameSurname"))
                object.nameSurname = message.nameSurname;
            return object;
        };

        /**
         * Converts this ApiGeneralWhoArePersonUuidsResponseEntry to JSON.
         * @function toJSON
         * @memberof api_general.ApiGeneralWhoArePersonUuidsResponseEntry
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiGeneralWhoArePersonUuidsResponseEntry.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiGeneralWhoArePersonUuidsResponseEntry
         * @function getTypeUrl
         * @memberof api_general.ApiGeneralWhoArePersonUuidsResponseEntry
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiGeneralWhoArePersonUuidsResponseEntry.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_general.ApiGeneralWhoArePersonUuidsResponseEntry";
        };

        return ApiGeneralWhoArePersonUuidsResponseEntry;
    })();

    api_general.ApiGeneralAck = (function() {

        /**
         * Properties of an ApiGeneralAck.
         * @memberof api_general
         * @interface IApiGeneralAck
         * @property {string|null} [sourceEventId] ApiGeneralAck sourceEventId
         */

        /**
         * Constructs a new ApiGeneralAck.
         * @memberof api_general
         * @classdesc Represents an ApiGeneralAck.
         * @implements IApiGeneralAck
         * @constructor
         * @param {api_general.IApiGeneralAck=} [properties] Properties to set
         */
        function ApiGeneralAck(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiGeneralAck sourceEventId.
         * @member {string} sourceEventId
         * @memberof api_general.ApiGeneralAck
         * @instance
         */
        ApiGeneralAck.prototype.sourceEventId = "";

        /**
         * Creates a new ApiGeneralAck instance using the specified properties.
         * @function create
         * @memberof api_general.ApiGeneralAck
         * @static
         * @param {api_general.IApiGeneralAck=} [properties] Properties to set
         * @returns {api_general.ApiGeneralAck} ApiGeneralAck instance
         */
        ApiGeneralAck.create = function create(properties) {
            return new ApiGeneralAck(properties);
        };

        /**
         * Encodes the specified ApiGeneralAck message. Does not implicitly {@link api_general.ApiGeneralAck.verify|verify} messages.
         * @function encode
         * @memberof api_general.ApiGeneralAck
         * @static
         * @param {api_general.IApiGeneralAck} message ApiGeneralAck message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiGeneralAck.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sourceEventId != null && Object.hasOwnProperty.call(message, "sourceEventId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.sourceEventId);
            return writer;
        };

        /**
         * Encodes the specified ApiGeneralAck message, length delimited. Does not implicitly {@link api_general.ApiGeneralAck.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_general.ApiGeneralAck
         * @static
         * @param {api_general.IApiGeneralAck} message ApiGeneralAck message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiGeneralAck.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiGeneralAck message from the specified reader or buffer.
         * @function decode
         * @memberof api_general.ApiGeneralAck
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_general.ApiGeneralAck} ApiGeneralAck
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiGeneralAck.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_general.ApiGeneralAck();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sourceEventId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiGeneralAck message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_general.ApiGeneralAck
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_general.ApiGeneralAck} ApiGeneralAck
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiGeneralAck.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiGeneralAck message.
         * @function verify
         * @memberof api_general.ApiGeneralAck
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiGeneralAck.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.sourceEventId != null && message.hasOwnProperty("sourceEventId"))
                if (!$util.isString(message.sourceEventId))
                    return "sourceEventId: string expected";
            return null;
        };

        /**
         * Creates an ApiGeneralAck message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_general.ApiGeneralAck
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_general.ApiGeneralAck} ApiGeneralAck
         */
        ApiGeneralAck.fromObject = function fromObject(object) {
            if (object instanceof $root.api_general.ApiGeneralAck)
                return object;
            let message = new $root.api_general.ApiGeneralAck();
            if (object.sourceEventId != null)
                message.sourceEventId = String(object.sourceEventId);
            return message;
        };

        /**
         * Creates a plain object from an ApiGeneralAck message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_general.ApiGeneralAck
         * @static
         * @param {api_general.ApiGeneralAck} message ApiGeneralAck
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiGeneralAck.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.sourceEventId = "";
            if (message.sourceEventId != null && message.hasOwnProperty("sourceEventId"))
                object.sourceEventId = message.sourceEventId;
            return object;
        };

        /**
         * Converts this ApiGeneralAck to JSON.
         * @function toJSON
         * @memberof api_general.ApiGeneralAck
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiGeneralAck.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiGeneralAck
         * @function getTypeUrl
         * @memberof api_general.ApiGeneralAck
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiGeneralAck.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_general.ApiGeneralAck";
        };

        return ApiGeneralAck;
    })();

    return api_general;
})();

export const asod_recorder_project = $root.asod_recorder_project = (() => {

    /**
     * Namespace asod_recorder_project.
     * @exports asod_recorder_project
     * @namespace
     */
    const asod_recorder_project = {};

    asod_recorder_project.AsodRecorderProjectComplete = (function() {

        /**
         * Properties of an AsodRecorderProjectComplete.
         * @memberof asod_recorder_project
         * @interface IAsodRecorderProjectComplete
         */

        /**
         * Constructs a new AsodRecorderProjectComplete.
         * @memberof asod_recorder_project
         * @classdesc Represents an AsodRecorderProjectComplete.
         * @implements IAsodRecorderProjectComplete
         * @constructor
         * @param {asod_recorder_project.IAsodRecorderProjectComplete=} [properties] Properties to set
         */
        function AsodRecorderProjectComplete(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new AsodRecorderProjectComplete instance using the specified properties.
         * @function create
         * @memberof asod_recorder_project.AsodRecorderProjectComplete
         * @static
         * @param {asod_recorder_project.IAsodRecorderProjectComplete=} [properties] Properties to set
         * @returns {asod_recorder_project.AsodRecorderProjectComplete} AsodRecorderProjectComplete instance
         */
        AsodRecorderProjectComplete.create = function create(properties) {
            return new AsodRecorderProjectComplete(properties);
        };

        /**
         * Encodes the specified AsodRecorderProjectComplete message. Does not implicitly {@link asod_recorder_project.AsodRecorderProjectComplete.verify|verify} messages.
         * @function encode
         * @memberof asod_recorder_project.AsodRecorderProjectComplete
         * @static
         * @param {asod_recorder_project.IAsodRecorderProjectComplete} message AsodRecorderProjectComplete message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodRecorderProjectComplete.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified AsodRecorderProjectComplete message, length delimited. Does not implicitly {@link asod_recorder_project.AsodRecorderProjectComplete.verify|verify} messages.
         * @function encodeDelimited
         * @memberof asod_recorder_project.AsodRecorderProjectComplete
         * @static
         * @param {asod_recorder_project.IAsodRecorderProjectComplete} message AsodRecorderProjectComplete message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodRecorderProjectComplete.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AsodRecorderProjectComplete message from the specified reader or buffer.
         * @function decode
         * @memberof asod_recorder_project.AsodRecorderProjectComplete
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {asod_recorder_project.AsodRecorderProjectComplete} AsodRecorderProjectComplete
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodRecorderProjectComplete.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.asod_recorder_project.AsodRecorderProjectComplete();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AsodRecorderProjectComplete message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof asod_recorder_project.AsodRecorderProjectComplete
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {asod_recorder_project.AsodRecorderProjectComplete} AsodRecorderProjectComplete
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodRecorderProjectComplete.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AsodRecorderProjectComplete message.
         * @function verify
         * @memberof asod_recorder_project.AsodRecorderProjectComplete
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AsodRecorderProjectComplete.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an AsodRecorderProjectComplete message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof asod_recorder_project.AsodRecorderProjectComplete
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {asod_recorder_project.AsodRecorderProjectComplete} AsodRecorderProjectComplete
         */
        AsodRecorderProjectComplete.fromObject = function fromObject(object) {
            if (object instanceof $root.asod_recorder_project.AsodRecorderProjectComplete)
                return object;
            return new $root.asod_recorder_project.AsodRecorderProjectComplete();
        };

        /**
         * Creates a plain object from an AsodRecorderProjectComplete message. Also converts values to other types if specified.
         * @function toObject
         * @memberof asod_recorder_project.AsodRecorderProjectComplete
         * @static
         * @param {asod_recorder_project.AsodRecorderProjectComplete} message AsodRecorderProjectComplete
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AsodRecorderProjectComplete.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this AsodRecorderProjectComplete to JSON.
         * @function toJSON
         * @memberof asod_recorder_project.AsodRecorderProjectComplete
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AsodRecorderProjectComplete.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AsodRecorderProjectComplete
         * @function getTypeUrl
         * @memberof asod_recorder_project.AsodRecorderProjectComplete
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AsodRecorderProjectComplete.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/asod_recorder_project.AsodRecorderProjectComplete";
        };

        return AsodRecorderProjectComplete;
    })();

    asod_recorder_project.AsodRecorderProjectAudioBlock = (function() {

        /**
         * Properties of an AsodRecorderProjectAudioBlock.
         * @memberof asod_recorder_project
         * @interface IAsodRecorderProjectAudioBlock
         * @property {string|null} [projectId] AsodRecorderProjectAudioBlock projectId
         * @property {asod_project.IAsodProjectAudioBlock|null} [block] AsodRecorderProjectAudioBlock block
         */

        /**
         * Constructs a new AsodRecorderProjectAudioBlock.
         * @memberof asod_recorder_project
         * @classdesc Represents an AsodRecorderProjectAudioBlock.
         * @implements IAsodRecorderProjectAudioBlock
         * @constructor
         * @param {asod_recorder_project.IAsodRecorderProjectAudioBlock=} [properties] Properties to set
         */
        function AsodRecorderProjectAudioBlock(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AsodRecorderProjectAudioBlock projectId.
         * @member {string} projectId
         * @memberof asod_recorder_project.AsodRecorderProjectAudioBlock
         * @instance
         */
        AsodRecorderProjectAudioBlock.prototype.projectId = "";

        /**
         * AsodRecorderProjectAudioBlock block.
         * @member {asod_project.IAsodProjectAudioBlock|null|undefined} block
         * @memberof asod_recorder_project.AsodRecorderProjectAudioBlock
         * @instance
         */
        AsodRecorderProjectAudioBlock.prototype.block = null;

        /**
         * Creates a new AsodRecorderProjectAudioBlock instance using the specified properties.
         * @function create
         * @memberof asod_recorder_project.AsodRecorderProjectAudioBlock
         * @static
         * @param {asod_recorder_project.IAsodRecorderProjectAudioBlock=} [properties] Properties to set
         * @returns {asod_recorder_project.AsodRecorderProjectAudioBlock} AsodRecorderProjectAudioBlock instance
         */
        AsodRecorderProjectAudioBlock.create = function create(properties) {
            return new AsodRecorderProjectAudioBlock(properties);
        };

        /**
         * Encodes the specified AsodRecorderProjectAudioBlock message. Does not implicitly {@link asod_recorder_project.AsodRecorderProjectAudioBlock.verify|verify} messages.
         * @function encode
         * @memberof asod_recorder_project.AsodRecorderProjectAudioBlock
         * @static
         * @param {asod_recorder_project.IAsodRecorderProjectAudioBlock} message AsodRecorderProjectAudioBlock message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodRecorderProjectAudioBlock.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.projectId != null && Object.hasOwnProperty.call(message, "projectId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.projectId);
            if (message.block != null && Object.hasOwnProperty.call(message, "block"))
                $root.asod_project.AsodProjectAudioBlock.encode(message.block, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified AsodRecorderProjectAudioBlock message, length delimited. Does not implicitly {@link asod_recorder_project.AsodRecorderProjectAudioBlock.verify|verify} messages.
         * @function encodeDelimited
         * @memberof asod_recorder_project.AsodRecorderProjectAudioBlock
         * @static
         * @param {asod_recorder_project.IAsodRecorderProjectAudioBlock} message AsodRecorderProjectAudioBlock message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodRecorderProjectAudioBlock.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AsodRecorderProjectAudioBlock message from the specified reader or buffer.
         * @function decode
         * @memberof asod_recorder_project.AsodRecorderProjectAudioBlock
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {asod_recorder_project.AsodRecorderProjectAudioBlock} AsodRecorderProjectAudioBlock
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodRecorderProjectAudioBlock.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.asod_recorder_project.AsodRecorderProjectAudioBlock();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.projectId = reader.string();
                        break;
                    }
                case 2: {
                        message.block = $root.asod_project.AsodProjectAudioBlock.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AsodRecorderProjectAudioBlock message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof asod_recorder_project.AsodRecorderProjectAudioBlock
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {asod_recorder_project.AsodRecorderProjectAudioBlock} AsodRecorderProjectAudioBlock
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodRecorderProjectAudioBlock.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AsodRecorderProjectAudioBlock message.
         * @function verify
         * @memberof asod_recorder_project.AsodRecorderProjectAudioBlock
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AsodRecorderProjectAudioBlock.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.projectId != null && message.hasOwnProperty("projectId"))
                if (!$util.isString(message.projectId))
                    return "projectId: string expected";
            if (message.block != null && message.hasOwnProperty("block")) {
                let error = $root.asod_project.AsodProjectAudioBlock.verify(message.block);
                if (error)
                    return "block." + error;
            }
            return null;
        };

        /**
         * Creates an AsodRecorderProjectAudioBlock message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof asod_recorder_project.AsodRecorderProjectAudioBlock
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {asod_recorder_project.AsodRecorderProjectAudioBlock} AsodRecorderProjectAudioBlock
         */
        AsodRecorderProjectAudioBlock.fromObject = function fromObject(object) {
            if (object instanceof $root.asod_recorder_project.AsodRecorderProjectAudioBlock)
                return object;
            let message = new $root.asod_recorder_project.AsodRecorderProjectAudioBlock();
            if (object.projectId != null)
                message.projectId = String(object.projectId);
            if (object.block != null) {
                if (typeof object.block !== "object")
                    throw TypeError(".asod_recorder_project.AsodRecorderProjectAudioBlock.block: object expected");
                message.block = $root.asod_project.AsodProjectAudioBlock.fromObject(object.block);
            }
            return message;
        };

        /**
         * Creates a plain object from an AsodRecorderProjectAudioBlock message. Also converts values to other types if specified.
         * @function toObject
         * @memberof asod_recorder_project.AsodRecorderProjectAudioBlock
         * @static
         * @param {asod_recorder_project.AsodRecorderProjectAudioBlock} message AsodRecorderProjectAudioBlock
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AsodRecorderProjectAudioBlock.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.projectId = "";
                object.block = null;
            }
            if (message.projectId != null && message.hasOwnProperty("projectId"))
                object.projectId = message.projectId;
            if (message.block != null && message.hasOwnProperty("block"))
                object.block = $root.asod_project.AsodProjectAudioBlock.toObject(message.block, options);
            return object;
        };

        /**
         * Converts this AsodRecorderProjectAudioBlock to JSON.
         * @function toJSON
         * @memberof asod_recorder_project.AsodRecorderProjectAudioBlock
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AsodRecorderProjectAudioBlock.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AsodRecorderProjectAudioBlock
         * @function getTypeUrl
         * @memberof asod_recorder_project.AsodRecorderProjectAudioBlock
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AsodRecorderProjectAudioBlock.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/asod_recorder_project.AsodRecorderProjectAudioBlock";
        };

        return AsodRecorderProjectAudioBlock;
    })();

    asod_recorder_project.AsodRecorderProjectAudio = (function() {

        /**
         * Properties of an AsodRecorderProjectAudio.
         * @memberof asod_recorder_project
         * @interface IAsodRecorderProjectAudio
         * @property {string|null} [projectId] AsodRecorderProjectAudio projectId
         * @property {string|null} [audioBlockId] AsodRecorderProjectAudio audioBlockId
         * @property {asod_audio.IAsodAudio|null} [audio] AsodRecorderProjectAudio audio
         */

        /**
         * Constructs a new AsodRecorderProjectAudio.
         * @memberof asod_recorder_project
         * @classdesc Represents an AsodRecorderProjectAudio.
         * @implements IAsodRecorderProjectAudio
         * @constructor
         * @param {asod_recorder_project.IAsodRecorderProjectAudio=} [properties] Properties to set
         */
        function AsodRecorderProjectAudio(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AsodRecorderProjectAudio projectId.
         * @member {string} projectId
         * @memberof asod_recorder_project.AsodRecorderProjectAudio
         * @instance
         */
        AsodRecorderProjectAudio.prototype.projectId = "";

        /**
         * AsodRecorderProjectAudio audioBlockId.
         * @member {string} audioBlockId
         * @memberof asod_recorder_project.AsodRecorderProjectAudio
         * @instance
         */
        AsodRecorderProjectAudio.prototype.audioBlockId = "";

        /**
         * AsodRecorderProjectAudio audio.
         * @member {asod_audio.IAsodAudio|null|undefined} audio
         * @memberof asod_recorder_project.AsodRecorderProjectAudio
         * @instance
         */
        AsodRecorderProjectAudio.prototype.audio = null;

        /**
         * Creates a new AsodRecorderProjectAudio instance using the specified properties.
         * @function create
         * @memberof asod_recorder_project.AsodRecorderProjectAudio
         * @static
         * @param {asod_recorder_project.IAsodRecorderProjectAudio=} [properties] Properties to set
         * @returns {asod_recorder_project.AsodRecorderProjectAudio} AsodRecorderProjectAudio instance
         */
        AsodRecorderProjectAudio.create = function create(properties) {
            return new AsodRecorderProjectAudio(properties);
        };

        /**
         * Encodes the specified AsodRecorderProjectAudio message. Does not implicitly {@link asod_recorder_project.AsodRecorderProjectAudio.verify|verify} messages.
         * @function encode
         * @memberof asod_recorder_project.AsodRecorderProjectAudio
         * @static
         * @param {asod_recorder_project.IAsodRecorderProjectAudio} message AsodRecorderProjectAudio message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodRecorderProjectAudio.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.projectId != null && Object.hasOwnProperty.call(message, "projectId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.projectId);
            if (message.audioBlockId != null && Object.hasOwnProperty.call(message, "audioBlockId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.audioBlockId);
            if (message.audio != null && Object.hasOwnProperty.call(message, "audio"))
                $root.asod_audio.AsodAudio.encode(message.audio, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified AsodRecorderProjectAudio message, length delimited. Does not implicitly {@link asod_recorder_project.AsodRecorderProjectAudio.verify|verify} messages.
         * @function encodeDelimited
         * @memberof asod_recorder_project.AsodRecorderProjectAudio
         * @static
         * @param {asod_recorder_project.IAsodRecorderProjectAudio} message AsodRecorderProjectAudio message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodRecorderProjectAudio.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AsodRecorderProjectAudio message from the specified reader or buffer.
         * @function decode
         * @memberof asod_recorder_project.AsodRecorderProjectAudio
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {asod_recorder_project.AsodRecorderProjectAudio} AsodRecorderProjectAudio
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodRecorderProjectAudio.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.asod_recorder_project.AsodRecorderProjectAudio();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.projectId = reader.string();
                        break;
                    }
                case 2: {
                        message.audioBlockId = reader.string();
                        break;
                    }
                case 3: {
                        message.audio = $root.asod_audio.AsodAudio.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AsodRecorderProjectAudio message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof asod_recorder_project.AsodRecorderProjectAudio
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {asod_recorder_project.AsodRecorderProjectAudio} AsodRecorderProjectAudio
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodRecorderProjectAudio.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AsodRecorderProjectAudio message.
         * @function verify
         * @memberof asod_recorder_project.AsodRecorderProjectAudio
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AsodRecorderProjectAudio.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.projectId != null && message.hasOwnProperty("projectId"))
                if (!$util.isString(message.projectId))
                    return "projectId: string expected";
            if (message.audioBlockId != null && message.hasOwnProperty("audioBlockId"))
                if (!$util.isString(message.audioBlockId))
                    return "audioBlockId: string expected";
            if (message.audio != null && message.hasOwnProperty("audio")) {
                let error = $root.asod_audio.AsodAudio.verify(message.audio);
                if (error)
                    return "audio." + error;
            }
            return null;
        };

        /**
         * Creates an AsodRecorderProjectAudio message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof asod_recorder_project.AsodRecorderProjectAudio
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {asod_recorder_project.AsodRecorderProjectAudio} AsodRecorderProjectAudio
         */
        AsodRecorderProjectAudio.fromObject = function fromObject(object) {
            if (object instanceof $root.asod_recorder_project.AsodRecorderProjectAudio)
                return object;
            let message = new $root.asod_recorder_project.AsodRecorderProjectAudio();
            if (object.projectId != null)
                message.projectId = String(object.projectId);
            if (object.audioBlockId != null)
                message.audioBlockId = String(object.audioBlockId);
            if (object.audio != null) {
                if (typeof object.audio !== "object")
                    throw TypeError(".asod_recorder_project.AsodRecorderProjectAudio.audio: object expected");
                message.audio = $root.asod_audio.AsodAudio.fromObject(object.audio);
            }
            return message;
        };

        /**
         * Creates a plain object from an AsodRecorderProjectAudio message. Also converts values to other types if specified.
         * @function toObject
         * @memberof asod_recorder_project.AsodRecorderProjectAudio
         * @static
         * @param {asod_recorder_project.AsodRecorderProjectAudio} message AsodRecorderProjectAudio
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AsodRecorderProjectAudio.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.projectId = "";
                object.audioBlockId = "";
                object.audio = null;
            }
            if (message.projectId != null && message.hasOwnProperty("projectId"))
                object.projectId = message.projectId;
            if (message.audioBlockId != null && message.hasOwnProperty("audioBlockId"))
                object.audioBlockId = message.audioBlockId;
            if (message.audio != null && message.hasOwnProperty("audio"))
                object.audio = $root.asod_audio.AsodAudio.toObject(message.audio, options);
            return object;
        };

        /**
         * Converts this AsodRecorderProjectAudio to JSON.
         * @function toJSON
         * @memberof asod_recorder_project.AsodRecorderProjectAudio
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AsodRecorderProjectAudio.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AsodRecorderProjectAudio
         * @function getTypeUrl
         * @memberof asod_recorder_project.AsodRecorderProjectAudio
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AsodRecorderProjectAudio.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/asod_recorder_project.AsodRecorderProjectAudio";
        };

        return AsodRecorderProjectAudio;
    })();

    asod_recorder_project.AsodRecorderProjectAudioChunk = (function() {

        /**
         * Properties of an AsodRecorderProjectAudioChunk.
         * @memberof asod_recorder_project
         * @interface IAsodRecorderProjectAudioChunk
         * @property {string|null} [projectId] AsodRecorderProjectAudioChunk projectId
         * @property {string|null} [audioBlockId] AsodRecorderProjectAudioChunk audioBlockId
         * @property {asod_audio.IAsodAudioChunk|null} [audioChunk] AsodRecorderProjectAudioChunk audioChunk
         */

        /**
         * Constructs a new AsodRecorderProjectAudioChunk.
         * @memberof asod_recorder_project
         * @classdesc Represents an AsodRecorderProjectAudioChunk.
         * @implements IAsodRecorderProjectAudioChunk
         * @constructor
         * @param {asod_recorder_project.IAsodRecorderProjectAudioChunk=} [properties] Properties to set
         */
        function AsodRecorderProjectAudioChunk(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AsodRecorderProjectAudioChunk projectId.
         * @member {string} projectId
         * @memberof asod_recorder_project.AsodRecorderProjectAudioChunk
         * @instance
         */
        AsodRecorderProjectAudioChunk.prototype.projectId = "";

        /**
         * AsodRecorderProjectAudioChunk audioBlockId.
         * @member {string} audioBlockId
         * @memberof asod_recorder_project.AsodRecorderProjectAudioChunk
         * @instance
         */
        AsodRecorderProjectAudioChunk.prototype.audioBlockId = "";

        /**
         * AsodRecorderProjectAudioChunk audioChunk.
         * @member {asod_audio.IAsodAudioChunk|null|undefined} audioChunk
         * @memberof asod_recorder_project.AsodRecorderProjectAudioChunk
         * @instance
         */
        AsodRecorderProjectAudioChunk.prototype.audioChunk = null;

        /**
         * Creates a new AsodRecorderProjectAudioChunk instance using the specified properties.
         * @function create
         * @memberof asod_recorder_project.AsodRecorderProjectAudioChunk
         * @static
         * @param {asod_recorder_project.IAsodRecorderProjectAudioChunk=} [properties] Properties to set
         * @returns {asod_recorder_project.AsodRecorderProjectAudioChunk} AsodRecorderProjectAudioChunk instance
         */
        AsodRecorderProjectAudioChunk.create = function create(properties) {
            return new AsodRecorderProjectAudioChunk(properties);
        };

        /**
         * Encodes the specified AsodRecorderProjectAudioChunk message. Does not implicitly {@link asod_recorder_project.AsodRecorderProjectAudioChunk.verify|verify} messages.
         * @function encode
         * @memberof asod_recorder_project.AsodRecorderProjectAudioChunk
         * @static
         * @param {asod_recorder_project.IAsodRecorderProjectAudioChunk} message AsodRecorderProjectAudioChunk message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodRecorderProjectAudioChunk.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.projectId != null && Object.hasOwnProperty.call(message, "projectId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.projectId);
            if (message.audioBlockId != null && Object.hasOwnProperty.call(message, "audioBlockId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.audioBlockId);
            if (message.audioChunk != null && Object.hasOwnProperty.call(message, "audioChunk"))
                $root.asod_audio.AsodAudioChunk.encode(message.audioChunk, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified AsodRecorderProjectAudioChunk message, length delimited. Does not implicitly {@link asod_recorder_project.AsodRecorderProjectAudioChunk.verify|verify} messages.
         * @function encodeDelimited
         * @memberof asod_recorder_project.AsodRecorderProjectAudioChunk
         * @static
         * @param {asod_recorder_project.IAsodRecorderProjectAudioChunk} message AsodRecorderProjectAudioChunk message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsodRecorderProjectAudioChunk.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AsodRecorderProjectAudioChunk message from the specified reader or buffer.
         * @function decode
         * @memberof asod_recorder_project.AsodRecorderProjectAudioChunk
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {asod_recorder_project.AsodRecorderProjectAudioChunk} AsodRecorderProjectAudioChunk
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodRecorderProjectAudioChunk.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.asod_recorder_project.AsodRecorderProjectAudioChunk();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.projectId = reader.string();
                        break;
                    }
                case 2: {
                        message.audioBlockId = reader.string();
                        break;
                    }
                case 3: {
                        message.audioChunk = $root.asod_audio.AsodAudioChunk.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AsodRecorderProjectAudioChunk message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof asod_recorder_project.AsodRecorderProjectAudioChunk
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {asod_recorder_project.AsodRecorderProjectAudioChunk} AsodRecorderProjectAudioChunk
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsodRecorderProjectAudioChunk.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AsodRecorderProjectAudioChunk message.
         * @function verify
         * @memberof asod_recorder_project.AsodRecorderProjectAudioChunk
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AsodRecorderProjectAudioChunk.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.projectId != null && message.hasOwnProperty("projectId"))
                if (!$util.isString(message.projectId))
                    return "projectId: string expected";
            if (message.audioBlockId != null && message.hasOwnProperty("audioBlockId"))
                if (!$util.isString(message.audioBlockId))
                    return "audioBlockId: string expected";
            if (message.audioChunk != null && message.hasOwnProperty("audioChunk")) {
                let error = $root.asod_audio.AsodAudioChunk.verify(message.audioChunk);
                if (error)
                    return "audioChunk." + error;
            }
            return null;
        };

        /**
         * Creates an AsodRecorderProjectAudioChunk message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof asod_recorder_project.AsodRecorderProjectAudioChunk
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {asod_recorder_project.AsodRecorderProjectAudioChunk} AsodRecorderProjectAudioChunk
         */
        AsodRecorderProjectAudioChunk.fromObject = function fromObject(object) {
            if (object instanceof $root.asod_recorder_project.AsodRecorderProjectAudioChunk)
                return object;
            let message = new $root.asod_recorder_project.AsodRecorderProjectAudioChunk();
            if (object.projectId != null)
                message.projectId = String(object.projectId);
            if (object.audioBlockId != null)
                message.audioBlockId = String(object.audioBlockId);
            if (object.audioChunk != null) {
                if (typeof object.audioChunk !== "object")
                    throw TypeError(".asod_recorder_project.AsodRecorderProjectAudioChunk.audioChunk: object expected");
                message.audioChunk = $root.asod_audio.AsodAudioChunk.fromObject(object.audioChunk);
            }
            return message;
        };

        /**
         * Creates a plain object from an AsodRecorderProjectAudioChunk message. Also converts values to other types if specified.
         * @function toObject
         * @memberof asod_recorder_project.AsodRecorderProjectAudioChunk
         * @static
         * @param {asod_recorder_project.AsodRecorderProjectAudioChunk} message AsodRecorderProjectAudioChunk
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AsodRecorderProjectAudioChunk.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.projectId = "";
                object.audioBlockId = "";
                object.audioChunk = null;
            }
            if (message.projectId != null && message.hasOwnProperty("projectId"))
                object.projectId = message.projectId;
            if (message.audioBlockId != null && message.hasOwnProperty("audioBlockId"))
                object.audioBlockId = message.audioBlockId;
            if (message.audioChunk != null && message.hasOwnProperty("audioChunk"))
                object.audioChunk = $root.asod_audio.AsodAudioChunk.toObject(message.audioChunk, options);
            return object;
        };

        /**
         * Converts this AsodRecorderProjectAudioChunk to JSON.
         * @function toJSON
         * @memberof asod_recorder_project.AsodRecorderProjectAudioChunk
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AsodRecorderProjectAudioChunk.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AsodRecorderProjectAudioChunk
         * @function getTypeUrl
         * @memberof asod_recorder_project.AsodRecorderProjectAudioChunk
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AsodRecorderProjectAudioChunk.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/asod_recorder_project.AsodRecorderProjectAudioChunk";
        };

        return AsodRecorderProjectAudioChunk;
    })();

    return asod_recorder_project;
})();

export const api_udelezenci = $root.api_udelezenci = (() => {

    /**
     * Namespace api_udelezenci.
     * @exports api_udelezenci
     * @namespace
     */
    const api_udelezenci = {};

    api_udelezenci.ApiUdelezenciAddParticipant = (function() {

        /**
         * Properties of an ApiUdelezenciAddParticipant.
         * @memberof api_udelezenci
         * @interface IApiUdelezenciAddParticipant
         * @property {string|null} [participationId] ApiUdelezenciAddParticipant participationId
         * @property {string|null} [sessionId] ApiUdelezenciAddParticipant sessionId
         * @property {string|null} [participantName] ApiUdelezenciAddParticipant participantName
         * @property {string|null} [participantTaxId] ApiUdelezenciAddParticipant participantTaxId
         * @property {string|null} [roleId] ApiUdelezenciAddParticipant roleId
         * @property {boolean|null} [grantPublicAccess] ApiUdelezenciAddParticipant grantPublicAccess
         * @property {number|null} [channelNumber] ApiUdelezenciAddParticipant channelNumber
         */

        /**
         * Constructs a new ApiUdelezenciAddParticipant.
         * @memberof api_udelezenci
         * @classdesc Represents an ApiUdelezenciAddParticipant.
         * @implements IApiUdelezenciAddParticipant
         * @constructor
         * @param {api_udelezenci.IApiUdelezenciAddParticipant=} [properties] Properties to set
         */
        function ApiUdelezenciAddParticipant(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiUdelezenciAddParticipant participationId.
         * @member {string} participationId
         * @memberof api_udelezenci.ApiUdelezenciAddParticipant
         * @instance
         */
        ApiUdelezenciAddParticipant.prototype.participationId = "";

        /**
         * ApiUdelezenciAddParticipant sessionId.
         * @member {string} sessionId
         * @memberof api_udelezenci.ApiUdelezenciAddParticipant
         * @instance
         */
        ApiUdelezenciAddParticipant.prototype.sessionId = "";

        /**
         * ApiUdelezenciAddParticipant participantName.
         * @member {string} participantName
         * @memberof api_udelezenci.ApiUdelezenciAddParticipant
         * @instance
         */
        ApiUdelezenciAddParticipant.prototype.participantName = "";

        /**
         * ApiUdelezenciAddParticipant participantTaxId.
         * @member {string} participantTaxId
         * @memberof api_udelezenci.ApiUdelezenciAddParticipant
         * @instance
         */
        ApiUdelezenciAddParticipant.prototype.participantTaxId = "";

        /**
         * ApiUdelezenciAddParticipant roleId.
         * @member {string} roleId
         * @memberof api_udelezenci.ApiUdelezenciAddParticipant
         * @instance
         */
        ApiUdelezenciAddParticipant.prototype.roleId = "";

        /**
         * ApiUdelezenciAddParticipant grantPublicAccess.
         * @member {boolean} grantPublicAccess
         * @memberof api_udelezenci.ApiUdelezenciAddParticipant
         * @instance
         */
        ApiUdelezenciAddParticipant.prototype.grantPublicAccess = false;

        /**
         * ApiUdelezenciAddParticipant channelNumber.
         * @member {number} channelNumber
         * @memberof api_udelezenci.ApiUdelezenciAddParticipant
         * @instance
         */
        ApiUdelezenciAddParticipant.prototype.channelNumber = 0;

        /**
         * Creates a new ApiUdelezenciAddParticipant instance using the specified properties.
         * @function create
         * @memberof api_udelezenci.ApiUdelezenciAddParticipant
         * @static
         * @param {api_udelezenci.IApiUdelezenciAddParticipant=} [properties] Properties to set
         * @returns {api_udelezenci.ApiUdelezenciAddParticipant} ApiUdelezenciAddParticipant instance
         */
        ApiUdelezenciAddParticipant.create = function create(properties) {
            return new ApiUdelezenciAddParticipant(properties);
        };

        /**
         * Encodes the specified ApiUdelezenciAddParticipant message. Does not implicitly {@link api_udelezenci.ApiUdelezenciAddParticipant.verify|verify} messages.
         * @function encode
         * @memberof api_udelezenci.ApiUdelezenciAddParticipant
         * @static
         * @param {api_udelezenci.IApiUdelezenciAddParticipant} message ApiUdelezenciAddParticipant message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiUdelezenciAddParticipant.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.participationId != null && Object.hasOwnProperty.call(message, "participationId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.participationId);
            if (message.sessionId != null && Object.hasOwnProperty.call(message, "sessionId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.sessionId);
            if (message.participantName != null && Object.hasOwnProperty.call(message, "participantName"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.participantName);
            if (message.participantTaxId != null && Object.hasOwnProperty.call(message, "participantTaxId"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.participantTaxId);
            if (message.roleId != null && Object.hasOwnProperty.call(message, "roleId"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.roleId);
            if (message.grantPublicAccess != null && Object.hasOwnProperty.call(message, "grantPublicAccess"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.grantPublicAccess);
            if (message.channelNumber != null && Object.hasOwnProperty.call(message, "channelNumber"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.channelNumber);
            return writer;
        };

        /**
         * Encodes the specified ApiUdelezenciAddParticipant message, length delimited. Does not implicitly {@link api_udelezenci.ApiUdelezenciAddParticipant.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_udelezenci.ApiUdelezenciAddParticipant
         * @static
         * @param {api_udelezenci.IApiUdelezenciAddParticipant} message ApiUdelezenciAddParticipant message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiUdelezenciAddParticipant.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiUdelezenciAddParticipant message from the specified reader or buffer.
         * @function decode
         * @memberof api_udelezenci.ApiUdelezenciAddParticipant
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_udelezenci.ApiUdelezenciAddParticipant} ApiUdelezenciAddParticipant
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiUdelezenciAddParticipant.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_udelezenci.ApiUdelezenciAddParticipant();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.participationId = reader.string();
                        break;
                    }
                case 2: {
                        message.sessionId = reader.string();
                        break;
                    }
                case 3: {
                        message.participantName = reader.string();
                        break;
                    }
                case 4: {
                        message.participantTaxId = reader.string();
                        break;
                    }
                case 5: {
                        message.roleId = reader.string();
                        break;
                    }
                case 6: {
                        message.grantPublicAccess = reader.bool();
                        break;
                    }
                case 7: {
                        message.channelNumber = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiUdelezenciAddParticipant message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_udelezenci.ApiUdelezenciAddParticipant
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_udelezenci.ApiUdelezenciAddParticipant} ApiUdelezenciAddParticipant
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiUdelezenciAddParticipant.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiUdelezenciAddParticipant message.
         * @function verify
         * @memberof api_udelezenci.ApiUdelezenciAddParticipant
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiUdelezenciAddParticipant.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.participationId != null && message.hasOwnProperty("participationId"))
                if (!$util.isString(message.participationId))
                    return "participationId: string expected";
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                if (!$util.isString(message.sessionId))
                    return "sessionId: string expected";
            if (message.participantName != null && message.hasOwnProperty("participantName"))
                if (!$util.isString(message.participantName))
                    return "participantName: string expected";
            if (message.participantTaxId != null && message.hasOwnProperty("participantTaxId"))
                if (!$util.isString(message.participantTaxId))
                    return "participantTaxId: string expected";
            if (message.roleId != null && message.hasOwnProperty("roleId"))
                if (!$util.isString(message.roleId))
                    return "roleId: string expected";
            if (message.grantPublicAccess != null && message.hasOwnProperty("grantPublicAccess"))
                if (typeof message.grantPublicAccess !== "boolean")
                    return "grantPublicAccess: boolean expected";
            if (message.channelNumber != null && message.hasOwnProperty("channelNumber"))
                if (!$util.isInteger(message.channelNumber))
                    return "channelNumber: integer expected";
            return null;
        };

        /**
         * Creates an ApiUdelezenciAddParticipant message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_udelezenci.ApiUdelezenciAddParticipant
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_udelezenci.ApiUdelezenciAddParticipant} ApiUdelezenciAddParticipant
         */
        ApiUdelezenciAddParticipant.fromObject = function fromObject(object) {
            if (object instanceof $root.api_udelezenci.ApiUdelezenciAddParticipant)
                return object;
            let message = new $root.api_udelezenci.ApiUdelezenciAddParticipant();
            if (object.participationId != null)
                message.participationId = String(object.participationId);
            if (object.sessionId != null)
                message.sessionId = String(object.sessionId);
            if (object.participantName != null)
                message.participantName = String(object.participantName);
            if (object.participantTaxId != null)
                message.participantTaxId = String(object.participantTaxId);
            if (object.roleId != null)
                message.roleId = String(object.roleId);
            if (object.grantPublicAccess != null)
                message.grantPublicAccess = Boolean(object.grantPublicAccess);
            if (object.channelNumber != null)
                message.channelNumber = object.channelNumber | 0;
            return message;
        };

        /**
         * Creates a plain object from an ApiUdelezenciAddParticipant message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_udelezenci.ApiUdelezenciAddParticipant
         * @static
         * @param {api_udelezenci.ApiUdelezenciAddParticipant} message ApiUdelezenciAddParticipant
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiUdelezenciAddParticipant.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.participationId = "";
                object.sessionId = "";
                object.participantName = "";
                object.participantTaxId = "";
                object.roleId = "";
                object.grantPublicAccess = false;
                object.channelNumber = 0;
            }
            if (message.participationId != null && message.hasOwnProperty("participationId"))
                object.participationId = message.participationId;
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                object.sessionId = message.sessionId;
            if (message.participantName != null && message.hasOwnProperty("participantName"))
                object.participantName = message.participantName;
            if (message.participantTaxId != null && message.hasOwnProperty("participantTaxId"))
                object.participantTaxId = message.participantTaxId;
            if (message.roleId != null && message.hasOwnProperty("roleId"))
                object.roleId = message.roleId;
            if (message.grantPublicAccess != null && message.hasOwnProperty("grantPublicAccess"))
                object.grantPublicAccess = message.grantPublicAccess;
            if (message.channelNumber != null && message.hasOwnProperty("channelNumber"))
                object.channelNumber = message.channelNumber;
            return object;
        };

        /**
         * Converts this ApiUdelezenciAddParticipant to JSON.
         * @function toJSON
         * @memberof api_udelezenci.ApiUdelezenciAddParticipant
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiUdelezenciAddParticipant.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiUdelezenciAddParticipant
         * @function getTypeUrl
         * @memberof api_udelezenci.ApiUdelezenciAddParticipant
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiUdelezenciAddParticipant.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_udelezenci.ApiUdelezenciAddParticipant";
        };

        return ApiUdelezenciAddParticipant;
    })();

    api_udelezenci.ApiUdelezenciUpdateParticipant = (function() {

        /**
         * Properties of an ApiUdelezenciUpdateParticipant.
         * @memberof api_udelezenci
         * @interface IApiUdelezenciUpdateParticipant
         * @property {string|null} [participationId] ApiUdelezenciUpdateParticipant participationId
         * @property {string|null} [participantName] ApiUdelezenciUpdateParticipant participantName
         * @property {string|null} [roleId] ApiUdelezenciUpdateParticipant roleId
         * @property {boolean|null} [grantPublicAccess] ApiUdelezenciUpdateParticipant grantPublicAccess
         * @property {number|null} [channelNumber] ApiUdelezenciUpdateParticipant channelNumber
         */

        /**
         * Constructs a new ApiUdelezenciUpdateParticipant.
         * @memberof api_udelezenci
         * @classdesc Represents an ApiUdelezenciUpdateParticipant.
         * @implements IApiUdelezenciUpdateParticipant
         * @constructor
         * @param {api_udelezenci.IApiUdelezenciUpdateParticipant=} [properties] Properties to set
         */
        function ApiUdelezenciUpdateParticipant(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiUdelezenciUpdateParticipant participationId.
         * @member {string} participationId
         * @memberof api_udelezenci.ApiUdelezenciUpdateParticipant
         * @instance
         */
        ApiUdelezenciUpdateParticipant.prototype.participationId = "";

        /**
         * ApiUdelezenciUpdateParticipant participantName.
         * @member {string} participantName
         * @memberof api_udelezenci.ApiUdelezenciUpdateParticipant
         * @instance
         */
        ApiUdelezenciUpdateParticipant.prototype.participantName = "";

        /**
         * ApiUdelezenciUpdateParticipant roleId.
         * @member {string} roleId
         * @memberof api_udelezenci.ApiUdelezenciUpdateParticipant
         * @instance
         */
        ApiUdelezenciUpdateParticipant.prototype.roleId = "";

        /**
         * ApiUdelezenciUpdateParticipant grantPublicAccess.
         * @member {boolean} grantPublicAccess
         * @memberof api_udelezenci.ApiUdelezenciUpdateParticipant
         * @instance
         */
        ApiUdelezenciUpdateParticipant.prototype.grantPublicAccess = false;

        /**
         * ApiUdelezenciUpdateParticipant channelNumber.
         * @member {number} channelNumber
         * @memberof api_udelezenci.ApiUdelezenciUpdateParticipant
         * @instance
         */
        ApiUdelezenciUpdateParticipant.prototype.channelNumber = 0;

        /**
         * Creates a new ApiUdelezenciUpdateParticipant instance using the specified properties.
         * @function create
         * @memberof api_udelezenci.ApiUdelezenciUpdateParticipant
         * @static
         * @param {api_udelezenci.IApiUdelezenciUpdateParticipant=} [properties] Properties to set
         * @returns {api_udelezenci.ApiUdelezenciUpdateParticipant} ApiUdelezenciUpdateParticipant instance
         */
        ApiUdelezenciUpdateParticipant.create = function create(properties) {
            return new ApiUdelezenciUpdateParticipant(properties);
        };

        /**
         * Encodes the specified ApiUdelezenciUpdateParticipant message. Does not implicitly {@link api_udelezenci.ApiUdelezenciUpdateParticipant.verify|verify} messages.
         * @function encode
         * @memberof api_udelezenci.ApiUdelezenciUpdateParticipant
         * @static
         * @param {api_udelezenci.IApiUdelezenciUpdateParticipant} message ApiUdelezenciUpdateParticipant message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiUdelezenciUpdateParticipant.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.participationId != null && Object.hasOwnProperty.call(message, "participationId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.participationId);
            if (message.participantName != null && Object.hasOwnProperty.call(message, "participantName"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.participantName);
            if (message.roleId != null && Object.hasOwnProperty.call(message, "roleId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.roleId);
            if (message.grantPublicAccess != null && Object.hasOwnProperty.call(message, "grantPublicAccess"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.grantPublicAccess);
            if (message.channelNumber != null && Object.hasOwnProperty.call(message, "channelNumber"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.channelNumber);
            return writer;
        };

        /**
         * Encodes the specified ApiUdelezenciUpdateParticipant message, length delimited. Does not implicitly {@link api_udelezenci.ApiUdelezenciUpdateParticipant.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_udelezenci.ApiUdelezenciUpdateParticipant
         * @static
         * @param {api_udelezenci.IApiUdelezenciUpdateParticipant} message ApiUdelezenciUpdateParticipant message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiUdelezenciUpdateParticipant.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiUdelezenciUpdateParticipant message from the specified reader or buffer.
         * @function decode
         * @memberof api_udelezenci.ApiUdelezenciUpdateParticipant
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_udelezenci.ApiUdelezenciUpdateParticipant} ApiUdelezenciUpdateParticipant
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiUdelezenciUpdateParticipant.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_udelezenci.ApiUdelezenciUpdateParticipant();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.participationId = reader.string();
                        break;
                    }
                case 2: {
                        message.participantName = reader.string();
                        break;
                    }
                case 3: {
                        message.roleId = reader.string();
                        break;
                    }
                case 4: {
                        message.grantPublicAccess = reader.bool();
                        break;
                    }
                case 5: {
                        message.channelNumber = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiUdelezenciUpdateParticipant message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_udelezenci.ApiUdelezenciUpdateParticipant
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_udelezenci.ApiUdelezenciUpdateParticipant} ApiUdelezenciUpdateParticipant
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiUdelezenciUpdateParticipant.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiUdelezenciUpdateParticipant message.
         * @function verify
         * @memberof api_udelezenci.ApiUdelezenciUpdateParticipant
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiUdelezenciUpdateParticipant.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.participationId != null && message.hasOwnProperty("participationId"))
                if (!$util.isString(message.participationId))
                    return "participationId: string expected";
            if (message.participantName != null && message.hasOwnProperty("participantName"))
                if (!$util.isString(message.participantName))
                    return "participantName: string expected";
            if (message.roleId != null && message.hasOwnProperty("roleId"))
                if (!$util.isString(message.roleId))
                    return "roleId: string expected";
            if (message.grantPublicAccess != null && message.hasOwnProperty("grantPublicAccess"))
                if (typeof message.grantPublicAccess !== "boolean")
                    return "grantPublicAccess: boolean expected";
            if (message.channelNumber != null && message.hasOwnProperty("channelNumber"))
                if (!$util.isInteger(message.channelNumber))
                    return "channelNumber: integer expected";
            return null;
        };

        /**
         * Creates an ApiUdelezenciUpdateParticipant message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_udelezenci.ApiUdelezenciUpdateParticipant
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_udelezenci.ApiUdelezenciUpdateParticipant} ApiUdelezenciUpdateParticipant
         */
        ApiUdelezenciUpdateParticipant.fromObject = function fromObject(object) {
            if (object instanceof $root.api_udelezenci.ApiUdelezenciUpdateParticipant)
                return object;
            let message = new $root.api_udelezenci.ApiUdelezenciUpdateParticipant();
            if (object.participationId != null)
                message.participationId = String(object.participationId);
            if (object.participantName != null)
                message.participantName = String(object.participantName);
            if (object.roleId != null)
                message.roleId = String(object.roleId);
            if (object.grantPublicAccess != null)
                message.grantPublicAccess = Boolean(object.grantPublicAccess);
            if (object.channelNumber != null)
                message.channelNumber = object.channelNumber | 0;
            return message;
        };

        /**
         * Creates a plain object from an ApiUdelezenciUpdateParticipant message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_udelezenci.ApiUdelezenciUpdateParticipant
         * @static
         * @param {api_udelezenci.ApiUdelezenciUpdateParticipant} message ApiUdelezenciUpdateParticipant
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiUdelezenciUpdateParticipant.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.participationId = "";
                object.participantName = "";
                object.roleId = "";
                object.grantPublicAccess = false;
                object.channelNumber = 0;
            }
            if (message.participationId != null && message.hasOwnProperty("participationId"))
                object.participationId = message.participationId;
            if (message.participantName != null && message.hasOwnProperty("participantName"))
                object.participantName = message.participantName;
            if (message.roleId != null && message.hasOwnProperty("roleId"))
                object.roleId = message.roleId;
            if (message.grantPublicAccess != null && message.hasOwnProperty("grantPublicAccess"))
                object.grantPublicAccess = message.grantPublicAccess;
            if (message.channelNumber != null && message.hasOwnProperty("channelNumber"))
                object.channelNumber = message.channelNumber;
            return object;
        };

        /**
         * Converts this ApiUdelezenciUpdateParticipant to JSON.
         * @function toJSON
         * @memberof api_udelezenci.ApiUdelezenciUpdateParticipant
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiUdelezenciUpdateParticipant.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiUdelezenciUpdateParticipant
         * @function getTypeUrl
         * @memberof api_udelezenci.ApiUdelezenciUpdateParticipant
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiUdelezenciUpdateParticipant.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_udelezenci.ApiUdelezenciUpdateParticipant";
        };

        return ApiUdelezenciUpdateParticipant;
    })();

    api_udelezenci.ApiUdelezenciSetParticipantTaxId = (function() {

        /**
         * Properties of an ApiUdelezenciSetParticipantTaxId.
         * @memberof api_udelezenci
         * @interface IApiUdelezenciSetParticipantTaxId
         * @property {string|null} [participationId] ApiUdelezenciSetParticipantTaxId participationId
         * @property {string|null} [taxId] ApiUdelezenciSetParticipantTaxId taxId
         */

        /**
         * Constructs a new ApiUdelezenciSetParticipantTaxId.
         * @memberof api_udelezenci
         * @classdesc Represents an ApiUdelezenciSetParticipantTaxId.
         * @implements IApiUdelezenciSetParticipantTaxId
         * @constructor
         * @param {api_udelezenci.IApiUdelezenciSetParticipantTaxId=} [properties] Properties to set
         */
        function ApiUdelezenciSetParticipantTaxId(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiUdelezenciSetParticipantTaxId participationId.
         * @member {string} participationId
         * @memberof api_udelezenci.ApiUdelezenciSetParticipantTaxId
         * @instance
         */
        ApiUdelezenciSetParticipantTaxId.prototype.participationId = "";

        /**
         * ApiUdelezenciSetParticipantTaxId taxId.
         * @member {string} taxId
         * @memberof api_udelezenci.ApiUdelezenciSetParticipantTaxId
         * @instance
         */
        ApiUdelezenciSetParticipantTaxId.prototype.taxId = "";

        /**
         * Creates a new ApiUdelezenciSetParticipantTaxId instance using the specified properties.
         * @function create
         * @memberof api_udelezenci.ApiUdelezenciSetParticipantTaxId
         * @static
         * @param {api_udelezenci.IApiUdelezenciSetParticipantTaxId=} [properties] Properties to set
         * @returns {api_udelezenci.ApiUdelezenciSetParticipantTaxId} ApiUdelezenciSetParticipantTaxId instance
         */
        ApiUdelezenciSetParticipantTaxId.create = function create(properties) {
            return new ApiUdelezenciSetParticipantTaxId(properties);
        };

        /**
         * Encodes the specified ApiUdelezenciSetParticipantTaxId message. Does not implicitly {@link api_udelezenci.ApiUdelezenciSetParticipantTaxId.verify|verify} messages.
         * @function encode
         * @memberof api_udelezenci.ApiUdelezenciSetParticipantTaxId
         * @static
         * @param {api_udelezenci.IApiUdelezenciSetParticipantTaxId} message ApiUdelezenciSetParticipantTaxId message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiUdelezenciSetParticipantTaxId.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.participationId != null && Object.hasOwnProperty.call(message, "participationId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.participationId);
            if (message.taxId != null && Object.hasOwnProperty.call(message, "taxId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.taxId);
            return writer;
        };

        /**
         * Encodes the specified ApiUdelezenciSetParticipantTaxId message, length delimited. Does not implicitly {@link api_udelezenci.ApiUdelezenciSetParticipantTaxId.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_udelezenci.ApiUdelezenciSetParticipantTaxId
         * @static
         * @param {api_udelezenci.IApiUdelezenciSetParticipantTaxId} message ApiUdelezenciSetParticipantTaxId message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiUdelezenciSetParticipantTaxId.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiUdelezenciSetParticipantTaxId message from the specified reader or buffer.
         * @function decode
         * @memberof api_udelezenci.ApiUdelezenciSetParticipantTaxId
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_udelezenci.ApiUdelezenciSetParticipantTaxId} ApiUdelezenciSetParticipantTaxId
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiUdelezenciSetParticipantTaxId.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_udelezenci.ApiUdelezenciSetParticipantTaxId();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.participationId = reader.string();
                        break;
                    }
                case 2: {
                        message.taxId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiUdelezenciSetParticipantTaxId message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_udelezenci.ApiUdelezenciSetParticipantTaxId
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_udelezenci.ApiUdelezenciSetParticipantTaxId} ApiUdelezenciSetParticipantTaxId
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiUdelezenciSetParticipantTaxId.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiUdelezenciSetParticipantTaxId message.
         * @function verify
         * @memberof api_udelezenci.ApiUdelezenciSetParticipantTaxId
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiUdelezenciSetParticipantTaxId.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.participationId != null && message.hasOwnProperty("participationId"))
                if (!$util.isString(message.participationId))
                    return "participationId: string expected";
            if (message.taxId != null && message.hasOwnProperty("taxId"))
                if (!$util.isString(message.taxId))
                    return "taxId: string expected";
            return null;
        };

        /**
         * Creates an ApiUdelezenciSetParticipantTaxId message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_udelezenci.ApiUdelezenciSetParticipantTaxId
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_udelezenci.ApiUdelezenciSetParticipantTaxId} ApiUdelezenciSetParticipantTaxId
         */
        ApiUdelezenciSetParticipantTaxId.fromObject = function fromObject(object) {
            if (object instanceof $root.api_udelezenci.ApiUdelezenciSetParticipantTaxId)
                return object;
            let message = new $root.api_udelezenci.ApiUdelezenciSetParticipantTaxId();
            if (object.participationId != null)
                message.participationId = String(object.participationId);
            if (object.taxId != null)
                message.taxId = String(object.taxId);
            return message;
        };

        /**
         * Creates a plain object from an ApiUdelezenciSetParticipantTaxId message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_udelezenci.ApiUdelezenciSetParticipantTaxId
         * @static
         * @param {api_udelezenci.ApiUdelezenciSetParticipantTaxId} message ApiUdelezenciSetParticipantTaxId
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiUdelezenciSetParticipantTaxId.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.participationId = "";
                object.taxId = "";
            }
            if (message.participationId != null && message.hasOwnProperty("participationId"))
                object.participationId = message.participationId;
            if (message.taxId != null && message.hasOwnProperty("taxId"))
                object.taxId = message.taxId;
            return object;
        };

        /**
         * Converts this ApiUdelezenciSetParticipantTaxId to JSON.
         * @function toJSON
         * @memberof api_udelezenci.ApiUdelezenciSetParticipantTaxId
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiUdelezenciSetParticipantTaxId.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiUdelezenciSetParticipantTaxId
         * @function getTypeUrl
         * @memberof api_udelezenci.ApiUdelezenciSetParticipantTaxId
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiUdelezenciSetParticipantTaxId.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_udelezenci.ApiUdelezenciSetParticipantTaxId";
        };

        return ApiUdelezenciSetParticipantTaxId;
    })();

    api_udelezenci.ApiUdelezenciRemoveParticipant = (function() {

        /**
         * Properties of an ApiUdelezenciRemoveParticipant.
         * @memberof api_udelezenci
         * @interface IApiUdelezenciRemoveParticipant
         * @property {string|null} [participationId] ApiUdelezenciRemoveParticipant participationId
         */

        /**
         * Constructs a new ApiUdelezenciRemoveParticipant.
         * @memberof api_udelezenci
         * @classdesc Represents an ApiUdelezenciRemoveParticipant.
         * @implements IApiUdelezenciRemoveParticipant
         * @constructor
         * @param {api_udelezenci.IApiUdelezenciRemoveParticipant=} [properties] Properties to set
         */
        function ApiUdelezenciRemoveParticipant(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiUdelezenciRemoveParticipant participationId.
         * @member {string} participationId
         * @memberof api_udelezenci.ApiUdelezenciRemoveParticipant
         * @instance
         */
        ApiUdelezenciRemoveParticipant.prototype.participationId = "";

        /**
         * Creates a new ApiUdelezenciRemoveParticipant instance using the specified properties.
         * @function create
         * @memberof api_udelezenci.ApiUdelezenciRemoveParticipant
         * @static
         * @param {api_udelezenci.IApiUdelezenciRemoveParticipant=} [properties] Properties to set
         * @returns {api_udelezenci.ApiUdelezenciRemoveParticipant} ApiUdelezenciRemoveParticipant instance
         */
        ApiUdelezenciRemoveParticipant.create = function create(properties) {
            return new ApiUdelezenciRemoveParticipant(properties);
        };

        /**
         * Encodes the specified ApiUdelezenciRemoveParticipant message. Does not implicitly {@link api_udelezenci.ApiUdelezenciRemoveParticipant.verify|verify} messages.
         * @function encode
         * @memberof api_udelezenci.ApiUdelezenciRemoveParticipant
         * @static
         * @param {api_udelezenci.IApiUdelezenciRemoveParticipant} message ApiUdelezenciRemoveParticipant message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiUdelezenciRemoveParticipant.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.participationId != null && Object.hasOwnProperty.call(message, "participationId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.participationId);
            return writer;
        };

        /**
         * Encodes the specified ApiUdelezenciRemoveParticipant message, length delimited. Does not implicitly {@link api_udelezenci.ApiUdelezenciRemoveParticipant.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_udelezenci.ApiUdelezenciRemoveParticipant
         * @static
         * @param {api_udelezenci.IApiUdelezenciRemoveParticipant} message ApiUdelezenciRemoveParticipant message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiUdelezenciRemoveParticipant.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiUdelezenciRemoveParticipant message from the specified reader or buffer.
         * @function decode
         * @memberof api_udelezenci.ApiUdelezenciRemoveParticipant
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_udelezenci.ApiUdelezenciRemoveParticipant} ApiUdelezenciRemoveParticipant
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiUdelezenciRemoveParticipant.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_udelezenci.ApiUdelezenciRemoveParticipant();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.participationId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiUdelezenciRemoveParticipant message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_udelezenci.ApiUdelezenciRemoveParticipant
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_udelezenci.ApiUdelezenciRemoveParticipant} ApiUdelezenciRemoveParticipant
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiUdelezenciRemoveParticipant.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiUdelezenciRemoveParticipant message.
         * @function verify
         * @memberof api_udelezenci.ApiUdelezenciRemoveParticipant
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiUdelezenciRemoveParticipant.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.participationId != null && message.hasOwnProperty("participationId"))
                if (!$util.isString(message.participationId))
                    return "participationId: string expected";
            return null;
        };

        /**
         * Creates an ApiUdelezenciRemoveParticipant message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_udelezenci.ApiUdelezenciRemoveParticipant
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_udelezenci.ApiUdelezenciRemoveParticipant} ApiUdelezenciRemoveParticipant
         */
        ApiUdelezenciRemoveParticipant.fromObject = function fromObject(object) {
            if (object instanceof $root.api_udelezenci.ApiUdelezenciRemoveParticipant)
                return object;
            let message = new $root.api_udelezenci.ApiUdelezenciRemoveParticipant();
            if (object.participationId != null)
                message.participationId = String(object.participationId);
            return message;
        };

        /**
         * Creates a plain object from an ApiUdelezenciRemoveParticipant message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_udelezenci.ApiUdelezenciRemoveParticipant
         * @static
         * @param {api_udelezenci.ApiUdelezenciRemoveParticipant} message ApiUdelezenciRemoveParticipant
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiUdelezenciRemoveParticipant.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.participationId = "";
            if (message.participationId != null && message.hasOwnProperty("participationId"))
                object.participationId = message.participationId;
            return object;
        };

        /**
         * Converts this ApiUdelezenciRemoveParticipant to JSON.
         * @function toJSON
         * @memberof api_udelezenci.ApiUdelezenciRemoveParticipant
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiUdelezenciRemoveParticipant.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiUdelezenciRemoveParticipant
         * @function getTypeUrl
         * @memberof api_udelezenci.ApiUdelezenciRemoveParticipant
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiUdelezenciRemoveParticipant.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_udelezenci.ApiUdelezenciRemoveParticipant";
        };

        return ApiUdelezenciRemoveParticipant;
    })();

    api_udelezenci.ApiUdelezenciGetSessionParticipants = (function() {

        /**
         * Properties of an ApiUdelezenciGetSessionParticipants.
         * @memberof api_udelezenci
         * @interface IApiUdelezenciGetSessionParticipants
         * @property {string|null} [sessionId] ApiUdelezenciGetSessionParticipants sessionId
         */

        /**
         * Constructs a new ApiUdelezenciGetSessionParticipants.
         * @memberof api_udelezenci
         * @classdesc Represents an ApiUdelezenciGetSessionParticipants.
         * @implements IApiUdelezenciGetSessionParticipants
         * @constructor
         * @param {api_udelezenci.IApiUdelezenciGetSessionParticipants=} [properties] Properties to set
         */
        function ApiUdelezenciGetSessionParticipants(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiUdelezenciGetSessionParticipants sessionId.
         * @member {string} sessionId
         * @memberof api_udelezenci.ApiUdelezenciGetSessionParticipants
         * @instance
         */
        ApiUdelezenciGetSessionParticipants.prototype.sessionId = "";

        /**
         * Creates a new ApiUdelezenciGetSessionParticipants instance using the specified properties.
         * @function create
         * @memberof api_udelezenci.ApiUdelezenciGetSessionParticipants
         * @static
         * @param {api_udelezenci.IApiUdelezenciGetSessionParticipants=} [properties] Properties to set
         * @returns {api_udelezenci.ApiUdelezenciGetSessionParticipants} ApiUdelezenciGetSessionParticipants instance
         */
        ApiUdelezenciGetSessionParticipants.create = function create(properties) {
            return new ApiUdelezenciGetSessionParticipants(properties);
        };

        /**
         * Encodes the specified ApiUdelezenciGetSessionParticipants message. Does not implicitly {@link api_udelezenci.ApiUdelezenciGetSessionParticipants.verify|verify} messages.
         * @function encode
         * @memberof api_udelezenci.ApiUdelezenciGetSessionParticipants
         * @static
         * @param {api_udelezenci.IApiUdelezenciGetSessionParticipants} message ApiUdelezenciGetSessionParticipants message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiUdelezenciGetSessionParticipants.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sessionId != null && Object.hasOwnProperty.call(message, "sessionId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.sessionId);
            return writer;
        };

        /**
         * Encodes the specified ApiUdelezenciGetSessionParticipants message, length delimited. Does not implicitly {@link api_udelezenci.ApiUdelezenciGetSessionParticipants.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_udelezenci.ApiUdelezenciGetSessionParticipants
         * @static
         * @param {api_udelezenci.IApiUdelezenciGetSessionParticipants} message ApiUdelezenciGetSessionParticipants message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiUdelezenciGetSessionParticipants.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiUdelezenciGetSessionParticipants message from the specified reader or buffer.
         * @function decode
         * @memberof api_udelezenci.ApiUdelezenciGetSessionParticipants
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_udelezenci.ApiUdelezenciGetSessionParticipants} ApiUdelezenciGetSessionParticipants
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiUdelezenciGetSessionParticipants.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_udelezenci.ApiUdelezenciGetSessionParticipants();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sessionId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiUdelezenciGetSessionParticipants message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_udelezenci.ApiUdelezenciGetSessionParticipants
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_udelezenci.ApiUdelezenciGetSessionParticipants} ApiUdelezenciGetSessionParticipants
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiUdelezenciGetSessionParticipants.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiUdelezenciGetSessionParticipants message.
         * @function verify
         * @memberof api_udelezenci.ApiUdelezenciGetSessionParticipants
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiUdelezenciGetSessionParticipants.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                if (!$util.isString(message.sessionId))
                    return "sessionId: string expected";
            return null;
        };

        /**
         * Creates an ApiUdelezenciGetSessionParticipants message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_udelezenci.ApiUdelezenciGetSessionParticipants
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_udelezenci.ApiUdelezenciGetSessionParticipants} ApiUdelezenciGetSessionParticipants
         */
        ApiUdelezenciGetSessionParticipants.fromObject = function fromObject(object) {
            if (object instanceof $root.api_udelezenci.ApiUdelezenciGetSessionParticipants)
                return object;
            let message = new $root.api_udelezenci.ApiUdelezenciGetSessionParticipants();
            if (object.sessionId != null)
                message.sessionId = String(object.sessionId);
            return message;
        };

        /**
         * Creates a plain object from an ApiUdelezenciGetSessionParticipants message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_udelezenci.ApiUdelezenciGetSessionParticipants
         * @static
         * @param {api_udelezenci.ApiUdelezenciGetSessionParticipants} message ApiUdelezenciGetSessionParticipants
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiUdelezenciGetSessionParticipants.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.sessionId = "";
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                object.sessionId = message.sessionId;
            return object;
        };

        /**
         * Converts this ApiUdelezenciGetSessionParticipants to JSON.
         * @function toJSON
         * @memberof api_udelezenci.ApiUdelezenciGetSessionParticipants
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiUdelezenciGetSessionParticipants.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiUdelezenciGetSessionParticipants
         * @function getTypeUrl
         * @memberof api_udelezenci.ApiUdelezenciGetSessionParticipants
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiUdelezenciGetSessionParticipants.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_udelezenci.ApiUdelezenciGetSessionParticipants";
        };

        return ApiUdelezenciGetSessionParticipants;
    })();

    api_udelezenci.ApiUdelezenciSessionParticipants = (function() {

        /**
         * Properties of an ApiUdelezenciSessionParticipants.
         * @memberof api_udelezenci
         * @interface IApiUdelezenciSessionParticipants
         * @property {string|null} [sourceEventId] ApiUdelezenciSessionParticipants sourceEventId
         * @property {string|null} [sessionId] ApiUdelezenciSessionParticipants sessionId
         * @property {Array.<api_udelezenci.IApiUdelezenciSessionParticipant>|null} [participants] ApiUdelezenciSessionParticipants participants
         */

        /**
         * Constructs a new ApiUdelezenciSessionParticipants.
         * @memberof api_udelezenci
         * @classdesc Represents an ApiUdelezenciSessionParticipants.
         * @implements IApiUdelezenciSessionParticipants
         * @constructor
         * @param {api_udelezenci.IApiUdelezenciSessionParticipants=} [properties] Properties to set
         */
        function ApiUdelezenciSessionParticipants(properties) {
            this.participants = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiUdelezenciSessionParticipants sourceEventId.
         * @member {string} sourceEventId
         * @memberof api_udelezenci.ApiUdelezenciSessionParticipants
         * @instance
         */
        ApiUdelezenciSessionParticipants.prototype.sourceEventId = "";

        /**
         * ApiUdelezenciSessionParticipants sessionId.
         * @member {string} sessionId
         * @memberof api_udelezenci.ApiUdelezenciSessionParticipants
         * @instance
         */
        ApiUdelezenciSessionParticipants.prototype.sessionId = "";

        /**
         * ApiUdelezenciSessionParticipants participants.
         * @member {Array.<api_udelezenci.IApiUdelezenciSessionParticipant>} participants
         * @memberof api_udelezenci.ApiUdelezenciSessionParticipants
         * @instance
         */
        ApiUdelezenciSessionParticipants.prototype.participants = $util.emptyArray;

        /**
         * Creates a new ApiUdelezenciSessionParticipants instance using the specified properties.
         * @function create
         * @memberof api_udelezenci.ApiUdelezenciSessionParticipants
         * @static
         * @param {api_udelezenci.IApiUdelezenciSessionParticipants=} [properties] Properties to set
         * @returns {api_udelezenci.ApiUdelezenciSessionParticipants} ApiUdelezenciSessionParticipants instance
         */
        ApiUdelezenciSessionParticipants.create = function create(properties) {
            return new ApiUdelezenciSessionParticipants(properties);
        };

        /**
         * Encodes the specified ApiUdelezenciSessionParticipants message. Does not implicitly {@link api_udelezenci.ApiUdelezenciSessionParticipants.verify|verify} messages.
         * @function encode
         * @memberof api_udelezenci.ApiUdelezenciSessionParticipants
         * @static
         * @param {api_udelezenci.IApiUdelezenciSessionParticipants} message ApiUdelezenciSessionParticipants message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiUdelezenciSessionParticipants.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sourceEventId != null && Object.hasOwnProperty.call(message, "sourceEventId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.sourceEventId);
            if (message.sessionId != null && Object.hasOwnProperty.call(message, "sessionId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.sessionId);
            if (message.participants != null && message.participants.length)
                for (let i = 0; i < message.participants.length; ++i)
                    $root.api_udelezenci.ApiUdelezenciSessionParticipant.encode(message.participants[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ApiUdelezenciSessionParticipants message, length delimited. Does not implicitly {@link api_udelezenci.ApiUdelezenciSessionParticipants.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_udelezenci.ApiUdelezenciSessionParticipants
         * @static
         * @param {api_udelezenci.IApiUdelezenciSessionParticipants} message ApiUdelezenciSessionParticipants message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiUdelezenciSessionParticipants.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiUdelezenciSessionParticipants message from the specified reader or buffer.
         * @function decode
         * @memberof api_udelezenci.ApiUdelezenciSessionParticipants
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_udelezenci.ApiUdelezenciSessionParticipants} ApiUdelezenciSessionParticipants
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiUdelezenciSessionParticipants.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_udelezenci.ApiUdelezenciSessionParticipants();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sourceEventId = reader.string();
                        break;
                    }
                case 2: {
                        message.sessionId = reader.string();
                        break;
                    }
                case 3: {
                        if (!(message.participants && message.participants.length))
                            message.participants = [];
                        message.participants.push($root.api_udelezenci.ApiUdelezenciSessionParticipant.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiUdelezenciSessionParticipants message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_udelezenci.ApiUdelezenciSessionParticipants
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_udelezenci.ApiUdelezenciSessionParticipants} ApiUdelezenciSessionParticipants
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiUdelezenciSessionParticipants.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiUdelezenciSessionParticipants message.
         * @function verify
         * @memberof api_udelezenci.ApiUdelezenciSessionParticipants
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiUdelezenciSessionParticipants.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.sourceEventId != null && message.hasOwnProperty("sourceEventId"))
                if (!$util.isString(message.sourceEventId))
                    return "sourceEventId: string expected";
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                if (!$util.isString(message.sessionId))
                    return "sessionId: string expected";
            if (message.participants != null && message.hasOwnProperty("participants")) {
                if (!Array.isArray(message.participants))
                    return "participants: array expected";
                for (let i = 0; i < message.participants.length; ++i) {
                    let error = $root.api_udelezenci.ApiUdelezenciSessionParticipant.verify(message.participants[i]);
                    if (error)
                        return "participants." + error;
                }
            }
            return null;
        };

        /**
         * Creates an ApiUdelezenciSessionParticipants message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_udelezenci.ApiUdelezenciSessionParticipants
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_udelezenci.ApiUdelezenciSessionParticipants} ApiUdelezenciSessionParticipants
         */
        ApiUdelezenciSessionParticipants.fromObject = function fromObject(object) {
            if (object instanceof $root.api_udelezenci.ApiUdelezenciSessionParticipants)
                return object;
            let message = new $root.api_udelezenci.ApiUdelezenciSessionParticipants();
            if (object.sourceEventId != null)
                message.sourceEventId = String(object.sourceEventId);
            if (object.sessionId != null)
                message.sessionId = String(object.sessionId);
            if (object.participants) {
                if (!Array.isArray(object.participants))
                    throw TypeError(".api_udelezenci.ApiUdelezenciSessionParticipants.participants: array expected");
                message.participants = [];
                for (let i = 0; i < object.participants.length; ++i) {
                    if (typeof object.participants[i] !== "object")
                        throw TypeError(".api_udelezenci.ApiUdelezenciSessionParticipants.participants: object expected");
                    message.participants[i] = $root.api_udelezenci.ApiUdelezenciSessionParticipant.fromObject(object.participants[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from an ApiUdelezenciSessionParticipants message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_udelezenci.ApiUdelezenciSessionParticipants
         * @static
         * @param {api_udelezenci.ApiUdelezenciSessionParticipants} message ApiUdelezenciSessionParticipants
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiUdelezenciSessionParticipants.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.participants = [];
            if (options.defaults) {
                object.sourceEventId = "";
                object.sessionId = "";
            }
            if (message.sourceEventId != null && message.hasOwnProperty("sourceEventId"))
                object.sourceEventId = message.sourceEventId;
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                object.sessionId = message.sessionId;
            if (message.participants && message.participants.length) {
                object.participants = [];
                for (let j = 0; j < message.participants.length; ++j)
                    object.participants[j] = $root.api_udelezenci.ApiUdelezenciSessionParticipant.toObject(message.participants[j], options);
            }
            return object;
        };

        /**
         * Converts this ApiUdelezenciSessionParticipants to JSON.
         * @function toJSON
         * @memberof api_udelezenci.ApiUdelezenciSessionParticipants
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiUdelezenciSessionParticipants.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiUdelezenciSessionParticipants
         * @function getTypeUrl
         * @memberof api_udelezenci.ApiUdelezenciSessionParticipants
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiUdelezenciSessionParticipants.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_udelezenci.ApiUdelezenciSessionParticipants";
        };

        return ApiUdelezenciSessionParticipants;
    })();

    api_udelezenci.ApiUdelezenciSessionParticipant = (function() {

        /**
         * Properties of an ApiUdelezenciSessionParticipant.
         * @memberof api_udelezenci
         * @interface IApiUdelezenciSessionParticipant
         * @property {string|null} [participationId] ApiUdelezenciSessionParticipant participationId
         * @property {string|null} [personId] ApiUdelezenciSessionParticipant personId
         * @property {string|null} [displayName] ApiUdelezenciSessionParticipant displayName
         * @property {string|null} [roleId] ApiUdelezenciSessionParticipant roleId
         * @property {boolean|null} [publicAccess] ApiUdelezenciSessionParticipant publicAccess
         */

        /**
         * Constructs a new ApiUdelezenciSessionParticipant.
         * @memberof api_udelezenci
         * @classdesc Represents an ApiUdelezenciSessionParticipant.
         * @implements IApiUdelezenciSessionParticipant
         * @constructor
         * @param {api_udelezenci.IApiUdelezenciSessionParticipant=} [properties] Properties to set
         */
        function ApiUdelezenciSessionParticipant(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApiUdelezenciSessionParticipant participationId.
         * @member {string} participationId
         * @memberof api_udelezenci.ApiUdelezenciSessionParticipant
         * @instance
         */
        ApiUdelezenciSessionParticipant.prototype.participationId = "";

        /**
         * ApiUdelezenciSessionParticipant personId.
         * @member {string} personId
         * @memberof api_udelezenci.ApiUdelezenciSessionParticipant
         * @instance
         */
        ApiUdelezenciSessionParticipant.prototype.personId = "";

        /**
         * ApiUdelezenciSessionParticipant displayName.
         * @member {string} displayName
         * @memberof api_udelezenci.ApiUdelezenciSessionParticipant
         * @instance
         */
        ApiUdelezenciSessionParticipant.prototype.displayName = "";

        /**
         * ApiUdelezenciSessionParticipant roleId.
         * @member {string} roleId
         * @memberof api_udelezenci.ApiUdelezenciSessionParticipant
         * @instance
         */
        ApiUdelezenciSessionParticipant.prototype.roleId = "";

        /**
         * ApiUdelezenciSessionParticipant publicAccess.
         * @member {boolean} publicAccess
         * @memberof api_udelezenci.ApiUdelezenciSessionParticipant
         * @instance
         */
        ApiUdelezenciSessionParticipant.prototype.publicAccess = false;

        /**
         * Creates a new ApiUdelezenciSessionParticipant instance using the specified properties.
         * @function create
         * @memberof api_udelezenci.ApiUdelezenciSessionParticipant
         * @static
         * @param {api_udelezenci.IApiUdelezenciSessionParticipant=} [properties] Properties to set
         * @returns {api_udelezenci.ApiUdelezenciSessionParticipant} ApiUdelezenciSessionParticipant instance
         */
        ApiUdelezenciSessionParticipant.create = function create(properties) {
            return new ApiUdelezenciSessionParticipant(properties);
        };

        /**
         * Encodes the specified ApiUdelezenciSessionParticipant message. Does not implicitly {@link api_udelezenci.ApiUdelezenciSessionParticipant.verify|verify} messages.
         * @function encode
         * @memberof api_udelezenci.ApiUdelezenciSessionParticipant
         * @static
         * @param {api_udelezenci.IApiUdelezenciSessionParticipant} message ApiUdelezenciSessionParticipant message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiUdelezenciSessionParticipant.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.participationId != null && Object.hasOwnProperty.call(message, "participationId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.participationId);
            if (message.personId != null && Object.hasOwnProperty.call(message, "personId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.personId);
            if (message.displayName != null && Object.hasOwnProperty.call(message, "displayName"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.displayName);
            if (message.roleId != null && Object.hasOwnProperty.call(message, "roleId"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.roleId);
            if (message.publicAccess != null && Object.hasOwnProperty.call(message, "publicAccess"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.publicAccess);
            return writer;
        };

        /**
         * Encodes the specified ApiUdelezenciSessionParticipant message, length delimited. Does not implicitly {@link api_udelezenci.ApiUdelezenciSessionParticipant.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api_udelezenci.ApiUdelezenciSessionParticipant
         * @static
         * @param {api_udelezenci.IApiUdelezenciSessionParticipant} message ApiUdelezenciSessionParticipant message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApiUdelezenciSessionParticipant.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ApiUdelezenciSessionParticipant message from the specified reader or buffer.
         * @function decode
         * @memberof api_udelezenci.ApiUdelezenciSessionParticipant
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api_udelezenci.ApiUdelezenciSessionParticipant} ApiUdelezenciSessionParticipant
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiUdelezenciSessionParticipant.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api_udelezenci.ApiUdelezenciSessionParticipant();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.participationId = reader.string();
                        break;
                    }
                case 2: {
                        message.personId = reader.string();
                        break;
                    }
                case 3: {
                        message.displayName = reader.string();
                        break;
                    }
                case 4: {
                        message.roleId = reader.string();
                        break;
                    }
                case 5: {
                        message.publicAccess = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ApiUdelezenciSessionParticipant message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api_udelezenci.ApiUdelezenciSessionParticipant
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api_udelezenci.ApiUdelezenciSessionParticipant} ApiUdelezenciSessionParticipant
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApiUdelezenciSessionParticipant.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ApiUdelezenciSessionParticipant message.
         * @function verify
         * @memberof api_udelezenci.ApiUdelezenciSessionParticipant
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ApiUdelezenciSessionParticipant.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.participationId != null && message.hasOwnProperty("participationId"))
                if (!$util.isString(message.participationId))
                    return "participationId: string expected";
            if (message.personId != null && message.hasOwnProperty("personId"))
                if (!$util.isString(message.personId))
                    return "personId: string expected";
            if (message.displayName != null && message.hasOwnProperty("displayName"))
                if (!$util.isString(message.displayName))
                    return "displayName: string expected";
            if (message.roleId != null && message.hasOwnProperty("roleId"))
                if (!$util.isString(message.roleId))
                    return "roleId: string expected";
            if (message.publicAccess != null && message.hasOwnProperty("publicAccess"))
                if (typeof message.publicAccess !== "boolean")
                    return "publicAccess: boolean expected";
            return null;
        };

        /**
         * Creates an ApiUdelezenciSessionParticipant message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api_udelezenci.ApiUdelezenciSessionParticipant
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api_udelezenci.ApiUdelezenciSessionParticipant} ApiUdelezenciSessionParticipant
         */
        ApiUdelezenciSessionParticipant.fromObject = function fromObject(object) {
            if (object instanceof $root.api_udelezenci.ApiUdelezenciSessionParticipant)
                return object;
            let message = new $root.api_udelezenci.ApiUdelezenciSessionParticipant();
            if (object.participationId != null)
                message.participationId = String(object.participationId);
            if (object.personId != null)
                message.personId = String(object.personId);
            if (object.displayName != null)
                message.displayName = String(object.displayName);
            if (object.roleId != null)
                message.roleId = String(object.roleId);
            if (object.publicAccess != null)
                message.publicAccess = Boolean(object.publicAccess);
            return message;
        };

        /**
         * Creates a plain object from an ApiUdelezenciSessionParticipant message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api_udelezenci.ApiUdelezenciSessionParticipant
         * @static
         * @param {api_udelezenci.ApiUdelezenciSessionParticipant} message ApiUdelezenciSessionParticipant
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ApiUdelezenciSessionParticipant.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.participationId = "";
                object.personId = "";
                object.displayName = "";
                object.roleId = "";
                object.publicAccess = false;
            }
            if (message.participationId != null && message.hasOwnProperty("participationId"))
                object.participationId = message.participationId;
            if (message.personId != null && message.hasOwnProperty("personId"))
                object.personId = message.personId;
            if (message.displayName != null && message.hasOwnProperty("displayName"))
                object.displayName = message.displayName;
            if (message.roleId != null && message.hasOwnProperty("roleId"))
                object.roleId = message.roleId;
            if (message.publicAccess != null && message.hasOwnProperty("publicAccess"))
                object.publicAccess = message.publicAccess;
            return object;
        };

        /**
         * Converts this ApiUdelezenciSessionParticipant to JSON.
         * @function toJSON
         * @memberof api_udelezenci.ApiUdelezenciSessionParticipant
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ApiUdelezenciSessionParticipant.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ApiUdelezenciSessionParticipant
         * @function getTypeUrl
         * @memberof api_udelezenci.ApiUdelezenciSessionParticipant
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ApiUdelezenciSessionParticipant.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api_udelezenci.ApiUdelezenciSessionParticipant";
        };

        return ApiUdelezenciSessionParticipant;
    })();

    return api_udelezenci;
})();

export const sfiles = $root.sfiles = (() => {

    /**
     * Namespace sfiles.
     * @exports sfiles
     * @namespace
     */
    const sfiles = {};

    sfiles.UploadStartSlot = (function() {

        /**
         * Properties of an UploadStartSlot.
         * @memberof sfiles
         * @interface IUploadStartSlot
         * @property {string|null} [key] UploadStartSlot key
         * @property {string|null} [localKey] UploadStartSlot localKey
         */

        /**
         * Constructs a new UploadStartSlot.
         * @memberof sfiles
         * @classdesc Represents an UploadStartSlot.
         * @implements IUploadStartSlot
         * @constructor
         * @param {sfiles.IUploadStartSlot=} [properties] Properties to set
         */
        function UploadStartSlot(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UploadStartSlot key.
         * @member {string} key
         * @memberof sfiles.UploadStartSlot
         * @instance
         */
        UploadStartSlot.prototype.key = "";

        /**
         * UploadStartSlot localKey.
         * @member {string} localKey
         * @memberof sfiles.UploadStartSlot
         * @instance
         */
        UploadStartSlot.prototype.localKey = "";

        /**
         * Creates a new UploadStartSlot instance using the specified properties.
         * @function create
         * @memberof sfiles.UploadStartSlot
         * @static
         * @param {sfiles.IUploadStartSlot=} [properties] Properties to set
         * @returns {sfiles.UploadStartSlot} UploadStartSlot instance
         */
        UploadStartSlot.create = function create(properties) {
            return new UploadStartSlot(properties);
        };

        /**
         * Encodes the specified UploadStartSlot message. Does not implicitly {@link sfiles.UploadStartSlot.verify|verify} messages.
         * @function encode
         * @memberof sfiles.UploadStartSlot
         * @static
         * @param {sfiles.IUploadStartSlot} message UploadStartSlot message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadStartSlot.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.key);
            if (message.localKey != null && Object.hasOwnProperty.call(message, "localKey"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.localKey);
            return writer;
        };

        /**
         * Encodes the specified UploadStartSlot message, length delimited. Does not implicitly {@link sfiles.UploadStartSlot.verify|verify} messages.
         * @function encodeDelimited
         * @memberof sfiles.UploadStartSlot
         * @static
         * @param {sfiles.IUploadStartSlot} message UploadStartSlot message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadStartSlot.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UploadStartSlot message from the specified reader or buffer.
         * @function decode
         * @memberof sfiles.UploadStartSlot
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sfiles.UploadStartSlot} UploadStartSlot
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadStartSlot.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.sfiles.UploadStartSlot();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.key = reader.string();
                        break;
                    }
                case 2: {
                        message.localKey = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UploadStartSlot message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof sfiles.UploadStartSlot
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {sfiles.UploadStartSlot} UploadStartSlot
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadStartSlot.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UploadStartSlot message.
         * @function verify
         * @memberof sfiles.UploadStartSlot
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UploadStartSlot.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.key != null && message.hasOwnProperty("key"))
                if (!$util.isString(message.key))
                    return "key: string expected";
            if (message.localKey != null && message.hasOwnProperty("localKey"))
                if (!$util.isString(message.localKey))
                    return "localKey: string expected";
            return null;
        };

        /**
         * Creates an UploadStartSlot message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sfiles.UploadStartSlot
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sfiles.UploadStartSlot} UploadStartSlot
         */
        UploadStartSlot.fromObject = function fromObject(object) {
            if (object instanceof $root.sfiles.UploadStartSlot)
                return object;
            let message = new $root.sfiles.UploadStartSlot();
            if (object.key != null)
                message.key = String(object.key);
            if (object.localKey != null)
                message.localKey = String(object.localKey);
            return message;
        };

        /**
         * Creates a plain object from an UploadStartSlot message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sfiles.UploadStartSlot
         * @static
         * @param {sfiles.UploadStartSlot} message UploadStartSlot
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UploadStartSlot.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.key = "";
                object.localKey = "";
            }
            if (message.key != null && message.hasOwnProperty("key"))
                object.key = message.key;
            if (message.localKey != null && message.hasOwnProperty("localKey"))
                object.localKey = message.localKey;
            return object;
        };

        /**
         * Converts this UploadStartSlot to JSON.
         * @function toJSON
         * @memberof sfiles.UploadStartSlot
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UploadStartSlot.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UploadStartSlot
         * @function getTypeUrl
         * @memberof sfiles.UploadStartSlot
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UploadStartSlot.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/sfiles.UploadStartSlot";
        };

        return UploadStartSlot;
    })();

    sfiles.UploadProgress = (function() {

        /**
         * Properties of an UploadProgress.
         * @memberof sfiles
         * @interface IUploadProgress
         * @property {number|Long|null} [nBytes] UploadProgress nBytes
         * @property {string|null} [key] UploadProgress key
         * @property {string|null} [localKey] UploadProgress localKey
         */

        /**
         * Constructs a new UploadProgress.
         * @memberof sfiles
         * @classdesc Represents an UploadProgress.
         * @implements IUploadProgress
         * @constructor
         * @param {sfiles.IUploadProgress=} [properties] Properties to set
         */
        function UploadProgress(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UploadProgress nBytes.
         * @member {number|Long} nBytes
         * @memberof sfiles.UploadProgress
         * @instance
         */
        UploadProgress.prototype.nBytes = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * UploadProgress key.
         * @member {string} key
         * @memberof sfiles.UploadProgress
         * @instance
         */
        UploadProgress.prototype.key = "";

        /**
         * UploadProgress localKey.
         * @member {string} localKey
         * @memberof sfiles.UploadProgress
         * @instance
         */
        UploadProgress.prototype.localKey = "";

        /**
         * Creates a new UploadProgress instance using the specified properties.
         * @function create
         * @memberof sfiles.UploadProgress
         * @static
         * @param {sfiles.IUploadProgress=} [properties] Properties to set
         * @returns {sfiles.UploadProgress} UploadProgress instance
         */
        UploadProgress.create = function create(properties) {
            return new UploadProgress(properties);
        };

        /**
         * Encodes the specified UploadProgress message. Does not implicitly {@link sfiles.UploadProgress.verify|verify} messages.
         * @function encode
         * @memberof sfiles.UploadProgress
         * @static
         * @param {sfiles.IUploadProgress} message UploadProgress message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadProgress.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.nBytes != null && Object.hasOwnProperty.call(message, "nBytes"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.nBytes);
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.key);
            if (message.localKey != null && Object.hasOwnProperty.call(message, "localKey"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.localKey);
            return writer;
        };

        /**
         * Encodes the specified UploadProgress message, length delimited. Does not implicitly {@link sfiles.UploadProgress.verify|verify} messages.
         * @function encodeDelimited
         * @memberof sfiles.UploadProgress
         * @static
         * @param {sfiles.IUploadProgress} message UploadProgress message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadProgress.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UploadProgress message from the specified reader or buffer.
         * @function decode
         * @memberof sfiles.UploadProgress
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sfiles.UploadProgress} UploadProgress
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadProgress.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.sfiles.UploadProgress();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.nBytes = reader.int64();
                        break;
                    }
                case 2: {
                        message.key = reader.string();
                        break;
                    }
                case 3: {
                        message.localKey = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UploadProgress message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof sfiles.UploadProgress
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {sfiles.UploadProgress} UploadProgress
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadProgress.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UploadProgress message.
         * @function verify
         * @memberof sfiles.UploadProgress
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UploadProgress.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.nBytes != null && message.hasOwnProperty("nBytes"))
                if (!$util.isInteger(message.nBytes) && !(message.nBytes && $util.isInteger(message.nBytes.low) && $util.isInteger(message.nBytes.high)))
                    return "nBytes: integer|Long expected";
            if (message.key != null && message.hasOwnProperty("key"))
                if (!$util.isString(message.key))
                    return "key: string expected";
            if (message.localKey != null && message.hasOwnProperty("localKey"))
                if (!$util.isString(message.localKey))
                    return "localKey: string expected";
            return null;
        };

        /**
         * Creates an UploadProgress message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sfiles.UploadProgress
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sfiles.UploadProgress} UploadProgress
         */
        UploadProgress.fromObject = function fromObject(object) {
            if (object instanceof $root.sfiles.UploadProgress)
                return object;
            let message = new $root.sfiles.UploadProgress();
            if (object.nBytes != null)
                if ($util.Long)
                    (message.nBytes = $util.Long.fromValue(object.nBytes)).unsigned = false;
                else if (typeof object.nBytes === "string")
                    message.nBytes = parseInt(object.nBytes, 10);
                else if (typeof object.nBytes === "number")
                    message.nBytes = object.nBytes;
                else if (typeof object.nBytes === "object")
                    message.nBytes = new $util.LongBits(object.nBytes.low >>> 0, object.nBytes.high >>> 0).toNumber();
            if (object.key != null)
                message.key = String(object.key);
            if (object.localKey != null)
                message.localKey = String(object.localKey);
            return message;
        };

        /**
         * Creates a plain object from an UploadProgress message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sfiles.UploadProgress
         * @static
         * @param {sfiles.UploadProgress} message UploadProgress
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UploadProgress.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.nBytes = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.nBytes = options.longs === String ? "0" : 0;
                object.key = "";
                object.localKey = "";
            }
            if (message.nBytes != null && message.hasOwnProperty("nBytes"))
                if (typeof message.nBytes === "number")
                    object.nBytes = options.longs === String ? String(message.nBytes) : message.nBytes;
                else
                    object.nBytes = options.longs === String ? $util.Long.prototype.toString.call(message.nBytes) : options.longs === Number ? new $util.LongBits(message.nBytes.low >>> 0, message.nBytes.high >>> 0).toNumber() : message.nBytes;
            if (message.key != null && message.hasOwnProperty("key"))
                object.key = message.key;
            if (message.localKey != null && message.hasOwnProperty("localKey"))
                object.localKey = message.localKey;
            return object;
        };

        /**
         * Converts this UploadProgress to JSON.
         * @function toJSON
         * @memberof sfiles.UploadProgress
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UploadProgress.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UploadProgress
         * @function getTypeUrl
         * @memberof sfiles.UploadProgress
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UploadProgress.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/sfiles.UploadProgress";
        };

        return UploadProgress;
    })();

    sfiles.UploadDone = (function() {

        /**
         * Properties of an UploadDone.
         * @memberof sfiles
         * @interface IUploadDone
         * @property {string|null} [key] UploadDone key
         * @property {sfiles.IUploadedFile|null} [file] UploadDone file
         */

        /**
         * Constructs a new UploadDone.
         * @memberof sfiles
         * @classdesc Represents an UploadDone.
         * @implements IUploadDone
         * @constructor
         * @param {sfiles.IUploadDone=} [properties] Properties to set
         */
        function UploadDone(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UploadDone key.
         * @member {string} key
         * @memberof sfiles.UploadDone
         * @instance
         */
        UploadDone.prototype.key = "";

        /**
         * UploadDone file.
         * @member {sfiles.IUploadedFile|null|undefined} file
         * @memberof sfiles.UploadDone
         * @instance
         */
        UploadDone.prototype.file = null;

        /**
         * Creates a new UploadDone instance using the specified properties.
         * @function create
         * @memberof sfiles.UploadDone
         * @static
         * @param {sfiles.IUploadDone=} [properties] Properties to set
         * @returns {sfiles.UploadDone} UploadDone instance
         */
        UploadDone.create = function create(properties) {
            return new UploadDone(properties);
        };

        /**
         * Encodes the specified UploadDone message. Does not implicitly {@link sfiles.UploadDone.verify|verify} messages.
         * @function encode
         * @memberof sfiles.UploadDone
         * @static
         * @param {sfiles.IUploadDone} message UploadDone message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadDone.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.key);
            if (message.file != null && Object.hasOwnProperty.call(message, "file"))
                $root.sfiles.UploadedFile.encode(message.file, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UploadDone message, length delimited. Does not implicitly {@link sfiles.UploadDone.verify|verify} messages.
         * @function encodeDelimited
         * @memberof sfiles.UploadDone
         * @static
         * @param {sfiles.IUploadDone} message UploadDone message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadDone.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UploadDone message from the specified reader or buffer.
         * @function decode
         * @memberof sfiles.UploadDone
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sfiles.UploadDone} UploadDone
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadDone.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.sfiles.UploadDone();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.key = reader.string();
                        break;
                    }
                case 2: {
                        message.file = $root.sfiles.UploadedFile.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UploadDone message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof sfiles.UploadDone
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {sfiles.UploadDone} UploadDone
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadDone.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UploadDone message.
         * @function verify
         * @memberof sfiles.UploadDone
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UploadDone.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.key != null && message.hasOwnProperty("key"))
                if (!$util.isString(message.key))
                    return "key: string expected";
            if (message.file != null && message.hasOwnProperty("file")) {
                let error = $root.sfiles.UploadedFile.verify(message.file);
                if (error)
                    return "file." + error;
            }
            return null;
        };

        /**
         * Creates an UploadDone message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sfiles.UploadDone
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sfiles.UploadDone} UploadDone
         */
        UploadDone.fromObject = function fromObject(object) {
            if (object instanceof $root.sfiles.UploadDone)
                return object;
            let message = new $root.sfiles.UploadDone();
            if (object.key != null)
                message.key = String(object.key);
            if (object.file != null) {
                if (typeof object.file !== "object")
                    throw TypeError(".sfiles.UploadDone.file: object expected");
                message.file = $root.sfiles.UploadedFile.fromObject(object.file);
            }
            return message;
        };

        /**
         * Creates a plain object from an UploadDone message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sfiles.UploadDone
         * @static
         * @param {sfiles.UploadDone} message UploadDone
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UploadDone.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.key = "";
                object.file = null;
            }
            if (message.key != null && message.hasOwnProperty("key"))
                object.key = message.key;
            if (message.file != null && message.hasOwnProperty("file"))
                object.file = $root.sfiles.UploadedFile.toObject(message.file, options);
            return object;
        };

        /**
         * Converts this UploadDone to JSON.
         * @function toJSON
         * @memberof sfiles.UploadDone
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UploadDone.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UploadDone
         * @function getTypeUrl
         * @memberof sfiles.UploadDone
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UploadDone.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/sfiles.UploadDone";
        };

        return UploadDone;
    })();

    sfiles.UploadedFile = (function() {

        /**
         * Properties of an UploadedFile.
         * @memberof sfiles
         * @interface IUploadedFile
         * @property {string|null} [localKey] UploadedFile localKey
         * @property {string|null} [url] UploadedFile url
         * @property {string|null} [id] UploadedFile id
         * @property {string|null} [mime] UploadedFile mime
         * @property {string|null} [thumbUrl] UploadedFile thumbUrl
         * @property {string|null} [name] UploadedFile name
         */

        /**
         * Constructs a new UploadedFile.
         * @memberof sfiles
         * @classdesc Represents an UploadedFile.
         * @implements IUploadedFile
         * @constructor
         * @param {sfiles.IUploadedFile=} [properties] Properties to set
         */
        function UploadedFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UploadedFile localKey.
         * @member {string} localKey
         * @memberof sfiles.UploadedFile
         * @instance
         */
        UploadedFile.prototype.localKey = "";

        /**
         * UploadedFile url.
         * @member {string} url
         * @memberof sfiles.UploadedFile
         * @instance
         */
        UploadedFile.prototype.url = "";

        /**
         * UploadedFile id.
         * @member {string} id
         * @memberof sfiles.UploadedFile
         * @instance
         */
        UploadedFile.prototype.id = "";

        /**
         * UploadedFile mime.
         * @member {string} mime
         * @memberof sfiles.UploadedFile
         * @instance
         */
        UploadedFile.prototype.mime = "";

        /**
         * UploadedFile thumbUrl.
         * @member {string} thumbUrl
         * @memberof sfiles.UploadedFile
         * @instance
         */
        UploadedFile.prototype.thumbUrl = "";

        /**
         * UploadedFile name.
         * @member {string} name
         * @memberof sfiles.UploadedFile
         * @instance
         */
        UploadedFile.prototype.name = "";

        /**
         * Creates a new UploadedFile instance using the specified properties.
         * @function create
         * @memberof sfiles.UploadedFile
         * @static
         * @param {sfiles.IUploadedFile=} [properties] Properties to set
         * @returns {sfiles.UploadedFile} UploadedFile instance
         */
        UploadedFile.create = function create(properties) {
            return new UploadedFile(properties);
        };

        /**
         * Encodes the specified UploadedFile message. Does not implicitly {@link sfiles.UploadedFile.verify|verify} messages.
         * @function encode
         * @memberof sfiles.UploadedFile
         * @static
         * @param {sfiles.IUploadedFile} message UploadedFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadedFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.localKey != null && Object.hasOwnProperty.call(message, "localKey"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.localKey);
            if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.url);
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.id);
            if (message.mime != null && Object.hasOwnProperty.call(message, "mime"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.mime);
            if (message.thumbUrl != null && Object.hasOwnProperty.call(message, "thumbUrl"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.thumbUrl);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.name);
            return writer;
        };

        /**
         * Encodes the specified UploadedFile message, length delimited. Does not implicitly {@link sfiles.UploadedFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof sfiles.UploadedFile
         * @static
         * @param {sfiles.IUploadedFile} message UploadedFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadedFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UploadedFile message from the specified reader or buffer.
         * @function decode
         * @memberof sfiles.UploadedFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sfiles.UploadedFile} UploadedFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadedFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.sfiles.UploadedFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.localKey = reader.string();
                        break;
                    }
                case 2: {
                        message.url = reader.string();
                        break;
                    }
                case 3: {
                        message.id = reader.string();
                        break;
                    }
                case 4: {
                        message.mime = reader.string();
                        break;
                    }
                case 5: {
                        message.thumbUrl = reader.string();
                        break;
                    }
                case 6: {
                        message.name = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UploadedFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof sfiles.UploadedFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {sfiles.UploadedFile} UploadedFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadedFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UploadedFile message.
         * @function verify
         * @memberof sfiles.UploadedFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UploadedFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.localKey != null && message.hasOwnProperty("localKey"))
                if (!$util.isString(message.localKey))
                    return "localKey: string expected";
            if (message.url != null && message.hasOwnProperty("url"))
                if (!$util.isString(message.url))
                    return "url: string expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.mime != null && message.hasOwnProperty("mime"))
                if (!$util.isString(message.mime))
                    return "mime: string expected";
            if (message.thumbUrl != null && message.hasOwnProperty("thumbUrl"))
                if (!$util.isString(message.thumbUrl))
                    return "thumbUrl: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            return null;
        };

        /**
         * Creates an UploadedFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sfiles.UploadedFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sfiles.UploadedFile} UploadedFile
         */
        UploadedFile.fromObject = function fromObject(object) {
            if (object instanceof $root.sfiles.UploadedFile)
                return object;
            let message = new $root.sfiles.UploadedFile();
            if (object.localKey != null)
                message.localKey = String(object.localKey);
            if (object.url != null)
                message.url = String(object.url);
            if (object.id != null)
                message.id = String(object.id);
            if (object.mime != null)
                message.mime = String(object.mime);
            if (object.thumbUrl != null)
                message.thumbUrl = String(object.thumbUrl);
            if (object.name != null)
                message.name = String(object.name);
            return message;
        };

        /**
         * Creates a plain object from an UploadedFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sfiles.UploadedFile
         * @static
         * @param {sfiles.UploadedFile} message UploadedFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UploadedFile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.localKey = "";
                object.url = "";
                object.id = "";
                object.mime = "";
                object.thumbUrl = "";
                object.name = "";
            }
            if (message.localKey != null && message.hasOwnProperty("localKey"))
                object.localKey = message.localKey;
            if (message.url != null && message.hasOwnProperty("url"))
                object.url = message.url;
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.mime != null && message.hasOwnProperty("mime"))
                object.mime = message.mime;
            if (message.thumbUrl != null && message.hasOwnProperty("thumbUrl"))
                object.thumbUrl = message.thumbUrl;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            return object;
        };

        /**
         * Converts this UploadedFile to JSON.
         * @function toJSON
         * @memberof sfiles.UploadedFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UploadedFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UploadedFile
         * @function getTypeUrl
         * @memberof sfiles.UploadedFile
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UploadedFile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/sfiles.UploadedFile";
        };

        return UploadedFile;
    })();

    sfiles.UploadStart = (function() {

        /**
         * Properties of an UploadStart.
         * @memberof sfiles
         * @interface IUploadStart
         * @property {string|null} [localKey] UploadStart localKey
         * @property {string|null} [extension] UploadStart extension
         * @property {string|null} [name] UploadStart name
         * @property {string|null} [mime] UploadStart mime
         */

        /**
         * Constructs a new UploadStart.
         * @memberof sfiles
         * @classdesc Represents an UploadStart.
         * @implements IUploadStart
         * @constructor
         * @param {sfiles.IUploadStart=} [properties] Properties to set
         */
        function UploadStart(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UploadStart localKey.
         * @member {string} localKey
         * @memberof sfiles.UploadStart
         * @instance
         */
        UploadStart.prototype.localKey = "";

        /**
         * UploadStart extension.
         * @member {string} extension
         * @memberof sfiles.UploadStart
         * @instance
         */
        UploadStart.prototype.extension = "";

        /**
         * UploadStart name.
         * @member {string} name
         * @memberof sfiles.UploadStart
         * @instance
         */
        UploadStart.prototype.name = "";

        /**
         * UploadStart mime.
         * @member {string} mime
         * @memberof sfiles.UploadStart
         * @instance
         */
        UploadStart.prototype.mime = "";

        /**
         * Creates a new UploadStart instance using the specified properties.
         * @function create
         * @memberof sfiles.UploadStart
         * @static
         * @param {sfiles.IUploadStart=} [properties] Properties to set
         * @returns {sfiles.UploadStart} UploadStart instance
         */
        UploadStart.create = function create(properties) {
            return new UploadStart(properties);
        };

        /**
         * Encodes the specified UploadStart message. Does not implicitly {@link sfiles.UploadStart.verify|verify} messages.
         * @function encode
         * @memberof sfiles.UploadStart
         * @static
         * @param {sfiles.IUploadStart} message UploadStart message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadStart.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.localKey != null && Object.hasOwnProperty.call(message, "localKey"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.localKey);
            if (message.extension != null && Object.hasOwnProperty.call(message, "extension"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.extension);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
            if (message.mime != null && Object.hasOwnProperty.call(message, "mime"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.mime);
            return writer;
        };

        /**
         * Encodes the specified UploadStart message, length delimited. Does not implicitly {@link sfiles.UploadStart.verify|verify} messages.
         * @function encodeDelimited
         * @memberof sfiles.UploadStart
         * @static
         * @param {sfiles.IUploadStart} message UploadStart message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadStart.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UploadStart message from the specified reader or buffer.
         * @function decode
         * @memberof sfiles.UploadStart
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sfiles.UploadStart} UploadStart
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadStart.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.sfiles.UploadStart();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.localKey = reader.string();
                        break;
                    }
                case 2: {
                        message.extension = reader.string();
                        break;
                    }
                case 3: {
                        message.name = reader.string();
                        break;
                    }
                case 4: {
                        message.mime = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UploadStart message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof sfiles.UploadStart
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {sfiles.UploadStart} UploadStart
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadStart.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UploadStart message.
         * @function verify
         * @memberof sfiles.UploadStart
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UploadStart.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.localKey != null && message.hasOwnProperty("localKey"))
                if (!$util.isString(message.localKey))
                    return "localKey: string expected";
            if (message.extension != null && message.hasOwnProperty("extension"))
                if (!$util.isString(message.extension))
                    return "extension: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.mime != null && message.hasOwnProperty("mime"))
                if (!$util.isString(message.mime))
                    return "mime: string expected";
            return null;
        };

        /**
         * Creates an UploadStart message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sfiles.UploadStart
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sfiles.UploadStart} UploadStart
         */
        UploadStart.fromObject = function fromObject(object) {
            if (object instanceof $root.sfiles.UploadStart)
                return object;
            let message = new $root.sfiles.UploadStart();
            if (object.localKey != null)
                message.localKey = String(object.localKey);
            if (object.extension != null)
                message.extension = String(object.extension);
            if (object.name != null)
                message.name = String(object.name);
            if (object.mime != null)
                message.mime = String(object.mime);
            return message;
        };

        /**
         * Creates a plain object from an UploadStart message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sfiles.UploadStart
         * @static
         * @param {sfiles.UploadStart} message UploadStart
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UploadStart.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.localKey = "";
                object.extension = "";
                object.name = "";
                object.mime = "";
            }
            if (message.localKey != null && message.hasOwnProperty("localKey"))
                object.localKey = message.localKey;
            if (message.extension != null && message.hasOwnProperty("extension"))
                object.extension = message.extension;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.mime != null && message.hasOwnProperty("mime"))
                object.mime = message.mime;
            return object;
        };

        /**
         * Converts this UploadStart to JSON.
         * @function toJSON
         * @memberof sfiles.UploadStart
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UploadStart.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UploadStart
         * @function getTypeUrl
         * @memberof sfiles.UploadStart
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UploadStart.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/sfiles.UploadStart";
        };

        return UploadStart;
    })();

    sfiles.DeleteFile = (function() {

        /**
         * Properties of a DeleteFile.
         * @memberof sfiles
         * @interface IDeleteFile
         * @property {sfiles.IUploadedFile|null} [file] DeleteFile file
         */

        /**
         * Constructs a new DeleteFile.
         * @memberof sfiles
         * @classdesc Represents a DeleteFile.
         * @implements IDeleteFile
         * @constructor
         * @param {sfiles.IDeleteFile=} [properties] Properties to set
         */
        function DeleteFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DeleteFile file.
         * @member {sfiles.IUploadedFile|null|undefined} file
         * @memberof sfiles.DeleteFile
         * @instance
         */
        DeleteFile.prototype.file = null;

        /**
         * Creates a new DeleteFile instance using the specified properties.
         * @function create
         * @memberof sfiles.DeleteFile
         * @static
         * @param {sfiles.IDeleteFile=} [properties] Properties to set
         * @returns {sfiles.DeleteFile} DeleteFile instance
         */
        DeleteFile.create = function create(properties) {
            return new DeleteFile(properties);
        };

        /**
         * Encodes the specified DeleteFile message. Does not implicitly {@link sfiles.DeleteFile.verify|verify} messages.
         * @function encode
         * @memberof sfiles.DeleteFile
         * @static
         * @param {sfiles.IDeleteFile} message DeleteFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.file != null && Object.hasOwnProperty.call(message, "file"))
                $root.sfiles.UploadedFile.encode(message.file, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified DeleteFile message, length delimited. Does not implicitly {@link sfiles.DeleteFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof sfiles.DeleteFile
         * @static
         * @param {sfiles.IDeleteFile} message DeleteFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeleteFile message from the specified reader or buffer.
         * @function decode
         * @memberof sfiles.DeleteFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sfiles.DeleteFile} DeleteFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.sfiles.DeleteFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.file = $root.sfiles.UploadedFile.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeleteFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof sfiles.DeleteFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {sfiles.DeleteFile} DeleteFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeleteFile message.
         * @function verify
         * @memberof sfiles.DeleteFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeleteFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.file != null && message.hasOwnProperty("file")) {
                let error = $root.sfiles.UploadedFile.verify(message.file);
                if (error)
                    return "file." + error;
            }
            return null;
        };

        /**
         * Creates a DeleteFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sfiles.DeleteFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sfiles.DeleteFile} DeleteFile
         */
        DeleteFile.fromObject = function fromObject(object) {
            if (object instanceof $root.sfiles.DeleteFile)
                return object;
            let message = new $root.sfiles.DeleteFile();
            if (object.file != null) {
                if (typeof object.file !== "object")
                    throw TypeError(".sfiles.DeleteFile.file: object expected");
                message.file = $root.sfiles.UploadedFile.fromObject(object.file);
            }
            return message;
        };

        /**
         * Creates a plain object from a DeleteFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sfiles.DeleteFile
         * @static
         * @param {sfiles.DeleteFile} message DeleteFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteFile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.file = null;
            if (message.file != null && message.hasOwnProperty("file"))
                object.file = $root.sfiles.UploadedFile.toObject(message.file, options);
            return object;
        };

        /**
         * Converts this DeleteFile to JSON.
         * @function toJSON
         * @memberof sfiles.DeleteFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DeleteFile
         * @function getTypeUrl
         * @memberof sfiles.DeleteFile
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DeleteFile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/sfiles.DeleteFile";
        };

        return DeleteFile;
    })();

    sfiles.UploadEnd = (function() {

        /**
         * Properties of an UploadEnd.
         * @memberof sfiles
         * @interface IUploadEnd
         */

        /**
         * Constructs a new UploadEnd.
         * @memberof sfiles
         * @classdesc Represents an UploadEnd.
         * @implements IUploadEnd
         * @constructor
         * @param {sfiles.IUploadEnd=} [properties] Properties to set
         */
        function UploadEnd(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new UploadEnd instance using the specified properties.
         * @function create
         * @memberof sfiles.UploadEnd
         * @static
         * @param {sfiles.IUploadEnd=} [properties] Properties to set
         * @returns {sfiles.UploadEnd} UploadEnd instance
         */
        UploadEnd.create = function create(properties) {
            return new UploadEnd(properties);
        };

        /**
         * Encodes the specified UploadEnd message. Does not implicitly {@link sfiles.UploadEnd.verify|verify} messages.
         * @function encode
         * @memberof sfiles.UploadEnd
         * @static
         * @param {sfiles.IUploadEnd} message UploadEnd message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadEnd.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified UploadEnd message, length delimited. Does not implicitly {@link sfiles.UploadEnd.verify|verify} messages.
         * @function encodeDelimited
         * @memberof sfiles.UploadEnd
         * @static
         * @param {sfiles.IUploadEnd} message UploadEnd message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadEnd.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UploadEnd message from the specified reader or buffer.
         * @function decode
         * @memberof sfiles.UploadEnd
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sfiles.UploadEnd} UploadEnd
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadEnd.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.sfiles.UploadEnd();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UploadEnd message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof sfiles.UploadEnd
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {sfiles.UploadEnd} UploadEnd
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadEnd.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UploadEnd message.
         * @function verify
         * @memberof sfiles.UploadEnd
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UploadEnd.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an UploadEnd message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sfiles.UploadEnd
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sfiles.UploadEnd} UploadEnd
         */
        UploadEnd.fromObject = function fromObject(object) {
            if (object instanceof $root.sfiles.UploadEnd)
                return object;
            return new $root.sfiles.UploadEnd();
        };

        /**
         * Creates a plain object from an UploadEnd message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sfiles.UploadEnd
         * @static
         * @param {sfiles.UploadEnd} message UploadEnd
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UploadEnd.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this UploadEnd to JSON.
         * @function toJSON
         * @memberof sfiles.UploadEnd
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UploadEnd.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UploadEnd
         * @function getTypeUrl
         * @memberof sfiles.UploadEnd
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UploadEnd.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/sfiles.UploadEnd";
        };

        return UploadEnd;
    })();

    return sfiles;
})();

export const socket_api = $root.socket_api = (() => {

    /**
     * Namespace socket_api.
     * @exports socket_api
     * @namespace
     */
    const socket_api = {};

    socket_api.Ack = (function() {

        /**
         * Properties of an Ack.
         * @memberof socket_api
         * @interface IAck
         * @property {string|null} [uuid] Ack uuid
         * @property {string|null} [errorMessage] Ack errorMessage
         * @property {number|null} [errorCode] Ack errorCode
         */

        /**
         * Constructs a new Ack.
         * @memberof socket_api
         * @classdesc Represents an Ack.
         * @implements IAck
         * @constructor
         * @param {socket_api.IAck=} [properties] Properties to set
         */
        function Ack(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Ack uuid.
         * @member {string} uuid
         * @memberof socket_api.Ack
         * @instance
         */
        Ack.prototype.uuid = "";

        /**
         * Ack errorMessage.
         * @member {string} errorMessage
         * @memberof socket_api.Ack
         * @instance
         */
        Ack.prototype.errorMessage = "";

        /**
         * Ack errorCode.
         * @member {number} errorCode
         * @memberof socket_api.Ack
         * @instance
         */
        Ack.prototype.errorCode = 0;

        /**
         * Creates a new Ack instance using the specified properties.
         * @function create
         * @memberof socket_api.Ack
         * @static
         * @param {socket_api.IAck=} [properties] Properties to set
         * @returns {socket_api.Ack} Ack instance
         */
        Ack.create = function create(properties) {
            return new Ack(properties);
        };

        /**
         * Encodes the specified Ack message. Does not implicitly {@link socket_api.Ack.verify|verify} messages.
         * @function encode
         * @memberof socket_api.Ack
         * @static
         * @param {socket_api.IAck} message Ack message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Ack.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.uuid != null && Object.hasOwnProperty.call(message, "uuid"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.uuid);
            if (message.errorMessage != null && Object.hasOwnProperty.call(message, "errorMessage"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.errorMessage);
            if (message.errorCode != null && Object.hasOwnProperty.call(message, "errorCode"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.errorCode);
            return writer;
        };

        /**
         * Encodes the specified Ack message, length delimited. Does not implicitly {@link socket_api.Ack.verify|verify} messages.
         * @function encodeDelimited
         * @memberof socket_api.Ack
         * @static
         * @param {socket_api.IAck} message Ack message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Ack.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an Ack message from the specified reader or buffer.
         * @function decode
         * @memberof socket_api.Ack
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {socket_api.Ack} Ack
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Ack.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.socket_api.Ack();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.uuid = reader.string();
                        break;
                    }
                case 2: {
                        message.errorMessage = reader.string();
                        break;
                    }
                case 4: {
                        message.errorCode = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an Ack message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof socket_api.Ack
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {socket_api.Ack} Ack
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Ack.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an Ack message.
         * @function verify
         * @memberof socket_api.Ack
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Ack.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.uuid != null && message.hasOwnProperty("uuid"))
                if (!$util.isString(message.uuid))
                    return "uuid: string expected";
            if (message.errorMessage != null && message.hasOwnProperty("errorMessage"))
                if (!$util.isString(message.errorMessage))
                    return "errorMessage: string expected";
            if (message.errorCode != null && message.hasOwnProperty("errorCode"))
                if (!$util.isInteger(message.errorCode))
                    return "errorCode: integer expected";
            return null;
        };

        /**
         * Creates an Ack message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof socket_api.Ack
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {socket_api.Ack} Ack
         */
        Ack.fromObject = function fromObject(object) {
            if (object instanceof $root.socket_api.Ack)
                return object;
            let message = new $root.socket_api.Ack();
            if (object.uuid != null)
                message.uuid = String(object.uuid);
            if (object.errorMessage != null)
                message.errorMessage = String(object.errorMessage);
            if (object.errorCode != null)
                message.errorCode = object.errorCode | 0;
            return message;
        };

        /**
         * Creates a plain object from an Ack message. Also converts values to other types if specified.
         * @function toObject
         * @memberof socket_api.Ack
         * @static
         * @param {socket_api.Ack} message Ack
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Ack.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.uuid = "";
                object.errorMessage = "";
                object.errorCode = 0;
            }
            if (message.uuid != null && message.hasOwnProperty("uuid"))
                object.uuid = message.uuid;
            if (message.errorMessage != null && message.hasOwnProperty("errorMessage"))
                object.errorMessage = message.errorMessage;
            if (message.errorCode != null && message.hasOwnProperty("errorCode"))
                object.errorCode = message.errorCode;
            return object;
        };

        /**
         * Converts this Ack to JSON.
         * @function toJSON
         * @memberof socket_api.Ack
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Ack.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Ack
         * @function getTypeUrl
         * @memberof socket_api.Ack
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Ack.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/socket_api.Ack";
        };

        return Ack;
    })();

    socket_api.IlolAck = (function() {

        /**
         * Properties of an IlolAck.
         * @memberof socket_api
         * @interface IIlolAck
         * @property {string|null} [sourceEventId] IlolAck sourceEventId
         */

        /**
         * Constructs a new IlolAck.
         * @memberof socket_api
         * @classdesc Represents an IlolAck.
         * @implements IIlolAck
         * @constructor
         * @param {socket_api.IIlolAck=} [properties] Properties to set
         */
        function IlolAck(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * IlolAck sourceEventId.
         * @member {string} sourceEventId
         * @memberof socket_api.IlolAck
         * @instance
         */
        IlolAck.prototype.sourceEventId = "";

        /**
         * Creates a new IlolAck instance using the specified properties.
         * @function create
         * @memberof socket_api.IlolAck
         * @static
         * @param {socket_api.IIlolAck=} [properties] Properties to set
         * @returns {socket_api.IlolAck} IlolAck instance
         */
        IlolAck.create = function create(properties) {
            return new IlolAck(properties);
        };

        /**
         * Encodes the specified IlolAck message. Does not implicitly {@link socket_api.IlolAck.verify|verify} messages.
         * @function encode
         * @memberof socket_api.IlolAck
         * @static
         * @param {socket_api.IIlolAck} message IlolAck message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        IlolAck.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sourceEventId != null && Object.hasOwnProperty.call(message, "sourceEventId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.sourceEventId);
            return writer;
        };

        /**
         * Encodes the specified IlolAck message, length delimited. Does not implicitly {@link socket_api.IlolAck.verify|verify} messages.
         * @function encodeDelimited
         * @memberof socket_api.IlolAck
         * @static
         * @param {socket_api.IIlolAck} message IlolAck message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        IlolAck.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an IlolAck message from the specified reader or buffer.
         * @function decode
         * @memberof socket_api.IlolAck
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {socket_api.IlolAck} IlolAck
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        IlolAck.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.socket_api.IlolAck();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sourceEventId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an IlolAck message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof socket_api.IlolAck
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {socket_api.IlolAck} IlolAck
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        IlolAck.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an IlolAck message.
         * @function verify
         * @memberof socket_api.IlolAck
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        IlolAck.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.sourceEventId != null && message.hasOwnProperty("sourceEventId"))
                if (!$util.isString(message.sourceEventId))
                    return "sourceEventId: string expected";
            return null;
        };

        /**
         * Creates an IlolAck message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof socket_api.IlolAck
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {socket_api.IlolAck} IlolAck
         */
        IlolAck.fromObject = function fromObject(object) {
            if (object instanceof $root.socket_api.IlolAck)
                return object;
            let message = new $root.socket_api.IlolAck();
            if (object.sourceEventId != null)
                message.sourceEventId = String(object.sourceEventId);
            return message;
        };

        /**
         * Creates a plain object from an IlolAck message. Also converts values to other types if specified.
         * @function toObject
         * @memberof socket_api.IlolAck
         * @static
         * @param {socket_api.IlolAck} message IlolAck
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        IlolAck.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.sourceEventId = "";
            if (message.sourceEventId != null && message.hasOwnProperty("sourceEventId"))
                object.sourceEventId = message.sourceEventId;
            return object;
        };

        /**
         * Converts this IlolAck to JSON.
         * @function toJSON
         * @memberof socket_api.IlolAck
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        IlolAck.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for IlolAck
         * @function getTypeUrl
         * @memberof socket_api.IlolAck
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        IlolAck.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/socket_api.IlolAck";
        };

        return IlolAck;
    })();

    socket_api.IlolError = (function() {

        /**
         * Properties of an IlolError.
         * @memberof socket_api
         * @interface IIlolError
         * @property {string|null} [sourceEventId] IlolError sourceEventId
         * @property {string|null} [reason] IlolError reason
         */

        /**
         * Constructs a new IlolError.
         * @memberof socket_api
         * @classdesc Represents an IlolError.
         * @implements IIlolError
         * @constructor
         * @param {socket_api.IIlolError=} [properties] Properties to set
         */
        function IlolError(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * IlolError sourceEventId.
         * @member {string} sourceEventId
         * @memberof socket_api.IlolError
         * @instance
         */
        IlolError.prototype.sourceEventId = "";

        /**
         * IlolError reason.
         * @member {string} reason
         * @memberof socket_api.IlolError
         * @instance
         */
        IlolError.prototype.reason = "";

        /**
         * Creates a new IlolError instance using the specified properties.
         * @function create
         * @memberof socket_api.IlolError
         * @static
         * @param {socket_api.IIlolError=} [properties] Properties to set
         * @returns {socket_api.IlolError} IlolError instance
         */
        IlolError.create = function create(properties) {
            return new IlolError(properties);
        };

        /**
         * Encodes the specified IlolError message. Does not implicitly {@link socket_api.IlolError.verify|verify} messages.
         * @function encode
         * @memberof socket_api.IlolError
         * @static
         * @param {socket_api.IIlolError} message IlolError message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        IlolError.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sourceEventId != null && Object.hasOwnProperty.call(message, "sourceEventId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.sourceEventId);
            if (message.reason != null && Object.hasOwnProperty.call(message, "reason"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.reason);
            return writer;
        };

        /**
         * Encodes the specified IlolError message, length delimited. Does not implicitly {@link socket_api.IlolError.verify|verify} messages.
         * @function encodeDelimited
         * @memberof socket_api.IlolError
         * @static
         * @param {socket_api.IIlolError} message IlolError message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        IlolError.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an IlolError message from the specified reader or buffer.
         * @function decode
         * @memberof socket_api.IlolError
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {socket_api.IlolError} IlolError
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        IlolError.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.socket_api.IlolError();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sourceEventId = reader.string();
                        break;
                    }
                case 2: {
                        message.reason = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an IlolError message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof socket_api.IlolError
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {socket_api.IlolError} IlolError
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        IlolError.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an IlolError message.
         * @function verify
         * @memberof socket_api.IlolError
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        IlolError.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.sourceEventId != null && message.hasOwnProperty("sourceEventId"))
                if (!$util.isString(message.sourceEventId))
                    return "sourceEventId: string expected";
            if (message.reason != null && message.hasOwnProperty("reason"))
                if (!$util.isString(message.reason))
                    return "reason: string expected";
            return null;
        };

        /**
         * Creates an IlolError message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof socket_api.IlolError
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {socket_api.IlolError} IlolError
         */
        IlolError.fromObject = function fromObject(object) {
            if (object instanceof $root.socket_api.IlolError)
                return object;
            let message = new $root.socket_api.IlolError();
            if (object.sourceEventId != null)
                message.sourceEventId = String(object.sourceEventId);
            if (object.reason != null)
                message.reason = String(object.reason);
            return message;
        };

        /**
         * Creates a plain object from an IlolError message. Also converts values to other types if specified.
         * @function toObject
         * @memberof socket_api.IlolError
         * @static
         * @param {socket_api.IlolError} message IlolError
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        IlolError.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.sourceEventId = "";
                object.reason = "";
            }
            if (message.sourceEventId != null && message.hasOwnProperty("sourceEventId"))
                object.sourceEventId = message.sourceEventId;
            if (message.reason != null && message.hasOwnProperty("reason"))
                object.reason = message.reason;
            return object;
        };

        /**
         * Converts this IlolError to JSON.
         * @function toJSON
         * @memberof socket_api.IlolError
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        IlolError.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for IlolError
         * @function getTypeUrl
         * @memberof socket_api.IlolError
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        IlolError.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/socket_api.IlolError";
        };

        return IlolError;
    })();

    socket_api.AsyncProgress = (function() {

        /**
         * Properties of an AsyncProgress.
         * @memberof socket_api
         * @interface IAsyncProgress
         * @property {string|null} [key] AsyncProgress key
         * @property {number|null} [progress] AsyncProgress progress
         * @property {string|null} [info] AsyncProgress info
         * @property {boolean|null} [done] AsyncProgress done
         * @property {string|null} [errorMessage] AsyncProgress errorMessage
         * @property {number|null} [errorCode] AsyncProgress errorCode
         */

        /**
         * Constructs a new AsyncProgress.
         * @memberof socket_api
         * @classdesc Represents an AsyncProgress.
         * @implements IAsyncProgress
         * @constructor
         * @param {socket_api.IAsyncProgress=} [properties] Properties to set
         */
        function AsyncProgress(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AsyncProgress key.
         * @member {string} key
         * @memberof socket_api.AsyncProgress
         * @instance
         */
        AsyncProgress.prototype.key = "";

        /**
         * AsyncProgress progress.
         * @member {number} progress
         * @memberof socket_api.AsyncProgress
         * @instance
         */
        AsyncProgress.prototype.progress = 0;

        /**
         * AsyncProgress info.
         * @member {string} info
         * @memberof socket_api.AsyncProgress
         * @instance
         */
        AsyncProgress.prototype.info = "";

        /**
         * AsyncProgress done.
         * @member {boolean} done
         * @memberof socket_api.AsyncProgress
         * @instance
         */
        AsyncProgress.prototype.done = false;

        /**
         * AsyncProgress errorMessage.
         * @member {string} errorMessage
         * @memberof socket_api.AsyncProgress
         * @instance
         */
        AsyncProgress.prototype.errorMessage = "";

        /**
         * AsyncProgress errorCode.
         * @member {number} errorCode
         * @memberof socket_api.AsyncProgress
         * @instance
         */
        AsyncProgress.prototype.errorCode = 0;

        /**
         * Creates a new AsyncProgress instance using the specified properties.
         * @function create
         * @memberof socket_api.AsyncProgress
         * @static
         * @param {socket_api.IAsyncProgress=} [properties] Properties to set
         * @returns {socket_api.AsyncProgress} AsyncProgress instance
         */
        AsyncProgress.create = function create(properties) {
            return new AsyncProgress(properties);
        };

        /**
         * Encodes the specified AsyncProgress message. Does not implicitly {@link socket_api.AsyncProgress.verify|verify} messages.
         * @function encode
         * @memberof socket_api.AsyncProgress
         * @static
         * @param {socket_api.IAsyncProgress} message AsyncProgress message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsyncProgress.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.key);
            if (message.progress != null && Object.hasOwnProperty.call(message, "progress"))
                writer.uint32(/* id 2, wireType 1 =*/17).double(message.progress);
            if (message.info != null && Object.hasOwnProperty.call(message, "info"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.info);
            if (message.done != null && Object.hasOwnProperty.call(message, "done"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.done);
            if (message.errorMessage != null && Object.hasOwnProperty.call(message, "errorMessage"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.errorMessage);
            if (message.errorCode != null && Object.hasOwnProperty.call(message, "errorCode"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.errorCode);
            return writer;
        };

        /**
         * Encodes the specified AsyncProgress message, length delimited. Does not implicitly {@link socket_api.AsyncProgress.verify|verify} messages.
         * @function encodeDelimited
         * @memberof socket_api.AsyncProgress
         * @static
         * @param {socket_api.IAsyncProgress} message AsyncProgress message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsyncProgress.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AsyncProgress message from the specified reader or buffer.
         * @function decode
         * @memberof socket_api.AsyncProgress
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {socket_api.AsyncProgress} AsyncProgress
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsyncProgress.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.socket_api.AsyncProgress();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.key = reader.string();
                        break;
                    }
                case 2: {
                        message.progress = reader.double();
                        break;
                    }
                case 3: {
                        message.info = reader.string();
                        break;
                    }
                case 4: {
                        message.done = reader.bool();
                        break;
                    }
                case 5: {
                        message.errorMessage = reader.string();
                        break;
                    }
                case 6: {
                        message.errorCode = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AsyncProgress message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof socket_api.AsyncProgress
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {socket_api.AsyncProgress} AsyncProgress
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsyncProgress.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AsyncProgress message.
         * @function verify
         * @memberof socket_api.AsyncProgress
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AsyncProgress.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.key != null && message.hasOwnProperty("key"))
                if (!$util.isString(message.key))
                    return "key: string expected";
            if (message.progress != null && message.hasOwnProperty("progress"))
                if (typeof message.progress !== "number")
                    return "progress: number expected";
            if (message.info != null && message.hasOwnProperty("info"))
                if (!$util.isString(message.info))
                    return "info: string expected";
            if (message.done != null && message.hasOwnProperty("done"))
                if (typeof message.done !== "boolean")
                    return "done: boolean expected";
            if (message.errorMessage != null && message.hasOwnProperty("errorMessage"))
                if (!$util.isString(message.errorMessage))
                    return "errorMessage: string expected";
            if (message.errorCode != null && message.hasOwnProperty("errorCode"))
                if (!$util.isInteger(message.errorCode))
                    return "errorCode: integer expected";
            return null;
        };

        /**
         * Creates an AsyncProgress message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof socket_api.AsyncProgress
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {socket_api.AsyncProgress} AsyncProgress
         */
        AsyncProgress.fromObject = function fromObject(object) {
            if (object instanceof $root.socket_api.AsyncProgress)
                return object;
            let message = new $root.socket_api.AsyncProgress();
            if (object.key != null)
                message.key = String(object.key);
            if (object.progress != null)
                message.progress = Number(object.progress);
            if (object.info != null)
                message.info = String(object.info);
            if (object.done != null)
                message.done = Boolean(object.done);
            if (object.errorMessage != null)
                message.errorMessage = String(object.errorMessage);
            if (object.errorCode != null)
                message.errorCode = object.errorCode | 0;
            return message;
        };

        /**
         * Creates a plain object from an AsyncProgress message. Also converts values to other types if specified.
         * @function toObject
         * @memberof socket_api.AsyncProgress
         * @static
         * @param {socket_api.AsyncProgress} message AsyncProgress
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AsyncProgress.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.key = "";
                object.progress = 0;
                object.info = "";
                object.done = false;
                object.errorMessage = "";
                object.errorCode = 0;
            }
            if (message.key != null && message.hasOwnProperty("key"))
                object.key = message.key;
            if (message.progress != null && message.hasOwnProperty("progress"))
                object.progress = options.json && !isFinite(message.progress) ? String(message.progress) : message.progress;
            if (message.info != null && message.hasOwnProperty("info"))
                object.info = message.info;
            if (message.done != null && message.hasOwnProperty("done"))
                object.done = message.done;
            if (message.errorMessage != null && message.hasOwnProperty("errorMessage"))
                object.errorMessage = message.errorMessage;
            if (message.errorCode != null && message.hasOwnProperty("errorCode"))
                object.errorCode = message.errorCode;
            return object;
        };

        /**
         * Converts this AsyncProgress to JSON.
         * @function toJSON
         * @memberof socket_api.AsyncProgress
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AsyncProgress.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AsyncProgress
         * @function getTypeUrl
         * @memberof socket_api.AsyncProgress
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AsyncProgress.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/socket_api.AsyncProgress";
        };

        return AsyncProgress;
    })();

    socket_api.UpgradeApiVersion = (function() {

        /**
         * Properties of an UpgradeApiVersion.
         * @memberof socket_api
         * @interface IUpgradeApiVersion
         * @property {number|null} [latest] UpgradeApiVersion latest
         */

        /**
         * Constructs a new UpgradeApiVersion.
         * @memberof socket_api
         * @classdesc Represents an UpgradeApiVersion.
         * @implements IUpgradeApiVersion
         * @constructor
         * @param {socket_api.IUpgradeApiVersion=} [properties] Properties to set
         */
        function UpgradeApiVersion(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpgradeApiVersion latest.
         * @member {number} latest
         * @memberof socket_api.UpgradeApiVersion
         * @instance
         */
        UpgradeApiVersion.prototype.latest = 0;

        /**
         * Creates a new UpgradeApiVersion instance using the specified properties.
         * @function create
         * @memberof socket_api.UpgradeApiVersion
         * @static
         * @param {socket_api.IUpgradeApiVersion=} [properties] Properties to set
         * @returns {socket_api.UpgradeApiVersion} UpgradeApiVersion instance
         */
        UpgradeApiVersion.create = function create(properties) {
            return new UpgradeApiVersion(properties);
        };

        /**
         * Encodes the specified UpgradeApiVersion message. Does not implicitly {@link socket_api.UpgradeApiVersion.verify|verify} messages.
         * @function encode
         * @memberof socket_api.UpgradeApiVersion
         * @static
         * @param {socket_api.IUpgradeApiVersion} message UpgradeApiVersion message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpgradeApiVersion.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.latest != null && Object.hasOwnProperty.call(message, "latest"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.latest);
            return writer;
        };

        /**
         * Encodes the specified UpgradeApiVersion message, length delimited. Does not implicitly {@link socket_api.UpgradeApiVersion.verify|verify} messages.
         * @function encodeDelimited
         * @memberof socket_api.UpgradeApiVersion
         * @static
         * @param {socket_api.IUpgradeApiVersion} message UpgradeApiVersion message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpgradeApiVersion.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpgradeApiVersion message from the specified reader or buffer.
         * @function decode
         * @memberof socket_api.UpgradeApiVersion
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {socket_api.UpgradeApiVersion} UpgradeApiVersion
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpgradeApiVersion.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.socket_api.UpgradeApiVersion();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.latest = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpgradeApiVersion message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof socket_api.UpgradeApiVersion
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {socket_api.UpgradeApiVersion} UpgradeApiVersion
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpgradeApiVersion.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpgradeApiVersion message.
         * @function verify
         * @memberof socket_api.UpgradeApiVersion
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpgradeApiVersion.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.latest != null && message.hasOwnProperty("latest"))
                if (!$util.isInteger(message.latest))
                    return "latest: integer expected";
            return null;
        };

        /**
         * Creates an UpgradeApiVersion message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof socket_api.UpgradeApiVersion
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {socket_api.UpgradeApiVersion} UpgradeApiVersion
         */
        UpgradeApiVersion.fromObject = function fromObject(object) {
            if (object instanceof $root.socket_api.UpgradeApiVersion)
                return object;
            let message = new $root.socket_api.UpgradeApiVersion();
            if (object.latest != null)
                message.latest = object.latest | 0;
            return message;
        };

        /**
         * Creates a plain object from an UpgradeApiVersion message. Also converts values to other types if specified.
         * @function toObject
         * @memberof socket_api.UpgradeApiVersion
         * @static
         * @param {socket_api.UpgradeApiVersion} message UpgradeApiVersion
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpgradeApiVersion.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.latest = 0;
            if (message.latest != null && message.hasOwnProperty("latest"))
                object.latest = message.latest;
            return object;
        };

        /**
         * Converts this UpgradeApiVersion to JSON.
         * @function toJSON
         * @memberof socket_api.UpgradeApiVersion
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpgradeApiVersion.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UpgradeApiVersion
         * @function getTypeUrl
         * @memberof socket_api.UpgradeApiVersion
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UpgradeApiVersion.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/socket_api.UpgradeApiVersion";
        };

        return UpgradeApiVersion;
    })();

    /**
     * AckErrorCode enum.
     * @name socket_api.AckErrorCode
     * @enum {number}
     * @property {number} error_code_none=0 error_code_none value
     * @property {number} error_code_unauthorized=401 error_code_unauthorized value
     */
    socket_api.AckErrorCode = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "error_code_none"] = 0;
        values[valuesById[401] = "error_code_unauthorized"] = 401;
        return values;
    })();

    return socket_api;
})();

export const authentication = $root.authentication = (() => {

    /**
     * Namespace authentication.
     * @exports authentication
     * @namespace
     */
    const authentication = {};

    authentication.LoginToken = (function() {

        /**
         * Properties of a LoginToken.
         * @memberof authentication
         * @interface ILoginToken
         * @property {string|null} [token] LoginToken token
         * @property {string|null} [refresh] LoginToken refresh
         */

        /**
         * Constructs a new LoginToken.
         * @memberof authentication
         * @classdesc Represents a LoginToken.
         * @implements ILoginToken
         * @constructor
         * @param {authentication.ILoginToken=} [properties] Properties to set
         */
        function LoginToken(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LoginToken token.
         * @member {string} token
         * @memberof authentication.LoginToken
         * @instance
         */
        LoginToken.prototype.token = "";

        /**
         * LoginToken refresh.
         * @member {string} refresh
         * @memberof authentication.LoginToken
         * @instance
         */
        LoginToken.prototype.refresh = "";

        /**
         * Creates a new LoginToken instance using the specified properties.
         * @function create
         * @memberof authentication.LoginToken
         * @static
         * @param {authentication.ILoginToken=} [properties] Properties to set
         * @returns {authentication.LoginToken} LoginToken instance
         */
        LoginToken.create = function create(properties) {
            return new LoginToken(properties);
        };

        /**
         * Encodes the specified LoginToken message. Does not implicitly {@link authentication.LoginToken.verify|verify} messages.
         * @function encode
         * @memberof authentication.LoginToken
         * @static
         * @param {authentication.ILoginToken} message LoginToken message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoginToken.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.token != null && Object.hasOwnProperty.call(message, "token"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.token);
            if (message.refresh != null && Object.hasOwnProperty.call(message, "refresh"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.refresh);
            return writer;
        };

        /**
         * Encodes the specified LoginToken message, length delimited. Does not implicitly {@link authentication.LoginToken.verify|verify} messages.
         * @function encodeDelimited
         * @memberof authentication.LoginToken
         * @static
         * @param {authentication.ILoginToken} message LoginToken message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoginToken.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LoginToken message from the specified reader or buffer.
         * @function decode
         * @memberof authentication.LoginToken
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {authentication.LoginToken} LoginToken
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoginToken.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.authentication.LoginToken();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.token = reader.string();
                        break;
                    }
                case 2: {
                        message.refresh = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LoginToken message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof authentication.LoginToken
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {authentication.LoginToken} LoginToken
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoginToken.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LoginToken message.
         * @function verify
         * @memberof authentication.LoginToken
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LoginToken.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.token != null && message.hasOwnProperty("token"))
                if (!$util.isString(message.token))
                    return "token: string expected";
            if (message.refresh != null && message.hasOwnProperty("refresh"))
                if (!$util.isString(message.refresh))
                    return "refresh: string expected";
            return null;
        };

        /**
         * Creates a LoginToken message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof authentication.LoginToken
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {authentication.LoginToken} LoginToken
         */
        LoginToken.fromObject = function fromObject(object) {
            if (object instanceof $root.authentication.LoginToken)
                return object;
            let message = new $root.authentication.LoginToken();
            if (object.token != null)
                message.token = String(object.token);
            if (object.refresh != null)
                message.refresh = String(object.refresh);
            return message;
        };

        /**
         * Creates a plain object from a LoginToken message. Also converts values to other types if specified.
         * @function toObject
         * @memberof authentication.LoginToken
         * @static
         * @param {authentication.LoginToken} message LoginToken
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LoginToken.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.token = "";
                object.refresh = "";
            }
            if (message.token != null && message.hasOwnProperty("token"))
                object.token = message.token;
            if (message.refresh != null && message.hasOwnProperty("refresh"))
                object.refresh = message.refresh;
            return object;
        };

        /**
         * Converts this LoginToken to JSON.
         * @function toJSON
         * @memberof authentication.LoginToken
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LoginToken.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LoginToken
         * @function getTypeUrl
         * @memberof authentication.LoginToken
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LoginToken.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/authentication.LoginToken";
        };

        return LoginToken;
    })();

    authentication.LoginError = (function() {

        /**
         * Properties of a LoginError.
         * @memberof authentication
         * @interface ILoginError
         * @property {string|null} [errorText] LoginError errorText
         * @property {string|null} [errorCode] LoginError errorCode
         */

        /**
         * Constructs a new LoginError.
         * @memberof authentication
         * @classdesc Represents a LoginError.
         * @implements ILoginError
         * @constructor
         * @param {authentication.ILoginError=} [properties] Properties to set
         */
        function LoginError(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LoginError errorText.
         * @member {string} errorText
         * @memberof authentication.LoginError
         * @instance
         */
        LoginError.prototype.errorText = "";

        /**
         * LoginError errorCode.
         * @member {string} errorCode
         * @memberof authentication.LoginError
         * @instance
         */
        LoginError.prototype.errorCode = "";

        /**
         * Creates a new LoginError instance using the specified properties.
         * @function create
         * @memberof authentication.LoginError
         * @static
         * @param {authentication.ILoginError=} [properties] Properties to set
         * @returns {authentication.LoginError} LoginError instance
         */
        LoginError.create = function create(properties) {
            return new LoginError(properties);
        };

        /**
         * Encodes the specified LoginError message. Does not implicitly {@link authentication.LoginError.verify|verify} messages.
         * @function encode
         * @memberof authentication.LoginError
         * @static
         * @param {authentication.ILoginError} message LoginError message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoginError.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.errorText != null && Object.hasOwnProperty.call(message, "errorText"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.errorText);
            if (message.errorCode != null && Object.hasOwnProperty.call(message, "errorCode"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.errorCode);
            return writer;
        };

        /**
         * Encodes the specified LoginError message, length delimited. Does not implicitly {@link authentication.LoginError.verify|verify} messages.
         * @function encodeDelimited
         * @memberof authentication.LoginError
         * @static
         * @param {authentication.ILoginError} message LoginError message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoginError.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LoginError message from the specified reader or buffer.
         * @function decode
         * @memberof authentication.LoginError
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {authentication.LoginError} LoginError
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoginError.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.authentication.LoginError();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.errorText = reader.string();
                        break;
                    }
                case 2: {
                        message.errorCode = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LoginError message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof authentication.LoginError
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {authentication.LoginError} LoginError
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoginError.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LoginError message.
         * @function verify
         * @memberof authentication.LoginError
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LoginError.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.errorText != null && message.hasOwnProperty("errorText"))
                if (!$util.isString(message.errorText))
                    return "errorText: string expected";
            if (message.errorCode != null && message.hasOwnProperty("errorCode"))
                if (!$util.isString(message.errorCode))
                    return "errorCode: string expected";
            return null;
        };

        /**
         * Creates a LoginError message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof authentication.LoginError
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {authentication.LoginError} LoginError
         */
        LoginError.fromObject = function fromObject(object) {
            if (object instanceof $root.authentication.LoginError)
                return object;
            let message = new $root.authentication.LoginError();
            if (object.errorText != null)
                message.errorText = String(object.errorText);
            if (object.errorCode != null)
                message.errorCode = String(object.errorCode);
            return message;
        };

        /**
         * Creates a plain object from a LoginError message. Also converts values to other types if specified.
         * @function toObject
         * @memberof authentication.LoginError
         * @static
         * @param {authentication.LoginError} message LoginError
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LoginError.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.errorText = "";
                object.errorCode = "";
            }
            if (message.errorText != null && message.hasOwnProperty("errorText"))
                object.errorText = message.errorText;
            if (message.errorCode != null && message.hasOwnProperty("errorCode"))
                object.errorCode = message.errorCode;
            return object;
        };

        /**
         * Converts this LoginError to JSON.
         * @function toJSON
         * @memberof authentication.LoginError
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LoginError.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LoginError
         * @function getTypeUrl
         * @memberof authentication.LoginError
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LoginError.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/authentication.LoginError";
        };

        return LoginError;
    })();

    authentication.Login = (function() {

        /**
         * Properties of a Login.
         * @memberof authentication
         * @interface ILogin
         * @property {string|null} [username] Login username
         * @property {string|null} [password] Login password
         */

        /**
         * Constructs a new Login.
         * @memberof authentication
         * @classdesc Represents a Login.
         * @implements ILogin
         * @constructor
         * @param {authentication.ILogin=} [properties] Properties to set
         */
        function Login(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Login username.
         * @member {string} username
         * @memberof authentication.Login
         * @instance
         */
        Login.prototype.username = "";

        /**
         * Login password.
         * @member {string} password
         * @memberof authentication.Login
         * @instance
         */
        Login.prototype.password = "";

        /**
         * Creates a new Login instance using the specified properties.
         * @function create
         * @memberof authentication.Login
         * @static
         * @param {authentication.ILogin=} [properties] Properties to set
         * @returns {authentication.Login} Login instance
         */
        Login.create = function create(properties) {
            return new Login(properties);
        };

        /**
         * Encodes the specified Login message. Does not implicitly {@link authentication.Login.verify|verify} messages.
         * @function encode
         * @memberof authentication.Login
         * @static
         * @param {authentication.ILogin} message Login message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Login.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.username != null && Object.hasOwnProperty.call(message, "username"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.username);
            if (message.password != null && Object.hasOwnProperty.call(message, "password"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.password);
            return writer;
        };

        /**
         * Encodes the specified Login message, length delimited. Does not implicitly {@link authentication.Login.verify|verify} messages.
         * @function encodeDelimited
         * @memberof authentication.Login
         * @static
         * @param {authentication.ILogin} message Login message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Login.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Login message from the specified reader or buffer.
         * @function decode
         * @memberof authentication.Login
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {authentication.Login} Login
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Login.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.authentication.Login();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.username = reader.string();
                        break;
                    }
                case 2: {
                        message.password = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Login message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof authentication.Login
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {authentication.Login} Login
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Login.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Login message.
         * @function verify
         * @memberof authentication.Login
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Login.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.username != null && message.hasOwnProperty("username"))
                if (!$util.isString(message.username))
                    return "username: string expected";
            if (message.password != null && message.hasOwnProperty("password"))
                if (!$util.isString(message.password))
                    return "password: string expected";
            return null;
        };

        /**
         * Creates a Login message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof authentication.Login
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {authentication.Login} Login
         */
        Login.fromObject = function fromObject(object) {
            if (object instanceof $root.authentication.Login)
                return object;
            let message = new $root.authentication.Login();
            if (object.username != null)
                message.username = String(object.username);
            if (object.password != null)
                message.password = String(object.password);
            return message;
        };

        /**
         * Creates a plain object from a Login message. Also converts values to other types if specified.
         * @function toObject
         * @memberof authentication.Login
         * @static
         * @param {authentication.Login} message Login
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Login.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.username = "";
                object.password = "";
            }
            if (message.username != null && message.hasOwnProperty("username"))
                object.username = message.username;
            if (message.password != null && message.hasOwnProperty("password"))
                object.password = message.password;
            return object;
        };

        /**
         * Converts this Login to JSON.
         * @function toJSON
         * @memberof authentication.Login
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Login.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Login
         * @function getTypeUrl
         * @memberof authentication.Login
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Login.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/authentication.Login";
        };

        return Login;
    })();

    authentication.RefreshToken = (function() {

        /**
         * Properties of a RefreshToken.
         * @memberof authentication
         * @interface IRefreshToken
         * @property {string|null} [refreshToken] RefreshToken refreshToken
         */

        /**
         * Constructs a new RefreshToken.
         * @memberof authentication
         * @classdesc Represents a RefreshToken.
         * @implements IRefreshToken
         * @constructor
         * @param {authentication.IRefreshToken=} [properties] Properties to set
         */
        function RefreshToken(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RefreshToken refreshToken.
         * @member {string} refreshToken
         * @memberof authentication.RefreshToken
         * @instance
         */
        RefreshToken.prototype.refreshToken = "";

        /**
         * Creates a new RefreshToken instance using the specified properties.
         * @function create
         * @memberof authentication.RefreshToken
         * @static
         * @param {authentication.IRefreshToken=} [properties] Properties to set
         * @returns {authentication.RefreshToken} RefreshToken instance
         */
        RefreshToken.create = function create(properties) {
            return new RefreshToken(properties);
        };

        /**
         * Encodes the specified RefreshToken message. Does not implicitly {@link authentication.RefreshToken.verify|verify} messages.
         * @function encode
         * @memberof authentication.RefreshToken
         * @static
         * @param {authentication.IRefreshToken} message RefreshToken message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RefreshToken.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.refreshToken != null && Object.hasOwnProperty.call(message, "refreshToken"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.refreshToken);
            return writer;
        };

        /**
         * Encodes the specified RefreshToken message, length delimited. Does not implicitly {@link authentication.RefreshToken.verify|verify} messages.
         * @function encodeDelimited
         * @memberof authentication.RefreshToken
         * @static
         * @param {authentication.IRefreshToken} message RefreshToken message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RefreshToken.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RefreshToken message from the specified reader or buffer.
         * @function decode
         * @memberof authentication.RefreshToken
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {authentication.RefreshToken} RefreshToken
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RefreshToken.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.authentication.RefreshToken();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.refreshToken = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RefreshToken message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof authentication.RefreshToken
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {authentication.RefreshToken} RefreshToken
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RefreshToken.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RefreshToken message.
         * @function verify
         * @memberof authentication.RefreshToken
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RefreshToken.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.refreshToken != null && message.hasOwnProperty("refreshToken"))
                if (!$util.isString(message.refreshToken))
                    return "refreshToken: string expected";
            return null;
        };

        /**
         * Creates a RefreshToken message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof authentication.RefreshToken
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {authentication.RefreshToken} RefreshToken
         */
        RefreshToken.fromObject = function fromObject(object) {
            if (object instanceof $root.authentication.RefreshToken)
                return object;
            let message = new $root.authentication.RefreshToken();
            if (object.refreshToken != null)
                message.refreshToken = String(object.refreshToken);
            return message;
        };

        /**
         * Creates a plain object from a RefreshToken message. Also converts values to other types if specified.
         * @function toObject
         * @memberof authentication.RefreshToken
         * @static
         * @param {authentication.RefreshToken} message RefreshToken
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RefreshToken.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.refreshToken = "";
            if (message.refreshToken != null && message.hasOwnProperty("refreshToken"))
                object.refreshToken = message.refreshToken;
            return object;
        };

        /**
         * Converts this RefreshToken to JSON.
         * @function toJSON
         * @memberof authentication.RefreshToken
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RefreshToken.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RefreshToken
         * @function getTypeUrl
         * @memberof authentication.RefreshToken
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RefreshToken.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/authentication.RefreshToken";
        };

        return RefreshToken;
    })();

    authentication.VerifyToken = (function() {

        /**
         * Properties of a VerifyToken.
         * @memberof authentication
         * @interface IVerifyToken
         */

        /**
         * Constructs a new VerifyToken.
         * @memberof authentication
         * @classdesc Represents a VerifyToken.
         * @implements IVerifyToken
         * @constructor
         * @param {authentication.IVerifyToken=} [properties] Properties to set
         */
        function VerifyToken(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new VerifyToken instance using the specified properties.
         * @function create
         * @memberof authentication.VerifyToken
         * @static
         * @param {authentication.IVerifyToken=} [properties] Properties to set
         * @returns {authentication.VerifyToken} VerifyToken instance
         */
        VerifyToken.create = function create(properties) {
            return new VerifyToken(properties);
        };

        /**
         * Encodes the specified VerifyToken message. Does not implicitly {@link authentication.VerifyToken.verify|verify} messages.
         * @function encode
         * @memberof authentication.VerifyToken
         * @static
         * @param {authentication.IVerifyToken} message VerifyToken message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VerifyToken.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified VerifyToken message, length delimited. Does not implicitly {@link authentication.VerifyToken.verify|verify} messages.
         * @function encodeDelimited
         * @memberof authentication.VerifyToken
         * @static
         * @param {authentication.IVerifyToken} message VerifyToken message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VerifyToken.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a VerifyToken message from the specified reader or buffer.
         * @function decode
         * @memberof authentication.VerifyToken
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {authentication.VerifyToken} VerifyToken
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VerifyToken.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.authentication.VerifyToken();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a VerifyToken message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof authentication.VerifyToken
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {authentication.VerifyToken} VerifyToken
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VerifyToken.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a VerifyToken message.
         * @function verify
         * @memberof authentication.VerifyToken
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        VerifyToken.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a VerifyToken message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof authentication.VerifyToken
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {authentication.VerifyToken} VerifyToken
         */
        VerifyToken.fromObject = function fromObject(object) {
            if (object instanceof $root.authentication.VerifyToken)
                return object;
            return new $root.authentication.VerifyToken();
        };

        /**
         * Creates a plain object from a VerifyToken message. Also converts values to other types if specified.
         * @function toObject
         * @memberof authentication.VerifyToken
         * @static
         * @param {authentication.VerifyToken} message VerifyToken
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        VerifyToken.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this VerifyToken to JSON.
         * @function toJSON
         * @memberof authentication.VerifyToken
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        VerifyToken.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for VerifyToken
         * @function getTypeUrl
         * @memberof authentication.VerifyToken
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        VerifyToken.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/authentication.VerifyToken";
        };

        return VerifyToken;
    })();

    authentication.TokenInvalid = (function() {

        /**
         * Properties of a TokenInvalid.
         * @memberof authentication
         * @interface ITokenInvalid
         */

        /**
         * Constructs a new TokenInvalid.
         * @memberof authentication
         * @classdesc Represents a TokenInvalid.
         * @implements ITokenInvalid
         * @constructor
         * @param {authentication.ITokenInvalid=} [properties] Properties to set
         */
        function TokenInvalid(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new TokenInvalid instance using the specified properties.
         * @function create
         * @memberof authentication.TokenInvalid
         * @static
         * @param {authentication.ITokenInvalid=} [properties] Properties to set
         * @returns {authentication.TokenInvalid} TokenInvalid instance
         */
        TokenInvalid.create = function create(properties) {
            return new TokenInvalid(properties);
        };

        /**
         * Encodes the specified TokenInvalid message. Does not implicitly {@link authentication.TokenInvalid.verify|verify} messages.
         * @function encode
         * @memberof authentication.TokenInvalid
         * @static
         * @param {authentication.ITokenInvalid} message TokenInvalid message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TokenInvalid.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified TokenInvalid message, length delimited. Does not implicitly {@link authentication.TokenInvalid.verify|verify} messages.
         * @function encodeDelimited
         * @memberof authentication.TokenInvalid
         * @static
         * @param {authentication.ITokenInvalid} message TokenInvalid message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TokenInvalid.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TokenInvalid message from the specified reader or buffer.
         * @function decode
         * @memberof authentication.TokenInvalid
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {authentication.TokenInvalid} TokenInvalid
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TokenInvalid.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.authentication.TokenInvalid();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TokenInvalid message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof authentication.TokenInvalid
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {authentication.TokenInvalid} TokenInvalid
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TokenInvalid.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TokenInvalid message.
         * @function verify
         * @memberof authentication.TokenInvalid
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TokenInvalid.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a TokenInvalid message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof authentication.TokenInvalid
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {authentication.TokenInvalid} TokenInvalid
         */
        TokenInvalid.fromObject = function fromObject(object) {
            if (object instanceof $root.authentication.TokenInvalid)
                return object;
            return new $root.authentication.TokenInvalid();
        };

        /**
         * Creates a plain object from a TokenInvalid message. Also converts values to other types if specified.
         * @function toObject
         * @memberof authentication.TokenInvalid
         * @static
         * @param {authentication.TokenInvalid} message TokenInvalid
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TokenInvalid.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this TokenInvalid to JSON.
         * @function toJSON
         * @memberof authentication.TokenInvalid
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TokenInvalid.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TokenInvalid
         * @function getTypeUrl
         * @memberof authentication.TokenInvalid
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TokenInvalid.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/authentication.TokenInvalid";
        };

        return TokenInvalid;
    })();

    authentication.RefreshTokenInvalid = (function() {

        /**
         * Properties of a RefreshTokenInvalid.
         * @memberof authentication
         * @interface IRefreshTokenInvalid
         */

        /**
         * Constructs a new RefreshTokenInvalid.
         * @memberof authentication
         * @classdesc Represents a RefreshTokenInvalid.
         * @implements IRefreshTokenInvalid
         * @constructor
         * @param {authentication.IRefreshTokenInvalid=} [properties] Properties to set
         */
        function RefreshTokenInvalid(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new RefreshTokenInvalid instance using the specified properties.
         * @function create
         * @memberof authentication.RefreshTokenInvalid
         * @static
         * @param {authentication.IRefreshTokenInvalid=} [properties] Properties to set
         * @returns {authentication.RefreshTokenInvalid} RefreshTokenInvalid instance
         */
        RefreshTokenInvalid.create = function create(properties) {
            return new RefreshTokenInvalid(properties);
        };

        /**
         * Encodes the specified RefreshTokenInvalid message. Does not implicitly {@link authentication.RefreshTokenInvalid.verify|verify} messages.
         * @function encode
         * @memberof authentication.RefreshTokenInvalid
         * @static
         * @param {authentication.IRefreshTokenInvalid} message RefreshTokenInvalid message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RefreshTokenInvalid.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified RefreshTokenInvalid message, length delimited. Does not implicitly {@link authentication.RefreshTokenInvalid.verify|verify} messages.
         * @function encodeDelimited
         * @memberof authentication.RefreshTokenInvalid
         * @static
         * @param {authentication.IRefreshTokenInvalid} message RefreshTokenInvalid message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RefreshTokenInvalid.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RefreshTokenInvalid message from the specified reader or buffer.
         * @function decode
         * @memberof authentication.RefreshTokenInvalid
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {authentication.RefreshTokenInvalid} RefreshTokenInvalid
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RefreshTokenInvalid.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.authentication.RefreshTokenInvalid();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RefreshTokenInvalid message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof authentication.RefreshTokenInvalid
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {authentication.RefreshTokenInvalid} RefreshTokenInvalid
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RefreshTokenInvalid.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RefreshTokenInvalid message.
         * @function verify
         * @memberof authentication.RefreshTokenInvalid
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RefreshTokenInvalid.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a RefreshTokenInvalid message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof authentication.RefreshTokenInvalid
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {authentication.RefreshTokenInvalid} RefreshTokenInvalid
         */
        RefreshTokenInvalid.fromObject = function fromObject(object) {
            if (object instanceof $root.authentication.RefreshTokenInvalid)
                return object;
            return new $root.authentication.RefreshTokenInvalid();
        };

        /**
         * Creates a plain object from a RefreshTokenInvalid message. Also converts values to other types if specified.
         * @function toObject
         * @memberof authentication.RefreshTokenInvalid
         * @static
         * @param {authentication.RefreshTokenInvalid} message RefreshTokenInvalid
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RefreshTokenInvalid.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this RefreshTokenInvalid to JSON.
         * @function toJSON
         * @memberof authentication.RefreshTokenInvalid
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RefreshTokenInvalid.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RefreshTokenInvalid
         * @function getTypeUrl
         * @memberof authentication.RefreshTokenInvalid
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RefreshTokenInvalid.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/authentication.RefreshTokenInvalid";
        };

        return RefreshTokenInvalid;
    })();

    return authentication;
})();

export const uploader = $root.uploader = (() => {

    /**
     * Namespace uploader.
     * @exports uploader
     * @namespace
     */
    const uploader = {};

    uploader.UploadTask = (function() {

        /**
         * Properties of an UploadTask.
         * @memberof uploader
         * @interface IUploadTask
         * @property {number|Long|null} [created] UploadTask created
         * @property {string|null} [path] UploadTask path
         * @property {string|null} [name] UploadTask name
         * @property {string|null} [mime] UploadTask mime
         * @property {uploader.UploadStatus|null} [status] UploadTask status
         * @property {number|null} [retryCounter] UploadTask retryCounter
         * @property {string|null} [error] UploadTask error
         * @property {string|null} [fingerprint] UploadTask fingerprint
         * @property {string|null} [url] UploadTask url
         * @property {Object.<string,string>|null} [metadata] UploadTask metadata
         */

        /**
         * Constructs a new UploadTask.
         * @memberof uploader
         * @classdesc Represents an UploadTask.
         * @implements IUploadTask
         * @constructor
         * @param {uploader.IUploadTask=} [properties] Properties to set
         */
        function UploadTask(properties) {
            this.metadata = {};
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UploadTask created.
         * @member {number|Long} created
         * @memberof uploader.UploadTask
         * @instance
         */
        UploadTask.prototype.created = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * UploadTask path.
         * @member {string} path
         * @memberof uploader.UploadTask
         * @instance
         */
        UploadTask.prototype.path = "";

        /**
         * UploadTask name.
         * @member {string} name
         * @memberof uploader.UploadTask
         * @instance
         */
        UploadTask.prototype.name = "";

        /**
         * UploadTask mime.
         * @member {string} mime
         * @memberof uploader.UploadTask
         * @instance
         */
        UploadTask.prototype.mime = "";

        /**
         * UploadTask status.
         * @member {uploader.UploadStatus} status
         * @memberof uploader.UploadTask
         * @instance
         */
        UploadTask.prototype.status = 0;

        /**
         * UploadTask retryCounter.
         * @member {number} retryCounter
         * @memberof uploader.UploadTask
         * @instance
         */
        UploadTask.prototype.retryCounter = 0;

        /**
         * UploadTask error.
         * @member {string} error
         * @memberof uploader.UploadTask
         * @instance
         */
        UploadTask.prototype.error = "";

        /**
         * UploadTask fingerprint.
         * @member {string} fingerprint
         * @memberof uploader.UploadTask
         * @instance
         */
        UploadTask.prototype.fingerprint = "";

        /**
         * UploadTask url.
         * @member {string} url
         * @memberof uploader.UploadTask
         * @instance
         */
        UploadTask.prototype.url = "";

        /**
         * UploadTask metadata.
         * @member {Object.<string,string>} metadata
         * @memberof uploader.UploadTask
         * @instance
         */
        UploadTask.prototype.metadata = $util.emptyObject;

        /**
         * Creates a new UploadTask instance using the specified properties.
         * @function create
         * @memberof uploader.UploadTask
         * @static
         * @param {uploader.IUploadTask=} [properties] Properties to set
         * @returns {uploader.UploadTask} UploadTask instance
         */
        UploadTask.create = function create(properties) {
            return new UploadTask(properties);
        };

        /**
         * Encodes the specified UploadTask message. Does not implicitly {@link uploader.UploadTask.verify|verify} messages.
         * @function encode
         * @memberof uploader.UploadTask
         * @static
         * @param {uploader.IUploadTask} message UploadTask message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadTask.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.created != null && Object.hasOwnProperty.call(message, "created"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.created);
            if (message.path != null && Object.hasOwnProperty.call(message, "path"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.path);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
            if (message.mime != null && Object.hasOwnProperty.call(message, "mime"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.mime);
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.status);
            if (message.retryCounter != null && Object.hasOwnProperty.call(message, "retryCounter"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.retryCounter);
            if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.error);
            if (message.fingerprint != null && Object.hasOwnProperty.call(message, "fingerprint"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.fingerprint);
            if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.url);
            if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                for (let keys = Object.keys(message.metadata), i = 0; i < keys.length; ++i)
                    writer.uint32(/* id 10, wireType 2 =*/82).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.metadata[keys[i]]).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UploadTask message, length delimited. Does not implicitly {@link uploader.UploadTask.verify|verify} messages.
         * @function encodeDelimited
         * @memberof uploader.UploadTask
         * @static
         * @param {uploader.IUploadTask} message UploadTask message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadTask.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UploadTask message from the specified reader or buffer.
         * @function decode
         * @memberof uploader.UploadTask
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {uploader.UploadTask} UploadTask
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadTask.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.uploader.UploadTask(), key, value;
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.created = reader.uint64();
                        break;
                    }
                case 2: {
                        message.path = reader.string();
                        break;
                    }
                case 3: {
                        message.name = reader.string();
                        break;
                    }
                case 4: {
                        message.mime = reader.string();
                        break;
                    }
                case 5: {
                        message.status = reader.int32();
                        break;
                    }
                case 6: {
                        message.retryCounter = reader.int32();
                        break;
                    }
                case 7: {
                        message.error = reader.string();
                        break;
                    }
                case 8: {
                        message.fingerprint = reader.string();
                        break;
                    }
                case 9: {
                        message.url = reader.string();
                        break;
                    }
                case 10: {
                        if (message.metadata === $util.emptyObject)
                            message.metadata = {};
                        let end2 = reader.uint32() + reader.pos;
                        key = "";
                        value = "";
                        while (reader.pos < end2) {
                            let tag2 = reader.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                key = reader.string();
                                break;
                            case 2:
                                value = reader.string();
                                break;
                            default:
                                reader.skipType(tag2 & 7);
                                break;
                            }
                        }
                        message.metadata[key] = value;
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UploadTask message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof uploader.UploadTask
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {uploader.UploadTask} UploadTask
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadTask.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UploadTask message.
         * @function verify
         * @memberof uploader.UploadTask
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UploadTask.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.created != null && message.hasOwnProperty("created"))
                if (!$util.isInteger(message.created) && !(message.created && $util.isInteger(message.created.low) && $util.isInteger(message.created.high)))
                    return "created: integer|Long expected";
            if (message.path != null && message.hasOwnProperty("path"))
                if (!$util.isString(message.path))
                    return "path: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.mime != null && message.hasOwnProperty("mime"))
                if (!$util.isString(message.mime))
                    return "mime: string expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                case 3:
                case 4:
                case 5:
                case 6:
                    break;
                }
            if (message.retryCounter != null && message.hasOwnProperty("retryCounter"))
                if (!$util.isInteger(message.retryCounter))
                    return "retryCounter: integer expected";
            if (message.error != null && message.hasOwnProperty("error"))
                if (!$util.isString(message.error))
                    return "error: string expected";
            if (message.fingerprint != null && message.hasOwnProperty("fingerprint"))
                if (!$util.isString(message.fingerprint))
                    return "fingerprint: string expected";
            if (message.url != null && message.hasOwnProperty("url"))
                if (!$util.isString(message.url))
                    return "url: string expected";
            if (message.metadata != null && message.hasOwnProperty("metadata")) {
                if (!$util.isObject(message.metadata))
                    return "metadata: object expected";
                let key = Object.keys(message.metadata);
                for (let i = 0; i < key.length; ++i)
                    if (!$util.isString(message.metadata[key[i]]))
                        return "metadata: string{k:string} expected";
            }
            return null;
        };

        /**
         * Creates an UploadTask message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof uploader.UploadTask
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {uploader.UploadTask} UploadTask
         */
        UploadTask.fromObject = function fromObject(object) {
            if (object instanceof $root.uploader.UploadTask)
                return object;
            let message = new $root.uploader.UploadTask();
            if (object.created != null)
                if ($util.Long)
                    (message.created = $util.Long.fromValue(object.created)).unsigned = true;
                else if (typeof object.created === "string")
                    message.created = parseInt(object.created, 10);
                else if (typeof object.created === "number")
                    message.created = object.created;
                else if (typeof object.created === "object")
                    message.created = new $util.LongBits(object.created.low >>> 0, object.created.high >>> 0).toNumber(true);
            if (object.path != null)
                message.path = String(object.path);
            if (object.name != null)
                message.name = String(object.name);
            if (object.mime != null)
                message.mime = String(object.mime);
            switch (object.status) {
            default:
                if (typeof object.status === "number") {
                    message.status = object.status;
                    break;
                }
                break;
            case "scheduled":
            case 0:
                message.status = 0;
                break;
            case "uploading":
            case 1:
                message.status = 1;
                break;
            case "paused":
            case 3:
                message.status = 3;
                break;
            case "done":
            case 4:
                message.status = 4;
                break;
            case "error":
            case 5:
                message.status = 5;
                break;
            case "restored":
            case 6:
                message.status = 6;
                break;
            }
            if (object.retryCounter != null)
                message.retryCounter = object.retryCounter | 0;
            if (object.error != null)
                message.error = String(object.error);
            if (object.fingerprint != null)
                message.fingerprint = String(object.fingerprint);
            if (object.url != null)
                message.url = String(object.url);
            if (object.metadata) {
                if (typeof object.metadata !== "object")
                    throw TypeError(".uploader.UploadTask.metadata: object expected");
                message.metadata = {};
                for (let keys = Object.keys(object.metadata), i = 0; i < keys.length; ++i)
                    message.metadata[keys[i]] = String(object.metadata[keys[i]]);
            }
            return message;
        };

        /**
         * Creates a plain object from an UploadTask message. Also converts values to other types if specified.
         * @function toObject
         * @memberof uploader.UploadTask
         * @static
         * @param {uploader.UploadTask} message UploadTask
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UploadTask.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.objects || options.defaults)
                object.metadata = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.created = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.created = options.longs === String ? "0" : 0;
                object.path = "";
                object.name = "";
                object.mime = "";
                object.status = options.enums === String ? "scheduled" : 0;
                object.retryCounter = 0;
                object.error = "";
                object.fingerprint = "";
                object.url = "";
            }
            if (message.created != null && message.hasOwnProperty("created"))
                if (typeof message.created === "number")
                    object.created = options.longs === String ? String(message.created) : message.created;
                else
                    object.created = options.longs === String ? $util.Long.prototype.toString.call(message.created) : options.longs === Number ? new $util.LongBits(message.created.low >>> 0, message.created.high >>> 0).toNumber(true) : message.created;
            if (message.path != null && message.hasOwnProperty("path"))
                object.path = message.path;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.mime != null && message.hasOwnProperty("mime"))
                object.mime = message.mime;
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.uploader.UploadStatus[message.status] === undefined ? message.status : $root.uploader.UploadStatus[message.status] : message.status;
            if (message.retryCounter != null && message.hasOwnProperty("retryCounter"))
                object.retryCounter = message.retryCounter;
            if (message.error != null && message.hasOwnProperty("error"))
                object.error = message.error;
            if (message.fingerprint != null && message.hasOwnProperty("fingerprint"))
                object.fingerprint = message.fingerprint;
            if (message.url != null && message.hasOwnProperty("url"))
                object.url = message.url;
            let keys2;
            if (message.metadata && (keys2 = Object.keys(message.metadata)).length) {
                object.metadata = {};
                for (let j = 0; j < keys2.length; ++j)
                    object.metadata[keys2[j]] = message.metadata[keys2[j]];
            }
            return object;
        };

        /**
         * Converts this UploadTask to JSON.
         * @function toJSON
         * @memberof uploader.UploadTask
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UploadTask.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UploadTask
         * @function getTypeUrl
         * @memberof uploader.UploadTask
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UploadTask.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/uploader.UploadTask";
        };

        return UploadTask;
    })();

    /**
     * UploadStatus enum.
     * @name uploader.UploadStatus
     * @enum {number}
     * @property {number} scheduled=0 scheduled value
     * @property {number} uploading=1 uploading value
     * @property {number} paused=3 paused value
     * @property {number} done=4 done value
     * @property {number} error=5 error value
     * @property {number} restored=6 restored value
     */
    uploader.UploadStatus = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "scheduled"] = 0;
        values[valuesById[1] = "uploading"] = 1;
        values[valuesById[3] = "paused"] = 3;
        values[valuesById[4] = "done"] = 4;
        values[valuesById[5] = "error"] = 5;
        values[valuesById[6] = "restored"] = 6;
        return values;
    })();

    uploader.UploadUFile = (function() {

        /**
         * Properties of an UploadUFile.
         * @memberof uploader
         * @interface IUploadUFile
         * @property {string|null} [fileId] UploadUFile fileId
         * @property {uploader.IUploadTask|null} [task] UploadUFile task
         */

        /**
         * Constructs a new UploadUFile.
         * @memberof uploader
         * @classdesc Represents an UploadUFile.
         * @implements IUploadUFile
         * @constructor
         * @param {uploader.IUploadUFile=} [properties] Properties to set
         */
        function UploadUFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UploadUFile fileId.
         * @member {string} fileId
         * @memberof uploader.UploadUFile
         * @instance
         */
        UploadUFile.prototype.fileId = "";

        /**
         * UploadUFile task.
         * @member {uploader.IUploadTask|null|undefined} task
         * @memberof uploader.UploadUFile
         * @instance
         */
        UploadUFile.prototype.task = null;

        /**
         * Creates a new UploadUFile instance using the specified properties.
         * @function create
         * @memberof uploader.UploadUFile
         * @static
         * @param {uploader.IUploadUFile=} [properties] Properties to set
         * @returns {uploader.UploadUFile} UploadUFile instance
         */
        UploadUFile.create = function create(properties) {
            return new UploadUFile(properties);
        };

        /**
         * Encodes the specified UploadUFile message. Does not implicitly {@link uploader.UploadUFile.verify|verify} messages.
         * @function encode
         * @memberof uploader.UploadUFile
         * @static
         * @param {uploader.IUploadUFile} message UploadUFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadUFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.fileId != null && Object.hasOwnProperty.call(message, "fileId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.fileId);
            if (message.task != null && Object.hasOwnProperty.call(message, "task"))
                $root.uploader.UploadTask.encode(message.task, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UploadUFile message, length delimited. Does not implicitly {@link uploader.UploadUFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof uploader.UploadUFile
         * @static
         * @param {uploader.IUploadUFile} message UploadUFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadUFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UploadUFile message from the specified reader or buffer.
         * @function decode
         * @memberof uploader.UploadUFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {uploader.UploadUFile} UploadUFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadUFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.uploader.UploadUFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.fileId = reader.string();
                        break;
                    }
                case 2: {
                        message.task = $root.uploader.UploadTask.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UploadUFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof uploader.UploadUFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {uploader.UploadUFile} UploadUFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadUFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UploadUFile message.
         * @function verify
         * @memberof uploader.UploadUFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UploadUFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.fileId != null && message.hasOwnProperty("fileId"))
                if (!$util.isString(message.fileId))
                    return "fileId: string expected";
            if (message.task != null && message.hasOwnProperty("task")) {
                let error = $root.uploader.UploadTask.verify(message.task);
                if (error)
                    return "task." + error;
            }
            return null;
        };

        /**
         * Creates an UploadUFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof uploader.UploadUFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {uploader.UploadUFile} UploadUFile
         */
        UploadUFile.fromObject = function fromObject(object) {
            if (object instanceof $root.uploader.UploadUFile)
                return object;
            let message = new $root.uploader.UploadUFile();
            if (object.fileId != null)
                message.fileId = String(object.fileId);
            if (object.task != null) {
                if (typeof object.task !== "object")
                    throw TypeError(".uploader.UploadUFile.task: object expected");
                message.task = $root.uploader.UploadTask.fromObject(object.task);
            }
            return message;
        };

        /**
         * Creates a plain object from an UploadUFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof uploader.UploadUFile
         * @static
         * @param {uploader.UploadUFile} message UploadUFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UploadUFile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.fileId = "";
                object.task = null;
            }
            if (message.fileId != null && message.hasOwnProperty("fileId"))
                object.fileId = message.fileId;
            if (message.task != null && message.hasOwnProperty("task"))
                object.task = $root.uploader.UploadTask.toObject(message.task, options);
            return object;
        };

        /**
         * Converts this UploadUFile to JSON.
         * @function toJSON
         * @memberof uploader.UploadUFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UploadUFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UploadUFile
         * @function getTypeUrl
         * @memberof uploader.UploadUFile
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UploadUFile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/uploader.UploadUFile";
        };

        return UploadUFile;
    })();

    uploader.DeleteUFile = (function() {

        /**
         * Properties of a DeleteUFile.
         * @memberof uploader
         * @interface IDeleteUFile
         * @property {string|null} [id] DeleteUFile id
         */

        /**
         * Constructs a new DeleteUFile.
         * @memberof uploader
         * @classdesc Represents a DeleteUFile.
         * @implements IDeleteUFile
         * @constructor
         * @param {uploader.IDeleteUFile=} [properties] Properties to set
         */
        function DeleteUFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DeleteUFile id.
         * @member {string} id
         * @memberof uploader.DeleteUFile
         * @instance
         */
        DeleteUFile.prototype.id = "";

        /**
         * Creates a new DeleteUFile instance using the specified properties.
         * @function create
         * @memberof uploader.DeleteUFile
         * @static
         * @param {uploader.IDeleteUFile=} [properties] Properties to set
         * @returns {uploader.DeleteUFile} DeleteUFile instance
         */
        DeleteUFile.create = function create(properties) {
            return new DeleteUFile(properties);
        };

        /**
         * Encodes the specified DeleteUFile message. Does not implicitly {@link uploader.DeleteUFile.verify|verify} messages.
         * @function encode
         * @memberof uploader.DeleteUFile
         * @static
         * @param {uploader.IDeleteUFile} message DeleteUFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteUFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            return writer;
        };

        /**
         * Encodes the specified DeleteUFile message, length delimited. Does not implicitly {@link uploader.DeleteUFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof uploader.DeleteUFile
         * @static
         * @param {uploader.IDeleteUFile} message DeleteUFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteUFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeleteUFile message from the specified reader or buffer.
         * @function decode
         * @memberof uploader.DeleteUFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {uploader.DeleteUFile} DeleteUFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteUFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.uploader.DeleteUFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeleteUFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof uploader.DeleteUFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {uploader.DeleteUFile} DeleteUFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteUFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeleteUFile message.
         * @function verify
         * @memberof uploader.DeleteUFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeleteUFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            return null;
        };

        /**
         * Creates a DeleteUFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof uploader.DeleteUFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {uploader.DeleteUFile} DeleteUFile
         */
        DeleteUFile.fromObject = function fromObject(object) {
            if (object instanceof $root.uploader.DeleteUFile)
                return object;
            let message = new $root.uploader.DeleteUFile();
            if (object.id != null)
                message.id = String(object.id);
            return message;
        };

        /**
         * Creates a plain object from a DeleteUFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof uploader.DeleteUFile
         * @static
         * @param {uploader.DeleteUFile} message DeleteUFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteUFile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.id = "";
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            return object;
        };

        /**
         * Converts this DeleteUFile to JSON.
         * @function toJSON
         * @memberof uploader.DeleteUFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteUFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DeleteUFile
         * @function getTypeUrl
         * @memberof uploader.DeleteUFile
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DeleteUFile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/uploader.DeleteUFile";
        };

        return DeleteUFile;
    })();

    uploader.UploadSlot = (function() {

        /**
         * Properties of an UploadSlot.
         * @memberof uploader
         * @interface IUploadSlot
         * @property {uploader.IUFile|null} [file] UploadSlot file
         * @property {Object.<string,string>|null} [metadata] UploadSlot metadata
         */

        /**
         * Constructs a new UploadSlot.
         * @memberof uploader
         * @classdesc Represents an UploadSlot.
         * @implements IUploadSlot
         * @constructor
         * @param {uploader.IUploadSlot=} [properties] Properties to set
         */
        function UploadSlot(properties) {
            this.metadata = {};
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UploadSlot file.
         * @member {uploader.IUFile|null|undefined} file
         * @memberof uploader.UploadSlot
         * @instance
         */
        UploadSlot.prototype.file = null;

        /**
         * UploadSlot metadata.
         * @member {Object.<string,string>} metadata
         * @memberof uploader.UploadSlot
         * @instance
         */
        UploadSlot.prototype.metadata = $util.emptyObject;

        /**
         * Creates a new UploadSlot instance using the specified properties.
         * @function create
         * @memberof uploader.UploadSlot
         * @static
         * @param {uploader.IUploadSlot=} [properties] Properties to set
         * @returns {uploader.UploadSlot} UploadSlot instance
         */
        UploadSlot.create = function create(properties) {
            return new UploadSlot(properties);
        };

        /**
         * Encodes the specified UploadSlot message. Does not implicitly {@link uploader.UploadSlot.verify|verify} messages.
         * @function encode
         * @memberof uploader.UploadSlot
         * @static
         * @param {uploader.IUploadSlot} message UploadSlot message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadSlot.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.file != null && Object.hasOwnProperty.call(message, "file"))
                $root.uploader.UFile.encode(message.file, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                for (let keys = Object.keys(message.metadata), i = 0; i < keys.length; ++i)
                    writer.uint32(/* id 2, wireType 2 =*/18).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.metadata[keys[i]]).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UploadSlot message, length delimited. Does not implicitly {@link uploader.UploadSlot.verify|verify} messages.
         * @function encodeDelimited
         * @memberof uploader.UploadSlot
         * @static
         * @param {uploader.IUploadSlot} message UploadSlot message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadSlot.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UploadSlot message from the specified reader or buffer.
         * @function decode
         * @memberof uploader.UploadSlot
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {uploader.UploadSlot} UploadSlot
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadSlot.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.uploader.UploadSlot(), key, value;
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.file = $root.uploader.UFile.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        if (message.metadata === $util.emptyObject)
                            message.metadata = {};
                        let end2 = reader.uint32() + reader.pos;
                        key = "";
                        value = "";
                        while (reader.pos < end2) {
                            let tag2 = reader.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                key = reader.string();
                                break;
                            case 2:
                                value = reader.string();
                                break;
                            default:
                                reader.skipType(tag2 & 7);
                                break;
                            }
                        }
                        message.metadata[key] = value;
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UploadSlot message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof uploader.UploadSlot
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {uploader.UploadSlot} UploadSlot
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadSlot.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UploadSlot message.
         * @function verify
         * @memberof uploader.UploadSlot
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UploadSlot.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.file != null && message.hasOwnProperty("file")) {
                let error = $root.uploader.UFile.verify(message.file);
                if (error)
                    return "file." + error;
            }
            if (message.metadata != null && message.hasOwnProperty("metadata")) {
                if (!$util.isObject(message.metadata))
                    return "metadata: object expected";
                let key = Object.keys(message.metadata);
                for (let i = 0; i < key.length; ++i)
                    if (!$util.isString(message.metadata[key[i]]))
                        return "metadata: string{k:string} expected";
            }
            return null;
        };

        /**
         * Creates an UploadSlot message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof uploader.UploadSlot
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {uploader.UploadSlot} UploadSlot
         */
        UploadSlot.fromObject = function fromObject(object) {
            if (object instanceof $root.uploader.UploadSlot)
                return object;
            let message = new $root.uploader.UploadSlot();
            if (object.file != null) {
                if (typeof object.file !== "object")
                    throw TypeError(".uploader.UploadSlot.file: object expected");
                message.file = $root.uploader.UFile.fromObject(object.file);
            }
            if (object.metadata) {
                if (typeof object.metadata !== "object")
                    throw TypeError(".uploader.UploadSlot.metadata: object expected");
                message.metadata = {};
                for (let keys = Object.keys(object.metadata), i = 0; i < keys.length; ++i)
                    message.metadata[keys[i]] = String(object.metadata[keys[i]]);
            }
            return message;
        };

        /**
         * Creates a plain object from an UploadSlot message. Also converts values to other types if specified.
         * @function toObject
         * @memberof uploader.UploadSlot
         * @static
         * @param {uploader.UploadSlot} message UploadSlot
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UploadSlot.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.objects || options.defaults)
                object.metadata = {};
            if (options.defaults)
                object.file = null;
            if (message.file != null && message.hasOwnProperty("file"))
                object.file = $root.uploader.UFile.toObject(message.file, options);
            let keys2;
            if (message.metadata && (keys2 = Object.keys(message.metadata)).length) {
                object.metadata = {};
                for (let j = 0; j < keys2.length; ++j)
                    object.metadata[keys2[j]] = message.metadata[keys2[j]];
            }
            return object;
        };

        /**
         * Converts this UploadSlot to JSON.
         * @function toJSON
         * @memberof uploader.UploadSlot
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UploadSlot.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UploadSlot
         * @function getTypeUrl
         * @memberof uploader.UploadSlot
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UploadSlot.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/uploader.UploadSlot";
        };

        return UploadSlot;
    })();

    uploader.UFile = (function() {

        /**
         * Properties of a UFile.
         * @memberof uploader
         * @interface IUFile
         * @property {string|null} [id] UFile id
         * @property {string|null} [src] UFile src
         * @property {string|null} [preview] UFile preview
         * @property {string|null} [previewLarge] UFile previewLarge
         * @property {string|null} [name] UFile name
         * @property {string|null} [uploadTask] UFile uploadTask
         * @property {string|null} [localPath] UFile localPath
         */

        /**
         * Constructs a new UFile.
         * @memberof uploader
         * @classdesc Represents a UFile.
         * @implements IUFile
         * @constructor
         * @param {uploader.IUFile=} [properties] Properties to set
         */
        function UFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UFile id.
         * @member {string} id
         * @memberof uploader.UFile
         * @instance
         */
        UFile.prototype.id = "";

        /**
         * UFile src.
         * @member {string} src
         * @memberof uploader.UFile
         * @instance
         */
        UFile.prototype.src = "";

        /**
         * UFile preview.
         * @member {string} preview
         * @memberof uploader.UFile
         * @instance
         */
        UFile.prototype.preview = "";

        /**
         * UFile previewLarge.
         * @member {string} previewLarge
         * @memberof uploader.UFile
         * @instance
         */
        UFile.prototype.previewLarge = "";

        /**
         * UFile name.
         * @member {string} name
         * @memberof uploader.UFile
         * @instance
         */
        UFile.prototype.name = "";

        /**
         * UFile uploadTask.
         * @member {string} uploadTask
         * @memberof uploader.UFile
         * @instance
         */
        UFile.prototype.uploadTask = "";

        /**
         * UFile localPath.
         * @member {string} localPath
         * @memberof uploader.UFile
         * @instance
         */
        UFile.prototype.localPath = "";

        /**
         * Creates a new UFile instance using the specified properties.
         * @function create
         * @memberof uploader.UFile
         * @static
         * @param {uploader.IUFile=} [properties] Properties to set
         * @returns {uploader.UFile} UFile instance
         */
        UFile.create = function create(properties) {
            return new UFile(properties);
        };

        /**
         * Encodes the specified UFile message. Does not implicitly {@link uploader.UFile.verify|verify} messages.
         * @function encode
         * @memberof uploader.UFile
         * @static
         * @param {uploader.IUFile} message UFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.src != null && Object.hasOwnProperty.call(message, "src"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.src);
            if (message.preview != null && Object.hasOwnProperty.call(message, "preview"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.preview);
            if (message.previewLarge != null && Object.hasOwnProperty.call(message, "previewLarge"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.previewLarge);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.name);
            if (message.uploadTask != null && Object.hasOwnProperty.call(message, "uploadTask"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.uploadTask);
            if (message.localPath != null && Object.hasOwnProperty.call(message, "localPath"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.localPath);
            return writer;
        };

        /**
         * Encodes the specified UFile message, length delimited. Does not implicitly {@link uploader.UFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof uploader.UFile
         * @static
         * @param {uploader.IUFile} message UFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a UFile message from the specified reader or buffer.
         * @function decode
         * @memberof uploader.UFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {uploader.UFile} UFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.uploader.UFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 2: {
                        message.src = reader.string();
                        break;
                    }
                case 3: {
                        message.preview = reader.string();
                        break;
                    }
                case 4: {
                        message.previewLarge = reader.string();
                        break;
                    }
                case 5: {
                        message.name = reader.string();
                        break;
                    }
                case 6: {
                        message.uploadTask = reader.string();
                        break;
                    }
                case 7: {
                        message.localPath = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a UFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof uploader.UFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {uploader.UFile} UFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a UFile message.
         * @function verify
         * @memberof uploader.UFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.src != null && message.hasOwnProperty("src"))
                if (!$util.isString(message.src))
                    return "src: string expected";
            if (message.preview != null && message.hasOwnProperty("preview"))
                if (!$util.isString(message.preview))
                    return "preview: string expected";
            if (message.previewLarge != null && message.hasOwnProperty("previewLarge"))
                if (!$util.isString(message.previewLarge))
                    return "previewLarge: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.uploadTask != null && message.hasOwnProperty("uploadTask"))
                if (!$util.isString(message.uploadTask))
                    return "uploadTask: string expected";
            if (message.localPath != null && message.hasOwnProperty("localPath"))
                if (!$util.isString(message.localPath))
                    return "localPath: string expected";
            return null;
        };

        /**
         * Creates a UFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof uploader.UFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {uploader.UFile} UFile
         */
        UFile.fromObject = function fromObject(object) {
            if (object instanceof $root.uploader.UFile)
                return object;
            let message = new $root.uploader.UFile();
            if (object.id != null)
                message.id = String(object.id);
            if (object.src != null)
                message.src = String(object.src);
            if (object.preview != null)
                message.preview = String(object.preview);
            if (object.previewLarge != null)
                message.previewLarge = String(object.previewLarge);
            if (object.name != null)
                message.name = String(object.name);
            if (object.uploadTask != null)
                message.uploadTask = String(object.uploadTask);
            if (object.localPath != null)
                message.localPath = String(object.localPath);
            return message;
        };

        /**
         * Creates a plain object from a UFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof uploader.UFile
         * @static
         * @param {uploader.UFile} message UFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UFile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.id = "";
                object.src = "";
                object.preview = "";
                object.previewLarge = "";
                object.name = "";
                object.uploadTask = "";
                object.localPath = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.src != null && message.hasOwnProperty("src"))
                object.src = message.src;
            if (message.preview != null && message.hasOwnProperty("preview"))
                object.preview = message.preview;
            if (message.previewLarge != null && message.hasOwnProperty("previewLarge"))
                object.previewLarge = message.previewLarge;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.uploadTask != null && message.hasOwnProperty("uploadTask"))
                object.uploadTask = message.uploadTask;
            if (message.localPath != null && message.hasOwnProperty("localPath"))
                object.localPath = message.localPath;
            return object;
        };

        /**
         * Converts this UFile to JSON.
         * @function toJSON
         * @memberof uploader.UFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UFile
         * @function getTypeUrl
         * @memberof uploader.UFile
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UFile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/uploader.UFile";
        };

        return UFile;
    })();

    return uploader;
})();

export { $root as default };
