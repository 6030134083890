import {observer} from "mobx-react-lite";
import {useNavigate} from "react-router-dom";
import React, {useContext, useEffect} from "react";
import {AppContextStoreContext} from "../stores/AppContextStore";
import {LoadingOverlay} from "../components/elementary/LoadingOverlay";
import {RecorderRoutes} from "../recorder/Routes";

export abstract class WebserviceRoutes {
    static index = '/';
    static login = '/login';
    static projectRoute = '/project/:projectId';
    static users = '/users';
    static management = '/management';
    static logs = '/logs';


    static project = (id: string) => `/project/${id}`;

    static get wsEndpoint() {
        const scheme = window.location.protocol === 'https:' ? 'wss' : 'ws';
        return `${scheme}://${window.location.hostname}/snemalnik-ws`;
    }
}

export const ProtectedRoute = observer((props: { children: any }) => {
    const navigate = useNavigate();
    const context = useContext(AppContextStoreContext)
    useEffect(() => {
        setTimeout(() => {
            if (!context?.auth.loggedInAndHasUser) {
                navigate(RecorderRoutes.login);
            }
        }, 500);
    }, [navigate, context?.auth.loggedInAndHasUser]);
    if (!context?.auth.loggedInAndHasUser) {
        return <LoadingOverlay visible={true}/>;
    }
    return props.children;
});