import LogoLight from '../../assets/logo/light.png';
import LogoDark from '../../assets/logo/dark.png';
import styled from "@emotion/styled";
import {CSSProperties} from "react";
import {useTheme} from "@mui/material";

interface LogoProps {
    style?: CSSProperties;
    onClick?: () => void;
}

export const Logo = (props: LogoProps) => {
    const theme = useTheme();
    return <Img
        onClick={() => props.onClick?.()}
        style={{
            cursor: props.onClick ? 'pointer' : undefined,
            ...props.style
    }}
        src={theme.palette.mode === 'dark' ? LogoLight : LogoDark}
        alt={'logo'}/>;
}

const Img = styled.img`
  width: 150px;
`;